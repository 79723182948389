.order-2-construction-section {
  .actions-warranty {
    display: flex;
    float: none;
    margin: 20px 28px;
    margin-bottom: 0;

    construction-action-list {
      flex: 1 0 auto;
      display: flex;

      .action-label {
        margin-right: 15px;
      }
    }

    div {
      float: none;
      //display: block;
    }
  }

  .constructions-grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    .construction-cell-wrapper {
      flex: 0 0 33%;
      padding: 10px;

      .construction-cell {
        padding: 15px;
        padding-top: 5px;
        border-radius: 5px;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
        // Fix for broken float higher up in dom hierarki
        display: block;
        height: 100%;

        h3 {
          color: #000;
          clear: both;
          font-size: 16px;
          line-height: 30px;
          margin-bottom: 2px;

          .red-astrix-mark {
            padding: 0;
            font-size: 14px;
            vertical-align: super;
            color: #ff0515 !important;
          }
        }
      }
    }

    .construction-cell-wrapper.two-span {
      flex: 0 0 66%;
    }
  }

  .construction-footer {
    padding: 20px;
    display: flex;

    div {
      flex: 1 0 33%;
      text-align: center;
      align-self: flex-end;
    }

    button {
      width: 120px;
      background-color: #105c9d;
      line-height: 32px;
      color: #fff;
      cursor: pointer;
      border: none;
    }

    .required-field {
      color: red;
      font-size: 13px;
      text-align: right;
      align-self: flex-end;
    }

    button:disabled,
    button[disabled] {
      border-color: #dcdada;
      color: #dcdada;
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  .disable-warranty {
    input {
      width: 100%;
      height: 100%;
    }
    opacity: 0.7;
  }

  .warranty-type-body {
    overflow: hidden;
  }
}
