#navBar {
  .newOrder.add-clinics {
    float: left;
    border-left: solid 0px #979797;
    border-right: solid 1px #979797;
    padding-left: 0;
    padding-right: 10px;
    margin-right: 10px;
  }
}

#add-lab-modal {
  .modal-dialog {
    width: 800px;
    .modal-content {
      padding: 20px 40px 20px 70px;
      border-radius: 0px;
      background: @white-color;
      .modal-header {
        border: 0px;
        .close {
          opacity: 1;
          font-size: 30px;
          margin: -15px -25px;
          &:focus {
            outline: none;
          }
        }
        h4.modal-title {
          font-size: 25px;
          font-weight: bold;
          text-align: center;
        }
      }
      .modal-body {
        text-align: left;
        .add-clinic-form {
          #full-width;
          margin-bottom: 55px;
          margin-top: 20px;
          .add-clinic-form-row {
            #full-width;
            margin-bottom: 55px;
            h4 {
              color: @sky-start-dark;
              margin-bottom: 20px;
            }
            input {
              width: 230px;
              float: left;
              padding: 8px 10px;
              font-size: 12px;
              text-align: center;
              border: solid 1px @gray-dark;
              min-height: 38px;
              &:focus {
                outline: none;
              }
            }
            .street-block {
              width: 35%;
              float: left;
              margin-right: 5%;
              margin-top: -15px;
              label {
                #full-width;
                color: @gray-bg-color;
                font-weight: 300;
                font-style: italic;
              }
            }
            .street-block.postcode-input {
              width: 15%;
              input {
                width: 100%;
              }
            }
            .add-clinic-form-col {
              width: 33%;
              float: left;
              input {
                width: 87%;
              }
            }
          }
        }
      }
      .modal-footer {
        border: 0;
        margin: 30px 0 20px;
        .btn.btn-default {
          border-radius: 0px;
          border: solid 1px @sky-start-dark;
          text-transform: uppercase;
          font-size: 18px;
          letter-spacing: 1px;
          font-weight: bold;
          padding: 10px 45px;
          color: @sky-start-dark;
          &:hover, &:focus, &.active-tab-close {
            background: @sky-start-dark;
            color: @white-color;
          }
        }
      }
    }
  }
}

.reports {
  width: 243px;
  height: 35px;
  background-color: #e2dbdb;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.profile-right-block-two {
  #manage-labs {
    .manage-labs {
      .categories-section {
        .category-section-block {
          select.tech-admin-options {
            margin: 0;
          }
        }
      }
      #order-list {
        .order-list {
          border: 0;
          box-shadow: none;
          .order-list-hading {
            background: @light-grey;
            color: @h1-color;
            font-size: 16px;
          }
          .order-list-content {
            max-height: 400px;
            overflow-y: auto;
            &::-webkit-scrollbar {
              width: 5px;
            }
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px @light-grey;
            }
            &::-webkit-scrollbar-thumb {
              background-color: darkgrey;
              outline: 1px solid slategrey;
            }
            .mobi-manage-content {
              .order-list-content {
                p {
                  font-weight: normal;
                }
                &:nth-child(2n) {
                  background: @light-grey;
                }
              }
            }
          }
        }
      }
    }
  }
}

.float-none {
  float: none !important;
  .chooseLAb {
    cursor: pointer;
  }
}

.w-70 {
  width: 70%;
}
.area-23 {
  height: 23px;
  width: 23px;
}