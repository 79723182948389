.constructions-grid .construction-cell-wrapper {
  // Standard select list
  .standard-list {
    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        flex: 0 0 auto;
        background-color: #dcdada;
        margin-right: 5px;
        margin-bottom: 5px;
        min-width: 80px;
        text-align: center;

        a {
          padding: 5px 5px;
          display: block;
          cursor: pointer;
          color: #5a5959;
          font-size: 13px;
        }
      }
    }
    input {
      font-size: 13px;
      margin-top: 5px;
    }
  }

  // Color & Shade picker
  .color-selector {
    .select-lists {
      margin-bottom: 10px;

      select {
        cursor: pointer;
        font-size: 13px;
        background-color: #dcdada;
        border: 0;
        padding: 8px 5px;
        min-width: 150px;
      }

      select.color {
        min-width: 100px;
      }

      .shade {
        margin-right: 5px;
      }
    }
  }

  // Icon list
  .icon-list {
    ul {
      li {
        background-color: #dcdada;
        margin-bottom: 5px;
        cursor: pointer;

        a {
          padding: 4px;
          display: block;
          font-size: 13px;
          color: #5a5959;

          img {
            height: 25px;
            background: #fff;
            margin: 0 5px;
            border-radius: 20px;
            padding: 5px;
          }
        }
      }
    }
  }

  // Number of units components
  .number-of-unit {
    .action-units-number {
      margin-top: 0;
    }
  }

  // Multiselect
  .multiselect-settings {
    cursor: pointer;

    .dropdown {
      margin: 0;
      font-size: 13px;
      background-color: #dcdada;
      max-width: 150px;
      padding: 5px 5px;
      font-weight: 400;
      color: #5a5959;

      input {
        cursor: pointer;
        border: 0;
        background: none;
      }

      i.fa {
        font-size: 10px;
      }
    }

    .dropdown-menu {
      padding: 0;
      height: 35vh;
      overflow: auto;

      li {
        padding: 5px 10px;
        border-bottom: 1px solid #eee;

        label {
          margin: auto;
        }
      }
    }
  }
}

// Warranty box
warranty {
  .wrapper-label {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;

    input,
    label {
      margin: auto;
      float: none;
    }
  }
}

.add-index {
  input {
    z-index: 0 !important;
  }
  z-index: 99999 !important;
}
