#section-page {
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 35px;
  padding: 18px 61px 50px;
}
#section-heading {
  float: left;
  font-size: 20px;
  color: #1f2532;
  font-weight: 700;
  border-bottom: solid 1px #1f2532;
  width: 100%;
  line-height: 50px;
}
#full-width {
  width: 100%;
  float: left;
}
#rate-it {
  /* Background */
  /* Selected state */
  /* Cancelation of hover styling */
  /* Hover styling */
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../../../../images/rated-star.svg");
}
#rate-it .ngrateit-reset {
  background-image: url("../../../../images/cancel-icon.svg");
}
#rate-it .ngrateit-bg-star {
  background-image: url("../../../../images/bg-star.svg");
}
#rate-it .ngrateit-selected {
  background-image: url("../../../../images/rated-star.svg");
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating span:hover ~ span {
  background-image: url("../../../../images/bg-star.svg");
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../../../../images/rated-star.svg");
}
.material-lists .add-new-material-list {
  width: auto;
  float: right;
  border: solid 1px #105c9d;
  color: #fff;
  background: #105c9d;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  height: 36px;
  margin-left: 15px;
  min-width: 60px;
}
.material-lists .material-list-dropdown-section {
  margin-top: 30px;
}
.material-lists .material-list-dropdown-section .ng-select {
  width: 250px;
  float: left;
}
.material-lists .material-list-dropdown-section.edit-section {
  display: flex;
}
.material-lists .material-list-dropdown-section.edit-section .edit-btn {
  margin-left: auto;
}
.material-lists .indicate-circle-black,
.material-lists .indicate-circle-gray {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #000;
}
.material-lists .indicate-circle-gray {
  background: #aaa;
}
.material-lists .material-list-dropdown-section.edit-section .ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.material-lists .material-list-dropdown-section.edit-section .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.material-lists .material-search-filter {
  margin-top: 30px;
  margin-bottom: 20px;
}
.material-lists .material-search-filter input {
  border: 0;
  background: #eee;
  padding: 5px 5px 5px 35px;
  width: 100%;
}
.material-lists .material-search-filter i {
  position: absolute;
  left: 10px;
  top: 10px;
}
.material-lists .material-list-item table {
  width: 100%;
}
.material-lists .material-list-item table th {
  background: #eee;
  font-size: 14px;
}
.material-lists .material-list-item table tbody tr {
  cursor: pointer;
}
.material-lists .material-list-item table tbody tr:hover {
  background: #eee;
}
.material-lists .material-list-item table tbody tr.disabled-td td {
  color: #aaa;
}
.material-lists .material-list-item table td,
.material-lists .material-list-item table th {
  padding: 12px 5px;
  text-align: center;
  color: #000;
  border-bottom: solid 1px #ccc;
}
.material-lists .material-list-item table td:nth-child(2),
.material-lists .material-list-item table th:nth-child(2) {
  text-align: left;
}
#add-material-list-modal .action-no-name,
#add-material-modal .action-no-name,
#add-action-list-modal .action-no-name,
#add-category-modal .action-no-name,
#add-actionList-action-modal .action-no-name {
  display: flex;
}
#add-material-list-modal .action-no-name h4,
#add-material-modal .action-no-name h4,
#add-action-list-modal .action-no-name h4,
#add-category-modal .action-no-name h4,
#add-actionList-action-modal .action-no-name h4 {
  line-height: 40px;
}
#add-material-list-modal .action-no-name h4 p,
#add-material-modal .action-no-name h4 p,
#add-action-list-modal .action-no-name h4 p,
#add-category-modal .action-no-name h4 p,
#add-actionList-action-modal .action-no-name h4 p {
  margin-bottom: 0;
}
#add-material-list-modal .action-no-name textarea,
#add-material-modal .action-no-name textarea,
#add-action-list-modal .action-no-name textarea,
#add-category-modal .action-no-name textarea,
#add-actionList-action-modal .action-no-name textarea {
  width: 100%;
}
#add-material-list-modal .commet-export-production input[type="checkbox"],
#add-material-modal .commet-export-production input[type="checkbox"],
#add-action-list-modal .commet-export-production input[type="checkbox"],
#add-category-modal .commet-export-production input[type="checkbox"],
#add-actionList-action-modal .commet-export-production input[type="checkbox"] {
  width: auto !important;
  min-height: 20px !important;
}
#add-material-list-modal .commet-export-production input[type="checkbox"]:checked.checkedInput:after,
#add-material-modal .commet-export-production input[type="checkbox"]:checked.checkedInput:after,
#add-action-list-modal .commet-export-production input[type="checkbox"]:checked.checkedInput:after,
#add-category-modal .commet-export-production input[type="checkbox"]:checked.checkedInput:after,
#add-actionList-action-modal .commet-export-production input[type="checkbox"]:checked.checkedInput:after,
#add-material-list-modal .commet-export-production input[type="checkbox"]:after,
#add-material-modal .commet-export-production input[type="checkbox"]:after,
#add-action-list-modal .commet-export-production input[type="checkbox"]:after,
#add-category-modal .commet-export-production input[type="checkbox"]:after,
#add-actionList-action-modal .commet-export-production input[type="checkbox"]:after {
  top: 2px !important;
  left: 3px !important;
}
#add-category-modal .modal-dialog {
  width: 550px !important;
}
#add-category-modal .modal-dialog .modal-content .modal-footer button {
  float: right !important;
}
#add-actionList-action-modal .modal-dialog {
  width: 900px !important;
}
#add-actionList-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .category-select input {
  border: 0 !important;
  padding: 0 !important;
  margin-top: -7px !important;
  text-align: left !important;
}
