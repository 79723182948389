construction-header {
  h1 {
    font-size: 16px;
    line-height: 36px !important;
  }

  .fa-trash {
    background-color: #fff;
    color: red !important;
    border-radius: 15px;
    margin-top: 3px;
  }
}
