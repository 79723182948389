.order-2-construction-section .actions-warranty {
  display: flex;
  float: none;
  margin: 20px 28px;
  margin-bottom: 0;
}
.order-2-construction-section .actions-warranty construction-action-list {
  flex: 1 0 auto;
  display: flex;
}
.order-2-construction-section .actions-warranty construction-action-list .action-label {
  margin-right: 15px;
}
.order-2-construction-section .actions-warranty div {
  float: none;
}
.order-2-construction-section .constructions-grid {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.order-2-construction-section .constructions-grid .construction-cell-wrapper {
  flex: 0 0 33%;
  padding: 10px;
}
.order-2-construction-section .constructions-grid .construction-cell-wrapper .construction-cell {
  padding: 15px;
  padding-top: 5px;
  border-radius: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  display: block;
  height: 100%;
}
.order-2-construction-section .constructions-grid .construction-cell-wrapper .construction-cell h3 {
  color: #000;
  clear: both;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 2px;
}
.order-2-construction-section .constructions-grid .construction-cell-wrapper .construction-cell h3 .red-astrix-mark {
  padding: 0;
  font-size: 14px;
  vertical-align: super;
  color: #ff0515 !important;
}
.order-2-construction-section .constructions-grid .construction-cell-wrapper.two-span {
  flex: 0 0 66%;
}
.order-2-construction-section .construction-footer {
  padding: 20px;
  display: flex;
}
.order-2-construction-section .construction-footer div {
  flex: 1 0 33%;
  text-align: center;
  align-self: flex-end;
}
.order-2-construction-section .construction-footer button {
  width: 120px;
  background-color: #105c9d;
  line-height: 32px;
  color: #fff;
  cursor: pointer;
  border: none;
}
.order-2-construction-section .construction-footer .required-field {
  color: red;
  font-size: 13px;
  text-align: right;
  align-self: flex-end;
}
.order-2-construction-section .construction-footer button:disabled,
.order-2-construction-section .construction-footer button[disabled] {
  border-color: #dcdada;
  color: #dcdada;
  opacity: 0.7;
  cursor: not-allowed;
}
.order-2-construction-section .disable-warranty {
  opacity: 0.7;
}
.order-2-construction-section .disable-warranty input {
  width: 100%;
  height: 100%;
}
.order-2-construction-section .warranty-type-body {
  overflow: hidden;
}
