
.status-filter {
  .several-options {
    position: relative;
    background: #dcdada;
    margin: 15px 0;

    select, input {
      width: 100%;
      float: left;
      background: #dcdada;
      border-radius: 0;
      box-shadow: none;
      white-space: inherit;
      line-height: 34px;
      height: 34px;
      border: 0;
      padding: 0 15px;
      font-size: 14px;

      &:focus {
        outline: none;
      }
    }

    .search-material-actions {
      #full-width;
      position: relative;
      margin-left: 0;
      i.fa-caret-down {
        position: absolute;
        top: 10px;
        right: 10px;
      }

      input {
        padding-left: 15px;
        font-size: 14px;
      }

      .option-materials-actions {
        #full-width;
        position: absolute;
        top: 33px;
        left: 0;
        box-shadow: 3px 3px 5px @gray-bg-color;
        background: @gray-light-white;
        z-index: 9;
        padding: 0;
        max-height: 350px !important;
        overflow-y: auto !important;
        overflow-x: hidden;
        .material {
          #full-width;
          ul {
            #full-width;
            padding: 0;
            margin: 0;
            li {
              #full-width;
              padding: 7px 15px;
              border-bottom: solid 1px @gray-bg-color;
              position: relative;
              label.checkbox-style {
                min-height: 16px;
                line-height: 16px;
                margin-bottom: 0;
                position: static;
                input[type="checkbox"] {
                  width: 100%;
                  height: 100%;
                  padding: 0;
                  margin: 0;
                }
                input[type="checkbox"] + label {
                  margin-left: 15px;
                  line-height: 16px;
                  padding-left:23px;
                  margin-bottom: 0;
                  &:after {
                    width: 16px;
                    height: 16px;
                  }
                }
                input[type="checkbox"]:checked + label {
                  &:after {
                    border-color: #105c9d;
                  }
                  &:before {
                    width: 16px;
                    height: 16px;
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }

        .edit-save-btn {
          width: 100%;
          float: left;
          padding: 4px 15px 4px 0;

          button {
            width: auto;
            float: right;
            display: flex;
            align-items: center;
            justify-content: space-around;
            border: solid 1px @sky-start-dark;
            line-height: 24px;
            color: @sky-start-dark;
            cursor: pointer;
            padding: 2px 50px;
            background: none;
            font-weight: 600;
          }
        }
      }
    }

    input {
      padding-left: 40px;
    }

    .glyphicon-search {
      position: absolute;
      top: 10px;
      left: 13px;
    }
  }
}

#order-list, #users {
  .list-view-status{
    height: 800px;
    #full-width;
    margin-top: 150px;
    .order-list {
      margin-top: 0;
    }
  }
}

.filter-btn {
  border: 0;
  padding: 0 21px 0 21px;
  background: #105c9d !important;
  color: white;
  &:disabled {
    cursor: not-allowed;
  }
  width: auto;
  @media (max-width: 767px) {
    width: 100%;
  }
}