@import "~stylesheets/mixins.less";
@import "~stylesheets/variables.less";

input:-webkit-autofill{ background: @white-color !important; background-color: @white-color !important; -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#profile-dentist{
  #full-width; margin-top: 200px; margin-bottom: 20px;
  .profile-dentist{
    #full-width;
    .profile-left-section{
      #full-width; background: @white-color; padding-top: 45px;
      .head-profile{
        #full-width; padding: 0 35px;
        h2{
          #full-width; text-align: center; background: url(images/border-head-h2.png) 0 center repeat-x;
          span{
            width: auto; padding: 0 28px; background: @white-color; text-transform: uppercase; font-size: 22px; font-weight: 300;
          }
        }
      }
      .profile-form-left{
        #full-width; padding-top: 25px;
        .profile-form-left-row{
          #full-width; margin-bottom: 37px; padding: 0 35px;
          .profile-form-left-col{
            width: 43%; float: left;
            label{
              color: @sky-start-dark;
              margin-top: 10px;
              margin-bottom: 0; float: left;
            }
            input {
              width: 100%;
              float: left;
              padding: 5px 10px;
              font-size: 15px;
              text-align: center;
              border: solid 1px @gray-dark;
              min-height: 37px;
            }
          }
          .profile-form-left-col:nth-child(2n){
            float: right;
          }
        }
        .profile-form-left-row.change-pass-div{
          .profile-form-left-col{
            label{
              line-height: 34px;
            }
            /*input{
              width: 50%; float: right; border: 0px;
            }*/
          }
          .profile-form-left-col{
            margin-top: -10px;
            input[type="button"]{
              color: #fff;
              background: #105c9d;
              font-size: 20px;
              width: 100%;
              text-transform: uppercase;
              white-space: inherit;
              line-height: 22px;
              font-weight: 500;
              &:disabled{
                cursor: not-allowed;
                opacity: 0.6;
              }
            }
            &:nth-child(2){
              input[type="button"] {
                background: url(../../../images/bankid_vector_rgb.svg) 94% 3px no-repeat, #105c9d;
                background-size: 30px;
              }
            }
            &.extra-top-margin-add {
              margin-top: -73px;
            }
          }
        }
        .change-profile-picture{
          #full-width; position: relative;
          padding-bottom: 80px;
          img{
            #full-width;
            width: 28%;
            float: left;
            margin-left: 36%;
          }
          input[type="button"], upload-button#profileImage {
            color: #fff;
            background: #105c9d;
            font-size: 20px;
            text-transform: uppercase;
            white-space: nowrap;
            line-height: 35px; width: auto; padding: 8px 15px; border: 0;
            font-weight: 500; position: absolute;
            bottom: 15px;
            left: 50%;
            transform: translate(-50%, 0);
          }
          upload-button#profileImage {
            padding: 0;
            button {
              color: #fff;
              background: #105c9d;
              font-size: 20px;
              text-transform: uppercase;
              white-space: nowrap;
              line-height: 35px; width: auto; padding: 8px 15px; border: 0;
              font-weight: 500;
            }
          }
        }
      }
      .notifications{
        float: left;
        padding-top: 40px;
        background: #eee;
        .head-profile {
          background: #fff;
          h2{
            margin-top: 30px;
          }
        }
        .profile-form-left{
          background: #fff;
          margin-bottom: 20px;
          &:last-child{ min-height:180px;}
          .profile-form-left {
            #full-width;
            padding: 0 35px;
            .profile-form-left-col{
              #full-width;
              .select-email{
                font-size: 13px; color: #a7a2a2;
              }
              .notifications-title{
                width: 100%;
                float: left;
                font-weight: bold;
                color: #105c9d;
              }
              .select-destination{
                font-size: 13px;
                color: #a7a2a2;
              }
              .user-email {
                #full-width;
                text-align: left;
                .checkbox-inline {
                  padding-left:0;
                  width: 100%;
                  font-size: 13px;
                  input[type="radio"]:not(:checked) {
                    float: left;
                    width: 15px;
                    margin: 2px 10px 0px 0px;
                    height: 15px;
                    background: none;
                    border: 2px solid #105c9d;
                    outline: none;
                    -webkit-appearance: inherit;
                    padding: 8px !important;
                    border-radius: 50%;
                    position: static;
                    cursor: pointer;
                  }

                  input[type="radio"]:checked {
                    border: 2px solid #105c9d;
                  }

                  input[type="radio"]:checked {
                    float: left ;
                    width: 15px ;
                    margin: 0px 10px 0px 0px;
                    height: 15px;
                    background: none;
                    border: 2px solid #105c9d ;
                    outline: none;
                    cursor: pointer;
                    -webkit-appearance: inherit;
                    position: static;
                    font: normal normal normal 14px/1 FontAwesome;
                    padding: 8px !important;
                    border-radius: 50%;
                  }

                  input[type="radio"]:checked:after {
                    content: '';
                    position: absolute;
                    top: 4px;
                    left: 4px;
                    font-size: 12px;
                    width: 12px;
                    height: 12px;
                    background: #105c9d;
                    border-radius: 50%;
                    color: #105c9d;
                  }

                  input[type="radio"]:checked:after {
                    background: #105c9d;
                  }
                }
              }
              .user-enter-email{
                .email-input {
                  width: 85%;
                }
                .checkbox-inline {
                  padding-left: 0px;
                  width: 100%;
                  margin-left: 0px ;
                  margin-top: 5px ;
                  font-size: 13px;
                  input[type="radio"]:not(:checked) {
                    float: left;
                    width: 15px ;
                    margin: 0px 10px 0px 0px;
                    height: 15px;
                    background: none;
                    border: 2px solid #105c9d ;
                    outline: none;
                    -webkit-appearance: inherit;
                    padding: 8px ;
                    border-radius: 50%;
                    position: static;
                    cursor: pointer;
                    min-height: inherit;
                  }
                  input[type="radio"]:checked {
                    border: 2px solid #105c9d ;
                  }
                  input[type="radio"]:checked {
                    float: left;
                    width: 15px ;
                    margin: 4px 10px 0 0;
                    height: 15px;
                    background: none;
                    border: 2px solid #105c9d;
                    outline: none;
                    cursor: pointer;
                    -webkit-appearance: inherit;
                    position: static;
                    font: normal normal normal 14px/1 FontAwesome;
                    padding: 8px ;
                    border-radius: 50%;
                  }
                  input[type="radio"]:checked:after {
                    content: '';
                    position: absolute;
                    top: 8px;
                    left: 4px;
                    font-size: 12px;
                    width: 12px;
                    height: 12px;
                    background: #105c9d;
                    border-radius: 50%;
                    color: #105c9d;
                  }
                  input[type="radio"]:checked:after {
                    background: #105c9d;
                  }
                  input{
                    float: left;
                    width: auto ;
                    text-align: left;
                    padding: 0 ;
                    padding-left: 5px ;
                    border: solid 2px #ccc;
                    color: #000;
                    min-height: 25px;
                  }
                }
              }
              .switch-button{
                #full-width;
                margin-bottom: 10px;
                .switch.checked  {
                  background: #105c9d;
                  border-color: #105c9d;
                  float: left;
                  margin-right: 20px;
                }
                .switch{
                  float: left;
                  margin-right: 20px;
                  background: #d9dadc;
                  width: 42px;
                  height: 22px;
                  small{
                    width: 22px;
                    height: 22px;
                  }
                }
                p{
                  float: left;
                  margin-bottom: 0;
                  font-size: 13px;
                  color: #a7a2a2;
                }
                input{
                  float: left;
                  margin-right:10px;
                  padding: 0 5px;
                  line-height: 25px;
                  font-size: 14px;
                  text-align: center;
                }
                span{
                  float: left;
                }
              }
            }
          }
        }

      }
    }
    .profile-right-section{
      width: 97%; float: right;
      .profile-right-block-one{
        #full-width; background: @white-color; padding: 45px 35px 82px;
        .head-profile{
          #full-width; padding: 0 35px;
          h2{
            #full-width; text-align: center; background: url(images/border-head-h2.png) 0 center repeat-x;
            span{
              width: auto; padding: 0 28px; background: @white-color; text-transform: uppercase; font-size: 22px; font-weight: 300;
            }
          }
        }
        .profile-form-right{
          #full-width; text-align: center; display: inline-block; margin-top: 25px;
          .profile-form-right-row{
            width: auto; float: none; text-align: center; display: inline-block;
            .profile-form-right-col{
              width: auto; float: left; margin: 0 20px;
              label{
                width: 100%;
                color: @sky-start-dark;
                margin-bottom: 10px; float: left; text-align: left;
                span.version-number{
                  display: inline-block;
                  color: @gray-dark;
                  margin-left: 15px;
                  font-size: 14px;
                }
              }
              select {
                width: 100%;
                float: left;
                padding: 7px 10px;
                font-size: 15px;
                text-align: center;
                border: 0px;
                background: @gray-light-white;
                &:focus{
                  outline: none;
                }
              }
              .ui-select{
                min-width: 300px;
              }
              .ui-select-container.select2.select2-container{
                cursor: pointer;
                min-width: inherit !important; width: 100%; float: left; position: relative;
                a.select2-choice.ui-select-match{
                  border: solid 1px #979797;
                  width: 100%;
                  float: left;
                  padding: 5px 0;
                  color: #979797;
                  i.fa.fa-angle-down{
                    float: right;
                    padding-top: 5px;
                    padding-right: 10px;
                  }
                }
                .ui-select-dropdown.select2-drop{
                  float: left;
                  width: 100%;
                  border: 1px solid #979797;
                  border-top: 0;
                  position: absolute;
                  left: 0;
                  top: 39px;
                  background: @white-color;
                  .search-container.select2-search{
                    display: none;
                  }
                }
              }
            }
            .profile-form-right-col.default-clinic-right-col{
              width: 92.5% !important;
              .ui-select-container.select2.select2-container{
                width: auto;
                a.select2-choice.ui-select-match{
                  width: auto; min-width: 150px;
                }
              }
            }
            .profile-form-right-col.default-clinic-right-col.top-margin {
              margin-top: 20px !important;
             &.auto-archive{
               input {
                 width: 15%;
                 margin-right: 10px;
                 float: left;
                 padding:0;
                 text-align: center;
                 font-weight:700;
                 min-height: 31px;
               }
               span{
                 font-size: 13px;
                 float:left;
                 margin-top:4px;
                 color: @gray-font-color;
                 margin-right: 15px;
               }
               p {
                 width: 100%;
                 margin-left: 7%;
                 text-align: left;
                 font-size: 13px;
                 line-height: 16px;
                 margin-right: 28%;
                 color: @gray-font-color;
               }
             }
              .switch-button{
                #full-width;
                margin-bottom: 10px;
                .switch.checked  {
                  background: #105c9d;
                  border-color: #105c9d;
                  float: left;
                  margin-right: 20px;
                }
                .switch{
                  float: left;
                  margin-right: 20px;
                  background: #d9dadc;
                  width: 42px;
                  height: 22px;
                  small{
                    width: 22px;
                    height: 22px;
                  }
                }
                p{
                  float: left;
                  margin-bottom: 0;
                  font-size: 13px;
                  color: #a7a2a2;
                }
              }
            }
          }
        }
      }
      .profile-right-block-two{
        #full-width; background: @white-color; padding: 45px 35px 82px; margin-top:35px;
        .head-profile{
          #full-width; padding: 0 35px;
          h2{
            #full-width; text-align: center; background: url(images/border-head-h2.png) 0 center repeat-x;
            span{
              width: auto; padding: 0 28px; background: @white-color; text-transform: uppercase; font-size: 22px; font-weight: 300;
            }
          }
        }
        .profile-form-right{
          #full-width; text-align: center; display: inline-block; margin-top: 25px;
          .profile-form-right-row{
            width: 100%; float: none; text-align: center; display: inline-block;
            .profile-form-right-col{
              width: auto; float: left; margin: 10px 3%; max-width: 27%;
              label{
                width: 100%;
                color: @sky-start-dark;
                margin-bottom: 0; float: left; text-align: left;
              }
              select {
                width: 100%;
                float: left;
                padding: 7px 10px;
                font-size: 15px;
                text-align: center;
                border: 0px;
                background: @gray-light-white;
                &:focus{
                  outline: none;
                }
              }
            }
          }
        }
      }
      .save-change-button{
        #full-width;
        button{
          width: auto; float: right; text-transform: uppercase; padding: 10px 15px; margin-top:20px; color: @white-color; background: @sky-start-dark; border: 0px;
          &.disabled{
            cursor: not-allowed;
            opacity: 0.6;
          }
        }
        .input-button{
          width: auto; float: right; text-transform: uppercase; padding: 10px 15px; margin-top:20px; color: @white-color; background: @sky-start-dark; border: 0px;
          &.disabled{
            cursor: not-allowed;
            opacity: 0.6;
          }
        }
      }
    }
    &.tabbing-section-add {
      ul.tabbing-ul {
        padding: 0;
        margin-bottom: 40px;
        li {
          #full-width;
          a{
            #full-width;
            padding: 0 10px !important;
            background: #fff;
            border-bottom: solid 1px @gray-bg-color;
            text-align: left;
            color: @header-bg;
            line-height: 44px;
            height: 44px;
          }
          &:last-child {
            border-bottom: 0;
          }
          &.active {
            a{
              background: @header-bg;
              color: @white-color;
            }
          }
        }
      }
      .profile-right-section {
        width: 100%;
        .profile-right-block-two {
          margin-top: 0;
          .head-profile {
            padding: 0;
          }
        }
      }
      .profile-left-section {
        .notifications {
          padding-top: 0;
          .head-profile {
            h2 {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

body.modal-open > .modal.in > .modal-dialog{  width: 100%;}
body.modal-open > .modal.in > .modal-dialog > .modal-content{
  background-color: inherit; box-shadow: none; border:0px;
}
/*.modal-dialog{
  width: 100%;
  .modal-content{
    background-color: inherit; box-shadow: none; border:0px;
  }
}*/
body.modal-open > modal-container.modal.fade .modal-dialog {
  min-height: 100vh;
  transform: translate(0, 0);
  margin: auto;
  display: flex;
  align-items: center;
  width: 800px;
}
body.modal-open > modal-container.modal.fade .modal-dialog .modal-content {
  width: 100%;
  padding: 20px 30px;
  background-color: #fff;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
#change-password-modal{
  .modal-header{
    border: 0px;
    .close{
      opacity: 1; font-size: 30px; margin: -15px -25px;
      &:focus{
        outline: none;
      }
    }
    h4.modal-title{
      font-size: 25px;
      font-weight: bold;
      text-align: center;
    }
  }
  .modal-body{
    #full-width;
    .add-clinic-form{
      width: 28%; float: none; margin: auto; text-align: center; margin-bottom: 55px; margin-top: 20px;
      .add-clinic-form-row{
        #full-width; margin-bottom: 45px; position: relative;
        h4{
          color: @sky-start-dark; margin-bottom: 12px; font-size: 15px;
        }
        h4.sky-low-dark{
          color: @sky-low-dark;
        }
        input{ width: 100%; float: left; padding: 8px 10px; font-size:12px; text-align: center; border: solid 1px @gray-dark;}
        span {
          float: left;
          width: 20px;
          margin: 0px 10px 0 10px;
          height:20px;
          background: @active-tab-green;
          outline: 0;
          -webkit-appearance: inherit;
          font: normal normal normal 14px/1 FontAwesome;
          padding: 10px;
          border-radius: 100%;
          content: '\f00c';
          position: absolute;
          right: -55px;
          top: 37px;
          &:after{
            content: '\f00c'; position: absolute; top: 5px; left: 4px; font-size: 12px; color: @white-color;
          }
        }
      }
    }
  }
  .modal-footer{
    border: 0;
    margin: 30px 0 20px;
    .btn.btn-default{
      border-radius: 0px; border: solid 1px @sky-start-dark; text-transform: uppercase; font-size:18px; letter-spacing: 1px; font-weight: bold; padding: 10px 25px; color:@sky-start-dark;
      &:hover, &:focus, &.active-tab-close{
        background: @sky-start-dark; color: @white-color;
      }
    }
  }
}
