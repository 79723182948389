#report-description-modal {
  .modal-dialog {
    min-width: 800px;

    .modal-content {
      padding: 20px 50px 30px;

      .modal-header {
        border-bottom: 0;
        h4 {
          text-align: center;
          color: #105c9d;
          font-weight: 600;
        }
        button {
          font-size: 32px;
          opacity: 1;
          margin: -25px -45px;
        }
      }
      .modal-body {
        p {
          margin-bottom: 0;
          &:first-child {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}