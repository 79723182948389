@import "~stylesheets/mixins.less";
@import "~stylesheets/variables.less";

.order-material {
  .add-associated-material {
    width: auto;
    border: solid 1px #105c9d;
    color: #fff;
    background: #105c9d;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    height: 36px;
    margin-left: 15px;
    min-width: 60px;
    margin-top: 15px;
  }
}

#add-order-material-modal, #add-simple-order-action-modal {
  .modal-dialog {
    width: 90%;
    .modal-content {
      padding: 20px 40px 20px 70px;
      border-radius: 0px;
      background: @white-color;
      box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
      .modal-header {
        border: 0px;
        .close {
          opacity: 1;
          font-size: 30px;
          margin: -15px -25px;
          &:focus {
            outline: none;
          }
        }
        label.modal-title {
          font-size: 25px;
          font-weight: bold;
          text-align: center;
          color: @sky-start-dark;
          width: 100%;
        }
      }
      .modal-body {
        label {
          display: flex;
          max-width: 100%;
          margin-bottom: 5px;
          align-items: center;
          font-size: 13px;
          font-weight: 700;
          min-height: 47px;
          text-align: center;
        }
        p.red-astrix-mark {
          width: auto;
          padding: 0 6px;
          float: none;
          display: inline-block;
        }
        input {
          width: 100%;
          text-align: center;
        }
        .associated-material-search-filter {
          margin-top: 10px;
          margin-bottom: 20px;
          input {
            border: 0;
            background: #eee;
            padding: 5px 5px 5px 35px;
            width: 100%;
          }
          i {
            position: absolute;
            left: 25px;
            top: 8px;
            color: #aaa;
          }
        }
        .material-custom-dropdown {
          margin-left: 0;
          .caret {
            position: absolute;
            top: 15px;
            right: 10px;
          }
          ul {
            min-width: 100% !important;
            li {
              color: #000;
              padding: 0;
              margin-bottom: 0;
              p.free-text {
                border: none;
                text-align: center;
              }
              &.dropdown-header {
                color: #aaa;
              }
              span {
                width: 80px;
                padding-top: 5px;
                padding-bottom: 5px;
                border-top: 1px solid #aaa;
                float: left;
                cursor: pointer;
                &:nth-child(2) {
                  width: calc(100% - 80px);
                  padding-left: 15px;
                  text-align: left;
                  float: right;
                }
              }
              &.material-item {
                max-height: 150px;
                overflow-y: auto;
                .material-data {
                  &:hover {
                    background: #eee;
                    width: 100%;
                    float: left;
                  }
                }
              }
            }
          }
        }

      }
      .modal-footer {
        border: none !important;
        .btn.btn-default {
          border-radius: 0px;
          border: solid 1px @sky-start-dark;
          text-transform: uppercase;
          font-size: 18px;
          letter-spacing: 1px;
          font-weight: bold;
          padding: 10px 45px;
          color: @sky-start-dark;
          &:hover, &:focus, &.active-tab-close {
            background: @sky-start-dark;
            color: @white-color;
          }
        }
      }
    }
  }
}