#construction-section {
  margin-top: 60px;
}
#construction-section main-categories {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#construction-section teeth-structure {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
