.toast-success {
  background-color: @success-green !important;
}
.toast-error {
  background-color: @delay-red-dark !important;
}
.toast-warning {
  background-color: @notification-yellow !important;
}

.toast-container.toast-top-center .ngx-toastr {
  margin-top: 20px;
  width: 98%;
  max-width: 650px;
}