#confirm-password-modal, #delete-clinic-modal{
  .modal-dialog{
    .modal-content{
      padding: 20px 40px 20px 40px;
      border-radius: 0px;
      background: @white-color;
      box-shadow: 0 5px 15px rgba(0,0,0,.5);
      .modal-header{
        border: 0px;
        .close{
          opacity: 1; font-size: 30px; margin: -15px -25px;
          &:focus{
            outline: none;
          }
        }
        h4.modal-title{
          font-size: 25px;
          font-weight: bold;
          text-align: center;
        }
      }
      .modal-body{
        text-align: center; padding: 20px 0 20px;
        span.fill-password-success{
          float: none;
          width: 70px;
          margin: 0 10px;
          height: 70px;
          background: #6ed803;
          outline: 0;
          -webkit-appearance: inherit;
          font: normal normal normal 14px/1 FontAwesome;
          padding: 0px 10px;
          border-radius: 100%;
          content: '\f00c';
          position: relative; display: inline-block;
          &:after{
            content: '\f00c';
            font-size: 40px;
            color: @white-color;
            float: left;
            margin: 15px 6px;
          }
        }
      }
      .modal-footer{
        border: 0;
        margin: 30px 0 20px;
        .btn.btn-default{
          border-radius: 0px; border: solid 1px @sky-start-dark; text-transform: uppercase; font-size:18px; letter-spacing: 1px; font-weight: bold; padding: 10px 45px; color:@sky-start-dark;
          &:hover, &:focus, &.active-tab-close{
            background: @sky-start-dark; color: @white-color;
          }
        }
      }
    }
  }
}
/*
#delete-clinic-modal {
  margin: -30px;
  .modal-dialog {
    width: 600px;
  }
}*/

#confirm-password-modal{
  height: 301px !important;
  overflow: hidden;
}