@import "~stylesheets/mixins.less";
@import "~stylesheets/variables.less";

input:-webkit-autofill{ background: @white-color !important; background-color: @white-color !important; -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;}

#account-setting{
  #full-width; margin-top: 200px; margin-bottom: 20px;
  .account-setting{
    #full-width;
    .profile-left-section{
      #full-width; background: @white-color; padding-top: 0px;
      .profile-form-left{
        #full-width; padding-top: 0px;
        .profile-form-left-row{
          #full-width; margin-bottom: 37px; padding: 0 35px;
          .profile-form-left-col{
            width: 43%; float: left;
            label{
              color: @sky-start-dark;
              margin-bottom: 10px; float: left;
            }
            input {
              width: 100%;
              float: left;
              padding: 5px 10px;
              font-size: 15px;
              text-align: center;
              border: solid 1px @gray-dark;
            }
          }
          .profile-form-left-col:nth-child(2n){
            float: right;
          }
        }
        .profile-form-left-row.change-pass-div{
          .profile-form-left-col{
            label{
              line-height: 34px;
            }
            /*input{
              width: 58%; border: 0px;
            }*/
          }
          .profile-form-left-col{
            margin-top: -10px;
            input[type="button"]{
              color: #fff;
              background: #105c9d;
              font-size: 20px;
              width: 100%;
              text-transform: uppercase;
              white-space: inherit;
              line-height: 22px;
              font-weight: 500;
              &:disabled{
                cursor: not-allowed;
                opacity: 0.6;
              }
            }
            &:nth-child(2){
              input[type="button"] {
                background: url(../../../images/bankid_vector_rgb.svg) 94% 3px no-repeat, #105c9d;
                background-size: 30px;
              }
            }
          }
        }
        .change-profile-picture{
          #full-width; position: relative;
          padding-bottom: 80px;
          img{
            #full-width;
            width: 28%;
            float: left;
            margin-left: 36%;
          }
          input[type="button"]{
            color: #fff;
            background: #105c9d;
            font-size: 20px;
            text-transform: uppercase;
            white-space: inherit;
            line-height: 35px; width: auto; padding: 8px 15px; border: 0;
            font-weight: 500; position: absolute;
            bottom: 15px;
            left: 50%;
            transform: translate(-50%, 0);
          }
        }
      }
    }
    .profile-right-section{
      width: 97%; float: right;
      .profile-right-block-one{
        #full-width; background: @white-color; padding: 45px 35px 65px;
        .head-profile{
          #full-width; padding: 0 35px;
          h2{
            #full-width; text-align: center; background: url(images/border-head-h2.png) 0 center repeat-x;
            span{
              width: auto; padding: 0 28px; background: @white-color; text-transform: uppercase; font-size: 22px; font-weight: 300;
            }
          }
        }
        .profile-form-right{
          #full-width; text-align: center; display: inline-block; margin-top: 25px;
          .profile-form-right-row{
            width: 100%; float: none; text-align: center; display: inline-block; margin-bottom: 63px;
            .profile-form-right-col{
              width: auto; float: left; margin: 0 3%; max-width: 27%;
              label{
                width: 100%;
                color: @sky-start-dark;
                margin-bottom: 10px; float: left; text-align: left;
              }
              input {
                width: 100%;
                float: left;
                padding: 5px 10px;
                font-size: 15px;
                text-align: center;
                border: solid 1px @gray-dark;
              }
              select {
                width: 100%;
                float: left;
                padding: 7px 10px;
                font-size: 15px;
                text-align: center;
                border: 0px;
                background: @gray-light-white;
                &:focus{
                  outline: none;
                }
              }
              .ui-select{
                min-width: 300px;
              }
              .ui-select-container.select2.select2-container{
                cursor: pointer;
                min-width: inherit !important; width: 100%; float: left; position: relative;
                a.select2-choice.ui-select-match{
                  border: solid 1px #979797;
                  width: 100%;
                  float: left;
                  padding: 4px 0;
                  color: #979797;
                  i.fa.fa-angle-down{
                    float: right;
                    padding-top: 5px;
                    padding-right: 10px;
                  }
                }
                .ui-select-dropdown.select2-drop{
                  float: left;
                  width: 100%;
                  border: 1px solid #979797;
                  border-top: 0;
                  position: absolute;
                  left: 0;
                  top: 36px;
                  ul {
                    background: #fff;
                    border-top: solid 0.1px #979797;
                  }
                  .search-container.select2-search{
                    display: none;
                  }
                }
              }
              &.archive-order{
                max-width: 100%;
                margin-top: -70px;
                input {
                  width: 15%;
                  margin-right: 10px;
                  float: left;
                  padding:0;
                  text-align: center;
                  font-weight:700;
                }
                span{
                  font-size: 13px;
                  float:left;
                  margin-top:4px;
                  color: @gray-font-color;
                  margin-right: 15px;
                }
                p {
                  width: 100%;
                  margin-left: 7%;
                  text-align: left;
                  font-size: 13px;
                  line-height: 16px;
                  margin-right: 28%;
                  color: @gray-font-color;
                }
              }
              &.view-settings {
                width: 100% !important;
                max-width: none;
                .switch-button{
                  #full-width;
                  margin-bottom: 10px;
                  .switch.checked  {
                    background: #105c9d;
                    border-color: #105c9d;
                    float: left;
                    margin-right: 20px;
                  }
                  .switch{
                    float: left;
                    margin-right: 20px;
                    background: #d9dadc;
                    width: 42px;
                    height: 22px;
                    small{
                      width: 22px;
                      height: 22px;
                    }
                  }
                  p{
                    float: left;
                    margin-bottom: 0;
                    font-size: 13px;
                    color: #a7a2a2;
                  }
                  input{
                    float: left;
                    margin-right:10px;
                  }
                  span{
                    float: left;
                  }
                }
              }
            }
          }
          .profile-form-right-row.change-pass-div{
            margin-bottom: 10px;
            .profile-form-right-col{
              label{
                line-height: 29px; width: auto; float: left;
              }
              input{
                width: 50%; border: 0px; float: right;
              }
            }
            .profile-form-right-col:nth-child(2){
              margin-top: -10px;
              input[type="button"]{
                color: #fff;
                background: #105c9d;
                font-size: 20px;
                width: 100%;
                text-transform: uppercase;
                white-space: inherit;
                line-height: 22px;
                font-weight: 500;
              }
            }
          }
          span.version-number{
            display: inline-block;
            color: @gray-dark;
            margin-left: 15px;
            font-size: 14px;
          }
        }
      }
      .save-change-btn{
        #full-width; margin-top: 20px;
        input[type="button"]{
          color: #fff;
          background: #105c9d;
          font-size: 20px;
          width: 100%;
          text-transform: uppercase;
          white-space: inherit;
          line-height: 35px;
          font-weight: 500; width: auto; float: right; border: 0px; padding: 8px 25px;
        }
      }
    }
  }
}
.display-inline{
  display: inline;
}

.profile-form-left-col.general-notifications {
  .notifications-title {
    margin-bottom: 10px !important;
  }
  form {
    .switch-button {
      input {
        width: 8% !important;
        margin-left: 5px;
        height: 25px;
        padding: 5px;
      }
    }
    span {
      font-size: 13px;
      line-height: 1.7;
      color: @gray-font-color;
    }
  }
}
.bankId-status {
  text-align: center;
}
