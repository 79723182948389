#navBar{
  .newOrder.add-users{
    float: left;
    border-left: solid 0px #979797;
    border-right: solid 1px #979797;
    padding-left: 0;
    padding-right: 10px;
    margin-right: 10px;
  }
}
#users{
  #full-width; margin-top: 250px;
  .users{
    #full-width;
    ul{
      #full-width;
      li{
        width:29.30%; float:left; margin: 0 2% 30px; background: @white-color; .box-shadow(0 0 5px @gray-font-color); padding-top:14px;
        .clinic-block{
          #full-width;
          .delete-clinic{
            cursor: pointer;
            width: auto; float: right; margin-right:17px; position: relative; z-index: 9;
          }
          .clinic-img{
            #full-width; text-align: center; display: inline-block;
            .round-img-box{
              width: auto; float: none; display: inline-block; border: solid 1px @gray-bg-color; .border-radius(100%); padding: 0px; position: relative;
              img{
                width: 80px; border-radius: 100%;
                height: 80px;
                object-fit: cover;
              }
              .round-active-clinic{
                width: 20px; height: 20px; .border-radius(100%); background: @active-tab-green; float: left; margin: 3px 10px 0 0; position: absolute; bottom: 0px; right: -8px;
              }
              .round-not-active-clinic{
                width: 20px; height: 20px; .border-radius(100%); background: @light-orange; float: left; margin: 3px 10px 0 0; position: absolute; bottom: 0px; right: -8px;
              }
              .round-inactive-clinic{
                width: 20px; height: 20px; .border-radius(100%); background: @dark-red-color; float: left; margin: 2px 10px 0 0; position: absolute; bottom: 0px; right: -8px;
              }
            }
          }
          .delete-clinic + .clinic-img{ margin-top: -15px;}
          h4{
            #full-width; text-align: center; color: @sky-start-dark; margin-top: 15px;
          }
          h3{
            #full-width; text-align: center; color: @post-block-color; margin-top: 15px; margin-top: -5px; font-size: 16px; margin-bottom: -10px;
          }
          p{
            #full-width; text-align: center; color:@gray-font-color; font-weight: 300; font-size: 13px;
          }
          .clinic-activation-block{
            #full-width; background: @gray-bg-color; padding: 20px; margin-top: 20px;
            .clinic-active{
              width: auto; float: left; text-transform: uppercase; font-size: 15px; position: relative; z-index: 8;
              .round-active-clinic{
                width: 20px; height: 20px; .border-radius(100%); background: @active-tab-green; float: left; margin: 2px 10px 0 0;
              }
              .round-not-active-clinic{
                width: 20px; height: 20px; .border-radius(100%); background: @light-orange; float: left; margin: 2px 10px 0 0;
              }
              .round-inactive-clinic{
                width: 20px; height: 20px; .border-radius(100%); background: @dark-red-color; float: left; margin: 2px 10px 0 0;
              }
            }
            .clinic-active-button{
              width: auto; float: right;
              a{
                color: @sky-start-dark; border: solid 1px @sky-start-dark; padding: 3px 5px; margin-left: 0px; font-size: 12px; text-transform: uppercase;
                &:hover, &:focus, &.activation-tab-btn{
                  color: @white-color; background: @sky-start-dark;
                }
              }
            }
          }
        }
      }
    }
  }
}

#add-lab-technician-modal, #remove-user-modal{
  .modal-dialog{
    width: 800px;
    .modal-content{
      padding: 20px 40px 20px 70px;
      border-radius: 0;
      background: @white-color;
      box-shadow: 0 5px 15px rgba(0,0,0,.5);
      .modal-header{
        border: 0;
        .close{
          opacity: 1; font-size: 30px; margin: -15px -25px;
          &:focus{
            outline: none;
          }
        }
        h4.modal-title{
          font-size: 25px;
          font-weight: bold;
          text-align: center;
        }
      }
      .modal-body{
        .add-clinic-form{
          #full-width; margin-top: 20px;
          .add-clinic-form-row{
            #full-width; margin-bottom: 55px;
            h4{
              color: @sky-start-dark; margin-bottom: 20px;
            }
            input{ width: 100%; float: left; min-height: 37px; padding: 8px 10px; font-size:12px;  text-align: center; border: solid 1px @gray-dark;}
            .street-block{
              width: 28%; float: left; margin-right: 5%; margin-top: -15px;
              ul{
                #full-width;
                li{
                  width: auto; float: left; margin-right: 10px; margin-bottom: 10px; padding: 8px 15px; background: @gray-bg-color;
                  cursor: pointer;
                  &.choose-clinic-tab{
                    background: @sky-start-dark; color: @white-color;
                  }
                }
              }
              .street-block-select {
                line-height: 38px;
                min-height: 38px;
                width: 120%;
                font-size: 14px;
                height: 38px;
              }
            }
            .street-block.full-street-div{
              width: 100%;
            }
            label{
              font-weight: normal; font-size: 14px; margin-top: 20px;

              input[type=checkbox] {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background: transparent;
                position: relative;
                visibility: hidden;
              }
              input[type=checkbox],
              input[type=checkbox] + label::before {
                font-size: 18px;
                line-height: 20px;
                cursor: pointer;
                vertical-align: middle;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -ms-border-radius: 5px;
                -o-border-radius: 5px;
                border-radius: 5px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                -o-user-select: none;
                user-select: none;
              }
              input[type=checkbox] + label::before {
                content: '';
                text-align: center;
                display: inline-block;
                pointer-events: none;
                opacity: 1;
                color: black;
                transition: all .3s ease;
                border: 2px solid @sky-start-dark;
                width: 23px;
                height: 23px;
                margin-right: 15px;
              }
              input[type=checkbox] + label {
                line-height: 23px;
                margin: 0 15px 0 15px;
              }
              input[type=checkbox]:hover {
                cursor: pointer;
              }
              input[type=checkbox]:hover + label::before {
                content: '';
              }
              input[type=checkbox]:checked + label::before {
                content: '\2714'; font-size: 14px; color:@sky-start-dark; text-align: center;
              }
              input[type=checkbox]:checked:hover + label::before {
                opacity: 1;
              }

              /*input[type="checkbox"].notCheckedInput{
                float: left;
                width: 25px;
                margin: 0px 10px 0px 0px;
                height: 25px;
                background: none;
                border: 2px solid @sky-start-dark;
                outline: none;
                -webkit-appearance: inherit; padding: 10px; border-radius: 4px;
              }
              input[type="checkbox"].checkedInput{
                float: left;
                width: 25px;
                margin: 0px 10px 0px 0px;
                height: 25px;
                background: none;
                border: 2px solid @sky-start-dark;
                outline: none;
                -webkit-appearance: inherit; position: relative; font: normal normal normal 14px/1 FontAwesome; padding: 10px; border-radius: 4px;
              }
              input[type="checkbox"].checkedInput:after{
                content: '\f00c'; position: absolute; top: 2px; left: 2px; font-size: 15px; color: @sky-start-dark;
              }*/
            }
          }
        }
      }
      .modal-footer{
        border: 0;
        margin: 30px 0 20px;
        .btn.btn-default{
          border-radius: 0px; border: solid 1px @sky-start-dark; text-transform: uppercase; font-size:18px; letter-spacing: 1px; font-weight: bold; padding: 10px 45px; color:@sky-start-dark;
          &:hover, &:focus, &.active-tab-close{
            background: @sky-start-dark; color: @white-color;
          }
        }
      }
    }
  }
  .check-icons i {
    background: #105c9d;
    color: @white-color;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    padding-top: 3px;
    text-align: center;
    position: absolute;
    top: 71px;
    right: 29px;
    font-size: 10px;
  }
}