.highlighted-text {
  background: #eee;
}

.search-digital-sample {
  height: 25px;
  margin-left: 10px;
}

.search-lab-flag-icon {
  font-size: 18px;
  color: #ff0000;
}
