#section-page {
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 35px;
  padding: 18px 61px 50px;
}
#section-heading {
  float: left;
  font-size: 20px;
  color: #1f2532;
  font-weight: 700;
  border-bottom: solid 1px #1f2532;
  width: 100%;
  line-height: 50px;
}
#full-width {
  width: 100%;
  float: left;
}
#rate-it {
  /* Background */
  /* Selected state */
  /* Cancelation of hover styling */
  /* Hover styling */
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../../../../images/rated-star.svg");
}
#rate-it .ngrateit-reset {
  background-image: url("../../../../images/cancel-icon.svg");
}
#rate-it .ngrateit-bg-star {
  background-image: url("../../../../images/bg-star.svg");
}
#rate-it .ngrateit-selected {
  background-image: url("../../../../images/rated-star.svg");
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating span:hover ~ span {
  background-image: url("../../../../images/bg-star.svg");
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../../../../images/rated-star.svg");
}
#add-image-modal .modal-dialog {
  width: 60%;
}
#add-image-modal .modal-dialog .modal-content {
  padding: 20px 40px 20px 70px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
#add-image-modal .modal-dialog .modal-content .modal-header {
  border: 0px;
}
#add-image-modal .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  font-size: 30px;
  margin: -15px -25px;
}
#add-image-modal .modal-dialog .modal-content .modal-header .close:focus {
  outline: none;
}
#add-image-modal .modal-dialog .modal-content .modal-header label.modal-title {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  color: #105c9d;
  width: 100%;
}
#add-image-modal .modal-dialog .modal-content .modal-body label {
  display: flex;
  max-width: 100%;
  margin-bottom: 5px;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  min-height: 47px;
  text-align: center;
}
#add-image-modal .modal-dialog .modal-content .modal-body p.red-astrix-mark {
  flex-grow: 0;
  max-width: 20px;
  padding: 0;
  margin-bottom: 15px;
}
#add-image-modal .modal-dialog .modal-content .modal-body input {
  width: 100%;
}
#add-image-modal .modal-dialog .modal-content .modal-body .template-image {
  width: 100%;
  border: 1px solid #333;
  cursor: pointer;
}
#add-image-modal .modal-dialog .modal-content .modal-body .template-image.selected {
  opacity: 0.8;
  border: 4px solid #105c9d;
}
#add-image-modal .modal-dialog .modal-content .modal-footer {
  border: none !important;
}
#add-image-modal .modal-dialog .modal-content .modal-footer .btn.btn-default {
  border-radius: 0px;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 10px 45px;
  color: #105c9d;
}
#add-image-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#add-image-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#add-image-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close {
  background: #105c9d;
  color: #fff;
}
