@import "~stylesheets/mixins.less";
@import "~stylesheets/variables.less";

.material-lists {
  .add-new-material-list {
    width: auto;
    float: right;
    border: solid 1px #105c9d;
    color: #fff;
    background: #105c9d;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    height: 36px;
    margin-left: 15px;
    min-width: 60px;
  }

  .material-list-dropdown-section {
    margin-top: 30px;
    .ng-select {
      width: 250px;
      float: left;
    }
    &.edit-section {
      display: flex;
      .edit-btn {
        margin-left: auto;
      }
    }
  }
  .indicate-circle-black, .indicate-circle-gray {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #000;
  }
  .indicate-circle-gray {
    background: #aaa;
  }
  .material-list-dropdown-section.edit-section .ng-dropdown-panel .ng-dropdown-panel-items .ng-option, .material-list-dropdown-section.edit-section .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .material-search-filter {
    margin-top: 30px;
    margin-bottom: 20px;
    input {
      border: 0;
      background: #eee;
      padding: 5px 5px 5px 35px;
      width: 100%;
    }
    i {
      position: absolute;
      left: 10px;
      top: 10px;
    }
  }

  .material-list-item {
    table {
      width: 100%;
      th {
        background: #eee;
        font-size: 14px;
      }
      tbody {
        tr {
          cursor: pointer;
          &:hover {
            background: #eee;
          }
          &.disabled-td {
            td {
              color: #aaa;
            }
          }
        }
      }
      td, th {
        padding: 12px 5px;
        text-align: center;
        color: #000;
        border-bottom: solid 1px #ccc;
        &:nth-child(2) {
          text-align: left;
        }
      }
    }
  }

}

#add-material-list-modal, #add-material-modal, #add-action-list-modal, #add-category-modal, #add-actionList-action-modal {
  .action-no-name {
    display: flex;
    h4 {
      line-height: 40px;
      p {
        margin-bottom: 0;
      }
    }
    textarea {
      width: 100%;
    }
  }
  .commet-export-production {
    input[type="checkbox"] {
      width: auto !important;
      min-height: 20px !important;
      &:checked.checkedInput:after,&:after {
        top: 2px !important;
        left: 3px !important;

      }
    }
  }
}

#add-category-modal{
  .modal-dialog {
    width: 550px !important;
  }
  .modal-dialog .modal-content .modal-footer button {
    float: right !important;
  }
}

#add-actionList-action-modal .modal-dialog {
  width: 900px !important;
}

#add-actionList-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .category-select input {
  border: 0 !important;
  padding: 0 !important;
  margin-top: -7px !important;
  text-align: left !important;
}
