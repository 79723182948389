@media (min-width: 1200px) {
  .container {
    width: 1222px;
  }
}

@media screen and (max-width: 767px) {
  //Patient section table start
  .patientExistingorder .checkBox span {
    font-size: 12px;
  }
  #patient-section.order-two-part span.check-icons i {
    top: 30px;
    right: 4px;
  }
  #patient-section.order-two-part .SearchPatient ul.four-column li {
    width: 47%;
  }
  #patient-section.order-two-part .SearchPatient ul li label {
    font-size: 12px;
  }
  #patient-section .SearchPatient .patientExistingorder .mobi-manage-content {
    width: 100%;
    display: inline-block;
    padding-bottom: 5px;
  }
  #patient-section
    .SearchPatient
    .patientExistingorder
    .mobi-manage-content
    .font-manage {
    font-size: 14px;
    padding: 0 10px;
    width: 50% !important;
    text-align: left !important;
  }
  #patient-section
    .SearchPatient
    .patientExistingorder
    .mobi-manage-content:last-child
    .col-lg-2 {
    width: 50% !important;
  }
  //Patient section table end
  .header-fixed {
    position: inherit;
  }

  order-new {
    .row {
      margin: 15px 0 0;
    }
  }
  #patient-section {
    span.check-icons {
      i {
        padding-left: 3px;
        top: 33px;
        right: -17px;
        font-size: 10px;
        padding-top: 3px;
      }
    }
    .patientLab {
      margin-top: 0;
      padding: 0;
    }
    h1.patientTitle {
      font-size: 15px;
      line-height: 35px;
    }
    .SearchPatient {
      padding: 25px 10px;
      h1 {
        font-size: 15px;
      }
      ul {
        width: 100%;
        li {
          width: 100%;
          margin-top: 10px;
          margin-right: 0;
          text-align: left;
          label {
            margin-bottom: 2px;
          }
          input.form-control {
            text-align: left;
          }
        }
      }
      .col-lg-2 {
        width: 100% !important;
        .chooseLAb {
          widows: 100%;
          margin-top: 5px;
        }
      }
      + .SearchPatient {
        text-align: center;
        display: inline-block;
        .col-lg-2 {
          width: auto !important;
          max-width: 110px;
          padding-right: 5px;
          float: none;
          display: inline-block;
        }
      }
    }
  }
  #construction-section {
    padding-top: 0;
    .cross-rectangle-post {
      width: 12px;
      height: 12px;
      bottom: -15px;
      right: 9.5px;
      line-height: 12px;
      font-size: 8px;
    }
    .cross-square {
      width: 12px;
      height: 12px;
      top: -9px;
      right: -8px;
      line-height: 12px;
      font-size: 8px;
    }
    .margin-top {
      margin-top: -10px !important;
    }
    .row {
      margin: 0;
    }
    h1.patientTitle {
      font-size: 15px;
      line-height: 35px;
    }
    .Constructions {
      margin-top: 0;
      padding: 0;
    }
    .SearchPatient {
      padding: 10px 0 0;
      ul {
        text-align: center;
        display: inline-block;
        li {
          margin-bottom: 10px;
          max-width: 130px;
          float: none;
          display: inline-block;
          a {
            font-size: 12px;
            line-height: 14px;
            border-bottom: solid 2px #fff;
          }
          &.active {
            a {
              font-weight: normal;
              border-bottom-width: 2px;
            }
          }
        }
      }
      &.margin-less-top {
        .constructions-sub-types {
          ul {
            display: block;
            li {
              max-width: inherit;
              float: left;
              display: block;
            }
          }
        }
      }
    }
    .divider-new {
      h1 {
        margin-top: 0;
        font-size: 18px;
      }
    }
    .clickonBoth {
      width: 100%;
      margin-left: 0;
      .well-sm {
        .range {
          display: none;
        }
        .btn-group {
          margin-bottom: 10px;
        }
      }
    }
    .margin-top-45 {
      margin-top: 5px !important;
    }
    .SelectMaterial {
      h3 {
        margin-top: 0;
        line-height: 24px;
      }
      ul {
        margin-top: 0;
        li {
          width: 32%;
          margin-right: 1.33%;
          margin-bottom: 3px;
          a {
            padding: 6px 3px;
            font-size: 11px;
          }
        }
      }
      .designBox {
        ul {
          li {
            width: 100%;
          }
        }
      }
      &.designBox {
        ul {
          li {
            margin-bottom: 3px;
          }
        }
      }
    }
    .form-group {
      .other-material {
        width: 100%;
      }
    }
    ul.singleSub {
      li {
        width: 49%;
        margin: 1px 0.5%;
      }
    }
    #bridgeTeeth {
      .row {
        margin: 0 -25px;
      }
    }
    .bridge-suggestion,
    .partial-suggestion {
      margin-left: 0;
      width: 100%;
      border-radius: 5px;
      padding: 5px;
      font-size: 12px;
      font-weight: normal;
    }
  }
  #delivery-section {
    padding-top: 0;
    .DeliveryInformation {
      margin-top: 0;
      padding: 0;
    }
    h1.patientTitle {
      font-size: 15px;
      line-height: 35px;
    }
    .DelInf {
      width: 100%;
      margin-left: 0;
    }
    .deliveryDate {
      margin-top: 10px;
      h3 {
        font-size: 14px;
        font-weight: normal;
      }
      ul {
        margin-top: 0;
      }
    }
    .form-group {
      margin-right: 15px;
      float: left;
      width: 90%;
      margin-top: 0;
      label {
        font-size: 14px;
        font-weight: normal;
        margin-top: 10px;
      }
      &.commet-export-production {
        .col-lg-12 {
          padding: 0;
        }
        p {
          font-size: 12px;
          margin-bottom: 0;
        }
      }
    }
  }
  #images-section {
    padding-top: 0;
    .upload-images {
      margin-top: 15px;
      padding: 0 0 15px;
    }
    h1.patientTitle {
      font-size: 15px;
      line-height: 35px;
    }
    .images-upload {
      float: left;
      width: 100%;
      margin-bottom: 0;
      margin-left: 0;
      margin-top: 10px;
      padding: 25px 0;
      font-size: 14px;
      img {
        width: 40px;
      }
    }
    .row {
      width: 100%;
      float: left;
      margin: 0;
      span.Images-circle {
        float: left;
        width: 50px;
        margin: 10px 0 0;
        display: block;
        height: inherit;
      }
      .images-file-name {
        width: 71%;
        float: left;
        margin: 5px 0 0 10px;
        padding: 0;
        text-align: left;
        font-size: 12px;
        word-wrap: break-word;
      }
      .file-icon {
        margin: 0;
        width: 50px;
        i {
          margin: 10px 0 0;
          font-size: 2em;
        }
      }
    }
  }
  #sign-section {
    padding-top: 0;
    h1.patientTitle {
      font-size: 15px;
      line-height: 35px;
    }
    .signSend {
      margin-top: 15px;
      padding: 0 0 15px;
      margin-bottom: 15px;
    }
    .signSendbox {
      h1 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;
      }
      p {
        font-size: 13px;
      }
      img {
        width: 50px;
        margin: 20px 0 30px;
      }
      .sign-with-img-icon {
        span.right-sign-all-select {
          width: 30px;
          height: 30px;
          padding: 15px;
          bottom: 15px;
          right: -25px;
          &:after {
            top: 6px;
            left: 5px;
            font-size: 20px;
          }
        }
      }
    }
  }

  #order-confirm {
    .order-confirm {
      margin-top: 20px;
      h1 {
        font-size: 22px;
      }
      .order-details {
        width: 100%;
        padding: 5px;
        span {
          font-size: 13px;
          margin-top: 5px;
        }
      }
      .order-button {
        a {
          font-size: 15px;
        }
      }
      .order-message {
        p {
          width: 100%;
        }
      }
    }
  }

  #denthub-header {
    padding: 5px 0 5px;
    .no-padding-default {
      padding: 0;
      .col-md-4.col-lg-3.col-sm-5.col-xs-12.text-left {
        text-align: center;
      }
      #imaginary_container {
        width: 100%;
        margin: 0 auto;
        .searchBg {
          background: none;
          .form-control {
            height: 36px;
            display: none;
          }
          span.input-group-addon {
            background: none !important;
            padding: 9px 0;
            color: @white-color;
            text-align: right;
            button {
              &:focus {
                outline: none;
              }
              span + span {
                display: none;
              }
            }
          }
          &.search-open-big {
            position: fixed;
            left: 0;
            top: -5px;
            background: #000;
            z-index: 9;
            width: 100%;
            height: 45px;
            border-bottom: solid 1px #f7f8f9;
            span.input-group-addon {
              background: #f7f8f9 !important;
              padding: 15px 0;
              position: absolute;
              right: 0;
              top: 5px;
              width: 50px;
              z-index: 9;
              color: #000;
              text-align: center;
              button {
                span {
                  display: none;
                }
                span + span {
                  display: block;
                }
              }
            }
            .form-control {
              display: block;
              line-height: 50px;
              height: 49px;
              padding: 10px 10px 0;
              min-height: 49px;
            }
          }
        }
      }
      #Notifications {
        .btn-default {
          padding: 6px 0;
          span.position-relative {
            span.Notifications-notes {
              top: -24px;
            }
          }
        }
        .btn:active {
          box-shadow: none;
        }
        ul.dropdown-menu {
          left: -230px;
          top: 40px;
          max-width: 250px;
          border-top: 0;
        }
        &.open {
          button {
            &:after {
              left: -2px;
              top: 15px;
            }
          }
        }
      }
      #menu-profile {
        margin: 0 11px 0 0;
        .btn-default {
          padding: 6px 0;
        }
        .btn:active {
          box-shadow: none;
        }
        ul.dropdown-menu {
          margin: 0 -65px !important;
        }
      }
      .notificationTitle {
        text-align: center;
      }
      .dropdown.open {
        .dropdown-menu {
          min-width: 100% !important;
          left: -105px;
          top: 39px;
          &:after {
            left: initial;
            right: 0;
          }
          li {
            text-align: left;
            a {
              padding: 7px 10px !important;
              font-size: 14px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            h1 {
              text-align: left;
            }
          }
        }
      }
      .notificationTitle {
        font-size: 0;
        i {
          font-size: 20px;
        }
      }
      .dropdown {
        float: right;
        margin-top: 0;
        .btn-default {
          font-size: 0;
          i {
            font-size: 20px;
            margin-right: 10px;
          }
        }
      }
    }
    a.dentHub-first-part {
      img {
        height: 35px;
      }
    }
    a.dentHub-second-part {
      img {
        height: 17px;
        margin-left: 7px;
      }
    }
    .dropdown-menu {
      li {
        a {
          &:focus {
            text-align: left;
          }
        }
      }
    }
    .fa-arrow-left.arrow-left {
      margin: 9px -68px 0;
    }
    #menu-profile {
      ul.dropdown-menu {
        li.User-icons {
          span.user-icons-img {
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  #navBar {
    .container {
      position: relative;
      .navbar.navbar-default {
        position: static;
        .navbar-header {
          button {
            border: 0;
            span {
              background-color: #fff;
            }
            &:focus,
            &:hover {
              background: none;
            }
          }
        }
        .navbar-collapse {
          position: absolute;
          background: #1f2532;
          z-index: 99;
          width: 220px;
          top: 53px;
          ul.nav {
            margin: 0;
            li {
              a {
                padding: 11px 15px;
                border-bottom: solid 0 #1f2532;
                text-align: left;
                font-size: 13px;
                line-height: 33px;
                &:hover {
                  border-bottom: solid 0 #105c9d;
                }
              }
              &.dentist-order-manage {
                a {
                  width: 100%;
                  padding: 15px 14px;
                  line-height: inherit;
                }
                &:first-child {
                  a {
                    width: 100%;
                    padding: 15px 14px;
                    line-height: inherit;
                    i {
                      margin-right: 10px;
                    }
                  }
                }
              }
              &.lab-order-manage {
                a {
                  width: 100%;
                  padding: 15px 14px;
                  line-height: inherit;
                }
                &:first-child {
                  a {
                    width: 100%;
                    padding: 15px 14px;
                    line-height: inherit;
                    i {
                      margin-right: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  #order-status-view {
    max-width: 350px;
    float: none;
    margin: 20px auto;
    .no-padding-lr {
      .no-padding-l {
        padding: 0 5px;
        margin-bottom: 15px;
      }
      .mid-sec-status {
        padding: 0 5px;
        margin-bottom: 15px;
        h1 {
          &:before {
            border-left: 0 solid #eee;
          }
        }
      }
      .no-padding-r {
        padding: 0 5px;
        margin-bottom: 15px;
        .Sent-by-dentist {
          h1 {
            &:before {
              border-left: 0 solid #eee;
            }
            &:after {
              border-left: 16px solid #105c9d;
              border-top: 22px solid transparent;
              border-bottom: 22px solid transparent;
            }
          }
        }
      }
    }
  }

  #order-list {
    background: none;
    min-height: inherit;
    .order-list {
      margin-top: 20px;
      border: 0;
      background: none;
      .no-padding-default {
        .order-list-hading {
          padding: 0;
        }
        .order-list-content {
          padding: 0;
          .mobi-manage-content {
            text-align: left;
            padding: 5px 0;
            width: 100%;
            float: left;
            word-wrap: break-word;
            border-bottom: solid 1px #f1f2f3;
            .font-manage {
              font-size: 14px;
              padding: 0 10px;
              width: 50%;
              br {
                display: none;
              }
              p {
                padding-left: 0;
              }
              .lab-flag-icon {
                left: -25px;
              }
            }
          }
          .mobi-manage-content:last-child {
            border: 0;
          }
        }
      }
      .order-list-box.no-padding-default {
        padding: 10px 15px;
        float: left;
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

  #my-modal-compatible {
    .modal-dialog {
      width: 99%;
      .modal-content {
        padding: 0 10px;
        .modal-footer {
          .btn.btn-default {
            font-size: 12px;
            padding: 15px 5px;
            float: left;
            width: 100%;
            margin-bottom: 5px;
            margin-left: 0 !important;
          }
        }
      }
    }
  }

  #clinics,
  #users-lab,
  #users {
    margin-top: 20px;
    .clinics,
    .users-lab,
    .users {
      ul {
        text-align: center;
        display: inline-block;
        li {
          width: 100%;
          margin: 0 0 10px;
          min-width: inherit;
          float: none;
          overflow: hidden;
          display: inline-block;
          max-width: 375px;
          .clinic-block {
            h3 {
              margin-bottom: 10px;
            }
            .clinic-activation-block {
              padding: 10px 5px;
              margin-top: 5px;
              .clinic-active {
                font-size: 12px;
                line-height: 20px;
                .round-active-clinic {
                  margin-top: -1px;
                }
              }
              .clinic-active-button {
                a {
                  padding: 0 5px;
                  margin-top: -2px;
                  float: left;
                  font-size: 12px;
                  margin-left: 1px;
                }
              }
            }
            ul.multiple-check-organization {
              li {
                min-width: inherit;
              }
            }
          }
        }
      }
    }
  }

  #add-clinic-modal,
  #add-user-modal,
  #add-user-lab-modal,
  #add-lab-modal {
    .modal-dialog {
      width: 95%;
      margin: 10px 2.5%;
      .modal-content {
        padding: 0 10px;
        .modal-header {
          h4.modal-title {
            font-size: 18px;
          }
          .close {
            margin: -10px -15px;
          }
        }
        .modal-body {
          .add-clinic-form {
            margin-top: 5px;
            margin-bottom: 5px;
            .add-clinic-form-row {
              margin-bottom: 35px;
              .add-clinic-form-col {
                width: 100%;
                float: left;
                margin-top: 10px;
              }
              h4 {
                margin-bottom: 5px;
                font-size: 12px;
              }
              .street-block {
                width: 100%;
                margin-top: 10px;
                margin-right: 0;
                &.postcode-input {
                  width: 70%;
                  margin-right: 0;
                }
                ul {
                  li {
                    margin: 2px;
                    padding: 2px 5px;
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
        .modal-footer {
          display: inline-block;
          text-align: right;
          width: 100%;
          padding: 10px 0 10px;
          margin: 0;
          .btn.btn-default {
            padding: 5px;
            font-size: 12px;
            letter-spacing: 0.5px;
            font-weight: normal;
            margin: 5px 0;
          }
        }
      }
    }
  }

  #connect-lab {
    margin-top: 20px;
    .connect-lab {
      .profile-left-section {
        .profile-form-left-row {
          padding: 0 20px;
        }
      }
      .profile-right-section {
        width: 100%;
        margin-right: 0;
        .profile-right-block-one {
          .profile-form-right {
            .jobs-div {
              width: 100%;
            }
            .select-days-div {
              .jobs-div-row {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  #account-setting {
    margin-top: 15px;
    .account-setting {
      .profile-left-section {
        .profile-form-left {
          .change-profile-picture {
            input[type='button'] {
              bottom: 5px;
              right: 5px;
              padding: 0 10px;
              font-size: 12px;
            }
          }
        }
      }
      .profile-right-section {
        width: 100%;
        .profile-right-block-one {
          padding: 20px 10px;
          .head-profile {
            padding: 0;
            h2 {
              span {
                padding: 0 5px;
                font-size: 18px;
              }
            }
          }
          .profile-form-right {
            .profile-form-right-row {
              margin-bottom: 30px;
              .profile-form-right-col {
                width: 100%;
                max-width: 100%;
                margin: 0 0 10px;
                label {
                  margin-bottom: 5px;
                }
                &.archive-order {
                  p {
                    margin-left: 0;
                    font-size: 10px;
                    margin-right: 0;
                    line-height: 13px;
                  }
                  span {
                    font-size: 11px;
                    margin-right: 9px;
                    margin-bottom: 10px;
                  }
                }
                &.view-settings {
                  margin-top: -70px;
                  .switch-button {
                    display: flex;
                    P {
                      float: none;
                    }
                  }
                }
              }
            }
          }
        }
        .save-change-btn {
          input[type='button'] {
            padding: 2px 15px;
            font-size: 12px;
          }
        }
      }
    }
    > .container {
      padding: 0;
    }
  }

  #profile-dentist {
    margin-top: 15px;
    .profile-dentist {
      .profile-left-section {
        padding-top: 15px;
        .head-profile {
          padding: 0;
          h2 {
            span {
              padding: 0 5px;
              font-size: 18px;
            }
          }
        }
        .profile-form-left {
          .profile-form-left-row {
            margin-bottom: 0;
            padding: 0 15px;
            .profile-form-left-col {
              width: 100%;
              margin-bottom: 20px;
            }
            &.change-pass-div {
              .profile-form-left-col {
                &:nth-child(2) {
                  input[type='button'] {
                    font-size: 15px;
                  }
                }
                &.extra-top-margin-add {
                  margin-top: -20px !important;
                }
              }
            }
          }
          .change-profile-picture {
            input[type='button'] {
              font-size: 14px;
              padding: 0 10px;
              bottom: 5px;
              right: 5px;
            }
          }
        }
        .notifications {
          padding-top: 15px;
          .profile-form-left {
            .profile-form-left {
              padding: 0 10px;
              .profile-form-left-col {
                .switch-button {
                  .switch {
                    margin-right: 8px;
                    &.checked {
                      margin-right: 8px;
                    }
                  }
                  p {
                    width: 76%;
                  }
                  input {
                    width: 44px !important;
                    margin: 0 8px 0 0;
                    padding: 0;
                  }
                }
              }
            }
            &:last-child {
              min-height: inherit;
            }
          }
        }
      }
      .profile-right-section {
        width: 100%;
        .profile-right-block-one {
          padding: 15px 10px 10px;
          .head-profile {
            padding: 0;
            h2 {
              span {
                padding: 0 5px;
                font-size: 18px;
              }
            }
          }
          .profile-form-right {
            .profile-form-right-row {
              .profile-form-right-col {
                width: 100%;
                max-width: inherit;
                margin-bottom: 20px;
                &.default-clinic-right-col {
                  width: 100% !important;
                  margin: 0;
                  &.top-margin {
                    .switch-button {
                      p {
                        width: 76%;
                        text-align: left;
                      }
                      .switch {
                        margin-right: 8px;
                        &.checked {
                          margin-right: 8px;
                        }
                      }
                    }
                    &.auto-archive {
                      span {
                        font-size: 12px;
                        margin-right: 10px;
                        margin-bottom: 30px;
                      }
                      p {
                        font-size: 11px;
                        line-height: 13px;
                        margin-right: 0;
                        margin-left: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .profile-right-block-two {
          padding: 10px;
          margin-top: 15px;
          .head-profile {
            padding: 0;
            h2 {
              span {
                padding: 0 5px;
                font-size: 18px;
              }
            }
          }
          .profile-form-right {
            .profile-form-right-row {
              .profile-form-right-col {
                width: 100%;
                max-width: inherit;
                margin: 5px 0;
              }
            }
          }
        }
      }
    }
    .container {
      padding: 0;
    }
  }

  #login-page {
    .card-container.card {
      margin: 20px auto;
      label + .ui-select-container.select2.select2-container + label {
        width: 100%;
        span {
          float: right;
        }
      }
      label + .ui-select-container.select2.select2-container {
        text-align: right;
      }
    }
  }

  #connect-to-lab {
    margin-top: 15px;
    .connect-to-lab {
      .search-box-lab {
        .search-input {
          width: 100%;
          tags-input {
            width: 90%;
            top: 0;
            left: 10%;
          }
          i.fa.fa-search {
            font-size: 14px;
            left: 12px;
            top: 13px;
          }
          input[type='search'] {
            width: 100%;
            height: 42px;
            line-height: 42px;
          }
        }
        input[type='button'] {
          width: 100%;
          height: 42px;
          line-height: 42px;
        }
      }
      .lab-report-section {
        overflow-x: scroll;
        .lab-report-head {
          width: 1192px;
        }
        .lab-report-body {
          width: 1192px;
        }
      }
      .pagination-center {
        margin: 0 0 20px;
        li {
          a {
            padding: 3px 5px;
            font-size: 12px;
          }
        }
      }
    }
  }

  #lab-profile {
    margin-top: 15px;
    .lab-profile {
      .profile-left-section {
        padding-top: 20px;
        .head-profile {
          padding: 0;
          h2 {
            span {
              padding: 0 5px;
              font-size: 18px;
            }
          }
        }
        .profile-form-left {
          .profile-form-left-row {
            padding: 0 10px;
            &.document-section {
              .document-div {
                padding: 3px;
                .document-div-left {
                  .upload-file-name {
                    padding: 5px 0 0;
                    min-height: 30px;
                    max-height: 30px;
                    width: 80%;
                    a.link-icon:before,
                    a.link-icon.link-file[href]:before {
                      margin-top: 3px;
                    }
                  }
                  img {
                    margin-top: 6px;
                    width: 11px;
                  }
                }
                .document-div-right {
                  .upload-file-div {
                    width: auto;
                    font-size: 12px;
                    .filechoose-div {
                      padding: 0 5px;
                    }
                  }
                  span {
                    font-size: 11px;
                  }
                }
              }
            }
          }
          .profile-form-left-row {
            &.current-jobs-section {
              .jobs-div {
                .jobs-div-row {
                  label {
                    width: 50%;
                    margin-left: 0;
                    margin-top: 0;
                    input {
                      margin: -1px 5px 0 0;
                    }
                    p {
                      font-size: 11px;
                    }
                  }
                  .delivery-section {
                    width: 50%;
                    input {
                      padding: 1px 0;
                      font-size: 12px;
                    }
                    span {
                      width: auto;
                      margin-bottom: 0;
                      font-size: 11px;
                      line-height: 22px;
                      margin-top: 0;
                    }
                    .tooltip.ng-scope.ng-isolate-scope.top.file-name-tooltip.fade.in {
                      width: 130px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .profile-right-section {
        width: 100%;
        margin-top: 15px;
        .profile-right-block-one {
          padding: 20px 5px;
          .head-profile {
            padding: 0;
            h2 {
              span {
                padding: 0 5px;
                font-size: 18px;
              }
            }
          }
          .media-section {
            .media-section-block {
              width: 100%;
              &:first-child {
                margin-bottom: 25px;
              }
            }
          }
        }
        .profile-right-block-two {
          padding: 20px 5px;
          margin-top: 15px;
          .head-profile {
            padding: 0;
            h2 {
              span {
                padding: 0 5px;
                font-size: 18px;
              }
            }
          }
          .media-section {
            .media-section-block {
              width: 100%;
              .select-days-div {
                .jobs-div-row {
                  padding-left: 5px;
                  label {
                    width: 75px;
                    input[type='checkbox'] {
                      &.checkedInput {
                        width: 100%;
                        height: 30px;
                      }
                      &.notCheckedInput {
                        width: 100%;
                        height: 30px;
                      }
                      + span {
                        line-height: 30px;
                        font-size: 14px;
                      }
                    }
                  }
                  .delivery-section {
                    width: 70%;
                    span {
                      font-size: 12px;
                      line-height: 31px;
                    }
                    .ng-isolate-scope {
                      tr {
                        td {
                          input {
                            line-height: 28px;
                            min-height: 28px;
                            height: 28px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .media-section-block:nth-child(2) {
              width: 100%;
              margin-top: 25px;
              .contact-form {
                .contact-form-row {
                  i {
                    width: 30px;
                  }
                }
              }
            }
          }
          .calendar-section {
            padding-left: 0;
            .btn-sm {
              padding: 5px 8px;
            }
          }
        }
      }
      .notifications {
        .profile-left-section {
          .head-profile {
            h2 {
              margin-top: 20px;
            }
          }
          .profile-form-left {
            padding: 0 15px !important;
            .profile-form-left-col {
              .user-email {
                .checkbox-inline {
                  p.email-noti-para {
                    width: 80%;
                  }
                }
              }
            }
            .profile-form-left-Select {
              .switch-button {
                .switch {
                  margin-right: 8px;
                  &.checked {
                    margin-right: 8px;
                  }
                }
                p {
                  width: 76%;
                }
              }
            }
          }
        }
      }
    }
    .container {
      padding: 0;
    }
  }

  #add-lab-technician-modal,
  #invite-lab,
  #add-lab-modal,
  #delete-clinic-modal,
  #remove-user-modal,
  #cancel-order,
  #archive-order,
  #accept-order,
  #clinic-received-delivery,
  #lab-work-started,
  #decline-order,
  #change-password-modal {
    padding-left: 0 !important;
    .modal-dialog {
      width: 96%;
      margin: 10px 2%;
      .modal-content {
        padding: 0 15px;
        .modal-header {
          h4.modal-title {
            font-size: 18px;
          }
        }
        .modal-body {
          padding: 0;
          .add-clinic-form {
            .add-clinic-form-row {
              margin-bottom: 30px;
              .street-block {
                width: 100%;
                margin: 10px 0 0;
                position: relative;
                ul {
                  li {
                    margin: 2px;
                    padding: 2px 5px;
                    font-size: 12px;
                  }
                }
                h4 {
                  margin-bottom: 3px;
                  font-size: 13px;
                }
                input {
                  padding: 4px 4px;
                  font-size: 11px;
                }
                .check-icons {
                  i {
                    padding-top: 3px;
                    top: 23px;
                    right: 1px;
                    font-size: 10px;
                    padding-left: 3px;
                  }
                }
              }
              label {
                margin-top: 0;
                input[type='checkbox'] + label {
                  margin: 0;
                }
              }
            }
            p {
              font-size: 12px;
            }
          }
        }
        .modal-footer {
          display: inline-block;
          text-align: right;
          width: 100%;
          padding: 10px 0 10px;
          margin: 0;
          .btn.btn-default {
            padding: 5px;
            font-size: 12px;
            letter-spacing: 0.5px;
            font-weight: normal;
            margin: 5px 0;
          }
        }
      }
    }
  }
  #cancel-order {
    .modal-dialog {
      .modal-content {
        .modal-header {
          .close {
            margin: -5px -14px;
          }
        }
      }
    }
  }
  #archive-order,
  #un-archive-order {
    .modal-dialog {
      .modal-content {
        .modal-header {
          .close {
            margin: -5px -14px;
          }
        }
        .modal-body {
          padding-top: 30px;
          padding-bottom: 40px;
        }
      }
    }
  }
  #change-password-modal {
    margin: 0 20px;
    .modal-dialog {
      .modal-content {
        .modal-body {
          .add-clinic-form {
            width: 90%;
          }
        }
      }
    }
  }
  #invite-lab {
    .modal-dialog {
      .modal-content {
        .modal-header {
          .close {
            margin: -3px -10px;
          }
        }
        .modal-body {
          overflow: hidden;
          padding-top: 15px;
          .add-clinic-form {
            h5 {
              font-size: 15px;
              font-weight: normal;
              text-align: left;
            }
          }
        }
      }
    }
  }
  #rate-order {
    .modal-dialog {
      width: 96%;
      margin: 10px 2%;
      .modal-content {
        padding: 15px 0px 5px;
        .modal-header {
          .close {
            margin: -15px 7px;
          }
        }
        .modal-body {
          p {
            font-size: 12px;
          }
          .row.auto-center-div {
            margin: 0;
            .col-md-2 {
              width: 100%;
            }
          }
        }
        .modal-footer {
          display: inline-block;
          text-align: right;
          width: 100%;
          padding: 25px 10px 10px;
          margin: 0;
          .btn.btn-default {
            padding: 5px;
            font-size: 12px;
            letter-spacing: 0.5px;
            font-weight: normal;
            margin: 5px 0;
          }
        }
      }
    }
    &.one-rate-option,
    &.two-rate-option,
    &.three-rate-option,
    &.four-rate-option {
      .modal-dialog {
        width: 96%;
        margin: 10px 2%;
        .modal-content {
          padding: 15px 0px 5px;
          .modal-header {
            .close {
              margin: -15px 7px;
            }
          }
          .modal-body {
            p {
              font-size: 12px;
            }
            .row.auto-center-div {
              margin: 0;
              .col-md-2 {
                width: 100%;
              }
            }
          }
          .modal-footer {
            display: inline-block;
            text-align: right;
            width: 100%;
            padding: 25px 10px 10px;
            margin: 0;
            .btn.btn-default {
              padding: 5px;
              font-size: 12px;
              letter-spacing: 0.5px;
              font-weight: normal;
              margin: 5px 0;
            }
          }
        }
      }
    }
  }

  #dentist-order-detail {
    margin-top: 15px;
    .container {
      padding: 0;
      .dentist-order-detail {
        .client-lab-detail {
          padding: 5px;
          .client-lab-detail-row {
            .left-detail-part {
              h3 {
                font-size: 13px;
              }
              p {
                font-size: 13px;
                line-height: 15px;
              }
              ul {
                li {
                  font-size: 13px;
                  line-height: 15px;
                }
              }
              &.full-construction {
                ul {
                  li {
                    span {
                      &:after {
                        right: 2px;
                        bottom: 15px;
                      }
                    }
                  }
                }
              }
            }
            .right-detail-part {
              h3 {
                font-size: 13px;
              }
              p {
                font-size: 13px;
                line-height: 15px;
              }
              &.order-created {
                i {
                  font-size: 30px;
                }
                span {
                  p {
                    font-weight: normal;
                    font-size: 10px;
                  }
                }
              }
              .modify-date {
                button {
                  margin: -12px 85px -20px 0;
                  float: right;
                  font-weight: normal;
                  padding: 4px 2px;
                  font-size: 11px;
                }
                span {
                  width: 80px;
                }
              }
            }
          }
          .orders-pics {
            padding: 8px;
            margin-top: 5px;
            ul {
              width: 70%;
              li {
                width: 48%;
                height: 46px;
                max-width: 100px;
                img {
                  width: 100%;
                  height: auto;
                  max-height: 45px;
                }
              }
              button {
                width: 15px;
                height: 15px;
                line-height: 15px;
                font-size: 13px;
              }
            }
            .order-pic-upload {
              width: 30%;
              .upload-image {
                margin-top: 0;
                span {
                  height: 30px;
                  line-height: 20px;
                  font-size: 10px;
                }
              }
            }
          }
        }
        .chat-box {
          padding: 5px 0;
          .chat-box-date {
            padding: 5px 0px;
            .chat-with-box {
              .chat-login-person {
                padding: 20px 10px 0px;
                .chat-front-person-chat {
                  width: 77%;
                }
              }
              .chat-front-person {
                padding: 20px 10px 0px;
                .chat-front-person-chat {
                  width: 77%;
                  float: right;
                }
              }
            }
          }
        }
        .chat-option-box {
          margin-top: 2px;
          input {
            padding: 15px;
          }
        }
        .chat-send-btn {
          input {
            padding: 5px 40px;
          }
        }
      }
    }
  }
  p.red-astrix-mark {
    font-size: 13px;
    line-height: 14px;
    padding: 0 2px;
  }
  .orders-pics-resend {
    ul {
      li {
        width: 100px;
        height: 50px;
        img {
          width: 100%;
          height: 45px;
        }
      }
    }
  }
  #resend-order-button {
    button {
      &:focus {
        outline: none;
      }
    }
  }
  #order-status-view {
    .no-padding-lr {
      .Sent-by-dentist {
        width: 96%;
        ul {
          li {
            padding: 5px;
            p {
              font-size: 11px;
            }
            .dentist {
              .dentist-right {
                &.width-resize {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }

  #accept-order,
  #clinic-received-delivery,
  #lab-work-started,
  #decline-order {
    .modal-dialog {
      .modal-content {
        .modal-header {
          .close {
            margin: -5px -12px;
          }
        }
      }
    }
  }
  #imgModal .align-img-cross {
    width: 60%;
  }
  #imgModal img {
    width: 100%;
    height: inherit;
  }
}

@media all and (orientation: landscape) and (max-width: 767px) {
  login,
  forgot {
    .container {
      display: block;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #patient-section.order-two-part .SearchPatient ul li label {
    font-size: 12px;
  }
  #patient-section.order-two-part .SearchPatient ul li p.red-astrix-mark {
    padding: 0;
  }
  #patient-section .SearchPatient .patientExistingorder .col-lg-12.order-list-box {
    float: left;
    width: 100%;
  }
  .clearfix-left {
    clear: left;
  }
  .color-sampling-radio {
    width: 50%;
    margin-top: 13px;
  }
  order-new {
    .row {
      margin: 15px 0 0;
    }
  }
  #patient-section {
    span.check-icons {
      i {
        padding-left: 3px;
        top: 39px;
        right: -17px;
        font-size: 10px;
        padding-top: 3px;
      }
    }
    .patientLab {
      margin-top: 120px;
      padding: 0;
    }
    h1.patientTitle {
      font-size: 15px;
      line-height: 35px;
    }
    .SearchPatient {
      padding: 25px 10px;
      h1 {
        font-size: 15px;
      }
      ul {
        width: 100%;
        li {
          margin-top: 10px;
          text-align: left;
          label {
            margin-bottom: 2px;
          }
          input.form-control {
            text-align: left;
          }
        }
      }
      .col-lg-2 {
        width: 100% !important;
        .chooseLAb {
          width: 100%;
          margin-top: 5px;
        }
      }
      + .SearchPatient {
        text-align: left;
        display: inline-block;
        .col-lg-2 {
          width: 100% !important;
          max-width: 110px;
          padding-right: 5px;
          float: none;
          display: inline-block;
        }
      }
    }
  }
  #construction-section {
    .cross-rectangle-post {
      width: 12px;
      height: 12px;
      bottom: -15px;
      right: 9.5px;
      line-height: 12px;
      font-size: 8px;
    }
    .cross-square {
      width: 12px;
      height: 12px;
      top: -9px;
      right: -8px;
      line-height: 12px;
      font-size: 8px;
    }
    .margin-top {
      margin-top: 0 !important;
    }
    .row {
      margin: 0;
    }
    h1.patientTitle {
      font-size: 15px;
      line-height: 35px;
    }
    .Constructions {
      margin-top: 0;
      padding: 0;
    }
    .SearchPatient {
      padding: 10px 0 0;
      ul {
        li {
          margin-bottom: 10px;
          a {
            font-size: 15px;
            line-height: 24px;
          }
          &.active {
            a {
              border-bottom-width: 2px;
            }
          }
        }
      }
    }
    .divider-new {
      h1 {
        margin-top: 0;
        font-size: 18px;
      }
    }
    .clickonBoth {
      .well-sm {
        .range {
          display: none;
        }
        .btn-group {
          margin-bottom: 10px;
        }
      }
    }
    .margin-top-45 {
      margin-top: 5px !important;
    }
    .SelectMaterial {
      h3 {
        margin-top: 0;
        line-height: 24px;
      }
      ul {
        margin-top: 0;
        li {
          width: 32%;
          margin-right: 1.33%;
          margin-bottom: 3px;
          a {
            padding: 6px 3px;
            font-size: 11px;
          }
        }
      }
      .designBox {
        ul {
          li {
            width: 100%;
          }
        }
      }
      &.designBox {
        ul {
          li {
            margin-bottom: 3px;
          }
        }
      }
    }
    .form-group {
      .other-material {
        width: 100%;
      }
    }
    ul.singleSub {
      width: 100%;
      li {
        width: 32%;
        margin: 1px 0.5%;
      }
    }
    #bridgeTeeth {
      .row {
        margin: 0 -25px;
      }
    }
    .bridge-suggestion,
    .partial-suggestion {
      margin-left: 0;
      width: 100%;
      border-radius: 5px;
      padding: 5px;
      font-size: 12px;
      font-weight: normal;
    }
  }
  #delivery-section {
    padding-top: 0;
    .DeliveryInformation {
      margin-top: 0;
      padding: 0;
    }
    h1.patientTitle {
      font-size: 15px;
      line-height: 35px;
    }
    .DelInf {
      width: 100%;
      margin-left: 0;
    }
    .deliveryDate {
      margin-top: 10px;
      h3 {
        font-size: 14px;
        font-weight: normal;
      }
      ul {
        margin-top: 0;
      }
    }
    .form-group {
      margin-right: 15px;
      float: left;
      width: 95.6%;
      margin-top: 0;
      label {
        font-size: 14px;
        font-weight: normal;
        margin-top: 10px;
      }
    }
  }
  #images-section {
    padding-top: 0;
    .upload-images {
      margin-top: 15px;
      padding: 0 0 15px;
    }
    h1.patientTitle {
      font-size: 15px;
      line-height: 35px;
    }
    .images-upload {
      float: left;
      margin-bottom: 0;
      margin-top: 10px;
      padding: 50px 0;
    }
    .row {
      width: 100%;
      float: left;
      margin: 0;
      span.Images-circle {
        float: left;
        width: 50px;
        margin: 10px 0 0;
        display: block;
        height: inherit;
      }
      .images-file-name {
        width: 90%;
        float: left;
        margin: 5px 0 0 10px;
        padding: 0;
        text-align: left;
        font-size: 12px;
        word-wrap: break-word;
      }
      .file-icon {
        margin: 0;
        width: 50px;
        i {
          margin: 10px 0 0;
          font-size: 2em;
        }
      }
    }
  }
  #sign-section {
    padding-top: 0;
    h1.patientTitle {
      font-size: 15px;
      line-height: 35px;
    }
    .signSend {
      margin-top: 15px;
      padding: 0 0 15px;
      margin-bottom: 15px;
    }
    .signSendbox {
      h1 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;
      }
      p {
        font-size: 13px;
      }
      img {
        width: 50px;
        margin: 20px 0 30px;
      }
      .sign-with-img-icon {
        span.right-sign-all-select {
          width: 30px;
          height: 30px;
          padding: 15px;
          bottom: 15px;
          right: -25px;
          &:after {
            top: 6px;
            left: 5px;
            font-size: 20px;
          }
        }
      }
    }
  }

  #denthub-header {
    #imaginary_container {
      width: 100%;
    }
    #menu-profile {
      ul.dropdown-menu {
        margin: 0 -65px !important;
        top: 49px;
        left: -105px;
      }
    }
    .notificationTitle {
      font-size: 0;
      i {
        font-size: 20px;
      }
    }
    #Notifications {
      ul.dropdown-menu {
        top: 42px;
        left: -229px;
        border-top: 0;
      }
      .btn-default {
        span.position-relative {
          span.Notifications-notes {
            top: -24px;
          }
        }
      }
      &.open {
        button {
          &:after {
            left: -2px;
            top: 16px;
          }
        }
      }
    }
    .dropdown {
      float: right;
      margin-top: 8px;
      .btn-default {
        font-size: 0;
        i {
          font-size: 20px;
          margin-right: 10px;
        }
      }
    }
    .dropdown-menu {
      li {
        a {
          &:focus {
            text-align: left;
          }
        }
      }
    }
  }

  #navBar {
    .navbar-collapse {
      padding: 0;
      .nav {
        li {
          a {
            position: relative;
            display: block;
            padding: 11px 5px;
            font-size: 11px;
            width: auto;
          }
          &:nth-child(3) {
            a {
              width: 170px;
            }
          }
          &.dentist-order-manage {
            &:first-child {
              a {
                width: 220px;
                padding-left: 5px;
              }
            }
            &:nth-child(3) {
              width: 100px;
              a {
                width: 100px;
              }
            }
            &.modify-order {
              width: 100px !important;
              text-align: center;
            }
          }
        }
      }
    }
  }

  #delivery-section {
    .deliveryDate {
      h3 {
        font-size: 14px;
      }
    }
  }

  #order-status-view {
    .no-padding-lr {
      .Sent-by-dentist {
        h1 {
          font-size: 16px;
          &:after,
          &:before {
            border-top: 20px solid transparent;
            border-bottom: 21px solid transparent;
          }
        }
        ul {
          li {
            .dentist {
              .dentist-left.width-resize {
                width: 100%;
              }
              .dentist-right.width-resize {
                width: 100%;
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }

  #order-list {
    .order-list {
      .no-padding-default {
        .order-list-hading {
          .font-manage {
            font-size: 14px;
            padding: 0 2px;
          }
        }
        .order-list-content {
          .font-manage {
            font-size: 14px;
            padding: 0 2px;
            .lab-flag-icon {
              left: -3px;
              top: 5px;
            }
          }
          &:first-child {
            .font-manage {
              padding: 0 20px;
            }
          }
        }
      }
      .order-list-box.no-padding-default {
        padding: 10px 15px;
        float: left;
        width: 100%;
      }
    }
  }

  #my-modal-compatible {
    .modal-dialog {
      width: 650px;
      .modal-content {
        padding: 10px 15px;
        .modal-footer {
          .btn.btn-default {
            font-size: 12px;
            padding: 15px 5px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  #clinics,
  #users-lab,
  #users {
    margin-top: 150px;
    .clinics,
    .users-lab,
    .users {
      ul {
        li {
          width: 32.3%;
          margin: 0 0.5% 10px;
          min-width: 32.3%;
          max-width: 32.3%;
          .clinic-block {
            h3 {
              margin-bottom: 10px;
            }
            .clinic-activation-block {
              padding: 10px 5px;
              margin-top: 5px;
              .clinic-active {
                font-size: 12px;
                line-height: 20px;
                .round-active-clinic {
                  margin-top: -1px;
                }
              }
              .clinic-active-button {
                a {
                  padding: 2px 2px;
                  margin-top: -2px;
                  float: left;
                  font-size: 10px;
                  margin-left: 1px;
                }
              }
            }
            ul.multiple-check-organization {
              li {
                min-width: 98%;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
  #clinics {
    .clinics,
    .users-lab,
    .users {
      ul {
        li {
          width: 49%;
          margin: 0 0.5% 10px;
          min-width: 49%;
          max-width: 49%;
        }
      }
    }
  }

  #add-clinic-modal,
  #add-user-modal,
  #add-user-lab-modal,
  #add-lab-modal {
    .modal-dialog {
      width: 700px;
    }
  }

  #connect-lab {
    margin-top: 150px;
    .connect-lab {
      .profile-left-section {
        .profile-form-left-row {
          padding: 0 20px;
        }
      }
      .profile-right-section {
        width: 100%;
        margin-right: 0;
        .profile-right-block-one {
          .profile-form-right {
            .jobs-div {
              width: 100%;
            }
            .select-days-div {
              .jobs-div-row {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  #account-setting {
    margin-top: 150px;
    .account-setting {
      .profile-left-section {
        .profile-form-left {
          .change-profile-picture {
            input[type='button'] {
              bottom: 5px;
              right: 5px;
              padding: 3px 10px;
              font-size: 15px;
            }
          }
        }
      }
      .profile-right-section {
        width: 100%;
        .profile-right-block-one {
          padding: 35px 5px;
          .head-profile {
            padding: 0;
            h2 {
              span {
                padding: 0 5px;
              }
            }
          }
          .profile-form-right {
            .profile-form-right-row {
              margin-bottom: 0;
              .profile-form-right-col {
                width: 100%;
                max-width: 100%;
                margin: 0 0 20px;
                &.archive-order {
                  margin-top: -40px;
                  span {
                    margin-bottom: 10px;
                  }
                  p {
                    margin-left: 0;
                  }
                }
                &.view-settings {
                  .switch-button {
                    display: flex;
                  }
                }
              }
            }
          }
        }
        .save-change-btn {
          input[type='button'] {
            padding: 6px 20px;
            font-size: 15px;
          }
        }
      }
    }
  }

  #lab-profile {
    margin-top: 150px;
    .lab-profile {
      .profile-left-section {
        padding-top: 20px;
        .head-profile {
          padding: 0;
          h2 {
            span {
              padding: 0 5px;
              font-size: 18px;
            }
          }
        }
        .profile-form-left {
          .profile-form-left-row {
            padding: 0 10px;
            &.document-section {
              .document-div {
                padding: 3px;
                .document-div-left {
                  .upload-file-name {
                    padding: 5px 0 0;
                    min-height: 30px;
                    max-height: 30px;
                    width: 80%;
                    a.link-icon:before,
                    a.link-icon.link-file[href]:before {
                      margin-top: 3px;
                    }
                  }
                  img {
                    margin-top: 6px;
                    width: 11px;
                  }
                }
                .document-div-right {
                  .upload-file-div {
                    width: auto;
                    font-size: 12px;
                    .filechoose-div {
                      padding: 0 5px;
                    }
                  }
                  span {
                    font-size: 11px;
                  }
                }
              }
            }
          }
          .profile-form-left-row.current-jobs-section {
            .jobs-div {
              .jobs-div-row {
                label {
                  width: auto;
                  margin-left: 0;
                  margin-top: 0;
                  input {
                    margin: -1px 5px 0 0;
                  }
                  p {
                    font-size: 11px;
                  }
                }
                .delivery-section {
                  width: 50%;
                  input {
                    padding: 1px 0;
                    font-size: 12px;
                  }
                  span {
                    width: auto;
                    margin-bottom: 0;
                    font-size: 11px;
                    line-height: 22px;
                    margin-top: 0;
                  }
                  .tooltip.ng-scope.ng-isolate-scope.top.file-name-tooltip.fade.in {
                    width: 130px;
                  }
                }
              }
            }
          }
        }
      }
      .profile-right-section {
        width: 100%;
        .profile-right-block-one {
          padding: 20px 5px;
          .head-profile {
            padding: 0;
            h2 {
              span {
                padding: 0 5px;
                font-size: 18px;
              }
            }
          }
          .media-section {
            .media-section-block {
              width: 100%;
              &:first-child {
                margin-bottom: 25px;
              }
            }
          }
        }
        .profile-right-block-two {
          padding: 20px 5px;
          margin-top: 15px;
          .head-profile {
            padding: 0;
            h2 {
              span {
                padding: 0 5px;
                font-size: 18px;
              }
            }
          }
          .media-section {
            .media-section-block {
              width: 100%;
              .select-days-div {
                .jobs-div-row {
                  padding-left: 5px;
                  label {
                    width: 75px;
                    input[type='checkbox'] {
                      &.checkedInput {
                        width: 100%;
                        height: 30px;
                      }
                      &.notCheckedInput {
                        width: 100%;
                        height: 30px;
                      }
                      + span {
                        line-height: 30px;
                        font-size: 14px;
                      }
                    }
                  }
                  .delivery-section {
                    width: 70%;
                    span {
                      font-size: 12px;
                      line-height: 31px;
                      padding: 0 10px;
                    }
                    .ng-isolate-scope {
                      tr {
                        td {
                          input {
                            line-height: 28px;
                            min-height: 28px;
                            height: 28px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .media-section-block:nth-child(2) {
              width: 100%;
              margin-top: 25px;
              .contact-form {
                .contact-form-row {
                  i {
                    width: 30px;
                  }
                }
              }
            }
          }
          .calendar-section {
            padding-left: 0;
            .btn-sm {
              padding: 5px 8px;
            }
          }
        }
      }
      .notifications {
        .profile-left-section {
          .head-profile {
            h2 {
              margin-top: 20px;
            }
          }
          .profile-form-left {
            padding: 0 15px !important;
            .profile-form-left-col {
              .user-email {
                .checkbox-inline {
                  p.email-noti-para {
                    width: 80%;
                  }
                }
              }
            }
            .profile-form-left-Select {
              .switch-button {
                .switch {
                  margin-right: 8px;
                  &.checked {
                    margin-right: 8px;
                  }
                }
                p {
                  width: 76%;
                }
              }
            }
          }
        }
      }
    }
    .container {
      padding: 0;
    }
  }

  #profile-dentist {
    margin-top: 150px;
    .profile-dentist {
      .profile-left-section {
        padding-top: 20px;
        .head-profile {
          padding: 0;
          h2 {
            span {
              padding: 0 5px;
            }
          }
        }
        .profile-form-left {
          .profile-form-left-row {
            padding: 0 15px;
            .profile-form-left-col {
              width: 100%;
              margin-bottom: 20px;
            }
            &.change-pass-div {
              .profile-form-left-col {
                &.extra-top-margin-add {
                  margin-top: -50px !important;
                }
              }
            }
          }
        }
        .notifications {
          padding-top: 15px;
          .profile-form-left {
            &:last-child {
              min-height: inherit;
            }
            .profile-form-left {
              padding: 0 15px;
              .profile-form-left-col {
                .switch-button {
                  .switch {
                    margin-right: 8px;
                    &.checked {
                      margin-right: 8px;
                    }
                  }
                  p {
                    width: 76%;
                  }
                }
              }
            }
          }
        }
      }
      .profile-right-section {
        width: 100%;
        .profile-right-block-one {
          padding: 20px 15px;
          .head-profile {
            padding: 0;
            h2 {
              span {
                padding: 0 5px;
                font-size: 18px;
              }
            }
          }
          .profile-form-right {
            .profile-form-right-row {
              .profile-form-right-col {
                width: 100%;
                max-width: inherit;
                margin: 0 0 15px;
                &.default-clinic-right-col {
                  margin: 0;
                  width: 100% !important;
                  &.top-margin {
                    .switch-button {
                      .switch {
                        margin-right: 8px;
                        &.checked {
                          margin-right: 8px;
                        }
                      }
                      p {
                        width: 78%;
                      }
                    }
                    &.auto-archive {
                      span {
                        margin-bottom: 10px;
                      }
                      p {
                        margin-left: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .profile-right-block-two {
          padding: 15px 10px;
          margin-top: 15px;
          .head-profile {
            padding: 0;
            h2 {
              span {
                padding: 0 5px;
                font-size: 18px;
              }
            }
          }
          .profile-form-right {
            .profile-form-right-row {
              .profile-form-right-col {
                width: 100%;
                max-width: inherit;
                margin: 0 0 15px;
              }
            }
          }
        }
      }
    }
    .container {
      padding: 0;
    }
  }

  #navBar .navbar {
    margin-top: 2px;
  }
  #navBar .nav > li > a {
    width: 135px;
  }

  #connect-to-lab {
    margin-top: 150px;
    .connect-to-lab {
      .search-box-lab {
        input[type='button'] {
          padding: 0 15px;
          font-size: 15px;
        }
      }
      .lab-report-section {
        .lab-report-head {
          .first-sec,
          .second-sec,
          .third-sec,
          .fourth-sec {
            font-size: 15px;
          }
        }
        .lab-report-body {
          .repeated-div-report {
            .fourth-sec {
              .one-by-third {
                .one-by-third-one {
                  input[type='button'] {
                    padding: 0 5px;
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
      .pagination-center {
        margin: 0;
        li {
          a {
            padding: 5px 10px;
            font-size: 14px;
          }
        }
      }
    }
  }
  #dentist-order-detail {
    margin-top: 150px;
    .container {
      padding: 0;
      .dentist-order-detail {
        .client-lab-detail {
          padding: 5px;
          .client-lab-detail-row {
            .left-detail-part {
              h3 {
                font-size: 13px;
              }
              p {
                font-size: 13px;
                line-height: 15px;
              }
              ul {
                li {
                  font-size: 13px;
                  line-height: 15px;
                }
              }
              &.full-construction {
                ul {
                  li {
                    span {
                      &:after {
                        right: 2px;
                        bottom: 15px;
                      }
                    }
                  }
                }
              }
            }
            .right-detail-part {
              h3 {
                font-size: 13px;
              }
              p {
                font-size: 13px;
                line-height: 15px;
              }
              &.order-created {
                i {
                  font-size: 30px;
                }
                span {
                  p {
                    font-weight: normal;
                    font-size: 10px;
                  }
                }
              }
            }
          }
          .orders-pics {
            padding: 8px;
            margin-top: 5px;
            ul {
              width: 70%;
              li {
                width: 48%;
                height: 46px;
                max-width: 100px;
                img {
                  width: 100%;
                  height: auto;
                  max-height: 45px;
                }
              }
              button {
                width: 15px;
                height: 15px;
                line-height: 15px;
                font-size: 13px;
              }
            }
            .order-pic-upload {
              width: 30%;
              .upload-image {
                margin-top: 0;
                span {
                  height: 30px;
                  line-height: 20px;
                  font-size: 10px;
                }
              }
            }
          }
        }
        .chat-box {
          padding: 0;
          .chat-box-date {
            padding: 5px 0px;
            .chat-with-box {
              .chat-login-person {
                padding: 20px 10px 0px;
                .chat-front-person-chat {
                  width: 77%;
                }
              }
            }
          }
        }
        .chat-option-box {
          margin-top: 2px;
          input {
            padding: 15px;
          }
        }
        .chat-send-btn {
          input {
            padding: 5px 40px;
          }
        }
      }
    }
  }
  p.red-astrix-mark {
    font-size: 13px;
    line-height: 14px;
    padding: 0 5px;
  }
  .orders-pics-resend {
    ul {
      li {
        width: 100px;
        height: 50px;
        img {
          width: 100%;
          height: 45px;
        }
      }
    }
  }
  #resend-order-button {
    button {
      &:focus {
        outline: none;
      }
    }
  }
  #order-status-view {
    .no-padding-lr {
      .Sent-by-dentist {
        width: 96%;
        ul {
          li {
            padding: 5px;
            p {
              font-size: 11px;
            }
            .dentist {
              .dentist-right {
                &.width-resize {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }

  #rate-order {
    .modal-dialog {
      width: 96%;
      margin: 10px 2%;
      .modal-content {
        padding: 15px 0px 5px;
        .modal-header {
          .close {
            margin: -15px 7px;
          }
        }
        .modal-body {
          p {
            font-size: 12px;
          }
          .row.auto-center-div {
            margin: 0;
            .col-md-2 {
              width: 100%;
            }
          }
        }
        .modal-footer {
          display: inline-block;
          text-align: right;
          width: 100%;
          padding: 25px 10px 10px;
          margin: 0;
          .btn.btn-default {
            padding: 5px;
            font-size: 12px;
            letter-spacing: 0.5px;
            font-weight: normal;
            margin: 5px 0;
          }
        }
      }
    }
    &.one-rate-option,
    &.two-rate-option,
    &.three-rate-option,
    &.four-rate-option {
      .modal-dialog {
        width: 96%;
        margin: 10px 2%;
        .modal-content {
          padding: 15px 0px 5px;
          .modal-header {
            .close {
              margin: -15px 7px;
            }
          }
          .modal-body {
            p {
              font-size: 12px;
            }
            .row.auto-center-div {
              margin: 0;
              .col-md-2 {
                width: 50%;
              }
            }
          }
          .modal-footer {
            display: inline-block;
            text-align: right;
            width: 100%;
            padding: 25px 10px 10px;
            margin: 0;
            .btn.btn-default {
              padding: 5px;
              font-size: 12px;
              letter-spacing: 0.5px;
              font-weight: normal;
              margin: 5px 0;
            }
          }
        }
      }
    }
  }
  #imgModal .align-img-cross {
    width: 60%;
  }
  #imgModal img {
    width: 100%;
    height: inherit;
  }
}

@media all and (orientation: landscape) and (min-width: 812px) and (max-width: 860px) {
  login,
  forgot {
    .container {
      display: block;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1599px) {
  #patient-section.order-two-part .SearchPatient ul li label {
    font-size: 12px;
  }
  #patient-section.order-two-part .SearchPatient ul li p.red-astrix-mark {
    padding: 0;
  }
  #patient-section .SearchPatient .patientExistingorder .col-lg-12.order-list-box {
    float: left;
    width: 100%;
  }
  #navBar .navbar {
    margin-top: 2px;
  }

  #order-confirm {
    .order-confirm {
      .order-details {
        width: 100%;
      }
    }
  }

  order-new {
    .row {
      margin: 15px 0 0;
    }
  }
  #patient-section {
    span.check-icons {
      i {
        padding-left: 3px;
        top: 42px;
        right: -17px;
        font-size: 10px;
        padding-top: 3px;
      }
    }
    .patientLab {
      margin-top: 120px;
      padding: 0;
    }
    h1.patientTitle {
      font-size: 15px;
      line-height: 35px;
    }
    .SearchPatient {
      padding: 25px 10px;
      h1 {
        font-size: 15px;
      }
      ul {
        width: 100%;
        li {
          margin-top: 10px;
          text-align: left;
          label {
            margin-bottom: 2px;
          }
          input.form-control {
            text-align: left;
          }
        }
      }
      .col-lg-2 {
        width: 100% !important;
        .chooseLAb {
          width: 100%;
          margin-top: 5px;
        }
      }
      + .SearchPatient {
        text-align: left;
        display: inline-block;
        .col-lg-2 {
          width: auto !important;
          max-width: 110px;
          padding-right: 5px;
          float: none;
          display: inline-block;
        }
      }
    }
  }
  #construction-section {
    .cross-rectangle-post {
      width: 12px;
      height: 12px;
      bottom: -15px;
      right: 9.5px;
      line-height: 12px;
      font-size: 8px;
    }
    .cross-square {
      width: 12px;
      height: 12px;
      top: -9px;
      right: -8px;
      line-height: 12px;
      font-size: 8px;
    }
    .margin-top {
      margin-top: 0 !important;
    }
    .row {
      margin: 0;
    }
    h1.patientTitle {
      font-size: 15px;
      line-height: 35px;
    }
    .Constructions {
      margin-top: 0;
      padding: 0;
    }
    .SearchPatient {
      padding: 10px 0 0;
      ul {
        li {
          margin-bottom: 10px;
          a {
            font-size: 15px;
            line-height: 24px;
          }
          &.active {
            a {
              border-bottom-width: 2px;
            }
          }
        }
      }
    }
    .divider-new {
      h1 {
        margin-top: 0;
        font-size: 18px;
      }
    }
    .clickonBoth {
      .well-sm {
        .range {
          display: none;
        }
        .btn-group {
          margin-bottom: 10px;
        }
      }
    }
    .margin-top-45 {
      margin-top: 5px !important;
    }
    .SelectMaterial {
      h3 {
        margin-top: 0;
        line-height: 24px;
      }
      ul {
        margin-top: 0;
        li {
          width: 32%;
          margin-right: 1.33%;
          margin-bottom: 3px;
          a {
            padding: 6px 3px;
            font-size: 11px;
          }
        }
      }
      .designBox {
        ul {
          li {
            width: 100%;
          }
        }
      }
      &.designBox {
        ul {
          li {
            margin-bottom: 3px;
          }
        }
      }
    }
    .form-group {
      .other-material {
        width: 100%;
      }
    }
    ul.singleSub {
      width: 100%;
      li {
        width: 32%;
        margin: 1px 0.5%;
      }
    }
    #bridgeTeeth {
      .row {
        margin: 0 -25px;
      }
    }
    .bridge-suggestion,
    .partial-suggestion {
      margin-left: 0;
      width: 100%;
      border-radius: 5px;
      padding: 5px;
      font-size: 12px;
      font-weight: normal;
    }
  }
  #delivery-section {
    h1.patientTitle {
      font-size: 15px;
      line-height: 35px;
    }
    .DelInf {
      width: 100%;
      margin-left: 0;
    }
    .deliveryDate {
      margin-top: 10px;
      h3 {
        font-size: 14px;
        font-weight: normal;
      }
      ul {
        margin-top: 0;
      }
    }
    .form-group {
      margin-right: 15px;
      float: left;
      width: 95.6%;
      margin-top: 0;
      label {
        font-size: 14px;
        font-weight: normal;
        margin-top: 10px;
      }
    }
  }
  #images-section {
    .upload-images {
      margin-top: 15px;
      padding: 0 0 15px;
    }
    h1.patientTitle {
      font-size: 15px;
      line-height: 35px;
    }
    .images-upload {
      float: left;
      margin-bottom: 0;
      margin-top: 10px;
      padding: 50px 0;
    }
    .row {
      width: 100%;
      float: left;
      margin: 0;
      span.Images-circle {
        float: left;
        width: 50px;
        margin: 10px 0 0;
        display: block;
        height: inherit;
      }
      .images-file-name {
        width: 90%;
        float: left;
        margin: 5px 0 0 10px;
        padding: 0;
        text-align: left;
        font-size: 12px;
        word-wrap: break-word;
      }
      .file-icon {
        margin: 0;
        width: 50px;
        i {
          margin: 10px 0 0;
          font-size: 2em;
        }
      }
    }
  }
  #sign-section {
    h1.patientTitle {
      font-size: 15px;
      line-height: 35px;
    }
    .signSend {
      margin-top: 15px;
      padding: 0 0 15px;
      margin-bottom: 15px;
    }
    .signSendbox {
      h1 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;
      }
      p {
        font-size: 13px;
      }
      img {
        width: 50px;
        margin: 20px 0 30px;
      }
      .sign-with-img-icon {
        span.right-sign-all-select {
          width: 30px;
          height: 30px;
          padding: 15px;
          bottom: 15px;
          right: -25px;
          &:after {
            top: 6px;
            left: 5px;
            font-size: 20px;
          }
        }
      }
    }
  }

  #denthub-header {
    #imaginary_container {
      width: 100%;
    }
    #menu-profile {
      ul.dropdown-menu {
        margin: 0 -105px !important;
        top: 46px;
      }
    }
    .notificationTitle {
      font-size: 15px;
    }
    .btn-default {
      font-size: 16px;
      margin-left: 11px;
      width: 90%;
    }
    #Notifications {
      ul.dropdown-menu {
        top: 47px;
        left: 21px;
        border-top: 0;
      }
      &.open {
        button {
          &:after {
            left: 21px;
            top: 21px;
          }
        }
      }
    }
  }

  #navBar {
    .navbar-collapse {
      padding: 0;
      .nav {
        li {
          a {
            position: relative;
            display: block;
            padding: 12px 15px 10px;
            font-size: 12px;
            width: auto;
          }
          &.dentist-order-manage {
            a {
              padding: 14px 15px 10px;
            }
            &.modify-order {
              width: auto !important;
            }
          }
          &.lab-order-manage {
            a {
              padding: 14px 15px 10px;
            }
            &:first-child {
              a {
                width: 220px;
                padding: 14px 0 10px;
              }
            }
            &.modify-order {
              width: auto !important;
            }
          }
          &.sub-name {
            a {
              padding: 8px 15px 6px;
            }
          }
        }
      }
    }
  }

  #add-user-modal {
    .check-icons {
      i {
        text-align: center;
      }
    }
  }

  #remove-user-modal {
    .check-icons {
      i {
        text-align: center;
      }
    }
  }

  #order-status-view {
    .no-padding-lr {
      .Sent-by-dentist {
        h1 {
          font-size: 18px;
          &:after,
          &:before {
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
          }
        }
        ul {
          li {
            padding: 7px;
          }
        }
      }
    }
  }

  #order-list {
    .order-list {
      .no-padding-default {
        .order-list-hading {
          .font-manage {
            font-size: 14px;
            padding: 0 20px;
            &:first-child {
              padding-left: 15px;
            }
          }
        }
        .order-list-content {
          .font-manage {
            font-size: 14px;
            padding: 0 2px;
            .dentist-right {
              width: 14%;
              margin-right: 8px;
              span {
                padding: 0 4px;
                font-size: 11px;
                top: -7px;
                right: -8px;
              }
            }
            .lab-flag-icon {
              left: -3px;
              top: 5px;
            }
          }
          &:first-child {
            .font-manage {
              padding: 0 20px;
            }
          }
        }
      }
      .order-list-box.no-padding-default {
        padding: 10px 15px;
        float: left;
        width: 100%;
      }
    }
  }

  #my-modal-compatible {
    .modal-dialog {
      width: 750px;
      .modal-content {
        padding: 10px 15px;
        .modal-footer {
          .btn.btn-default {
            font-size: 14px;
            padding: 15px 10px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  #clinics,
  #users-lab,
  #users {
    margin-top: 175px;
    .clinics,
    .users-lab,
    .users {
      ul {
        li {
          width: 32.3%;
          margin: 0 0.5% 10px;
          min-width: 32.3%;
          max-width: 32.3%;
          .clinic-block {
            h3 {
              margin-bottom: 10px;
            }
            h4 {
              min-height: 20px;
            }
            .clinic-activation-block {
              padding: 10px 5px;
              margin-top: 5px;
              .clinic-active {
                font-size: 12px;
                line-height: 20px;
                .round-active-clinic {
                  margin-top: -1px;
                }
              }
              .clinic-active-button {
                a {
                  padding: 0 5px;
                  margin-top: -2px;
                  float: left;
                  font-size: 12px;
                  margin-left: 1px;
                }
              }
            }
            ul.multiple-check-organization {
              li {
                min-width: 98%;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }

  #connect-lab {
    margin-top: 20px;
    .connect-lab {
      .profile-left-section {
        .profile-form-left-row {
          padding: 0 20px;
        }
      }
      .profile-right-section {
        width: 100%;
        margin-right: 0;
        .profile-right-block-one {
          .profile-form-right {
            .jobs-div {
              width: 80%;
            }
            .select-days-div {
              .jobs-div-row {
                width: 80%;
              }
            }
          }
        }
      }
    }
  }

  #connect-to-lab {
    .connect-to-lab {
      .search-box-lab {
        input[type='button'] {
          padding: 0 25px;
        }
      }
    }
  }

  #account-setting {
    margin-top: 150px;
    .account-setting {
      .profile-left-section {
        .profile-form-left {
          .change-profile-picture {
            input[type='button'] {
              bottom: 5px;
              right: 5px;
              padding: 3px 10px;
              font-size: 15px;
            }
          }
        }
      }
      .profile-right-section {
        width: 100%;
        .profile-right-block-one {
          padding: 35px 5px;
          .head-profile {
            padding: 0;
            h2 {
              span {
                padding: 0 5px;
              }
            }
          }
          .profile-form-right {
            .profile-form-right-row {
              margin-bottom: 25px;
              .profile-form-right-col {
                width: 100%;
                max-width: 31%;
                margin: 0 1% 0;
                &.archive-order {
                  margin-top: -40px;
                  span {
                    margin-bottom: 10px;
                  }
                  p {
                    margin-left: 0;
                  }
                }
                &.view-settings {
                  .switch-button {
                    display: flex;
                  }
                }
              }
            }
          }
        }
        .save-change-btn {
          input[type='button'] {
            padding: 6px 20px;
            font-size: 15px;
          }
        }
      }
    }
  }

  #lab-profile {
    margin-top: 150px;
    .lab-profile {
      .profile-left-section {
        .head-profile {
          padding: 0;
          h2 {
            span {
              padding: 0 5px;
            }
          }
        }
        .profile-form-left {
          .profile-form-left-row {
            padding: 0 10px;
            &.document-section {
              .document-div {
                .document-div-right {
                  span {
                    font-size: 14px;
                  }
                }
              }
            }
          }
          .profile-form-left-row.current-jobs-section {
            .jobs-div {
              .jobs-div-row {
                label {
                  width: 55%;
                }
                .delivery-section {
                  width: 40%;
                  input {
                    width: 20%;
                    font-size: 13px;
                  }
                  span {
                    width: 80%;
                  }
                }
              }
            }
          }
        }
      }
      .profile-right-section {
        width: 100%;
        .profile-right-block-one {
          padding: 35px 5px;
          .head-profile {
            padding: 0;
            h2 {
              span {
                padding: 0 5px;
              }
            }
          }
        }
        .profile-right-block-two {
          padding: 35px 5px;
          .head-profile {
            padding: 0;
            h2 {
              span {
                padding: 0 5px;
              }
            }
          }
          .media-section {
            .media-section-block {
              width: 57%;
              .select-days-div {
                .jobs-div-row {
                  padding-left: 5px;
                }
              }
            }
            .media-section-block:nth-child(2) {
              width: 40%;
              .contact-form {
                .contact-form-row {
                  i {
                    width: 30px;
                  }
                }
              }
            }
          }
        }
      }
      .notifications {
        .profile-left-section {
          .profile-form-left {
            .profile-form-left-col {
              .user-email {
                .checkbox-inline {
                  p.email-noti-para {
                    width: 80%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  #profile-dentist {
    margin-top: 150px;
    .profile-dentist {
      .profile-left-section {
        padding-top: 20px;
        .head-profile {
          padding: 0;
          h2 {
            span {
              padding: 0 5px;
            }
          }
        }
        .profile-form-left {
          .profile-form-left-row {
            padding: 0 15px;
            .profile-form-left-col {
              width: 100%;
              margin-bottom: 20px;
            }
            &.change-pass-div {
              .profile-form-left-col {
                &.extra-top-margin-add {
                  margin-top: -50px !important;
                }
              }
            }
          }
        }
        .notifications {
          padding-top: 15px;
          .profile-form-left {
            &:last-child {
              min-height: inherit;
            }
            .profile-form-left {
              padding: 0 15px;
              .profile-form-left-col {
                .switch-button {
                  .switch {
                    margin-right: 8px;
                    &.checked {
                      margin-right: 8px;
                    }
                  }
                  p {
                    width: 76%;
                  }
                }
              }
            }
          }
        }
      }
      .profile-right-section {
        width: 100%;
        .profile-right-block-one {
          padding: 20px 15px;
          .head-profile {
            padding: 0;
            h2 {
              span {
                padding: 0 5px;
                font-size: 18px;
              }
            }
          }
          .profile-form-right {
            .profile-form-right-row {
              .profile-form-right-col {
                width: 100%;
                max-width: inherit;
                margin: 0 0 15px;
                &.default-clinic-right-col {
                  margin: 0;
                  width: 100% !important;
                  &.top-margin {
                    .switch-button {
                      .switch {
                        margin-right: 8px;
                        &.checked {
                          margin-right: 8px;
                        }
                      }
                      p {
                        width: 78%;
                      }
                    }
                    &.auto-archive {
                      span {
                        margin-bottom: 10px;
                      }
                      p {
                        margin-left: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .profile-right-block-two {
          padding: 15px 10px;
          margin-top: 15px;
          .head-profile {
            padding: 0;
            h2 {
              span {
                padding: 0 5px;
                font-size: 18px;
              }
            }
          }
          .profile-form-right {
            .profile-form-right-row {
              .profile-form-right-col {
                width: 100%;
                max-width: inherit;
                margin: 0 0 15px;
              }
            }
          }
        }
      }
    }
    .container {
      padding: 0;
    }
  }

  #dentist-order-detail {
    .dentist-order-detail {
      .client-lab-detail {
        .client-lab-detail-row {
          .left-detail-part {
            width: 65%;
          }
          .right-detail-part {
            width: 35%;
          }
        }
        .orders-pics {
          padding: 8px;
          margin-top: 5px;
          ul {
            width: 70%;
            li {
              width: 32%;
              height: 46px;
              max-width: 100px;
              img {
                width: 100%;
                height: auto;
                max-height: 45px;
              }
            }
            button {
              width: 15px;
              height: 15px;
              line-height: 15px;
              font-size: 13px;
            }
          }
          .order-pic-upload {
            width: 30%;
            .upload-image {
              margin-top: 0;
              span {
                height: 35px;
                line-height: 24px;
                font-size: 13px;
              }
            }
          }
        }
      }
      .chat-box {
        .chat-box-date {
          .chat-with-box {
            .chat-login-person {
              .chat-front-person-chat {
                width: 80%;
              }
            }
            .chat-front-person {
              .chat-front-person-chat {
                width: 80%;
              }
            }
          }
        }
      }
    }
  }

  #connect-to-lab {
    margin-top: 150px;
    .connect-to-lab {
      .search-box-lab {
        input[type='button'] {
          padding: 0 15px;
          font-size: 15px;
        }
      }
      .lab-report-section {
        .lab-report-head {
          .first-sec,
          .second-sec,
          .third-sec,
          .fourth-sec {
            font-size: 15px;
          }
        }
      }
      .pagination-center {
        margin: 0;
        li {
          a {
            padding: 5px 10px;
            font-size: 14px;
          }
        }
      }
    }
  }
  .orders-pics-resend {
    ul {
      li {
        width: 100px;
        height: 50px;
        img {
          width: 100%;
          height: 45px;
        }
      }
    }
  }
  #imgModal .align-img-cross {
    width: 60%;
  }
  #imgModal img {
    width: 100%;
    height: inherit;
  }
}

@media only screen and (min-width: 2500px) {
  #navBar .nav > li.dentist-order-manage:first-child > a {
    width: 367px;
  }
  #navBar .nav > li.dentist-order-manage > a {
    height: 53px;
  }
}

.modal-dialog {
  margin-top: 70px !important;
}
