@import "~stylesheets/mixins.less";
@import "~stylesheets/variables.less";

.tabs-ui{
  #full-width; padding: 20px 0;
  ul.nav-tabs {
    padding: 0 35px;
    li {
      display: block;
      width: auto;
      float: left;
      &.active {
        a {
          border: 0;
          border-bottom: 5px solid @sky-start-dark;
          &:focus, &:hover {
            border: 0;
            border-bottom: 5px solid @sky-start-dark;
          }
        }
      }
      a {
        border: 0;
        border-bottom: 5px solid @white-color;
        font-weight: 600;
        color: @h1-color;
        padding: 7px 35px;
      }
    }
  }
  .profile-form-left {
    margin-bottom: 20px; margin-top: 20px;
    .info-tab-content-row {
      #full-width; margin-bottom: 15px;
      h4{
        color: @sky-start-dark; margin-bottom: 4px; font-size: 14px;
        p.red-astrix-mark {
          margin: 0;
          line-height: 19px;
        }
      }
      input{
        width: 100%; float: left; padding: 8px 10px; font-size:12px; text-align: center; border: solid 1px @gray-dark; min-height: 38px;
        &:focus{
          outline: none;
        }
      }
      .street-block{
        width: 32%; float: left; margin-right: 2%; margin-bottom: 7px;
        label{
          #full-width; color: @gray-bg-color; font-weight: 300; font-style: italic; line-height: 15px; font-size: 13px;
        }
        ul{
          #full-width;
          li{
            width: auto; float: left; margin-right: 10px; margin-bottom: 10px; padding: 8px 15px; background: @gray-bg-color;
            cursor: pointer;
            &.choose-clinic-tab{
              background: @sky-start-dark; color: @white-color;
            }
          }
        }
        &.full-street-div {
          width: 100%;
        }
        .street-block-select {
          line-height: 38px;
          min-height: 38px;
          width: 120%;
          font-size: 14px;
          height: 38px;
        }
        &.remove-mrgn {
          margin-right: 0;
        }
      }
      .street-block.postcode-input{
        width: 15%;
        input{ width: 100%;}
      }
    }
    .profile-form-left-col {
      .user-email {
        label.checkbox-style {
          margin-top: 5px;
          margin-bottom: -10px;
          input[type=radio] {
            width: 25px;
            height: 25px;
            margin: 0;
          }
        }
      }
      .input-number {
        width: 50px; text-align: center; margin-left: 15px;
      }
      .starting-number-input { width: 120px;}
    }
    .invoice-list {
      #full-width;
      .invoice-list-header {
        #full-width;
        background: @gray-bg-color;
      }
      .invoice-list-content {
        .invoice-list-settings:nth-child(2n) {
          #full-width;
          background: @light-grey;
        }
      }
      .font-manage {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 35px;
        input, select {
          height: 26px;
          width: 100%;
        }
        .profile-form-left-Select {
          margin-top: 0 !important;
          .switch-button {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}

.ml-0 {
  margin-left: 0!important;
}
#order-list .order-list .order-list-hading.invoice-table-width .col-md-2{
  width:16.66666667%;
}
.invoice-pdf-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}
.padding-zero {
  padding: 0 !important;
}