#main-categories-list {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;

  .main-category {
    text-align: center;
    border: solid 1px #105c9d;
    height: 36px;
    margin: 0 10px 5px;
    min-width: 250px;
    cursor: pointer;

    a {
      color: #105c9d;
      font-size: 16px;
      line-height: 36px;
    }
  }
  .main-category.active {
    font-weight: bold;
    background-color: #105c9d;

    a {
      color: #fff;
    }
  }
}

.main-category-instruction {
  clear: left;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  margin: 20px;
  font-weight: 400;
}
