@header-bg: #105c9d;

.clickonBoth {
  .teeth-label {
    font-size: 14px;
    padding: 5px;
    font-weight: 400;
  }

  // Bridge selection ////////////////////////////////////////////

  .bridge-start {
    border: 3px solid @header-bg;
    border-right: none;
    border-radius: 8px 0 0 8px;
    margin-right: 0;
    margin-left: 3px;
    width: 35px;
    padding: 0;
  }

  .bridge-middle,
  .bridge-start-temp {
    border: 3px solid @header-bg;
    border-left: none;
    border-right: none;
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    width: 38px;
  }

  .bridge-end {
    border: 3px solid @header-bg;
    border-left: none;
    border-radius: 0 8px 8px 0;
    margin-left: 0;
    margin-right: 3px;
    padding: 0;
    width: 35px;
  }
}
