#sub-categories-list {
  display: flex;
  justify-content: center;
}
#sub-categories-list .sub-category {
  margin: 0 10px 5px;
  cursor: pointer;
  min-width: 150px;
  height: 36px;
  text-align: center;
}
#sub-categories-list .sub-category .sub-category-label {
  font-weight: 400;
  text-transform: uppercase;
  padding: 4px;
  font-size: 14px;
  line-height: 36px;
}
