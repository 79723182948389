login .container,
forgot .container {
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}
#login-page .card-container.card {
  max-width: 350px;
  padding: 20px 40px;
}
#login-page .card {
  background-color: #fff;
  /* just in case there no content*/
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  /* shadows and rounded borders */
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
#login-page .card .profile-img-card {
  max-width: 250px;
  height: auto;
  margin: 0 auto 10px;
  display: block;
}
#login-page .card .profile-name-card {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0 0;
  min-height: 1em;
}
#login-page .card .form-signin .reauth-email {
  display: block;
  color: #404040;
  line-height: 2;
  margin-bottom: 10px;
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#login-page .card .form-signin #inputEmail,
#login-page .card .form-signin #inputPassword,
#login-page .card .form-signin #inputFederation {
  direction: ltr;
  height: 44px;
  font-size: 16px;
}
#login-page .card .form-signin input[type=email],
#login-page .card .form-signin input[type=password],
#login-page .card .form-signin input[type=text],
#login-page .card .form-signin select,
#login-page .card .form-signin button {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  z-index: 1;
  position: relative;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#login-page .card .form-signin .form-control:focus {
  border-color: #105c9d;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 5px #105c9d;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 5px #105c9d;
}
#login-page .card .form-signin .btn.btn-signin {
  background-color: #105c9d;
  padding: 0px;
  font-weight: 700;
  font-size: 14px;
  height: 36px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: none;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
#login-page .card .form-signin .btn.btn-signin:hover,
#login-page .card .form-signin .btn.btn-signin:focus,
#login-page .card .form-signin .btn.btn-signin:active {
  background-color: #105c9d;
}
#login-page .card .form-signin .btn.btn-selectorg {
  background-color: #105c9d;
  font-weight: 700;
  font-size: 14px;
  height: 36px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: none;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px 0 5px;
}
#login-page .card .form-signin .btn.btn-selectorg:hover,
#login-page .card .form-signin .btn.btn-selectorg:focus,
#login-page .card .form-signin .btn.btn-selectorg:active {
  background-color: #105c9d;
}
#login-page .card .form-signin .select-organization h1 {
  text-align: center;
  font-size: 18px;
  color: #105c9d;
  font-weight: 700;
  margin-bottom: 15px;
}
#login-page .card .form-signin .color-sampling-radio .checkbox-style {
  cursor: pointer;
}
#login-page .card .form-signin .color-sampling-radio .checkbox-style input[type="radio"]:checked,
#login-page .card .form-signin .color-sampling-radio .checkbox-style input[type="radio"]:not(:checked) {
  opacity: 1;
  position: static !important;
  border: 2px solid #6bd0d0;
}
#login-page .card .form-signin .color-sampling-radio .checkbox-style input[type="radio"]:checked:after {
  background: #6bd0d0;
}
#login-page .card .forgot-password {
  color: #105c9d;
}
#login-page .card .forgot-password:hover,
#login-page .card .forgot-password:focus,
#login-page .card .forgot-password:active {
  color: #105c9d;
}
.card.card-container label + .ui-select-container.select2.select2-container {
  width: 60%;
  float: right;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.ui-select-container[theme=select2].direction-up .ui-select-dropdown {
  position: static !important;
  border-radius: 0;
  border-top-width: 0;
  border-top-style: solid;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.25);
  margin-top: 10px;
  background: #fff;
  display: block;
  padding: 5px 15px;
}
.ui-select-container[theme=select2].direction-up .ui-select-dropdown .search-container.select2-search {
  display: none;
}
.card.card-container label + .ui-select-container.select2.select2-container .ui-select-dropdown .search-container.select2-search input {
  height: 0px;
  padding: 0px;
  border: 0px;
}
.card.card-container label + .ui-select-container.select2.select2-container .ui-select-dropdown .search-container.select2-search + ul {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.25);
  margin-top: 10px;
  background: #fff;
  display: block;
  padding: 0.1px 0px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 18px;
}
.card.card-container label span {
  margin-left: 80px;
}
.select2-result-label.ui-select-choices-row-inner {
  text-align: left;
  margin-left: 34px;
}
.bankId {
  height: 25px;
}
