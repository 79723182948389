.info-area {
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  border-radius: 4px;
  background-color: rgb(229, 246, 253);
  display: flex;
  padding: 6px 16px;
  color: rgb(1, 67, 97);
  float: left;
  align-items: center;
  width: 100%;

  .mat-icon {
    margin-right: 5px;
  }
}

#sign-section {
  &.order-details-2 {
    .sign-send {
      padding: 0;
      background: none;
      .sign-send {
        background: @white-color;
        padding: 18px 15px 50px;
        .files-box {
          img {
            max-width: 100%;
            display: inline-block;
            float: none;
          }
          a {
            display: block;
            border: 0;
            text-align: center;
            .file-icon i {
              color: @sky-start-dark;
            }
          }
          h3 {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 40px;
            overflow: hidden;
          }
        }
        #images-section {
          .images-upload {
            margin-top: 0;
            padding: 10px 0 15px;
            line-height: 180px;
            font-weight: 600;
            img {
              width: 200px;
              float: left;
            }
          }
        }
        #imgModal {
          .carousel {
            a {
              height: 100vh;
              span.glyphicon {
                left: 50%;
                top: 45%;
                font-size: 30px;
              }
            }
          }
        }
      }
      .confirm-info-sign-send {
        p {
          font-size: 14px;
          display: flex;
          i.fa-info,
          i.fa-phone {
            min-width: 18px;
            height: 18px;
            text-align: center;
            line-height: 16px;
            border: solid 2px @sky-start-dark;
            border-radius: 50%;
            margin-right: 5px;
            color: @sky-start-dark;
            cursor: pointer;
            margin-top: 0;
            margin-left: -27px;
            font-size: 11px;
          }
          span.invoice-inputs {
            display: flex;
            justify-content: space-between;
            input {
              width: 100px;
              float: left;
              font-size: 12px;
            }
            button {
              width: 65px;
              float: right;
              display: flex;
              align-items: center;
              justify-content: space-around;
              border: solid 1px @sky-start-dark;
              line-height: 24px;
              color: @sky-start-dark;
              cursor: pointer;
              padding: 0 8px;
              text-align: center;
              margin-left: 5px;
              background: @white-color;
              &:hover {
                background: @sky-start-dark;
                color: @white-color;
              }
              &:disabled {
                cursor: not-allowed;
              }
            }
          }
        }
        a.add-anchor-btn {
          width: 80px;
          float: left;
          display: flex;
          align-items: center;
          justify-content: space-around;
          border: solid 1px @sky-start-dark;
          line-height: 24px;
          color: @sky-start-dark;
          cursor: pointer;
        }
        h4 {
          i {
            float: right;
            color: @sky-start-dark;
            cursor: pointer;
          }
          &.show-teeth-construction {
            i {
              transform: rotate(-90deg);
            }
          }
        }
        .teeth-construction-action {
          .add-materias-actions {
            #full-width;
            .add-materias-actions-btn {
              #full-width;
              button {
                width: auto;
                float: left;
                display: flex;
                align-items: center;
                justify-content: space-around;
                border: solid 1px @sky-start-dark;
                line-height: 24px;
                color: @sky-start-dark;
                cursor: pointer;
                padding: 5px 30px;
                background: none;
                i {
                  margin-right: 15px;
                }
              }
              &.hide-this-part {
                display: none;
              }
            }
            .add-materias-actions-section {
              #full-width;
              .added-materials-actions {
                padding: 10px 30px;
                h5 {
                  color: #000;
                  font-weight: 500;
                }
                .material-action-box {
                  #full-width;
                  display: flex;
                  margin-top: 15px;
                  > span {
                    color: #000;
                    font-size: 14px;
                    font-weight: 500;
                  }
                  .search-material-actions {
                    #full-width;
                    margin-left: 15px;
                    position: relative;
                    i.fa-search {
                      position: absolute;
                      top: 5px;
                      left: 15px;
                    }
                    i.fa-caret-down {
                      position: absolute;
                      top: 5px;
                      right: 15px;
                    }
                    input {
                      #full-width;
                      padding: 0 50px;
                      border: solid 1px @dark-grey;
                      background: @light-grey;
                      height: 28px;
                    }
                    .option-materials-actions {
                      #full-width;
                      position: absolute;
                      top: 28px;
                      left: 0;
                      box-shadow: 3px 3px 5px @gray-bg-color;
                      background: @gray-light-white;
                      z-index: 9;
                      .material {
                        #full-width;
                        h5 {
                          #full-width;
                          padding: 5px 25px;
                          background: @gray-bg-color;
                        }
                        ul {
                          #full-width;
                          padding: 0;
                          margin: 0;
                          li {
                            #full-width;
                            padding: 4px 15px;
                            border-bottom: solid 1px @gray-bg-color;
                            label.checkbox-style {
                              height: 16px;
                              line-height: 16px;
                              margin-bottom: 0;
                              input[type='checkbox'] + label {
                                margin-left: 15px;
                                line-height: 16px;
                                &:after {
                                  width: 16px;
                                  height: 16px;
                                }
                              }
                              input[type='checkbox']:checked + label {
                                &:after {
                                  border-color: #105c9d;
                                }
                                &:before {
                                  width: 16px;
                                  height: 16px;
                                  font-size: 12px;
                                }
                              }
                            }
                          }
                        }
                      }
                      .edit-save-btn {
                        padding: 4px 15px 4px 0;
                        button {
                          padding: 2px 30px;
                        }
                      }
                    }
                  }
                }
                .order-list-hading {
                  font-weight: 500;
                  color: #aaa;
                  display: flex;
                  align-items: center;
                  text-align: center;
                  font-size: 12px;
                }
                .order-list-box {
                  #full-width;
                  .order-list-content {
                    #full-width;
                    display: flex;
                    align-items: flex-end;
                    margin: 2px 0;
                    position: relative;
                    .font-manage {
                      h5,
                      span,
                      input {
                        #full-width;
                        font-size: 12px;
                        margin-bottom: 15px;
                        color: #000;
                      }
                      &:nth-child(2),
                      &:nth-child(3),
                      &:nth-child(4),
                      &:nth-child(5) {
                        text-align: center;
                      }
                    }
                    div {
                      &:last-child {
                        position: static;
                        padding-left: 44px;
                      }
                      i.fa-trash {
                        position: absolute;
                        top: 3px;
                        right: 50px;
                        font-size: 24px;
                      }
                    }
                  }
                }
                .edit-save-btn {
                  #full-width;
                  padding-right: 110px;
                  button {
                    width: auto;
                    float: right;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    border: solid 1px @sky-start-dark;
                    line-height: 24px;
                    color: @sky-start-dark;
                    cursor: pointer;
                    padding: 2px 50px;
                    background: none;
                    font-weight: 600;
                    &:disabled {
                      cursor: not-allowed;
                      opacity: 0.6;
                    }
                  }
                }
              }
            }
          }
        }
        .files-box {
          .file-icon {
            i {
              color: #dcdada;
              cursor: pointer;
            }
          }
          h3 {
            text-align: center;
            font-weight: 600;
            font-size: 13px;
          }
        }
        .flag-icon {
          font-size: 25px;
          color: #9f9d9d;
          cursor: pointer;
          &.flag-color-red {
            color: #ff0000;
          }
        }
        .green-truck,
        .red-truck {
          color: #8dc44d;
          cursor: pointer;
          font-size: 30px;
          transform: scaleX(-1);
        }
        .red-truck {
          color: #ff0415;
        }
        .green-truck,
        .red-truck {
          &.dentist-truck {
            cursor: default;
          }
        }
        .digital-sample {
          height: 25px;
        }
      }
      #dentist-order-detail {
        margin-top: 30px;
      }
    }
  }
}

#contact-info-modal,
#price-specification-modal,
#rate-modal,
#approve-modal,
#user-info-modal,
#dentist-info-modal {
  .modal-dialog {
    width: 420px;
    .modal-content {
      padding: 20px 40px 0 40px;
      border-radius: 0;
      background: @white-color;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      .modal-header {
        border: 0;
        padding: 0;
        .close {
          opacity: 1;
          font-size: 30px;
          margin: -15px -25px;
          &:focus {
            outline: none;
          }
        }
        h4.modal-title {
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          color: @sky-start-dark;
          margin-bottom: 0;
        }
      }
      .modal-body {
        padding-top: 30px;
        padding-bottom: 30px;
        overflow: hidden;
        .add-clinic-form {
          #full-width;
          margin-top: 0;
          h4 {
            text-decoration: underline;
            margin-bottom: 15px;
          }
          p {
            text-align: left;
            margin: 0;
            width: 100%;
            float: left;
            line-height: 26px;
          }
        }
      }
      .modal-footer {
        border: 0;
        margin: 30px 0 20px;
        .btn.btn-default {
          border-radius: 0;
          border: solid 1px @sky-start-dark;
          text-transform: uppercase;
          font-size: 15px;
          letter-spacing: 1px;
          font-weight: bold;
          padding: 10px 10px;
          color: @sky-start-dark;
          &:hover,
          &:focus,
          &.active-tab-close {
            background: @sky-start-dark;
            color: @white-color;
          }
        }
      }
    }
  }
}

#price-specification-modal {
  .modal-dialog {
    width: 900px;
    #order-list {
      min-height: inherit;
      .order-list {
        margin-top: 0;
        border: 0;
        box-shadow: none;
        .order-list-hading {
          padding: 10px 0;
        }
      }
      .order-list-content {
        min-height: inherit;
        &.order-list-bg {
          padding: 5px 0;
          background: none;
          border-bottom: solid 1px @gray-bg-color;
          .mobi-manage-content {
            &:first-child {
              p {
                text-align: left;
              }
            }
          }
          p {
            font-weight: normal;
            color: @gray-font-color;
          }
        }
      }
    }
  }
}

#rate-modal,
#approve-modal {
  .modal-dialog {
    width: 700px;
    .modal-content {
      .modal-header {
        h4.modal-title {
          text-align: left;
        }
      }
      .modal-body {
        .add-clinic-form {
          .add-clinic-form-row {
            .street-block {
              h5 {
                #full-width;
              }
              .choose-rate-option {
                #full-width;
                margin-top: 20px;
                li {
                  width: auto;
                  float: left;
                  cursor: pointer;
                  border-radius: 0;
                  border: solid 1px @sky-start-dark;
                  text-transform: uppercase;
                  font-size: 15px;
                  letter-spacing: 1px;
                  font-weight: bold;
                  padding: 5px 25px 4px;
                  margin: 0 30px 30px 0;
                  color: @sky-start-dark;
                  &.active-tab-close {
                    background: @sky-start-dark;
                    color: @white-color;
                  }
                }
              }
              .not-satisfied-check {
                #full-width;
                .choose-rate-option {
                  li {
                    margin: 0 15px 15px 0;
                  }
                }
                span {
                  font-size: 14px;
                }
                textarea {
                  #full-width;
                  margin-top: 10px;
                  padding: 15px;
                  border: solid 1px @gray-bg-color;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #price-specification-modal {
    .modal-dialog {
      width: 100%;
      .modal-content {
        padding: 0;
        .modal-header .close {
          margin: 0px 5px;
        }
      }
      #order-list {
        .order-list {
          .order-list-hading {
            padding: 0;
          }
        }
      }
    }
  }
}

.border-0 {
  border: 0;
}
#OrderDetail2PrintableArea #images-section {
  padding-top: 0;
}
.red-color {
  color: @delay-red-dark !important;
}
.red-del {
  text-decoration: line-through;
  color: @delay-red-dark !important;
}
.mt-53.mb-30 {
  width: 100%;
  float: left;
  margin-bottom: -30px;
  position: relative;
  z-index: 1;
}
.mt-53.mb-30 select {
  min-height: 32px;
  border: solid 1px #eee;
  font-size: 14px;
}
span.version-msg {
  padding: 0 !important;
  margin: 0 5px !important;
}
span.version-msg {
  padding: 0 !important;
  margin: 0 5px !important;
}

.custom-time-picker {
  display: inline-flex;
  width: 100px;
  float: left;
  background: #eee;
  border: solid 1px #ddd;
  height: 24px;
}
.custom-time-picker input {
  width: 100%;
  height: 24px;
  text-align: center;
  background: none;
  border: 0;
  &:disabled {
    cursor: not-allowed;
  }
}
.exception-check input {
  z-index: 11 !important;
  cursor: pointer;
}
.exception-check {
  width: auto !important;
}
#sign-section.order-details-2
  .sign-send
  .sign-send
  p.comment-p.additional-information
  label {
  width: auto;
  float: left;
  min-width: 17.5%;
}
#sign-section.order-details-2
  .sign-send
  .sign-send
  p.comment-p.additional-information
  span {
  float: left;
  width: 80%;
  margin-left: 1%;
}
#sign-section.order-details-2
  .sign-send
  .sign-send
  p.comment-p.additional-information
  span.remove-m-w {
  width: auto;
  margin-left: 0;
}
.archive-check input {
  z-index: 11 !important;
  cursor: pointer;
}
.archive-check {
  width: auto !important;
}
.commet-export-production input[type='checkbox'] {
  cursor: pointer;
}

.chat-option-box .chat-area {
  border: 0;
  width: 100%;
  resize: none;
  padding: 18px;
  margin: -15px 0;
}

.approve-modal-add-msg-section {
  margin-bottom: 30px;
  width: 100%;
  float: left;
  p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
  }
  label.checkbox-style.mt-20.archive-check {
    margin-top: 30px;
  }
}

#approve-modal label.checkbox-style input[type='checkbox'] + label:after {
  border-color: #105c9d;
}
#approve-modal label.checkbox-style input[type='checkbox'] {
  width: 100%;
  height: 100%;
}

.printHidden {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
}

.pdf-btn {
  border: none;
  background: white;
  &:disabled {
    i {
      cursor: not-allowed !important;
    }
  }
}

.print-button-wrapper {
  padding: 5;
}
.additional-information .rightPanel > span {
  margin-bottom: 15px;
  width: 100% !important;
  display: inline-block;
}

.additional-information .rightPanel > del {
  margin-bottom: 15px;
  width: 100% !important;
  display: inline-block;
}

.invoice-pdf-download {
  width: 27px;
  margin-left: 10px;
  margin-top: -3px;
  cursor: pointer;
}

.text-align-left {
  text-align: left;
  color: #000;
}
.flex-start {
  align-items: flex-start !important;
  &:hover {
    cursor: pointer;
  }
  span {
    font-weight: 600;
  }
}
.order-material {
  width: 100%;
  float: left;
}
#sign-section
  .sign-send
  .confirm-info-sign-send
  .teeth-construction-action
  .action-on-teeth
  .add-info
  p.comment-p.additional-information
  span {
  margin-left: 0;
}
#sign-section
  .sign-send
  .confirm-info-sign-send
  .teeth-construction-action
  .action-on-teeth
  .add-info
  p.comment-p.additional-information
  label {
  margin-right: 10px;
  width: 17.5%;
}
.action-display-block {
  display: block !important;
}
.padding-left-change {
  padding-left: 15px !important;
}
.order-transport-status {
  b {
    right: 36px;
    top: 6px;
    position: absolute;
    font-size: 13px;
  }
}

#certify-modal,
#approve-modal {
  .modal-dialog {
    width: 900px !important;
    #order-list {
      min-height: 0;
      .order-list {
        margin-top: 0;
      }
      .order-list-content div:nth-child(1) p {
        text-align: left;
      }
      .order-list-content.order-list-bg {
        padding: 5px 0;
        background: none;
        min-height: inherit;
        border-bottom: solid 1px #dcdada;
      }
    }
  }
}

.details-page-label-black {
  label {
    color: #000 !important;
  }
}

.table-formate {
  width: 100%;
  text-align: left;
  tr {
    th {
      background: #105c9d;
      color: #fff;
      padding: 14px 10px;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      &:first-child {
        text-align: left;
      }
    }
    td {
      font-weight: normal;
      color: #a5a3a3;
      padding: 4px 10px;
      font-size: 13px;
      text-align: center;
      border-bottom: solid 1px #dcdada;
      &:first-child {
        text-align: left;
      }
    }
  }
}

.downloading-progress-nav {
  position: fixed;
  top: 117px;
  left: 50%;
  background: #105c9d;
  color: #fff;
  padding: 5px 10px;
  transform: translateX(-50%);
}

.pd-0 {
  padding: 0 !important;
}

.version-files {
  text-align: left !important;
  width: auto !important;
  h3,
  a {
    text-align: left !important;
  }
}

.remove-height #order-list {
  min-height: inherit;
}

#sign-section.order-details-2 .sign-send .confirm-info-sign-send p label + label.checkbox-style.do-not-invoice-label {
  min-width: inherit !important;
  width: auto !important;
}

.disable-box {
  opacity: 0.5;
}

.download-section {
  width: calc(100% - 10px);
  float: left;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  background: #fff;
  margin: 5px;
  border: 1px #ccc solid;
  button {
    color: #105c9d;
    span {
      font-weight: 500;
    }
  }
}

.production-finished .red-astrix-mark {
  color: #ff0515
}

.invoicing-module {
  display: flex;
  flex-direction: column;
  label {
    width: 100% !important;
  }
}

.edit-delete-material {
  display: flex;
  padding: 0 !important;
  .fa-trash {
    position: static !important;
    margin-left: 18px;
    margin-top: -4px;
  }
}

.warranty-type {
  color: #ff6c03;
  font-weight: 600;
}

.main-container {
  width: 100%;
  float: left;
  margin-top: 15px;
  margin-left: 15px;
  padding-right: 30px;
  h5 {
    font-weight: bold;
    font-size: 16px;
  }
  span {
    font-size: 14px;
    padding-top: 4px;
  }
  p {
    padding: 0 !important;
    text-align: justify;
  }
  .inner-section {
    padding-left: 30px;
    width: 100%;
    float: left;
    margin-top: 15px;
    padding-right: 30px;
  }
}
.dentist-note span{
  text-align: justify;
}
.dentist-note span:first-child {
  padding-right: 3px;
  font-weight: bold;
}
