#order-status-view {
  #section-page;
  margin-top: 132px;
  margin-bottom: 20px;
  height:20px;
  padding: 0;
  background: transparent;
  .no-padding-lr{
    padding: 0;
    .Sent-by-dentist {
      float: left;
      width: 100%;
      background: @white-color;
      h1{
        background: @status-view-color;
        color: @white-color;
        font-size: 18px;
        text-align: center;
        padding: 13px 10px 12px;
        text-transform: uppercase;
        position: relative;
        &:after{
          width: 0;
          height: 0;
          border-top: 22px solid transparent;
          border-bottom: 22px solid transparent;
          border-left: 16px solid @status-view-color;
          position: absolute;
          right: -16px;
          top: 0;
          content: '';
        }
        &:before{
          width: 0;
          height: 0;
          border-top: 22px solid transparent;
          border-bottom: 22px solid transparent;
          border-left: 16px solid @light-grey;
          position: absolute;
          left: 0px;
          top: 0;
          content: '';
        }
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        float: left;
        width: 100%;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .39), 0 2px 10px 0 rgba(0, 0, 0, .39);
        li {
          padding:10px 20px 10px;
          cursor: pointer;
          float: left;
          width: 100%;
          border-bottom: solid 1px @gray-dark;
          p {
            padding: 0;
            margin: 0;
            font-size: 12px;
            line-height: 18px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            b{
              margin-right: 5px; float: left;
            }
          }
          .dentist {
            float: left;
            width: 100%;
            margin-bottom: 0px;
            .dentist-left {
              float: left;
              width: 70%;
              p{
                span{
                  margin-right: 5px; position: relative;
                  &:after{
                    width: 2px; height: 2px; content: ','; position: absolute; bottom: 16px; right: -3px;
                  }
                  &:last-child {
                    &:after {
                      content: '';
                    }
                  }
                }
                span:last-child{
                  &:after{
                    content: '';
                  }
                }
              }
              .tandtekniker {
                float: left;
                width: auto;
                text-align: left;
                text-transform: uppercase;
                color: @gray-font-color;
                background: @white-color;
                padding: 0px 5px 0px 2px;
                img{
                  width: auto;
                  height: 30px;
                  max-width: 100%;
                }
                span.color-change{
                  color: @light-green; font-size: 14px;
                }
                span {
                  float: right;
                  font-size: 12px
                }
              }
            }
            .dentist-left.width-resize {
              width: 30%;
              float: left;
            }
            .dentist-right {
              float: right;
              width: 30%;
              text-align: right;
              padding-top: 5px;
              font-size: 19px;
              position: relative;
              i{
                font-size: 25px;
                color: @light-yellow;
              }
              span{
                position: absolute;
                width: auto;
                padding: 0 6px;
                background: @dark-red-color;
                border-radius: 100%;
                font-size: 11px;
                color: @white-color;
                top: -4px;
                right: -7px;
              }
              &.digital-flag-truck {
                margin-top: -10px;
                .lab-flag-icon {
                  left: 4px;
                  top: 13px;
                  font-size: 25px;
                  color: #ff0000;
                  margin: 0 5px;
                  transform: none;
                  height: 25px;
                  + .lab-flag-icon {
                    top: 21px;
                  }
                  &.two-icon {
                    top: 0;
                  }
                }
                i {
                  font-size: 27px;
                  transform: scaleX(-1);
                  &.green-truck {
                    color: #8dc44d;
                    + .status-view-digital-sample {
                      margin: -12px 5px 0;
                      height: 25px;
                    }
                  }
                  &.red-truck {
                    color: #ff0415;
                    + .status-view-digital-sample {
                      margin: -12px 5px 0;
                      height: 25px;
                    }
                  }
                }
                .status-view-digital-sample {
                  margin: 0 5px;
                  height: 25px;
                }
              }
            }
            .dentist-right.width-resize {
              width: 70%;
              float: right;
              font-size: 12px;
              margin-top: 0;
            }
          }
          .dentist:last-child{
            margin-bottom: 0;
            margin-top: -5px;

          }
        }
        li.light-red-bg{
          cursor: pointer;
          background: @light-red-color;
          .dentist{
            .dentist-right.width-resize{
              color: @dark-red-color;
            }
          }
        }
      }
    }
    .no-padding-l{
      padding-left: 0px;
      .Sent-by-dentist{
        h1{
          &:before{
            border:0px;
          }
        }
      }
    }
    .mid-sec-status{
      .Sent-by-dentist{
        h1 {
          background: @sky-mid-dark;
          &:after{
            border-left-color: @sky-mid-dark;
          }
        }
      }
    }
    .no-padding-r{
      padding-right: 0px;
      .Sent-by-dentist{
        h1 {
          background: @sky-dark;
          &:after{
            border: 0px;
          }
        }
      }
    }

  }
}

#my-modal-compatible{
  .modal-dialog{
    width: 850px;
    .modal-content{
      padding: 20px 30px;
      border-radius: 0px;
      background: @white-color;
      box-shadow: 0 5px 15px rgba(0,0,0,.5);
      .modal-header{
        border: 0px;
        .close{
          opacity: 1; font-size: 30px; margin: -15px;
        }
        h4.modal-title{
          color: @sky-start-dark;
          font-size: 25px;
          font-weight: bold;
          span{
            border: solid 1px @sky-start-dark;
            width: 24px;
            height: 24px;
            border-radius: 100%;
            display: inline-block;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            line-height: 24px;
          }
        }
      }
      .modal-body{
        p{
          font-size: 16px; line-height: 18px;
          b{
            width: 100%; float: left;
          }
        }
      }
      .modal-footer{
        border: 0;
        margin: 30px 0 20px;
        .btn.btn-default{
          border-radius: 0px; border: solid 1px @sky-start-dark; font-size:18px; letter-spacing: 1px; font-weight: bold; padding: 15px 15px; color:@sky-start-dark;
          &:hover, &:focus, &.active-tab-close{
            background: @sky-start-dark; color: @white-color;
          }
        }
      }
    }
  }
}