  #navBar{
  .newOrder.add-users{
    float: left;
    border-left: solid 0px #979797;
    border-right: solid 1px #979797;
    padding-left: 0;
    padding-right: 10px;
    margin-right: 10px;
  }
}
.no-padding-default{ padding: 0px;}


.profile-form-left-row {
  label {
    color: black !important;
  }
}

.profile-form-left-col {
  .notifications-title {
    color: black !important;
  }
}

.profile-right-section {
  h3 {
    color: black !important;
  }
}

#connect-lab{
  #full-width; margin-top: 200px; margin-bottom: 20px;
  .connect-lab{
    #full-width; background: @white-color;
    .profile-left-section{
      #full-width; background: @white-color; padding-top: 45px;
      .head-profile{
        #full-width; padding: 0 35px;
        h2{
          #full-width; text-align: center; background: url(../images/border-head-h2.png) 0 center repeat-x;
          span{
            width: auto; padding: 0 28px; background: @white-color; text-transform: uppercase; font-size: 22px; font-weight: 300;
          }
        }
      }
      .profile-form-left{
        #full-width; padding-top: 25px;
        .profile-form-left-row{
          #full-width; margin-bottom: 37px; padding: 0 68px;
          .logo-profile-coonect-lab{
            overflow: hidden;
            img{
              width: auto; height: 71px;
            }
          }
          .contact-form{
            #full-width; margin-top: 7px;
            h3{
              font-size: 18px; color: @sky-start-dark; #full-width; margin-top: 40px; margin-bottom: 25px;
            }
            .contact-form-row{
              #full-width; margin-bottom: 20px; padding-left: 10px;
              i{
                width: 35px; text-align: left; float: left; color: @sky-dark; font-size: 20px; line-height: 25px;
              }
              span{
                line-height: 25px;
              }
            }
          }
        }
        .change-profile-picture{
          #full-width; overflow: hidden;
          position: relative;
          min-height: 200px;
          img{
            width: 100%;
          }
        }
      }
    }
    .profile-right-section{
      width: 97%; float: right; margin-right: 25px;
      .profile-right-block-one{
        #full-width; background: @white-color; padding: 45px 0px 82px;
        .profile-form-right{
          #full-width; display: inline-block; margin-bottom: 10px;
          h3{
            #full-width; font-size: 18px; color: @sky-start-dark; margin-bottom: 20px; margin-top: 27px;
          }
          p{
            #full-width; font-size: 15px; line-height: 20px; color: @gray-font-color; margin-bottom: 10px;
          }
          .jobs-div{
            width: 60%; float: left; margin-left: 10px;
            .jobs-div-row{
              width: 50%; float: left; margin: 1px 0px;
              label{
                width:100%; margin-right: 2.5%; float: left; font-weight: normal; font-size: 14px; margin-top: 5px; color: @gray-font-color;
                input[type="checkbox"].notCheckedInput{
                  float: left;
                  width: 15px;
                  margin: 2px 10px 0px 0px;
                  height: 15px;
                  background: none;
                  border: 2px solid @sky-low-bright;
                  outline: none;
                  -webkit-appearance: inherit; padding: 7px; border-radius: 2px;
                }
                input[type="checkbox"].checkedInput{
                  float: left;
                  width: 15px;
                  margin: 2px 10px 0 0;
                  height: 15px;
                  background: #105c9d;
                  border: 2px solid #105c9d;
                  outline: 0;
                  -webkit-appearance: inherit;
                  position: relative;
                  font: normal normal normal 14px/1 FontAwesome;
                  padding: 7px;
                  border-radius: 4px;
                }
                input[type="checkbox"].checkedInput:after{
                  content: '\f00c'; position: absolute; top: 1px; left: 1px; font-size: 12px; color: @white-color;
                }
              }
              .delivery-section{
                width: 40%; float: right;
                input{
                  width: 30%; float: left; text-align: center; padding: 6px 0px;
                }
                span{
                  width: 70%; margin-bottom: -1px; float: right; color: @gray-bg-color; font-size: 16px; text-align: right; line-height: 39px;
                }
              }
            }
          }
          .select-days-div{
            #full-width;
            .jobs-div-row{
              width: 60%; float: left; padding-left: 0px;
              label{
                width:auto; position: relative; float: left; font-weight: 300; font-size: 18px; margin-top: 0px; color: @dark-grey; line-height: 39px;
                font-style: italic;
              }
              .delivery-section{
                width: 63%; float: right;
                label{
                  width: 27%; float: left; text-align: center;
                  padding: 1px 0 0px;
                  font-size: 14px;
                  color: @h1-color; font-style: normal;
                }
                span{
                  width: auto; padding: 0 0px; font-style: italic; text-align: center; margin-bottom: -1px; font-weight: 300; float: left; color: @dark-grey; font-size: 16px; line-height: 39px;
                }
              }
            }
          }
          .document-div-left{
            width: 100%; margin-top: 3px; margin-bottom: 6px; float: left; max-width: 60%;
            .upload-file-name{
              margin-top: 0px; color: @h1-color; padding: 0px; margin-bottom: 0px; width: auto; float: left;
              i{
                float: left; margin: 5px;
              }
            }
            .upload-file-name + a{
              #full-width;
              .download-file-name{
                background: @sky-start-dark; margin-top: 0px; color: @white-color; padding: 0px 21px; margin-bottom: 0px; width: auto; float: left;
              }
            }
          }
        }
      }
      .save-change-button{
        #full-width;
        button{
          width: auto; float: right; text-transform: uppercase; padding: 10px 15px; margin:20px 0 38px;  color: @white-color; background: @sky-start-dark; border: 0px;
          font-weight: normal;
          letter-spacing: 1px;
          font-size: 18px;
        }
        button.redColor{
          background: @delay-red-dark;
        }
      }
    }
  }
}
  .pagination-center{
    #full-width; display: inline-block; text-align: center;
    ul {
      width: auto; float: none; display: inline-block;
      li{
        a{
          color: @header-bg;
          &:focus, &:hover {
            background-color: @header-bg;
            border-color: @header-bg;
            color: @white-color;
          }
        }
      }
      li.active {
        a{
          background-color: @header-bg;
          border-color: @header-bg;
          &:focus, &:hover{
            background-color: @header-bg;
            border-color: @header-bg;
            span{
              background-color: @header-bg;
              border-color: @header-bg;
            }
          }
        }
      }
    }
  }

  #invite-lab {
    .modal-dialog{
      width: 700px;
      .modal-content{
        padding: 20px 40px 0px 40px;
        border-radius: 0px;
        background: @white-color;
        box-shadow: 0 5px 15px rgba(0,0,0,.5);
        .modal-header{
          border: 0px; padding: 0px;
          .close{
            opacity: 1; font-size: 30px; margin: -15px -25px;
            &:focus{
              outline: none;
            }
          }
          h4.modal-title{
            font-size: 20px;
            font-weight: bold;
            text-align: left; color: @sky-start-dark; margin-bottom: 0px;
          }
        }
        .modal-body{
          padding-top: 30px; padding-bottom: 30px; overflow: hidden;
          .add-clinic-form{
            #full-width; margin-top: 0px;
            h5{
              font-size: 18px;
              font-weight: bold;
              text-align: right; color: @sky-start-dark; margin-bottom: 0px;
              line-height:34px;
            }


            p{
              text-align: left;
              margin: 0 0 20px;
              width: 100%;
              float: left;
              line-height: 18px;
            }

          }
        }
        .modal-footer{
          border: 0;
          margin: 0 0 10px;
          .btn.btn-default{
            border-radius: 0px; border: solid 1px @sky-start-dark; text-transform: uppercase; font-size:15px; letter-spacing: 1px; font-weight: bold; padding: 10px 10px; color:@sky-start-dark;
            &:hover, &:focus, &.active-tab-close{
              background: @sky-start-dark; color: @white-color;
            }
          }
        }
      }
    }
  }