@media print {
  *,
  *:before,
  *:after {
    box-shadow: none !important;
    text-shadow: none !important;
  }

  @page {
    size: auto;
    margin: 0;
  }
  .mini-print {
    margin: 25px 100px;
  }
  .printableMiniArea {
    margin: 25px 100px;
  }
  .full-page-print {
    margin: 25px 50px;
  }

  .main-div {
    text-align: center;
    font-family: Roboto, sans-serif !important;
  }
  .top-div {
    width: 100%;
    float: left;
    text-align: center;
  }
  .top-div.mini-print {
    width: 100%;
    float: left;
    text-align: left;
  }

  .top-div img {
    width: auto;
    height: 100px;
  }
  .print-date {
    position: absolute;
    top: -10px;
    right: 10px;
    font-size: 15px;
  }
  .print-date p {
    display: inline-block;
    font-size: 11px;
  }
  .info-div {
    float: left;
    width: 100%;
    margin-top: 40px;
  }
  .info-div span {
    float: left;
    width: 100%;
    font-size: 17px;
    margin-top: 12px;
    text-align: left;
  }
  .info-div span h3 {
    display: inline-block;
    margin: 0;
    font-size: 17px;
    font-weight: 700;
  }
  .info-div span p {
    display: inline-block;
    margin: 0;
    font-size: 17px;
  }
  .info-div span.span-two-full {
    float: left;
    width: 100%;
    font-size: 17px;
    margin-top: 25px;
  }
  .info-div span.span-two-full ul {
    float: left;
    width: 100%;
    font-size: 15px;
    margin-top: 0;
    text-align: left;
    padding: 0;
  }
  .info-div span.span-two-full h3 {
    margin: 0;
    width: 100%;
    float: left;
    font-weight: 700;
    font-size: 21px;
  }
  .info-div span.span-two-full ul li {
    list-style: none;
    margin-right: 15px;
    margin-bottom: 10px;
  }
  .info-div span.span-two-full ul li > div {
    display: inline;
  }
  .info-div span.span-two-full ul li span {
    width: auto;
    float: none;
    display: inline;
    margin: 0;
  }
  .info-div span.span-two-full ul li strong {
    display: inline;
    margin: 0;
  }
  .info-div span.span-bottom-sec {
    float: left;
    width: 100%;
    margin-top: 20px;
  }
  .info-div span.span-bottom-sec ul {
    float: left;
    width: 100%;
    padding: 0;
  }
  .info-div span.span-bottom-sec ul li {
    width: auto;
    overflow: hidden;
    float: left;
    margin-right: 10px;
    list-style: none;
    display: inline-block;
  }
  .info-div span.span-bottom-sec ul li img {
    width: auto;
    height: 100px;
  }
  .info-div span.span-two-full ul li > del > div {
    display: inline;
  }

  .info-div span.span-two-full ul.partial-temporary-type li {
    margin-bottom: 0;
  }
  .info-div
    span.span-two-full
    ul.partial-temporary-type
    li:first-child
    div
    span.construction-type {
    display: block;
  }
  .info-div
    span.span-two-full
    ul.partial-temporary-type
    li:first-child
    div
    span.construction-type:after {
    content: '';
  }
  .info-div
    span.span-two-full
    ul.partial-temporary-type
    li
    div
    span.construction-type {
    display: none;
  }
  .info-div span.span-two-full ul.partial-temporary-type li div .move-to-left {
    margin-left: 80px;
  }
  .info-div span.span-two-full ul.partial-temporary-type li del div .move-to-left {
    margin-left: 80px;
  }

  .printableMiniArea.info-div
    span.span-two-full
    ul.partial-temporary-type
    li
    div
    span.construction-type {
    display: none;
  }
  .printableMiniArea.info-div span.span-two-full ul.partial-temporary-type li {
    margin-bottom: 0;
    display: inline;
    margin-right: 3px;
  }
  .printableMiniArea.info-div
    span.span-two-full
    ul.partial-temporary-type
    li:first-child
    div
    span.construction-type {
    display: inline;
  }
  .printableMiniArea.info-div span.span-two-full ul.partial-temporary-type li div {
    display: inline;
  }
  .printableMiniArea.info-div
    span.span-two-full
    ul.partial-temporary-type
    li
    div
    span {
    display: inline;
  }
  .printableMiniArea.info-div
    span.span-two-full
    ul.partial-temporary-type
    li
    div
    span
    p {
    display: inline;
  }
  .printableMiniArea.info-div
    span.span-two-full
    ul.partial-temporary-type
    li:last-child
    div
    span
    p {
    display: none;
  }

  .order-confirm-print {
    text-align: center;
  }
  .order-confirm-print div img {
    width: 100px;
    height: auto;
  }
  .order-confirm-print .order-details span {
    float: left;
    width: 100%;
    font-size: 27px;
    margin-top: 34px;
  }

  .modified {
    color: red !important;
    span {
      color: red !important;
    }
    strong {
      color: red !important;
    }
  }
  .printableMiniArea.info-div span {
    margin-top: 0;
  }
  .printableMiniArea.info-div span > div {
    height: 20px;
  }
  .printableMiniArea.info-div span.span-two-full {
    margin-top: 25px;
  }
  .printableMiniArea.info-div span h3 {
    display: inline-block;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
  }
  .printableMiniArea.info-div span.span-two-full ul {
    font-size: 14px;
    margin-bottom: 25px;
  }
  .printableMiniArea.info-div span p {
    display: inline-block;
    margin: 0;
    font-size: 14px;
  }
  .printableMiniArea.info-div span.span-two-full ul li {
    margin-bottom: 0;
    font-size: 13px;
  }
  .printableMiniArea.info-div span.span-two-full ul li span {
    font-size: 13px;
    margin: 0;
  }

  .sign-send .col-lg-6 {
    width: 50%;
    float: left;
    padding: 0 15px;
  }
  .sign-send .col-lg-6 + .col-lg-6 {
    margin-top: 20px;
  }
  .sign-send .col-lg-6 + .mt-0 {
    margin-top: 0;
  }
  .sign-send .col-lg-3 {
    width: 25%;
  }
  .sign-send .col-lg-6 p {
    padding: 0 15px;
  }
  .sign-send .col-lg-3 img {
    width: 100%;
  }
  .sign-send h2 {
    float: left;
    font-size: 20px;
    color: #1f2532 !important;
    -webkit-print-color-adjust: exact;
    font-weight: 800;
    border-bottom: solid 2px #1f2532;
    width: 100%;
    line-height: 30px;
    padding: 0 15px;
    margin-bottom: 10px;
  }
  .sign-send .action-on-teeth p {
    width: 50%;
    float: left;
    padding: 0 15px;
    font-size: 12px;
  }
  .sign-send p {
    font-size: 12px;
  }
  .sign-send .action-on-teeth p label {
    width: 145px;
    float: left;
    color: #a5a3a3 !important;
    -webkit-print-color-adjust: exact;
  }
  .sign-send .col-lg-6 label {
    width: 145px;
    float: left;
    color: #a5a3a3 !important;
    -webkit-print-color-adjust: exact;
  }
  .sign-send h4 {
    width: 100%;
    float: left;
    margin: 10px 0 10px;
    padding-bottom: 5px;
  }
  .sign-send .action-text {
    display: flex;
    width: 100%;
    float: left;
    padding: 15px 15px 0;
  }
  .sign-send label {
    width: 145px;
    float: left;
    font-weight: 800;
    color: #a5a3a3 !important;
    -webkit-print-color-adjust: exact;
  }
  .sign-send label strong {
    color: #a5a3a3 !important;
    -webkit-print-color-adjust: exact;
  }
  .sign-send .images-upload {
    float: left;
    width: 100%;
    background: #f2f2f2;
    text-align: center;
    margin-bottom: 20px;
    margin-left: 12%;
    margin-top: 43px;
    padding: 41px 20px 40px;
  }
  .extra-margin-on-print-option h2 {
    margin-bottom: 40px;
  }
  .sign-send .files-box h3 {
    font-size: 14px;
    margin-top: 25px;
  }
  .sign-send .col-lg-6 img {
    min-width: 22px;
    height: 22px;
    margin-left: 10px;
    cursor: pointer;
    margin-top: -3px;
  }

  .sign-send .add-materias-actions {
    width: 100%;
    float: left;
  }
  .sign-send .add-materias-actions-section {
    width: 100%;
    float: left;
  }
  .sign-send .added-materials-actions {
    padding: 10px 30px;
  }
  .sign-send .added-materials-actions .order-list-hading {
    font-weight: 500;
    color: #aaa;
    text-align: center;
    font-size: 14px;
    width: 100%;
    float: left;
  }
  .sign-send .added-materials-actions .order-list-hading .col-lg-6 {
    width: 50%;
    float: left;
  }
  .sign-send .added-materials-actions .order-list-hading .col-lg-2 {
    width: 16.66666667%;
    float: left;
  }
  .sign-send .added-materials-actions .order-list-content {
    width: 100%;
    float: left;
    display: flex;
    align-items: flex-end;
    margin: 2px 0;
    position: relative;
    width: 100%;
    float: left;
  }
  .sign-send .added-materials-actions .order-list-content .col-lg-6 {
    width: 50%;
    float: left;
    text-align: left;
  }
  .sign-send .added-materials-actions .order-list-content .col-lg-2 {
    width: 16.66666667%;
    float: left;
    text-align: center;
  }
  .order-list-content.flex-start,
  .order-list-hading,
  .added-action-content,
  .added-action-heading {
    display: flex;
    width: 100% !important;
  }
  .order-list-content.flex-start div,
  .order-list-hading div,
  .added-action-content div,
  .added-action-heading div {
    width: 100%;
    min-width: 100px;
    text-align: center;
  }
  .order-list-content.flex-start div:first-child,
  .order-list-hading div:first-child,
  .added-action-content div:first-child,
  .added-action-heading div:first-child {
    text-align: left;
    min-width: 200px;
    font-weight: 700;
  }
  .order-list-hading div:first-child {
    color: #000;
  }
  .order-list-hading.simple-material-heading div {
    min-width: 150px !important;
  }
  .order-list-content.flex-start.simple-material-content div {
    min-width: 170px !important;
    width: 100%;
    text-align: center;
  }
  .order-list-content.flex-start.simple-material-content div:first-child {
    text-align: left;
  }
  .sign-send .order-list-box {
    width: 100%;
    float: left;
  }
  .sign-send .order-list-box h5 {
    color: #000;
    font-weight: 500;
  }
  .sign-send .order-list-content {
    width: 100%;
    float: left;
    display: flex;
    align-items: flex-end;
    margin: 2px 0;
    position: relative;
  }
  .sign-send .order-list-content .font-manage h5 {
    width: 100%;
    float: left;
    font-size: 14px;
    margin-bottom: 15px;
    color: #000;
  }
  .sign-send .order-list-content .font-manage span {
    width: 100%;
    float: left;
    font-size: 14px;
    margin-bottom: 15px;
    color: #000;
  }
  .sign-send .sending-box {
    width: 100%;
    float: left;
    display: inline-block;
    text-align: center;
    margin: 50px 0 20px;
  }
  .sign-send .sending-box label {
    width: 100%;
    font-size: 20px;
    display: inline-block;
    color: #105c9d !important;
    font-weight: normal;
  }
  .sign-send .action-text .action-perform-text span {
    width: 100%;
    float: left;
    font-weight: normal;
  }

  .sign-send p.comment-p {
    width: 100%;
  }
  .sign-send p.comment-p label {
    width: 80px;
  }
  .sign-send p.comment-p .aside-area {
    width: 90%;
  }

  .OrderDetail2PrintableArea p {
    font-weight: 400;
    margin: 3px 0 0;
    float: left;
    width: 100%;
    line-height: 20px;
    color: #1f2532;
    padding: 0 15px;
    font-size: 14px;
    display: flex;
  }
  .OrderDetail2PrintableArea p label {
    font-weight: 500;
    color: #a5a3a3;
    width: 40%;
    margin-bottom: 2px;
    float: left;
    min-width: 40%;
  }
  .OrderDetail2PrintableArea p .aside-area {
    width: 55%;
    float: left;
  }

  .sign-send
    p.comment-p.steps-manage-area
    .aside-area
    .checkbox-style
    input:checked
    + label:before {
    content: '\2714';
    font-size: 14px;
    text-align: center;
    color: #fff !important;
    background: #105c9d !important;
    width: 23px;
    height: 23px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 3px;
    z-index: 10;
  }
  .sign-send p.comment-p.steps-manage-area .aside-area .checkbox-style label {
    color: #1f2532 !important;
  }

  .confirm-print .printableMiniArea h3 {
    width: 100% !important;
    float: left !important;
    display: block !important;
  }
  .confirm-print .printableMiniArea p {
    width: 100% !important;
    float: left !important;
    display: block !important;
  }

  .sign-send p.comment-p.additional-information label {
    width: auto;
    float: left;
    min-width: 22%;
  }
  .sign-send p.comment-p.additional-information span {
    float: left;
    width: 78%;
    margin-left: 0;
  }

  .sample-span-ui p.comment-p.additional-information span.remove-m-w {
    width: auto;
    margin-left: 1%;
    white-space: nowrap;
    font-size: 11px;
    padding-right: 0;
  }
  .sample-content-comma span {
    position: relative;
  }
  .sample-content-comma span:after {
    position: absolute;
    right: -5px;
    bottom: 0;
    content: ',';
  }
  .sample-content-comma span:last-child:after {
    display: none;
  }
  .sample-span-ui .additional-information span.remove-m-w {
    width: 80%;
    display: inline-block;
    margin-left: 0 !important;
    margin-right: 10px;
  }
  .action-display-block {
    display: flex !important;
  }
}
