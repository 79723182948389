#manage-labs {
  #full-width;
  .container {
    margin-top: 180px;
    padding-bottom: 50px;
    background: @white-color;
  }
  .manage-labs {
    #full-width;
    .actions-header {
      #full-width;
      padding: 40px 20px 5px;
      border-bottom: solid 2px @post-block-color;
      color: @post-block-color;
      font-size: 20px;
      font-weight: 600;
    }
    .lab-logo-name {
      #full-width;
      margin-top: 45px;
      display: flex;
      img {
        height: 60px;
        margin-right: 35px;
      }
      h2 {
        font-weight: bold;
        font-size: 32px;
        color: @h1-color;
        padding-top: 13px;
      }
    }
    .categories-section {
      #full-width;
      .section-header {
        #full-width;
        padding: 40px 20px 0;
        color: @h1-color;
        font-size: 18px;
        font-weight: 500;
      }
      .category-section-block {
        #full-width;
        h4 {
          width: auto;
          float: left;
          padding: 5px 25px;
          margin: 20px 20px 5px 5px;
          color: @h1-color;
          font-weight: 600;
          line-height: 39px;
        }
        select {
          width: 100%;
          float: left;
          padding: 4px 2px 4px 10px;
          margin: 5px;
          border: solid 1px @gray-bg-color;
          color: @header-bg;
          font-weight: 600;
          &:focus {
            outline: none;
          }
          &.tech-admin-options {
            width: 220px;
            padding: 8px 10px 8px 10px;
            background: @gray-light-white;
            color: @post-block-color;
            font-weight: normal;
            + button {
              padding: 3px 10px;
              margin: 11px !important;
              font-size: 12px;
              &:disabled {
                opacity: 0.4;
                cursor: not-allowed;
              }
            }
          }
        }
        ul {
          width: auto;
          float: left;
          margin-right: 40px;
          li {
            width: auto;
            float: left;
            padding: 4px 2px 4px 10px;
            height: 39px;
            margin: 5px;
            border: solid 1px @gray-bg-color;
            color: @header-bg;
            font-weight: 600;
            cursor: pointer;
            i {
              float: left;
              margin-top: 6px;
            }
            input {
              border: 0;
              background: 0;
              box-shadow: none;
              padding-left: 10px;
              font-weight: normal;
              color: @h1-color;
              line-height: 29px;
              height: 29px;
              &:focus {
                outline: none;
              }
            }
          }
        }
        button {
          width: auto;
          float: left;
          padding: 5px 20px;
          margin: 5px;
          border: solid 1px @header-bg;
          color: @header-bg;
          background: none;
          font-weight: 600;
          i {
            font-size: 22px;
            margin-top: 2px;
            float: left;
            margin-right: 15px;
          }
          &.pull-right {
            padding: 2px 50px;
          }
        }
        .several-options {
          width: 180px;
          float: left;
          margin: 5px;
          select {
            padding: 11px !important;
          }
        }
        .multiselect-parent {
          width: 100%;
          button.dropdown-toggle {
            #full-width;
            padding: 5px 6px;
            margin-left: 0;
            text-align: center;
            border: solid 1px @gray-font-color;
            color: @gray-font-color;
            font-size: 14px;
            background: @light-grey;
            border-radius: 3px;
            box-shadow: none;
            .caret {
              float: right;
              margin-top: 8px;
              border-top: 6px dashed;
              border-right: 3px solid transparent;
              border-left: 3px solid transparent;
            }
          }
          ul.dropdown-menu.dropdown-menu-form {
            width: 100%;
            padding: 0;
            margin: 0;
            background: #eee;
            border-radius: 0;
            max-height: 40vh;
            z-index: 10;
            &::-webkit-scrollbar-track {
              background-color: #ccc;
            }
            &::-webkit-scrollbar {
              width: 6px;
              background-color: #ccc;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #105c9d;
            }
            li {
              width: 100%;
              float: left;
              padding: 0;
              margin: 0;
              border-bottom: solid 1px #fff;
              color: #105c9d;
              font-weight: 600;
              cursor: pointer;
              &:nth-child(1),
              &:nth-child(2) {
                width: 50%;
                a {
                  padding: 8px 0;
                  font-size: 12px;
                  #full-width;
                }
              }
              &:nth-child(3) {
                display: none;
              }
              a {
                width: 100%;
                float: left;
                white-space: inherit;
                padding: 8px 20px;
                .checkbox {
                  margin: 0;
                  label {
                    display: flex;
                    align-items: center;
                    padding: 0;
                    input[type="checkbox"]:not(:checked) {
                      margin: 0 20px 0 0;
                      border: 1px solid #ccc;
                      outline: none;
                      -webkit-appearance: inherit;
                      padding: 10px;
                      position: static;
                      cursor: pointer;
                      display: inline;
                      border-radius: 3px;
                      background: #fff;
                    }
                    input[type="checkbox"]:checked {
                      margin: 0 20px 0 0;
                      border: 1px solid #105c9d;
                      outline: none;
                      cursor: pointer;
                      -webkit-appearance: inherit;
                      position: relative;
                      font: normal normal normal 14px/1 FontAwesome;
                      padding: 10px;
                      display: inline;
                      background: #105c9d;
                      border-radius: 3px;
                    }
                    input[type="checkbox"]:checked:after {
                      content: '\f00c';
                      position: absolute;
                      top: 5px;
                      left: 4px;
                      font-size: 12px;
                      color: #fff;
                    }
                  }
                }
                &:hover {
                  color: #105c9d;
                }
                &:focus {
                  outline: none;
                }
              }
              &.active {
                a {
                  background: #ddd;
                  color: #000;
                  padding: 8px 20px;
                }
              }
            }
          }
        }
        .main-category-list {
          #full-width;
          .main-category-list-item {
            #full-width;
          }
        }
        .selected-category-item {
          width: auto;
          float: left;
          padding: 8px 30px;
          margin: 5px;
          border: solid 1px @header-bg;
          color: @header-bg;
          background: none;
          font-weight: 600;
          border-radius: 0;
          cursor: pointer;
        }
      }
      &.edit-labs-select {
        margin-top: 30px;
        .category-section-block {
          select {
            padding: 8px 10px;
            margin: 0;
          }
        }
      }
    }
    .lab-report-section{
      #full-width;
      margin-top: 60px;
      .lab-report-head{
        #full-width; background: @sky-start-dark; padding: 7px 0px;
        .first-sec{
          width: 40%; font-weight: bold; float: left; border-right: solid 1px @white-color; text-align: center; font-size: 18px; color: @white-color;
          span{
            width: auto; display: inline-block;
            i{
              float: left; top: -3px; font-size: 15px; position: relative;
            }
            i+i{
              top: 5px; right:-1px;
            }
          }
        }
        .second-sec{
          width: 15%; font-weight: bold; float: left; border-right: solid 1px @white-color; text-align: center; font-size: 18px; color: @white-color;
        }
        .third-sec{
          width: 15%; font-weight: bold; float: left; border-right: solid 1px @white-color; text-align: center; font-size: 18px; color: @white-color;
        }
        .fourth-sec{
          width: 15%; font-weight: bold; float: left; border-right: solid 1px @white-color; text-align: center; font-size: 18px; color: @white-color;
        }
        .fifth-sec{
          width: 15%; font-weight: bold; float: left; text-align: center; font-size: 18px; color: @white-color;
        }
        &.text-color-white {
          color: @white-color;
        }
      }
      .lab-report-body{
        #full-width;
        .repeated-div-report{
          #full-width; background: @white-color; padding: 12px 0px; border-bottom: solid 1px @gray-d-line;
          width: 100%; float: left; cursor: pointer;
          .first-sec{
            width: 40%; float: left; text-align: center; color: @h1-color; font-weight: normal;
            .one-by-two{
              width: 50%; float: left; font-size: 13px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden; font-weight: normal;
              img{
                height: 40px; width:auto;
              }
              &.public-lab {
                width: 100% !important;
              }
            }
            .one-by-two+.one-by-two{
              margin-top:13px;
            }
          }
          .second-sec{
            width: 15%; float: left; text-align: center; font-size: 13px;
            overflow: hidden;
            margin-top: 13px;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-height: 16px;
            max-height: 48px;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            color: @h1-color; font-weight: normal;
          }
          .third-sec{
            width: 15%; float: left; text-align: left; font-size: 13px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden; margin-top:13px; padding:0 15px;
            color: @h1-color; font-weight: normal;
          }
          .fourth-sec{
            width: 15%; float: left; text-align: center;
            input[type="button"] {
              width: auto;
              display: inline-block;
              padding: 5px 20px;
              margin: 5px;
              border: solid 1px @header-bg;
              color: @header-bg;
              background: none;
              font-weight: 600;
              font-size: 13px;
              text-transform: uppercase;
            }
          }
          .fifth-sec {
            width: 15%; float: left; text-align: center;
            input[type="button"] {
              width: auto;
              display: inline-block;
              padding: 5px 20px;
              margin: 5px;
              border: solid 1px @header-bg;
              color: @header-bg;
              background: none;
              font-weight: 600;
              font-size: 13px;
              text-transform: uppercase;
            }
          }
          .disabled-lab{
            width: 45%; float: left; text-align: center; font-size: 18px;
            overflow: hidden; margin-top: 10px; padding: 0 5px;
            color: @dark-red-color; text-overflow: ellipsis; font-weight: 500;
          }
          .head-bg {
            background: @gray-bg-color;
          }
        }
      }
    }
    .no-data-found{
      text-align: center;
    }
    .lab-availability {
      #full-width;
      margin-top: 45px;
      .head-lab-avail {
        #full-width;
        ul {
          #full-width;
          margin: 0;
          padding: 0;
          li {
            float: left;
            line-height: 18px;
            width: 70px;
            text-align: left;
            font-size: 12px;
            &:first-child {
              font-weight: bold;
            }
            &:nth-child(2) {
              text-align: center;
            }
          }
        }
      }
      .body-lab-avail {
        #full-width;
        ul {
          #full-width;
          margin: 3px 0 0;
          padding: 0;
          li {
            width: auto;
            float: left;
            height: 24px;
            min-width: 70px;
            text-align: center;
            border: solid 1px @gray-bg-color;
            border-right: 0;
            font-size: 13px;
            &:first-child {
              font-weight: bold;
            }
            &:last-child {
              border: 0;
            }
            &.border-0 {
              border: 0;
            }
            ul {
              margin: 0;
              li {
                min-width: 32px;
                &:last-child {
                  border: solid 1px @gray-bg-color;
                }
                &.available-date {
                  background: @available-date;
                  font-size: 0;
                }
                &.unavailable-date {
                  background: @unavailable-date;
                  font-size: 0;
                }
                &.white {
                  background: @white;
                  font-size: 0;
                }
              }
            }
          }
        }
      }
    }
    .enable-disable-button {
      button {
        width: auto;
        float: left;
        padding: 8px 25px;
        margin: 50px 5px 5px;
        border: solid 1px @header-bg;
        color: @header-bg;
        background: none;
        font-weight: 600;
        border-radius: 0;
        i {
          font-size: 22px;
          margin-top: 2px;
          float: left;
          margin-right: 15px;
        }
      }
    }
    .lab-info {
      #full-width;
      margin-top: 65px;
      background: @white-color;
      .lab-info-heading {
        #full-width;
        background: @header-bg;
        line-height: 36px;
        font-weight: 500;
        color: @white-color;
      }
      .lab-info-item {
        #full-width;
        line-height: 36px;
        border-bottom: solid 1px @dark-grey;
        font-weight: 500;
        color: @gray-font-color;
        font-size: 14px;
        &:nth-child(2n) {
          background: @light-grey;
        }
      }
    }
    .edit-connected-labs-table {
      #full-width;
      margin-top: 20px;
      .head-table {
        #full-width;
        background: @light-grey;
        line-height: 36px;
        font-weight: 500;
        color: @h1-color;
        text-align: center;
      }
      .body-table {
        #full-width;
        margin-bottom: 25px;
        max-height: 450px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
        }
        &::-webkit-scrollbar-thumb {
          background-color: @header-bg;
          outline: 1px solid slategrey;
        }
        .repeat-lis {
          #full-width;
          line-height: 30px;
          border-bottom: solid 1px @dark-grey;
          color: @gray-font-color;
          font-size: 14px;
          text-align: center;
          &:nth-child(2n) {
            background: @light-grey;
          }
          input {
            #full-width;
            min-height: 20px;
            line-height: 20px;
            margin-top: 4px;
            border-radius: 3px;
            border: solid 1px #ccc;
            padding: 0 15px;
            box-sizing: border-box;
          }
        }
        input[type="checkbox"]:not(:checked) {
          border: 1px solid @sky-start-dark;
          outline: none;
          -webkit-appearance: inherit;
          position: static;
          cursor: pointer;
          display: inline;
          float: left;
          width: 24px;
          height: 24px;
          padding: 0;
          margin: 0 10px 0 0;
        }
        input[type="checkbox"]:checked {
          border: 1px solid @sky-start-dark;
          outline: none;
          cursor: pointer;
          -webkit-appearance: inherit;
          position: relative;
          font: normal normal normal 14px/1 FontAwesome;
          display: inline;
          background: @sky-start-dark;
          float: left;
          width: 24px;
          height: 24px;
          padding: 0;
          margin: 0 10px 0 0;
        }
        input[type="checkbox"]:checked:after {
          content: '\f00c';
          position: absolute;
          top: 7px;
          left: 7px;
          font-size: 16px;
          color: @white-color;
        }
        label.checkbox-style{
          width:auto;
          margin: 3px 0;
        }
        input[type="checkbox"]:checked + label:before,
        input.checkedInput + label:before,
        input.chooseLAb.active[type="radio"] + label:before {
          z-index: 8;
        }
      }
      button {
        width: auto;
        float: right;
        padding: 8px 30px;
        margin: 5px;
        border: solid 1px @header-bg;
        color: @header-bg;
        background: none;
        font-weight: 600;
        border-radius: 0;
        i {
          font-size: 20px;
          margin-top: 0px;
          float: right;
          margin-left: 30px;
        }
      }
      button + button {
        margin-right: 50px;
      }
    }
  }
}

#connect-to-lab-modal, #three-shape-upload-modal, #three-shape-scan-view {
  .modal-dialog {
    width: 700px;
    background: @white-color;
    overflow: hidden;
    .modal-header {
      border: 0;
      h4 {
        color: @header-bg;
      }
    }
    .lab-search-input {
      #full-width;
      margin: 25px 0;
      position: relative;
      input {
        width: 300px;
        float: left;
        border: solid 1px @light-grey;
        line-height: 42px;
        padding: 0 20px 0 60px;
        height: 44px;
      }
      i {
        position: absolute;
        left: 18px;
        top: 12px;
      }
    }
    .lab-list-heading {
      #full-width;
      background: @light-grey;
      border-bottom: solid 1px @gray-d-line;
      display: flex;
      align-items: center;
      height: 45px;
      justify-content: center;
      .font-manage {
        text-align: center;
      }
      color: #000;
      font-weight: bold;
    }
    .lab-list-box {
      max-height: 45vh;
      float: left;
      z-index: 999999;
      position: relative;
      overflow-y: auto;
      .lab-list-content {
        #full-width;
        display: flex;
        align-items: center;
        height: 45px;
        justify-content: center;
        border-bottom: solid 1px @gray-d-line;
        .mobi-manage-content {
          text-align: center;
          label.checkbox-style{
            width: 24px;
            font-weight: normal;
            font-size: 14px;
            position: relative;
            height: 24px;
            display: inline-block;
            margin: 0;
            float: none;
            label {
              color: @h1-color !important;
              margin-left: 0 !important;
              font-weight: 400;
            }
            input[type="checkbox"] {
              position: absolute !important;
              top: 0;
              left: 0;
              z-index: 11;
              opacity: 0;
              width: 100%;
              height: 100%;
              margin: 0;
            }
            input[type="checkbox"] + label {
              position: relative;
              padding-left: 30px;
              line-height: 23px;
              float: left;
            }
            input[type="checkbox"] + label:after {
              width: 23px;
              height: 23px;
              content: '';
              border: solid 2px @gray-d-line;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 9;
              border-radius: 3px;
            }
            input[type="checkbox"]:checked + label:after {
              border-color: @sky-dark;
            }
            input[type="checkbox"]:checked + label:before {
              content: '\2714';
              font-size: 14px;
              text-align: center;
              color: #fff !important;
              background: @sky-dark;
              width: 23px;
              height: 23px;
              position: absolute;
              left: 0;
              border-radius: 3px;
              z-index: 10;
            }
          }
        }
        &:nth-child(2n) {
          background: @light-grey;
        }
        &:hover {
          background: @dark-grey;
        }
      }
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
      }
      &::-webkit-scrollbar-thumb {
        background-color: @header-bg;
        outline: 1px solid slategrey;
      }
    }
    .modal-footer {
      float: right;
      button {
        width: auto;
        float: right;
        padding: 8px 32px;
        margin: 20px 5px 12px;
        border: solid 1px @header-bg;
        color: @header-bg;
        background: none;
        font-weight: 600;
      }
    }
  }
}

@media (max-width: 767px) {
  #manage-labs {
    .container {
      margin-top: 20px;
      padding-bottom: 20px;
    }
    .manage-labs {
      .categories-section {
        .category-section-block {
          ul {
            margin-right: 0;
            li {
              input {
                width: 88%;
              }
            }
          }
        }
      }
      .lab-report-section, .lab-availability, .edit-connected-labs-table {
        width: 100%;
        overflow-x: auto;
        .manage-scroll-mobile {
          width: 1170px;
        }
      }
      .lab-logo-name {
        img {
          margin-right: 10px;
        }
        h2 {
          font-size: 18px;
        }
      }
      .lab-info {
        .lab-info-item {
          line-height: 24px;
          font-size: 12px;
        }
      }
    }
  }
  #connect-to-lab-modal {
    .modal-dialog {
      width: 94%;
    }
  }
}

.labRanking {
  margin-bottom: 10px;
  input {
    margin-left: 15px;
  }
}

.lab-avail-month {
  input {
    width: 100%;
    float: left;
  }
  i {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.input-margin {
  margin: 6px 0 30px;
}

.lab-avail-month-dropdown-menu {
  top: 30px;
  width: 120%;
  ul {
    max-height: 300px;
    overflow-y: auto;
  }
  li {
    .checkbox-style {
      padding: 10px 20px;
      width: 100%;
      background: #eee;
      margin-bottom: 0;
      border-bottom: 1px solid #ddd;
      cursor: pointer;
    }
    span {
      background: #ddd;
      padding: 15px;
      float: left;
      width: 50%;
      position: relative;
      border-right: 2px solid #eee;
      input{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
}

