#order-list {
  width: 100%;
  float: left;
  margin-bottom: 20px;
  min-height: 97vh;
  .order-list-content {
    padding: 10px 0;
    float: left;
    width: 100%;
    min-height: 64px;
    cursor: pointer;
    &.user-list-content {
      min-height: 35px;
      padding: 6px 0 0;
      cursor: default;
    }
  }
  .order-list {
    float: left;
    width: 100%;
    background: #fff;
    margin-top: 150px;
    border: solid 1px #d0d0d0;
    box-shadow: 0 0 3px #ebebeb;
    text-align: center;
    &.user-dentdata{
      text-align:left;
      .order-list-content .mobi-manage-content:first-child{
        .font-manage {
          padding-left: 15px;
        }
      }
    }
    .no-padding-default {
      padding: 0;
    }
    .order-list-hading {
      background: #105c9d;
      float: left;
      width: 100%;
      color: #fff;
      padding: 14px 0px;
      font-size: 18px;
      font-weight: bold;
      i {
        margin-left: 10px;

       /* &:last-child {
          position: absolute;
          top: 17px;
          right: 66px;
        }*/
      }
      .col-lg-2{
        width: 14.25%;
      }
      .col-md-2 {
        width: 14.25%;
      }
      .col-sm-2{
        width: 14.25%;
      }
    }
    .order-list-box {
      padding: 15px;
      border-bottom: solid 1px #d4d4d4;
      &:last-child{ border: 0;}

      p {
        margin: 0;
        padding: 0;
        font-size: 13px;
        color: #000;
        font-weight: bold;
      }
      i {
        font-size: 30px;
        transform: scaleX(-1);
        &.green-truck {
          color: #8dc44d;
          + .digital-sample {
            margin: -12px 5px 0;
            height: 25px;
          }
        }
        &.red-truck {
          color: #ff0415;
          + .digital-sample {
            margin: -12px 5px 0;
            height: 25px;
          }
        }
      }
      .digital-sample {
        margin: 0 5px;
        height: 25px;
      }
      p.manageWidth{
        width: 100%; float: left; padding-left: 0;
      }
      &:nth-child(2n+1) {
        .order-list-content {
          &.user-list-content {
            background: @light-grey-list-view !important;
          }
        }
      }
    }

    .order-list-content{
      background: #fff;
      .mobi-manage-content{
        .col-lg-2{
          width: 14.25%;
        }
        .col-md-2 {
          width: 14.25%;
        }
        .col-sm-2{
          width: 14.25%;
        }
        .font-manage {
          .ngrateit{
            width: 100%; float:left;
          }
          .lab-flag-icon {
            position: absolute;
            left: 4px;
            top: 13px;
            font-size: 18px;
            color: #ff0000;
            margin: 0 5px;
            transform: none;
            height: 25px;
            + .lab-flag-icon {
              top: 21px;
            }
            &.two-icon {
              top: 0;
            }
          }
        }
        &.add-edit-css {
          .font-manage {
            .clinic-active{
              width: auto;
              float: right;
              text-transform: uppercase;
              font-size: 13px;
              position: relative;
              z-index: 9;
              line-height: 25px;
              font-weight: bold;
              color: #000;
              .active-inactive-content {
                min-width: 60px;
                float: right;
                text-align: left;
                &.margin-us-ex-add {
                  margin-left: 0;
                  margin-right: -20px;
                }
                &.margin-cl-ex-add {
                  margin-left: 0;
                  margin-right: -50px;
                }
              }
              .round-active-clinic {
                width: 20px;
                height: 20px;
                -webkit-border-radius: 100%;
                -moz-border-radius: 100%;
                border-radius: 100%;
                background: @active-tab-green;
                float: left;
                margin: 2px 10px 0 0;
              }
              .round-inactive-clinic {
                width: 20px;
                height: 20px;
                -webkit-border-radius: 100%;
                -moz-border-radius: 100%;
                border-radius: 100%;
                background: @dark-red-color;
                float: left;
                margin: 2px 10px 0 0;
              }
              @media (max-width: 767px) {
                margin-left: 5px;
              }
            }
            a {
              color: @sky-start-dark;
              border: solid 1px @sky-start-dark;
              padding: 3px 5px;
              margin-left: 0;
              font-size: 12px;
              text-transform: uppercase;
              &:hover {
                color: @white-color;
                background: @sky-start-dark;
              }
            }
            @media (max-width: 767px) {
              width: auto !important;
            }
          }
          .font-manage.clinic-active-button {
            .clinic-active {
              float: right;
            }
          }
        }
        &.multi-org-manage {
          .multiple-check-organization {
            li {
              font-weight: bold;
              font-size: 13px;
              color: #000;
            }
          }
        }
        &:first-child {
          .font-manage {
            padding-left: 35px;
          }
        }
      }
      &.order-list-bg {
        background: #ffeef0;
        float: left;
        width: 100%;
        padding: 10px 0;

        span.position-relative{
          position: relative;
          float: right;
          i{color: #f5d823; font-size: 21px;

          }
          span.Notifications-notes {
            position: absolute;
            color: #fff;
            top: -8px;
            background: #ec0b27;
            width: 14px;
            height: 15px;
            border-radius: 100%;
            font-size: 10px;
            padding-top: 0px;
            right: -5px;
          }
        }
        p {
          margin: 0;
          padding: 0;
          font-size: 13px;
          color: #000;
          -webkit-align-items: center;
          align-items: center;
        }


      }
    }

  }

}


.order-list-bg{
  background: @light-red-color;


}

.dentist-right {
  float: right;
  width: 17%;
  text-align: right;
  padding-top: 7px;
  font-size: 19px;
  position: relative;
  margin-top: 5px;
}

.dentist-right i {
  font-size: 25px;
  color: #f5d823;
}

.dentist-right span {
  position: absolute;
  width: auto;
  padding: 0 6px;
  background: #d0021b;
  border-radius: 100%;
  font-size: 11px;
  color: #fff;
  top: -4px;
  right: -7px;
}

p.manageWidth.red-astrix-mark {
  display: inline;
  color: @delay-red-dark !important;
}

#order-list {
  .container {
    position: relative;
    span.spinner {
      position: absolute;
      top: 300px;
      left: 50%;

    }
  }
}

.scroll-spinner {
  position: fixed;
  bottom: 58px;
  left: 50%;
}

.container.status-view {
  position: relative;
  span.spinner {
    position: absolute;
    top: 300px;
    left: 50%;

  }
}

.hidden-content {
  opacity: 0;
}

.green-color {
  color: #8dc44d;
}

#order-list .order-list .order-list-content .mobi-manage-content:first-child .font-manage.left-align {
  padding-left: 15px;
}

@media (max-width: 767px) {
  #order-list .order-list .order-list-content .mobi-manage-content:first-child .font-manage.permissions-div {
    text-align: left;
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  #order-list .order-list .order-list-content .mobi-manage-content:first-child .font-manage.left-align {
    padding-left: 2px;
  }
}

@media only screen and (max-width: 1169px) and (min-width: 992px) {
  #order-list .order-list .order-list-content .mobi-manage-content:first-child .font-manage.left-align {
    padding-left: 2px;
  }
}

.position-initial {
  position: initial !important;
}

