// TODO: Used only for non migrated order flow.
// Once all parts of order flow is migrated, remove this one.

.construction-pointer {
  cursor: pointer;
}

#construction-section.order-two-part .SearchPatient.newConstructionType {
  width: 100%;
  float: left;
  display: inline-block;
  text-align: center;
}
#construction-section.order-two-part .SearchPatient.newConstructionType ul {
  float: none;
  width: auto;
  display: inline-block;
  margin-top: 15px;
}

#construction-section.order-two-part .SearchPatient.newConstructionType span {
  float: left;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  margin: 20px 0;
}

#construction-section.order-two-part .SearchPatient.newConstructionType li {
  float: left;
  width: auto;
  text-align: center;
  border: solid 0 @sky-start-dark;
  height: 32px;
  margin: 0 10px 5px;
}
#construction-section.order-two-part .SearchPatient.newConstructionType li a {
  color: @sky-start-dark;
  border: solid 1px @sky-start-dark;
  padding: 0;
  font-size: 16px;
  min-width: 150px;
}
#construction-section.order-two-part .SearchPatient.newConstructionType li.active a {
  font-weight: bold;
  background-color: @header-bg;
  width: 100%;
  float: left;
  color: @white-color;
}

#construction-section.order-two-part .right-sec-head .btn-default {
  font-size: 22px;
  border: 0;
  padding-right: 0;
  color: #000;
}
#construction-section.order-two-part .right-sec-head .btn-default:hover {
  background: none;
  color: #000;
}
#construction-section.order-two-part .align-btn .required-field {
  margin-top: -40px;
  position: static !important;
}
#construction-section.order-two-part .align-btn button {
  width: 120px;
  float: right;
  border: solid 1px @sky-start-dark;
  line-height: 32px;
  color: @sky-start-dark;
  cursor: pointer;
  font-weight: 500;
  background: none;
  opacity: 1;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}
#construction-section.order-two-part .align-btn button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

#construction-section.order-two-part .SelectMaterial p.red-astrix-mark,
.color-sampling-radio p.red-astrix-mark {
  margin: 0;
  line-height: 16px;
}
#construction-section.order-two-part .SelectMaterial.remove-margin-top {
  margin-top: 0;
}

#construction-section ul.singleSub-odr-2 {
  float: none;
  width: auto;
  margin-top: 5px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
}

#construction-section ul.singleSub-odr-2 li {
  margin: 0 10px 5px;
  width: auto;
  min-width: 150px;
}

#construction-section ul.singleSub-odr-2 li a {
  /*border: solid 1px @sky-start-dark !important;*/
  font-weight: 400 !important;
  text-transform: uppercase;
  padding: 4px;
  font-size: 14px;
}

#construction-section ul.singleSub-odr-2 li.active a {
  box-shadow: 0 2px 2px rgba(8, 8, 8, 0.66);
  font-weight: bold !important;
}
#construction-section.order-two-part h1 {
  color: inherit;
}

#construction-section
  .manage-flex-boxes
  .SelectMaterial.designBox.img-width-change
  ul {
  margin-top: 1px;
}
#construction-section
  .manage-flex-boxes
  .SelectMaterial.designBox.img-width-change
  ul
  li {
  margin-bottom: 0;
  border-bottom: solid 1px #ccc;
}
#construction-section
  .manage-flex-boxes
  .SelectMaterial.designBox.img-width-change
  ul
  li
  a {
  padding: 6px 10px;
  height: 36px;
}

#construction-section .SelectMaterial.colorBox ul li.orthColorSelect select {
  padding: 8.5px 0;
}

#construction-section.order-two-part .clickonBoth {
  width: 80%;
  margin-left: 10%;
}
.manage-div-smooth {
  width: 100%;
  float: left;
}

/*Constructions css*/
#construction-section {
  width: 100%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

#construction-section.simple-order-section {
  margin-top: 0;
}

@media only screen and (min-width: 787px) {
  .cons-sec-modify {
    margin-top: 120px;
  }
}

#construction-section .Constructions {
  #section-page;
  padding-bottom: 50px;
}

@media only screen and (min-width: 787px) {
  .cons-sec-modify {
    margin-top: 120px;
  }
}

#construction-section h1.patientTitle {
  #section-heading;
}

#construction-section .SearchPatient {
  float: left;
  width: 100%;
  padding: 5px;
  //padding-top: 10px;
}

#construction-section .SearchPatient.margin-less-top {
  padding-top: 0px;
}

#construction-section .SearchPatient ul {
  float: left;
  width: 100%;
}

#construction-section .SearchPatient li {
  float: left;
  width: 49%;
  text-align: center;
}

//#construction-section .SearchPatient li:last-child {
//  float: right
//}

#construction-section .SearchPatient li a {
  font-size: 18px;
  text-transform: uppercase;
  color: #969696;
  border-bottom: solid 5px @white-color;
  width: 100%;
  float: left;
  text-decoration: none;
}

#construction-section .SearchPatient li.active a {
  font-weight: bold;
  border-bottom: solid 5px @header-bg;
  width: 100%;
  float: left;
  color: @header-bg;
}

#construction-section .constructions-sub-types {
  width: 100%;
  float: left;
  text-align: center;
  display: inline-block;
}

#construction-section .SearchPatient li.active a:focus {
  outline: none;
}

#construction-section ul.singleSub {
  float: none;
  width: auto;
  margin-top: 5px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
}

#construction-section ul.singleSub li {
  margin: 0 5px;
  width: 155px;
}

#construction-section ul.singleSub li a {
  border: 0 !important;
  font-weight: 400 !important;
  color: #000 !important;
  text-transform: uppercase;
  padding: 4px 0;
  font-size: 14px;
}

#construction-section ul.singleSub li.active a {
  color: @white-color !important;
  box-shadow: 0px 2px 2px rgba(8, 8, 8, 0.66);
  font-weight: bold !important;
}

#construction-section ul.implantSub {
  float: right;
  width: 100%;
  margin-top: 30px;
}

#construction-section ul.implantSub li {
  margin-right: 10px;
  width: 49%;
}

#construction-section ul.implantSub li:last-child {
  margin-right: 0;
}

#construction-section ul.implantSub li a {
  border: 0 !important;
  background: #f4f4f4;
  font-weight: normal !important;
  color: #000 !important;
  text-transform: uppercase;
  padding: 10px 15px;
  font-size: 19px;
}

#construction-section ul.implantSub li.active a {
  background: #a6d273;
  color: @white-color !important;
  box-shadow: 0px 2px 2px rgba(8, 8, 8, 0.66);
  font-weight: bold !important;
}

#construction-section .clickonBoth {
  float: left;
  width: 100%;
  margin-top: 0px;
  margin-left: 0;
}
#construction-section .clickonBoth .well-sm.text-center p {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 500;
}
#construction-section .clickonBoth h1 {
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 35px;
  font-weight: 400;
  color: #1f2532;
}

#construction-section .btn span.glyphicon {
  opacity: 0;
}

#construction-section .btn.active span.glyphicon {
  opacity: 1;
}

#construction-section label.btn {
  background: @white-color;
  border: solid 1px #ccc;
  border-radius: 4px;
  width: 57px;
  height: 57px;
}

#construction-section label.btn.active {
  background: @light-gray-yellow;
  color: @white-color;
  border: solid 1px #ccc;
  box-shadow: none;
  position: relative;
  &:after {
    width: 0;
    height: 0;
    border-bottom: 46px solid @sky-start-dark;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    position: absolute;
    right: 22.5px;
    bottom: -3px;
    content: '';
  }
}

#construction-section label.btn:focus,
#construction-section label.btn.focus {
  outline: none !important;
  box-shadow: none;
}

#construction-section
  .btn-group
  > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

#construction-section .btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

#construction-section .btn-group {
  //margin-right: 4px;
}

#construction-section p.range {
  position: absolute;
  bottom: 0px;
  left: -53px;
  text-transform: uppercase;
  font-size: 14px;
}

#construction-section .well-sm.text-center.marginBottom {
  margin-top: 35px;
}

#construction-section .well-sm {
  padding: 0;
}

#construction-section #bridgeTeeth {
  float: left;
  width: 100%;
  margin: 30px 0 15px;
  padding-bottom: 10px;
}

#construction-section .divider-new {
  font-weight: 300;
  margin-top: 45px;
  margin-bottom: 5px;
  float: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#construction-section .divider-new h1 {
  text-transform: uppercase;
  font-size: 22px;
  color: #4a4a4a;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 0;
}

#construction-section .divider-new::after {
  content: '';
  height: 1px;
  background: #868686;
  flex: 1;
  margin: 11px 0 0 0.45em;
}

#construction-section .divider-new::before {
  content: '';
  height: 1px;
  background: #868686;
  flex: 1;
  margin: 11px 0.45em 0 0;
}

#construction-section .SelectMaterial {
  float: left;
  width: 100%;
  margin-top: 10px;
}

#construction-section .SelectMaterial h3 {
  font-size: 15px;
  margin-top: 10px;
}

#construction-section .SelectMaterial ul {
  width: 100%;
  float: left;
  margin-top: 15px;
}

#construction-section .SelectMaterial ul li {
  float: left;
  width: auto;
  min-width: 19%;
  //background: #eeecec;

  margin-right: 4px;
  text-align: center;
  margin-bottom: 12px;
  cursor: pointer;
}

#construction-section .SelectMaterial ul li a {
  color: #5a5959;
  padding: 4px 3px;
  width: 100%;
  float: left;
  min-width: 70px;
  font-size: 13px;
  height: 30px;
}

#construction-section .SelectMaterial ul li.active {
  background: #105c9d;
}

#construction-section .SelectMaterial.designBox.img-width-change ul li img {
  width: 20px;
}

#construction-section .SelectMaterial ul li.active a {
  color: @white-color;
}

#construction-section .SelectMaterial.colorBox ul li {
  width: 35%;
}

#construction-section .SelectMaterial.colorBox ul li {
  width: 100%;
}

#construction-section .SelectMaterial.colorBox ul li select {
  width: 100%;
  border: 0;
  padding: 5px 0px;
  background: transparent;
  width: 100%;
  float: left;
  font-size: 13px;
}

#construction-section .ColorSampling {
  float: right;
  width: 100%;
  text-align: left;
  margin-right: 0;
  margin-top: 0px;
  font-size: 12px;
  margin-bottom: 12px;
}

#construction-section .ColorSampling .checkbox-inline {
  padding-left: 0px;
  width: 100%;
}

#construction-section .form-group.other-material {
  margin-top: 7px;
  float: left;
}

#construction-section .form-group.other-material label {
  float: left;
  width: 100%;
  font-weight: normal;
  margin-top: 20px;
}

#construction-section .form-group.other-material input {
  width: 100%;
  height: 34px;
}
#construction-section .form-group.other-material.post-input input {
  width: 100%;
  height: 34px;
  margin-top: 9px;
  float: left;
}

#construction-section .bridgeTeeth {
  float: left;
  width: 100%;
  //background: #105c9d;
  padding: 3px 0 5px;
  span {
    text-transform: uppercase;
  }
}

#construction-section h1 {
  color: @white-color;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  line-height: 28px;
}

#construction-section h3.addPost {
  color: @white-color;
  font-size: 15px;
  font-weight: bold;
  padding-top: 3px;
}

#construction-section .SelectMaterial.designBox ul li {
  width: 100%;
  float: left;
  padding-left: 5px;
  text-align: left;
  margin-bottom: 10px;
}

#construction-section .SelectMaterial.designBox ul li img {
  height: 20px;
  margin-right: 5px;
}

#construction-section .padding_Bottom-50 {
  margin-bottom: 50px;
}

#construction-section .SelectMaterial.colorBox ul {
  width: 97%;
}

#construction-section .right-sec-head .btn-default {
  color: @white-color;
  background: none;
  border-color: @white-color;
  font-size: 12px;
  border-radius: initial;
  font-weight: 600;
  padding: 4px 25px 2px;
  margin-right: 8px;
}

#construction-section .right-sec-head .btn-default:hover,
#construction-section .right-sec-head .btn-default.active {
  background: @white-color;
  color: #a6d273;
  box-shadow: initial;
  opacity: 1;
}

#construction-section .btn.active span.glyphicon {
  opacity: 0;
}

#construction-section .SelectMaterial ul li input {
  width: 100px;
  font-size: 13px;
  padding: 2px 5px 2px;
  min-height: 30px;
}

#construction-section .ColorSampling input[type='checkbox'].ng-empty {
  float: left;
  width: 15px;
  margin: 0px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid @post-block-color;
  outline: none;
  -webkit-appearance: inherit;
  padding: 8px;
  border-radius: 4px;
  position: static;
  cursor: pointer;
}

#construction-section .ColorSampling input[type='checkbox'].ng-not-empty {
  float: left;
  width: 15px;
  margin: 0px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid @header-bg;
  outline: none;
  cursor: pointer;
  -webkit-appearance: inherit;
  position: static;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 8px;
  border-radius: 4px;
}

#construction-section .ColorSampling input[type='checkbox'].ng-not-empty:after {
  content: '\f00c';
  position: absolute;
  top: 4px;
  left: 4px;
  font-size: 12px;
  color: @header-bg;
}

#construction-section .softInside input[type='checkbox'].ng-empty {
  float: left;
  width: 15px;
  margin: 0px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid @post-block-color;
  outline: none;
  -webkit-appearance: inherit;
  padding: 8px;
  border-radius: 4px;
  position: static;
  cursor: pointer;
}

#construction-section .softInside input[type='checkbox'].ng-not-empty {
  float: left;
  width: 15px;
  margin: 0px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid @bitetray;
  outline: none;
  cursor: pointer;
  -webkit-appearance: inherit;
  position: static;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 8px;
  border-radius: 4px;
}

#construction-section .softInside input[type='checkbox'].ng-not-empty:after {
  content: '\f00c';
  position: absolute;
  top: 4px;
  left: 4px;
  font-size: 12px;
  color: @bitetray;
}

#construction-section .selectmaterial ul li.single-crown a {
  color: @white-color;
}

#construction-section .selectmaterial ul li.bridge a {
  color: @white-color;
}

#construction-section .selectmaterial ul li.post a {
  color: @white-color;
}

#construction-section .selectmaterial ul li.implant-crown a {
  color: @white-color;
}

#construction-section .selectmaterial ul li.implant-bridge a {
  color: @white-color;
}

#construction-section .selectmaterial ul li.other a {
  color: @white-color;
}

#construction-section {
  .default-bg {
    background: @delay-red;
  }

  .default-bg.inactive-construction {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .gray-bg-color {
    background: @gray-bg-color;
  }

  .single-crown {
    background: @single-crown;

    a {
      color: @white-color;
    }
  }

  .other {
    background: @other;

    a {
      color: @white-color;
    }
  }

  .single-crown.bitetray {
    background: linear-gradient(to bottom, @bitetray 50%, @single-crown 0%);
  }

  .other.bitetray {
    background: linear-gradient(to bottom, @bitetray 50%, @other 0%);
  }

  .bridge {
    background: @bridge;

    a {
      color: @white-color;
    }
  }

  .bridge.bitetray {
    background: linear-gradient(to bottom, @bitetray 50%, @bridge 0%);
  }

  .post {
    background: @post;

    a {
      color: @white-color;
    }
  }

  .post.bitetray {
    background: linear-gradient(to bottom, @bitetray 50%, @post 0%);
  }

  .implant-crown {
    background: @implant-crown;

    a {
      color: @white-color;
    }
  }

  .implant-crown.bitetray {
    background: linear-gradient(to bottom, @bitetray 50%, @implant-crown 0%);
  }

  .implant-bridge {
    background: @implant-bridge;

    a {
      color: @white-color;
    }
  }

  .implant-bridge.bitetray {
    background: linear-gradient(to bottom, @bitetray 50%, @implant-bridge 0%);
  }

  .complete-denture {
    background: @complete-denture;

    a {
      color: @white-color;
    }
  }

  .complete-denture.bitetray {
    background: linear-gradient(to bottom, @bitetray 50%, @complete-denture 0%);
  }

  .partial-denture {
    background: @partial-denture;

    a {
      color: @white-color;
    }
  }

  .partial-denture.bitetray {
    background: linear-gradient(to bottom, @bitetray 50%, @partial-denture 0%);
  }

  .temporary-denture {
    background: @temporary-denture;

    a {
      color: @white-color;
    }
  }

  .temporary-denture.bitetray {
    background: linear-gradient(to bottom, @bitetray 50%, @temporary-denture 0%);
  }

  .other-removable {
    background: @other;

    a {
      color: @white-color;
    }
  }

  .other-removable.bitetray {
    background: linear-gradient(to bottom, @bitetray 50%, @other 0%);
  }

  .bitetray {
    background: @bitetray;

    a {
      color: @white-color;
    }
  }

  #bridgeTeeth.single-crown-section {
    border: solid 1px @single-crown;
    position: relative;
  }

  #bridgeTeeth.implant-crown-section {
    border: solid 1px @implant-crown;
    position: relative;
  }

  #bridgeTeeth.bridge-section {
    border: solid 1px @bridge;
    position: relative;
  }

  #bridgeTeeth.implant-bridge-section {
    border: solid 1px @implant-bridge;
    position: relative;
  }

  #bridgeTeeth.partial-denture-section {
    border: solid 1px @partial-denture;
    position: relative;
  }

  #bridgeTeeth.temporary-denture-section {
    border: solid 1px @temporary-denture;
    position: relative;
  }

  #bridgeTeeth.other-removable-section {
    border: solid 1px @other;
    position: relative;
  }

  #bridgeTeeth.complete-denture-section {
    border: solid 1px @complete-denture;
    position: relative;
  }

  #bridgeTeeth.bitetray-denture-section {
    border: solid 1px @bitetray;
    position: relative;
  }

  #bridgeTeeth.bridgeTeethPost {
    border: solid 1px @post;
    position: relative;
  }

  #bridgeTeeth.bridgeTeethOther {
    border: solid 1px @other;
    position: relative;
  }

  #bridgeTeeth.other-section {
    border: solid 1px @other;
    position: relative;
  }

  .bridge-start {
    border: 3px solid @header-bg;
    border-right: none;
    border-radius: 8px 0 0 8px;
    margin-right: 0px;
    width: 38px;
    padding: 0px;
  }

  .bridge-selected.bridge-start {
    border: 3px solid @header-bg;
    border-right: none;
    border-radius: 8px 0 0 8px;
    margin-right: 0px;
    width: 38px;
    padding: 0px;
  }

  .bridge-start-temp {
    border: 3px solid @header-bg;
    border-right: none;
    border-left: none;
    border-radius: 0px;
    margin-right: 0px;
    width: 38px;
    padding: 0px;
  }

  .bridge-end {
    border: 3px solid @header-bg;
    border-left: none;
    border-radius: 0px 8px 8px 0px;
    margin-left: 0px;
    padding: 0px;
    width: 38px;
  }

  .bridge-selected.bridge-end {
    border: 3px solid @header-bg;
    border-left: none;
    border-radius: 0px 8px 8px 0px;
    margin-left: 0px;
    padding: 0px;
    width: 38px;
  }

  .bridge-middle {
    border: 3px solid @header-bg;
    border-left: none;
    border-right: none;
    border-radius: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
    width: 38px;
  }

  .bridge-selected {
    border-radius: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
    width: 38px;
    border-left: none;
    border-right: none;
    position: relative;
  }

  .bridge-selected-start {
    border-radius: 0px;
    margin-right: 0px;
    padding: 0px;
    width: 38px;
    border-right: none;
    border-radius: 3px 0px 0px 3px;
    position: relative;
  }

  .bridge-selected-end {
    border-radius: 0px;
    margin-left: 0px;
    padding: 0px;
    width: 38px;
    border-left: none;
    border-radius: 0px 3px 3px 0px;
    position: relative;
  }

  .single-crown.square-selected,
  .implant-crown.square-selected,
  .bridge-end.bridge,
  .bridge-end.implant-bridge,
  .partial-denture.square-selected,
  .temporary-denture.square-selected,
  .bridge-end.complete-denture,
  .bridge-end.bitetray,
  .bridge-selected-end.bridge-end,
  .other-removable.square-selected,
  .other.square-selected {
    .cross-square {
      display: block;

      &:hover {
        background: @temporary-denture;
      }
    }
  }

  .square-post.square-selected {
    .cross-rectangle-post {
      display: block;

      &:hover {
        background: @temporary-denture;
      }
    }
  }
}

// POST THOOTH
#construction-section {
  #bridgeTeeth.bridgeTeethPost {
    border: solid 1px @post-block-color;
    .bridgeTeeth {
      background: @post-block-color;
      .row {
        .container {
          .col-md-6.col-lg-6.col-sm-6.col-xs-12 {
            h1.bridgeTeeth {
              padding: 0px;
              color: @white-color;
              span {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    .row {
      .extra-padding-left-right {
        padding: 0 45px;
        .col-md-6.col-lg-6.col-sm-6.col-xs-12 {
          .SelectMaterial {
            ul {
              li {
                &.active {
                  background: @post-block-color;
                }
              }
            }
          }
        }
        .col-md-6.col-lg-6.col-sm-6.col-xs-12 {
          .form-group.other-material {
            margin-top: 5px;
            float: left;
            width: 49%;
            margin-left: 11%;
            label {
              margin-bottom: 15px;
            }
            input {
              border-radius: 0px;
            }
          }
        }
      }
    }
    .extra-padding-left-right.padding_Bottom-50 {
      padding: 45px 45px 0px;
    }
  }
}

// RESPONSIVE
@media screen and (max-width: 767px) {
  #construction-section {
    padding-top: 0;
    .cross-rectangle-post {
      width: 12px;
      height: 12px;
      bottom: -15px;
      right: 9.5px;
      line-height: 12px;
      font-size: 8px;
    }
    .cross-square {
      width: 12px;
      height: 12px;
      top: -9px;
      right: -8px;
      line-height: 12px;
      font-size: 8px;
    }
    .margin-top {
      margin-top: -10px !important;
    }
    .row {
      margin: 0;
    }
    h1.patientTitle {
      font-size: 15px;
      line-height: 35px;
    }
    .Constructions {
      margin-top: 0;
      padding: 0;
    }
    .SearchPatient {
      padding: 10px 0 0;
      ul {
        text-align: center;
        display: inline-block;
        li {
          margin-bottom: 10px;
          max-width: 130px;
          float: none;
          display: inline-block;
          a {
            font-size: 12px;
            line-height: 14px;
            border-bottom: solid 2px #fff;
          }
          &.active {
            a {
              font-weight: normal;
              border-bottom-width: 2px;
            }
          }
        }
      }
      &.margin-less-top {
        .constructions-sub-types {
          ul {
            display: block;
            li {
              max-width: inherit;
              float: left;
              display: block;
            }
          }
        }
      }
    }
    .divider-new {
      h1 {
        margin-top: 0;
        font-size: 18px;
      }
    }
    .clickonBoth {
      width: 100%;
      margin-left: 0;
      .well-sm {
        .range {
          display: none;
        }
        .btn-group {
          margin-bottom: 10px;
        }
      }
    }
    .margin-top-45 {
      margin-top: 5px !important;
    }
    .SelectMaterial {
      h3 {
        margin-top: 0;
        line-height: 24px;
      }
      ul {
        margin-top: 0;
        li {
          width: 32%;
          margin-right: 1.33%;
          margin-bottom: 3px;
          a {
            padding: 6px 3px;
            font-size: 11px;
          }
        }
      }
      .designBox {
        ul {
          li {
            width: 100%;
          }
        }
      }
      &.designBox {
        ul {
          li {
            margin-bottom: 3px;
          }
        }
      }
    }
    .form-group {
      .other-material {
        width: 100%;
      }
    }
    ul.singleSub {
      li {
        width: 49%;
        margin: 1px 0.5%;
      }
    }
    #bridgeTeeth {
      .row {
        margin: 0 -25px;
      }
    }
    .bridge-suggestion,
    .partial-suggestion {
      margin-left: 0;
      width: 100%;
      border-radius: 5px;
      padding: 5px;
      font-size: 12px;
      font-weight: normal;
    }
  }
}
