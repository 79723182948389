@import "~stylesheets/mixins.less";
@import "~stylesheets/variables.less";

#add-image-modal {
  .modal-dialog {
    width: 60%;
    .modal-content {
      padding: 20px 40px 20px 70px;
      border-radius: 0px;
      background: @white-color;
      box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
      .modal-header {
        border: 0px;
        .close {
          opacity: 1;
          font-size: 30px;
          margin: -15px -25px;
          &:focus {
            outline: none;
          }
        }
        label.modal-title {
          font-size: 25px;
          font-weight: bold;
          text-align: center;
          color: @sky-start-dark;
          width: 100%;
        }
      }
      .modal-body {
        label {
          display: flex;
          max-width: 100%;
          margin-bottom: 5px;
          align-items: center;
          font-size: 13px;
          font-weight: 700;
          min-height: 47px;
          text-align: center;
        }
        p.red-astrix-mark {
          flex-grow: 0;
          max-width: 20px;
          padding: 0;
          margin-bottom: 15px;
        }
        input {
          width: 100%;
        }
        .template-image {
          width: 100%;
          border: 1px solid #333;
          cursor: pointer;
        }
        .template-image.selected {
          opacity: 0.8;
          border: 4px solid #105c9d;
        }
      }
      .modal-footer {
        border: none !important;
        .btn.btn-default {
          border-radius: 0px;
          border: solid 1px @sky-start-dark;
          text-transform: uppercase;
          font-size: 18px;
          letter-spacing: 1px;
          font-weight: bold;
          padding: 10px 45px;
          color: @sky-start-dark;
          &:hover, &:focus, &.active-tab-close {
            background: @sky-start-dark;
            color: @white-color;
          }
        }
      }
    }
  }
}