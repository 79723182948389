#three-shape-upload-modal h4,
#three-shape-scan-view h4 {
  text-align: center;
  color: #636363 !important;
  font-weight: 600 !important;
  border: none !important;
}
#three-shape-upload-modal h5,
#three-shape-scan-view h5 {
  text-align: center;
  color: #aaa;
}
#three-shape-upload-modal .close,
#three-shape-scan-view .close {
  font-size: 25px !important;
  opacity: 1.2 !important;
}
#three-shape-upload-modal .shape-download-info,
#three-shape-scan-view .shape-download-info {
  float: left;
  padding: 8px 32px;
  margin: 20px 5px 12px;
}
#three-shape-upload-modal .shape-download-info i,
#three-shape-scan-view .shape-download-info i {
  font-size: 25px;
  color: #5dcbcc;
}
#three-shape-upload-modal .scan-spinner-text,
#three-shape-scan-view .scan-spinner-text {
  font-size: 20px !important;
  color: #000 !important;
}
.shape-open-list-btn {
  color: #fff;
  padding: 5px;
  margin-left: 0;
  font-size: 13px;
  text-transform: uppercase;
  background: #105c9d;
  margin-bottom: 3px;
  border: none;
}
.uploading-toastr {
  background: #fff !important;
  color: black !important;
}
.uploading-toastr i {
  color: #5dcbcc !important;
}
.uploading-toastr .toast-close-button {
  color: #5dcbcc !important;
}
.shape-open-list-btn.image-annotation {
  padding: 3px 15px;
  float: initial;
  margin-left: 10px;
}
.annotation-instruction {
  font-size: 16px;
  font-weight: 500;
  margin: 50px 0 0 15px;
}
