#manage-actions {
  #full-width;
  .container {
    margin-top: 180px;
    padding-bottom: 50px;
    background: @white-color;
  }
  .manage-actions {
    #full-width;
    .actions-header {
      #full-width;
      padding: 40px 20px 5px;
      border-bottom: solid 2px @post-block-color;
      color: @post-block-color;
      font-size: 20px;
      font-weight: 600;
    }
    .categories-section {
      #full-width;
      .section-header {
        #full-width;
        padding: 40px 20px 5px;
        color: @header-bg;
        font-size: 20px;
        font-weight: 600;
        border-bottom: solid 2px @header-bg;
      }
      .category-section-block {
        #full-width;
        h4 {
          width: auto;
          float: left;
          padding: 5px 25px;
          margin: 20px 20px 5px 5px;
          color: @h1-color;
          font-weight: 600;
          line-height: 39px;
        }
        ul {
          width: auto;
          float: left;
          margin-top: 15px;
          li {
            width: auto;
            float: left;
            padding: 5px 50px;
            margin: 5px;
            border: solid 1px @header-bg;
            color: @header-bg;
            font-weight: 600;
            cursor: pointer;
            &.sub-category-text{
              color: @white-color;
              //text-shadow: 0 0 1px @h1-color;
            }
          }
        }
        button {
          width: auto;
          float: left;
          padding: 5px 50px;
          margin: 20px 5px 5px;
          border: solid 1px @header-bg;
          color: @header-bg;
          background: none;
          font-weight: 600;
        }
        .main-category-list {
          #full-width;
          .main-category-list-item {
            #full-width;
          }
        }
      }
      .manage-action-filter {
        #full-width;
        margin: 15px 0;
        .category-dropdown {
          width: 15%;
          float: left;
          margin-right: 2%;
          text-align: center;
          label {
            #full-width;
            font-weight: 500;
            line-height: 31px;
            margin: 0;
            font-size: 14px;
          }
          select {
            #full-width;
            padding: 5px 0;
            text-align: center;
            border: solid 1px @gray-font-color;
            color: @gray-dark;
            font-size: 14px;
            background: @gray-bg-color;
          }
        }
        .category-search-input {
          width: 65%;
          float: left;
          margin-top: 31px;
          input {
            width: 72%;
            float: left;
            padding: 3px 0;
            text-align: center;
            border: solid 1px @gray-font-color;
            color: @gray-dark;
            font-size: 14px;
            margin-right: 2%;
            background: @gray-bg-color;
            height: 31px;
          }
          button {
            width: auto;
            float: right;
            padding: 1px 30px;
            margin: 0 5px 5px;
            border: solid 1px @header-bg;
            color: @header-bg;
            background: none;
            font-weight: 600;
          }
        }
      }
      .action-data-table {
        #full-width;
        background: @light-grey;
        margin: 15px 0;
        p {
          #full-width;
          text-align: right;
          font-size: 11px;
          padding: 5px 10px;
          span {
            color: @dark-red-color;
          }
        }
        .table-head-title {
          #full-width;
          margin-top: 60px;
          border-bottom: solid 1px @dark-grey;
          label {
            width: 10%;
            float: left;
            font-size: 12px;
            color: @h1-color;
            font-weight: 600;
            margin-bottom: 0;
            &:nth-child(2) {
              width: 20%;
            }
            &.rotate-90 {
              width: 1%;
              transform: rotate(-90deg);
              white-space: nowrap;
              margin-left: 5px;
              margin-right: 5px;
              font-weight: 400;
            }
            &.extra-width {
              width: 4%;
              margin: -17px 0;
            }
            &.check-this,
            &.not-check-this {
              width: 1%;
              margin-left: 5px;
              margin-right: 5px;
              text-overflow: clip !important;
            }
            &.color-red-sign {
              color: @dark-red-color;
            }
            &:first-child {
              padding-left: 10px;
            }
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
              text-align: center;
            }
          }
          &.table-body {
            margin-top: 0;
            border-bottom: 0;
          }
          .table-body-content {
            #full-width;
            background: @white-color;
            margin-top: 0;
            border-bottom: solid 1px @dark-grey;
            cursor: pointer;
            label {
              font-weight: 400;
              padding: 10px 0;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              cursor: pointer;
              &:first-child {
                padding-left: 10px;
              }
              &.extra-width {
                margin: 0;
                width: 4%;
                text-align: center;
                span {
                  width: 100%;
                  float: left;
                  text-align: center;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
              }
              a {
                width: 60px;
                float: right;
                color: @sky-dark;
                border: solid 1px @sky-dark;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

#main-category-modal, #sub-category-modal, #material-group-modal, #add-action-modal, #add-role-modal, #material-price-diff-modal, #add-material-list-modal, #add-action-list-modal, #add-category-modal, #upload-modal {
  .modal-dialog{
    width: 420px;
    .modal-content{
      padding: 20px 40px 0 40px;
      border-radius: 0;
      background: @white-color;
      box-shadow: 0 5px 15px rgba(0,0,0,.5);
      overflow: hidden;
      .modal-header{
        border: 0; padding: 0;
        .close{
          opacity: 1;
          font-size: 30px;
          margin: -15px -25px;
          &:focus{
            outline: none;
          }
        }
        h4.modal-title{
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          color: @sky-start-dark;
          margin-bottom: 0;
        }
      }
      .modal-body{
        #full-width;
        padding-top: 30px;
        padding-bottom: 20px;
        .add-clinic-form{
          #full-width;
          margin: 10px 0;
          #instruction-textarea {
            width: 100%;
            padding: 70px 5px 50px;
            text-align: center;
          }
          input{
            text-align: center;
            margin: 0;
            width: 100%;
            float: left;
            line-height: 30px;
            height: 36px;
          }
          &.select-content {
            text-align: right;
            label {
              font-weight: 400;
              line-height: 31px;
              margin: 0;
            }
            select {
              width: 100px;
              float: right;
              padding: 5px 0;
              margin-left: 8px;
              text-align: center;
              border: solid 1px @gray-font-color;
              color: @gray-font-color;
              font-size: 14px;
            }
            input {
              width: 150px;
              float: right;
              padding: 0;
              line-height: 29px;
              height: 35px;
              margin-left: 8px;
              margin-bottom: 5px;
              text-align: center;
              border: solid 1px @gray-font-color;
              color: @gray-font-color;
              font-size: 14px;
            }
            ul {
              width: 150px;
              float: right;
              margin-right: -22px;
              li {
                #full-width;
                input {
                  width: 120px;
                  float: left;
                }
                i {
                  float: right;
                  margin-top: 10px;
                }
              }
            }
            .manage-add-btn {
              #full-width;
              button {
                border-radius: 0;
                border: solid 1px @sky-start-dark;
                text-transform: uppercase;
                font-size:15px;
                letter-spacing: 1px;
                font-weight: bold;
                padding: 0 20px;
                color:@sky-start-dark;
                float: right;
                background: none;
                &:hover, &:focus, &.active-tab-close{
                  background: @sky-start-dark;
                  color: @white-color;
                }
              }
            }
          }
        }
      }
      .modal-footer{
        #full-width;
        border: 0;
        padding: 5px 30px 30px;
        button {
          border-radius: 0;
          border: solid 1px @sky-start-dark;
          text-transform: uppercase;
          font-size:15px;
          letter-spacing: 1px;
          font-weight: bold;
          padding: 5px 20px;
          color:@sky-start-dark;
          float: left;
          &:hover, &:focus, &.active-tab-close{
            background: @sky-start-dark;
            color: @white-color;
          }
          i {
            float: left;
            margin: 2px 10px 2px 0;
          }
        }
        button + button {
          float: right;
        }
      }
    }
  }
}

#add-material-list-modal, #add-action-list-modal, #upload-modal {
  .modal-dialog {
    width: 900px;
  }
  .modal-dialog .modal-content .modal-footer button {
      float: right;
  }
}

#add-action-modal {
  .modal-dialog{
    width: 700px;
    .modal-content{
      .modal-body{
        .action-no-name {
          #full-width;
          input {
            width: 15%;
            float: left;
            border: solid 1px @dark-grey;
            min-height: 32px;
            height: 36px;
            padding: 0 10px;
            box-sizing: border-box;
            font-size: 14px;
          }
          input + input {
            width: 83%;
            margin-left: 2%;
          }
        }
        .cat-action-perform {
          #full-width;
          .left-cat-section {
            width: 32%;
            float: left;
            .cat-division {
              #full-width;
              margin-top: 25px;
              text-align: center;
              label {
                #full-width;
                font-weight: 400;
                line-height: 31px;
                margin: 0;
                font-size: 14px;
              }
              select {
                #full-width;
                padding: 5px 0;
                margin-left: 0;
                text-align: center;
                border: solid 1px @gray-font-color;
                color: @gray-font-color;
                font-size: 14px;
                background: @light-grey;
                border-radius: 3px;
              }
              .several-options { margin: 0;}
              .multiselect-parent {
                width: 100%;
                button.dropdown-toggle {
                  #full-width;
                  padding: 5px 6px;
                  margin-left: 0;
                  text-align: center;
                  border: solid 1px @gray-font-color;
                  color: @gray-font-color;
                  font-size: 14px;
                  background: @light-grey;
                  border-radius: 3px;
                  box-shadow: none;
                  .caret {
                    float: right;
                    margin-top: 8px;
                    border-top: 6px dashed;
                    border-right: 3px solid transparent;
                    border-left: 3px solid transparent;
                  }
                }
                ul.dropdown-menu.dropdown-menu-form {
                  width: 100%;
                  padding: 0;
                  margin: 0;
                  background: #eee;
                  border-radius: 0;
                  max-height: 40vh;
                  z-index: 10;
                  &::-webkit-scrollbar-track {
                    background-color: #ccc;
                  }
                  &::-webkit-scrollbar {
                    width: 6px;
                    background-color: #ccc;
                  }
                  &::-webkit-scrollbar-thumb {
                    background-color: #105c9d;
                  }
                  li {
                    width: 100%;
                    float: left;
                    padding: 0;
                    margin: 0;
                    border-bottom: solid 1px #fff;
                    color: #105c9d;
                    font-weight: 600;
                    cursor: pointer;
                    &:nth-child(1),
                    &:nth-child(2) {
                      width: 50%;
                      a {
                        padding: 8px 0;
                        font-size: 12px;
                        #full-width;
                      }
                    }
                    &:nth-child(3) {
                      display: none;
                    }
                    a {
                      width: 100%;
                      float: left;
                      white-space: inherit;
                      padding: 8px 20px;
                      .checkbox {
                        margin: 0;
                        label {
                          display: flex;
                          align-items: center;
                          padding: 0;
                          input[type="checkbox"]:not(:checked) {
                            margin: 0 20px 0 0;
                            border: 1px solid #ccc;
                            outline: none;
                            -webkit-appearance: inherit;
                            padding: 10px;
                            position: static;
                            cursor: pointer;
                            display: inline;
                            border-radius: 3px;
                            background: #fff;
                          }
                          input[type="checkbox"]:checked {
                            margin: 0 20px 0 0;
                            border: 1px solid #105c9d;
                            outline: none;
                            cursor: pointer;
                            -webkit-appearance: inherit;
                            position: relative;
                            font: normal normal normal 14px/1 FontAwesome;
                            padding: 10px;
                            display: inline;
                            background: #105c9d;
                            border-radius: 3px;
                          }
                          input[type="checkbox"]:checked:after {
                            content: '\f00c';
                            position: absolute;
                            top: 5px;
                            left: 4px;
                            font-size: 12px;
                            color: #fff;
                          }
                        }
                      }
                      &:hover {
                        color: #105c9d;
                      }
                      &:focus {
                        outline: none;
                      }
                    }
                    &.active {
                      a {
                        background: #ddd;
                        color: #000;
                        padding: 8px 20px;
                      }
                    }
                  }
                }
              }
            }
          }
          .right-cat-section {
            width: 65%;
            float: right;
            margin-top: 20px;
            .head-part {
              #full-width;
              h4 {
                width: 40%;
                float: left;
                opacity: 0;
                font-weight: normal;
                margin: 0;
                font-size: 14px;
              }
              h4 + h4 {
                width: 30%;
                float: left;
                text-align: center;
                opacity: 1;
              }
            }
            .body-part {
              #full-width;
              .repeat-box-li {
                #full-width;
                margin-top: 10px;
                h4 {
                  width: 40%;
                  float: left;
                  font-weight: normal;
                  margin: 0;
                  font-size: 14px;
                  text-align: right;
                  line-height: 24px;
                }
                .create-area-check {
                  width: 30%;
                  float: left;
                  text-align: center;
                  display: inline-block;
                  label {
                    float: none;
                  }
                  select {
                    width: 65%;
                    float: left;
                    border: solid 1px @dark-grey;
                    min-height: 24px;
                    padding: 0 10px;
                    box-sizing: border-box;
                    font-size: 14px;
                    margin-left: 45px;
                    &:disabled {
                      opacity: 0.5;
                    }
                  }
                  input[type="text"] {
                    width: 160%;
                    float: left;
                    border: solid 1px @dark-grey;
                    min-height: 24px;
                    padding: 0 10px;
                    box-sizing: border-box;
                    font-size: 14px;
                    margin-bottom: 35px;
                    margin-left: 45px;
                  }
                }
                &.extra-margin-35 {
                  margin-top: 35px;
                }
              }
            }
          }
        }
      }
    }
  }
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.create-area-check {
  label.checkbox-style{
  width: 24px;
  font-weight: normal;
  font-size: 14px;
  position: relative;
  height: 24px;
  display: inline-block;
  margin: 0;
  label {
    color: @h1-color !important;
    margin-left: 0 !important;
    font-weight: 400;
  }
  input[type="checkbox"] {
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 11;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    label {
      color: @h1-color !important;
      margin-left: 0 !important;
      font-weight: 400;
    }
    input[type="checkbox"] {
      position: absolute !important;
      top: 0;
      left: 0;
      z-index: 11;
      opacity: 0;
      width: 100%;
      height: 100%;
      margin: 0;
    }
    input[type="checkbox"] + label {
      position: relative;
      padding-left: 30px;
      line-height: 23px;
      float: left;
    }
    input[type="checkbox"] + label:after {
      width: 23px;
      height: 23px;
      content: '';
      border: solid 2px @post-block-color;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      border-radius: 3px;
    }
    input[type="checkbox"]:checked + label:after {
      border-color: @sky-dark;
    }
    input[type="checkbox"]:checked + label:before {
      content: '\2714';
      font-size: 14px;
      text-align: center;
      color: #fff !important;
      background: @sky-dark;
      width: 23px;
      height: 23px;
      position: absolute;
      left: 0;
      border-radius: 3px;
      z-index: 10;
    }
  }
}
}

@media (max-width: 767px) {
  #manage-actions {
    .container {
      margin-top: 10px;
      padding: 0;
    }
    .manage-actions {
      .actions-header {
        padding: 10px 0 5px;
        font-size: 15px;
      }
      .categories-section {
        .section-header {
          padding: 10px 0 0;
          font-size: 14px;
          + div {
            padding: 0;
          }
        }
        .category-section-block {
          ul {
            margin-top: 2px;
            li {
              padding: 0 5px;
              margin: 2px;
              font-weight: 400;
              font-size: 12px;
            }
          }
          h4 {
            padding: 0 3px;
            margin: 4px 2px 0 0;
            line-height: 22px;
            font-size: 14px;
          }
          button {
            padding: 0 5px;
            margin: 4px;
            font-weight: 400;
            font-size: 12px;
          }
        }
        .manage-action-filter {
          .category-dropdown {
            width: 48%;
            select {
              padding: 3px 0;
            }
          }
          .category-search-input {
            width: 100%;
            margin-top: 4px;
            input {
              padding: 2px 0;
              font-size: 12px;
              height: 31px;
            }
            button {
              padding: 1px 7px 0;
              margin: 0 5px 5px;
              font-size: 14px;
              float: right;
            }
          }
        }
        .action-data-table {
          overflow-x: scroll;
          p {
            text-align: left;
          }
          .table-scroll-mobile {
            width: 1169px;
          }
        }
      }
    }
  }
  #main-category-modal, #sub-category-modal, #material-group-modal, #add-action-modal {
    .modal-dialog {
      width: 300px;
      margin: 10px auto;
      .modal-content {
        padding: 15px 30px 0 30px;
        .modal-body {
          padding: 10px 0;
          .add-clinic-form {
            &.select-content {
              text-align: center;
              label, select {
                #full-width;
                margin: 0;
              }
            }
          }
        }
        .modal-footer {
          padding: 5px 5px 20px;
        }
      }
    }
  }
  #add-action-modal {
    .modal-dialog {
      .modal-content {
        padding: 15px 10px 0 10px;
        .modal-header {
          .close {
            margin: -15px -5px;
          }
        }
        .modal-body {
          .cat-action-perform {
            .left-cat-section,
            .right-cat-section {
              width: 100%;
              .cat-division {
                select {
                  margin-left: 0;
                }
              }
              .body-part {
                .repeat-box-li {
                  .create-area-check {
                    input[type="text"] {
                      margin-left: 15px;
                    }
                    input[type="number"] {
                      margin-left: 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  #manage-actions {
    .container {
      margin-top: 150px;
      padding: 0;
    }
    .manage-actions {
      .actions-header {
        padding: 10px 0 5px;
        font-size: 15px;
      }
      .categories-section {
        .section-header {
          padding: 10px 0 0;
          font-size: 14px;
          + div {
            padding: 0;
          }
        }
        .category-section-block {
          ul {
            margin-top: 2px;
            li {
              padding: 0 5px;
              margin: 2px;
              font-weight: 400;
              font-size: 12px;
            }
          }
          h4 {
            padding: 0 3px;
            margin: 4px 2px 0 0;
            line-height: 22px;
            font-size: 14px;
          }
          button {
            padding: 0 5px;
            margin: 4px;
            font-weight: 400;
            font-size: 12px;
          }
        }
        .manage-action-filter {
          .category-search-input {
            button {
              padding: 1px 30px;
            }
          }
        }
        .action-data-table {
          p {
            text-align: left;
          }
          .table-head-title {
            label {
              a {
                width: 40px !important;
                float: right !important;
              }
              &:nth-child(2) {
                width: 13%;
              }
              &.check-this {
                font-size: 8px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1365px) {
  #manage-actions {
    .container {
      margin-top: 150px;
      padding: 0;
    }
    .manage-actions {
      .actions-header {
        padding: 10px 0 5px;
        font-size: 15px;
      }
      .categories-section {
        .section-header {
          padding: 10px 0 0;
          font-size: 14px;
          + div {
            padding: 0;
          }
        }
        .category-section-block {
          ul {
            margin-top: 2px;
            li {
              padding: 0 5px;
              margin: 2px;
              font-weight: 400;
              font-size: 12px;
            }
          }
          h4 {
            padding: 0 3px;
            margin: 4px 2px 0 0;
            line-height: 22px;
            font-size: 14px;
          }
          button {
            padding: 0 5px;
            margin: 4px;
            font-weight: 400;
            font-size: 12px;
          }
        }
        .manage-action-filter {
          .category-search-input {
            button {
              padding: 1px 30px;
            }
          }
        }
        .action-data-table {
          p {
            text-align: left;
          }
          .table-head-title {
            label {
              a {
                width: 40px !important;
                float: right !important;
              }
              &:nth-child(2) {
                width: 16%;
              }
              &.check-this {
                font-size: 8px;
              }
            }
          }
        }
      }
    }
  }
}

#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul {
  width: 150px;
  float: left;
  margin-right: 0;
  text-align: center;
}
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li span,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form > span,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li input {
  width: 100%;
  float: left;
  border: solid 1px #bab9b9;
  margin-bottom: 5px;
  line-height: 33px;
  text-align: center;
  color: #bab9b9;
  font-weight: 300;
}
#material-price-diff-modal .modal-dialog .modal-content .modal-footer button {
  float: right;
}

.subcategory-input-margin {
  margin: 6px 0 30px;
}
.sub-category-sec {
  margin-left: 0;
}
.sub-category-checkbox {
  input {
    width: 100%;
    float: left;
    background: #eee;
    text-align: center;
    border: solid 1px #a9a7a7;
  }
  i {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #aaa;
  }
}
.sub-category-dropdown-menu {
  top: 30px;
  width: 100%;
  margin: 0;
  padding: 0;
  li {
    cursor: pointer;
    .checkbox-style {
      padding: 10px 20px;
      width: 100%;
      background: #eee;
      margin-bottom: 0;
      border-bottom: 1px solid #ddd;
      cursor: pointer;
    }
    &:hover {
      label.checkbox-style label {
        cursor: pointer;
        color: #105c9d !important;
      }
    }
  }
}

.font-14 {
  font-size: 14px;
}
