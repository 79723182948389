#reports {
  #full-width;
  .container {
    margin-top: 150px;
    padding-bottom: 50px;
    background: @white-color;
  }
  .report-separator {
    #full-width;
    padding: 40px 20px 5px;
    border-bottom: solid 2px @post-block-color;
  }
  .report-data-placeholder {
    #full-width;
    p {
      padding: 15px 15px 15px;
      background: @light-grey;
    }
  }
  span.spinner {
    position: absolute;
    top: 250px;
    left: 50%;
  }
  .report-container {
    #full-width;
    .report-header {
      #full-width;
      padding: 40px 20px 5px;
      border-bottom: solid 2px @post-block-color;
      color: @post-block-color;
      font-size: 20px;
      font-weight: 600;
    }
    .report-data-header {
      #full-width;
      padding: 10px 20px 10px;
      background: @light-grey;
      h1 {
        padding: 40px 20px 5px;
        color: @post-block-color;
        font-size: 16px;
        font-weight: 600;
      }
      button {
        float: right;
        width: auto;
        padding: 1px 30px;
        margin: 35px 20px 5px;
        border: solid 1px @header-bg;
        color: @header-bg;
        background: @white-color;
        font-weight: 600;
      }
    }
    .report-data-row {
      #full-width;
      padding: 10px 20px 10px;
      border-bottom: solid 1px @dark-grey;
    }
    .report-data-row-heading {
      #full-width;
      background: @light-grey;
      padding: 10px 20px;
      &.scroll-auto {
        padding: 0;
        overflow-x: auto;
        background: #eee;
        .display-flex {
          span {
            min-width: 200px;
            float: left;
            padding: 10px 20px;
            max-width: 200px;
          }
          &.bg-change-white {
            background: @white-color;
            span {
              background: @white-color;
            }
            &:nth-child(2n) {
              background: @light-grey;
              span {
                background: @light-grey;
              }
            }
          }
          &.report-data-row {
            padding: 0;
          }
        }
        .vertical-scroll-auto {
          max-height: 250px;
          overflow-y: auto;
          overflow-x: hidden;
          display: inline-block;
        }
        .full-white-space {
          + .vertical-scroll-auto {
            width: 100%;
          }
        }
      }
    }
    .report-options-section {
      #full-width;
      .report-option-row {
        #full-width;
        margin: 15px 0;
        .report-option {
          width:100%;
          float: left;
          margin-right: 2%;
          label {
            #full-width;
            font-weight: 500;
            line-height: 31px;
            margin: 0;
            font-size: 16px;
          }
          select {
            #full-width;
            height: 2em;
            padding: 5px 0;
            text-align: center;
            border: solid 1px @gray-font-color;
            color: @gray-dark;
            font-size: 16px;
            background: @white-color;
          }
          button {
            width: auto;
            padding: 1px 30px;
            margin: 16% 5px 5px;
            border: solid 1px @header-bg;
            color: @white-color;
            background: @header-bg;
            font-weight: 600;
          }
          input {
            #full-width;
            text-align: left;
            border-radius: 4px 4px 4px;
            border: solid 1px @gray-font-color;
            color: @gray-dark;
            font-size: 16px;
            background: @white-color;
            padding-left: 5px;
          }
        }
      }
      .action-data-table {
        #full-width;
        background: @light-grey;
        margin: 15px 0;
        p {
          #full-width;
          text-align: right;
          font-size: 11px;
          padding: 5px 10px;
          span {
            color: @dark-red-color;
          }
        }
        .table-head-title {
          #full-width;
          margin-top: 60px;
          border-bottom: solid 1px @dark-grey;
          label {
            width: 10%;
            float: left;
            font-size: 12px;
            color: @h1-color;
            font-weight: 600;
            margin-bottom: 0;
            &:nth-child(2) {
              width: 22%;
            }
            &.rotate-90 {
              width: 1%;
              transform: rotate(-90deg);
              white-space: nowrap;
              margin-left: 5px;
              margin-right: 5px;
              font-weight: 400;
            }
            &.extra-width {
              width: 4%;
              margin: -17px 0;
            }
            &.check-this,
            &.not-check-this {
              width: 1%;
              margin-left: 5px;
              margin-right: 5px;
              text-overflow: clip !important;
            }
            &.color-red-sign {
              color: @dark-red-color;
            }
            &:first-child {
              padding-left: 10px;
            }
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
              text-align: center;
            }
          }
          &.table-body {
            margin-top: 0;
            border-bottom: 0;
          }
          .table-body-content {
            #full-width;
            background: @white-color;
            margin-top: 0;
            border-bottom: solid 1px @dark-grey;
            cursor: pointer;
            label {
              font-weight: 400;
              padding: 10px 0;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              cursor: pointer;
              &:first-child {
                padding-left: 10px;
              }
              &.extra-width {
                margin: 0;
                width: 4%;
                text-align: center;
                span {
                  width: 100%;
                  float: left;
                  text-align: center;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
              }
              a {
                width: 60px;
                float: right;
                color: @sky-dark;
                border: solid 1px @sky-dark;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
.display-flex {
  display: flex;
}

#reports{
  select, input{
    background: #ffffff;
    border: solid 1px #a5a3a3;
    border-radius: 0 !important;
    height: 33px !important;
    color: #929090 !important;
    font-size: 14px !important;
    padding-left: 15px !important;
  }
  input::placeholder{
    color: #929090 !important;
    font-size: 14px !important;
  }
  .status-filter{
    h3{
      width: 100%;
      float: left;
      font-weight: 500;
      line-height: 31px;
      margin: 0;
      font-size: 16px;
    }
  }
  .status-filter .several-options{

    margin: 0 0 15px;
  }
  .createBtn {
    margin-top: -25px;
  }
  .report-description-icon {
    min-width: 18px;
    height: 18px;
    text-align: center;
    line-height: 16px;
    border: solid 2px #105c9d;
    border-radius: 50%;
    margin-right: 5px;
    color: #105c9d;
    cursor: pointer;
    margin-top: -23px;
    font-size: 11px;
  }
}
