@import '~ngx-toastr/toastr.css';
@import '~font-awesome/css/font-awesome.css';
@import '~angular-loading-bar/build/loading-bar.css';
@import '~node_modules/angular-toastr/dist/angular-toastr.css';
@import '~node_modules/ng-tags-input/build/ng-tags-input.css';
@import '~node_modules/angular-rateit/dist/ng-rateit.css';
@import '~node_modules/ui-select/dist/select.css';
@import '~node_modules/angular-ui-switch/angular-ui-switch.css';
@import '~node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';
@import '~ngx-ui-switch/ui-switch.component.css';
@import '~bootstrap/dist/css/bootstrap.css';
@import '~@ng-select/ng-select/themes/default.theme.css';
#section-page {
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 35px;
  padding: 18px 61px 50px;
}
#section-heading {
  float: left;
  font-size: 20px;
  color: #1f2532;
  font-weight: 700;
  border-bottom: solid 1px #1f2532;
  width: 100%;
  line-height: 50px;
}
#full-width {
  width: 100%;
  float: left;
}
#rate-it {
  /* Background */
  /* Selected state */
  /* Cancelation of hover styling */
  /* Hover styling */
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../images/rated-star.svg");
}
#rate-it .ngrateit-reset {
  background-image: url("../images/cancel-icon.svg");
}
#rate-it .ngrateit-bg-star {
  background-image: url("../images/bg-star.svg");
}
#rate-it .ngrateit-selected {
  background-image: url("../images/rated-star.svg");
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating span:hover ~ span {
  background-image: url("../images/bg-star.svg");
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../images/rated-star.svg");
}
.btn-outline {
  border-radius: 0px;
  border: 1px solid #979797;
  color: #979797;
}
.add-row-span-boxes {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 100px;
  grid-gap: 10px;
}
.add-row-span-boxes .col-lg-3 {
  width: 100%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .add-row-span-boxes .col-lg-3 {
    width: 25%;
  }
}
.one-span {
  grid-row: span 1;
}
.two-span {
  grid-row: span 2;
}
.three-span {
  grid-row: span 3;
}
body {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  display: block;
  color: #5c5c5c;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.7;
  background: #F5F3F2 !important;
  font-family: 'Roboto', sans-serif !important;
}
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
ul,
li,
img {
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  list-style: none;
}
a {
  text-decoration: none;
  color: #105c9d;
  font-weight: 400;
}
a:hover,
a:focus {
  text-decoration: none !important;
}
p {
  line-height: 1.7;
  margin-bottom: 10px;
  font-weight: 400;
}
span {
  font-weight: 400;
}
/*header ccs*/
.margin-0 {
  margin: 0;
}
.padding-0 {
  padding: 0;
}
.header-fixed {
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
}
#denthub-header {
  background: #1f2532;
  width: 100%;
  height: 48px;
  display: flex;
}
#denthub-header .logo-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  margin-left: 62px;
}
#denthub-header .menu-items-wrapper {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
}
#denthub-header .menu-items-wrapper .header-icon-button {
  margin-left: 10px;
  color: #fff;
}
#denthub-header a.dentHub-first-part img {
  height: 30px;
}
#denthub-header a.dentHub-second-part img {
  height: 20px;
  margin-left: 10px;
}
#denthub-header a.white-label-logo img {
  height: 36px;
  margin-left: 10px;
}
#denthub-header .stylish-input-group {
  border-radius: 4px;
}
#denthub-header .stylish-input-group .input-group-addon {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
  font-size: 16px;
  padding-left: 15px;
  padding-right: 0;
}
#denthub-header .stylish-input-group .form-control {
  border-right: 0;
  box-shadow: 0 0 0;
  border-color: #ccc;
  width: 330px;
}
#denthub-header .stylish-input-group input {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
  font-size: 15px;
}
#denthub-header .stylish-input-group input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
#denthub-header #imaginary_container .form-control {
  border: inherit;
  border-radius: 0 1px 1px 0px !important;
  height: 39px;
}
#denthub-header #imaginary_container .input-group-addon {
  border: inherit;
  border-radius: 1px 0px 0px 1px;
}
#denthub-header #imaginary_container .input-group-addon span + span {
  display: none;
}
#denthub-header .btn-default {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  font-size: 17px;
  text-transform: uppercase;
  width: 73%;
  padding: 4px 0px;
  border-radius: inherit;
}
#denthub-header .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 10px dashed;
  border-top: 4px solid \9;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}
#denthub-header .btn .caret {
  margin-right: 10px;
}
#denthub-header .btn:focus,
.btn:active:focus {
  outline: inherit;
  outline-offset: inherit;
  box-shadow: inherit;
}
#denthub-header .notificationTitle {
  width: 100%;
  color: #fff;
  float: left;
  font-size: 17px;
  padding-top: 14px;
  text-transform: uppercase;
}
#denthub-header .notificationTitle i {
  margin-right: 10px;
  font-size: 21px;
}
#denthub-header .row.padding-top-10 {
  padding-top: 10px;
}
#denthub-header #imaginary_container {
  margin: 2px auto 0px;
  float: left;
  margin-top: 0px;
  height: 39px;
}
#navBar {
  background: #1f2532;
  width: 100%;
  float: left;
  min-height: 53px;
  padding-left: 100px;
}
.navbar-default .navbar-collapse {
  min-height: 53px !important;
  height: inherit !important;
}
#navBar .navbar-default {
  background-color: transparent;
  border-color: transparent;
}
#navBar .navbar {
  margin-bottom: 0;
  border: 0;
  float: left;
}
#navBar .navbar-default .navbar-nav > .active > a,
#navBar .navbar-default .navbar-nav > .active > a:hover,
#navBar .navbar-default .navbar-nav > .active > a:focus {
  color: #fff;
  background-color: transparent;
  border-bottom: solid 9px #105c9d;
}
#navBar .navbar-default .navbar-nav > li > a:hover,
#navBar .navbar-default .navbar-nav > li > a:focus {
  color: #fff;
  background-color: transparent;
  border-bottom: solid 9px #105c9d;
}
#navBar .navbar-default .navbar-nav > li > a {
  color: #fff;
}
#navBar .container {
  position: relative;
}
#navBar .nav > li > a {
  position: relative;
  display: block;
  padding: 12px 0 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  cursor: pointer;
  width: 160px;
  text-align: center;
}
#navBar .nav > li > a.order2-step {
  width: 204px;
}
#navBar .nav > li > a.order-step {
  width: 204px;
}
#navBar .nav > li:first-child > a.first-a-li {
  width: 235px;
}
#navBar .navbar,
#navBar .nav {
  width: 100%;
}
#navBar .nav li.openModal {
  float: right;
}
#navBar .nav > li.dentist-order-manage.modify-order {
  border: 0;
  width: 202px !important;
  float: left;
}
#navBar .nav > li.dentist-order-manage a {
  height: 52.99px;
}
#navBar .nav > li.dentist-order-manage:first-child > a {
  width: 270px;
}
#navBar .nav > li > a img {
  width: 16px;
  margin-top: -3px;
}
#navBar .nav li.sub-name a {
  padding: 7px 0 5px;
  font-size: 11px;
  font-weight: 400;
}
#navBar .nav li a p {
  margin: 0;
}
#navBar .nav li.sub-name a p {
  font-weight: 500;
  font-size: 16px;
  line-height: 10px;
  text-transform: uppercase;
  margin: 0;
}
#denthub-header .dropdown-menu > li > a:focus,
.dropdown-menu > li {
  color: #105c9d;
  text-decoration: none;
  text-align: center;
  font-size: 15px;
  line-height: 22px;
  width: 100%;
  float: left;
}
#denthub-header .dropdown-menu > li > a:focus,
.dropdown-menu > li:last-child {
  border: 0;
}
#denthub-header .dropdown-menu > li > a {
  padding: 16px 15px !important;
  font-size: 17px;
  width: 100%;
  float: left;
}
#denthub-header .dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: transparent;
}
#denthub-header .dropdown-menu {
  padding-bottom: 0 !important;
  position: absolute;
  margin: 0 -77px 0 !important;
}
#denthub-header .collapsing,
.dropdown,
.dropup {
  margin-left: 19px;
}
#denthub-header #Notifications.open > button {
  position: relative;
}
#denthub-header #Notifications.open > button:after {
  content: '';
  height: 25px;
  right: 60px;
  position: absolute;
  top: 21px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #fff;
}
#denthub-header .btn-default:hover {
  color: #ffe;
  background-color: transparent !important;
  border-color: transparent !important;
}
.dropdown-menu {
  min-width: 196px !important;
}
#denthub-header .no-padding-default {
  padding: 0 !important;
}
.no-padding-default {
  padding: 0 !important;
}
#denthub-header::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 15px;
  color: #dcdcdc;
}
#denthub-header::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 15px;
  color: #dcdcdc;
}
#denthub-header:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 15px;
  color: #dcdcdc;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-size: 15px;
  color: #dcdcdc;
}
/********************************less*****************/
#denthub-header .search-bar-list {
  float: left;
  width: 370px;
  background: #fff;
  margin-top: 7px;
  position: absolute;
  top: 39px;
  z-index: 9;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.39), 0 2px 10px 0 rgba(0, 0, 0, 0.39);
  border-radius: 4px;
}
#denthub-header .search-bar-list ul {
  max-height: 75vh;
  overflow-y: auto;
}
#denthub-header .search-bar-list ul li {
  width: 100%;
  float: left;
  padding: 15px;
}
#denthub-header .search-bar-list ul li div {
  line-height: 24px;
  color: black;
}
#denthub-header .search-bar-list ul li .person-name {
  font-size: 16px;
  font-weight: bold;
}
#denthub-header .search-bar-list ul li .pin,
#denthub-header .search-bar-list ul li .details {
  font-size: 15px;
}
#denthub-header .search-bar-list ul li .created-time {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}
#denthub-header .search-bar-list ul li:hover {
  background: #ebebeb;
}
#denthub-header #Notifications .btn-default {
  width: 100%;
  text-align: left;
  margin-right: 5px;
}
#denthub-header #Notifications .btn-default span.position-relative {
  position: relative;
}
#denthub-header #Notifications .btn-default span.position-relative span.Notifications-notes {
  position: absolute;
  color: #fff;
  top: -17px;
  background: #f44336;
  width: auto;
  height: auto;
  border-radius: 100%;
  font-size: 12px;
  padding: 1px 5px 1px;
  right: -10px;
}
#denthub-header #Notifications ul.dropdown-menu {
  width: 100%;
  float: left;
  margin: 0 !important;
  padding: 0;
  position: static;
  display: block;
  background: #fff;
  border: 0;
  box-shadow: none;
  max-height: 60vh;
  overflow-y: auto;
  min-width: 340px !important;
}
#denthub-header #Notifications li a {
  border-bottom: solid 1px #d4d4d4;
  font-size: 13px;
}
#denthub-header #Notifications li a:hover {
  background: #f5f5f5;
  color: #626262;
  cursor: pointer;
}
#denthub-header #Notifications li.is-not-read {
  background: #fcf6cc;
}
#denthub-header #Notifications li.is-not-read a {
  font-weight: bold;
}
#denthub-header #Notifications li.is-read {
  background: #fff;
}
#denthub-header #Notifications li.is-read a {
  opacity: 0.6;
}
#denthub-header #menu-profile ul.dropdown-menu {
  padding-bottom: 0 !important;
  position: absolute;
  margin: 0 -82px !important;
  min-width: 100px !important;
  width: 196px;
  padding-top: 0;
  max-height: 80vh;
  overflow-y: auto;
}
#denthub-header #menu-profile ul.dropdown-menu li.User-icons {
  width: 100%;
  float: left;
}
#denthub-header #menu-profile ul.dropdown-menu li.User-icons span.user-icons-img {
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: solid 1px #ccc;
  overflow: hidden;
}
#denthub-header #menu-profile ul.dropdown-menu li.User-icons span.user-icons-img img {
  width: auto;
  height: 100%;
}
#denthub-header #menu-profile ul.dropdown-menu li.User-icons h1 {
  font-size: 16px;
  float: right;
  color: #105c9d;
  width: 67%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#denthub-header #menu-profile ul.dropdown-menu li.User-icons h1 span {
  float: left;
  width: 100%;
  font-size: 12px;
  padding-top: 5px;
}
#denthub-header #menu-profile ul.dropdown-menu li a {
  color: #105c9d;
}
#denthub-header #menu-profile ul.dropdown-menu li:hover {
  background: #eee;
}
#denthub-header #menu-profile ul.dropdown-menu::-webkit-scrollbar-track {
  border-radius: 7px;
  background-color: #fff;
}
#denthub-header #menu-profile ul.dropdown-menu::-webkit-scrollbar {
  width: 7px;
  background-color: #fff;
}
#denthub-header #menu-profile ul.dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #1f2532;
  border-radius: 7px;
}
#menu-profile .dropdown-menu {
  top: 51px;
  left: -4px;
}
#menu-profile {
  margin-top: 10px;
}
.searchBg {
  overflow: hidden;
}
.arrow-left {
  margin-top: 15px;
  color: #fff;
  cursor: pointer;
}
#denthub-header #Notifications li a.bold-font {
  font-weight: bold;
}
#denthub-header #Notifications li {
  text-align: left;
}
#denthub-header #Notifications li a img {
  width: 20px;
  margin: 0 5px;
}
#denthub-header #Notifications li a i {
  font-size: 18px;
  width: 30px;
  text-align: center;
}
#denthub-header .search-bar-list ul li h2 {
  width: 91%;
  float: left;
  font-size: 14px;
  font-weight: 600;
  padding: 0 0 0 2px;
}
#denthub-header .search-bar-list ul li h2 span {
  font-size: 12px;
  font-weight: 400;
}
#denthub-header .search-bar-list ul li .search-related-data {
  width: 100%;
  cursor: pointer;
  margin: auto;
}
#denthub-header .search-bar-list ul li .search-related-data a span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  float: left;
  width: 100%;
}
#denthub-header .search-bar-list ul li .search-related-data a span span {
  display: inline;
  width: auto;
  float: none;
}
.manage-all-notification {
  padding-bottom: 0 !important;
  position: absolute;
  margin: 0 5px !important;
  min-width: 250px !important;
  top: 46px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  display: none;
  z-index: 1;
}
.open > .manage-all-notification {
  display: block;
}
#denthub-header .dropdown-menu.clear-all-notifications {
  background: #105c9d !important;
  border-radius: 0;
}
#denthub-header .dropdown-menu.clear-all-notifications li a {
  text-align: center;
  font-size: 20px !important;
  color: #fff !important;
  padding: 10px !important;
  font-weight: normal !important;
}
#denthub-header .dropdown-menu.clear-all-notifications li a:hover {
  background: #105c9d !important;
}
#navBar .nav > li > a.add-dentist-sub-menu {
  width: 175px;
}
.transform {
  transform: scaleX(-1);
}
#imaginary_container .overlay > div:not(.loading-text) {
  left: 90%;
}
.reload-page-text {
  position: fixed;
  right: 0;
  z-index: 9;
  background: #f5d823;
  padding: 10px 16px;
  color: black;
  font-size: 14px;
}
.reload-page-text p {
  margin: 0;
}
.reload-page-text p .message {
  margin-right: 10px;
  font-weight: 500;
}
order-new > .row {
  max-width: 1200px;
  margin: auto !important;
}
/*patient Lab css*/
#patient-section {
  width: 100%;
  margin-left: auto;
}
#patient-section .patientLab {
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 35px;
  padding: 18px 61px 50px;
  margin-top: 155px;
}
#patient-section h1.patientTitle {
  float: left;
  font-size: 20px;
  color: #1f2532;
  font-weight: 700;
  border-bottom: solid 1px #1f2532;
  width: 100%;
  line-height: 50px;
}
#patient-section .SearchPatient {
  width: 100%;
  float: left;
  padding: 40px 70px 10px;
}
#patient-section .SearchPatient .col-lg-2 {
  width: 14% !important;
}
#patient-section .SearchPatient .col-lg-2 {
  width: 19% !important;
  padding: 0;
  padding-right: 54px;
  min-height: 150px;
}
#patient-section .SearchPatient h1 {
  font-size: 18px;
  color: #1f2532;
  font-weight: 700;
}
#patient-section .SearchPatient ul {
  float: left;
  width: 85%;
  margin-top: 15px;
}
#patient-section .SearchPatient ul li {
  float: left;
  width: 28%;
  margin-right: 8%;
  text-align: center;
  position: relative;
}
#patient-section .SearchPatient ul li input {
  text-align: center;
  border-radius: initial;
  box-shadow: inherit;
}
#patient-section .SearchPatient ul li input.success-value {
  border: solid 1px green;
  color: #000;
  letter-spacing: 1.2px;
  font-weight: 400;
}
#patient-section .SearchPatient ul li input.success-error {
  border: solid 1px red;
}
#patient-section .SearchPatient ul li label {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 6px;
}
#patient-section .SearchPatient ul li input.form-control {
  height: 32px;
  padding: 5px 12px;
}
#patient-section .SearchPatient ul li:last-child {
  margin-right: 0;
  float: right;
}
#patient-section .chooseLAb {
  float: left;
  width: 100%;
  background: #ffffff;
  padding: 5px 0 0;
  text-align: center;
  margin: 25px auto auto;
  display: block;
  cursor: pointer;
  border: solid 2px #eeecec;
  overflow: hidden;
}
#patient-section .chooseLAb h1 {
  font-weight: 400 !important;
  font-size: 12px;
  padding: 0;
  color: #676666;
  margin-top: 5px !important;
  margin-bottom: 0px !important;
  background: #eeecec;
  padding: 9px 0 5px;
}
#patient-section .chooseLAb.active {
  background: #1f2532;
  cursor: pointer;
  border-color: #1f2532;
}
#patient-section .chooseLAb:hover {
  background: #1f2532;
  cursor: pointer;
}
#patient-section .chooseLAb:hover h1 {
  color: #fff !important;
}
#patient-section .chooseLAb.active h1 {
  color: #fff !important;
  background: #1f2532;
}
#patient-section .chooseLAb .imageWrapper {
  width: 100%;
  height: 68px;
}
#patient-section .chooseLAb img {
  width: 100%;
  height: 68px;
  object-fit: contain;
}
#patient-section span.check-icons i {
  background: #105c9d;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  padding-top: 3px;
  position: absolute;
  top: 46px;
  right: -20px;
  font-size: 10px;
}
@-moz-document url-prefix() {
  #patient-section span.check-icons i {
    top: 40px;
  }
}
.no-padding-default {
  padding: 0 !important;
}
/*Delivery Information ccs*/
#delivery-section {
  width: 100%;
  padding-top: 55px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
#delivery-section .DeliveryInformation {
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 35px;
  padding: 18px 61px 50px;
  padding-bottom: 35px;
}
#delivery-section h1.patientTitle {
  float: left;
  font-size: 20px;
  color: #1f2532;
  font-weight: 700;
  border-bottom: solid 1px #1f2532;
  width: 100%;
  line-height: 50px;
}
#delivery-section .DelInf {
  float: left;
  width: 80%;
  margin-left: 10%;
}
#delivery-section .deliveryDate {
  float: left;
  width: 100%;
  margin-top: 41px;
}
#delivery-section .deliveryDate h3 {
  font-size: 18px;
  font-weight: bold;
  color: #105c9d;
}
#delivery-section .deliveryDate ul {
  width: 100%;
  float: left;
  margin-top: 10px;
}
#delivery-section .deliveryDate ul li {
  float: left;
  width: 100%;
  background: #eeecec;
  margin-right: 1%;
  text-align: center;
  margin-bottom: 2%;
}
#delivery-section .deliveryDate ul li a {
  color: #5a5959;
}
#delivery-section .deliveryDate ul li select {
  width: 100%;
  border: 0;
  background: transparent;
  padding: 2px 9px;
  cursor: pointer;
}
#delivery-section .deliveryDate ul li input {
  width: 100%;
  border: 0;
  background: transparent;
  margin: 0;
  float: left;
  padding: 2px 9px;
  cursor: pointer;
  min-height: 26px;
}
#delivery-section .form-group {
  margin-bottom: 15px;
  margin-top: 22px;
  margin-left: 15px;
}
#delivery-section .form-group label {
  font-size: 18px;
  font-weight: bold;
  color: #105c9d;
}
#delivery-section .padding_Bottom-50 .btn-default:hover,
#delivery-section .padding_Bottom-50 .btn-default.active {
  background: #1f2532;
  color: #fff;
  box-shadow: initial;
}
#delivery-section .padding_Bottom-50 .btn-default {
  color: #1f2532;
  background-color: #fff;
  border-color: #1f2532;
  font-size: 15px;
  border-radius: initial;
  font-weight: 600;
  padding: 13px 40px;
}
.flex-textarea {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 400px;
  height: 250px;
  background-color: lightgrey;
  text-align: center;
}
/*upload-images css*/
#images-section {
  width: 100%;
  padding-top: 60px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
#images-section .upload-images {
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 35px;
  padding: 18px 61px 50px;
  padding-bottom: 30px;
}
#images-section h1.patientTitle {
  float: left;
  font-size: 20px;
  color: #1f2532;
  font-weight: 700;
  border-bottom: solid 1px #1f2532;
  width: 100%;
  line-height: 50px;
}
#images-section .images-upload {
  float: left;
  width: 77%;
  background: #f2f2f2;
  text-align: center;
  margin-bottom: 20px;
  margin-left: 12%;
  margin-top: 43px;
  padding: 41px 20px 40px;
}
#images-section .images-upload img {
  width: 90px;
}
#images-section .drop-box {
  margin-top: 0px;
  padding: 41px 20px 40px;
  cursor: pointer;
}
#images-section .upload-images .drop-box.filechoose-div {
  width: 100%;
  margin-top: 28px !important;
  border: none;
  padding: 41px 20px 40px;
  cursor: pointer;
}
#images-section .progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  width: 50%;
  margin-top: 14px;
  float: left;
}
#images-section .progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  color: #fff;
  text-align: center;
  background-color: #1f2532 !important;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
#images-section span.images-file-name {
  float: left;
  margin-right: 12px;
  margin-left: 10px;
  padding-top: 14px;
  font-size: 15px;
}
#images-section span.images-file-name i {
  cursor: pointer;
}
#images-section span.Images-circle img {
  width: 100%;
}
#images-section span.Images-circle {
  float: left;
  width: 50px;
  height: 50px;
  margin: 5px;
}
#images-section span.images-refresh {
  padding-top: 17px;
  float: left;
  margin-left: 16px;
}
#images-section span.file-icon {
  float: left;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 10px;
}
#images-section span.file-icon i {
  font-size: 4em;
}
/*signSend css*/
#sign-section {
  width: 100%;
  padding-top: 60px;
}
#sign-section .signSend {
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 35px;
  padding: 18px 61px 50px;
  padding-bottom: 45px;
  margin-bottom: 45px;
}
#sign-section h1.patientTitle {
  float: left;
  font-size: 20px;
  color: #1f2532;
  font-weight: 700;
  border-bottom: solid 1px #1f2532;
  width: 100%;
  line-height: 50px;
}
#sign-section .signSendbox {
  float: left;
  width: 100%;
  text-align: center;
}
.sign-with-img-icon {
  position: relative;
  width: auto;
  display: inline-block;
  float: none;
}
.sign-with-img-icon span.right-sign-all-select {
  float: left;
  width: 50px;
  margin: 0 10px 0 0;
  height: 50px;
  background: #105c9d;
  border: 2px solid #105c9d;
  outline: 0;
  /* cursor: pointer;*/
  -webkit-appearance: inherit;
  position: absolute;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 30px;
  border-radius: 100%;
  bottom: 10px;
  right: -45px;
}
.sign-with-img-icon span.right-sign-all-select:after {
  content: '\f00c';
  position: absolute;
  top: 17px;
  left: 15px;
  font-size: 30px;
  color: #ffffff;
}
#sign-section .signSendbox h1 {
  font-size: 25px;
  color: #000;
  font-weight: bold;
  margin-bottom: 20px;
}
#sign-section .signSendbox p {
  font-size: 15px;
  margin-bottom: 15px;
}
#sign-section label.btn {
  background: #fff;
  border: solid 1px #ccc;
  border-radius: inherit;
  margin-right: 15px;
  width: 22px;
  height: 26px;
  margin-top: -1px;
}
#sign-section label.btn input {
  opacity: 0;
}
#sign-section label.btn.active {
  background: #105c9d;
  color: #fff;
  border: solid 1px #105c9d;
}
#sign-section label.btn .glyphicon {
  display: none;
}
#sign-section label.btn.active .glyphicon {
  left: -7px;
  top: -17px;
  font-size: 12px;
  display: block;
}
#sign-section .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: inherit;
  border-top-right-radius: inherit;
}
#sign-section .btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: inherit;
  border-top-left-radius: inherit;
}
#sign-section .btn.active:focus {
  outline: inherit;
}
#sign-section .signSendbox .glyphicon-ok:before {
  content: '\e013';
  color: #fff;
}
#sign-section .signSendbox img {
  width: 85px;
  margin: 40px 0px;
}
#sign-section .signSendbox button.btn.btn-default.active,
.signSendbox button.btn.btn-default.active {
  background: #1f2532;
  color: #fff;
  box-shadow: initial;
}
#sign-section .signSendbox button.btn.btn-default {
  color: #1f2532;
  background-color: #fff;
  border-color: #1f2532;
  font-size: 15px;
  border-radius: initial;
  font-weight: 600;
  padding: 9px 0px;
  min-width: 155px;
  text-transform: uppercase;
}
.has-error {
  border: 1px solid #de4744 !important;
}
#sign-section .signSendbox .well-sm {
  margin-bottom: 18px;
}
#sign-section span.Sample-disinfected.ng-binding {
  font-size: 15px;
}
#sign-section .glyphicon {
  left: -6px;
  top: 0;
  font-size: 12px;
}
.square {
  border: solid 1px #dcdada;
  border-radius: 8px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  margin: 0 3px;
  margin-right: 0px;
}
.bridge-suggestion {
  margin-left: 20px;
  width: 120px;
  margin-top: 10px;
  border: 1px solid #8dc44e;
  border-radius: 15px;
  padding: 10px;
  color: #8dc44e;
}
label.bridge-suggestion {
  max-width: inherit !important;
  text-align: center;
}
.partial-suggestion {
  margin-left: 20px;
  width: 120px;
  margin-top: 24px;
  border: 1px solid #8dc44e;
  border-radius: 15px;
  padding: 10px;
  color: #8dc44e;
}
label.partial-suggestion {
  max-width: inherit !important;
  text-align: center;
}
.square-bg {
  background: #a6d273;
}
.square-selected {
  border: 3px solid #1f2532 !important;
}
.softInside .checkbox-inline {
  padding-left: 0px;
  width: 100%;
}
.color-sampling-radio input[type='radio']:not(:checked) {
  float: left;
  width: 15px;
  margin: 0px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid #4a4a4a;
  outline: none;
  -webkit-appearance: inherit;
  padding: 8px;
  border-radius: 50%;
  position: static;
  cursor: pointer;
}
.color-sampling-radio input[type='radio']:checked {
  float: left;
  width: 15px;
  margin: 0px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid #2c3995;
  outline: none;
  cursor: pointer;
  -webkit-appearance: inherit;
  position: static;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 8px;
  border-radius: 50%;
}
.color-sampling-radio input[type='radio']:checked:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  font-size: 12px;
  width: 12px;
  height: 12px;
  background: #2c3995;
  border-radius: 50%;
  color: #2c3995;
}
.color-sampling-radio.implant-bridge-retainment input[type='radio']:checked {
  border: 2px solid #096d9f;
}
.color-sampling-radio.implant-bridge-retainment input[type='radio']:checked:after {
  background: #096d9f;
}
.color-sampling-radio.implant-crown-retainment input[type='radio']:checked {
  border: 2px solid #e65405;
}
.color-sampling-radio.implant-crown-retainment input[type='radio']:checked:after {
  background: #e65405;
}
.signSendbox .marginBottom input[type='checkbox'] {
  opacity: 1 !important;
}
.signSendbox .marginBottom #Sample-disinfected,
.signSendbox .marginBottom #sampleDisinfected1 {
  opacity: 0 !important;
}
/*.signSendbox .marginBottom input[type="checkbox"]{ opacity: 1 !important;}
  .signSendbox .marginBottom input[type="checkbox"]:not(:checked){
    float: left;
    width: 22px;
    margin: 0px 10px 0px 0px;
    height: 26px;
    background: none;
    border: 0px solid @post-block-color;
    outline: none;
    -webkit-appearance: inherit;
    padding: 0px;
    border-radius: 50%;
    position: static;
    cursor: pointer;
  }

  .signSendbox .marginBottom input[type="checkbox"]:checked {
    float: left;
    width: 22px;
    margin: 0px 10px 0px 0px;
    height: 26px;
    background: none;
    border: 0px solid @complete-denture;
    outline: none;
    cursor: pointer;
    -webkit-appearance: inherit;
    position: static;
    font: normal normal normal 14px/1 FontAwesome;
    padding: 0px;
    border-radius: 50%;
  }

  .signSendbox .marginBottom input[type="checkbox"]:checked:after {
    content: '\f00c';
    position: absolute;
    top: 4px;
    left: 4px;
    font-size: 15px;
    color: @white-color;
  }*/
.color-sampling-radio .checkbox-inline {
  padding-left: 0px;
  width: 100%;
  margin-left: 0px !important;
  margin-top: 5px !important;
  font-size: 13px;
}
.color-sampling-radio {
  margin-top: 20px;
}
.color-sampling-radio h3 {
  font-size: 15px;
  margin-bottom: 15px;
}
.color-sampling-radio img {
  max-width: 50%;
}
.image-printable {
  padding: 5% 5% 5% 5%;
  align-content: center;
}
.image-printable img {
  height: 600px;
}
#sign-section label.btn:focus {
  outline: 0;
  outline-offset: 0;
}
.square-post {
  position: relative;
}
.square-post:after {
  width: 0;
  height: 0;
  border-bottom: 28px solid #4a4a4a;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  position: absolute;
  right: 14px;
  bottom: -4px;
  content: '';
}
.square-post:hover:after {
  bottom: -1px;
  right: 12px;
}
.square-selected.square-post:after {
  bottom: -1px;
  right: 12px;
}
.square-bg:hover {
  border: solid 3px #1f2532;
  position: relative;
}
.square {
  position: relative;
}
.cross-square {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #1f2532;
  position: absolute;
  top: -12px;
  right: -8px;
  color: #fff;
  line-height: 20px;
  font-size: 12px;
  display: none;
  z-index: 9;
}
.cross-rectangle-post {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #1f2532;
  position: absolute;
  bottom: -19px;
  right: 3.5px;
  color: #fff;
  line-height: 20px;
  font-size: 12px;
  display: none;
}
.square:hover {
  border: solid 3px #1f2532;
}
p.red-astrix-mark {
  display: inline-block;
  padding: 0 5px;
  color: #ff0515 !important;
}
b.red-astrix-mark {
  display: inline;
  color: #ff0515;
}
p.required-field.ng-binding {
  color: #ff0515;
  font-size: 13px;
  position: absolute;
  bottom: -5px;
  right: 28px;
  margin-bottom: 0px;
}
.margin-top {
  margin-top: 35px !important;
}
.margin-top-45 {
  margin-top: -15px !important;
}
.margin-top-75 {
  margin-top: -61px !important;
}
.margin-top-130 {
  margin-top: -95px !important;
}
input::-webkit-input-placeholder {
  color: #979797;
}
input::-moz-placeholder {
  color: #979797;
}
input:-ms-input-placeholder {
  color: #979797;
}
input:-moz-placeholder {
  color: #979797;
}
.identityError {
  color: #d0021b;
}
a._720kb-datepicker-calendar-day._720kb-datepicker-disabled {
  background-color: #ffb5b6 !important;
}
a._720kb-datepicker-calendar-day._720kb-datepicker-active {
  background-color: #6ed803 !important;
}
._720kb-datepicker-calendar {
  z-index: 9 !important;
}
._720kb-datepicker-calendar-header:nth-child(odd),
._720kb-datepicker-calendar-header:nth-child(even) {
  background: #1f2532 !important;
  border-bottom: solid 1px #fff;
}
.commet-export-production p {
  display: inline-block;
  line-height: 22px;
  font-size: 15px;
  font-weight: 400;
  color: #5c5c5c;
  text-shadow: none;
}
.commet-export-production input[type='checkbox']:not(:checked),
.commet-export-production input.notCheckedInput {
  float: left;
  width: 15px;
  height: 15px;
  -webkit-appearance: inherit;
  margin: 2px 10px 0px 0px;
  background: none;
  border-width: 2px;
  border-style: solid;
  border-color: #8edddd;
  border-image: initial;
  outline: none;
  padding: 7px;
  border-radius: 2px;
}
.commet-export-production input[type='checkbox']:checked,
.commet-export-production input:checked.checkedInput {
  float: left;
  width: 15px;
  height: 15px;
  -webkit-appearance: inherit;
  position: relative;
  margin: 2px 10px 0px 0px;
  background: #1fbabb;
  border-width: 2px;
  border-style: solid;
  border-color: #1fbabb;
  border-image: initial;
  outline: 0px;
  font: 14px/1 FontAwesome;
  padding: 7px;
  border-radius: 4px;
}
.commet-export-production input[type='checkbox']:checked:after,
.commet-export-production input:checked.checkedInput:after {
  content: '\f00c';
  position: absolute;
  top: 1px;
  left: 1px;
  font-size: 13px;
  color: #fff;
}
.new-order-comments {
  margin-bottom: 0px !important;
  font-size: 13px !important;
}
/*label.checkbox-style{
    font-weight: normal; font-size: 14px; margin-top: 20px;
    label {
      color: @h1-color !important;
      margin-left: 0 !important;
      font-weight: 400;
    }
    input[type=radio],
    input[type=checkbox],
    input.notCheckedInput {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: transparent;
      position: relative;
      visibility: hidden;
    }
    input[type=radio],
    input[type=checkbox],
    input[type=radio] + label::before,
    input[type=checkbox] + label::before,
    input.notCheckedInput + label::before {
      font-size: 18px;
      line-height: 20px;
      cursor: pointer;
      vertical-align: middle;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      border-radius: 5px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
      display: none;
    }

    input[type=radio] {
      border-radius: 50%;
    }

    input[type=radio] + label::before,
    input[type=checkbox] + label::before,
    input.notCheckedInput + label::before{
      content: '';
      text-align: center;
      display: inline-block;
      pointer-events: none;
      opacity: 1;
      color: black;
      transition: all .3s ease;
      border: 2px solid @sky-start-dark;
      width: 23px;
      height: 23px;
      margin-right: 15px;
    }
    input[type=radio] + label::before,
    input.checkedInput + label::before,
    input.ng-not-empty + label::before{
      background: @sky-start-dark;
    }
    input[type=radio] + label,
    input[type=checkbox] + label,
    input.notCheckedInput + label {
      line-height: 23px;
      margin: 0 15px 0 15px;
    }
    input[type=checkbox]:hover,
    input.notCheckedInput:hover {
      cursor: pointer;
    }
    input.notCheckedInput.ng-empty:hover + label::before {
      content: '';
    }
    input[type=checkbox]:checked + label::before,
    input.checkedInput + label::before {
      content: '\2714';
      font-size: 14px;
      text-align: center;
      color: #fff !important;
    }

    input[type=radio]:checked + label::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      font-size: 12px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      color: #2c3995;
      background: #096d9f;
    }

    input[type=checkbox]:checked:hover + label::before,
    input.checkedInput:hover + label::before {
      opacity: 1;
    }
  }*/
label.checkbox-style {
  width: 100%;
  float: left;
  font-weight: normal;
  font-size: 14px;
  position: relative;
  cursor: pointer;
}
label.checkbox-style label {
  cursor: pointer;
  color: #1f2532 !important;
  margin-left: 0 !important;
  font-weight: 400;
}
label.checkbox-style label .email-noti-para span:last-child span {
  display: none;
}
label.checkbox-style input[type='checkbox'],
label.checkbox-style input[type='radio'],
label.checkbox-style input.notCheckedInput {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
}
label.checkbox-style input.chooseLAb[type='radio'] {
  width: 30px;
}
label.checkbox-style input[type='checkbox'] + label,
label.checkbox-style input[type='radio'] + label,
label.checkbox-style input.notCheckedInput + label {
  position: relative;
  padding-left: 30px;
  line-height: 23px;
  float: left;
}
label.checkbox-style input[type='checkbox'] + label:after,
label.checkbox-style input[type='radio'] + label:after,
label.checkbox-style input.notCheckedInput + label:after,
label.checkbox-style input.chooseLAb[type='radio'] + label:after {
  width: 23px;
  height: 23px;
  content: '';
  border: solid 2px #4a4a4a;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  border-radius: 3px;
}
label.checkbox-style input[type='radio'] + label:after {
  border-radius: 50%;
}
label.checkbox-style input[type='checkbox']:checked + label:after,
label.checkbox-style input.checkedInput + label:after,
label.checkbox-style input.chooseLAb.active[type='radio'] + label:after {
  border-color: #105c9d;
}
label.checkbox-style input[type='checkbox']:checked + label:before,
label.checkbox-style input.checkedInput + label:before,
label.checkbox-style input.chooseLAb.active[type='radio'] + label:before {
  content: '\2714';
  font-size: 14px;
  text-align: center;
  color: #fff !important;
  background: #105c9d;
  width: 23px;
  height: 23px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 3px;
  z-index: 8;
}
label.checkbox-style input[type='radio']:checked + label:before,
label.checkbox-style input[type='radio'].checkedInput:checked + label:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  color: #105c9d;
  background: #105c9d;
}
label.checkbox-style input[type='radio']:checked + label:after {
  border-color: #105c9d;
}
.implant-crown-retainment label.checkbox-style input[type='checkbox']:checked + label:after,
.implant-crown-retainment label.checkbox-style input.checkedInput + label:after,
.implant-crown-retainment label.checkbox-style input[type='radio']:checked + label:after {
  border-color: #e65405;
}
.implant-crown-retainment label.checkbox-style input[type='checkbox']:checked + label:before,
.implant-crown-retainment label.checkbox-style input.checkedInput + label:before,
.implant-crown-retainment label.checkbox-style input[type='radio']:checked + label:before {
  background: #e65405;
}
.implant-bridge-retainment label.checkbox-style input[type='checkbox']:checked + label:after,
.implant-bridge-retainment label.checkbox-style input.checkedInput + label:after,
.implant-bridge-retainment label.checkbox-style input[type='radio']:checked + label:after {
  border-color: #096d9f;
}
.implant-bridge-retainment label.checkbox-style input[type='checkbox']:checked + label:before,
.implant-bridge-retainment label.checkbox-style input.checkedInput + label:before,
.implant-bridge-retainment label.checkbox-style input[type='radio']:checked + label:before {
  background: #096d9f;
}
.complete-denture-section label.checkbox-style input[type='checkbox']:checked + label:after,
.complete-denture-section label.checkbox-style input.checkedInput + label:after,
.complete-denture-section label.checkbox-style input[type='radio']:checked + label:after {
  border-color: #2c3995;
}
.complete-denture-section label.checkbox-style input[type='checkbox']:checked + label:before,
.complete-denture-section label.checkbox-style input.checkedInput + label:before,
.complete-denture-section label.checkbox-style input[type='radio']:checked + label:before {
  background: #2c3995;
}
.signSendbox label.checkbox-style label {
  color: #a5a3a3;
}
.export-prod:after {
  z-index: 8 !important;
}
.flex-div-layout {
  display: flex;
  flex-wrap: wrap;
}
.flex-div-layout .col-lg-2 {
  min-width: 19% !important;
}
@media only screen and (min-width: 768px) {
  .simple-order-section {
    padding-top: 0 !important;
    margin-top: -15px;
  }
}
@media only screen and (min-width: 768px) {
  .simple-order-image-sign-section {
    padding-top: 0 !important;
    margin-top: -7px;
  }
}
:root {
  --radio-color: #2cba92;
}
.mr-18 {
  margin-right: 18px !important;
}
order-new-2 > .row {
  max-width: 1300px;
  margin: auto;
}
#patient-section.order-two-part .SearchPatient ul {
  width: 100%;
}
#patient-section.order-two-part .SearchPatient ul li {
  width: 30%;
  margin-right: 3%;
  text-align: left;
}
#patient-section.order-two-part .SearchPatient ul.four-column li {
  width: 22%;
}
#patient-section.order-two-part .SearchPatient ul.five-column li {
  width: 17%;
}
#patient-section.order-two-part .SearchPatient ul li .dropdown {
  margin-left: 0;
}
#patient-section.order-two-part .SearchPatient ul li .dropdown .btn.form-control {
  height: 32px;
  box-shadow: none;
  border-radius: 0;
}
#patient-section.order-two-part .SearchPatient ul li label {
  margin-bottom: -6px;
}
#patient-section.order-two-part .SearchPatient ul li .dropdown ul.dropdown-menu {
  min-width: 183px !important;
  margin: 0;
}
#patient-section.order-two-part .SearchPatient ul li .dropdown ul li.ui-select-choices-group {
  width: 100%;
  margin: 0;
}
.next-step-btn {
  width: 100%;
  float: left;
  padding: 40px 106px 10px;
}
.next-step-btn .step-button {
  width: 220px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: none;
  line-height: 43px;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  margin-right: 0;
  padding: 0 20px;
  background: #105c9d;
}
.next-step-btn .step-button i {
  font-size: 24px;
  font-weight: normal;
}
.previous-step-btn.step-button {
  float: left;
}
.next-step-btn .step-button.disabled {
  border-color: #dcdada;
  color: #dcdada;
  opacity: 0.7;
  cursor: not-allowed;
}
#images-section.order-two-part .upload-images h3.patientTitle {
  padding-left: 12%;
}
#order-list.order-two-part .order-list {
  margin-top: 15px;
}
#order-list.order-two-part .order-list .order-list-box .order-list-content {
  cursor: default;
}
#order-list.order-two-part .order-list .order-list-hading .col-lg-2,
#order-list.order-two-part .order-list .order-list-box .order-list-content .col-lg-2 {
  width: 16.66666667%;
}
#order-list.order-two-part .order-list .order-list-box .order-list-content .mobi-manage-content .font-manage {
  padding-top: 13px;
}
#order-list.order-two-part .order-list .order-list-box .order-list-content .mobi-manage-content:first-child .font-manage {
  padding-top: 0;
}
#order-list.order-two-part .order-list .order-list-box .order-list-content .mobi-manage-content .font-manage label.checkbox-style {
  width: auto;
  margin-top: 10px;
}
.permissions-div label.checkbox-style {
  margin-top: 0 !important;
}
#order-list.order-two-part .order-list .order-list-box .order-list-content .mobi-manage-content .font-manage img {
  width: auto;
  float: right;
  max-height: 70px;
  max-width: 70%;
  margin-top: 3px;
}
.permissions-edit-role button {
  margin: 3px !important;
}
#sign-section .sign-send {
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 35px;
  padding: 18px 61px 50px;
}
#sign-section .sign-send .confirm-info-sign-send h3 {
  float: left;
  font-size: 18px;
  color: #1f2532;
  font-weight: 700;
  width: 100%;
  line-height: 70px;
}
#sign-section .sign-send .confirm-info-sign-send h2 {
  float: left;
  font-size: 20px;
  color: #1f2532;
  font-weight: 800;
  border-bottom: solid 2px #1f2532;
  width: 100%;
  line-height: 30px;
  padding: 0 15px;
}
#sign-section .sign-send .confirm-info-sign-send p {
  font-weight: 400;
  margin: 6px 0 0;
  float: left;
  width: 100%;
  line-height: 20px;
  color: #1f2532;
  padding: 0 15px;
}
#sign-section .sign-send .confirm-info-sign-send p label,
#sign-section .sign-send .confirm-info-sign-send .teeth-construction-action .action-on-teeth .action-text label,
#sign-section.order-details-2 .sign-send .confirm-info-sign-send p label {
  font-weight: 500;
  color: #a5a3a3;
  width: 40%;
  margin-bottom: 2px;
  float: left;
  min-width: 40%;
}
#sign-section .sign-send .confirm-info-sign-send p label span,
#sign-section.order-details-2 .sign-send .confirm-info-sign-send p label span .red-astrix-mark {
  color: #ff0515 !important;
}
#sign-section .sign-send .confirm-info-sign-send p label.remove-parent-astrick-color span {
  color: #000 !important;
}
#sign-section .sign-send .confirm-info-sign-send p label.remove-parent-astrick-color span.red-color {
  color: #ff0515 !important;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send p label.handle-lab-request {
  width: 39%;
  min-width: 39%;
  margin-right: 5px;
}
#sign-section .sign-send .confirm-info-sign-send .teeth-construction-action .action-on-teeth p.comment-p label {
  font-weight: normal;
}
.aside-area {
  width: 60%;
  float: left;
}
.aside-area label.checkbox-style {
  width: 100% !important;
  display: flex;
}
.aside-area label.checkbox-style label.check-radio-color {
  width: auto !important;
  margin-right: 10px;
  min-width: 52% !important;
}
.aside-area label.checkbox-style span {
  line-height: 25px;
  color: #000;
}
.aside-area label.checkbox-style span.date-span {
  margin-left: auto;
}
.aside-area label.checkbox-style span.date-span.sign-send-date-span {
  margin-left: 0;
}
.aside-area label.checkbox-style span:after {
  content: '';
}
.aside-area span {
  position: relative;
  padding-right: 10px;
  float: left;
}
.aside-area span:after {
  position: absolute;
  right: 5px;
  bottom: 0;
  content: ',';
}
.aside-area span:last-child:after {
  content: '';
}
.aside-area span span {
  padding-right: 0;
}
p.comment-p .aside-area {
  width: 90%;
  float: left;
  text-align: justify;
}
p.comment-p.steps-manage-area {
  width: 50% !important;
  display: block !important;
  min-width: 50% !important;
}
p.comment-p.steps-manage-area label {
  width: 100% !important;
  display: flex !important;
}
p.comment-p.steps-manage-area .aside-area {
  width: 100% !important;
  display: block;
}
.mt-20 {
  margin-top: 20px;
}
.mt-35 {
  margin-top: 35px;
}
#sign-section .sign-send .confirm-info-sign-send h4 {
  float: left;
  font-size: 20px;
  color: #1f2532;
  font-weight: 800;
  border-bottom: solid 2px #105c9d;
  width: 100%;
  line-height: 30px;
  padding: 0 15px;
  cursor: pointer;
}
#sign-section .sign-send .confirm-info-sign-send h4.single-crown-h4 {
  border-bottom: solid 2px #cddc39;
}
#sign-section .sign-send .confirm-info-sign-send h4.bridge-h4 {
  border-bottom: solid 2px #e92266;
}
#sign-section .sign-send .confirm-info-sign-send h4.post-h4 {
  border-bottom: solid 2px #4a4a4a;
}
#sign-section .sign-send .confirm-info-sign-send h4.implant-crown-h4 {
  border-bottom: solid 2px #e65405;
}
#sign-section .sign-send .confirm-info-sign-send h4.implant-bridge-h4 {
  border-bottom: solid 2px #096d9f;
}
#sign-section .sign-send .confirm-info-sign-send h4.partial-denture-h4 {
  border-bottom: solid 2px #a6d273;
}
#sign-section .sign-send .confirm-info-sign-send h4.temporary-denture-h4 {
  border-bottom: solid 2px #0095a8;
}
#sign-section .sign-send .confirm-info-sign-send h4.complete-denture-h4 {
  border-bottom: solid 2px #2c3995;
}
#sign-section .sign-send .confirm-info-sign-send h4.bitetray-h4 {
  border-bottom: solid 2px #ffcb05;
}
#sign-section .sign-send .confirm-info-sign-send h4.other-h4 {
  border-bottom: solid 2px #461b7e;
}
#sign-section .sign-send .confirm-info-sign-send .teeth-construction-action .action-on-teeth p {
  width: 50%;
  float: left;
}
#sign-section .sign-send .confirm-info-sign-send .teeth-construction-action .action-on-teeth p i {
  min-width: 22px;
  height: 22px;
  text-align: center;
  line-height: 20px;
  border: solid 2px #105c9d;
  border-radius: 50%;
  margin-left: -29px;
  color: #105c9d;
  cursor: pointer;
  margin-top: -3px;
}
#sign-section .sign-send .confirm-info-sign-send .teeth-construction-action .action-on-teeth p.comment-p {
  width: 100%;
  margin-top: 25px;
}
div.comment-p {
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
}
#sign-section .sign-send .confirm-info-sign-send .teeth-construction-action .action-on-teeth .action-text {
  display: flex;
  width: 100%;
  float: left;
  padding: 15px 15px 0;
}
#sign-section .sign-send .confirm-info-sign-send .teeth-construction-action .action-on-teeth .action-text .action-perform-text span strong,
#sign-section .sign-send .confirm-info-sign-send .teeth-construction-action .action-on-teeth .action-text label {
  color: #1e2533;
  font-weight: 800;
  width: 19%;
  min-width: 19%;
}
#sign-section .sign-send .confirm-info-sign-send .teeth-construction-action .action-on-teeth .action-text .action-perform-text {
  width: 81%;
  float: left;
}
#sign-section .sign-send .confirm-info-sign-send .teeth-construction-action .action-on-teeth p.comment-p label {
  width: 80px;
  min-width: inherit;
}
#sign-section .sign-send .confirm-info-sign-send .teeth-construction-action .action-on-teeth .action-text .action-perform-text span {
  width: 100%;
  float: left;
  font-weight: normal;
}
#sign-section .sign-send .files-box {
  width: 100%;
  float: left;
  text-align: center;
}
#sign-section .sign-send .files-box img {
  width: auto;
  height: 100px;
  float: none;
  display: inline-block;
  margin: 0;
}
#sign-section .sign-send .files-box .file-icon {
  margin: 0;
  display: inline-block;
  text-align: center;
  float: none;
}
#sign-section .sign-send .files-box .file-icon i {
  font-size: 100px;
}
#sign-section .sign-send .files-box h3 {
  width: 100%;
  float: left;
  margin: 12px 0 0;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
}
#sign-section .sign-send .confirm-info-sign-send .sending-box,
#sign-section .signSend .confirm-info-sign-send .sending-box {
  width: 100%;
  float: left;
  display: inline-block;
  text-align: center;
  margin: 50px 0 20px;
}
#sign-section .sign-send .confirm-info-sign-send .sending-box label,
#sign-section .signSend .confirm-info-sign-send .sending-box label {
  width: 100%;
  font-size: 20px;
  display: inline-block;
  color: #105c9d;
}
#sign-section .sign-send .confirm-info-sign-send .sending-box p {
  width: 100%;
  display: inline-block;
  color: #105c9d;
  line-height: 40px;
  font-size: 20px;
  font-weight: bold;
}
#sign-section .sign-send .confirm-info-sign-send .sending-box p input[type='checkbox']:not(:checked) {
  margin-left: 10px;
  border: 1px solid #105c9d;
  outline: none;
  -webkit-appearance: inherit;
  padding: 3px 15px;
  position: static;
  cursor: pointer;
  display: inline;
}
#order-list.order-two-part .order-list .order-list-box .order-list-content .font-manage input[type='radio']:not(:checked) {
  margin-right: 10px;
  border: 1px solid #105c9d;
  outline: none;
  -webkit-appearance: inherit;
  padding: 6px 15px 5px;
  position: static;
  cursor: pointer;
  display: inline;
  float: left;
  height: 32px;
}
#sign-section .sign-send .confirm-info-sign-send .sending-box p input[type='checkbox']:checked {
  margin-left: 10px;
  border: 1px solid #105c9d;
  outline: none;
  cursor: pointer;
  -webkit-appearance: inherit;
  position: relative;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 8px 15px;
  display: inline;
  background: #105c9d;
}
#order-list.order-two-part .order-list .order-list-box .order-list-content .font-manage input[type='radio']:checked {
  margin-right: 10px;
  border: 1px solid #105c9d;
  outline: none;
  cursor: pointer;
  -webkit-appearance: inherit;
  position: relative;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 8px 15px;
  display: inline;
  background: #105c9d;
  float: left;
  height: 32px;
}
#sign-section .sign-send .confirm-info-sign-send .sending-box p input[type='checkbox']:checked:after {
  content: '\f00c';
  position: absolute;
  top: 7px;
  left: 7px;
  font-size: 16px;
  color: #fff;
}
#order-list.order-two-part .order-list .order-list-box .order-list-content .font-manage input[type='radio']:checked:after {
  content: '\f00c';
  position: absolute;
  top: 7px;
  left: 7px;
  font-size: 16px;
  color: #fff;
}
#patient-section.order-two-part .SearchPatient ul li:first-child input.form-control {
  text-align: left;
}
#patient-section.order-two-part span.check-icons i {
  top: 36px;
  right: 8px;
  padding: 3px 0 0 3px;
}
.several-options {
  width: 100%;
  float: left;
  margin: 20px 0 30px;
}
.several-options .search-material-actions.dropdown {
  margin-left: 0;
}
.several-options .search-material-actions.dropdown input.full-width {
  background: #e6e6e6;
  color: #333;
  border: 0;
  width: 100%;
  cursor: pointer;
  float: left;
  line-height: 32px;
  padding: 6px;
  border-radius: 4px;
}
.several-options .search-material-actions.dropdown input.full-width:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.several-options .search-material-actions.dropdown input.full-width:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.several-options .search-material-actions.dropdown input.full-width + i {
  position: absolute;
  right: 20px;
  top: 15px;
  color: #fff;
}
.several-options .search-material-actions.dropdown .option-materials-actions.dropdown-menu.full-width {
  padding: 0;
  margin-top: 45px;
}
.several-options .search-material-actions.dropdown .option-materials-actions.dropdown-menu.full-width ul {
  width: 100%;
  padding: 0;
  margin: 0;
  background: #eee;
  border-radius: 0;
  max-height: 40vh;
  z-index: 10;
  float: left;
  overflow-y: auto;
  overflow-x: hidden;
}
.several-options .search-material-actions.dropdown .option-materials-actions.dropdown-menu.full-width ul::-webkit-scrollbar-track {
  background-color: #dcdada;
}
.several-options .search-material-actions.dropdown .option-materials-actions.dropdown-menu.full-width ul::-webkit-scrollbar {
  width: 6px;
  background-color: #dcdada;
}
.several-options .search-material-actions.dropdown .option-materials-actions.dropdown-menu.full-width ul::-webkit-scrollbar-thumb {
  background-color: #105c9d;
}
.several-options .search-material-actions.dropdown .option-materials-actions.dropdown-menu.full-width ul li {
  text-align: left;
  cursor: pointer;
  border-bottom: solid 1px #dcdada;
  padding: 3px 20px;
  float: left;
  width: 100%;
}
.several-options .search-material-actions.dropdown .option-materials-actions.dropdown-menu.full-width ul li label {
  cursor: pointer;
  margin-bottom: 0 !important;
}
.several-options .search-material-actions.dropdown .option-materials-actions.dropdown-menu.full-width ul li label p {
  margin-bottom: 0;
}
.several-options .search-material-actions.dropdown .option-materials-actions.dropdown-menu.full-width ul li .checkbox {
  cursor: pointer;
  pointer-events: none;
  margin: 0;
}
.several-options .search-material-actions.dropdown .option-materials-actions.dropdown-menu.full-width ul li .checkbox label {
  display: flex;
  align-items: center;
  padding: 0;
}
.several-options .search-material-actions.dropdown .option-materials-actions.dropdown-menu.full-width ul li .checkbox label input[type='checkbox']:not(:checked) {
  margin: 0 20px 0 0;
  border: 1px solid #dcdada;
  outline: none;
  -webkit-appearance: inherit;
  padding: 10px;
  position: static;
  cursor: pointer;
  display: inline;
  border-radius: 3px;
  background: #fff;
}
.several-options .search-material-actions.dropdown .option-materials-actions.dropdown-menu.full-width ul li .checkbox label input[type='checkbox']:checked {
  margin: 0 20px 0 0;
  border: 1px solid #105c9d;
  outline: none;
  cursor: pointer;
  -webkit-appearance: inherit;
  position: relative;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 10px;
  display: inline;
  background: #105c9d;
  border-radius: 3px;
}
.several-options .search-material-actions.dropdown .option-materials-actions.dropdown-menu.full-width ul li .checkbox label input[type='checkbox']:checked:after {
  content: '\f00c';
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 12px;
  color: #fff;
}
.several-options .search-material-actions.dropdown .option-materials-actions.dropdown-menu.full-width ul li:hover {
  color: #105c9d;
}
.several-options .search-material-actions.dropdown .option-materials-actions.dropdown-menu.full-width ul li:focus {
  outline: none;
}
.several-options .search-material-actions.dropdown .option-materials-actions.dropdown-menu.full-width ul li.active a {
  background: #dcdada;
  color: #1e2533;
}
.several-options .multiselect-parent {
  width: 100%;
}
.several-options .multiselect-parent input {
  width: 100%;
  background: #dcdada;
  border-radius: 0;
  box-shadow: none;
  white-space: inherit;
}
.several-options .multiselect-parent input .caret {
  float: right;
  margin-top: 10px;
}
.several-options .multiselect-parent ul.dropdown-menu.dropdown-menu-form {
  width: 100%;
  padding: 0;
  margin: 0;
  background: #eee;
  border-radius: 0;
  max-height: 40vh;
  z-index: 10;
}
.several-options .multiselect-parent ul.dropdown-menu.dropdown-menu-form::-webkit-scrollbar-track {
  background-color: #dcdada;
}
.several-options .multiselect-parent ul.dropdown-menu.dropdown-menu-form::-webkit-scrollbar {
  width: 6px;
  background-color: #dcdada;
}
.several-options .multiselect-parent ul.dropdown-menu.dropdown-menu-form::-webkit-scrollbar-thumb {
  background-color: #105c9d;
}
.several-options .multiselect-parent ul.dropdown-menu.dropdown-menu-form li {
  text-align: left;
  cursor: pointer;
  border-bottom: solid 1px #dcdada;
}
.several-options .multiselect-parent ul.dropdown-menu.dropdown-menu-form li:nth-child(1),
.several-options .multiselect-parent ul.dropdown-menu.dropdown-menu-form li:nth-child(2),
.several-options .multiselect-parent ul.dropdown-menu.dropdown-menu-form li:nth-child(3) {
  display: none;
}
.several-options .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a {
  white-space: inherit;
}
.several-options .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox {
  margin: 0;
}
.several-options .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox label {
  display: flex;
  align-items: center;
  padding: 0;
}
.several-options .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox label input[type='checkbox']:not(:checked) {
  margin: 0 20px 0 0;
  border: 1px solid #dcdada;
  outline: none;
  -webkit-appearance: inherit;
  padding: 10px;
  position: static;
  cursor: pointer;
  display: inline;
  border-radius: 3px;
  background: #fff;
}
.several-options .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox label input[type='checkbox']:checked {
  margin: 0 20px 0 0;
  border: 1px solid #105c9d;
  outline: none;
  cursor: pointer;
  -webkit-appearance: inherit;
  position: relative;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 10px;
  display: inline;
  background: #105c9d;
  border-radius: 3px;
}
.several-options .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox label input[type='checkbox']:checked:after {
  content: '\f00c';
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 12px;
  color: #fff;
}
.several-options .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a:hover {
  color: #105c9d;
}
.several-options .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a:focus {
  outline: none;
}
.several-options .multiselect-parent ul.dropdown-menu.dropdown-menu-form li.active a {
  background: #dcdada;
  color: #1e2533;
}
.several-options button {
  background: #105c9d;
  color: #fff;
  border: 0;
  width: 100%;
  float: left;
  line-height: 32px;
}
.reason-exception {
  width: 100%;
  float: left;
  padding: 50px 150px 10px;
}
.reason-exception p.red-astrix-mark.textarea-bottom {
  display: inline-block;
  padding: 0;
  color: #ff0515;
  text-align: right;
  width: 100%;
  margin: -40px 0;
}
.reason-exception h4 {
  color: #1e2533;
  font-weight: 700;
  margin-bottom: 10px;
}
.reason-exception ul {
  width: 100%;
  float: left;
  margin-top: 10px;
  padding-left: 10px;
}
.reason-exception ul li {
  width: auto;
  float: left;
  padding: 2px 25px;
  border: solid 2px #105c9d;
  color: #105c9d;
  font-weight: 600;
  margin-right: 40px;
  margin-bottom: 20px;
  cursor: pointer;
}
.reason-exception ul li.active {
  background: #105c9d;
  color: #fff;
}
.actions-content li {
  font-weight: 500;
}
.build-construction {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  color: #1e2533;
  background: #eee;
  padding: 5px 10px;
  margin-top: 30px;
  margin-bottom: 20px;
  cursor: pointer;
}
.build-construction span.count-construction {
  width: 40px;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
}
.build-construction .construction-content {
  width: 100%;
  float: left;
  text-align: center;
}
.build-construction .construction-content h3 {
  font-size: 16px;
  font-weight: 700;
}
.build-construction .construction-content h4 {
  font-size: 14px;
  font-weight: 400;
  margin-top: 3px;
}
.build-construction .construction-content h4 span {
  position: relative;
  padding-right: 7px;
}
.build-construction .construction-content h4 span:after {
  position: absolute;
  right: 3px;
  top: 0;
  content: ',';
}
.build-construction .construction-content h4 span:last-child:after {
  content: '';
}
.build-construction.single-crown {
  background: #cddc39;
}
.build-construction.bridge {
  background: #e92266;
}
.build-construction.implant-crown {
  background: #e65405;
}
.build-construction.other {
  background: #461b7e;
}
.build-construction.post {
  background: #4a4a4a;
}
.build-construction.implant-bridge {
  background: #096d9f;
}
.build-construction.complete-denture {
  background: #2c3995;
}
.build-construction.partial-denture {
  background: #a6d273;
}
.build-construction.temporary-denture {
  background: #0095a8;
}
.build-construction.other-removable {
  background: #461b7e;
}
.build-construction.bitetray {
  background: #ffcb05;
}
.color-sampling-radio label.checkbox-style input[type='radio']:checked + label:before {
  background: var(--radio-color) !important;
}
.color-sampling-radio label.checkbox-style input[type='radio']:checked + label:after {
  border: 2px solid var(--radio-color) !important;
}
.multiselect-parent ul.dropdown-menu.dropdown-menu-form li a {
  width: 100%;
  float: left;
}
.multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox label {
  width: 100%;
  float: left;
  font-weight: normal;
  font-size: 14px;
  position: relative;
}
.multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox label input[type='checkbox'] {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
}
.multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox label input[type='checkbox'] + span {
  position: relative;
  padding-left: 30px;
  line-height: 23px;
  float: left;
}
.multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox label input[type='checkbox'] + span:after {
  width: 23px;
  height: 23px;
  content: '';
  border: solid 2px #4a4a4a;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  border-radius: 3px;
}
.multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox label input[type='checkbox']:checked + span:after {
  border-color: #105c9d;
}
.multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox label input[type='checkbox']:checked + span:before {
  content: '\2714';
  font-size: 14px;
  text-align: center;
  color: #fff !important;
  background: #105c9d;
  width: 23px;
  height: 23px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 3px;
  z-index: 10;
}
#order-list.order-two-part .order-list .order-list-hading i {
  margin-left: 0;
}
.ui-select-bootstrap .ui-select-choices-row.active > span {
  background: #105c9d;
}
.ui-select-container.ui-select-bootstrap.dropdown .ui-select-match {
  display: block !important;
}
.ui-select-container.ui-select-bootstrap.dropdown input.ui-select-search {
  display: none;
}
.extra-mrn-btn button {
  margin-top: 60px;
}
@media (max-width: 767px) {
  .next-step-btn {
    padding: 15px 0;
  }
  #sign-section .container {
    padding: 0;
  }
  #sign-section .container .sign-send {
    border-radius: 0;
    margin-top: 0;
    padding: 10px 0 10px;
  }
}
#order-list.order-two-part {
  min-height: inherit !important;
}
.full-div {
  width: 100% !important;
}
#order-list.order-two-part .order-list .order-list-box.first-child-bg {
  background: #bce9e9;
}
#order-list.order-two-part .order-list .order-list-box.first-child-bg .order-list-content {
  background: none;
}
#order-list.order-two-part .order-list .order-list-box .order-list-content.active-lis {
  background: #eee;
}
#images-section.order-two-part .upload-images h3.patientTitle {
  padding-left: 0;
}
#images-section .images-upload {
  width: 100%;
  margin-left: 0;
}
.manage-flex-boxes {
  /*display: flex;
  flex: 1;
  flex-wrap: wrap;*/
}
.manage-flex-boxes .color-sampling-radio h3 {
  margin-bottom: 15px;
}
.action-units-number {
  width: 100%;
  float: left;
  margin: 15px 0;
  cursor: pointer;
}
.action-units-number i {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  float: left;
  border: solid 1px #eee;
  font-size: 14px;
  background: #eee;
  color: #000;
}
.action-units-number input {
  width: 60px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  float: left;
  border: solid 1px #eee;
  border-left: 0;
  border-right: 0;
  font-size: 12px;
  background: none;
}
.action-units-number input:focus {
  outline: none;
}
.text-right.right-sec-head i {
  color: #fff;
  line-height: 28px;
  cursor: pointer;
  width: 28px;
  text-align: center;
}
#sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content input#hex {
  width: 100px;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division.inline-content {
  display: flex;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division.inline-content label {
  line-height: 23px;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .checkbox-style {
  width: 25px;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .checkbox-style label:before {
  z-index: 8;
  line-height: 25px;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division.inline-content input {
  width: 100%;
  height: 100%;
}
#bridgeTeeth.order-2-construction-section {
  position: relative;
}
.half-section-fill {
  height: 16px;
  float: left;
  position: absolute !important;
  left: 0;
  bottom: 0;
  cursor: pointer;
}
.marginBottom .half-section-fill {
  bottom: 23px;
}
#manage-labs .manage-labs #order-list.order-two-part .order-list .order-list-box .order-list-content.tag-header {
  background-color: #ccc;
  padding: 0;
  min-height: 36px;
  font-weight: 500;
  text-align: left;
}
#manage-labs .manage-labs #order-list.order-two-part .order-list .order-list-box .order-list-content {
  min-height: 36px;
  border-bottom: solid 1px #ddd;
  padding: 5px 0;
}
#manage-labs .manage-labs #order-list.order-two-part .order-list .order-list-hading {
  padding: 6px 0;
  text-align: left;
}
#customer-settings #delivery-section {
  padding-top: 122px;
}
#customer-settings #delivery-section #order-list.order-two-part .order-list .order-list-box .order-list-content .mobi-manage-content .font-manage,
#order-list .order-list.clinic-list-view .order-list-box .order-list-content .mobi-manage-content .font-manage {
  text-align: left;
  padding-top: 0;
}
#order-list .order-list.clinic-list-view .order-list-box .order-list-content {
  cursor: pointer;
  min-height: 36px;
  padding-bottom: 6px;
}
#order-list .order-list.clinic-list-view .order-list-box .order-list-content .mobi-manage-content.add-edit-css a,
#order-list .order-list .order-list-content .mobi-manage-content.add-edit-css a {
  border: solid 1px #1f2532;
  color: #1f2532;
  padding: 0 10px;
  float: right;
  margin-right: 5px;
  font-size: 13px;
}
#customer-settings #delivery-section #order-list.order-two-part .order-list .order-list-box .order-list-content .mobi-manage-content .font-manage select,
#customer-settings #delivery-section #order-list.order-two-part .order-list .order-list-box .order-list-content .mobi-manage-content .font-manage textarea,
#customer-settings #delivery-section #order-list.order-two-part .order-list .order-list-box .order-list-content .mobi-manage-content .font-manage input[type='text'] {
  width: 100%;
  border: solid 1px #ddd;
  min-height: 30px;
  line-height: 30px;
}
#customer-settings #delivery-section #order-list.order-two-part .order-list .order-list-box .order-list-content:nth-child(2n + 1) {
  background: #f4f4f4;
}
#customer-settings #delivery-section #order-list.order-two-part .order-list .order-list-box .order-list-content .mobi-manage-content .mobi-manage-content:first-child .font-manage p,
#customer-settings #delivery-section #order-list.order-two-part .order-list .order-list-box .order-list-content .mobi-manage-content .mobi-manage-content:nth-child(3) .font-manage p {
  font-size: 16px;
  font-weight: normal;
  word-break: break-word;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #customer-settings #delivery-section #order-list.order-two-part .order-list .order-list-box .order-list-content .mobi-manage-content .mobi-manage-content:first-child .font-manage p,
  #customer-settings #delivery-section #order-list.order-two-part .order-list .order-list-box .order-list-content .mobi-manage-content .mobi-manage-content:nth-child(3) .font-manage p {
    word-break: break-all;
  }
}
#customer-settings #delivery-section #order-list.order-two-part .order-list .order-list-box .order-list-content .mobi-manage-content .mobi-manage-content:nth-child(2) .font-manage p {
  font-size: 13px;
  font-weight: normal;
  word-break: break-word;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #customer-settings #delivery-section #order-list.order-two-part .order-list .order-list-box .order-list-content .mobi-manage-content .mobi-manage-content:nth-child(2) .font-manage p {
    word-break: break-all;
  }
}
#customer-settings #delivery-section #order-list.order-two-part .order-list .order-list-box .order-list-content {
  min-height: 36px;
  border-bottom: solid 1px #ddd;
  padding: 5px 0;
}
#customer-settings #delivery-section #order-list.order-two-part .order-list .order-list-hading .font-manage,
#order-list .order-list.clinic-list-view .order-list-hading .font-manage {
  text-align: left;
}
#customer-settings #delivery-section #order-list.order-two-part .order-list .order-list-box .order-list-content.tag-header {
  background-color: #ccc;
  padding: 0;
  min-height: 36px;
  font-weight: 500;
}
#customer-settings #delivery-section #order-list.order-two-part .order-list .order-list-box .order-list-content.tag-header .font-manage {
  padding-top: 5px;
}
#customer-settings #delivery-section #order-list.order-two-part .text-right button {
  width: auto;
  float: right;
  padding: 3px 50px;
  margin: 50px 5px 5px;
  border: solid 1px #1f2532;
  color: #1f2532;
  background: none;
  font-weight: 600;
}
#customer-settings #delivery-section #order-list.order-two-part .order-list .order-list-box .order-list-content .mobi-manage-content .font-manage .checkbox-style {
  width: 24px;
  height: 24px;
}
#customer-settings #delivery-section #order-list.order-two-part .order-list .order-list-box .order-list-content .mobi-manage-content .font-manage .checkbox-style input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin: 0;
}
#customer-settings #delivery-section #order-list.order-two-part .order-list .order-list-box .order-list-content .mobi-manage-content .font-manage label.checkbox-style input[type='checkbox'] + label:after,
#customer-settings #delivery-section #order-list.order-two-part .order-list .order-list-box .order-list-content .mobi-manage-content .font-manage label.checkbox-style input[type='checkbox']:checked + label:before {
  z-index: 9;
}
.display-block {
  display: block;
}
#navBar .navbar-default .navbar-nav > li > a.order2-step:hover,
#navBar .navbar-default .navbar-nav > li > a.order2-step:focus {
  border-bottom: solid 9px #1f2532;
  cursor: default;
}
.mt-53 {
  margin-top: 53px;
}
.order-two-part .bridge-suggestion {
  margin-top: 10px;
  margin-left: 0;
}
.font-weight-normal {
  font-weight: normal;
}
.manage-flex-boxes {
  margin-bottom: 40px;
}
.manage-div-smooth .construction-msg {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .files-box .file-icon svg {
  fill: #dcdada;
  width: 40px;
  margin-top: 10px;
  cursor: pointer;
  height: 40px;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .files-box .file-icon i.fa-file {
  font-size: 40px;
  margin-top: 8px;
}
.manage-steps h3 {
  margin-bottom: 20px;
}
.manage-steps .checkbox-style {
  display: flex;
}
.manage-steps .checkbox-style .datepicker {
  width: 120px;
  margin-left: auto;
  margin-right: 0;
}
.manage-steps .checkbox-style table tr {
  background: #eee;
}
.manage-steps .checkbox-style table tr:first-child,
.manage-steps .checkbox-style table tr:last-child {
  display: none;
}
.manage-steps .checkbox-style table tr .form-control {
  height: 27px;
  padding: 0;
  width: 40px;
  background: none;
  border: 0;
}
.manage-steps .checkbox-style datepicker input {
  background: #eee;
  border: solid 1px #ddd;
  width: 95%;
  height: 26px;
}
#delivery-section .deliveryDate .deliveryDateTime li {
  width: 48%;
}
#delivery-section .deliveryDate .deliveryDateTime li span.custom-time-picker {
  height: 26px;
  background: none;
  border: none;
}
#delivery-section .deliveryDate .deliveryDateTime li span.custom-time-picker input {
  outline: none;
  color: #5c5c5c;
  opacity: 1;
  font-size: 13.5px;
}
.action-on-teeth span.deliveryTime {
  margin-left: 4px;
}
#delivery-section .deliveryDate .deliveryDateTime li span.custom-time-picker input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #5c5c5c;
  opacity: 1;
  font-size: 13.5px;
}
#delivery-section .deliveryDate .deliveryDateTime li span.custom-time-picker input::-moz-placeholder {
  /* Firefox 19+ */
  color: #5c5c5c;
  opacity: 1;
  font-size: 13.5px;
}
#delivery-section .deliveryDate .deliveryDateTime li span.custom-time-picker input:-ms-input-placeholder {
  /* IE 10+ */
  color: #5c5c5c;
  opacity: 1;
  font-size: 13.5px;
}
#delivery-section .deliveryDate .deliveryDateTime li span.custom-time-picker input:-moz-placeholder {
  /* Firefox 18- */
  color: #5c5c5c;
  opacity: 1;
  font-size: 13.5px;
}
.additional-dentist {
  width: 100%;
  float: left;
  margin: 35px 0;
}
.additional-dentist .divider-new {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}
.additional-dentist .additional-dentist-details {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}
.additional-dentist .additional-dentist-details span {
  color: #000;
  font-size: 14px;
  margin-bottom: 0;
  display: block;
}
.additional-dentist .additional-dentist-details span > span {
  display: inline-block;
}
.additional-dentist .additional-dentist-details span span > span {
  color: #105c9d;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  text-decoration: underline;
}
.additional-dentist .additional-dentist-btn {
  width: 100%;
  float: left;
}
.additional-dentist .additional-dentist-btn button {
  border: solid 1px #105c9d;
  background: #fff;
  color: #105c9d;
  padding: 2px 12px;
  font-size: 16px;
}
.additional-dentist .additional-dentist-btn button:disabled {
  background: #e7e5e5;
  color: #8888886e;
  cursor: no-drop;
}
#change-dentist-modal .modal-dialog {
  background: #fff;
  overflow: hidden;
  width: 100%;
  max-width: 850px;
}
#change-dentist-modal .modal-dialog .lab-search-input {
  width: 100%;
  float: left;
  margin: 0;
  position: relative;
}
#change-dentist-modal .modal-dialog .lab-search-input i {
  position: absolute;
  left: 18px;
  top: 11px;
  color: #105c9d;
}
#change-dentist-modal .modal-dialog .lab-list {
  margin: 20px 0;
  width: 100%;
  float: left;
}
#change-dentist-modal .modal-dialog .lab-search-input input {
  width: 250px;
  float: left;
  border: solid 1px #eee;
  line-height: 40px;
  padding: 0 20px 0 45px;
  height: 40px;
}
#change-dentist-modal .modal-dialog .lab-list-heading {
  background: #105c9d;
  width: 100%;
  float: left;
  color: #fff;
  font-size: 16px;
  margin: 0;
  padding: 6px 0;
  font-weight: bold;
}
#change-dentist-modal .modal-header {
  border-bottom: none;
}
#change-dentist-modal .modal-footer {
  text-align: center;
  border-top: none;
}
#change-dentist-modal .modal-footer button {
  color: #105c9d;
  background-color: #fff;
  border-color: #105c9d;
  border-radius: 0;
  padding: 6px 30px;
}
#change-dentist-modal .modal-footer button:hover {
  color: #105c9d;
  background-color: #fff;
  border-color: #105c9d;
}
#change-dentist-modal .lab-list-box {
  height: 350px;
  overflow: auto;
  padding: 6px 0;
}
#change-dentist-modal .mobi-manage-content {
  min-height: 27px;
  border-bottom: solid 1px #eee;
  cursor: pointer;
}
#change-dentist-modal .selected-additional-dentist {
  width: 100%;
  float: left;
  background: #eee;
}
#change-dentist-modal .mobi-manage-content > div {
  min-height: 33px;
  color: #000;
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 500;
  white-space: nowrap;
  padding: 5px 0;
}
#change-dentist-modal .lab-list-box::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}
/* Track */
#change-dentist-modal .lab-list-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
#change-dentist-modal .lab-list-box::-webkit-scrollbar-thumb {
  background: #105c9d;
}
#sign-section .sign-send .confirm-info-sign-send .teeth-construction-action .action-on-teeth p.comment-p.additional-information label {
  width: auto;
  float: left;
  min-width: 17.5%;
}
#sign-section .sign-send .confirm-info-sign-send .teeth-construction-action .action-on-teeth p.comment-p.additional-information span {
  float: left;
  width: 80%;
  margin-left: 2%;
}
#sign-section.order-details-2 .sign-send .sign-send .sample-span-ui p.comment-p.additional-information span.remove-m-w {
  width: auto;
  margin-left: 1%;
  white-space: nowrap;
  font-size: 13px;
  padding-right: 0;
}
#sign-section.order-details-2 .sign-send .sign-send .sample-span-ui p.comment-p.additional-information.sample-content-comma span:after {
  right: -4px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.status-view-row {
  height: 20px;
}
#change-dentist-modal .pagination,
#change-dentist-modal .pagination ul {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
  display: inline-block;
  text-align: center;
}
#change-dentist-modal .pagination ul {
  width: auto;
  float: none;
}
#change-dentist-modal .pagination ul li {
  width: auto;
  height: 30px;
  float: left;
  text-align: center;
  line-height: 30px;
  margin: 1px;
  border-radius: 3px;
  cursor: pointer;
}
#change-dentist-modal .pagination ul li i {
  line-height: 29px;
}
#change-dentist-modal .pagination ul li.active-tab {
  color: #6dd2d3;
  border: solid 1px #6dd2d3;
  font-weight: bold;
}
#order-list.invoice-table .order-list .order-list-hading {
  font-size: 13px;
  line-height: 16px;
}
#order-list.invoice-table .order-list {
  font-size: 12px;
}
#order-list.invoice-table .order-list .order-list-box p {
  font-size: 12px;
  font-weight: normal;
  padding: 10px 0;
}
.status-filter.invoice-filter-part .several-options input {
  padding-left: 15px;
}
.user-message {
  padding: 4px 0;
  font-weight: bold;
}
#manage-labs .manage-scroll-mobile .repeat-lis {
  float: left;
  width: 100%;
  border-bottom: solid 2px #ccc;
  cursor: pointer;
  color: #000;
  padding-top: 5px;
}
#manage-labs .manage-scroll-mobile .repeat-lis .text-left label {
  font-weight: normal;
}
#manage-labs .manage-scroll-mobile .last-price-section {
  text-align: center;
  width: 100%;
  min-height: 27px;
}
#manage-labs .manage-scroll-mobile .change-head-bg .head-table {
  background: #eeeeee;
  width: 100%;
  float: left;
  padding: 5px 0 0;
  color: #000;
}
h3.material-heading {
  color: #1bd3c8;
  font-weight: normal;
  margin: 25px 0 15px;
}
.sample-taken-options label.checkbox-style label {
  cursor: pointer;
  z-index: 12;
}
label.checkbox-style.sample-taken-radio input[type='radio'] + label:after {
  border-radius: 50%;
}
.sample-taken-options {
  float: left;
  width: 100%;
  display: inline-flex;
}
.sample-taken-options .sample-taken-option {
  margin-right: 36px;
  z-index: 8;
}
.sample-content {
  width: 100%;
  float: left;
  padding: 20px;
}
.sample-content ul {
  width: 100%;
  float: left;
  margin-top: 10px;
  padding-left: 10px;
}
.sample-content ul li {
  width: auto;
  float: left;
  padding: 0 14px;
  border: solid 2px #105c9d;
  color: #105c9d;
  font-weight: 600;
  margin-right: 16px;
  margin-bottom: 20px;
  cursor: pointer;
}
.sample-content ul li.active {
  background: #105c9d;
  color: #fff;
}
.sample-content-comma span {
  position: relative;
  padding-right: 10px;
}
.sample-content-comma span:after {
  position: absolute;
  right: 5px;
  bottom: 0;
  content: ',';
}
.sample-content-comma span:last-child:after {
  content: '';
}
.sample-all-comments .additional-information label {
  width: 20% !important;
  min-width: 20% !important;
}
.sample-all-comments .additional-information .rightPanel {
  float: left;
  width: 79%;
}
.sample-all-comments .additional-information .rightPanel > span {
  margin-bottom: 15px;
  width: 100% !important;
  display: inline-block;
}
.patientExistingorder {
  display: inline-block;
  width: 100%;
}
.patientExistingorder > p {
  font-size: 14px;
  color: #105c9d;
  padding: 40px 0 0px;
  font-weight: bold;
  margin-bottom: 0px;
}
.patientExistingorder .checkBox label.checkbox-style {
  font-weight: normal;
  font-size: 14px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: auto;
  float: none;
  padding-left: 0;
}
.patientExistingorder .checkBox span {
  padding-left: 10px;
  padding-top: 3px;
  display: inline-block;
  vertical-align: middle;
  font-weight: 600;
  font-size: 14px;
}
#patient-section .SearchPatient .patientExistingorder .col-lg-2 {
  width: 16% !important;
  min-height: auto;
  padding-right: 0;
  text-align: center;
}
#patient-section .SearchPatient .patientExistingorder .order-list-hading .col-lg-2:last-child {
  width: 18% !important;
}
#patient-section .SearchPatient .patientExistingorder .order-list-hading {
  border-bottom: 3px solid #105c9d;
  overflow: hidden;
  margin-bottom: 0;
}
#patient-section .SearchPatient .patientExistingorder .order-list-hading:not(:first-child) .mobi-manage-content {
  text-align: center;
}
#patient-section .SearchPatient .patientExistingorder .order-list-hading .col-lg-2 {
  padding: 10px 0 0;
  margin-bottom: 0;
  border-bottom: 0;
  font-weight: bold;
}
#patient-section .SearchPatient .patientExistingorder .mobi-manage-content:last-child .col-lg-2 {
  width: 18% !important;
}
#patient-section .SearchPatient .patientExistingorder .col-lg-12 p {
  line-height: normal;
  margin-bottom: 0;
  font-size: 12px;
  color: #000;
}
#patient-section .SearchPatient .patientExistingorder .col-lg-12.order-list-box {
  border-bottom: 2px solid #bdbdbd;
  padding-bottom: 8px !important;
  margin-bottom: 0 !important;
  cursor: pointer;
  padding-top: 8px !important;
}
#patient-section .SearchPatient .patientExistingorder .col-lg-12.order-list-box:nth-last-child(2) {
  border-bottom: 0;
}
#patient-section .SearchPatient .patientExistingorder .col-lg-12.order-list-box:hover {
  background: #dcdada;
}
#patient-section .SearchPatient .patientExistingorder .order-list-hading .col-lg-2:first-child {
  text-align: left;
}
#patient-section .SearchPatient .patientExistingorder .mobi-manage-content:first-child .col-lg-2 {
  text-align: left !important;
}
.DeliveryInformation .contentWrapper {
  margin: 10px 0 0 15px;
}
.constallCommenwrapper {
  display: table;
  width: 100%;
}
.constallCommenwrapper .constallComment {
  display: table-cell;
  float: none !important;
  width: 50%;
}
.constallCommenwrapper .constallComment .checkbox-style.full-div {
  margin-top: 30px;
}
#sign-section .sign-send .confirm-info-sign-send .teeth-construction-action .action-on-teeth p.comment-p.exception-reason span {
  width: auto;
  margin-left: 0;
}
#sign-section .sign-send .confirm-info-sign-send .teeth-construction-action .action-on-teeth p.comment-p.exception-reason span:nth-child(2) {
  margin-left: 2%;
}
.mr-12 {
  margin-right: 12px !important;
}
.h-32 {
  height: 32px !important;
}
#patient-section.order-two-part .SearchPatient .pagination ul {
  display: flex;
  justify-content: center;
}
#patient-section.order-two-part .SearchPatient .pagination ul li {
  width: auto;
  margin-right: 4px;
}
#patient-section.order-two-part .SearchPatient .pagination ul li.active a {
  background-color: #105c9d;
  border-color: #105c9d;
}
.info-area {
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  border-radius: 4px;
  background-color: #e5f6fd;
  display: flex;
  padding: 6px 16px;
  color: #014361;
  float: left;
  align-items: center;
  width: 100%;
}
.info-area .mat-icon {
  margin-right: 5px;
}
#sign-section.order-details-2 .sign-send {
  padding: 0;
  background: none;
}
#sign-section.order-details-2 .sign-send .sign-send {
  background: #fff;
  padding: 18px 15px 50px;
}
#sign-section.order-details-2 .sign-send .sign-send .files-box img {
  max-width: 100%;
  display: inline-block;
  float: none;
}
#sign-section.order-details-2 .sign-send .sign-send .files-box a {
  display: block;
  border: 0;
  text-align: center;
}
#sign-section.order-details-2 .sign-send .sign-send .files-box a .file-icon i {
  color: #105c9d;
}
#sign-section.order-details-2 .sign-send .sign-send .files-box h3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
  overflow: hidden;
}
#sign-section.order-details-2 .sign-send .sign-send #images-section .images-upload {
  margin-top: 0;
  padding: 10px 0 15px;
  line-height: 180px;
  font-weight: 600;
}
#sign-section.order-details-2 .sign-send .sign-send #images-section .images-upload img {
  width: 200px;
  float: left;
}
#sign-section.order-details-2 .sign-send .sign-send #imgModal .carousel a {
  height: 100vh;
}
#sign-section.order-details-2 .sign-send .sign-send #imgModal .carousel a span.glyphicon {
  left: 50%;
  top: 45%;
  font-size: 30px;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send p {
  font-size: 14px;
  display: flex;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send p i.fa-info,
#sign-section.order-details-2 .sign-send .confirm-info-sign-send p i.fa-phone {
  min-width: 18px;
  height: 18px;
  text-align: center;
  line-height: 16px;
  border: solid 2px #105c9d;
  border-radius: 50%;
  margin-right: 5px;
  color: #105c9d;
  cursor: pointer;
  margin-top: 0;
  margin-left: -27px;
  font-size: 11px;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send p span.invoice-inputs {
  display: flex;
  justify-content: space-between;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send p span.invoice-inputs input {
  width: 100px;
  float: left;
  font-size: 12px;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send p span.invoice-inputs button {
  width: 65px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: solid 1px #105c9d;
  line-height: 24px;
  color: #105c9d;
  cursor: pointer;
  padding: 0 8px;
  text-align: center;
  margin-left: 5px;
  background: #fff;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send p span.invoice-inputs button:hover {
  background: #105c9d;
  color: #fff;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send p span.invoice-inputs button:disabled {
  cursor: not-allowed;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send a.add-anchor-btn {
  width: 80px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: solid 1px #105c9d;
  line-height: 24px;
  color: #105c9d;
  cursor: pointer;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send h4 i {
  float: right;
  color: #105c9d;
  cursor: pointer;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send h4.show-teeth-construction i {
  transform: rotate(-90deg);
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions {
  width: 100%;
  float: left;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-btn {
  width: 100%;
  float: left;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-btn button {
  width: auto;
  float: left;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: solid 1px #105c9d;
  line-height: 24px;
  color: #105c9d;
  cursor: pointer;
  padding: 5px 30px;
  background: none;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-btn button i {
  margin-right: 15px;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-btn.hide-this-part {
  display: none;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section {
  width: 100%;
  float: left;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions {
  padding: 10px 30px;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions h5 {
  color: #000;
  font-weight: 500;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .material-action-box {
  width: 100%;
  float: left;
  display: flex;
  margin-top: 15px;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .material-action-box > span {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .material-action-box .search-material-actions {
  width: 100%;
  float: left;
  margin-left: 15px;
  position: relative;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .material-action-box .search-material-actions i.fa-search {
  position: absolute;
  top: 5px;
  left: 15px;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .material-action-box .search-material-actions i.fa-caret-down {
  position: absolute;
  top: 5px;
  right: 15px;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .material-action-box .search-material-actions input {
  width: 100%;
  float: left;
  padding: 0 50px;
  border: solid 1px #dcdada;
  background: #eee;
  height: 28px;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .material-action-box .search-material-actions .option-materials-actions {
  width: 100%;
  float: left;
  position: absolute;
  top: 28px;
  left: 0;
  box-shadow: 3px 3px 5px #dcdada;
  background: #f4f4f4;
  z-index: 9;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .material-action-box .search-material-actions .option-materials-actions .material {
  width: 100%;
  float: left;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .material-action-box .search-material-actions .option-materials-actions .material h5 {
  width: 100%;
  float: left;
  padding: 5px 25px;
  background: #dcdada;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .material-action-box .search-material-actions .option-materials-actions .material ul {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .material-action-box .search-material-actions .option-materials-actions .material ul li {
  width: 100%;
  float: left;
  padding: 4px 15px;
  border-bottom: solid 1px #dcdada;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .material-action-box .search-material-actions .option-materials-actions .material ul li label.checkbox-style {
  height: 16px;
  line-height: 16px;
  margin-bottom: 0;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .material-action-box .search-material-actions .option-materials-actions .material ul li label.checkbox-style input[type='checkbox'] + label {
  margin-left: 15px;
  line-height: 16px;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .material-action-box .search-material-actions .option-materials-actions .material ul li label.checkbox-style input[type='checkbox'] + label:after {
  width: 16px;
  height: 16px;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .material-action-box .search-material-actions .option-materials-actions .material ul li label.checkbox-style input[type='checkbox']:checked + label:after {
  border-color: #105c9d;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .material-action-box .search-material-actions .option-materials-actions .material ul li label.checkbox-style input[type='checkbox']:checked + label:before {
  width: 16px;
  height: 16px;
  font-size: 12px;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .material-action-box .search-material-actions .option-materials-actions .edit-save-btn {
  padding: 4px 15px 4px 0;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .material-action-box .search-material-actions .option-materials-actions .edit-save-btn button {
  padding: 2px 30px;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .order-list-hading {
  font-weight: 500;
  color: #aaa;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 12px;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .order-list-box {
  width: 100%;
  float: left;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .order-list-box .order-list-content {
  width: 100%;
  float: left;
  display: flex;
  align-items: flex-end;
  margin: 2px 0;
  position: relative;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .order-list-box .order-list-content .font-manage h5,
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .order-list-box .order-list-content .font-manage span,
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .order-list-box .order-list-content .font-manage input {
  width: 100%;
  float: left;
  font-size: 12px;
  margin-bottom: 15px;
  color: #000;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .order-list-box .order-list-content .font-manage:nth-child(2),
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .order-list-box .order-list-content .font-manage:nth-child(3),
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .order-list-box .order-list-content .font-manage:nth-child(4),
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .order-list-box .order-list-content .font-manage:nth-child(5) {
  text-align: center;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .order-list-box .order-list-content div:last-child {
  position: static;
  padding-left: 44px;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .order-list-box .order-list-content div i.fa-trash {
  position: absolute;
  top: 3px;
  right: 50px;
  font-size: 24px;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .edit-save-btn {
  width: 100%;
  float: left;
  padding-right: 110px;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .edit-save-btn button {
  width: auto;
  float: right;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: solid 1px #105c9d;
  line-height: 24px;
  color: #105c9d;
  cursor: pointer;
  padding: 2px 50px;
  background: none;
  font-weight: 600;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .teeth-construction-action .add-materias-actions .add-materias-actions-section .added-materials-actions .edit-save-btn button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .files-box .file-icon i {
  color: #dcdada;
  cursor: pointer;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .files-box h3 {
  text-align: center;
  font-weight: 600;
  font-size: 13px;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .flag-icon {
  font-size: 25px;
  color: #9f9d9d;
  cursor: pointer;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .flag-icon.flag-color-red {
  color: #ff0000;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .green-truck,
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .red-truck {
  color: #8dc44d;
  cursor: pointer;
  font-size: 30px;
  transform: scaleX(-1);
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .red-truck {
  color: #ff0415;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .green-truck.dentist-truck,
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .red-truck.dentist-truck {
  cursor: default;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send .digital-sample {
  height: 25px;
}
#sign-section.order-details-2 .sign-send #dentist-order-detail {
  margin-top: 30px;
}
#contact-info-modal .modal-dialog,
#price-specification-modal .modal-dialog,
#rate-modal .modal-dialog,
#approve-modal .modal-dialog,
#user-info-modal .modal-dialog,
#dentist-info-modal .modal-dialog {
  width: 420px;
}
#contact-info-modal .modal-dialog .modal-content,
#price-specification-modal .modal-dialog .modal-content,
#rate-modal .modal-dialog .modal-content,
#approve-modal .modal-dialog .modal-content,
#user-info-modal .modal-dialog .modal-content,
#dentist-info-modal .modal-dialog .modal-content {
  padding: 20px 40px 0 40px;
  border-radius: 0;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
#contact-info-modal .modal-dialog .modal-content .modal-header,
#price-specification-modal .modal-dialog .modal-content .modal-header,
#rate-modal .modal-dialog .modal-content .modal-header,
#approve-modal .modal-dialog .modal-content .modal-header,
#user-info-modal .modal-dialog .modal-content .modal-header,
#dentist-info-modal .modal-dialog .modal-content .modal-header {
  border: 0;
  padding: 0;
}
#contact-info-modal .modal-dialog .modal-content .modal-header .close,
#price-specification-modal .modal-dialog .modal-content .modal-header .close,
#rate-modal .modal-dialog .modal-content .modal-header .close,
#approve-modal .modal-dialog .modal-content .modal-header .close,
#user-info-modal .modal-dialog .modal-content .modal-header .close,
#dentist-info-modal .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  font-size: 30px;
  margin: -15px -25px;
}
#contact-info-modal .modal-dialog .modal-content .modal-header .close:focus,
#price-specification-modal .modal-dialog .modal-content .modal-header .close:focus,
#rate-modal .modal-dialog .modal-content .modal-header .close:focus,
#approve-modal .modal-dialog .modal-content .modal-header .close:focus,
#user-info-modal .modal-dialog .modal-content .modal-header .close:focus,
#dentist-info-modal .modal-dialog .modal-content .modal-header .close:focus {
  outline: none;
}
#contact-info-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#price-specification-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#rate-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#approve-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#user-info-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#dentist-info-modal .modal-dialog .modal-content .modal-header h4.modal-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #105c9d;
  margin-bottom: 0;
}
#contact-info-modal .modal-dialog .modal-content .modal-body,
#price-specification-modal .modal-dialog .modal-content .modal-body,
#rate-modal .modal-dialog .modal-content .modal-body,
#approve-modal .modal-dialog .modal-content .modal-body,
#user-info-modal .modal-dialog .modal-content .modal-body,
#dentist-info-modal .modal-dialog .modal-content .modal-body {
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
}
#contact-info-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#price-specification-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#rate-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#approve-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#user-info-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#dentist-info-modal .modal-dialog .modal-content .modal-body .add-clinic-form {
  width: 100%;
  float: left;
  margin-top: 0;
}
#contact-info-modal .modal-dialog .modal-content .modal-body .add-clinic-form h4,
#price-specification-modal .modal-dialog .modal-content .modal-body .add-clinic-form h4,
#rate-modal .modal-dialog .modal-content .modal-body .add-clinic-form h4,
#approve-modal .modal-dialog .modal-content .modal-body .add-clinic-form h4,
#user-info-modal .modal-dialog .modal-content .modal-body .add-clinic-form h4,
#dentist-info-modal .modal-dialog .modal-content .modal-body .add-clinic-form h4 {
  text-decoration: underline;
  margin-bottom: 15px;
}
#contact-info-modal .modal-dialog .modal-content .modal-body .add-clinic-form p,
#price-specification-modal .modal-dialog .modal-content .modal-body .add-clinic-form p,
#rate-modal .modal-dialog .modal-content .modal-body .add-clinic-form p,
#approve-modal .modal-dialog .modal-content .modal-body .add-clinic-form p,
#user-info-modal .modal-dialog .modal-content .modal-body .add-clinic-form p,
#dentist-info-modal .modal-dialog .modal-content .modal-body .add-clinic-form p {
  text-align: left;
  margin: 0;
  width: 100%;
  float: left;
  line-height: 26px;
}
#contact-info-modal .modal-dialog .modal-content .modal-footer,
#price-specification-modal .modal-dialog .modal-content .modal-footer,
#rate-modal .modal-dialog .modal-content .modal-footer,
#approve-modal .modal-dialog .modal-content .modal-footer,
#user-info-modal .modal-dialog .modal-content .modal-footer,
#dentist-info-modal .modal-dialog .modal-content .modal-footer {
  border: 0;
  margin: 30px 0 20px;
}
#contact-info-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
#price-specification-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
#rate-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
#approve-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
#user-info-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
#dentist-info-modal .modal-dialog .modal-content .modal-footer .btn.btn-default {
  border-radius: 0;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 10px 10px;
  color: #105c9d;
}
#contact-info-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#price-specification-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#rate-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#approve-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#user-info-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#dentist-info-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#contact-info-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#price-specification-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#rate-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#approve-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#user-info-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#dentist-info-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#contact-info-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close,
#price-specification-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close,
#rate-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close,
#approve-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close,
#user-info-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close,
#dentist-info-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close {
  background: #105c9d;
  color: #fff;
}
#price-specification-modal .modal-dialog {
  width: 900px;
}
#price-specification-modal .modal-dialog #order-list {
  min-height: inherit;
}
#price-specification-modal .modal-dialog #order-list .order-list {
  margin-top: 0;
  border: 0;
  box-shadow: none;
}
#price-specification-modal .modal-dialog #order-list .order-list .order-list-hading {
  padding: 10px 0;
}
#price-specification-modal .modal-dialog #order-list .order-list-content {
  min-height: inherit;
}
#price-specification-modal .modal-dialog #order-list .order-list-content.order-list-bg {
  padding: 5px 0;
  background: none;
  border-bottom: solid 1px #dcdada;
}
#price-specification-modal .modal-dialog #order-list .order-list-content.order-list-bg .mobi-manage-content:first-child p {
  text-align: left;
}
#price-specification-modal .modal-dialog #order-list .order-list-content.order-list-bg p {
  font-weight: normal;
  color: #a5a3a3;
}
#rate-modal .modal-dialog,
#approve-modal .modal-dialog {
  width: 700px;
}
#rate-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#approve-modal .modal-dialog .modal-content .modal-header h4.modal-title {
  text-align: left;
}
#rate-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block h5,
#approve-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block h5 {
  width: 100%;
  float: left;
}
#rate-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .choose-rate-option,
#approve-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .choose-rate-option {
  width: 100%;
  float: left;
  margin-top: 20px;
}
#rate-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .choose-rate-option li,
#approve-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .choose-rate-option li {
  width: auto;
  float: left;
  cursor: pointer;
  border-radius: 0;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 5px 25px 4px;
  margin: 0 30px 30px 0;
  color: #105c9d;
}
#rate-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .choose-rate-option li.active-tab-close,
#approve-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .choose-rate-option li.active-tab-close {
  background: #105c9d;
  color: #fff;
}
#rate-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .not-satisfied-check,
#approve-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .not-satisfied-check {
  width: 100%;
  float: left;
}
#rate-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .not-satisfied-check .choose-rate-option li,
#approve-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .not-satisfied-check .choose-rate-option li {
  margin: 0 15px 15px 0;
}
#rate-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .not-satisfied-check span,
#approve-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .not-satisfied-check span {
  font-size: 14px;
}
#rate-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .not-satisfied-check textarea,
#approve-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .not-satisfied-check textarea {
  width: 100%;
  float: left;
  margin-top: 10px;
  padding: 15px;
  border: solid 1px #dcdada;
}
@media (max-width: 767px) {
  #price-specification-modal .modal-dialog {
    width: 100%;
  }
  #price-specification-modal .modal-dialog .modal-content {
    padding: 0;
  }
  #price-specification-modal .modal-dialog .modal-content .modal-header .close {
    margin: 0px 5px;
  }
  #price-specification-modal .modal-dialog #order-list .order-list .order-list-hading {
    padding: 0;
  }
}
.border-0 {
  border: 0;
}
#OrderDetail2PrintableArea #images-section {
  padding-top: 0;
}
.red-color {
  color: #ff0515 !important;
}
.red-del {
  text-decoration: line-through;
  color: #ff0515 !important;
}
.mt-53.mb-30 {
  width: 100%;
  float: left;
  margin-bottom: -30px;
  position: relative;
  z-index: 1;
}
.mt-53.mb-30 select {
  min-height: 32px;
  border: solid 1px #eee;
  font-size: 14px;
}
span.version-msg {
  padding: 0 !important;
  margin: 0 5px !important;
}
span.version-msg {
  padding: 0 !important;
  margin: 0 5px !important;
}
.custom-time-picker {
  display: inline-flex;
  width: 100px;
  float: left;
  background: #eee;
  border: solid 1px #ddd;
  height: 24px;
}
.custom-time-picker input {
  width: 100%;
  height: 24px;
  text-align: center;
  background: none;
  border: 0;
}
.custom-time-picker input:disabled {
  cursor: not-allowed;
}
.exception-check input {
  z-index: 11 !important;
  cursor: pointer;
}
.exception-check {
  width: auto !important;
}
#sign-section.order-details-2 .sign-send .sign-send p.comment-p.additional-information label {
  width: auto;
  float: left;
  min-width: 17.5%;
}
#sign-section.order-details-2 .sign-send .sign-send p.comment-p.additional-information span {
  float: left;
  width: 80%;
  margin-left: 1%;
}
#sign-section.order-details-2 .sign-send .sign-send p.comment-p.additional-information span.remove-m-w {
  width: auto;
  margin-left: 0;
}
.archive-check input {
  z-index: 11 !important;
  cursor: pointer;
}
.archive-check {
  width: auto !important;
}
.commet-export-production input[type='checkbox'] {
  cursor: pointer;
}
.chat-option-box .chat-area {
  border: 0;
  width: 100%;
  resize: none;
  padding: 18px;
  margin: -15px 0;
}
.approve-modal-add-msg-section {
  margin-bottom: 30px;
  width: 100%;
  float: left;
}
.approve-modal-add-msg-section p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
}
.approve-modal-add-msg-section label.checkbox-style.mt-20.archive-check {
  margin-top: 30px;
}
#approve-modal label.checkbox-style input[type='checkbox'] + label:after {
  border-color: #105c9d;
}
#approve-modal label.checkbox-style input[type='checkbox'] {
  width: 100%;
  height: 100%;
}
.printHidden {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
}
.pdf-btn {
  border: none;
  background: white;
}
.pdf-btn:disabled i {
  cursor: not-allowed !important;
}
.print-button-wrapper {
  padding: 5;
}
.additional-information .rightPanel > span {
  margin-bottom: 15px;
  width: 100% !important;
  display: inline-block;
}
.additional-information .rightPanel > del {
  margin-bottom: 15px;
  width: 100% !important;
  display: inline-block;
}
.invoice-pdf-download {
  width: 27px;
  margin-left: 10px;
  margin-top: -3px;
  cursor: pointer;
}
.text-align-left {
  text-align: left;
  color: #000;
}
.flex-start {
  align-items: flex-start !important;
}
.flex-start:hover {
  cursor: pointer;
}
.flex-start span {
  font-weight: 600;
}
.order-material {
  width: 100%;
  float: left;
}
#sign-section .sign-send .confirm-info-sign-send .teeth-construction-action .action-on-teeth .add-info p.comment-p.additional-information span {
  margin-left: 0;
}
#sign-section .sign-send .confirm-info-sign-send .teeth-construction-action .action-on-teeth .add-info p.comment-p.additional-information label {
  margin-right: 10px;
  width: 17.5%;
}
.action-display-block {
  display: block !important;
}
.padding-left-change {
  padding-left: 15px !important;
}
.order-transport-status b {
  right: 36px;
  top: 6px;
  position: absolute;
  font-size: 13px;
}
#certify-modal .modal-dialog,
#approve-modal .modal-dialog {
  width: 900px !important;
}
#certify-modal .modal-dialog #order-list,
#approve-modal .modal-dialog #order-list {
  min-height: 0;
}
#certify-modal .modal-dialog #order-list .order-list,
#approve-modal .modal-dialog #order-list .order-list {
  margin-top: 0;
}
#certify-modal .modal-dialog #order-list .order-list-content div:nth-child(1) p,
#approve-modal .modal-dialog #order-list .order-list-content div:nth-child(1) p {
  text-align: left;
}
#certify-modal .modal-dialog #order-list .order-list-content.order-list-bg,
#approve-modal .modal-dialog #order-list .order-list-content.order-list-bg {
  padding: 5px 0;
  background: none;
  min-height: inherit;
  border-bottom: solid 1px #dcdada;
}
.details-page-label-black label {
  color: #000 !important;
}
.table-formate {
  width: 100%;
  text-align: left;
}
.table-formate tr th {
  background: #105c9d;
  color: #fff;
  padding: 14px 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.table-formate tr th:first-child {
  text-align: left;
}
.table-formate tr td {
  font-weight: normal;
  color: #a5a3a3;
  padding: 4px 10px;
  font-size: 13px;
  text-align: center;
  border-bottom: solid 1px #dcdada;
}
.table-formate tr td:first-child {
  text-align: left;
}
.downloading-progress-nav {
  position: fixed;
  top: 117px;
  left: 50%;
  background: #105c9d;
  color: #fff;
  padding: 5px 10px;
  transform: translateX(-50%);
}
.pd-0 {
  padding: 0 !important;
}
.version-files {
  text-align: left !important;
  width: auto !important;
}
.version-files h3,
.version-files a {
  text-align: left !important;
}
.remove-height #order-list {
  min-height: inherit;
}
#sign-section.order-details-2 .sign-send .confirm-info-sign-send p label + label.checkbox-style.do-not-invoice-label {
  min-width: inherit !important;
  width: auto !important;
}
.disable-box {
  opacity: 0.5;
}
.download-section {
  width: calc(100% - 10px);
  float: left;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  background: #fff;
  margin: 5px;
  border: 1px #ccc solid;
}
.download-section button {
  color: #105c9d;
}
.download-section button span {
  font-weight: 500;
}
.production-finished .red-astrix-mark {
  color: #ff0515;
}
.invoicing-module {
  display: flex;
  flex-direction: column;
}
.invoicing-module label {
  width: 100% !important;
}
.edit-delete-material {
  display: flex;
  padding: 0 !important;
}
.edit-delete-material .fa-trash {
  position: static !important;
  margin-left: 18px;
  margin-top: -4px;
}
.warranty-type {
  color: #ff6c03;
  font-weight: 600;
}
.main-container {
  width: 100%;
  float: left;
  margin-top: 15px;
  margin-left: 15px;
  padding-right: 30px;
}
.main-container h5 {
  font-weight: bold;
  font-size: 16px;
}
.main-container span {
  font-size: 14px;
  padding-top: 4px;
}
.main-container p {
  padding: 0 !important;
  text-align: justify;
}
.main-container .inner-section {
  padding-left: 30px;
  width: 100%;
  float: left;
  margin-top: 15px;
  padding-right: 30px;
}
.dentist-note span {
  text-align: justify;
}
.dentist-note span:first-child {
  padding-right: 3px;
  font-weight: bold;
}
#order-confirm {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
#order-confirm .order-confirm {
  width: 100%;
  float: left;
  background: #fff;
  border-radius: 4px;
  margin-top: 150px;
  padding: 20px;
  text-align: center;
}
#order-confirm .order-confirm h1 {
  font-size: 38px;
  text-align: center;
  color: #1f2532;
  margin-top: 37px;
  font-weight: bold;
}
#order-confirm .order-confirm .order-details {
  width: 100%;
  max-width: 800px;
  background: #f5f5f5;
  margin: auto;
  padding: 20px;
  text-align: center;
  display: inline-block;
  margin-top: 36px;
}
#order-confirm .order-confirm .order-details span {
  float: left;
  width: 100%;
  font-size: 25px;
  margin-top: 10px;
}
#order-confirm .order-confirm .order-button {
  width: 100%;
  float: left;
  text-align: center;
  margin-top: 37px;
}
#order-confirm .order-confirm .order-button a {
  font-size: 28px;
  color: #dcdada;
  padding: 20px 40px 20px 40px;
  text-transform: uppercase;
  text-decoration: none;
}
a#order-confirm .order-confirm .order-button:hover {
  text-decoration: none;
}
#order-confirm .order-confirm .right-detail-part.order-created {
  width: 300px !important;
  float: none;
  margin: 30px auto 0;
}
#order-confirm .order-confirm .right-detail-part.order-created span {
  width: 70px;
  margin: 0 35px;
  float: right;
  text-align: center;
  cursor: pointer;
}
#order-confirm .order-confirm .right-detail-part.order-created span svg {
  height: 65px;
}
#order-confirm .order-confirm .right-detail-part.order-created span p {
  font-weight: bold;
  font-size: 20px;
  color: #bbb;
  line-height: 17px;
}
#order-confirm .order-confirm .right-detail-part.order-created span i {
  font-size: 65px;
  color: #ccc;
}
#order-confirm .order-confirm .right-detail-part.order-created button {
  margin: 0 35px;
  text-align: center;
  cursor: pointer;
  background: none;
  border: 0;
}
#order-confirm .order-confirm .right-detail-part.order-created button svg {
  height: 65px;
}
#order-confirm .order-confirm .right-detail-part.order-created button p {
  font-weight: bold;
  font-size: 20px;
  color: #bbb;
  line-height: 17px;
}
#order-confirm .order-confirm .right-detail-part.order-created button i {
  font-size: 65px;
  color: #ccc;
}
#order-confirm .order-confirm .right-detail-part.order-created button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
#order-confirm .order-confirm .right-detail-part.order-created.order-action-btn {
  width: 350px !important;
}
#order-confirm .order-confirm .right-detail-part.order-created.order-action-btn button p {
  margin-top: 10px;
}
#order-confirm .order-confirm .order-message {
  float: left;
  text-align: center;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}
#order-confirm .order-confirm .order-message p {
  width: 50%;
  margin: auto;
  text-align: center;
  font-size: 20px;
  color: #a7a7a7;
  font-weight: 300;
}
#order-confirm .order-confirm .next-step-btn.home-page-btn {
  display: inline-block;
  text-align: center;
}
#order-confirm .order-confirm .next-step-btn.home-page-btn .step-button {
  width: 220px;
  float: none;
  display: inline-block;
}
#order-list {
  width: 100%;
  float: left;
  margin-bottom: 20px;
  min-height: 97vh;
}
#order-list .order-list-content {
  padding: 10px 0;
  float: left;
  width: 100%;
  min-height: 64px;
  cursor: pointer;
}
#order-list .order-list-content.user-list-content {
  min-height: 35px;
  padding: 6px 0 0;
  cursor: default;
}
#order-list .order-list {
  float: left;
  width: 100%;
  background: #fff;
  margin-top: 150px;
  border: solid 1px #d0d0d0;
  box-shadow: 0 0 3px #ebebeb;
  text-align: center;
}
#order-list .order-list.user-dentdata {
  text-align: left;
}
#order-list .order-list.user-dentdata .order-list-content .mobi-manage-content:first-child .font-manage {
  padding-left: 15px;
}
#order-list .order-list .no-padding-default {
  padding: 0;
}
#order-list .order-list .order-list-hading {
  background: #105c9d;
  float: left;
  width: 100%;
  color: #fff;
  padding: 14px 0px;
  font-size: 18px;
  font-weight: bold;
}
#order-list .order-list .order-list-hading i {
  margin-left: 10px;
  /* &:last-child {
          position: absolute;
          top: 17px;
          right: 66px;
        }*/
}
#order-list .order-list .order-list-hading .col-lg-2 {
  width: 14.25%;
}
#order-list .order-list .order-list-hading .col-md-2 {
  width: 14.25%;
}
#order-list .order-list .order-list-hading .col-sm-2 {
  width: 14.25%;
}
#order-list .order-list .order-list-box {
  padding: 15px;
  border-bottom: solid 1px #d4d4d4;
}
#order-list .order-list .order-list-box:last-child {
  border: 0;
}
#order-list .order-list .order-list-box p {
  margin: 0;
  padding: 0;
  font-size: 13px;
  color: #000;
  font-weight: bold;
}
#order-list .order-list .order-list-box i {
  font-size: 30px;
  transform: scaleX(-1);
}
#order-list .order-list .order-list-box i.green-truck {
  color: #8dc44d;
}
#order-list .order-list .order-list-box i.green-truck + .digital-sample {
  margin: -12px 5px 0;
  height: 25px;
}
#order-list .order-list .order-list-box i.red-truck {
  color: #ff0415;
}
#order-list .order-list .order-list-box i.red-truck + .digital-sample {
  margin: -12px 5px 0;
  height: 25px;
}
#order-list .order-list .order-list-box .digital-sample {
  margin: 0 5px;
  height: 25px;
}
#order-list .order-list .order-list-box p.manageWidth {
  width: 100%;
  float: left;
  padding-left: 0;
}
#order-list .order-list .order-list-box:nth-child(2n+1) .order-list-content.user-list-content {
  background: #f7f8f9 !important;
}
#order-list .order-list .order-list-content {
  background: #fff;
}
#order-list .order-list .order-list-content .mobi-manage-content .col-lg-2 {
  width: 14.25%;
}
#order-list .order-list .order-list-content .mobi-manage-content .col-md-2 {
  width: 14.25%;
}
#order-list .order-list .order-list-content .mobi-manage-content .col-sm-2 {
  width: 14.25%;
}
#order-list .order-list .order-list-content .mobi-manage-content .font-manage .ngrateit {
  width: 100%;
  float: left;
}
#order-list .order-list .order-list-content .mobi-manage-content .font-manage .lab-flag-icon {
  position: absolute;
  left: 4px;
  top: 13px;
  font-size: 18px;
  color: #ff0000;
  margin: 0 5px;
  transform: none;
  height: 25px;
}
#order-list .order-list .order-list-content .mobi-manage-content .font-manage .lab-flag-icon + .lab-flag-icon {
  top: 21px;
}
#order-list .order-list .order-list-content .mobi-manage-content .font-manage .lab-flag-icon.two-icon {
  top: 0;
}
#order-list .order-list .order-list-content .mobi-manage-content.add-edit-css .font-manage .clinic-active {
  width: auto;
  float: right;
  text-transform: uppercase;
  font-size: 13px;
  position: relative;
  z-index: 9;
  line-height: 25px;
  font-weight: bold;
  color: #000;
}
#order-list .order-list .order-list-content .mobi-manage-content.add-edit-css .font-manage .clinic-active .active-inactive-content {
  min-width: 60px;
  float: right;
  text-align: left;
}
#order-list .order-list .order-list-content .mobi-manage-content.add-edit-css .font-manage .clinic-active .active-inactive-content.margin-us-ex-add {
  margin-left: 0;
  margin-right: -20px;
}
#order-list .order-list .order-list-content .mobi-manage-content.add-edit-css .font-manage .clinic-active .active-inactive-content.margin-cl-ex-add {
  margin-left: 0;
  margin-right: -50px;
}
#order-list .order-list .order-list-content .mobi-manage-content.add-edit-css .font-manage .clinic-active .round-active-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #6ed803;
  float: left;
  margin: 2px 10px 0 0;
}
#order-list .order-list .order-list-content .mobi-manage-content.add-edit-css .font-manage .clinic-active .round-inactive-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #d0021b;
  float: left;
  margin: 2px 10px 0 0;
}
@media (max-width: 767px) {
  #order-list .order-list .order-list-content .mobi-manage-content.add-edit-css .font-manage .clinic-active {
    margin-left: 5px;
  }
}
#order-list .order-list .order-list-content .mobi-manage-content.add-edit-css .font-manage a {
  color: #105c9d;
  border: solid 1px #105c9d;
  padding: 3px 5px;
  margin-left: 0;
  font-size: 12px;
  text-transform: uppercase;
}
#order-list .order-list .order-list-content .mobi-manage-content.add-edit-css .font-manage a:hover {
  color: #fff;
  background: #105c9d;
}
@media (max-width: 767px) {
  #order-list .order-list .order-list-content .mobi-manage-content.add-edit-css .font-manage {
    width: auto !important;
  }
}
#order-list .order-list .order-list-content .mobi-manage-content.add-edit-css .font-manage.clinic-active-button .clinic-active {
  float: right;
}
#order-list .order-list .order-list-content .mobi-manage-content.multi-org-manage .multiple-check-organization li {
  font-weight: bold;
  font-size: 13px;
  color: #000;
}
#order-list .order-list .order-list-content .mobi-manage-content:first-child .font-manage {
  padding-left: 35px;
}
#order-list .order-list .order-list-content.order-list-bg {
  background: #ffeef0;
  float: left;
  width: 100%;
  padding: 10px 0;
}
#order-list .order-list .order-list-content.order-list-bg span.position-relative {
  position: relative;
  float: right;
}
#order-list .order-list .order-list-content.order-list-bg span.position-relative i {
  color: #f5d823;
  font-size: 21px;
}
#order-list .order-list .order-list-content.order-list-bg span.position-relative span.Notifications-notes {
  position: absolute;
  color: #fff;
  top: -8px;
  background: #ec0b27;
  width: 14px;
  height: 15px;
  border-radius: 100%;
  font-size: 10px;
  padding-top: 0px;
  right: -5px;
}
#order-list .order-list .order-list-content.order-list-bg p {
  margin: 0;
  padding: 0;
  font-size: 13px;
  color: #000;
  -webkit-align-items: center;
  align-items: center;
}
.order-list-bg {
  background: #ffeef0;
}
.dentist-right {
  float: right;
  width: 17%;
  text-align: right;
  padding-top: 7px;
  font-size: 19px;
  position: relative;
  margin-top: 5px;
}
.dentist-right i {
  font-size: 25px;
  color: #f5d823;
}
.dentist-right span {
  position: absolute;
  width: auto;
  padding: 0 6px;
  background: #d0021b;
  border-radius: 100%;
  font-size: 11px;
  color: #fff;
  top: -4px;
  right: -7px;
}
p.manageWidth.red-astrix-mark {
  display: inline;
  color: #ff0515 !important;
}
#order-list .container {
  position: relative;
}
#order-list .container span.spinner {
  position: absolute;
  top: 300px;
  left: 50%;
}
.scroll-spinner {
  position: fixed;
  bottom: 58px;
  left: 50%;
}
.container.status-view {
  position: relative;
}
.container.status-view span.spinner {
  position: absolute;
  top: 300px;
  left: 50%;
}
.hidden-content {
  opacity: 0;
}
.green-color {
  color: #8dc44d;
}
#order-list .order-list .order-list-content .mobi-manage-content:first-child .font-manage.left-align {
  padding-left: 15px;
}
@media (max-width: 767px) {
  #order-list .order-list .order-list-content .mobi-manage-content:first-child .font-manage.permissions-div {
    text-align: left;
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  #order-list .order-list .order-list-content .mobi-manage-content:first-child .font-manage.left-align {
    padding-left: 2px;
  }
}
@media only screen and (max-width: 1169px) and (min-width: 992px) {
  #order-list .order-list .order-list-content .mobi-manage-content:first-child .font-manage.left-align {
    padding-left: 2px;
  }
}
.position-initial {
  position: initial !important;
}
#order-status-view {
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 35px;
  padding: 18px 61px 50px;
  margin-top: 132px;
  margin-bottom: 20px;
  height: 20px;
  padding: 0;
  background: transparent;
}
#order-status-view .no-padding-lr {
  padding: 0;
}
#order-status-view .no-padding-lr .Sent-by-dentist {
  float: left;
  width: 100%;
  background: #fff;
}
#order-status-view .no-padding-lr .Sent-by-dentist h1 {
  background: #82e2e5;
  color: #fff;
  font-size: 18px;
  text-align: center;
  padding: 13px 10px 12px;
  text-transform: uppercase;
  position: relative;
}
#order-status-view .no-padding-lr .Sent-by-dentist h1:after {
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 16px solid #82e2e5;
  position: absolute;
  right: -16px;
  top: 0;
  content: '';
}
#order-status-view .no-padding-lr .Sent-by-dentist h1:before {
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 16px solid #eee;
  position: absolute;
  left: 0px;
  top: 0;
  content: '';
}
#order-status-view .no-padding-lr .Sent-by-dentist ul {
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
  width: 100%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.39), 0 2px 10px 0 rgba(0, 0, 0, 0.39);
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li {
  padding: 10px 20px 10px;
  cursor: pointer;
  float: left;
  width: 100%;
  border-bottom: solid 1px #979797;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li p {
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li p b {
  margin-right: 5px;
  float: left;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist {
  float: left;
  width: 100%;
  margin-bottom: 0px;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-left {
  float: left;
  width: 70%;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-left p span {
  margin-right: 5px;
  position: relative;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-left p span:after {
  width: 2px;
  height: 2px;
  content: ',';
  position: absolute;
  bottom: 16px;
  right: -3px;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-left p span:last-child:after {
  content: '';
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-left p span:last-child:after {
  content: '';
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-left .tandtekniker {
  float: left;
  width: auto;
  text-align: left;
  text-transform: uppercase;
  color: #a5a3a3;
  background: #fff;
  padding: 0px 5px 0px 2px;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-left .tandtekniker img {
  width: auto;
  height: 30px;
  max-width: 100%;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-left .tandtekniker span.color-change {
  color: #3a9e33;
  font-size: 14px;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-left .tandtekniker span {
  float: right;
  font-size: 12px;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-left.width-resize {
  width: 30%;
  float: left;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-right {
  float: right;
  width: 30%;
  text-align: right;
  padding-top: 5px;
  font-size: 19px;
  position: relative;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-right i {
  font-size: 25px;
  color: #f5a623;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-right span {
  position: absolute;
  width: auto;
  padding: 0 6px;
  background: #d0021b;
  border-radius: 100%;
  font-size: 11px;
  color: #fff;
  top: -4px;
  right: -7px;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-right.digital-flag-truck {
  margin-top: -10px;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-right.digital-flag-truck .lab-flag-icon {
  left: 4px;
  top: 13px;
  font-size: 25px;
  color: #ff0000;
  margin: 0 5px;
  transform: none;
  height: 25px;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-right.digital-flag-truck .lab-flag-icon + .lab-flag-icon {
  top: 21px;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-right.digital-flag-truck .lab-flag-icon.two-icon {
  top: 0;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-right.digital-flag-truck i {
  font-size: 27px;
  transform: scaleX(-1);
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-right.digital-flag-truck i.green-truck {
  color: #8dc44d;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-right.digital-flag-truck i.green-truck + .status-view-digital-sample {
  margin: -12px 5px 0;
  height: 25px;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-right.digital-flag-truck i.red-truck {
  color: #ff0415;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-right.digital-flag-truck i.red-truck + .status-view-digital-sample {
  margin: -12px 5px 0;
  height: 25px;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-right.digital-flag-truck .status-view-digital-sample {
  margin: 0 5px;
  height: 25px;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-right.width-resize {
  width: 70%;
  float: right;
  font-size: 12px;
  margin-top: 0;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist:last-child {
  margin-bottom: 0;
  margin-top: -5px;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li.light-red-bg {
  cursor: pointer;
  background: #ffeef0;
}
#order-status-view .no-padding-lr .Sent-by-dentist ul li.light-red-bg .dentist .dentist-right.width-resize {
  color: #d0021b;
}
#order-status-view .no-padding-lr .no-padding-l {
  padding-left: 0px;
}
#order-status-view .no-padding-lr .no-padding-l .Sent-by-dentist h1:before {
  border: 0px;
}
#order-status-view .no-padding-lr .mid-sec-status .Sent-by-dentist h1 {
  background: #27ced2;
}
#order-status-view .no-padding-lr .mid-sec-status .Sent-by-dentist h1:after {
  border-left-color: #27ced2;
}
#order-status-view .no-padding-lr .no-padding-r {
  padding-right: 0px;
}
#order-status-view .no-padding-lr .no-padding-r .Sent-by-dentist h1 {
  background: #105c9d;
}
#order-status-view .no-padding-lr .no-padding-r .Sent-by-dentist h1:after {
  border: 0px;
}
#my-modal-compatible .modal-dialog {
  width: 850px;
}
#my-modal-compatible .modal-dialog .modal-content {
  padding: 20px 30px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
#my-modal-compatible .modal-dialog .modal-content .modal-header {
  border: 0px;
}
#my-modal-compatible .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  font-size: 30px;
  margin: -15px;
}
#my-modal-compatible .modal-dialog .modal-content .modal-header h4.modal-title {
  color: #105c9d;
  font-size: 25px;
  font-weight: bold;
}
#my-modal-compatible .modal-dialog .modal-content .modal-header h4.modal-title span {
  border: solid 1px #105c9d;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
}
#my-modal-compatible .modal-dialog .modal-content .modal-body p {
  font-size: 16px;
  line-height: 18px;
}
#my-modal-compatible .modal-dialog .modal-content .modal-body p b {
  width: 100%;
  float: left;
}
#my-modal-compatible .modal-dialog .modal-content .modal-footer {
  border: 0;
  margin: 30px 0 20px;
}
#my-modal-compatible .modal-dialog .modal-content .modal-footer .btn.btn-default {
  border-radius: 0px;
  border: solid 1px #105c9d;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 15px 15px;
  color: #105c9d;
}
#my-modal-compatible .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#my-modal-compatible .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#my-modal-compatible .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close {
  background: #105c9d;
  color: #fff;
}
#navBar .newOrder.add-clinics {
  float: left;
  border-left: solid 0px #979797;
  border-right: solid 1px #979797;
  padding-left: 0;
  padding-right: 10px;
  margin-right: 10px;
}
#clinics {
  width: 100%;
  float: left;
  margin-top: 200px;
}
#clinics .clinics {
  width: 100%;
  float: left;
}
#clinics .clinics ul {
  width: 100%;
  float: left;
}
#clinics .clinics ul li {
  width: 29.3%;
  float: left;
  margin: 0 2% 30px;
  background: #fff;
  -webkit-box-shadow: 0 0 5px #a5a3a3;
  -moz-box-shadow: 0 0 5px #a5a3a3;
  box-shadow: 0 0 5px #a5a3a3;
  padding-top: 14px;
}
#clinics .clinics ul li .clinic-block {
  width: 100%;
  float: left;
}
#clinics .clinics ul li .clinic-block .delete-clinic {
  cursor: pointer;
  width: auto;
  float: right;
  margin-right: 17px;
  position: relative;
  z-index: 9;
}
#clinics .clinics ul li .clinic-block .clinic-img {
  width: 100%;
  float: left;
  text-align: center;
  display: inline-block;
}
#clinics .clinics ul li .clinic-block .clinic-img .round-img-box {
  width: auto;
  float: none;
  display: inline-block;
  border: solid 1px #dcdada;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  padding: 18px;
  position: relative;
}
#clinics .clinics ul li .clinic-block .clinic-img .round-img-box .round-active-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #6ed803;
  float: left;
  margin: 3px 10px 0 0;
  position: absolute;
  bottom: 0px;
  right: -8px;
}
#clinics .clinics ul li .clinic-block .clinic-img .round-img-box .round-inactive-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #d0021b;
  float: left;
  margin: 3px 10px 0 0;
  position: absolute;
  bottom: 0px;
  right: -8px;
}
#clinics .clinics ul li .clinic-block .delete-clinic + .clinic-img {
  margin-top: -15px;
}
#clinics .clinics ul li .clinic-block h4 {
  width: 100%;
  float: left;
  text-align: center;
  color: #105c9d;
  margin-top: 15px;
  min-height: 20px;
}
#clinics .clinics ul li .clinic-block p {
  width: 100%;
  float: left;
  text-align: center;
  color: #a5a3a3;
  font-weight: 300;
  font-size: 13px;
}
#clinics .clinics ul li .clinic-block .clinic-activation-block {
  width: 100%;
  float: left;
  background: #dcdada;
  padding: 20px 10px;
  margin-top: 20px;
}
#clinics .clinics ul li .clinic-block .clinic-activation-block .clinic-active {
  width: auto;
  float: left;
  margin-top: 4px;
  text-transform: uppercase;
  font-size: 14px;
  position: relative;
  z-index: 8;
}
#clinics .clinics ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #6ed803;
  float: left;
  margin-right: 4px;
}
#clinics .clinics ul li .clinic-block .clinic-activation-block .clinic-active .round-inactive-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #d0021b;
  float: left;
  margin-right: 4px;
}
#clinics .clinics ul li .clinic-block .clinic-activation-block .clinic-active-button {
  width: auto;
  float: right;
}
#clinics .clinics ul li .clinic-block .clinic-activation-block .clinic-active-button a {
  color: #105c9d;
  border: solid 1px #105c9d;
  padding: 3px 5px;
  margin-left: 0px;
  font-size: 12px;
  text-transform: uppercase;
}
#clinics .clinics ul li .clinic-block .clinic-activation-block .clinic-active-button a:hover,
#clinics .clinics ul li .clinic-block .clinic-activation-block .clinic-active-button a:focus,
#clinics .clinics ul li .clinic-block .clinic-activation-block .clinic-active-button a.activation-tab-btn {
  color: #fff;
  background: #105c9d;
}
#add-clinic-modal .modal-dialog,
#add-client-modal .modal-dialog,
#add-material-modal .modal-dialog,
#add-actionList-action-modal .modal-dialog,
#add-transport-clinic-modal .modal-dialog {
  width: 1000px;
}
#add-clinic-modal .modal-dialog .modal-content,
#add-client-modal .modal-dialog .modal-content,
#add-material-modal .modal-dialog .modal-content,
#add-actionList-action-modal .modal-dialog .modal-content,
#add-transport-clinic-modal .modal-dialog .modal-content {
  padding: 20px 40px 20px 70px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
#add-clinic-modal .modal-dialog .modal-content .modal-header,
#add-client-modal .modal-dialog .modal-content .modal-header,
#add-material-modal .modal-dialog .modal-content .modal-header,
#add-actionList-action-modal .modal-dialog .modal-content .modal-header,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-header {
  border: 0px;
}
#add-clinic-modal .modal-dialog .modal-content .modal-header .close,
#add-client-modal .modal-dialog .modal-content .modal-header .close,
#add-material-modal .modal-dialog .modal-content .modal-header .close,
#add-actionList-action-modal .modal-dialog .modal-content .modal-header .close,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  font-size: 30px;
  margin: -15px -25px;
}
#add-clinic-modal .modal-dialog .modal-content .modal-header .close:focus,
#add-client-modal .modal-dialog .modal-content .modal-header .close:focus,
#add-material-modal .modal-dialog .modal-content .modal-header .close:focus,
#add-actionList-action-modal .modal-dialog .modal-content .modal-header .close:focus,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-header .close:focus {
  outline: none;
}
#add-clinic-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#add-client-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#add-material-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#add-actionList-action-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-header h4.modal-title {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}
#add-clinic-modal .modal-dialog .modal-content .modal-body,
#add-client-modal .modal-dialog .modal-content .modal-body,
#add-material-modal .modal-dialog .modal-content .modal-body,
#add-actionList-action-modal .modal-dialog .modal-content .modal-body,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-body {
  text-align: left;
}
#add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#add-client-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#add-material-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#add-actionList-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form {
  width: 100%;
  float: left;
  margin-bottom: 55px;
  margin-top: 20px;
}
#add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
#add-client-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
#add-material-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
#add-actionList-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row {
  width: 100%;
  float: left;
  margin-bottom: 30px;
}
#add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4,
#add-client-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4,
#add-material-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4,
#add-actionList-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4 {
  color: #105c9d;
  margin-bottom: 10px;
}
#add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4 p.red-astrix-mark,
#add-client-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4 p.red-astrix-mark,
#add-material-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4 p.red-astrix-mark,
#add-actionList-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4 p.red-astrix-mark,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4 p.red-astrix-mark {
  margin: 0;
  line-height: 19px;
}
#add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row input,
#add-client-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row input,
#add-material-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row input,
#add-actionList-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row input,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row input {
  width: 210px;
  float: left;
  padding: 8px 10px;
  font-size: 12px;
  text-align: center;
  border: solid 1px #979797;
  min-height: 38px;
}
#add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row input:focus,
#add-client-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row input:focus,
#add-material-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row input:focus,
#add-actionList-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row input:focus,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row input:focus {
  outline: none;
}
#add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
#add-client-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
#add-material-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
#add-actionList-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block {
  width: 25%;
  float: left;
  margin-right: 2%;
  margin-top: -15px;
}
#add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block label,
#add-client-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block label,
#add-material-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block label,
#add-actionList-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block label,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block label {
  width: 100%;
  float: left;
  color: #dcdada;
  font-weight: 300;
  font-style: italic;
}
#add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul,
#add-client-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul,
#add-material-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul,
#add-actionList-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul {
  width: 100%;
  float: left;
}
#add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
#add-client-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
#add-material-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
#add-actionList-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li {
  width: auto;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px 15px;
  background: #dcdada;
  cursor: pointer;
}
#add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li.choose-clinic-tab,
#add-client-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li.choose-clinic-tab,
#add-material-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li.choose-clinic-tab,
#add-actionList-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li.choose-clinic-tab,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li.choose-clinic-tab {
  background: #105c9d;
  color: #fff;
}
#add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.full-street-div,
#add-client-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.full-street-div,
#add-material-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.full-street-div,
#add-actionList-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.full-street-div,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.full-street-div {
  width: 100%;
}
#add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .street-block-select,
#add-client-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .street-block-select,
#add-material-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .street-block-select,
#add-actionList-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .street-block-select,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .street-block-select {
  line-height: 38px;
  min-height: 38px;
  width: 120%;
  font-size: 14px;
  height: 38px;
}
#add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.postcode-input,
#add-client-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.postcode-input,
#add-material-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.postcode-input,
#add-actionList-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.postcode-input,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.postcode-input {
  width: 15%;
}
#add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.postcode-input input,
#add-client-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.postcode-input input,
#add-material-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.postcode-input input,
#add-actionList-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.postcode-input input,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.postcode-input input {
  width: 100%;
}
#add-clinic-modal .modal-dialog .modal-content .modal-footer,
#add-client-modal .modal-dialog .modal-content .modal-footer,
#add-material-modal .modal-dialog .modal-content .modal-footer,
#add-actionList-action-modal .modal-dialog .modal-content .modal-footer,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-footer {
  border: 0;
  margin: 30px 0 20px;
}
#add-clinic-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
#add-client-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
#add-material-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
#add-actionList-action-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-footer .btn.btn-default {
  border-radius: 0px;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 10px 45px;
  color: #105c9d;
}
#add-clinic-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#add-client-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#add-material-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#add-actionList-action-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#add-clinic-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#add-client-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#add-material-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#add-actionList-action-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#add-clinic-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close,
#add-client-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close,
#add-material-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close,
#add-actionList-action-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close,
#add-transport-clinic-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close {
  background: #105c9d;
  color: #fff;
}
.inValidPinCode {
  color: red;
  font-size: 11px;
}
#organization-setting {
  width: 100%;
  float: left;
  margin-bottom: 5px;
}
#organization-setting h4 {
  color: #105c9d;
  margin-bottom: 20px;
}
#organization-setting .full-street-div {
  width: 100%;
  float: left;
}
#organization-setting .full-street-div label {
  width: 100%;
  float: left;
  color: #dcdada;
  font-weight: 300;
  font-style: italic;
}
#organization-setting .full-street-div ul {
  width: 100%;
  float: left;
}
#organization-setting .full-street-div ul li {
  width: auto;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px 15px;
  background: #dcdada;
  cursor: pointer;
}
#organization-setting .full-street-div ul li.choose-clinic-tab {
  background: #105c9d;
  color: #fff;
}
.is-Pick-up-delivery-place input[type="checkbox"]:not(:checked),
.is-Pick-up-delivery-place input.notCheckedInput {
  float: left !important;
  width: 15px !important;
  height: 15px;
  -webkit-appearance: inherit;
  margin: 2px 10px 0px 0px;
  background: none;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: #8edddd !important;
  border-image: initial !important;
  outline: none;
  padding: 7px !important;
  border-radius: 2px;
  min-height: inherit !important;
}
.is-Pick-up-delivery-place input[type="checkbox"]:checked,
.is-Pick-up-delivery-place input:checked.checkedInput {
  float: left !important;
  width: 15px !important;
  height: 15px;
  -webkit-appearance: inherit;
  position: relative;
  margin: 2px 10px 0px 0px;
  background: #1fbabb;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: #1fbabb !important;
  border-image: initial !important;
  outline: 0px;
  font: 14px/1 FontAwesome;
  padding: 7px !important;
  border-radius: 4px;
  min-height: inherit !important;
}
.is-Pick-up-delivery-place input[type="checkbox"]:checked:after,
.is-Pick-up-delivery-place input:checked.checkedInput:after {
  content: '\f00c';
  position: absolute;
  top: 1px;
  left: 1px;
  font-size: 13px;
  color: #fff;
}
.is-Pick-up-delivery-place label {
  font-weight: 400;
}
#navBar .newOrder.add-users {
  float: left;
  border-left: solid 0px #979797;
  border-right: solid 1px #979797;
  padding-left: 0;
  padding-right: 10px;
  margin-right: 10px;
}
#clinics.users-carddata {
  margin-top: 150px;
}
#users.users-carddata .users ul,
#clinics.users-carddata .users ul,
#users.users-carddata .clinics ul,
#clinics.users-carddata .clinics ul {
  display: block;
}
#users.users-carddata .users ul li,
#clinics.users-carddata .users ul li,
#users.users-carddata .clinics ul li,
#clinics.users-carddata .clinics ul li {
  width: 31%;
  float: left;
  margin: 0 2% 30px 0;
  min-width: inherit;
  max-width: inherit;
}
#users {
  margin-top: 200px;
  width: 100%;
  float: left;
  margin-top: 250px;
}
#users.users-carddata {
  margin: 0;
}
#users .users {
  width: 100%;
  float: left;
}
#users .users ul {
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
}
#users .users ul li {
  width: 29.3%;
  float: left;
  margin: 0 2% 30px;
  background: #fff;
  -webkit-box-shadow: 0 0 5px #a5a3a3;
  -moz-box-shadow: 0 0 5px #a5a3a3;
  box-shadow: 0 0 5px #a5a3a3;
  padding-top: 14px;
  padding-bottom: 90px;
  position: relative;
  flex: 1;
  min-width: 345px;
  max-width: 345px;
}
#users .users ul li .clinic-block {
  width: 100%;
  float: left;
}
#users .users ul li .clinic-block .delete-clinic {
  cursor: pointer;
  width: auto;
  float: right;
  margin-right: 17px;
  position: relative;
  z-index: 9;
}
#users .users ul li .clinic-block .clinic-img {
  width: 100%;
  float: left;
  text-align: center;
  display: inline-block;
}
#users .users ul li .clinic-block .clinic-img .round-img-box {
  width: auto;
  float: none;
  display: inline-block;
  border: solid 1px #dcdada;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  padding: 0px;
  position: relative;
}
#users .users ul li .clinic-block .clinic-img .round-img-box img {
  width: 80px;
  border-radius: 100%;
  height: 80px;
  object-fit: cover;
}
#users .users ul li .clinic-block .clinic-img .round-img-box .round-active-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #6ed803;
  float: left;
  margin: 3px 10px 0 0;
  position: absolute;
  bottom: 0px;
  right: -8px;
}
#users .users ul li .clinic-block .clinic-img .round-img-box .round-not-active-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #ff9f00;
  float: left;
  margin: 3px 10px 0 0;
  position: absolute;
  bottom: 0px;
  right: -8px;
}
#users .users ul li .clinic-block .clinic-img .round-img-box .round-inactive-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #d0021b;
  float: left;
  margin: 2px 10px 0 0;
  position: absolute;
  bottom: 0px;
  right: -8px;
}
#users .users ul li .clinic-block .delete-clinic + .clinic-img {
  margin-top: -15px;
}
#users .users ul li .clinic-block h4 {
  width: 100%;
  float: left;
  text-align: center;
  color: #105c9d;
  margin-top: 15px;
}
#users .users ul li .clinic-block ul.multiple-check-organization {
  width: 100%;
  text-align: center;
  display: inline-block;
  margin-top: -5px;
  margin-bottom: -10px;
  /*li:last-child{
              &:after{
                content: '';
              }
            }*/
}
#users .users ul li .clinic-block ul.multiple-check-organization li {
  width: 96%;
  float: none;
  margin-bottom: 0px;
  display: inline-block;
  color: #4a4a4a;
  position: relative;
  font-size: 16px;
  padding: 0px;
  box-shadow: none;
  min-width: 335px;
  /*&:after{
                content: ','; position: absolute; right: -5px; bottom: -4px; font-size: 25px;
              }*/
}
#users .users ul li .clinic-block p {
  width: 100%;
  float: left;
  text-align: center;
  color: #a5a3a3;
  font-weight: 300;
  font-size: 13px;
}
#users .users ul li .clinic-block .clinic-activation-block {
  width: 100%;
  float: left;
  background: #dcdada;
  padding: 20px;
  margin-top: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
}
#users .users ul li .clinic-block .clinic-activation-block .clinic-active {
  width: auto;
  float: left;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 29px;
  position: relative;
  z-index: 9;
}
#users .users ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #6ed803;
  float: left;
  margin: 2px 10px 0 0;
}
#users .users ul li .clinic-block .clinic-activation-block .clinic-active .round-not-active-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #ff9f00;
  float: left;
  margin: 2px 10px 0 0;
}
#users .users ul li .clinic-block .clinic-activation-block .clinic-active .round-inactive-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #d0021b;
  float: left;
  margin: 2px 10px 0 0;
}
#users .users ul li .clinic-block .clinic-activation-block .clinic-active-button {
  width: auto;
  float: right;
}
#users .users ul li .clinic-block .clinic-activation-block .clinic-active-button a {
  color: #105c9d;
  border: solid 1px #105c9d;
  padding: 3px 5px;
  margin-left: 0px;
  font-size: 12px;
  text-transform: uppercase;
}
#users .users ul li .clinic-block .clinic-activation-block .clinic-active-button a:hover,
#users .users ul li .clinic-block .clinic-activation-block .clinic-active-button a:focus,
#users .users ul li .clinic-block .clinic-activation-block .clinic-active-button a.activation-tab-btn {
  color: #fff;
  background: #105c9d;
}
#add-user-modal .modal-dialog,
#remove-user-modal .modal-dialog,
#add-local-dentist-modal .modal-dialog {
  width: 800px;
}
#add-user-modal .modal-dialog .modal-content,
#remove-user-modal .modal-dialog .modal-content,
#add-local-dentist-modal .modal-dialog .modal-content {
  padding: 20px 40px 20px 70px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
#add-user-modal .modal-dialog .modal-content .modal-header,
#remove-user-modal .modal-dialog .modal-content .modal-header,
#add-local-dentist-modal .modal-dialog .modal-content .modal-header {
  border: 0px;
}
#add-user-modal .modal-dialog .modal-content .modal-header .close,
#remove-user-modal .modal-dialog .modal-content .modal-header .close,
#add-local-dentist-modal .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  font-size: 30px;
  margin: -15px -25px;
}
#add-user-modal .modal-dialog .modal-content .modal-header .close:focus,
#remove-user-modal .modal-dialog .modal-content .modal-header .close:focus,
#add-local-dentist-modal .modal-dialog .modal-content .modal-header .close:focus {
  outline: none;
}
#add-user-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#remove-user-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#add-local-dentist-modal .modal-dialog .modal-content .modal-header h4.modal-title {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form {
  width: 100%;
  float: left;
  margin-top: 20px;
}
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row {
  width: 100%;
  float: left;
  margin-bottom: 55px;
}
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4,
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4 {
  color: #105c9d;
  margin-bottom: 10px;
}
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row input,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row input,
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row input {
  width: 100%;
  float: left;
  padding: 8px 10px;
  font-size: 12px;
  text-align: center;
  border: solid 1px #979797;
  min-height: 38px;
}
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block {
  width: 28%;
  float: left;
  margin-right: 5%;
  margin-top: -15px;
}
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .street-block-select,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .street-block-select,
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .street-block-select {
  line-height: 38px;
  min-height: 38px;
  width: 120%;
  font-size: 14px;
  height: 38px;
}
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul,
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul {
  width: 100%;
  float: left;
}
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li {
  width: auto;
  float: left;
  margin-right: 10px;
  margin: 10px;
  padding: 8px 15px;
  background: #dcdada;
  cursor: pointer;
}
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li.choose-clinic-tab,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li.choose-clinic-tab,
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li.choose-clinic-tab {
  background: #105c9d;
  color: #fff;
}
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.full-street-div,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.full-street-div,
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.full-street-div {
  width: 100%;
}
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label,
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label {
  font-weight: normal;
  font-size: 14px;
  margin-top: 20px;
}
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox],
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox],
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  position: relative;
  visibility: hidden;
}
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox],
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox],
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox],
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox] + label::before,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox] + label::before,
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox] + label::before {
  font-size: 18px;
  line-height: 20px;
  cursor: pointer;
  vertical-align: middle;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox] + label::before,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox] + label::before,
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox] + label::before {
  content: '';
  text-align: center;
  display: inline-block;
  pointer-events: none;
  opacity: 1;
  color: black;
  transition: all 0.3s ease;
  border: 2px solid #105c9d;
  width: 23px;
  height: 23px;
  margin-right: 15px;
}
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox] + label,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox] + label,
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox] + label {
  line-height: 23px;
  margin: 0 15px 0 15px;
}
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:hover,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:hover,
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:hover {
  cursor: pointer;
}
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:hover + label::before,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:hover + label::before,
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:hover + label::before {
  content: '';
}
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:checked + label::before,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:checked + label::before,
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:checked + label::before {
  content: '\2714';
  font-size: 14px;
  color: #105c9d;
  text-align: center;
}
#add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:checked:hover + label::before,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:checked:hover + label::before,
#add-local-dentist-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:checked:hover + label::before {
  opacity: 1;
}
#add-user-modal .modal-dialog .modal-content .modal-footer,
#remove-user-modal .modal-dialog .modal-content .modal-footer,
#add-local-dentist-modal .modal-dialog .modal-content .modal-footer {
  border: 0;
  margin: 30px 0 20px;
}
#add-user-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
#remove-user-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
#add-local-dentist-modal .modal-dialog .modal-content .modal-footer .btn.btn-default {
  border-radius: 0px;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 10px 45px;
  color: #105c9d;
}
#add-user-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#remove-user-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#add-local-dentist-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#add-user-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#remove-user-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#add-local-dentist-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#add-user-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close,
#remove-user-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close,
#add-local-dentist-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close {
  background: #105c9d;
  color: #fff;
}
#add-user-modal .check-icons i,
#remove-user-modal .check-icons i,
#add-local-dentist-modal .check-icons i {
  background: #105c9d;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  padding-top: 3px;
  text-align: center;
  position: absolute;
  top: 61px;
  right: 29px;
  font-size: 10px;
}
#navBar .newOrder.add-users {
  float: left;
  border-left: solid 0px #979797;
  border-right: solid 1px #979797;
  padding-left: 0;
  padding-right: 10px;
  margin-right: 10px;
}
#users-lab {
  width: 100%;
  float: left;
  margin-top: 200px;
}
#users-lab .users-lab {
  width: 100%;
  float: left;
}
#users-lab .users-lab ul {
  width: 100%;
  float: left;
}
#users-lab .users-lab ul li {
  width: 29.3%;
  float: left;
  margin: 0 2% 30px;
  background: #fff;
  -webkit-box-shadow: 0 0 5px #a5a3a3;
  -moz-box-shadow: 0 0 5px #a5a3a3;
  box-shadow: 0 0 5px #a5a3a3;
  padding-top: 14px;
}
#users-lab .users-lab ul li .clinic-block {
  width: 100%;
  float: left;
}
#users-lab .users-lab ul li .clinic-block .delete-clinic {
  cursor: pointer;
  width: auto;
  float: right;
  margin-right: 17px;
  position: relative;
  z-index: 9;
}
#users-lab .users-lab ul li .clinic-block .clinic-img {
  width: 100%;
  float: left;
  text-align: center;
  display: inline-block;
}
#users-lab .users-lab ul li .clinic-block .clinic-img .round-img-box {
  width: auto;
  float: none;
  display: inline-block;
  border: solid 1px #dcdada;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  padding: 0px;
  position: relative;
}
#users-lab .users-lab ul li .clinic-block .clinic-img .round-img-box img {
  width: 80px;
  border-radius: 100%;
}
#users-lab .users-lab ul li .clinic-block .clinic-img .round-img-box .round-active-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #6ed803;
  float: left;
  margin: 3px 10px 0 0;
  position: absolute;
  bottom: 0px;
  right: -8px;
}
#users-lab .users-lab ul li .clinic-block .clinic-img .round-img-box .round-not-active-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #ff9f00;
  float: left;
  margin: 3px 10px 0 0;
  position: absolute;
  bottom: 0px;
  right: -8px;
}
#users-lab .users-lab ul li .clinic-block .clinic-img .round-img-box .round-inactive-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #d0021b;
  float: left;
  margin: 2px 10px 0 0;
  position: absolute;
  bottom: 0px;
  right: -8px;
}
#users-lab .users-lab ul li .clinic-block .delete-clinic + .clinic-img {
  margin-top: -15px;
}
#users-lab .users-lab ul li .clinic-block h4 {
  width: 100%;
  float: left;
  text-align: center;
  color: #105c9d;
  margin-top: 15px;
}
#users-lab .users-lab ul li .clinic-block h3 {
  width: 100%;
  float: left;
  text-align: center;
  color: #4a4a4a;
  margin-top: 15px;
  margin-top: -5px;
  font-size: 16px;
  margin-bottom: -10px;
}
#users-lab .users-lab ul li .clinic-block p {
  width: 100%;
  float: left;
  text-align: center;
  color: #a5a3a3;
  font-weight: 300;
  font-size: 13px;
}
#users-lab .users-lab ul li .clinic-block .clinic-activation-block {
  width: 100%;
  float: left;
  background: #dcdada;
  padding: 20px;
  margin-top: 20px;
}
#users-lab .users-lab ul li .clinic-block .clinic-activation-block .clinic-active {
  width: auto;
  float: left;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 29px;
  position: relative;
  z-index: 9;
}
#users-lab .users-lab ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #6ed803;
  float: left;
  margin: 2px 10px 0 0;
}
#users-lab .users-lab ul li .clinic-block .clinic-activation-block .clinic-active .round-not-active-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #ff9f00;
  float: left;
  margin: 2px 10px 0 0;
}
#users-lab .users-lab ul li .clinic-block .clinic-activation-block .clinic-active .round-inactive-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #d0021b;
  float: left;
  margin: 2px 10px 0 0;
}
#users-lab .users-lab ul li .clinic-block .clinic-activation-block .clinic-active-button {
  width: auto;
  float: right;
}
#users-lab .users-lab ul li .clinic-block .clinic-activation-block .clinic-active-button a {
  color: #105c9d;
  border: solid 1px #105c9d;
  padding: 3px 5px;
  margin-left: 0px;
  font-size: 12px;
  text-transform: uppercase;
}
#users-lab .users-lab ul li .clinic-block .clinic-activation-block .clinic-active-button a:hover,
#users-lab .users-lab ul li .clinic-block .clinic-activation-block .clinic-active-button a:focus,
#users-lab .users-lab ul li .clinic-block .clinic-activation-block .clinic-active-button a.activation-tab-btn {
  color: #fff;
  background: #105c9d;
}
#add-user-lab-modal .modal-dialog {
  width: 800px;
}
#add-user-lab-modal .modal-dialog .modal-content {
  padding: 20px 40px 20px 70px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
#add-user-lab-modal .modal-dialog .modal-content .modal-header {
  border: 0px;
}
#add-user-lab-modal .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  font-size: 30px;
  margin: -15px -25px;
}
#add-user-lab-modal .modal-dialog .modal-content .modal-header .close:focus {
  outline: none;
}
#add-user-lab-modal .modal-dialog .modal-content .modal-header h4.modal-title {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}
#add-user-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form {
  width: 100%;
  float: left;
  margin-top: 20px;
}
#add-user-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row {
  width: 100%;
  float: left;
  margin-bottom: 55px;
}
#add-user-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4 {
  color: #105c9d;
  margin-bottom: 20px;
}
#add-user-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row input {
  width: 280px;
  float: left;
  padding: 8px 10px;
  font-size: 12px;
  text-align: center;
  border: solid 1px #979797;
}
#add-user-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block {
  width: 45%;
  float: left;
  margin-right: 5%;
  margin-top: -15px;
}
#add-user-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul {
  width: 100%;
  float: left;
}
#add-user-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li {
  width: auto;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px 15px;
  background: #dcdada;
}
#add-user-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li.choose-clinic-tab {
  background: #105c9d;
  color: #fff;
}
#add-user-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label {
  font-weight: normal;
  font-size: 14px;
  margin-top: 20px;
}
#add-user-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type="checkbox"].notCheckedInput {
  float: left;
  width: 15px;
  margin: 0px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid #105c9d;
  outline: none;
  -webkit-appearance: inherit;
  padding: 10px;
  border-radius: 4px;
}
#add-user-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type="checkbox"].checkedInput {
  float: left;
  width: 15px;
  margin: 0px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid #105c9d;
  outline: none;
  -webkit-appearance: inherit;
  position: relative;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 10px;
  border-radius: 4px;
}
#add-user-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type="checkbox"].checkedInput:after {
  content: '\f00c';
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 15px;
  color: #105c9d;
}
#add-user-lab-modal .modal-dialog .modal-content .modal-footer {
  border: 0;
  margin: 30px 0 20px;
}
#add-user-lab-modal .modal-dialog .modal-content .modal-footer .btn.btn-default {
  border-radius: 0px;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 10px 45px;
  color: #105c9d;
}
#add-user-lab-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#add-user-lab-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#add-user-lab-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close {
  background: #105c9d;
  color: #fff;
}
#confirm-password-modal .modal-dialog .modal-content,
#delete-clinic-modal .modal-dialog .modal-content {
  padding: 20px 40px 20px 40px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
#confirm-password-modal .modal-dialog .modal-content .modal-header,
#delete-clinic-modal .modal-dialog .modal-content .modal-header {
  border: 0px;
}
#confirm-password-modal .modal-dialog .modal-content .modal-header .close,
#delete-clinic-modal .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  font-size: 30px;
  margin: -15px -25px;
}
#confirm-password-modal .modal-dialog .modal-content .modal-header .close:focus,
#delete-clinic-modal .modal-dialog .modal-content .modal-header .close:focus {
  outline: none;
}
#confirm-password-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#delete-clinic-modal .modal-dialog .modal-content .modal-header h4.modal-title {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}
#confirm-password-modal .modal-dialog .modal-content .modal-body,
#delete-clinic-modal .modal-dialog .modal-content .modal-body {
  text-align: center;
  padding: 20px 0 20px;
}
#confirm-password-modal .modal-dialog .modal-content .modal-body span.fill-password-success,
#delete-clinic-modal .modal-dialog .modal-content .modal-body span.fill-password-success {
  float: none;
  width: 70px;
  margin: 0 10px;
  height: 70px;
  background: #6ed803;
  outline: 0;
  -webkit-appearance: inherit;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 0px 10px;
  border-radius: 100%;
  content: '\f00c';
  position: relative;
  display: inline-block;
}
#confirm-password-modal .modal-dialog .modal-content .modal-body span.fill-password-success:after,
#delete-clinic-modal .modal-dialog .modal-content .modal-body span.fill-password-success:after {
  content: '\f00c';
  font-size: 40px;
  color: #fff;
  float: left;
  margin: 15px 6px;
}
#confirm-password-modal .modal-dialog .modal-content .modal-footer,
#delete-clinic-modal .modal-dialog .modal-content .modal-footer {
  border: 0;
  margin: 30px 0 20px;
}
#confirm-password-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
#delete-clinic-modal .modal-dialog .modal-content .modal-footer .btn.btn-default {
  border-radius: 0px;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 10px 45px;
  color: #105c9d;
}
#confirm-password-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#delete-clinic-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#confirm-password-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#delete-clinic-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#confirm-password-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close,
#delete-clinic-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close {
  background: #105c9d;
  color: #fff;
}
/*
#delete-clinic-modal {
  margin: -30px;
  .modal-dialog {
    width: 600px;
  }
}*/
#confirm-password-modal {
  height: 301px !important;
  overflow: hidden;
}
#navBar .newOrder.add-users {
  float: left;
  border-left: solid 0px #979797;
  border-right: solid 1px #979797;
  padding-left: 0;
  padding-right: 10px;
  margin-right: 10px;
}
.no-padding-default {
  padding: 0px;
}
.profile-form-left-row label {
  color: black !important;
}
.profile-form-left-col .notifications-title {
  color: black !important;
}
.profile-right-section h3 {
  color: black !important;
}
#connect-lab {
  width: 100%;
  float: left;
  margin-top: 200px;
  margin-bottom: 20px;
}
#connect-lab .connect-lab {
  width: 100%;
  float: left;
  background: #fff;
}
#connect-lab .connect-lab .profile-left-section {
  width: 100%;
  float: left;
  background: #fff;
  padding-top: 45px;
}
#connect-lab .connect-lab .profile-left-section .head-profile {
  width: 100%;
  float: left;
  padding: 0 35px;
}
#connect-lab .connect-lab .profile-left-section .head-profile h2 {
  width: 100%;
  float: left;
  text-align: center;
  background: url(../images/border-head-h2.png) 0 center repeat-x;
}
#connect-lab .connect-lab .profile-left-section .head-profile h2 span {
  width: auto;
  padding: 0 28px;
  background: #fff;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 300;
}
#connect-lab .connect-lab .profile-left-section .profile-form-left {
  width: 100%;
  float: left;
  padding-top: 25px;
}
#connect-lab .connect-lab .profile-left-section .profile-form-left .profile-form-left-row {
  width: 100%;
  float: left;
  margin-bottom: 37px;
  padding: 0 68px;
}
#connect-lab .connect-lab .profile-left-section .profile-form-left .profile-form-left-row .logo-profile-coonect-lab {
  overflow: hidden;
}
#connect-lab .connect-lab .profile-left-section .profile-form-left .profile-form-left-row .logo-profile-coonect-lab img {
  width: auto;
  height: 71px;
}
#connect-lab .connect-lab .profile-left-section .profile-form-left .profile-form-left-row .contact-form {
  width: 100%;
  float: left;
  margin-top: 7px;
}
#connect-lab .connect-lab .profile-left-section .profile-form-left .profile-form-left-row .contact-form h3 {
  font-size: 18px;
  color: #105c9d;
  width: 100%;
  float: left;
  margin-top: 40px;
  margin-bottom: 25px;
}
#connect-lab .connect-lab .profile-left-section .profile-form-left .profile-form-left-row .contact-form .contact-form-row {
  width: 100%;
  float: left;
  margin-bottom: 20px;
  padding-left: 10px;
}
#connect-lab .connect-lab .profile-left-section .profile-form-left .profile-form-left-row .contact-form .contact-form-row i {
  width: 35px;
  text-align: left;
  float: left;
  color: #105c9d;
  font-size: 20px;
  line-height: 25px;
}
#connect-lab .connect-lab .profile-left-section .profile-form-left .profile-form-left-row .contact-form .contact-form-row span {
  line-height: 25px;
}
#connect-lab .connect-lab .profile-left-section .profile-form-left .change-profile-picture {
  width: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  min-height: 200px;
}
#connect-lab .connect-lab .profile-left-section .profile-form-left .change-profile-picture img {
  width: 100%;
}
#connect-lab .connect-lab .profile-right-section {
  width: 97%;
  float: right;
  margin-right: 25px;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one {
  width: 100%;
  float: left;
  background: #fff;
  padding: 45px 0px 82px;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right {
  width: 100%;
  float: left;
  display: inline-block;
  margin-bottom: 10px;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right h3 {
  width: 100%;
  float: left;
  font-size: 18px;
  color: #105c9d;
  margin-bottom: 20px;
  margin-top: 27px;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right p {
  width: 100%;
  float: left;
  font-size: 15px;
  line-height: 20px;
  color: #a5a3a3;
  margin-bottom: 10px;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .jobs-div {
  width: 60%;
  float: left;
  margin-left: 10px;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .jobs-div .jobs-div-row {
  width: 50%;
  float: left;
  margin: 1px 0px;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .jobs-div .jobs-div-row label {
  width: 100%;
  margin-right: 2.5%;
  float: left;
  font-weight: normal;
  font-size: 14px;
  margin-top: 5px;
  color: #a5a3a3;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .jobs-div .jobs-div-row label input[type="checkbox"].notCheckedInput {
  float: left;
  width: 15px;
  margin: 2px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid #8edddd;
  outline: none;
  -webkit-appearance: inherit;
  padding: 7px;
  border-radius: 2px;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .jobs-div .jobs-div-row label input[type="checkbox"].checkedInput {
  float: left;
  width: 15px;
  margin: 2px 10px 0 0;
  height: 15px;
  background: #105c9d;
  border: 2px solid #105c9d;
  outline: 0;
  -webkit-appearance: inherit;
  position: relative;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 7px;
  border-radius: 4px;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .jobs-div .jobs-div-row label input[type="checkbox"].checkedInput:after {
  content: '\f00c';
  position: absolute;
  top: 1px;
  left: 1px;
  font-size: 12px;
  color: #fff;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .jobs-div .jobs-div-row .delivery-section {
  width: 40%;
  float: right;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .jobs-div .jobs-div-row .delivery-section input {
  width: 30%;
  float: left;
  text-align: center;
  padding: 6px 0px;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .jobs-div .jobs-div-row .delivery-section span {
  width: 70%;
  margin-bottom: -1px;
  float: right;
  color: #dcdada;
  font-size: 16px;
  text-align: right;
  line-height: 39px;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .select-days-div {
  width: 100%;
  float: left;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .select-days-div .jobs-div-row {
  width: 60%;
  float: left;
  padding-left: 0px;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .select-days-div .jobs-div-row label {
  width: auto;
  position: relative;
  float: left;
  font-weight: 300;
  font-size: 18px;
  margin-top: 0px;
  color: #dcdada;
  line-height: 39px;
  font-style: italic;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .select-days-div .jobs-div-row .delivery-section {
  width: 63%;
  float: right;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .select-days-div .jobs-div-row .delivery-section label {
  width: 27%;
  float: left;
  text-align: center;
  padding: 1px 0 0px;
  font-size: 14px;
  color: #1f2532;
  font-style: normal;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .select-days-div .jobs-div-row .delivery-section span {
  width: auto;
  padding: 0 0px;
  font-style: italic;
  text-align: center;
  margin-bottom: -1px;
  font-weight: 300;
  float: left;
  color: #dcdada;
  font-size: 16px;
  line-height: 39px;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .document-div-left {
  width: 100%;
  margin-top: 3px;
  margin-bottom: 6px;
  float: left;
  max-width: 60%;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .document-div-left .upload-file-name {
  margin-top: 0px;
  color: #1f2532;
  padding: 0px;
  margin-bottom: 0px;
  width: auto;
  float: left;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .document-div-left .upload-file-name i {
  float: left;
  margin: 5px;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .document-div-left .upload-file-name + a {
  width: 100%;
  float: left;
}
#connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .document-div-left .upload-file-name + a .download-file-name {
  background: #105c9d;
  margin-top: 0px;
  color: #fff;
  padding: 0px 21px;
  margin-bottom: 0px;
  width: auto;
  float: left;
}
#connect-lab .connect-lab .profile-right-section .save-change-button {
  width: 100%;
  float: left;
}
#connect-lab .connect-lab .profile-right-section .save-change-button button {
  width: auto;
  float: right;
  text-transform: uppercase;
  padding: 10px 15px;
  margin: 20px 0 38px;
  color: #fff;
  background: #105c9d;
  border: 0px;
  font-weight: normal;
  letter-spacing: 1px;
  font-size: 18px;
}
#connect-lab .connect-lab .profile-right-section .save-change-button button.redColor {
  background: #ff0515;
}
.pagination-center {
  width: 100%;
  float: left;
  display: inline-block;
  text-align: center;
}
.pagination-center ul {
  width: auto;
  float: none;
  display: inline-block;
}
.pagination-center ul li a {
  color: #1f2532;
}
.pagination-center ul li a:focus,
.pagination-center ul li a:hover {
  background-color: #1f2532;
  border-color: #1f2532;
  color: #fff;
}
.pagination-center ul li.active a {
  background-color: #1f2532;
  border-color: #1f2532;
}
.pagination-center ul li.active a:focus,
.pagination-center ul li.active a:hover {
  background-color: #1f2532;
  border-color: #1f2532;
}
.pagination-center ul li.active a:focus span,
.pagination-center ul li.active a:hover span {
  background-color: #1f2532;
  border-color: #1f2532;
}
#invite-lab .modal-dialog {
  width: 700px;
}
#invite-lab .modal-dialog .modal-content {
  padding: 20px 40px 0px 40px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
#invite-lab .modal-dialog .modal-content .modal-header {
  border: 0px;
  padding: 0px;
}
#invite-lab .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  font-size: 30px;
  margin: -15px -25px;
}
#invite-lab .modal-dialog .modal-content .modal-header .close:focus {
  outline: none;
}
#invite-lab .modal-dialog .modal-content .modal-header h4.modal-title {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #105c9d;
  margin-bottom: 0px;
}
#invite-lab .modal-dialog .modal-content .modal-body {
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
}
#invite-lab .modal-dialog .modal-content .modal-body .add-clinic-form {
  width: 100%;
  float: left;
  margin-top: 0px;
}
#invite-lab .modal-dialog .modal-content .modal-body .add-clinic-form h5 {
  font-size: 18px;
  font-weight: bold;
  text-align: right;
  color: #105c9d;
  margin-bottom: 0px;
  line-height: 34px;
}
#invite-lab .modal-dialog .modal-content .modal-body .add-clinic-form p {
  text-align: left;
  margin: 0 0 20px;
  width: 100%;
  float: left;
  line-height: 18px;
}
#invite-lab .modal-dialog .modal-content .modal-footer {
  border: 0;
  margin: 0 0 10px;
}
#invite-lab .modal-dialog .modal-content .modal-footer .btn.btn-default {
  border-radius: 0px;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 10px 10px;
  color: #105c9d;
}
#invite-lab .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#invite-lab .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#invite-lab .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close {
  background: #105c9d;
  color: #fff;
}
#navBar .newOrder.add-clinics {
  float: left;
  border-left: solid 0px #979797;
  border-right: solid 1px #979797;
  padding-left: 0;
  padding-right: 10px;
  margin-right: 10px;
}
#add-lab-modal .modal-dialog {
  width: 800px;
}
#add-lab-modal .modal-dialog .modal-content {
  padding: 20px 40px 20px 70px;
  border-radius: 0px;
  background: #fff;
}
#add-lab-modal .modal-dialog .modal-content .modal-header {
  border: 0px;
}
#add-lab-modal .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  font-size: 30px;
  margin: -15px -25px;
}
#add-lab-modal .modal-dialog .modal-content .modal-header .close:focus {
  outline: none;
}
#add-lab-modal .modal-dialog .modal-content .modal-header h4.modal-title {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}
#add-lab-modal .modal-dialog .modal-content .modal-body {
  text-align: left;
}
#add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form {
  width: 100%;
  float: left;
  margin-bottom: 55px;
  margin-top: 20px;
}
#add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row {
  width: 100%;
  float: left;
  margin-bottom: 55px;
}
#add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4 {
  color: #105c9d;
  margin-bottom: 20px;
}
#add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row input {
  width: 230px;
  float: left;
  padding: 8px 10px;
  font-size: 12px;
  text-align: center;
  border: solid 1px #979797;
  min-height: 38px;
}
#add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row input:focus {
  outline: none;
}
#add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block {
  width: 35%;
  float: left;
  margin-right: 5%;
  margin-top: -15px;
}
#add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block label {
  width: 100%;
  float: left;
  color: #dcdada;
  font-weight: 300;
  font-style: italic;
}
#add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.postcode-input {
  width: 15%;
}
#add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.postcode-input input {
  width: 100%;
}
#add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .add-clinic-form-col {
  width: 33%;
  float: left;
}
#add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .add-clinic-form-col input {
  width: 87%;
}
#add-lab-modal .modal-dialog .modal-content .modal-footer {
  border: 0;
  margin: 30px 0 20px;
}
#add-lab-modal .modal-dialog .modal-content .modal-footer .btn.btn-default {
  border-radius: 0px;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 10px 45px;
  color: #105c9d;
}
#add-lab-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#add-lab-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#add-lab-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close {
  background: #105c9d;
  color: #fff;
}
.reports {
  width: 243px;
  height: 35px;
  background-color: #e2dbdb;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.profile-right-block-two #manage-labs .manage-labs .categories-section .category-section-block select.tech-admin-options {
  margin: 0;
}
.profile-right-block-two #manage-labs .manage-labs #order-list .order-list {
  border: 0;
  box-shadow: none;
}
.profile-right-block-two #manage-labs .manage-labs #order-list .order-list .order-list-hading {
  background: #eee;
  color: #1f2532;
  font-size: 16px;
}
.profile-right-block-two #manage-labs .manage-labs #order-list .order-list .order-list-content {
  max-height: 400px;
  overflow-y: auto;
}
.profile-right-block-two #manage-labs .manage-labs #order-list .order-list .order-list-content::-webkit-scrollbar {
  width: 5px;
}
.profile-right-block-two #manage-labs .manage-labs #order-list .order-list .order-list-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #eee;
}
.profile-right-block-two #manage-labs .manage-labs #order-list .order-list .order-list-content::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.profile-right-block-two #manage-labs .manage-labs #order-list .order-list .order-list-content .mobi-manage-content .order-list-content p {
  font-weight: normal;
}
.profile-right-block-two #manage-labs .manage-labs #order-list .order-list .order-list-content .mobi-manage-content .order-list-content:nth-child(2n) {
  background: #eee;
}
.float-none {
  float: none !important;
}
.float-none .chooseLAb {
  cursor: pointer;
}
.w-70 {
  width: 70%;
}
.area-23 {
  height: 23px;
  width: 23px;
}
#navBar .newOrder.add-users {
  float: left;
  border-left: solid 0px #979797;
  border-right: solid 1px #979797;
  padding-left: 0;
  padding-right: 10px;
  margin-right: 10px;
}
#users {
  width: 100%;
  float: left;
  margin-top: 250px;
}
#users .users {
  width: 100%;
  float: left;
}
#users .users ul {
  width: 100%;
  float: left;
}
#users .users ul li {
  width: 29.3%;
  float: left;
  margin: 0 2% 30px;
  background: #fff;
  -webkit-box-shadow: 0 0 5px #a5a3a3;
  -moz-box-shadow: 0 0 5px #a5a3a3;
  box-shadow: 0 0 5px #a5a3a3;
  padding-top: 14px;
}
#users .users ul li .clinic-block {
  width: 100%;
  float: left;
}
#users .users ul li .clinic-block .delete-clinic {
  cursor: pointer;
  width: auto;
  float: right;
  margin-right: 17px;
  position: relative;
  z-index: 9;
}
#users .users ul li .clinic-block .clinic-img {
  width: 100%;
  float: left;
  text-align: center;
  display: inline-block;
}
#users .users ul li .clinic-block .clinic-img .round-img-box {
  width: auto;
  float: none;
  display: inline-block;
  border: solid 1px #dcdada;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  padding: 0px;
  position: relative;
}
#users .users ul li .clinic-block .clinic-img .round-img-box img {
  width: 80px;
  border-radius: 100%;
  height: 80px;
  object-fit: cover;
}
#users .users ul li .clinic-block .clinic-img .round-img-box .round-active-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #6ed803;
  float: left;
  margin: 3px 10px 0 0;
  position: absolute;
  bottom: 0px;
  right: -8px;
}
#users .users ul li .clinic-block .clinic-img .round-img-box .round-not-active-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #ff9f00;
  float: left;
  margin: 3px 10px 0 0;
  position: absolute;
  bottom: 0px;
  right: -8px;
}
#users .users ul li .clinic-block .clinic-img .round-img-box .round-inactive-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #d0021b;
  float: left;
  margin: 2px 10px 0 0;
  position: absolute;
  bottom: 0px;
  right: -8px;
}
#users .users ul li .clinic-block .delete-clinic + .clinic-img {
  margin-top: -15px;
}
#users .users ul li .clinic-block h4 {
  width: 100%;
  float: left;
  text-align: center;
  color: #105c9d;
  margin-top: 15px;
}
#users .users ul li .clinic-block h3 {
  width: 100%;
  float: left;
  text-align: center;
  color: #4a4a4a;
  margin-top: 15px;
  margin-top: -5px;
  font-size: 16px;
  margin-bottom: -10px;
}
#users .users ul li .clinic-block p {
  width: 100%;
  float: left;
  text-align: center;
  color: #a5a3a3;
  font-weight: 300;
  font-size: 13px;
}
#users .users ul li .clinic-block .clinic-activation-block {
  width: 100%;
  float: left;
  background: #dcdada;
  padding: 20px;
  margin-top: 20px;
}
#users .users ul li .clinic-block .clinic-activation-block .clinic-active {
  width: auto;
  float: left;
  text-transform: uppercase;
  font-size: 15px;
  position: relative;
  z-index: 8;
}
#users .users ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #6ed803;
  float: left;
  margin: 2px 10px 0 0;
}
#users .users ul li .clinic-block .clinic-activation-block .clinic-active .round-not-active-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #ff9f00;
  float: left;
  margin: 2px 10px 0 0;
}
#users .users ul li .clinic-block .clinic-activation-block .clinic-active .round-inactive-clinic {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #d0021b;
  float: left;
  margin: 2px 10px 0 0;
}
#users .users ul li .clinic-block .clinic-activation-block .clinic-active-button {
  width: auto;
  float: right;
}
#users .users ul li .clinic-block .clinic-activation-block .clinic-active-button a {
  color: #105c9d;
  border: solid 1px #105c9d;
  padding: 3px 5px;
  margin-left: 0px;
  font-size: 12px;
  text-transform: uppercase;
}
#users .users ul li .clinic-block .clinic-activation-block .clinic-active-button a:hover,
#users .users ul li .clinic-block .clinic-activation-block .clinic-active-button a:focus,
#users .users ul li .clinic-block .clinic-activation-block .clinic-active-button a.activation-tab-btn {
  color: #fff;
  background: #105c9d;
}
#add-lab-technician-modal .modal-dialog,
#remove-user-modal .modal-dialog {
  width: 800px;
}
#add-lab-technician-modal .modal-dialog .modal-content,
#remove-user-modal .modal-dialog .modal-content {
  padding: 20px 40px 20px 70px;
  border-radius: 0;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-header,
#remove-user-modal .modal-dialog .modal-content .modal-header {
  border: 0;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-header .close,
#remove-user-modal .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  font-size: 30px;
  margin: -15px -25px;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-header .close:focus,
#remove-user-modal .modal-dialog .modal-content .modal-header .close:focus {
  outline: none;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#remove-user-modal .modal-dialog .modal-content .modal-header h4.modal-title {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form {
  width: 100%;
  float: left;
  margin-top: 20px;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row {
  width: 100%;
  float: left;
  margin-bottom: 55px;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4 {
  color: #105c9d;
  margin-bottom: 20px;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row input,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row input {
  width: 100%;
  float: left;
  min-height: 37px;
  padding: 8px 10px;
  font-size: 12px;
  text-align: center;
  border: solid 1px #979797;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block {
  width: 28%;
  float: left;
  margin-right: 5%;
  margin-top: -15px;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul {
  width: 100%;
  float: left;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li {
  width: auto;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px 15px;
  background: #dcdada;
  cursor: pointer;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li.choose-clinic-tab,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li.choose-clinic-tab {
  background: #105c9d;
  color: #fff;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .street-block-select,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .street-block-select {
  line-height: 38px;
  min-height: 38px;
  width: 120%;
  font-size: 14px;
  height: 38px;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.full-street-div,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.full-street-div {
  width: 100%;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label {
  font-weight: normal;
  font-size: 14px;
  margin-top: 20px;
  /*input[type="checkbox"].notCheckedInput{
                float: left;
                width: 25px;
                margin: 0px 10px 0px 0px;
                height: 25px;
                background: none;
                border: 2px solid @sky-start-dark;
                outline: none;
                -webkit-appearance: inherit; padding: 10px; border-radius: 4px;
              }
              input[type="checkbox"].checkedInput{
                float: left;
                width: 25px;
                margin: 0px 10px 0px 0px;
                height: 25px;
                background: none;
                border: 2px solid @sky-start-dark;
                outline: none;
                -webkit-appearance: inherit; position: relative; font: normal normal normal 14px/1 FontAwesome; padding: 10px; border-radius: 4px;
              }
              input[type="checkbox"].checkedInput:after{
                content: '\f00c'; position: absolute; top: 2px; left: 2px; font-size: 15px; color: @sky-start-dark;
              }*/
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox],
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  position: relative;
  visibility: hidden;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox],
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox],
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox] + label::before,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox] + label::before {
  font-size: 18px;
  line-height: 20px;
  cursor: pointer;
  vertical-align: middle;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox] + label::before,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox] + label::before {
  content: '';
  text-align: center;
  display: inline-block;
  pointer-events: none;
  opacity: 1;
  color: black;
  transition: all 0.3s ease;
  border: 2px solid #105c9d;
  width: 23px;
  height: 23px;
  margin-right: 15px;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox] + label,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox] + label {
  line-height: 23px;
  margin: 0 15px 0 15px;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:hover,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:hover {
  cursor: pointer;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:hover + label::before,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:hover + label::before {
  content: '';
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:checked + label::before,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:checked + label::before {
  content: '\2714';
  font-size: 14px;
  color: #105c9d;
  text-align: center;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:checked:hover + label::before,
#remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type=checkbox]:checked:hover + label::before {
  opacity: 1;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-footer,
#remove-user-modal .modal-dialog .modal-content .modal-footer {
  border: 0;
  margin: 30px 0 20px;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
#remove-user-modal .modal-dialog .modal-content .modal-footer .btn.btn-default {
  border-radius: 0px;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 10px 45px;
  color: #105c9d;
}
#add-lab-technician-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#remove-user-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#add-lab-technician-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#remove-user-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#add-lab-technician-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close,
#remove-user-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close {
  background: #105c9d;
  color: #fff;
}
#add-lab-technician-modal .check-icons i,
#remove-user-modal .check-icons i {
  background: #105c9d;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  padding-top: 3px;
  text-align: center;
  position: absolute;
  top: 71px;
  right: 29px;
  font-size: 10px;
}
#manage-labs {
  width: 100%;
  float: left;
}
#manage-labs .container {
  margin-top: 180px;
  padding-bottom: 50px;
  background: #fff;
}
#manage-labs .manage-labs {
  width: 100%;
  float: left;
}
#manage-labs .manage-labs .actions-header {
  width: 100%;
  float: left;
  padding: 40px 20px 5px;
  border-bottom: solid 2px #4a4a4a;
  color: #4a4a4a;
  font-size: 20px;
  font-weight: 600;
}
#manage-labs .manage-labs .lab-logo-name {
  width: 100%;
  float: left;
  margin-top: 45px;
  display: flex;
}
#manage-labs .manage-labs .lab-logo-name img {
  height: 60px;
  margin-right: 35px;
}
#manage-labs .manage-labs .lab-logo-name h2 {
  font-weight: bold;
  font-size: 32px;
  color: #1f2532;
  padding-top: 13px;
}
#manage-labs .manage-labs .categories-section {
  width: 100%;
  float: left;
}
#manage-labs .manage-labs .categories-section .section-header {
  width: 100%;
  float: left;
  padding: 40px 20px 0;
  color: #1f2532;
  font-size: 18px;
  font-weight: 500;
}
#manage-labs .manage-labs .categories-section .category-section-block {
  width: 100%;
  float: left;
}
#manage-labs .manage-labs .categories-section .category-section-block h4 {
  width: auto;
  float: left;
  padding: 5px 25px;
  margin: 20px 20px 5px 5px;
  color: #1f2532;
  font-weight: 600;
  line-height: 39px;
}
#manage-labs .manage-labs .categories-section .category-section-block select {
  width: 100%;
  float: left;
  padding: 4px 2px 4px 10px;
  margin: 5px;
  border: solid 1px #dcdada;
  color: #1f2532;
  font-weight: 600;
}
#manage-labs .manage-labs .categories-section .category-section-block select:focus {
  outline: none;
}
#manage-labs .manage-labs .categories-section .category-section-block select.tech-admin-options {
  width: 220px;
  padding: 8px 10px 8px 10px;
  background: #f4f4f4;
  color: #4a4a4a;
  font-weight: normal;
}
#manage-labs .manage-labs .categories-section .category-section-block select.tech-admin-options + button {
  padding: 3px 10px;
  margin: 11px !important;
  font-size: 12px;
}
#manage-labs .manage-labs .categories-section .category-section-block select.tech-admin-options + button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
#manage-labs .manage-labs .categories-section .category-section-block ul {
  width: auto;
  float: left;
  margin-right: 40px;
}
#manage-labs .manage-labs .categories-section .category-section-block ul li {
  width: auto;
  float: left;
  padding: 4px 2px 4px 10px;
  height: 39px;
  margin: 5px;
  border: solid 1px #dcdada;
  color: #1f2532;
  font-weight: 600;
  cursor: pointer;
}
#manage-labs .manage-labs .categories-section .category-section-block ul li i {
  float: left;
  margin-top: 6px;
}
#manage-labs .manage-labs .categories-section .category-section-block ul li input {
  border: 0;
  background: 0;
  box-shadow: none;
  padding-left: 10px;
  font-weight: normal;
  color: #1f2532;
  line-height: 29px;
  height: 29px;
}
#manage-labs .manage-labs .categories-section .category-section-block ul li input:focus {
  outline: none;
}
#manage-labs .manage-labs .categories-section .category-section-block button {
  width: auto;
  float: left;
  padding: 5px 20px;
  margin: 5px;
  border: solid 1px #1f2532;
  color: #1f2532;
  background: none;
  font-weight: 600;
}
#manage-labs .manage-labs .categories-section .category-section-block button i {
  font-size: 22px;
  margin-top: 2px;
  float: left;
  margin-right: 15px;
}
#manage-labs .manage-labs .categories-section .category-section-block button.pull-right {
  padding: 2px 50px;
}
#manage-labs .manage-labs .categories-section .category-section-block .several-options {
  width: 180px;
  float: left;
  margin: 5px;
}
#manage-labs .manage-labs .categories-section .category-section-block .several-options select {
  padding: 11px !important;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent {
  width: 100%;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent button.dropdown-toggle {
  width: 100%;
  float: left;
  padding: 5px 6px;
  margin-left: 0;
  text-align: center;
  border: solid 1px #a5a3a3;
  color: #a5a3a3;
  font-size: 14px;
  background: #eee;
  border-radius: 3px;
  box-shadow: none;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent button.dropdown-toggle .caret {
  float: right;
  margin-top: 8px;
  border-top: 6px dashed;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent ul.dropdown-menu.dropdown-menu-form {
  width: 100%;
  padding: 0;
  margin: 0;
  background: #eee;
  border-radius: 0;
  max-height: 40vh;
  z-index: 10;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent ul.dropdown-menu.dropdown-menu-form::-webkit-scrollbar-track {
  background-color: #ccc;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent ul.dropdown-menu.dropdown-menu-form::-webkit-scrollbar {
  width: 6px;
  background-color: #ccc;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent ul.dropdown-menu.dropdown-menu-form::-webkit-scrollbar-thumb {
  background-color: #105c9d;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent ul.dropdown-menu.dropdown-menu-form li {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
  border-bottom: solid 1px #fff;
  color: #105c9d;
  font-weight: 600;
  cursor: pointer;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent ul.dropdown-menu.dropdown-menu-form li:nth-child(1),
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent ul.dropdown-menu.dropdown-menu-form li:nth-child(2) {
  width: 50%;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent ul.dropdown-menu.dropdown-menu-form li:nth-child(1) a,
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent ul.dropdown-menu.dropdown-menu-form li:nth-child(2) a {
  padding: 8px 0;
  font-size: 12px;
  width: 100%;
  float: left;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent ul.dropdown-menu.dropdown-menu-form li:nth-child(3) {
  display: none;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a {
  width: 100%;
  float: left;
  white-space: inherit;
  padding: 8px 20px;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox {
  margin: 0;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox label {
  display: flex;
  align-items: center;
  padding: 0;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox label input[type="checkbox"]:not(:checked) {
  margin: 0 20px 0 0;
  border: 1px solid #ccc;
  outline: none;
  -webkit-appearance: inherit;
  padding: 10px;
  position: static;
  cursor: pointer;
  display: inline;
  border-radius: 3px;
  background: #fff;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox label input[type="checkbox"]:checked {
  margin: 0 20px 0 0;
  border: 1px solid #105c9d;
  outline: none;
  cursor: pointer;
  -webkit-appearance: inherit;
  position: relative;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 10px;
  display: inline;
  background: #105c9d;
  border-radius: 3px;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox label input[type="checkbox"]:checked:after {
  content: '\f00c';
  position: absolute;
  top: 5px;
  left: 4px;
  font-size: 12px;
  color: #fff;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a:hover {
  color: #105c9d;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a:focus {
  outline: none;
}
#manage-labs .manage-labs .categories-section .category-section-block .multiselect-parent ul.dropdown-menu.dropdown-menu-form li.active a {
  background: #ddd;
  color: #000;
  padding: 8px 20px;
}
#manage-labs .manage-labs .categories-section .category-section-block .main-category-list {
  width: 100%;
  float: left;
}
#manage-labs .manage-labs .categories-section .category-section-block .main-category-list .main-category-list-item {
  width: 100%;
  float: left;
}
#manage-labs .manage-labs .categories-section .category-section-block .selected-category-item {
  width: auto;
  float: left;
  padding: 8px 30px;
  margin: 5px;
  border: solid 1px #1f2532;
  color: #1f2532;
  background: none;
  font-weight: 600;
  border-radius: 0;
  cursor: pointer;
}
#manage-labs .manage-labs .categories-section.edit-labs-select {
  margin-top: 30px;
}
#manage-labs .manage-labs .categories-section.edit-labs-select .category-section-block select {
  padding: 8px 10px;
  margin: 0;
}
#manage-labs .manage-labs .lab-report-section {
  width: 100%;
  float: left;
  margin-top: 60px;
}
#manage-labs .manage-labs .lab-report-section .lab-report-head {
  width: 100%;
  float: left;
  background: #105c9d;
  padding: 7px 0px;
}
#manage-labs .manage-labs .lab-report-section .lab-report-head .first-sec {
  width: 40%;
  font-weight: bold;
  float: left;
  border-right: solid 1px #fff;
  text-align: center;
  font-size: 18px;
  color: #fff;
}
#manage-labs .manage-labs .lab-report-section .lab-report-head .first-sec span {
  width: auto;
  display: inline-block;
}
#manage-labs .manage-labs .lab-report-section .lab-report-head .first-sec span i {
  float: left;
  top: -3px;
  font-size: 15px;
  position: relative;
}
#manage-labs .manage-labs .lab-report-section .lab-report-head .first-sec span i + i {
  top: 5px;
  right: -1px;
}
#manage-labs .manage-labs .lab-report-section .lab-report-head .second-sec {
  width: 15%;
  font-weight: bold;
  float: left;
  border-right: solid 1px #fff;
  text-align: center;
  font-size: 18px;
  color: #fff;
}
#manage-labs .manage-labs .lab-report-section .lab-report-head .third-sec {
  width: 15%;
  font-weight: bold;
  float: left;
  border-right: solid 1px #fff;
  text-align: center;
  font-size: 18px;
  color: #fff;
}
#manage-labs .manage-labs .lab-report-section .lab-report-head .fourth-sec {
  width: 15%;
  font-weight: bold;
  float: left;
  border-right: solid 1px #fff;
  text-align: center;
  font-size: 18px;
  color: #fff;
}
#manage-labs .manage-labs .lab-report-section .lab-report-head .fifth-sec {
  width: 15%;
  font-weight: bold;
  float: left;
  text-align: center;
  font-size: 18px;
  color: #fff;
}
#manage-labs .manage-labs .lab-report-section .lab-report-head.text-color-white {
  color: #fff;
}
#manage-labs .manage-labs .lab-report-section .lab-report-body {
  width: 100%;
  float: left;
}
#manage-labs .manage-labs .lab-report-section .lab-report-body .repeated-div-report {
  background: #fff;
  padding: 12px 0px;
  border-bottom: solid 1px #d4d4d4;
  width: 100%;
  float: left;
  cursor: pointer;
}
#manage-labs .manage-labs .lab-report-section .lab-report-body .repeated-div-report .first-sec {
  width: 40%;
  float: left;
  text-align: center;
  color: #1f2532;
  font-weight: normal;
}
#manage-labs .manage-labs .lab-report-section .lab-report-body .repeated-div-report .first-sec .one-by-two {
  width: 50%;
  float: left;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
}
#manage-labs .manage-labs .lab-report-section .lab-report-body .repeated-div-report .first-sec .one-by-two img {
  height: 40px;
  width: auto;
}
#manage-labs .manage-labs .lab-report-section .lab-report-body .repeated-div-report .first-sec .one-by-two.public-lab {
  width: 100% !important;
}
#manage-labs .manage-labs .lab-report-section .lab-report-body .repeated-div-report .first-sec .one-by-two + .one-by-two {
  margin-top: 13px;
}
#manage-labs .manage-labs .lab-report-section .lab-report-body .repeated-div-report .second-sec {
  width: 15%;
  float: left;
  text-align: center;
  font-size: 13px;
  overflow: hidden;
  margin-top: 13px;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  max-height: 48px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #1f2532;
  font-weight: normal;
}
#manage-labs .manage-labs .lab-report-section .lab-report-body .repeated-div-report .third-sec {
  width: 15%;
  float: left;
  text-align: left;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 13px;
  padding: 0 15px;
  color: #1f2532;
  font-weight: normal;
}
#manage-labs .manage-labs .lab-report-section .lab-report-body .repeated-div-report .fourth-sec {
  width: 15%;
  float: left;
  text-align: center;
}
#manage-labs .manage-labs .lab-report-section .lab-report-body .repeated-div-report .fourth-sec input[type="button"] {
  width: auto;
  display: inline-block;
  padding: 5px 20px;
  margin: 5px;
  border: solid 1px #1f2532;
  color: #1f2532;
  background: none;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
}
#manage-labs .manage-labs .lab-report-section .lab-report-body .repeated-div-report .fifth-sec {
  width: 15%;
  float: left;
  text-align: center;
}
#manage-labs .manage-labs .lab-report-section .lab-report-body .repeated-div-report .fifth-sec input[type="button"] {
  width: auto;
  display: inline-block;
  padding: 5px 20px;
  margin: 5px;
  border: solid 1px #1f2532;
  color: #1f2532;
  background: none;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
}
#manage-labs .manage-labs .lab-report-section .lab-report-body .repeated-div-report .disabled-lab {
  width: 45%;
  float: left;
  text-align: center;
  font-size: 18px;
  overflow: hidden;
  margin-top: 10px;
  padding: 0 5px;
  color: #d0021b;
  text-overflow: ellipsis;
  font-weight: 500;
}
#manage-labs .manage-labs .lab-report-section .lab-report-body .repeated-div-report .head-bg {
  background: #dcdada;
}
#manage-labs .manage-labs .no-data-found {
  text-align: center;
}
#manage-labs .manage-labs .lab-availability {
  width: 100%;
  float: left;
  margin-top: 45px;
}
#manage-labs .manage-labs .lab-availability .head-lab-avail {
  width: 100%;
  float: left;
}
#manage-labs .manage-labs .lab-availability .head-lab-avail ul {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
}
#manage-labs .manage-labs .lab-availability .head-lab-avail ul li {
  float: left;
  line-height: 18px;
  width: 70px;
  text-align: left;
  font-size: 12px;
}
#manage-labs .manage-labs .lab-availability .head-lab-avail ul li:first-child {
  font-weight: bold;
}
#manage-labs .manage-labs .lab-availability .head-lab-avail ul li:nth-child(2) {
  text-align: center;
}
#manage-labs .manage-labs .lab-availability .body-lab-avail {
  width: 100%;
  float: left;
}
#manage-labs .manage-labs .lab-availability .body-lab-avail ul {
  width: 100%;
  float: left;
  margin: 3px 0 0;
  padding: 0;
}
#manage-labs .manage-labs .lab-availability .body-lab-avail ul li {
  width: auto;
  float: left;
  height: 24px;
  min-width: 70px;
  text-align: center;
  border: solid 1px #dcdada;
  border-right: 0;
  font-size: 13px;
}
#manage-labs .manage-labs .lab-availability .body-lab-avail ul li:first-child {
  font-weight: bold;
}
#manage-labs .manage-labs .lab-availability .body-lab-avail ul li:last-child {
  border: 0;
}
#manage-labs .manage-labs .lab-availability .body-lab-avail ul li.border-0 {
  border: 0;
}
#manage-labs .manage-labs .lab-availability .body-lab-avail ul li ul {
  margin: 0;
}
#manage-labs .manage-labs .lab-availability .body-lab-avail ul li ul li {
  min-width: 32px;
}
#manage-labs .manage-labs .lab-availability .body-lab-avail ul li ul li:last-child {
  border: solid 1px #dcdada;
}
#manage-labs .manage-labs .lab-availability .body-lab-avail ul li ul li.available-date {
  background: #c6fed0;
  font-size: 0;
}
#manage-labs .manage-labs .lab-availability .body-lab-avail ul li ul li.unavailable-date {
  background: #fdc5c5;
  font-size: 0;
}
#manage-labs .manage-labs .lab-availability .body-lab-avail ul li ul li.white {
  background: #ffffff;
  font-size: 0;
}
#manage-labs .manage-labs .enable-disable-button button {
  width: auto;
  float: left;
  padding: 8px 25px;
  margin: 50px 5px 5px;
  border: solid 1px #1f2532;
  color: #1f2532;
  background: none;
  font-weight: 600;
  border-radius: 0;
}
#manage-labs .manage-labs .enable-disable-button button i {
  font-size: 22px;
  margin-top: 2px;
  float: left;
  margin-right: 15px;
}
#manage-labs .manage-labs .lab-info {
  width: 100%;
  float: left;
  margin-top: 65px;
  background: #fff;
}
#manage-labs .manage-labs .lab-info .lab-info-heading {
  width: 100%;
  float: left;
  background: #1f2532;
  line-height: 36px;
  font-weight: 500;
  color: #fff;
}
#manage-labs .manage-labs .lab-info .lab-info-item {
  width: 100%;
  float: left;
  line-height: 36px;
  border-bottom: solid 1px #dcdada;
  font-weight: 500;
  color: #a5a3a3;
  font-size: 14px;
}
#manage-labs .manage-labs .lab-info .lab-info-item:nth-child(2n) {
  background: #eee;
}
#manage-labs .manage-labs .edit-connected-labs-table {
  width: 100%;
  float: left;
  margin-top: 20px;
}
#manage-labs .manage-labs .edit-connected-labs-table .head-table {
  width: 100%;
  float: left;
  background: #eee;
  line-height: 36px;
  font-weight: 500;
  color: #1f2532;
  text-align: center;
}
#manage-labs .manage-labs .edit-connected-labs-table .body-table {
  width: 100%;
  float: left;
  margin-bottom: 25px;
  max-height: 450px;
  overflow-y: auto;
}
#manage-labs .manage-labs .edit-connected-labs-table .body-table::-webkit-scrollbar {
  width: 5px;
}
#manage-labs .manage-labs .edit-connected-labs-table .body-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
#manage-labs .manage-labs .edit-connected-labs-table .body-table::-webkit-scrollbar-thumb {
  background-color: #1f2532;
  outline: 1px solid slategrey;
}
#manage-labs .manage-labs .edit-connected-labs-table .body-table .repeat-lis {
  width: 100%;
  float: left;
  line-height: 30px;
  border-bottom: solid 1px #dcdada;
  color: #a5a3a3;
  font-size: 14px;
  text-align: center;
}
#manage-labs .manage-labs .edit-connected-labs-table .body-table .repeat-lis:nth-child(2n) {
  background: #eee;
}
#manage-labs .manage-labs .edit-connected-labs-table .body-table .repeat-lis input {
  width: 100%;
  float: left;
  min-height: 20px;
  line-height: 20px;
  margin-top: 4px;
  border-radius: 3px;
  border: solid 1px #ccc;
  padding: 0 15px;
  box-sizing: border-box;
}
#manage-labs .manage-labs .edit-connected-labs-table .body-table input[type="checkbox"]:not(:checked) {
  border: 1px solid #105c9d;
  outline: none;
  -webkit-appearance: inherit;
  position: static;
  cursor: pointer;
  display: inline;
  float: left;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 10px 0 0;
}
#manage-labs .manage-labs .edit-connected-labs-table .body-table input[type="checkbox"]:checked {
  border: 1px solid #105c9d;
  outline: none;
  cursor: pointer;
  -webkit-appearance: inherit;
  position: relative;
  font: normal normal normal 14px/1 FontAwesome;
  display: inline;
  background: #105c9d;
  float: left;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 10px 0 0;
}
#manage-labs .manage-labs .edit-connected-labs-table .body-table input[type="checkbox"]:checked:after {
  content: '\f00c';
  position: absolute;
  top: 7px;
  left: 7px;
  font-size: 16px;
  color: #fff;
}
#manage-labs .manage-labs .edit-connected-labs-table .body-table label.checkbox-style {
  width: auto;
  margin: 3px 0;
}
#manage-labs .manage-labs .edit-connected-labs-table .body-table input[type="checkbox"]:checked + label:before,
#manage-labs .manage-labs .edit-connected-labs-table .body-table input.checkedInput + label:before,
#manage-labs .manage-labs .edit-connected-labs-table .body-table input.chooseLAb.active[type="radio"] + label:before {
  z-index: 8;
}
#manage-labs .manage-labs .edit-connected-labs-table button {
  width: auto;
  float: right;
  padding: 8px 30px;
  margin: 5px;
  border: solid 1px #1f2532;
  color: #1f2532;
  background: none;
  font-weight: 600;
  border-radius: 0;
}
#manage-labs .manage-labs .edit-connected-labs-table button i {
  font-size: 20px;
  margin-top: 0px;
  float: right;
  margin-left: 30px;
}
#manage-labs .manage-labs .edit-connected-labs-table button + button {
  margin-right: 50px;
}
#connect-to-lab-modal .modal-dialog,
#three-shape-upload-modal .modal-dialog,
#three-shape-scan-view .modal-dialog {
  width: 700px;
  background: #fff;
  overflow: hidden;
}
#connect-to-lab-modal .modal-dialog .modal-header,
#three-shape-upload-modal .modal-dialog .modal-header,
#three-shape-scan-view .modal-dialog .modal-header {
  border: 0;
}
#connect-to-lab-modal .modal-dialog .modal-header h4,
#three-shape-upload-modal .modal-dialog .modal-header h4,
#three-shape-scan-view .modal-dialog .modal-header h4 {
  color: #1f2532;
}
#connect-to-lab-modal .modal-dialog .lab-search-input,
#three-shape-upload-modal .modal-dialog .lab-search-input,
#three-shape-scan-view .modal-dialog .lab-search-input {
  width: 100%;
  float: left;
  margin: 25px 0;
  position: relative;
}
#connect-to-lab-modal .modal-dialog .lab-search-input input,
#three-shape-upload-modal .modal-dialog .lab-search-input input,
#three-shape-scan-view .modal-dialog .lab-search-input input {
  width: 300px;
  float: left;
  border: solid 1px #eee;
  line-height: 42px;
  padding: 0 20px 0 60px;
  height: 44px;
}
#connect-to-lab-modal .modal-dialog .lab-search-input i,
#three-shape-upload-modal .modal-dialog .lab-search-input i,
#three-shape-scan-view .modal-dialog .lab-search-input i {
  position: absolute;
  left: 18px;
  top: 12px;
}
#connect-to-lab-modal .modal-dialog .lab-list-heading,
#three-shape-upload-modal .modal-dialog .lab-list-heading,
#three-shape-scan-view .modal-dialog .lab-list-heading {
  width: 100%;
  float: left;
  background: #eee;
  border-bottom: solid 1px #d4d4d4;
  display: flex;
  align-items: center;
  height: 45px;
  justify-content: center;
  color: #000;
  font-weight: bold;
}
#connect-to-lab-modal .modal-dialog .lab-list-heading .font-manage,
#three-shape-upload-modal .modal-dialog .lab-list-heading .font-manage,
#three-shape-scan-view .modal-dialog .lab-list-heading .font-manage {
  text-align: center;
}
#connect-to-lab-modal .modal-dialog .lab-list-box,
#three-shape-upload-modal .modal-dialog .lab-list-box,
#three-shape-scan-view .modal-dialog .lab-list-box {
  max-height: 45vh;
  float: left;
  z-index: 999999;
  position: relative;
  overflow-y: auto;
}
#connect-to-lab-modal .modal-dialog .lab-list-box .lab-list-content,
#three-shape-upload-modal .modal-dialog .lab-list-box .lab-list-content,
#three-shape-scan-view .modal-dialog .lab-list-box .lab-list-content {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  height: 45px;
  justify-content: center;
  border-bottom: solid 1px #d4d4d4;
}
#connect-to-lab-modal .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content,
#three-shape-upload-modal .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content,
#three-shape-scan-view .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content {
  text-align: center;
}
#connect-to-lab-modal .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style,
#three-shape-upload-modal .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style,
#three-shape-scan-view .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style {
  width: 24px;
  font-weight: normal;
  font-size: 14px;
  position: relative;
  height: 24px;
  display: inline-block;
  margin: 0;
  float: none;
}
#connect-to-lab-modal .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style label,
#three-shape-upload-modal .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style label,
#three-shape-scan-view .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style label {
  color: #1f2532 !important;
  margin-left: 0 !important;
  font-weight: 400;
}
#connect-to-lab-modal .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style input[type="checkbox"],
#three-shape-upload-modal .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style input[type="checkbox"],
#three-shape-scan-view .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style input[type="checkbox"] {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 11;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}
#connect-to-lab-modal .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style input[type="checkbox"] + label,
#three-shape-upload-modal .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style input[type="checkbox"] + label,
#three-shape-scan-view .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style input[type="checkbox"] + label {
  position: relative;
  padding-left: 30px;
  line-height: 23px;
  float: left;
}
#connect-to-lab-modal .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style input[type="checkbox"] + label:after,
#three-shape-upload-modal .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style input[type="checkbox"] + label:after,
#three-shape-scan-view .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style input[type="checkbox"] + label:after {
  width: 23px;
  height: 23px;
  content: '';
  border: solid 2px #d4d4d4;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  border-radius: 3px;
}
#connect-to-lab-modal .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style input[type="checkbox"]:checked + label:after,
#three-shape-upload-modal .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style input[type="checkbox"]:checked + label:after,
#three-shape-scan-view .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style input[type="checkbox"]:checked + label:after {
  border-color: #105c9d;
}
#connect-to-lab-modal .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style input[type="checkbox"]:checked + label:before,
#three-shape-upload-modal .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style input[type="checkbox"]:checked + label:before,
#three-shape-scan-view .modal-dialog .lab-list-box .lab-list-content .mobi-manage-content label.checkbox-style input[type="checkbox"]:checked + label:before {
  content: '\2714';
  font-size: 14px;
  text-align: center;
  color: #fff !important;
  background: #105c9d;
  width: 23px;
  height: 23px;
  position: absolute;
  left: 0;
  border-radius: 3px;
  z-index: 10;
}
#connect-to-lab-modal .modal-dialog .lab-list-box .lab-list-content:nth-child(2n),
#three-shape-upload-modal .modal-dialog .lab-list-box .lab-list-content:nth-child(2n),
#three-shape-scan-view .modal-dialog .lab-list-box .lab-list-content:nth-child(2n) {
  background: #eee;
}
#connect-to-lab-modal .modal-dialog .lab-list-box .lab-list-content:hover,
#three-shape-upload-modal .modal-dialog .lab-list-box .lab-list-content:hover,
#three-shape-scan-view .modal-dialog .lab-list-box .lab-list-content:hover {
  background: #dcdada;
}
#connect-to-lab-modal .modal-dialog .lab-list-box::-webkit-scrollbar,
#three-shape-upload-modal .modal-dialog .lab-list-box::-webkit-scrollbar,
#three-shape-scan-view .modal-dialog .lab-list-box::-webkit-scrollbar {
  width: 5px;
}
#connect-to-lab-modal .modal-dialog .lab-list-box::-webkit-scrollbar-track,
#three-shape-upload-modal .modal-dialog .lab-list-box::-webkit-scrollbar-track,
#three-shape-scan-view .modal-dialog .lab-list-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
#connect-to-lab-modal .modal-dialog .lab-list-box::-webkit-scrollbar-thumb,
#three-shape-upload-modal .modal-dialog .lab-list-box::-webkit-scrollbar-thumb,
#three-shape-scan-view .modal-dialog .lab-list-box::-webkit-scrollbar-thumb {
  background-color: #1f2532;
  outline: 1px solid slategrey;
}
#connect-to-lab-modal .modal-dialog .modal-footer,
#three-shape-upload-modal .modal-dialog .modal-footer,
#three-shape-scan-view .modal-dialog .modal-footer {
  float: right;
}
#connect-to-lab-modal .modal-dialog .modal-footer button,
#three-shape-upload-modal .modal-dialog .modal-footer button,
#three-shape-scan-view .modal-dialog .modal-footer button {
  width: auto;
  float: right;
  padding: 8px 32px;
  margin: 20px 5px 12px;
  border: solid 1px #1f2532;
  color: #1f2532;
  background: none;
  font-weight: 600;
}
@media (max-width: 767px) {
  #manage-labs .container {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  #manage-labs .manage-labs .categories-section .category-section-block ul {
    margin-right: 0;
  }
  #manage-labs .manage-labs .categories-section .category-section-block ul li input {
    width: 88%;
  }
  #manage-labs .manage-labs .lab-report-section,
  #manage-labs .manage-labs .lab-availability,
  #manage-labs .manage-labs .edit-connected-labs-table {
    width: 100%;
    overflow-x: auto;
  }
  #manage-labs .manage-labs .lab-report-section .manage-scroll-mobile,
  #manage-labs .manage-labs .lab-availability .manage-scroll-mobile,
  #manage-labs .manage-labs .edit-connected-labs-table .manage-scroll-mobile {
    width: 1170px;
  }
  #manage-labs .manage-labs .lab-logo-name img {
    margin-right: 10px;
  }
  #manage-labs .manage-labs .lab-logo-name h2 {
    font-size: 18px;
  }
  #manage-labs .manage-labs .lab-info .lab-info-item {
    line-height: 24px;
    font-size: 12px;
  }
  #connect-to-lab-modal .modal-dialog {
    width: 94%;
  }
}
.labRanking {
  margin-bottom: 10px;
}
.labRanking input {
  margin-left: 15px;
}
.lab-avail-month input {
  width: 100%;
  float: left;
}
.lab-avail-month i {
  position: absolute;
  top: 10px;
  right: 10px;
}
.input-margin {
  margin: 6px 0 30px;
}
.lab-avail-month-dropdown-menu {
  top: 30px;
  width: 120%;
}
.lab-avail-month-dropdown-menu ul {
  max-height: 300px;
  overflow-y: auto;
}
.lab-avail-month-dropdown-menu li .checkbox-style {
  padding: 10px 20px;
  width: 100%;
  background: #eee;
  margin-bottom: 0;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}
.lab-avail-month-dropdown-menu li span {
  background: #ddd;
  padding: 15px;
  float: left;
  width: 50%;
  position: relative;
  border-right: 2px solid #eee;
}
.lab-avail-month-dropdown-menu li span input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
#connect-to-lab {
  width: 100%;
  float: left;
  margin-top: 150px;
  margin-bottom: 20px;
}
#connect-to-lab .connect-to-lab {
  width: 100%;
  float: left;
}
#connect-to-lab .connect-to-lab .search-box-lab {
  width: 100%;
  float: left;
  padding: 0 3px;
  margin-bottom: 30px;
}
#connect-to-lab .connect-to-lab .search-box-lab .search-input {
  width: 82%;
  float: left;
  position: relative;
  background: #fff;
}
#connect-to-lab .connect-to-lab .search-box-lab .search-input input[type="search"] {
  width: 100%;
  float: right;
  padding: 0 65px;
  border-radius: 3px;
  border: solid 1px #f4f4f4;
  height: 55px;
  line-height: 55px;
  background: #fff;
  box-shadow: 1px 2px 3px #dcdada;
}
#connect-to-lab .connect-to-lab .search-box-lab .search-input input[type="search"]:focus {
  outline: none;
}
#connect-to-lab .connect-to-lab .search-box-lab .search-input i.fa.fa-search {
  font-size: 20px;
  left: 18px;
  top: 17px;
  position: absolute;
}
#connect-to-lab .connect-to-lab .search-box-lab .search-input i.fa.fa-times {
  font-size: 20px;
  right: 18px;
  top: 17px;
  position: absolute;
}
#connect-to-lab .connect-to-lab .search-box-lab .search-input tags-input {
  display: block;
  position: absolute;
  left: 50px;
  border: 0;
  box-shadow: inherit;
  width: 89%;
  top: 6px;
}
#connect-to-lab .connect-to-lab .search-box-lab .search-input tags-input .tags {
  border: 0;
  box-shadow: inherit;
  height: 100%;
}
#connect-to-lab .connect-to-lab .search-box-lab input[type="button"] {
  width: auto;
  font-size: 18px;
  font-weight: bold;
  border-radius: 1px;
  padding: 0 38px;
  text-transform: uppercase;
  float: right;
  height: 55px;
  line-height: 55px;
  border: 0px;
  background: #105c9d;
  color: #fff;
  box-shadow: 1px 2px 3px #dcdada;
}
#connect-to-lab .connect-to-lab .lab-report-section {
  width: 100%;
  float: left;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-head {
  width: 100%;
  float: left;
  background: #105c9d;
  padding: 7px 0px;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-head .first-sec {
  width: 25%;
  font-weight: bold;
  float: left;
  border-right: solid 1px #fff;
  text-align: center;
  font-size: 18px;
  color: #fff;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-head .first-sec span {
  width: auto;
  display: inline-block;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-head .first-sec span i {
  float: left;
  top: -3px;
  font-size: 15px;
  position: relative;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-head .first-sec span i + i {
  top: 5px;
  right: -1px;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-head .second-sec {
  width: 20%;
  font-weight: bold;
  float: left;
  border-right: solid 1px #fff;
  text-align: center;
  font-size: 18px;
  color: #fff;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-head .third-sec {
  width: 15%;
  font-weight: bold;
  float: left;
  border-right: solid 1px #fff;
  text-align: center;
  font-size: 18px;
  color: #fff;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-head .fourth-sec {
  width: 40%;
  font-weight: bold;
  float: left;
  text-align: center;
  font-size: 18px;
  color: #fff;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-head .fourth-sec span {
  width: auto;
  display: inline-block;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-head .fourth-sec span i {
  float: left;
  top: -3px;
  font-size: 15px;
  position: relative;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-head .fourth-sec span i + i {
  top: 5px;
  right: -3px;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body {
  width: 100%;
  float: left;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report {
  background: #fff;
  padding: 12px 0px;
  border-bottom: solid 1px #d4d4d4;
  width: 100%;
  float: left;
  cursor: pointer;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .first-sec {
  width: 25%;
  float: left;
  text-align: center;
  color: #1f2532;
  font-weight: normal;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .first-sec .one-by-two {
  width: 50%;
  float: left;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .first-sec .one-by-two img {
  height: 40px;
  width: auto;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .first-sec .one-by-two.public-lab {
  width: 100% !important;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .first-sec .one-by-two + .one-by-two {
  margin-top: 13px;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .second-sec {
  width: 20%;
  float: left;
  text-align: center;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  max-height: 48px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #1f2532;
  font-weight: normal;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .third-sec {
  width: 15%;
  float: left;
  text-align: center;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 13px;
  padding: 0 5px;
  color: #1f2532;
  font-weight: normal;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .third-sec p.third-sec-day-name {
  width: auto;
  float: none;
  margin-right: 5px;
  position: relative;
  display: inline;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .third-sec p.third-sec-day-name:after {
  width: 2px;
  height: 2px;
  content: ',';
  position: absolute;
  right: -3px;
  bottom: 17px;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .third-sec p.third-sec-day-name:last-child:after {
  content: '';
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .fourth-sec {
  width: 40%;
  float: left;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .fourth-sec .one-by-third {
  width: 33.33%;
  float: left;
  font-size: 13px;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .fourth-sec .one-by-third .one-by-third-one {
  width: 100%;
  float: left;
  padding-right: 5px;
  margin-top: -2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #1f2532;
  font-weight: normal;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .fourth-sec .one-by-third .one-by-third-one input[type="button"] {
  width: auto;
  float: right;
  text-transform: uppercase;
  margin-right: 7px;
  padding: 0 8px;
  border: 0px;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  font-weight: 400;
  margin-top: 5px;
  color: #fff;
  background: #105c9d;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .fourth-sec .one-by-third .one-by-third-one input[type="button"].redColor {
  background: #ff0515;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .fourth-sec .one-by-third .one-by-third-one i {
  color: #105c9d;
  width: 15px;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .fourth-sec .one-by-third .one-by-third-one + .one-by-third-one {
  margin-top: 5px;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .fourth-sec.public-lab-fourth {
  width: 75%;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .fourth-sec.public-lab-fourth .one-by-third {
  text-align: right;
  padding-top: 1px;
  width: 26.5%;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .fourth-sec.public-lab-fourth .one-by-third input[type="button"] {
  padding: 0 25px !important;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .fourth-sec.public-lab-fourth .one-by-third:nth-child(2) {
  text-align: left;
}
#connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .fourth-sec.public-lab-fourth .one-by-third:nth-child(1) {
  width: 47%;
}
#connect-to-lab .connect-to-lab .no-data-found {
  text-align: center;
}
#connect-to-lab .connect-to-lab #style-2::-webkit-scrollbar-track {
  border-radius: 7px;
  background-color: #fff;
}
#connect-to-lab .connect-to-lab #style-2::-webkit-scrollbar {
  width: 7px;
  background-color: #fff;
}
#connect-to-lab .connect-to-lab #style-2::-webkit-scrollbar-thumb {
  background-color: #1f2532;
  border-radius: 7px;
}
@media screen and (min-height: 765px) and (max-height: 770px) {
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-body {
    max-height: 460px;
  }
}
@media screen and (min-height: 890px) and (max-height: 910px) {
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-body {
    max-height: 600px;
  }
}
@media screen and (min-height: 1040px) and (max-height: 1060px) {
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-body {
    max-height: 740px;
  }
}
@media screen and (min-height: 1070px) and (max-height: 1090px) {
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-body {
    max-height: 760px;
  }
}
@media screen and (min-height: 1190px) and (max-height: 1210px) {
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-body {
    max-height: 880px;
  }
}
.status-filter .several-options {
  position: relative;
  background: #dcdada;
  margin: 15px 0;
}
.status-filter .several-options select,
.status-filter .several-options input {
  width: 100%;
  float: left;
  background: #dcdada;
  border-radius: 0;
  box-shadow: none;
  white-space: inherit;
  line-height: 34px;
  height: 34px;
  border: 0;
  padding: 0 15px;
  font-size: 14px;
}
.status-filter .several-options select:focus,
.status-filter .several-options input:focus {
  outline: none;
}
.status-filter .several-options .search-material-actions {
  width: 100%;
  float: left;
  position: relative;
  margin-left: 0;
}
.status-filter .several-options .search-material-actions i.fa-caret-down {
  position: absolute;
  top: 10px;
  right: 10px;
}
.status-filter .several-options .search-material-actions input {
  padding-left: 15px;
  font-size: 14px;
}
.status-filter .several-options .search-material-actions .option-materials-actions {
  width: 100%;
  float: left;
  position: absolute;
  top: 33px;
  left: 0;
  box-shadow: 3px 3px 5px #dcdada;
  background: #f4f4f4;
  z-index: 9;
  padding: 0;
  max-height: 350px !important;
  overflow-y: auto !important;
  overflow-x: hidden;
}
.status-filter .several-options .search-material-actions .option-materials-actions .material {
  width: 100%;
  float: left;
}
.status-filter .several-options .search-material-actions .option-materials-actions .material ul {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
}
.status-filter .several-options .search-material-actions .option-materials-actions .material ul li {
  width: 100%;
  float: left;
  padding: 7px 15px;
  border-bottom: solid 1px #dcdada;
  position: relative;
}
.status-filter .several-options .search-material-actions .option-materials-actions .material ul li label.checkbox-style {
  min-height: 16px;
  line-height: 16px;
  margin-bottom: 0;
  position: static;
}
.status-filter .several-options .search-material-actions .option-materials-actions .material ul li label.checkbox-style input[type="checkbox"] {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.status-filter .several-options .search-material-actions .option-materials-actions .material ul li label.checkbox-style input[type="checkbox"] + label {
  margin-left: 15px;
  line-height: 16px;
  padding-left: 23px;
  margin-bottom: 0;
}
.status-filter .several-options .search-material-actions .option-materials-actions .material ul li label.checkbox-style input[type="checkbox"] + label:after {
  width: 16px;
  height: 16px;
}
.status-filter .several-options .search-material-actions .option-materials-actions .material ul li label.checkbox-style input[type="checkbox"]:checked + label:after {
  border-color: #105c9d;
}
.status-filter .several-options .search-material-actions .option-materials-actions .material ul li label.checkbox-style input[type="checkbox"]:checked + label:before {
  width: 16px;
  height: 16px;
  font-size: 12px;
}
.status-filter .several-options .search-material-actions .option-materials-actions .edit-save-btn {
  width: 100%;
  float: left;
  padding: 4px 15px 4px 0;
}
.status-filter .several-options .search-material-actions .option-materials-actions .edit-save-btn button {
  width: auto;
  float: right;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: solid 1px #105c9d;
  line-height: 24px;
  color: #105c9d;
  cursor: pointer;
  padding: 2px 50px;
  background: none;
  font-weight: 600;
}
.status-filter .several-options input {
  padding-left: 40px;
}
.status-filter .several-options .glyphicon-search {
  position: absolute;
  top: 10px;
  left: 13px;
}
#order-list .list-view-status,
#users .list-view-status {
  height: 800px;
  width: 100%;
  float: left;
  margin-top: 150px;
}
#order-list .list-view-status .order-list,
#users .list-view-status .order-list {
  margin-top: 0;
}
.filter-btn {
  border: 0;
  padding: 0 21px 0 21px;
  background: #105c9d !important;
  color: white;
  width: auto;
}
.filter-btn:disabled {
  cursor: not-allowed;
}
@media (max-width: 767px) {
  .filter-btn {
    width: 100%;
  }
}
.toast-success {
  background-color: #8dc44e !important;
}
.toast-error {
  background-color: #ff0515 !important;
}
.toast-warning {
  background-color: #fad800 !important;
}
.toast-container.toast-top-center .ngx-toastr {
  margin-top: 20px;
  width: 98%;
  max-width: 650px;
}
#dentist-order-detail {
  width: 100%;
  float: left;
  margin-top: 150px;
  margin-bottom: 20px;
}
#dentist-order-detail .dentist-order-detail {
  width: 100%;
  float: left;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail {
  width: 100%;
  float: left;
  background: #fff;
  padding: 25px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part {
  width: 50%;
  float: left;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part h3 {
  width: 100%;
  float: left;
  font-size: 16px;
  color: #105c9d;
  margin: 0px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part p {
  width: 100%;
  float: left;
  font-size: 16px;
  color: #4a4a4a;
  line-height: 20px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part p.destination-address {
  width: 100%;
  float: left;
  font-size: 16px;
  color: #4a4a4a;
  line-height: 20px;
  margin-bottom: 0px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part ul h3 {
  margin-top: 3px;
  color: #4a4a4a;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part ul li {
  width: 100%;
  float: left;
  font-size: 16px;
  color: #4a4a4a;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 15px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part ul.partial-temporary-type li {
  margin-bottom: 0px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part ul.partial-temporary-type li:first-child div span.construction-type {
  display: block;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part ul.partial-temporary-type li:first-child div span.construction-type:after {
  content: '';
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part ul.partial-temporary-type li div span.construction-type {
  display: none;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part ul.partial-temporary-type li div .move-to-left {
  margin-left: 80px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part.full-construction ul li span {
  position: relative;
  margin-right: 5px;
  display: inline-flex;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part.full-construction ul li span:after {
  content: ',';
  color: #000;
  font-weight: bold;
  width: 3px;
  height: 0px;
  position: absolute;
  right: 1px;
  bottom: 19px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part.full-construction ul li span strong {
  margin-right: 6px;
  position: relative;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part.full-construction ul li span strong:before {
  position: absolute;
  right: -6px;
  top: 10px;
  width: 6px;
  height: 1px;
  background: #fff;
  content: '';
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part.full-construction ul li span span:after {
  content: '';
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part.full-construction ul li span:last-child:after {
  content: '';
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part.full-construction ul li span.separate-arrow-right {
  display: block;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part.full-construction ul li span.separate-arrow-right strong {
  margin-right: 0;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part.full-construction ul li span.separate-arrow-right span span:after {
  content: ',';
  right: -4px;
  color: #ff0515;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part.full-construction ul li span.separate-arrow-right span:last-child span:after {
  content: '';
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part.full-construction ul li del span strong:before {
  background: #ff0515;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part.full-construction ul li del span:after {
  color: #ff0515 !important;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part.full-construction ul li del span:before {
  color: #ff0515 !important;
  position: absolute;
  right: -6px;
  top: 10px;
  width: 6px;
  height: 1px;
  background: #ff0515;
  content: '';
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part.full-construction ul li.modified span:after {
  color: #ff0515 !important;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part {
  width: 50%;
  float: left;
  text-align: right;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part h3 {
  width: 100%;
  float: left;
  font-size: 16px;
  color: #105c9d;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part p {
  width: 100%;
  float: left;
  font-size: 16px;
  color: #4a4a4a;
  line-height: 20px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part p.destination-address {
  width: 100%;
  float: left;
  font-size: 16px;
  color: #4a4a4a;
  line-height: 20px;
  margin-bottom: 0px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part .modify-date {
  width: 100%;
  float: left;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part .modify-date button {
  background: #105c9d;
  box-shadow: none;
  border: 0;
  color: #fff;
  margin: -15px 0 0 80px;
  float: left;
  border-radius: 0;
  font-weight: 600;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part .modify-date span {
  width: 33%;
  float: right;
  margin-top: 5px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part .modify-date span datepicker input {
  background: #eeecec;
  box-shadow: none;
  border: 0;
  margin: 0;
  float: right;
  border-radius: 0;
  width: 100%;
  text-align: center;
  font-weight: 600;
  min-height: 25px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part.full-construction {
  width: 100%;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part.order-created {
  width: 70% !important;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part.order-created {
  width: 30% !important;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part.order-created span {
  width: 40%;
  float: right;
  text-align: center;
  cursor: pointer;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part.order-created span p {
  font-weight: bold;
  font-size: 14px;
  color: #bbb;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .modified {
  color: #ff0515 !important;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .modified:after {
  color: #ff0515 !important;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics {
  width: 100%;
  float: left;
  background: #f4f4f4;
  padding: 13px;
  margin-top: 25px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics ul {
  width: 80%;
  float: left;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics ul li {
  width: 24%;
  float: left;
  margin-right: 1%;
  margin-bottom: 10px;
  text-align: center;
  position: relative;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics ul li img {
  width: 100%;
  height: 60px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics ul li button {
  position: absolute;
  right: 0;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 100%;
  background: #fff;
  opacity: 1;
  top: -7px;
  color: #105c9d;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics ul li span {
  width: 70%;
  float: left;
  padding: 3px 10px 3px 0px;
  color: #1f2532;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics .order-pic-upload {
  width: 20%;
  float: right;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics .order-pic-upload .upload-image {
  position: relative;
  width: 100%;
  margin-top: 10px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics .order-pic-upload .upload-image input {
  width: 100%;
  opacity: 0;
  position: relative;
  z-index: 99;
  height: 33px;
  cursor: pointer;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics .order-pic-upload .upload-image span {
  width: 100%;
  text-align: center;
  font-size: 15px;
  background: #105c9d;
  height: 42px;
  line-height: 33px;
  color: #fff;
  padding: 5px;
  float: left;
  cursor: pointer;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics .order-pic-upload p {
  width: 100%;
  float: left;
  color: #979797;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 2px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics.pdf-call-order {
  margin-top: 15px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics.pdf-call-order .order-pic-upload .upload-image {
  margin-top: 0px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics.pdf-call-order .order-pic-upload p {
  margin-bottom: 0px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row.order-created-by {
  margin-top: 25px;
  margin-bottom: 0px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row.order-created-by .left-detail-part h3 {
  width: auto;
  margin-top: 10px;
  margin-right: 5px;
  margin-bottom: 0px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row.order-created-by .left-detail-part p {
  width: auto;
  margin-top: 10px;
  margin-bottom: 0px;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row.order-created-by .right-detail-part i {
  font-size: 50px;
  color: #dcdada;
  cursor: pointer;
}
#dentist-order-detail .dentist-order-detail .barcode-wrapper {
  text-align: center;
  background: #fff;
  margin: 5px;
  border: 1px #ccc solid;
}
#dentist-order-detail .dentist-order-detail .chat-box {
  width: 100%;
  float: left;
  padding: 5px;
  /* position: fixed;*/
}
#dentist-order-detail .dentist-order-detail .chat-box.order-two-chat .chat-box-date {
  border: 1px #ccc solid;
}
#dentist-order-detail .dentist-order-detail .chat-box.order-two-chat .chat-box-date .chat-with-box .chat-login-person .chat-front-person-chat {
  width: 81%;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date {
  width: 100%;
  float: left;
  min-height: 300px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 0px;
  background: #fff;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-date {
  width: 100%;
  float: left;
  text-align: center;
  display: inline-block;
  margin-top: 30px;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-date span {
  width: auto;
  display: inline-block;
  float: none;
  padding: 3px 15px 2px;
  border-radius: 12px;
  background: #1f2532;
  color: #fff;
  font-size: 12px;
  margin: 0 10px;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box {
  width: 100%;
  float: left;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-login-person {
  width: 100%;
  float: left;
  padding: 30px 25px 0px;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-login-person .chat-front-person-img {
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 100%;
  float: left;
  margin-right: 10px;
  box-shadow: 2px 3px 8px 2px #eee;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-login-person .chat-front-person-img img {
  width: auto;
  height: 100%;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-login-person .chat-front-person-chat {
  width: 86%;
  float: left;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-login-person .chat-front-person-chat .front-person-chatting {
  width: auto;
  float: left;
  font-size: 14px;
  word-wrap: break-word;
  line-height: 18px;
  height: max-content;
  background: #d8d5d5;
  padding: 8px 15px;
  border-radius: 10px;
  box-shadow: 2px 3px 8px 2px #eee;
  color: #1f2532;
  max-width: 100%;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-login-person .chat-front-person-chat .front-person-chatting-date {
  width: 100%;
  float: left;
  color: #d8d5d5;
  font-size: 13px;
  margin-top: 5px;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-login-person .chat-front-person-chat .front-person-chatting.new-massage-bg {
  background: #f5d823;
  position: relative;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-login-person .chat-front-person-chat .front-person-chatting.new-massage-bg span {
  width: auto;
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  right: -55px;
  top: 30%;
  color: #f5d823;
  display: block;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-front-person {
  width: 100%;
  float: left;
  padding: 30px 25px 0px;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-front-person .chat-front-person-img {
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 100%;
  float: right;
  margin-left: 10px;
  box-shadow: 2px 3px 8px 2px #eee;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-front-person .chat-front-person-img img {
  width: auto;
  height: 100%;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-front-person .chat-front-person-chat {
  width: 86%;
  float: left;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-front-person .chat-front-person-chat .front-person-chatting {
  width: auto;
  float: right;
  font-size: 14px;
  word-wrap: break-word;
  line-height: 18px;
  height: max-content;
  background: #c2fefb;
  padding: 8px 15px;
  border-radius: 10px;
  box-shadow: 2px 3px 8px 2px #eee;
  color: #1f2532;
  max-width: 100%;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-front-person .chat-front-person-chat .front-person-chatting.new-massage-bg {
  background: #f5d823;
  position: relative;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-front-person .chat-front-person-chat .front-person-chatting.new-massage-bg span {
  width: auto;
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  left: -55px;
  top: 30%;
  color: #f5d823;
  display: block;
}
#dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-front-person .chat-front-person-chat .front-person-chatting-date {
  width: 100%;
  float: left;
  color: #d8d5d5;
  font-size: 13px;
  margin-top: 5px;
  text-align: right;
}
#dentist-order-detail .dentist-order-detail .chat-box #style-1::-webkit-scrollbar-track {
  border-radius: 7px;
  background-color: #fff;
}
#dentist-order-detail .dentist-order-detail .chat-box #style-1::-webkit-scrollbar {
  width: 7px;
  background-color: #fff;
}
#dentist-order-detail .dentist-order-detail .chat-box #style-1::-webkit-scrollbar-thumb {
  background-color: #1f2532;
  border-radius: 7px;
}
#dentist-order-detail .dentist-order-detail .chat-option-box {
  width: 100%;
  float: left;
  background: #fff;
  padding: 0;
  border-radius: 4px;
  margin-top: 21px;
  box-shadow: 2px 3px 8px 2px #eee;
}
#dentist-order-detail .dentist-order-detail .chat-option-box input {
  border: 0;
  width: 100%;
  float: left;
  padding: 35px 25px;
  line-height: 25px;
}
#dentist-order-detail .dentist-order-detail .chat-option-box input:focus {
  outline: inherit;
}
#dentist-order-detail .dentist-order-detail .chat-send-btn {
  width: 100%;
  float: left;
  margin-top: 21.5px;
}
#dentist-order-detail .dentist-order-detail .chat-send-btn input {
  width: auto;
  float: right;
  padding: 10px 40px;
  font-weight: 500;
  color: #fff;
  background: #105c9d;
  border: 0px;
}
#dentist-order-detail .dentist-order-detail .chat-send-btn input:focus {
  outline: inherit;
}
#dentist-order-detail .dentist-order-detail .chat-send-btn input.not-allowed-cursor {
  cursor: not-allowed;
}
.orders-pics.pdf-call-order .tooltip.file-name-tooltip .tooltip-inner {
  word-wrap: break-word;
  color: #462a43;
  background-color: #abe6b7;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.orders-pics-resend {
  width: 100%;
  float: left;
  background: #f4f4f4;
  padding: 13px;
  margin-top: 25px;
}
.orders-pics-resend ul {
  width: 100%;
  float: left;
}
.orders-pics-resend ul li {
  width: 10%;
  float: left;
  margin-right: 1%;
  text-align: center;
  position: relative;
}
.orders-pics-resend ul li img {
  width: 100%;
  height: 60px;
}
.orders-pics-resend ul li button {
  position: absolute;
  right: 0;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 100%;
  background: #fff;
  opacity: 1;
  top: -7px;
  color: #105c9d;
}
.orders-pics-resend ul li span {
  width: 100%;
  float: left;
  padding: 12px 0;
  background: #1f2532;
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
}
.navbar-nav li.dentist-order-manage {
  border-right: solid 1px #979797;
  min-height: 53px;
  /*a.first-a-li{
      .ngrateit{
        span{
          margin-top: 2px;
        }
      }

    }*/
}
.navbar-nav li.dentist-order-manage a {
  float: left;
}
.navbar-nav li.dentist-order-manage a i {
  font-size: 40px;
  float: left;
  margin: -10px 25px 0 0;
}
.navbar-nav li.dentist-order-manage a strong {
  width: 100%;
  float: left;
  margin-top: -5px;
}
.navbar-nav li.dentist-order-manage a span {
  width: 80%;
  float: left;
  text-align: left;
  font-size: 11px;
  margin-top: -7px;
  margin-bottom: 0px;
}
.navbar-nav li.dentist-order-manage a .rate-star-rating {
  width: 100%;
  float: left;
  padding-left: 56px;
}
.navbar-nav li.dentist-order-manage a strong.rate-head-strong {
  margin: -10px 0 -3px 0px;
}
.navbar-nav li.dentist-order-manage a strong.rate-head-strong + .ngrateit.ng-empty span {
  margin-top: 2px;
  margin-bottom: -9px;
}
.navbar-nav li.dentist-order-manage a strong.rate-head-strong + .ngrateit span {
  margin-top: 4px;
  margin-bottom: -2px;
}
.navbar-nav li.dentist-order-manage:first-child a {
  padding-bottom: 0px !important;
}
.navbar-nav li.dentist-order-manage:first-child a strong {
  text-align: left;
  width: 75%;
}
.navbar-nav li a.first-a-li {
  padding-bottom: 0px !important;
}
.navbar-nav li a.disabled {
  opacity: 0.2;
}
.navbar-nav li.lab-order-manage {
  border-right: solid 1px #979797;
  min-height: 53px;
}
.navbar-nav li.lab-order-manage a {
  float: left;
}
.navbar-nav li.lab-order-manage a i {
  font-size: 40px;
  float: left;
  margin: -10px 15px 0 0;
}
.navbar-nav li.lab-order-manage a strong {
  width: 100%;
  float: left;
  margin-top: -5px;
}
.navbar-nav li.lab-order-manage a span {
  width: 85%;
  float: left;
  text-align: left;
  font-size: 11px;
  margin-top: -7px;
  margin-bottom: 0px;
}
.navbar-nav li.lab-order-manage:first-child a {
  padding-bottom: 0px !important;
}
.navbar-nav li.lab-order-manage:first-child a strong {
  text-align: left;
  width: 75%;
}
@media screen and (min-height: 599px) and (max-height: 601px) {
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date {
    max-height: 250px;
    min-height: 250px;
  }
}
@media screen and (min-height: 765px) and (max-height: 770px) {
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date {
    max-height: 400px;
    min-height: 400px;
  }
}
@media screen and (min-height: 795px) and (max-height: 805px) {
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date {
    max-height: 430px;
    min-height: 430px;
  }
}
@media screen and (min-height: 890px) and (max-height: 910px) {
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date {
    max-height: 480px;
    min-height: 480px;
  }
}
@media screen and (min-height: 1040px) and (max-height: 1060px) {
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date {
    max-height: 630px;
    min-height: 630px;
  }
}
@media screen and (min-height: 1070px) and (max-height: 1090px) {
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date {
    max-height: 650px;
    min-height: 650px;
  }
}
@media screen and (min-height: 1190px) and (max-height: 1210px) {
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date {
    max-height: 780px;
    min-height: 780px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1169px) {
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-front-person .chat-front-person-chat {
    width: 83%;
    float: left;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1599px) {
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-front-person .chat-front-person-chat {
    width: 88%;
    float: left;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1599px) {
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-front-person .chat-front-person-chat {
    width: 88%;
    float: left;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1919px) {
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-front-person .chat-front-person-chat {
    width: 89%;
    float: left;
  }
}
@media screen and (min-width: 1920px) {
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-front-person .chat-front-person-chat {
    width: 90%;
    float: left;
  }
}
#cancel-order .modal-dialog {
  width: 700px;
}
#cancel-order .modal-dialog .modal-content {
  padding: 20px 40px 0px 40px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
#cancel-order .modal-dialog .modal-content .modal-header {
  border: 0px;
  padding: 0px;
}
#cancel-order .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  font-size: 30px;
  margin: -15px -25px;
}
#cancel-order .modal-dialog .modal-content .modal-header .close:focus {
  outline: none;
}
#cancel-order .modal-dialog .modal-content .modal-header h4.modal-title {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #105c9d;
  margin-bottom: 0px;
}
#cancel-order .modal-dialog .modal-content .modal-body {
  padding-top: 0px;
  padding-bottom: 20px;
}
#cancel-order .modal-dialog .modal-content .modal-body .add-clinic-form {
  width: 100%;
  float: left;
  margin-top: 0px;
}
#cancel-order .modal-dialog .modal-content .modal-body .add-clinic-form p {
  text-align: left;
  margin: 0;
  width: 100%;
  float: left;
  line-height: 18px;
}
#cancel-order .modal-dialog .modal-content .modal-body .add-clinic-form input {
  width: 100%;
  float: left;
  padding: 35px;
  text-align: center;
  margin: 7px 0 12px;
}
#cancel-order .modal-dialog .modal-content .modal-footer {
  border: 0;
  margin: 30px 0 20px;
}
#cancel-order .modal-dialog .modal-content .modal-footer .btn.btn-default {
  border-radius: 0px;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 10px 10px;
  color: #105c9d;
}
#cancel-order .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#cancel-order .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#cancel-order .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close {
  background: #105c9d;
  color: #fff;
}
#decline-order .modal-dialog {
  width: 700px;
}
#decline-order .modal-dialog .modal-content {
  padding: 20px 40px 0px 40px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
#decline-order .modal-dialog .modal-content .modal-header {
  border: 0px;
  padding: 0px;
}
#decline-order .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  font-size: 30px;
  margin: -15px -25px;
}
#decline-order .modal-dialog .modal-content .modal-header .close:focus {
  outline: none;
}
#decline-order .modal-dialog .modal-content .modal-header h4.modal-title {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #105c9d;
  margin-bottom: 0px;
}
#decline-order .modal-dialog .modal-content .modal-body {
  padding-top: 0px;
  padding-bottom: 20px;
}
#decline-order .modal-dialog .modal-content .modal-body .add-clinic-form {
  width: 100%;
  float: left;
  margin-top: 0px;
}
#decline-order .modal-dialog .modal-content .modal-body .add-clinic-form p {
  text-align: left;
  margin: 0;
  width: 100%;
  float: left;
  line-height: 18px;
}
#decline-order .modal-dialog .modal-content .modal-body .add-clinic-form input {
  width: 100%;
  float: left;
  padding: 35px;
  text-align: center;
  margin: 7px 0 12px;
}
#decline-order .modal-dialog .modal-content .modal-footer {
  border: 0;
  margin: 30px 0 20px;
}
#decline-order .modal-dialog .modal-content .modal-footer .btn.btn-default {
  border-radius: 0px;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 10px 10px;
  color: #105c9d;
}
#decline-order .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#decline-order .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#decline-order .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close {
  background: #105c9d;
  color: #fff;
}
#send-order .modal-dialog {
  width: 700px;
}
#send-order .modal-dialog .modal-content {
  padding: 20px 40px 0px 40px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
#send-order .modal-dialog .modal-content .modal-header {
  border: 0px;
  padding: 0px;
}
#send-order .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  font-size: 30px;
  margin: -15px -25px;
}
#send-order .modal-dialog .modal-content .modal-header .close:focus {
  outline: none;
}
#send-order .modal-dialog .modal-content .modal-header h4.modal-title {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #105c9d;
  margin-bottom: 0px;
}
#send-order .modal-dialog .modal-content .modal-body {
  padding-top: 30px;
  padding-bottom: 60px;
}
#send-order .modal-dialog .modal-content .modal-body .approved-by-wrapper {
  margin-top: 15px;
}
#send-order .modal-dialog .modal-content .modal-body .approved-by-wrapper .red-astrix-mark {
  padding: 0;
  font-size: 14px;
  vertical-align: super;
  color: #ff0515 !important;
}
#send-order .modal-dialog .modal-content .modal-body .approved-by-wrapper .approved-by-instructions {
  margin-top: 5px;
  font-size: 12px;
}
#send-order .modal-dialog .modal-content .modal-body .approved-by-wrapper .approved-by-search-select {
  max-width: 300px;
}
#send-order .modal-dialog .modal-content .modal-body .add-clinic-form {
  float: none !important;
  width: 100%;
  float: left;
  margin-top: 0px;
}
#send-order .modal-dialog .modal-content .modal-body .add-clinic-form p {
  text-align: left;
  margin: 0;
  width: 100%;
  line-height: 18px;
}
#send-order .modal-dialog .modal-content .modal-footer {
  border: 0;
  margin: 30px 0 20px;
  display: flex;
  align-items: center;
}
#send-order .modal-dialog .modal-content .modal-footer .archive-check-wrapper {
  flex: 1 0 auto;
  margin: 0;
}
#send-order .modal-dialog .modal-content .modal-footer .archive-check-wrapper input.archive-check-box {
  width: 100%;
  height: 100%;
}
#send-order .modal-dialog .modal-content .modal-footer .btn.btn-default {
  flex: 0 1 auto;
  border-radius: 0px;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 10px 10px;
  color: #105c9d;
}
#send-order .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#send-order .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#send-order .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close {
  background: #105c9d;
  color: #fff;
}
#archive-order .modal-dialog,
#un-archive-order .modal-dialog {
  width: 700px;
}
#archive-order .modal-dialog .modal-content,
#un-archive-order .modal-dialog .modal-content {
  padding: 20px 40px 0px 40px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
#archive-order .modal-dialog .modal-content .modal-header,
#un-archive-order .modal-dialog .modal-content .modal-header {
  border: 0px;
  padding: 0px;
}
#archive-order .modal-dialog .modal-content .modal-header .close,
#un-archive-order .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  font-size: 30px;
  margin: -15px -25px;
}
#archive-order .modal-dialog .modal-content .modal-header .close:focus,
#un-archive-order .modal-dialog .modal-content .modal-header .close:focus {
  outline: none;
}
#archive-order .modal-dialog .modal-content .modal-header h4.modal-title,
#un-archive-order .modal-dialog .modal-content .modal-header h4.modal-title {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #105c9d;
  margin-bottom: 0px;
}
#archive-order .modal-dialog .modal-content .modal-body,
#un-archive-order .modal-dialog .modal-content .modal-body {
  padding-top: 30px;
  padding-bottom: 60px;
}
#archive-order .modal-dialog .modal-content .modal-body .add-clinic-form,
#un-archive-order .modal-dialog .modal-content .modal-body .add-clinic-form {
  width: 100%;
  float: left;
  margin-top: 0px;
}
#archive-order .modal-dialog .modal-content .modal-body .add-clinic-form p,
#un-archive-order .modal-dialog .modal-content .modal-body .add-clinic-form p {
  text-align: left;
  margin: 0;
  width: 100%;
  float: left;
  line-height: 18px;
}
#archive-order .modal-dialog .modal-content .modal-footer,
#un-archive-order .modal-dialog .modal-content .modal-footer {
  border: 0;
  margin: 30px 0 20px;
}
#archive-order .modal-dialog .modal-content .modal-footer .btn.btn-default,
#un-archive-order .modal-dialog .modal-content .modal-footer .btn.btn-default {
  border-radius: 0px;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 10px 10px;
  color: #105c9d;
}
#archive-order .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#un-archive-order .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#archive-order .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#un-archive-order .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#archive-order .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close,
#un-archive-order .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close {
  background: #105c9d;
  color: #fff;
}
#accept-order .modal-dialog,
#clinic-received-delivery .modal-dialog,
#lab-work-started .modal-dialog {
  width: 700px;
}
#accept-order .modal-dialog .modal-content,
#clinic-received-delivery .modal-dialog .modal-content,
#lab-work-started .modal-dialog .modal-content {
  padding: 20px 40px 0px 40px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
#accept-order .modal-dialog .modal-content .modal-header,
#clinic-received-delivery .modal-dialog .modal-content .modal-header,
#lab-work-started .modal-dialog .modal-content .modal-header {
  border: 0px;
  padding: 0px;
}
#accept-order .modal-dialog .modal-content .modal-header .close,
#clinic-received-delivery .modal-dialog .modal-content .modal-header .close,
#lab-work-started .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  font-size: 30px;
  margin: -15px -25px;
}
#accept-order .modal-dialog .modal-content .modal-header .close:focus,
#clinic-received-delivery .modal-dialog .modal-content .modal-header .close:focus,
#lab-work-started .modal-dialog .modal-content .modal-header .close:focus {
  outline: none;
}
#accept-order .modal-dialog .modal-content .modal-header h4.modal-title,
#clinic-received-delivery .modal-dialog .modal-content .modal-header h4.modal-title,
#lab-work-started .modal-dialog .modal-content .modal-header h4.modal-title {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #105c9d;
  margin-bottom: 0px;
}
#accept-order .modal-dialog .modal-content .modal-body,
#clinic-received-delivery .modal-dialog .modal-content .modal-body,
#lab-work-started .modal-dialog .modal-content .modal-body {
  padding-top: 30px;
  padding-bottom: 0;
}
#accept-order .modal-dialog .modal-content .modal-body .version-comment,
#clinic-received-delivery .modal-dialog .modal-content .modal-body .version-comment,
#lab-work-started .modal-dialog .modal-content .modal-body .version-comment {
  font-size: 13px;
  color: #000;
  font-weight: normal;
}
#accept-order .modal-dialog .modal-content .modal-body .add-clinic-form,
#clinic-received-delivery .modal-dialog .modal-content .modal-body .add-clinic-form,
#lab-work-started .modal-dialog .modal-content .modal-body .add-clinic-form {
  width: 100%;
  float: left;
  margin-top: 0px;
}
#accept-order .modal-dialog .modal-content .modal-body .add-clinic-form p,
#clinic-received-delivery .modal-dialog .modal-content .modal-body .add-clinic-form p,
#lab-work-started .modal-dialog .modal-content .modal-body .add-clinic-form p {
  text-align: left;
  margin: 25px 0 0;
  width: 100%;
  float: left;
  line-height: 18px;
}
#accept-order .modal-dialog .modal-content .modal-body .commet-export-production label.change-label-color,
#clinic-received-delivery .modal-dialog .modal-content .modal-body .commet-export-production label.change-label-color,
#lab-work-started .modal-dialog .modal-content .modal-body .commet-export-production label.change-label-color {
  color: #105c9d;
  margin-top: 30px;
  font-weight: 500;
}
#accept-order .modal-dialog .modal-content .modal-body .sample-content ul,
#clinic-received-delivery .modal-dialog .modal-content .modal-body .sample-content ul,
#lab-work-started .modal-dialog .modal-content .modal-body .sample-content ul {
  margin-top: -20px;
  padding-left: 0;
  margin-left: -4px;
}
#accept-order .modal-dialog .modal-content .modal-footer,
#clinic-received-delivery .modal-dialog .modal-content .modal-footer,
#lab-work-started .modal-dialog .modal-content .modal-footer {
  border: 0;
  margin: 30px 0 20px;
}
#accept-order .modal-dialog .modal-content .modal-footer .btn.btn-default,
#clinic-received-delivery .modal-dialog .modal-content .modal-footer .btn.btn-default,
#lab-work-started .modal-dialog .modal-content .modal-footer .btn.btn-default {
  border-radius: 0px;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 10px 10px;
  color: #105c9d;
}
#accept-order .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#clinic-received-delivery .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#lab-work-started .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#accept-order .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#clinic-received-delivery .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#lab-work-started .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#accept-order .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close,
#clinic-received-delivery .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close,
#lab-work-started .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close {
  background: #105c9d;
  color: #fff;
}
#rate-order .modal-dialog {
  width: 1200px;
}
#rate-order .modal-dialog .modal-content {
  padding: 30px 70px 5px 70px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
#rate-order .modal-dialog .modal-content .modal-header {
  border: 0px;
  padding: 0px;
}
#rate-order .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  font-size: 30px;
  margin: -15px -25px;
}
#rate-order .modal-dialog .modal-content .modal-header .close:focus {
  outline: none;
}
#rate-order .modal-dialog .modal-content .modal-header h4.modal-title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #4a4a4a;
  margin-bottom: 0px;
}
#rate-order .modal-dialog .modal-content .modal-body {
  padding-top: 10px;
  padding-bottom: 10px;
}
#rate-order .modal-dialog .modal-content .modal-body p {
  text-align: left;
  margin: 0;
  width: 100%;
  float: left;
  line-height: 18px;
  font-size: 15px;
  text-align: center;
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div {
  text-align: center;
  display: inline-block;
  width: 100%;
  float: left;
  margin-bottom: 50px;
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 {
  display: inline-block;
  float: none;
  vertical-align: top;
  width: 20%;
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 .rating-head-div {
  width: 100%;
  float: left;
  border-bottom: solid 1px #105c9d;
  padding: 10px 0;
  margin-top: 25px;
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 .rating-head-div .rating-heading {
  width: 40%;
  float: left;
  text-align: center;
  line-height: 16px;
  color: #105c9d;
  font-weight: 600;
  /* Background */
  /* Selected state */
  /* Cancelation of hover styling */
  /* Hover styling */
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 .rating-head-div .rating-heading .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../images/rated-star.svg");
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 .rating-head-div .rating-heading .ngrateit-reset {
  background-image: url("../images/cancel-icon.svg");
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 .rating-head-div .rating-heading .ngrateit-bg-star {
  background-image: url("../images/bg-star.svg");
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 .rating-head-div .rating-heading .ngrateit-selected {
  background-image: url("../images/rated-star.svg");
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 .rating-head-div .rating-heading .ngrateit:not(.ngrateit-readonly) .ngrateit-rating span:hover ~ span {
  background-image: url("../images/bg-star.svg");
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 .rating-head-div .rating-heading .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../images/rated-star.svg");
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 .rating-head-div .rating-heading + .rating-heading {
  width: 60%;
  float: left;
  text-align: center;
  /* Background */
  /* Selected state */
  /* Cancelation of hover styling */
  /* Hover styling */
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 .rating-head-div .rating-heading + .rating-heading .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../images/rated-star.svg");
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 .rating-head-div .rating-heading + .rating-heading .ngrateit-reset {
  background-image: url("../images/cancel-icon.svg");
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 .rating-head-div .rating-heading + .rating-heading .ngrateit-bg-star {
  background-image: url("../images/bg-star.svg");
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 .rating-head-div .rating-heading + .rating-heading .ngrateit-selected {
  background-image: url("../images/rated-star.svg");
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 .rating-head-div .rating-heading + .rating-heading .ngrateit:not(.ngrateit-readonly) .ngrateit-rating span:hover ~ span {
  background-image: url("../images/bg-star.svg");
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 .rating-head-div .rating-heading + .rating-heading .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../images/rated-star.svg");
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 .rating-head-div .rating-heading + .rating-heading a.ngrateit-reset {
  display: none;
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 ul li {
  color: #105c9d;
  font-weight: 600;
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 ul li .rating-head-div {
  border: 0px;
  margin: 0px;
  padding: 16px 0 0px;
}
#rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 ul li .rating-head-div .rating-heading {
  color: #4a4a4a;
}
#rate-order .modal-dialog .modal-content .modal-footer {
  border: 0;
  margin: 30px 0 20px;
}
#rate-order .modal-dialog .modal-content .modal-footer .btn.btn-default {
  border-radius: 0px;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 10px 25px;
  color: #105c9d;
}
#rate-order .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#rate-order .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#rate-order .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close {
  background: #105c9d;
  color: #fff;
}
#rate-order.one-rate-option .modal-dialog {
  width: 500px;
}
#rate-order.one-rate-option .modal-dialog .modal-content {
  padding: 30px 0px 5px;
}
#rate-order.one-rate-option .modal-dialog .modal-content .modal-header .close {
  margin: -15px 15px;
}
#rate-order.one-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 {
  width: 50%;
}
#rate-order.two-rate-option .modal-dialog {
  width: 600px;
}
#rate-order.two-rate-option .modal-dialog .modal-content {
  padding: 30px 0px 5px;
}
#rate-order.two-rate-option .modal-dialog .modal-content .modal-header .close {
  margin: -15px 15px;
}
#rate-order.two-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 {
  width: 50%;
}
#rate-order.three-rate-option .modal-dialog {
  width: 750px;
}
#rate-order.three-rate-option .modal-dialog .modal-content {
  padding: 30px 10px 5px;
}
#rate-order.three-rate-option .modal-dialog .modal-content .modal-header .close {
  margin: -15px 15px;
}
#rate-order.three-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 {
  width: 33%;
}
#rate-order.four-rate-option .modal-dialog {
  width: 1000px;
}
#rate-order.four-rate-option .modal-dialog .modal-content {
  padding: 30px 10px 5px;
}
#rate-order.four-rate-option .modal-dialog .modal-content .modal-header .close {
  margin: -15px 15px;
}
#rate-order.four-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 {
  width: 25%;
}
#resend-order-button {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}
#resend-order-button button {
  width: 100%;
  float: left;
  padding: 10px 15px 8px;
  text-align: left;
  border-radius: 0px;
  background: #1f2532;
  color: #fff;
}
#resend-order-button button i {
  float: right;
  font-size: 20px;
}
.order-patient-resend #patient-section .patientLab {
  margin-top: 0px;
}
.compress-container-30 {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}
@media (min-width: 1169px) {
  .compress-container-30 .container {
    width: 1192px;
  }
}
.full-width {
  width: 100%;
  float: left;
}
#imgModal .ui-carousel .slide .carousel-item .image {
  width: 100%;
  float: left;
  text-align: center;
}
#imgModal .ui-carousel .slide .carousel-item .image img {
  height: 450px;
  float: none;
  display: inline-block;
  margin: 100px 0 0px;
}
#imgModal .ui-carousel .carousel-prev .carousel-btn {
  left: 15px;
}
#imgModal .ui-carousel .carousel-prev .carousel-btn i {
  line-height: 27px;
  background: #fff;
  padding: 3px 10px;
  border-radius: 50%;
}
#imgModal .ui-carousel .carousel-next .carousel-btn {
  right: 0px;
}
#imgModal .ui-carousel .carousel-next .carousel-btn i {
  line-height: 27px;
  background: #fff;
  padding: 3px 10px;
  border-radius: 50%;
}
#imgModal .carousel-dots {
  bottom: 70px;
}
#imgModal .carousel-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: #fff;
  border-radius: 50%;
}
#imgModal .carousel-dots li button:before {
  color: #fff;
}
#imgModal span.close {
  position: absolute;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 32px;
  border-radius: 100%;
  background: #fff;
  opacity: 1;
  top: 60px;
  color: #111;
  font-size: 25px;
  z-index: 999;
  right: -25px;
}
#imgModal .text-center {
  text-align: center;
  display: inline-block;
  width: 100%;
  float: left;
  position: relative;
}
#imgModal .align-img-cross {
  width: auto;
  display: inline-block;
  position: relative;
}
#imgModal img {
  max-width: 1000px;
  margin: 80px auto;
  max-height: 450px;
}
#imgModal ul.carousel-dots li {
  width: 15px;
  height: 15px;
}
.span-two-full ul li span {
  position: relative;
  margin-right: 10px;
  display: inline-flex;
}
.span-two-full ul li span:after {
  content: ',';
  color: #000;
  font-weight: bold;
  width: 3px;
  height: 0px;
  position: absolute;
  right: 1px;
  bottom: 25px;
}
.span-two-full ul li span span:after {
  content: '';
}
.span-two-full ul li span:last-child:after {
  content: '';
}
.span-two-full ul li span.modified {
  margin-right: 5px;
}
.span-two-full ul li span.modified:after {
  color: #ff0515 !important;
  -webkit-print-color-adjust: exact;
}
.span-two-full ul li del span {
  margin-right: 0px;
}
.span-two-full ul li del span:after {
  color: #ff0515 !important;
  -webkit-print-color-adjust: exact;
}
.span-two-full ul li.modified span {
  margin-right: 5px;
}
.span-two-full ul li.modified span:after {
  color: #ff0515 !important;
  -webkit-print-color-adjust: exact;
}
span.temporary-denture-tooth {
  position: relative;
  margin-right: 10px;
  display: inline-flex;
}
span.temporary-denture-tooth:after {
  content: ',' !important;
  color: #000;
  font-weight: bold;
  width: 3px;
  height: 0px;
  position: absolute;
  right: -2px !important;
  bottom: 20px !important;
}
span.temporary-denture-tooth span.temporary-denture-tooth:after {
  content: '' !important;
}
span.temporary-denture-tooth:last-child:after {
  content: '' !important;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part ul li > div {
  display: inline;
}
#dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part ul li > del > div {
  display: inline;
}
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}
.chat-box .chat-box-date {
  position: relative;
}
#sppiner-loader-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 999;
}
.comment-date {
  white-space: nowrap;
  min-width: 125px;
}
#find-lab {
  width: 100%;
  float: left;
  margin-top: 120px;
  margin-bottom: 20px;
}
#find-lab .find-lab {
  width: 100%;
  float: left;
}
#find-lab .find-lab .find-lab-form {
  width: 50%;
  float: none;
  margin: 30px auto;
}
#find-lab .find-lab .find-lab-form .lab-logo {
  width: 100%;
  float: left;
  text-align: center;
}
#find-lab .find-lab .find-lab-form h3 {
  width: 100%;
  float: left;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: #105c9d;
  margin: 15px 0 10px;
}
#find-lab .find-lab .find-lab-form p {
  width: 80%;
  float: left;
  margin-left: 10%;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  color: #1f2532;
  margin-bottom: 30px;
}
#find-lab .find-lab .find-lab-form .find-form {
  width: 100%;
  float: left;
  position: relative;
  text-align: center;
  margin-bottom: 40px;
}
#find-lab .find-lab .find-lab-form .find-form input[type="text"] {
  width: 67%;
  padding: 0 10px 0 55px;
  border-radius: 3px;
  border: solid 1px #f4f4f4;
  height: 55px;
  line-height: 55px;
  background: #fff;
  box-shadow: 1px 2px 3px #dcdada;
}
#find-lab .find-lab .find-lab-form .find-form input[type="text"]:focus {
  outline: none;
}
#find-lab .find-lab .find-lab-form .find-form i.fa.fa-search {
  font-size: 15px;
  left: 18px;
  top: 20px;
  position: absolute;
}
#find-lab .find-lab .find-lab-form .find-form input[type="button"] {
  min-width: 187px;
  max-width: 187px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  border-radius: 1px;
  padding: 0px;
  text-transform: uppercase;
  display: inline-block;
  height: 55px;
  line-height: 55px;
  border: 0px;
  background: #105c9d;
  color: #fff;
  box-shadow: 1px 2px 3px #dcdada;
}
#find-lab .find-lab .find-lab-form .find-form button[type="button"] {
  min-width: 55px;
  max-width: 55px;
  border-radius: 100%;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  padding: 0px;
  text-transform: uppercase;
  display: inline-block;
  height: 55px;
  line-height: 55px;
  border: 0px;
  background: #105c9d;
  color: #fff;
  box-shadow: 1px 2px 3px #dcdada;
}
#find-lab .find-lab .find-lab-form .find-form.arrow-form {
  margin-top: 20px;
}
#find-lab .find-lab .find-lab-form .find-form.arrow-form i.fa.fa-search {
  left: 85px;
}
.highlighted-text {
  background: #eee;
}
.search-digital-sample {
  height: 25px;
  margin-left: 10px;
}
.search-lab-flag-icon {
  font-size: 18px;
  color: #ff0000;
}
a[download$=".com"].link-icon:before,
a[download$=".net"].link-icon:before,
a[download$=".org"].link-icon:before,
a[download$=".edu"].link-icon:before,
a[download$=".gov"].link-icon:before,
a[download$=".mil"].link-icon:before,
a[download$="/"].link-icon:before,
a[download$=".html"].link-icon:before,
a[download$=".htm"].link-icon:before,
a[download$=".xhtml"].link-icon:before,
a[download$=".jhtml"].link-icon:before,
a[download$=".php"].link-icon:before,
a[download$=".php3"].link-icon:before,
a[download$=".php4"].link-icon:before,
a[download$=".php5"].link-icon:before,
a[download$=".phtml"].link-icon:before,
a[download$=".asp"].link-icon:before,
a[download$=".aspx"].link-icon:before,
a[download$=".cfm"].link-icon:before,
a[download*="?"].link-icon:before,
a[download*="#"].link-icon:before,
a.link-icon:not([href*='.']):before,
a.link-icon.link[href]:before {
  content: "\f0c1";
  /*link*/
}
a.link-icon:before,
a.link-icon.link-file[href]:before {
  content: "\f016";
  /* generic file icon */
  display: inline-block;
  font: normal normal normal 18px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 20px;
  text-align: right;
  margin-right: 10px;
  float: left;
  margin-top: 6px;
  color: #1f2532;
}
a[download$=".pdf"].link-icon:before,
a.link-icon.link-pdf[href]:before {
  content: "\f1c1";
  /*PDF*/
}
a[download$=".txt"].link-icon:before,
a.link-icon.link-text[href]:before {
  content: "\f0f6";
  /*Text*/
}
a[download$=".doc"].link-icon:before,
a[download$=".docx"].link-icon:before,
a.link-icon.link-word[href]:before {
  content: "\f1c2";
  /*Word*/
}
a[download$=".xls"].link-icon:before,
a[download$=".xlsx"].link-icon:before,
a.link-icon.link-excel[href]:before {
  content: "\f1c3";
  /*Excel*/
}
a[download$=".ppt"].link-icon:before,
a[download$=".pptx"].link-icon:before,
a.link-icon.link-powerpoint[href]:before {
  content: "\f1c4";
  /*Powerpoint*/
}
a[download$=".gif"].link-icon:before,
a[download$=".jpg"].link-icon:before,
a[download$=".jpeg"].link-icon:before,
a[download$=".png"].link-icon:before,
a[download$=".bmp"].link-icon:before,
a[download$=".tif"].link-icon:before,
a.link-icon.link-image[href]:before {
  content: "\f1c5";
  /*Image*/
}
a[download$=".zip"].link-icon:before,
a[download$=".zipx"].link-icon:before,
a[download$=".rar"].link-icon:before,
a[download$=".tar"].link-icon:before,
a[download$=".gz"].link-icon:before,
a[download$=".dmg"].link-icon:before,
a[download$=".iso"].link-icon:before,
a.link-icon.link-archive[href]:before {
  content: "\f1c6";
  /*Archive*/
}
a[download$=".wav"].link-icon:before,
a[download$=".mp3"].link-icon:before,
a[download$=".fla"].link-icon:before,
a[download$=".flac"].link-icon:before,
a[download$=".ra"].link-icon:before,
a[download$=".rma"].link-icon:before,
a[download$=".aif"].link-icon:before,
a[download$=".aiff"].link-icon:before,
a[download$=".aa"].link-icon:before,
a[download$=".aac"].link-icon:before,
a[download$=".aax"].link-icon:before,
a[download$=".ac3"].link-icon:before,
a[download$=".au"].link-icon:before,
a[download$=".ogg"].link-icon:before,
a[download$=".avr"].link-icon:before,
a[download$=".3ga"].link-icon:before,
a[download$=".flac"].link-icon:before,
a[download$=".mid"].link-icon:before,
a[download$=".midi"].link-icon:before,
a[download$=".m4a"].link-icon:before,
a[download$=".mp4a"].link-icon:before,
a[download$=".amz"].link-icon:before,
a[download$=".mka"].link-icon:before,
a[download$=".asx"].link-icon:before,
a[download$=".pcm"].link-icon:before,
a[download$=".m3u"].link-icon:before,
a[download$=".wma"].link-icon:before,
a[download$=".xwma"].link-icon:before,
a.link-icon.link-audio[href]:before {
  content: "\f1c7";
  /*Audio*/
}
a[download$=".avi"].link-icon:before,
a[download$=".mpg"].link-icon:before,
a[download$=".mp4"].link-icon:before,
a[download$=".mkv"].link-icon:before,
a[download$=".mov"].link-icon:before,
a[download$=".wmv"].link-icon:before,
a[download$=".vp6"].link-icon:before,
a[download$=".264"].link-icon:before,
a[download$=".vid"].link-icon:before,
a[download$=".rv"].link-icon:before,
a[download$=".webm"].link-icon:before,
a[download$=".swf"].link-icon:before,
a[download$=".h264"].link-icon:before,
a[download$=".flv"].link-icon:before,
a[download$=".mk3d"].link-icon:before,
a[download$=".gifv"].link-icon:before,
a[download$=".oggv"].link-icon:before,
a[download$=".3gp"].link-icon:before,
a[download$=".m4v"].link-icon:before,
a[download$=".movie"].link-icon:before,
a[download$=".divx"].link-icon:before,
a.link-icon.link-video[href]:before {
  content: "\f1c8";
  /*Video*/
}
a[download$=".css"].link-icon:before,
a[download$=".js"].link-icon:before,
a[download$=".py"].link-icon:before,
a[download$=".git"].link-icon:before,
a[download$=".py"].link-icon:before,
a[download$=".cpp"].link-icon:before,
a[download$=".h"].link-icon:before,
a[download$=".ini"].link-icon:before,
a[download$=".config"].link-icon:before,
a.link-icon.link-code[href]:before {
  content: "\f1c9";
  /*code*/
}
a[download$=".exe"].link-icon:before,
a[download$=".jar"].link-icon:before,
a[download$=".dll"].link-icon:before,
a[download$=".bat"].link-icon:before,
a[download$=".pl"].link-icon:before,
a[download$=".scr"].link-icon:before,
a[download$=".msi"].link-icon:before,
a[download$=".app"].link-icon:before,
a[download$=".deb"].link-icon:before,
a[download$=".apk"].link-icon:before,
a[download$=".jar"].link-icon:before,
a[download$=".vb"].link-icon:before,
a[download$=".prg"].link-icon:before,
a[download$=".sh"].link-icon:before,
a.link-icon.link-exe[href]:before {
  content: "\f085";
  /*Executable*/
}
@media print {
  *,
  *:before,
  *:after {
    box-shadow: none !important;
    text-shadow: none !important;
  }
  @page {
    size: auto;
    margin: 0;
  }
  .mini-print {
    margin: 25px 100px;
  }
  .printableMiniArea {
    margin: 25px 100px;
  }
  .full-page-print {
    margin: 25px 50px;
  }
  .main-div {
    text-align: center;
    font-family: Roboto, sans-serif !important;
  }
  .top-div {
    width: 100%;
    float: left;
    text-align: center;
  }
  .top-div.mini-print {
    width: 100%;
    float: left;
    text-align: left;
  }
  .top-div img {
    width: auto;
    height: 100px;
  }
  .print-date {
    position: absolute;
    top: -10px;
    right: 10px;
    font-size: 15px;
  }
  .print-date p {
    display: inline-block;
    font-size: 11px;
  }
  .info-div {
    float: left;
    width: 100%;
    margin-top: 40px;
  }
  .info-div span {
    float: left;
    width: 100%;
    font-size: 17px;
    margin-top: 12px;
    text-align: left;
  }
  .info-div span h3 {
    display: inline-block;
    margin: 0;
    font-size: 17px;
    font-weight: 700;
  }
  .info-div span p {
    display: inline-block;
    margin: 0;
    font-size: 17px;
  }
  .info-div span.span-two-full {
    float: left;
    width: 100%;
    font-size: 17px;
    margin-top: 25px;
  }
  .info-div span.span-two-full ul {
    float: left;
    width: 100%;
    font-size: 15px;
    margin-top: 0;
    text-align: left;
    padding: 0;
  }
  .info-div span.span-two-full h3 {
    margin: 0;
    width: 100%;
    float: left;
    font-weight: 700;
    font-size: 21px;
  }
  .info-div span.span-two-full ul li {
    list-style: none;
    margin-right: 15px;
    margin-bottom: 10px;
  }
  .info-div span.span-two-full ul li > div {
    display: inline;
  }
  .info-div span.span-two-full ul li span {
    width: auto;
    float: none;
    display: inline;
    margin: 0;
  }
  .info-div span.span-two-full ul li strong {
    display: inline;
    margin: 0;
  }
  .info-div span.span-bottom-sec {
    float: left;
    width: 100%;
    margin-top: 20px;
  }
  .info-div span.span-bottom-sec ul {
    float: left;
    width: 100%;
    padding: 0;
  }
  .info-div span.span-bottom-sec ul li {
    width: auto;
    overflow: hidden;
    float: left;
    margin-right: 10px;
    list-style: none;
    display: inline-block;
  }
  .info-div span.span-bottom-sec ul li img {
    width: auto;
    height: 100px;
  }
  .info-div span.span-two-full ul li > del > div {
    display: inline;
  }
  .info-div span.span-two-full ul.partial-temporary-type li {
    margin-bottom: 0;
  }
  .info-div span.span-two-full ul.partial-temporary-type li:first-child div span.construction-type {
    display: block;
  }
  .info-div span.span-two-full ul.partial-temporary-type li:first-child div span.construction-type:after {
    content: '';
  }
  .info-div span.span-two-full ul.partial-temporary-type li div span.construction-type {
    display: none;
  }
  .info-div span.span-two-full ul.partial-temporary-type li div .move-to-left {
    margin-left: 80px;
  }
  .info-div span.span-two-full ul.partial-temporary-type li del div .move-to-left {
    margin-left: 80px;
  }
  .printableMiniArea.info-div span.span-two-full ul.partial-temporary-type li div span.construction-type {
    display: none;
  }
  .printableMiniArea.info-div span.span-two-full ul.partial-temporary-type li {
    margin-bottom: 0;
    display: inline;
    margin-right: 3px;
  }
  .printableMiniArea.info-div span.span-two-full ul.partial-temporary-type li:first-child div span.construction-type {
    display: inline;
  }
  .printableMiniArea.info-div span.span-two-full ul.partial-temporary-type li div {
    display: inline;
  }
  .printableMiniArea.info-div span.span-two-full ul.partial-temporary-type li div span {
    display: inline;
  }
  .printableMiniArea.info-div span.span-two-full ul.partial-temporary-type li div span p {
    display: inline;
  }
  .printableMiniArea.info-div span.span-two-full ul.partial-temporary-type li:last-child div span p {
    display: none;
  }
  .order-confirm-print {
    text-align: center;
  }
  .order-confirm-print div img {
    width: 100px;
    height: auto;
  }
  .order-confirm-print .order-details span {
    float: left;
    width: 100%;
    font-size: 27px;
    margin-top: 34px;
  }
  .modified {
    color: red !important;
  }
  .modified span {
    color: red !important;
  }
  .modified strong {
    color: red !important;
  }
  .printableMiniArea.info-div span {
    margin-top: 0;
  }
  .printableMiniArea.info-div span > div {
    height: 20px;
  }
  .printableMiniArea.info-div span.span-two-full {
    margin-top: 25px;
  }
  .printableMiniArea.info-div span h3 {
    display: inline-block;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
  }
  .printableMiniArea.info-div span.span-two-full ul {
    font-size: 14px;
    margin-bottom: 25px;
  }
  .printableMiniArea.info-div span p {
    display: inline-block;
    margin: 0;
    font-size: 14px;
  }
  .printableMiniArea.info-div span.span-two-full ul li {
    margin-bottom: 0;
    font-size: 13px;
  }
  .printableMiniArea.info-div span.span-two-full ul li span {
    font-size: 13px;
    margin: 0;
  }
  .sign-send .col-lg-6 {
    width: 50%;
    float: left;
    padding: 0 15px;
  }
  .sign-send .col-lg-6 + .col-lg-6 {
    margin-top: 20px;
  }
  .sign-send .col-lg-6 + .mt-0 {
    margin-top: 0;
  }
  .sign-send .col-lg-3 {
    width: 25%;
  }
  .sign-send .col-lg-6 p {
    padding: 0 15px;
  }
  .sign-send .col-lg-3 img {
    width: 100%;
  }
  .sign-send h2 {
    float: left;
    font-size: 20px;
    color: #1f2532 !important;
    -webkit-print-color-adjust: exact;
    font-weight: 800;
    border-bottom: solid 2px #1f2532;
    width: 100%;
    line-height: 30px;
    padding: 0 15px;
    margin-bottom: 10px;
  }
  .sign-send .action-on-teeth p {
    width: 50%;
    float: left;
    padding: 0 15px;
    font-size: 12px;
  }
  .sign-send p {
    font-size: 12px;
  }
  .sign-send .action-on-teeth p label {
    width: 145px;
    float: left;
    color: #a5a3a3 !important;
    -webkit-print-color-adjust: exact;
  }
  .sign-send .col-lg-6 label {
    width: 145px;
    float: left;
    color: #a5a3a3 !important;
    -webkit-print-color-adjust: exact;
  }
  .sign-send h4 {
    width: 100%;
    float: left;
    margin: 10px 0 10px;
    padding-bottom: 5px;
  }
  .sign-send .action-text {
    display: flex;
    width: 100%;
    float: left;
    padding: 15px 15px 0;
  }
  .sign-send label {
    width: 145px;
    float: left;
    font-weight: 800;
    color: #a5a3a3 !important;
    -webkit-print-color-adjust: exact;
  }
  .sign-send label strong {
    color: #a5a3a3 !important;
    -webkit-print-color-adjust: exact;
  }
  .sign-send .images-upload {
    float: left;
    width: 100%;
    background: #f2f2f2;
    text-align: center;
    margin-bottom: 20px;
    margin-left: 12%;
    margin-top: 43px;
    padding: 41px 20px 40px;
  }
  .extra-margin-on-print-option h2 {
    margin-bottom: 40px;
  }
  .sign-send .files-box h3 {
    font-size: 14px;
    margin-top: 25px;
  }
  .sign-send .col-lg-6 img {
    min-width: 22px;
    height: 22px;
    margin-left: 10px;
    cursor: pointer;
    margin-top: -3px;
  }
  .sign-send .add-materias-actions {
    width: 100%;
    float: left;
  }
  .sign-send .add-materias-actions-section {
    width: 100%;
    float: left;
  }
  .sign-send .added-materials-actions {
    padding: 10px 30px;
  }
  .sign-send .added-materials-actions .order-list-hading {
    font-weight: 500;
    color: #aaa;
    text-align: center;
    font-size: 14px;
    width: 100%;
    float: left;
  }
  .sign-send .added-materials-actions .order-list-hading .col-lg-6 {
    width: 50%;
    float: left;
  }
  .sign-send .added-materials-actions .order-list-hading .col-lg-2 {
    width: 16.66666667%;
    float: left;
  }
  .sign-send .added-materials-actions .order-list-content {
    display: flex;
    align-items: flex-end;
    margin: 2px 0;
    position: relative;
    width: 100%;
    float: left;
  }
  .sign-send .added-materials-actions .order-list-content .col-lg-6 {
    width: 50%;
    float: left;
    text-align: left;
  }
  .sign-send .added-materials-actions .order-list-content .col-lg-2 {
    width: 16.66666667%;
    float: left;
    text-align: center;
  }
  .order-list-content.flex-start,
  .order-list-hading,
  .added-action-content,
  .added-action-heading {
    display: flex;
    width: 100% !important;
  }
  .order-list-content.flex-start div,
  .order-list-hading div,
  .added-action-content div,
  .added-action-heading div {
    width: 100%;
    min-width: 100px;
    text-align: center;
  }
  .order-list-content.flex-start div:first-child,
  .order-list-hading div:first-child,
  .added-action-content div:first-child,
  .added-action-heading div:first-child {
    text-align: left;
    min-width: 200px;
    font-weight: 700;
  }
  .order-list-hading div:first-child {
    color: #000;
  }
  .order-list-hading.simple-material-heading div {
    min-width: 150px !important;
  }
  .order-list-content.flex-start.simple-material-content div {
    min-width: 170px !important;
    width: 100%;
    text-align: center;
  }
  .order-list-content.flex-start.simple-material-content div:first-child {
    text-align: left;
  }
  .sign-send .order-list-box {
    width: 100%;
    float: left;
  }
  .sign-send .order-list-box h5 {
    color: #000;
    font-weight: 500;
  }
  .sign-send .order-list-content {
    width: 100%;
    float: left;
    display: flex;
    align-items: flex-end;
    margin: 2px 0;
    position: relative;
  }
  .sign-send .order-list-content .font-manage h5 {
    width: 100%;
    float: left;
    font-size: 14px;
    margin-bottom: 15px;
    color: #000;
  }
  .sign-send .order-list-content .font-manage span {
    width: 100%;
    float: left;
    font-size: 14px;
    margin-bottom: 15px;
    color: #000;
  }
  .sign-send .sending-box {
    width: 100%;
    float: left;
    display: inline-block;
    text-align: center;
    margin: 50px 0 20px;
  }
  .sign-send .sending-box label {
    width: 100%;
    font-size: 20px;
    display: inline-block;
    color: #105c9d !important;
    font-weight: normal;
  }
  .sign-send .action-text .action-perform-text span {
    width: 100%;
    float: left;
    font-weight: normal;
  }
  .sign-send p.comment-p {
    width: 100%;
  }
  .sign-send p.comment-p label {
    width: 80px;
  }
  .sign-send p.comment-p .aside-area {
    width: 90%;
  }
  .OrderDetail2PrintableArea p {
    font-weight: 400;
    margin: 3px 0 0;
    float: left;
    width: 100%;
    line-height: 20px;
    color: #1f2532;
    padding: 0 15px;
    font-size: 14px;
    display: flex;
  }
  .OrderDetail2PrintableArea p label {
    font-weight: 500;
    color: #a5a3a3;
    width: 40%;
    margin-bottom: 2px;
    float: left;
    min-width: 40%;
  }
  .OrderDetail2PrintableArea p .aside-area {
    width: 55%;
    float: left;
  }
  .sign-send p.comment-p.steps-manage-area .aside-area .checkbox-style input:checked + label:before {
    content: '\2714';
    font-size: 14px;
    text-align: center;
    color: #fff !important;
    background: #105c9d !important;
    width: 23px;
    height: 23px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 3px;
    z-index: 10;
  }
  .sign-send p.comment-p.steps-manage-area .aside-area .checkbox-style label {
    color: #1f2532 !important;
  }
  .confirm-print .printableMiniArea h3 {
    width: 100% !important;
    float: left !important;
    display: block !important;
  }
  .confirm-print .printableMiniArea p {
    width: 100% !important;
    float: left !important;
    display: block !important;
  }
  .sign-send p.comment-p.additional-information label {
    width: auto;
    float: left;
    min-width: 22%;
  }
  .sign-send p.comment-p.additional-information span {
    float: left;
    width: 78%;
    margin-left: 0;
  }
  .sample-span-ui p.comment-p.additional-information span.remove-m-w {
    width: auto;
    margin-left: 1%;
    white-space: nowrap;
    font-size: 11px;
    padding-right: 0;
  }
  .sample-content-comma span {
    position: relative;
  }
  .sample-content-comma span:after {
    position: absolute;
    right: -5px;
    bottom: 0;
    content: ',';
  }
  .sample-content-comma span:last-child:after {
    display: none;
  }
  .sample-span-ui .additional-information span.remove-m-w {
    width: 80%;
    display: inline-block;
    margin-left: 0 !important;
    margin-right: 10px;
  }
  .action-display-block {
    display: flex !important;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1222px;
  }
}
@media screen and (max-width: 767px) {
  .patientExistingorder .checkBox span {
    font-size: 12px;
  }
  #patient-section.order-two-part span.check-icons i {
    top: 30px;
    right: 4px;
  }
  #patient-section.order-two-part .SearchPatient ul.four-column li {
    width: 47%;
  }
  #patient-section.order-two-part .SearchPatient ul li label {
    font-size: 12px;
  }
  #patient-section .SearchPatient .patientExistingorder .mobi-manage-content {
    width: 100%;
    display: inline-block;
    padding-bottom: 5px;
  }
  #patient-section .SearchPatient .patientExistingorder .mobi-manage-content .font-manage {
    font-size: 14px;
    padding: 0 10px;
    width: 50% !important;
    text-align: left !important;
  }
  #patient-section .SearchPatient .patientExistingorder .mobi-manage-content:last-child .col-lg-2 {
    width: 50% !important;
  }
  .header-fixed {
    position: inherit;
  }
  order-new .row {
    margin: 15px 0 0;
  }
  #patient-section span.check-icons i {
    padding-left: 3px;
    top: 33px;
    right: -17px;
    font-size: 10px;
    padding-top: 3px;
  }
  #patient-section .patientLab {
    margin-top: 0;
    padding: 0;
  }
  #patient-section h1.patientTitle {
    font-size: 15px;
    line-height: 35px;
  }
  #patient-section .SearchPatient {
    padding: 25px 10px;
  }
  #patient-section .SearchPatient h1 {
    font-size: 15px;
  }
  #patient-section .SearchPatient ul {
    width: 100%;
  }
  #patient-section .SearchPatient ul li {
    width: 100%;
    margin-top: 10px;
    margin-right: 0;
    text-align: left;
  }
  #patient-section .SearchPatient ul li label {
    margin-bottom: 2px;
  }
  #patient-section .SearchPatient ul li input.form-control {
    text-align: left;
  }
  #patient-section .SearchPatient .col-lg-2 {
    width: 100% !important;
  }
  #patient-section .SearchPatient .col-lg-2 .chooseLAb {
    widows: 100%;
    margin-top: 5px;
  }
  #patient-section .SearchPatient + .SearchPatient {
    text-align: center;
    display: inline-block;
  }
  #patient-section .SearchPatient + .SearchPatient .col-lg-2 {
    width: auto !important;
    max-width: 110px;
    padding-right: 5px;
    float: none;
    display: inline-block;
  }
  #construction-section {
    padding-top: 0;
  }
  #construction-section .cross-rectangle-post {
    width: 12px;
    height: 12px;
    bottom: -15px;
    right: 9.5px;
    line-height: 12px;
    font-size: 8px;
  }
  #construction-section .cross-square {
    width: 12px;
    height: 12px;
    top: -9px;
    right: -8px;
    line-height: 12px;
    font-size: 8px;
  }
  #construction-section .margin-top {
    margin-top: -10px !important;
  }
  #construction-section .row {
    margin: 0;
  }
  #construction-section h1.patientTitle {
    font-size: 15px;
    line-height: 35px;
  }
  #construction-section .Constructions {
    margin-top: 0;
    padding: 0;
  }
  #construction-section .SearchPatient {
    padding: 10px 0 0;
  }
  #construction-section .SearchPatient ul {
    text-align: center;
    display: inline-block;
  }
  #construction-section .SearchPatient ul li {
    margin-bottom: 10px;
    max-width: 130px;
    float: none;
    display: inline-block;
  }
  #construction-section .SearchPatient ul li a {
    font-size: 12px;
    line-height: 14px;
    border-bottom: solid 2px #fff;
  }
  #construction-section .SearchPatient ul li.active a {
    font-weight: normal;
    border-bottom-width: 2px;
  }
  #construction-section .SearchPatient.margin-less-top .constructions-sub-types ul {
    display: block;
  }
  #construction-section .SearchPatient.margin-less-top .constructions-sub-types ul li {
    max-width: inherit;
    float: left;
    display: block;
  }
  #construction-section .divider-new h1 {
    margin-top: 0;
    font-size: 18px;
  }
  #construction-section .clickonBoth {
    width: 100%;
    margin-left: 0;
  }
  #construction-section .clickonBoth .well-sm .range {
    display: none;
  }
  #construction-section .clickonBoth .well-sm .btn-group {
    margin-bottom: 10px;
  }
  #construction-section .margin-top-45 {
    margin-top: 5px !important;
  }
  #construction-section .SelectMaterial h3 {
    margin-top: 0;
    line-height: 24px;
  }
  #construction-section .SelectMaterial ul {
    margin-top: 0;
  }
  #construction-section .SelectMaterial ul li {
    width: 32%;
    margin-right: 1.33%;
    margin-bottom: 3px;
  }
  #construction-section .SelectMaterial ul li a {
    padding: 6px 3px;
    font-size: 11px;
  }
  #construction-section .SelectMaterial .designBox ul li {
    width: 100%;
  }
  #construction-section .SelectMaterial.designBox ul li {
    margin-bottom: 3px;
  }
  #construction-section .form-group .other-material {
    width: 100%;
  }
  #construction-section ul.singleSub li {
    width: 49%;
    margin: 1px 0.5%;
  }
  #construction-section #bridgeTeeth .row {
    margin: 0 -25px;
  }
  #construction-section .bridge-suggestion,
  #construction-section .partial-suggestion {
    margin-left: 0;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    font-size: 12px;
    font-weight: normal;
  }
  #delivery-section {
    padding-top: 0;
  }
  #delivery-section .DeliveryInformation {
    margin-top: 0;
    padding: 0;
  }
  #delivery-section h1.patientTitle {
    font-size: 15px;
    line-height: 35px;
  }
  #delivery-section .DelInf {
    width: 100%;
    margin-left: 0;
  }
  #delivery-section .deliveryDate {
    margin-top: 10px;
  }
  #delivery-section .deliveryDate h3 {
    font-size: 14px;
    font-weight: normal;
  }
  #delivery-section .deliveryDate ul {
    margin-top: 0;
  }
  #delivery-section .form-group {
    margin-right: 15px;
    float: left;
    width: 90%;
    margin-top: 0;
  }
  #delivery-section .form-group label {
    font-size: 14px;
    font-weight: normal;
    margin-top: 10px;
  }
  #delivery-section .form-group.commet-export-production .col-lg-12 {
    padding: 0;
  }
  #delivery-section .form-group.commet-export-production p {
    font-size: 12px;
    margin-bottom: 0;
  }
  #images-section {
    padding-top: 0;
  }
  #images-section .upload-images {
    margin-top: 15px;
    padding: 0 0 15px;
  }
  #images-section h1.patientTitle {
    font-size: 15px;
    line-height: 35px;
  }
  #images-section .images-upload {
    float: left;
    width: 100%;
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 10px;
    padding: 25px 0;
    font-size: 14px;
  }
  #images-section .images-upload img {
    width: 40px;
  }
  #images-section .row {
    width: 100%;
    float: left;
    margin: 0;
  }
  #images-section .row span.Images-circle {
    float: left;
    width: 50px;
    margin: 10px 0 0;
    display: block;
    height: inherit;
  }
  #images-section .row .images-file-name {
    width: 71%;
    float: left;
    margin: 5px 0 0 10px;
    padding: 0;
    text-align: left;
    font-size: 12px;
    word-wrap: break-word;
  }
  #images-section .row .file-icon {
    margin: 0;
    width: 50px;
  }
  #images-section .row .file-icon i {
    margin: 10px 0 0;
    font-size: 2em;
  }
  #sign-section {
    padding-top: 0;
  }
  #sign-section h1.patientTitle {
    font-size: 15px;
    line-height: 35px;
  }
  #sign-section .signSend {
    margin-top: 15px;
    padding: 0 0 15px;
    margin-bottom: 15px;
  }
  #sign-section .signSendbox h1 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  #sign-section .signSendbox p {
    font-size: 13px;
  }
  #sign-section .signSendbox img {
    width: 50px;
    margin: 20px 0 30px;
  }
  #sign-section .signSendbox .sign-with-img-icon span.right-sign-all-select {
    width: 30px;
    height: 30px;
    padding: 15px;
    bottom: 15px;
    right: -25px;
  }
  #sign-section .signSendbox .sign-with-img-icon span.right-sign-all-select:after {
    top: 6px;
    left: 5px;
    font-size: 20px;
  }
  #order-confirm .order-confirm {
    margin-top: 20px;
  }
  #order-confirm .order-confirm h1 {
    font-size: 22px;
  }
  #order-confirm .order-confirm .order-details {
    width: 100%;
    padding: 5px;
  }
  #order-confirm .order-confirm .order-details span {
    font-size: 13px;
    margin-top: 5px;
  }
  #order-confirm .order-confirm .order-button a {
    font-size: 15px;
  }
  #order-confirm .order-confirm .order-message p {
    width: 100%;
  }
  #denthub-header {
    padding: 5px 0 5px;
  }
  #denthub-header .no-padding-default {
    padding: 0;
  }
  #denthub-header .no-padding-default .col-md-4.col-lg-3.col-sm-5.col-xs-12.text-left {
    text-align: center;
  }
  #denthub-header .no-padding-default #imaginary_container {
    width: 100%;
    margin: 0 auto;
  }
  #denthub-header .no-padding-default #imaginary_container .searchBg {
    background: none;
  }
  #denthub-header .no-padding-default #imaginary_container .searchBg .form-control {
    height: 36px;
    display: none;
  }
  #denthub-header .no-padding-default #imaginary_container .searchBg span.input-group-addon {
    background: none !important;
    padding: 9px 0;
    color: #fff;
    text-align: right;
  }
  #denthub-header .no-padding-default #imaginary_container .searchBg span.input-group-addon button:focus {
    outline: none;
  }
  #denthub-header .no-padding-default #imaginary_container .searchBg span.input-group-addon button span + span {
    display: none;
  }
  #denthub-header .no-padding-default #imaginary_container .searchBg.search-open-big {
    position: fixed;
    left: 0;
    top: -5px;
    background: #000;
    z-index: 9;
    width: 100%;
    height: 45px;
    border-bottom: solid 1px #f7f8f9;
  }
  #denthub-header .no-padding-default #imaginary_container .searchBg.search-open-big span.input-group-addon {
    background: #f7f8f9 !important;
    padding: 15px 0;
    position: absolute;
    right: 0;
    top: 5px;
    width: 50px;
    z-index: 9;
    color: #000;
    text-align: center;
  }
  #denthub-header .no-padding-default #imaginary_container .searchBg.search-open-big span.input-group-addon button span {
    display: none;
  }
  #denthub-header .no-padding-default #imaginary_container .searchBg.search-open-big span.input-group-addon button span + span {
    display: block;
  }
  #denthub-header .no-padding-default #imaginary_container .searchBg.search-open-big .form-control {
    display: block;
    line-height: 50px;
    height: 49px;
    padding: 10px 10px 0;
    min-height: 49px;
  }
  #denthub-header .no-padding-default #Notifications .btn-default {
    padding: 6px 0;
  }
  #denthub-header .no-padding-default #Notifications .btn-default span.position-relative span.Notifications-notes {
    top: -24px;
  }
  #denthub-header .no-padding-default #Notifications .btn:active {
    box-shadow: none;
  }
  #denthub-header .no-padding-default #Notifications ul.dropdown-menu {
    left: -230px;
    top: 40px;
    max-width: 250px;
    border-top: 0;
  }
  #denthub-header .no-padding-default #Notifications.open button:after {
    left: -2px;
    top: 15px;
  }
  #denthub-header .no-padding-default #menu-profile {
    margin: 0 11px 0 0;
  }
  #denthub-header .no-padding-default #menu-profile .btn-default {
    padding: 6px 0;
  }
  #denthub-header .no-padding-default #menu-profile .btn:active {
    box-shadow: none;
  }
  #denthub-header .no-padding-default #menu-profile ul.dropdown-menu {
    margin: 0 -65px !important;
  }
  #denthub-header .no-padding-default .notificationTitle {
    text-align: center;
  }
  #denthub-header .no-padding-default .dropdown.open .dropdown-menu {
    min-width: 100% !important;
    left: -105px;
    top: 39px;
  }
  #denthub-header .no-padding-default .dropdown.open .dropdown-menu:after {
    left: initial;
    right: 0;
  }
  #denthub-header .no-padding-default .dropdown.open .dropdown-menu li {
    text-align: left;
  }
  #denthub-header .no-padding-default .dropdown.open .dropdown-menu li a {
    padding: 7px 10px !important;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  #denthub-header .no-padding-default .dropdown.open .dropdown-menu li h1 {
    text-align: left;
  }
  #denthub-header .no-padding-default .notificationTitle {
    font-size: 0;
  }
  #denthub-header .no-padding-default .notificationTitle i {
    font-size: 20px;
  }
  #denthub-header .no-padding-default .dropdown {
    float: right;
    margin-top: 0;
  }
  #denthub-header .no-padding-default .dropdown .btn-default {
    font-size: 0;
  }
  #denthub-header .no-padding-default .dropdown .btn-default i {
    font-size: 20px;
    margin-right: 10px;
  }
  #denthub-header a.dentHub-first-part img {
    height: 35px;
  }
  #denthub-header a.dentHub-second-part img {
    height: 17px;
    margin-left: 7px;
  }
  #denthub-header .dropdown-menu li a:focus {
    text-align: left;
  }
  #denthub-header .fa-arrow-left.arrow-left {
    margin: 9px -68px 0;
  }
  #denthub-header #menu-profile ul.dropdown-menu li.User-icons span.user-icons-img img {
    width: 100%;
  }
  #navBar .container {
    position: relative;
  }
  #navBar .container .navbar.navbar-default {
    position: static;
  }
  #navBar .container .navbar.navbar-default .navbar-header button {
    border: 0;
  }
  #navBar .container .navbar.navbar-default .navbar-header button span {
    background-color: #fff;
  }
  #navBar .container .navbar.navbar-default .navbar-header button:focus,
  #navBar .container .navbar.navbar-default .navbar-header button:hover {
    background: none;
  }
  #navBar .container .navbar.navbar-default .navbar-collapse {
    position: absolute;
    background: #1f2532;
    z-index: 99;
    width: 220px;
    top: 53px;
  }
  #navBar .container .navbar.navbar-default .navbar-collapse ul.nav {
    margin: 0;
  }
  #navBar .container .navbar.navbar-default .navbar-collapse ul.nav li a {
    padding: 11px 15px;
    border-bottom: solid 0 #1f2532;
    text-align: left;
    font-size: 13px;
    line-height: 33px;
  }
  #navBar .container .navbar.navbar-default .navbar-collapse ul.nav li a:hover {
    border-bottom: solid 0 #105c9d;
  }
  #navBar .container .navbar.navbar-default .navbar-collapse ul.nav li.dentist-order-manage a {
    width: 100%;
    padding: 15px 14px;
    line-height: inherit;
  }
  #navBar .container .navbar.navbar-default .navbar-collapse ul.nav li.dentist-order-manage:first-child a {
    width: 100%;
    padding: 15px 14px;
    line-height: inherit;
  }
  #navBar .container .navbar.navbar-default .navbar-collapse ul.nav li.dentist-order-manage:first-child a i {
    margin-right: 10px;
  }
  #navBar .container .navbar.navbar-default .navbar-collapse ul.nav li.lab-order-manage a {
    width: 100%;
    padding: 15px 14px;
    line-height: inherit;
  }
  #navBar .container .navbar.navbar-default .navbar-collapse ul.nav li.lab-order-manage:first-child a {
    width: 100%;
    padding: 15px 14px;
    line-height: inherit;
  }
  #navBar .container .navbar.navbar-default .navbar-collapse ul.nav li.lab-order-manage:first-child a i {
    margin-right: 10px;
  }
  #order-status-view {
    max-width: 350px;
    float: none;
    margin: 20px auto;
  }
  #order-status-view .no-padding-lr .no-padding-l {
    padding: 0 5px;
    margin-bottom: 15px;
  }
  #order-status-view .no-padding-lr .mid-sec-status {
    padding: 0 5px;
    margin-bottom: 15px;
  }
  #order-status-view .no-padding-lr .mid-sec-status h1:before {
    border-left: 0 solid #eee;
  }
  #order-status-view .no-padding-lr .no-padding-r {
    padding: 0 5px;
    margin-bottom: 15px;
  }
  #order-status-view .no-padding-lr .no-padding-r .Sent-by-dentist h1:before {
    border-left: 0 solid #eee;
  }
  #order-status-view .no-padding-lr .no-padding-r .Sent-by-dentist h1:after {
    border-left: 16px solid #105c9d;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
  }
  #order-list {
    background: none;
    min-height: inherit;
  }
  #order-list .order-list {
    margin-top: 20px;
    border: 0;
    background: none;
  }
  #order-list .order-list .no-padding-default .order-list-hading {
    padding: 0;
  }
  #order-list .order-list .no-padding-default .order-list-content {
    padding: 0;
  }
  #order-list .order-list .no-padding-default .order-list-content .mobi-manage-content {
    text-align: left;
    padding: 5px 0;
    width: 100%;
    float: left;
    word-wrap: break-word;
    border-bottom: solid 1px #f1f2f3;
  }
  #order-list .order-list .no-padding-default .order-list-content .mobi-manage-content .font-manage {
    font-size: 14px;
    padding: 0 10px;
    width: 50%;
  }
  #order-list .order-list .no-padding-default .order-list-content .mobi-manage-content .font-manage br {
    display: none;
  }
  #order-list .order-list .no-padding-default .order-list-content .mobi-manage-content .font-manage p {
    padding-left: 0;
  }
  #order-list .order-list .no-padding-default .order-list-content .mobi-manage-content .font-manage .lab-flag-icon {
    left: -25px;
  }
  #order-list .order-list .no-padding-default .order-list-content .mobi-manage-content:last-child {
    border: 0;
  }
  #order-list .order-list .order-list-box.no-padding-default {
    padding: 10px 15px;
    float: left;
    width: 100%;
    margin-bottom: 10px;
  }
  #my-modal-compatible .modal-dialog {
    width: 99%;
  }
  #my-modal-compatible .modal-dialog .modal-content {
    padding: 0 10px;
  }
  #my-modal-compatible .modal-dialog .modal-content .modal-footer .btn.btn-default {
    font-size: 12px;
    padding: 15px 5px;
    float: left;
    width: 100%;
    margin-bottom: 5px;
    margin-left: 0 !important;
  }
  #clinics,
  #users-lab,
  #users {
    margin-top: 20px;
  }
  #clinics .clinics ul,
  #users-lab .clinics ul,
  #users .clinics ul,
  #clinics .users-lab ul,
  #users-lab .users-lab ul,
  #users .users-lab ul,
  #clinics .users ul,
  #users-lab .users ul,
  #users .users ul {
    text-align: center;
    display: inline-block;
  }
  #clinics .clinics ul li,
  #users-lab .clinics ul li,
  #users .clinics ul li,
  #clinics .users-lab ul li,
  #users-lab .users-lab ul li,
  #users .users-lab ul li,
  #clinics .users ul li,
  #users-lab .users ul li,
  #users .users ul li {
    width: 100%;
    margin: 0 0 10px;
    min-width: inherit;
    float: none;
    overflow: hidden;
    display: inline-block;
    max-width: 375px;
  }
  #clinics .clinics ul li .clinic-block h3,
  #users-lab .clinics ul li .clinic-block h3,
  #users .clinics ul li .clinic-block h3,
  #clinics .users-lab ul li .clinic-block h3,
  #users-lab .users-lab ul li .clinic-block h3,
  #users .users-lab ul li .clinic-block h3,
  #clinics .users ul li .clinic-block h3,
  #users-lab .users ul li .clinic-block h3,
  #users .users ul li .clinic-block h3 {
    margin-bottom: 10px;
  }
  #clinics .clinics ul li .clinic-block .clinic-activation-block,
  #users-lab .clinics ul li .clinic-block .clinic-activation-block,
  #users .clinics ul li .clinic-block .clinic-activation-block,
  #clinics .users-lab ul li .clinic-block .clinic-activation-block,
  #users-lab .users-lab ul li .clinic-block .clinic-activation-block,
  #users .users-lab ul li .clinic-block .clinic-activation-block,
  #clinics .users ul li .clinic-block .clinic-activation-block,
  #users-lab .users ul li .clinic-block .clinic-activation-block,
  #users .users ul li .clinic-block .clinic-activation-block {
    padding: 10px 5px;
    margin-top: 5px;
  }
  #clinics .clinics ul li .clinic-block .clinic-activation-block .clinic-active,
  #users-lab .clinics ul li .clinic-block .clinic-activation-block .clinic-active,
  #users .clinics ul li .clinic-block .clinic-activation-block .clinic-active,
  #clinics .users-lab ul li .clinic-block .clinic-activation-block .clinic-active,
  #users-lab .users-lab ul li .clinic-block .clinic-activation-block .clinic-active,
  #users .users-lab ul li .clinic-block .clinic-activation-block .clinic-active,
  #clinics .users ul li .clinic-block .clinic-activation-block .clinic-active,
  #users-lab .users ul li .clinic-block .clinic-activation-block .clinic-active,
  #users .users ul li .clinic-block .clinic-activation-block .clinic-active {
    font-size: 12px;
    line-height: 20px;
  }
  #clinics .clinics ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #users-lab .clinics ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #users .clinics ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #clinics .users-lab ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #users-lab .users-lab ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #users .users-lab ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #clinics .users ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #users-lab .users ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #users .users ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic {
    margin-top: -1px;
  }
  #clinics .clinics ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #users-lab .clinics ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #users .clinics ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #clinics .users-lab ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #users-lab .users-lab ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #users .users-lab ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #clinics .users ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #users-lab .users ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #users .users ul li .clinic-block .clinic-activation-block .clinic-active-button a {
    padding: 0 5px;
    margin-top: -2px;
    float: left;
    font-size: 12px;
    margin-left: 1px;
  }
  #clinics .clinics ul li .clinic-block ul.multiple-check-organization li,
  #users-lab .clinics ul li .clinic-block ul.multiple-check-organization li,
  #users .clinics ul li .clinic-block ul.multiple-check-organization li,
  #clinics .users-lab ul li .clinic-block ul.multiple-check-organization li,
  #users-lab .users-lab ul li .clinic-block ul.multiple-check-organization li,
  #users .users-lab ul li .clinic-block ul.multiple-check-organization li,
  #clinics .users ul li .clinic-block ul.multiple-check-organization li,
  #users-lab .users ul li .clinic-block ul.multiple-check-organization li,
  #users .users ul li .clinic-block ul.multiple-check-organization li {
    min-width: inherit;
  }
  #add-clinic-modal .modal-dialog,
  #add-user-modal .modal-dialog,
  #add-user-lab-modal .modal-dialog,
  #add-lab-modal .modal-dialog {
    width: 95%;
    margin: 10px 2.5%;
  }
  #add-clinic-modal .modal-dialog .modal-content,
  #add-user-modal .modal-dialog .modal-content,
  #add-user-lab-modal .modal-dialog .modal-content,
  #add-lab-modal .modal-dialog .modal-content {
    padding: 0 10px;
  }
  #add-clinic-modal .modal-dialog .modal-content .modal-header h4.modal-title,
  #add-user-modal .modal-dialog .modal-content .modal-header h4.modal-title,
  #add-user-lab-modal .modal-dialog .modal-content .modal-header h4.modal-title,
  #add-lab-modal .modal-dialog .modal-content .modal-header h4.modal-title {
    font-size: 18px;
  }
  #add-clinic-modal .modal-dialog .modal-content .modal-header .close,
  #add-user-modal .modal-dialog .modal-content .modal-header .close,
  #add-user-lab-modal .modal-dialog .modal-content .modal-header .close,
  #add-lab-modal .modal-dialog .modal-content .modal-header .close {
    margin: -10px -15px;
  }
  #add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
  #add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
  #add-user-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
  #add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  #add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
  #add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
  #add-user-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
  #add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row {
    margin-bottom: 35px;
  }
  #add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .add-clinic-form-col,
  #add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .add-clinic-form-col,
  #add-user-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .add-clinic-form-col,
  #add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .add-clinic-form-col {
    width: 100%;
    float: left;
    margin-top: 10px;
  }
  #add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4,
  #add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4,
  #add-user-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4,
  #add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row h4 {
    margin-bottom: 5px;
    font-size: 12px;
  }
  #add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
  #add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
  #add-user-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
  #add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block {
    width: 100%;
    margin-top: 10px;
    margin-right: 0;
  }
  #add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.postcode-input,
  #add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.postcode-input,
  #add-user-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.postcode-input,
  #add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block.postcode-input {
    width: 70%;
    margin-right: 0;
  }
  #add-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
  #add-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
  #add-user-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
  #add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li {
    margin: 2px;
    padding: 2px 5px;
    font-size: 12px;
  }
  #add-clinic-modal .modal-dialog .modal-content .modal-footer,
  #add-user-modal .modal-dialog .modal-content .modal-footer,
  #add-user-lab-modal .modal-dialog .modal-content .modal-footer,
  #add-lab-modal .modal-dialog .modal-content .modal-footer {
    display: inline-block;
    text-align: right;
    width: 100%;
    padding: 10px 0 10px;
    margin: 0;
  }
  #add-clinic-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #add-user-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #add-user-lab-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #add-lab-modal .modal-dialog .modal-content .modal-footer .btn.btn-default {
    padding: 5px;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: normal;
    margin: 5px 0;
  }
  #connect-lab {
    margin-top: 20px;
  }
  #connect-lab .connect-lab .profile-left-section .profile-form-left-row {
    padding: 0 20px;
  }
  #connect-lab .connect-lab .profile-right-section {
    width: 100%;
    margin-right: 0;
  }
  #connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .jobs-div {
    width: 100%;
  }
  #connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .select-days-div .jobs-div-row {
    width: 100%;
  }
  #account-setting {
    margin-top: 15px;
  }
  #account-setting .account-setting .profile-left-section .profile-form-left .change-profile-picture input[type='button'] {
    bottom: 5px;
    right: 5px;
    padding: 0 10px;
    font-size: 12px;
  }
  #account-setting .account-setting .profile-right-section {
    width: 100%;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one {
    padding: 20px 10px;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .head-profile {
    padding: 0;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .head-profile h2 span {
    padding: 0 5px;
    font-size: 18px;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row {
    margin-bottom: 30px;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col {
    width: 100%;
    max-width: 100%;
    margin: 0 0 10px;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col label {
    margin-bottom: 5px;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.archive-order p {
    margin-left: 0;
    font-size: 10px;
    margin-right: 0;
    line-height: 13px;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.archive-order span {
    font-size: 11px;
    margin-right: 9px;
    margin-bottom: 10px;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.view-settings {
    margin-top: -70px;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.view-settings .switch-button {
    display: flex;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.view-settings .switch-button P {
    float: none;
  }
  #account-setting .account-setting .profile-right-section .save-change-btn input[type='button'] {
    padding: 2px 15px;
    font-size: 12px;
  }
  #account-setting > .container {
    padding: 0;
  }
  #profile-dentist {
    margin-top: 15px;
  }
  #profile-dentist .profile-dentist .profile-left-section {
    padding-top: 15px;
  }
  #profile-dentist .profile-dentist .profile-left-section .head-profile {
    padding: 0;
  }
  #profile-dentist .profile-dentist .profile-left-section .head-profile h2 span {
    padding: 0 5px;
    font-size: 18px;
  }
  #profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row {
    margin-bottom: 0;
    padding: 0 15px;
  }
  #profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row .profile-form-left-col {
    width: 100%;
    margin-bottom: 20px;
  }
  #profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row.change-pass-div .profile-form-left-col:nth-child(2) input[type='button'] {
    font-size: 15px;
  }
  #profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row.change-pass-div .profile-form-left-col.extra-top-margin-add {
    margin-top: -20px !important;
  }
  #profile-dentist .profile-dentist .profile-left-section .profile-form-left .change-profile-picture input[type='button'] {
    font-size: 14px;
    padding: 0 10px;
    bottom: 5px;
    right: 5px;
  }
  #profile-dentist .profile-dentist .profile-left-section .notifications {
    padding-top: 15px;
  }
  #profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left {
    padding: 0 10px;
  }
  #profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .switch-button .switch {
    margin-right: 8px;
  }
  #profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .switch-button .switch.checked {
    margin-right: 8px;
  }
  #profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .switch-button p {
    width: 76%;
  }
  #profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .switch-button input {
    width: 44px !important;
    margin: 0 8px 0 0;
    padding: 0;
  }
  #profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left:last-child {
    min-height: inherit;
  }
  #profile-dentist .profile-dentist .profile-right-section {
    width: 100%;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one {
    padding: 15px 10px 10px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .head-profile {
    padding: 0;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .head-profile h2 span {
    padding: 0 5px;
    font-size: 18px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col {
    width: 100%;
    max-width: inherit;
    margin-bottom: 20px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col {
    width: 100% !important;
    margin: 0;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin .switch-button p {
    width: 76%;
    text-align: left;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin .switch-button .switch {
    margin-right: 8px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin .switch-button .switch.checked {
    margin-right: 8px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin.auto-archive span {
    font-size: 12px;
    margin-right: 10px;
    margin-bottom: 30px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin.auto-archive p {
    font-size: 11px;
    line-height: 13px;
    margin-right: 0;
    margin-left: 0;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-two {
    padding: 10px;
    margin-top: 15px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-two .head-profile {
    padding: 0;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-two .head-profile h2 span {
    padding: 0 5px;
    font-size: 18px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-two .profile-form-right .profile-form-right-row .profile-form-right-col {
    width: 100%;
    max-width: inherit;
    margin: 5px 0;
  }
  #profile-dentist .container {
    padding: 0;
  }
  #login-page .card-container.card {
    margin: 20px auto;
  }
  #login-page .card-container.card label + .ui-select-container.select2.select2-container + label {
    width: 100%;
  }
  #login-page .card-container.card label + .ui-select-container.select2.select2-container + label span {
    float: right;
  }
  #login-page .card-container.card label + .ui-select-container.select2.select2-container {
    text-align: right;
  }
  #connect-to-lab {
    margin-top: 15px;
  }
  #connect-to-lab .connect-to-lab .search-box-lab .search-input {
    width: 100%;
  }
  #connect-to-lab .connect-to-lab .search-box-lab .search-input tags-input {
    width: 90%;
    top: 0;
    left: 10%;
  }
  #connect-to-lab .connect-to-lab .search-box-lab .search-input i.fa.fa-search {
    font-size: 14px;
    left: 12px;
    top: 13px;
  }
  #connect-to-lab .connect-to-lab .search-box-lab .search-input input[type='search'] {
    width: 100%;
    height: 42px;
    line-height: 42px;
  }
  #connect-to-lab .connect-to-lab .search-box-lab input[type='button'] {
    width: 100%;
    height: 42px;
    line-height: 42px;
  }
  #connect-to-lab .connect-to-lab .lab-report-section {
    overflow-x: scroll;
  }
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-head {
    width: 1192px;
  }
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-body {
    width: 1192px;
  }
  #connect-to-lab .connect-to-lab .pagination-center {
    margin: 0 0 20px;
  }
  #connect-to-lab .connect-to-lab .pagination-center li a {
    padding: 3px 5px;
    font-size: 12px;
  }
  #lab-profile {
    margin-top: 15px;
  }
  #lab-profile .lab-profile .profile-left-section {
    padding-top: 20px;
  }
  #lab-profile .lab-profile .profile-left-section .head-profile {
    padding: 0;
  }
  #lab-profile .lab-profile .profile-left-section .head-profile h2 span {
    padding: 0 5px;
    font-size: 18px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row {
    padding: 0 10px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div {
    padding: 3px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-left .upload-file-name {
    padding: 5px 0 0;
    min-height: 30px;
    max-height: 30px;
    width: 80%;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-left .upload-file-name a.link-icon:before,
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-left .upload-file-name a.link-icon.link-file[href]:before {
    margin-top: 3px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-left img {
    margin-top: 6px;
    width: 11px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-right .upload-file-div {
    width: auto;
    font-size: 12px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-right .upload-file-div .filechoose-div {
    padding: 0 5px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-right span {
    font-size: 11px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row label {
    width: 50%;
    margin-left: 0;
    margin-top: 0;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row label input {
    margin: -1px 5px 0 0;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row label p {
    font-size: 11px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row .delivery-section {
    width: 50%;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row .delivery-section input {
    padding: 1px 0;
    font-size: 12px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row .delivery-section span {
    width: auto;
    margin-bottom: 0;
    font-size: 11px;
    line-height: 22px;
    margin-top: 0;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row .delivery-section .tooltip.ng-scope.ng-isolate-scope.top.file-name-tooltip.fade.in {
    width: 130px;
  }
  #lab-profile .lab-profile .profile-right-section {
    width: 100%;
    margin-top: 15px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-one {
    padding: 20px 5px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-one .head-profile {
    padding: 0;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-one .head-profile h2 span {
    padding: 0 5px;
    font-size: 18px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-one .media-section .media-section-block {
    width: 100%;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-one .media-section .media-section-block:first-child {
    margin-bottom: 25px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two {
    padding: 20px 5px;
    margin-top: 15px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .head-profile {
    padding: 0;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .head-profile h2 span {
    padding: 0 5px;
    font-size: 18px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block {
    width: 100%;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row {
    padding-left: 5px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row label {
    width: 75px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row label input[type='checkbox'].checkedInput {
    width: 100%;
    height: 30px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row label input[type='checkbox'].notCheckedInput {
    width: 100%;
    height: 30px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row label input[type='checkbox'] + span {
    line-height: 30px;
    font-size: 14px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row .delivery-section {
    width: 70%;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row .delivery-section span {
    font-size: 12px;
    line-height: 31px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row .delivery-section .ng-isolate-scope tr td input {
    line-height: 28px;
    min-height: 28px;
    height: 28px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block:nth-child(2) {
    width: 100%;
    margin-top: 25px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block:nth-child(2) .contact-form .contact-form-row i {
    width: 30px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .calendar-section {
    padding-left: 0;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .calendar-section .btn-sm {
    padding: 5px 8px;
  }
  #lab-profile .lab-profile .notifications .profile-left-section .head-profile h2 {
    margin-top: 20px;
  }
  #lab-profile .lab-profile .notifications .profile-left-section .profile-form-left {
    padding: 0 15px !important;
  }
  #lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-email .checkbox-inline p.email-noti-para {
    width: 80%;
  }
  #lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-Select .switch-button .switch {
    margin-right: 8px;
  }
  #lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-Select .switch-button .switch.checked {
    margin-right: 8px;
  }
  #lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-Select .switch-button p {
    width: 76%;
  }
  #lab-profile .container {
    padding: 0;
  }
  #add-lab-technician-modal,
  #invite-lab,
  #add-lab-modal,
  #delete-clinic-modal,
  #remove-user-modal,
  #cancel-order,
  #archive-order,
  #accept-order,
  #clinic-received-delivery,
  #lab-work-started,
  #decline-order,
  #change-password-modal {
    padding-left: 0 !important;
  }
  #add-lab-technician-modal .modal-dialog,
  #invite-lab .modal-dialog,
  #add-lab-modal .modal-dialog,
  #delete-clinic-modal .modal-dialog,
  #remove-user-modal .modal-dialog,
  #cancel-order .modal-dialog,
  #archive-order .modal-dialog,
  #accept-order .modal-dialog,
  #clinic-received-delivery .modal-dialog,
  #lab-work-started .modal-dialog,
  #decline-order .modal-dialog,
  #change-password-modal .modal-dialog {
    width: 96%;
    margin: 10px 2%;
  }
  #add-lab-technician-modal .modal-dialog .modal-content,
  #invite-lab .modal-dialog .modal-content,
  #add-lab-modal .modal-dialog .modal-content,
  #delete-clinic-modal .modal-dialog .modal-content,
  #remove-user-modal .modal-dialog .modal-content,
  #cancel-order .modal-dialog .modal-content,
  #archive-order .modal-dialog .modal-content,
  #accept-order .modal-dialog .modal-content,
  #clinic-received-delivery .modal-dialog .modal-content,
  #lab-work-started .modal-dialog .modal-content,
  #decline-order .modal-dialog .modal-content,
  #change-password-modal .modal-dialog .modal-content {
    padding: 0 15px;
  }
  #add-lab-technician-modal .modal-dialog .modal-content .modal-header h4.modal-title,
  #invite-lab .modal-dialog .modal-content .modal-header h4.modal-title,
  #add-lab-modal .modal-dialog .modal-content .modal-header h4.modal-title,
  #delete-clinic-modal .modal-dialog .modal-content .modal-header h4.modal-title,
  #remove-user-modal .modal-dialog .modal-content .modal-header h4.modal-title,
  #cancel-order .modal-dialog .modal-content .modal-header h4.modal-title,
  #archive-order .modal-dialog .modal-content .modal-header h4.modal-title,
  #accept-order .modal-dialog .modal-content .modal-header h4.modal-title,
  #clinic-received-delivery .modal-dialog .modal-content .modal-header h4.modal-title,
  #lab-work-started .modal-dialog .modal-content .modal-header h4.modal-title,
  #decline-order .modal-dialog .modal-content .modal-header h4.modal-title,
  #change-password-modal .modal-dialog .modal-content .modal-header h4.modal-title {
    font-size: 18px;
  }
  #add-lab-technician-modal .modal-dialog .modal-content .modal-body,
  #invite-lab .modal-dialog .modal-content .modal-body,
  #add-lab-modal .modal-dialog .modal-content .modal-body,
  #delete-clinic-modal .modal-dialog .modal-content .modal-body,
  #remove-user-modal .modal-dialog .modal-content .modal-body,
  #cancel-order .modal-dialog .modal-content .modal-body,
  #archive-order .modal-dialog .modal-content .modal-body,
  #accept-order .modal-dialog .modal-content .modal-body,
  #clinic-received-delivery .modal-dialog .modal-content .modal-body,
  #lab-work-started .modal-dialog .modal-content .modal-body,
  #decline-order .modal-dialog .modal-content .modal-body,
  #change-password-modal .modal-dialog .modal-content .modal-body {
    padding: 0;
  }
  #add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
  #invite-lab .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
  #add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
  #delete-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
  #remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
  #cancel-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
  #archive-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
  #accept-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
  #clinic-received-delivery .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
  #lab-work-started .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
  #decline-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row,
  #change-password-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row {
    margin-bottom: 30px;
  }
  #add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
  #invite-lab .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
  #add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
  #delete-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
  #remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
  #cancel-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
  #archive-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
  #accept-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
  #clinic-received-delivery .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
  #lab-work-started .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
  #decline-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block,
  #change-password-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block {
    width: 100%;
    margin: 10px 0 0;
    position: relative;
  }
  #add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
  #invite-lab .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
  #add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
  #delete-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
  #remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
  #cancel-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
  #archive-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
  #accept-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
  #clinic-received-delivery .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
  #lab-work-started .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
  #decline-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li,
  #change-password-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block ul li {
    margin: 2px;
    padding: 2px 5px;
    font-size: 12px;
  }
  #add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block h4,
  #invite-lab .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block h4,
  #add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block h4,
  #delete-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block h4,
  #remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block h4,
  #cancel-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block h4,
  #archive-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block h4,
  #accept-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block h4,
  #clinic-received-delivery .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block h4,
  #lab-work-started .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block h4,
  #decline-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block h4,
  #change-password-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block h4 {
    margin-bottom: 3px;
    font-size: 13px;
  }
  #add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block input,
  #invite-lab .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block input,
  #add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block input,
  #delete-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block input,
  #remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block input,
  #cancel-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block input,
  #archive-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block input,
  #accept-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block input,
  #clinic-received-delivery .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block input,
  #lab-work-started .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block input,
  #decline-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block input,
  #change-password-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block input {
    padding: 4px 4px;
    font-size: 11px;
  }
  #add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .check-icons i,
  #invite-lab .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .check-icons i,
  #add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .check-icons i,
  #delete-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .check-icons i,
  #remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .check-icons i,
  #cancel-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .check-icons i,
  #archive-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .check-icons i,
  #accept-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .check-icons i,
  #clinic-received-delivery .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .check-icons i,
  #lab-work-started .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .check-icons i,
  #decline-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .check-icons i,
  #change-password-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row .street-block .check-icons i {
    padding-top: 3px;
    top: 23px;
    right: 1px;
    font-size: 10px;
    padding-left: 3px;
  }
  #add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label,
  #invite-lab .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label,
  #add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label,
  #delete-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label,
  #remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label,
  #cancel-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label,
  #archive-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label,
  #accept-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label,
  #clinic-received-delivery .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label,
  #lab-work-started .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label,
  #decline-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label,
  #change-password-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label {
    margin-top: 0;
  }
  #add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type='checkbox'] + label,
  #invite-lab .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type='checkbox'] + label,
  #add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type='checkbox'] + label,
  #delete-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type='checkbox'] + label,
  #remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type='checkbox'] + label,
  #cancel-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type='checkbox'] + label,
  #archive-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type='checkbox'] + label,
  #accept-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type='checkbox'] + label,
  #clinic-received-delivery .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type='checkbox'] + label,
  #lab-work-started .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type='checkbox'] + label,
  #decline-order .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type='checkbox'] + label,
  #change-password-modal .modal-dialog .modal-content .modal-body .add-clinic-form .add-clinic-form-row label input[type='checkbox'] + label {
    margin: 0;
  }
  #add-lab-technician-modal .modal-dialog .modal-content .modal-body .add-clinic-form p,
  #invite-lab .modal-dialog .modal-content .modal-body .add-clinic-form p,
  #add-lab-modal .modal-dialog .modal-content .modal-body .add-clinic-form p,
  #delete-clinic-modal .modal-dialog .modal-content .modal-body .add-clinic-form p,
  #remove-user-modal .modal-dialog .modal-content .modal-body .add-clinic-form p,
  #cancel-order .modal-dialog .modal-content .modal-body .add-clinic-form p,
  #archive-order .modal-dialog .modal-content .modal-body .add-clinic-form p,
  #accept-order .modal-dialog .modal-content .modal-body .add-clinic-form p,
  #clinic-received-delivery .modal-dialog .modal-content .modal-body .add-clinic-form p,
  #lab-work-started .modal-dialog .modal-content .modal-body .add-clinic-form p,
  #decline-order .modal-dialog .modal-content .modal-body .add-clinic-form p,
  #change-password-modal .modal-dialog .modal-content .modal-body .add-clinic-form p {
    font-size: 12px;
  }
  #add-lab-technician-modal .modal-dialog .modal-content .modal-footer,
  #invite-lab .modal-dialog .modal-content .modal-footer,
  #add-lab-modal .modal-dialog .modal-content .modal-footer,
  #delete-clinic-modal .modal-dialog .modal-content .modal-footer,
  #remove-user-modal .modal-dialog .modal-content .modal-footer,
  #cancel-order .modal-dialog .modal-content .modal-footer,
  #archive-order .modal-dialog .modal-content .modal-footer,
  #accept-order .modal-dialog .modal-content .modal-footer,
  #clinic-received-delivery .modal-dialog .modal-content .modal-footer,
  #lab-work-started .modal-dialog .modal-content .modal-footer,
  #decline-order .modal-dialog .modal-content .modal-footer,
  #change-password-modal .modal-dialog .modal-content .modal-footer {
    display: inline-block;
    text-align: right;
    width: 100%;
    padding: 10px 0 10px;
    margin: 0;
  }
  #add-lab-technician-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #invite-lab .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #add-lab-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #delete-clinic-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #remove-user-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #cancel-order .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #archive-order .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #accept-order .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #clinic-received-delivery .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #lab-work-started .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #decline-order .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #change-password-modal .modal-dialog .modal-content .modal-footer .btn.btn-default {
    padding: 5px;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: normal;
    margin: 5px 0;
  }
  #cancel-order .modal-dialog .modal-content .modal-header .close {
    margin: -5px -14px;
  }
  #archive-order .modal-dialog .modal-content .modal-header .close,
  #un-archive-order .modal-dialog .modal-content .modal-header .close {
    margin: -5px -14px;
  }
  #archive-order .modal-dialog .modal-content .modal-body,
  #un-archive-order .modal-dialog .modal-content .modal-body {
    padding-top: 30px;
    padding-bottom: 40px;
  }
  #change-password-modal {
    margin: 0 20px;
  }
  #change-password-modal .modal-dialog .modal-content .modal-body .add-clinic-form {
    width: 90%;
  }
  #invite-lab .modal-dialog .modal-content .modal-header .close {
    margin: -3px -10px;
  }
  #invite-lab .modal-dialog .modal-content .modal-body {
    overflow: hidden;
    padding-top: 15px;
  }
  #invite-lab .modal-dialog .modal-content .modal-body .add-clinic-form h5 {
    font-size: 15px;
    font-weight: normal;
    text-align: left;
  }
  #rate-order .modal-dialog {
    width: 96%;
    margin: 10px 2%;
  }
  #rate-order .modal-dialog .modal-content {
    padding: 15px 0px 5px;
  }
  #rate-order .modal-dialog .modal-content .modal-header .close {
    margin: -15px 7px;
  }
  #rate-order .modal-dialog .modal-content .modal-body p {
    font-size: 12px;
  }
  #rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div {
    margin: 0;
  }
  #rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 {
    width: 100%;
  }
  #rate-order .modal-dialog .modal-content .modal-footer {
    display: inline-block;
    text-align: right;
    width: 100%;
    padding: 25px 10px 10px;
    margin: 0;
  }
  #rate-order .modal-dialog .modal-content .modal-footer .btn.btn-default {
    padding: 5px;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: normal;
    margin: 5px 0;
  }
  #rate-order.one-rate-option .modal-dialog,
  #rate-order.two-rate-option .modal-dialog,
  #rate-order.three-rate-option .modal-dialog,
  #rate-order.four-rate-option .modal-dialog {
    width: 96%;
    margin: 10px 2%;
  }
  #rate-order.one-rate-option .modal-dialog .modal-content,
  #rate-order.two-rate-option .modal-dialog .modal-content,
  #rate-order.three-rate-option .modal-dialog .modal-content,
  #rate-order.four-rate-option .modal-dialog .modal-content {
    padding: 15px 0px 5px;
  }
  #rate-order.one-rate-option .modal-dialog .modal-content .modal-header .close,
  #rate-order.two-rate-option .modal-dialog .modal-content .modal-header .close,
  #rate-order.three-rate-option .modal-dialog .modal-content .modal-header .close,
  #rate-order.four-rate-option .modal-dialog .modal-content .modal-header .close {
    margin: -15px 7px;
  }
  #rate-order.one-rate-option .modal-dialog .modal-content .modal-body p,
  #rate-order.two-rate-option .modal-dialog .modal-content .modal-body p,
  #rate-order.three-rate-option .modal-dialog .modal-content .modal-body p,
  #rate-order.four-rate-option .modal-dialog .modal-content .modal-body p {
    font-size: 12px;
  }
  #rate-order.one-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div,
  #rate-order.two-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div,
  #rate-order.three-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div,
  #rate-order.four-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div {
    margin: 0;
  }
  #rate-order.one-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2,
  #rate-order.two-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2,
  #rate-order.three-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2,
  #rate-order.four-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 {
    width: 100%;
  }
  #rate-order.one-rate-option .modal-dialog .modal-content .modal-footer,
  #rate-order.two-rate-option .modal-dialog .modal-content .modal-footer,
  #rate-order.three-rate-option .modal-dialog .modal-content .modal-footer,
  #rate-order.four-rate-option .modal-dialog .modal-content .modal-footer {
    display: inline-block;
    text-align: right;
    width: 100%;
    padding: 25px 10px 10px;
    margin: 0;
  }
  #rate-order.one-rate-option .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #rate-order.two-rate-option .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #rate-order.three-rate-option .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #rate-order.four-rate-option .modal-dialog .modal-content .modal-footer .btn.btn-default {
    padding: 5px;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: normal;
    margin: 5px 0;
  }
  #dentist-order-detail {
    margin-top: 15px;
  }
  #dentist-order-detail .container {
    padding: 0;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail {
    padding: 5px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part h3 {
    font-size: 13px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part p {
    font-size: 13px;
    line-height: 15px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part ul li {
    font-size: 13px;
    line-height: 15px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part.full-construction ul li span:after {
    right: 2px;
    bottom: 15px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part h3 {
    font-size: 13px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part p {
    font-size: 13px;
    line-height: 15px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part.order-created i {
    font-size: 30px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part.order-created span p {
    font-weight: normal;
    font-size: 10px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part .modify-date button {
    margin: -12px 85px -20px 0;
    float: right;
    font-weight: normal;
    padding: 4px 2px;
    font-size: 11px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part .modify-date span {
    width: 80px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .orders-pics {
    padding: 8px;
    margin-top: 5px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .orders-pics ul {
    width: 70%;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .orders-pics ul li {
    width: 48%;
    height: 46px;
    max-width: 100px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .orders-pics ul li img {
    width: 100%;
    height: auto;
    max-height: 45px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .orders-pics ul button {
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 13px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .orders-pics .order-pic-upload {
    width: 30%;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .orders-pics .order-pic-upload .upload-image {
    margin-top: 0;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .orders-pics .order-pic-upload .upload-image span {
    height: 30px;
    line-height: 20px;
    font-size: 10px;
  }
  #dentist-order-detail .container .dentist-order-detail .chat-box {
    padding: 5px 0;
  }
  #dentist-order-detail .container .dentist-order-detail .chat-box .chat-box-date {
    padding: 5px 0px;
  }
  #dentist-order-detail .container .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-login-person {
    padding: 20px 10px 0px;
  }
  #dentist-order-detail .container .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-login-person .chat-front-person-chat {
    width: 77%;
  }
  #dentist-order-detail .container .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-front-person {
    padding: 20px 10px 0px;
  }
  #dentist-order-detail .container .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-front-person .chat-front-person-chat {
    width: 77%;
    float: right;
  }
  #dentist-order-detail .container .dentist-order-detail .chat-option-box {
    margin-top: 2px;
  }
  #dentist-order-detail .container .dentist-order-detail .chat-option-box input {
    padding: 15px;
  }
  #dentist-order-detail .container .dentist-order-detail .chat-send-btn input {
    padding: 5px 40px;
  }
  p.red-astrix-mark {
    font-size: 13px;
    line-height: 14px;
    padding: 0 2px;
  }
  .orders-pics-resend ul li {
    width: 100px;
    height: 50px;
  }
  .orders-pics-resend ul li img {
    width: 100%;
    height: 45px;
  }
  #resend-order-button button:focus {
    outline: none;
  }
  #order-status-view .no-padding-lr .Sent-by-dentist {
    width: 96%;
  }
  #order-status-view .no-padding-lr .Sent-by-dentist ul li {
    padding: 5px;
  }
  #order-status-view .no-padding-lr .Sent-by-dentist ul li p {
    font-size: 11px;
  }
  #order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-right.width-resize {
    font-size: 12px;
  }
  #accept-order .modal-dialog .modal-content .modal-header .close,
  #clinic-received-delivery .modal-dialog .modal-content .modal-header .close,
  #lab-work-started .modal-dialog .modal-content .modal-header .close,
  #decline-order .modal-dialog .modal-content .modal-header .close {
    margin: -5px -12px;
  }
  #imgModal .align-img-cross {
    width: 60%;
  }
  #imgModal img {
    width: 100%;
    height: inherit;
  }
}
@media all and (orientation: landscape) and (max-width: 767px) {
  login .container,
  forgot .container {
    display: block;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #patient-section.order-two-part .SearchPatient ul li label {
    font-size: 12px;
  }
  #patient-section.order-two-part .SearchPatient ul li p.red-astrix-mark {
    padding: 0;
  }
  #patient-section .SearchPatient .patientExistingorder .col-lg-12.order-list-box {
    float: left;
    width: 100%;
  }
  .clearfix-left {
    clear: left;
  }
  .color-sampling-radio {
    width: 50%;
    margin-top: 13px;
  }
  order-new .row {
    margin: 15px 0 0;
  }
  #patient-section span.check-icons i {
    padding-left: 3px;
    top: 39px;
    right: -17px;
    font-size: 10px;
    padding-top: 3px;
  }
  #patient-section .patientLab {
    margin-top: 120px;
    padding: 0;
  }
  #patient-section h1.patientTitle {
    font-size: 15px;
    line-height: 35px;
  }
  #patient-section .SearchPatient {
    padding: 25px 10px;
  }
  #patient-section .SearchPatient h1 {
    font-size: 15px;
  }
  #patient-section .SearchPatient ul {
    width: 100%;
  }
  #patient-section .SearchPatient ul li {
    margin-top: 10px;
    text-align: left;
  }
  #patient-section .SearchPatient ul li label {
    margin-bottom: 2px;
  }
  #patient-section .SearchPatient ul li input.form-control {
    text-align: left;
  }
  #patient-section .SearchPatient .col-lg-2 {
    width: 100% !important;
  }
  #patient-section .SearchPatient .col-lg-2 .chooseLAb {
    width: 100%;
    margin-top: 5px;
  }
  #patient-section .SearchPatient + .SearchPatient {
    text-align: left;
    display: inline-block;
  }
  #patient-section .SearchPatient + .SearchPatient .col-lg-2 {
    width: 100% !important;
    max-width: 110px;
    padding-right: 5px;
    float: none;
    display: inline-block;
  }
  #construction-section .cross-rectangle-post {
    width: 12px;
    height: 12px;
    bottom: -15px;
    right: 9.5px;
    line-height: 12px;
    font-size: 8px;
  }
  #construction-section .cross-square {
    width: 12px;
    height: 12px;
    top: -9px;
    right: -8px;
    line-height: 12px;
    font-size: 8px;
  }
  #construction-section .margin-top {
    margin-top: 0 !important;
  }
  #construction-section .row {
    margin: 0;
  }
  #construction-section h1.patientTitle {
    font-size: 15px;
    line-height: 35px;
  }
  #construction-section .Constructions {
    margin-top: 0;
    padding: 0;
  }
  #construction-section .SearchPatient {
    padding: 10px 0 0;
  }
  #construction-section .SearchPatient ul li {
    margin-bottom: 10px;
  }
  #construction-section .SearchPatient ul li a {
    font-size: 15px;
    line-height: 24px;
  }
  #construction-section .SearchPatient ul li.active a {
    border-bottom-width: 2px;
  }
  #construction-section .divider-new h1 {
    margin-top: 0;
    font-size: 18px;
  }
  #construction-section .clickonBoth .well-sm .range {
    display: none;
  }
  #construction-section .clickonBoth .well-sm .btn-group {
    margin-bottom: 10px;
  }
  #construction-section .margin-top-45 {
    margin-top: 5px !important;
  }
  #construction-section .SelectMaterial h3 {
    margin-top: 0;
    line-height: 24px;
  }
  #construction-section .SelectMaterial ul {
    margin-top: 0;
  }
  #construction-section .SelectMaterial ul li {
    width: 32%;
    margin-right: 1.33%;
    margin-bottom: 3px;
  }
  #construction-section .SelectMaterial ul li a {
    padding: 6px 3px;
    font-size: 11px;
  }
  #construction-section .SelectMaterial .designBox ul li {
    width: 100%;
  }
  #construction-section .SelectMaterial.designBox ul li {
    margin-bottom: 3px;
  }
  #construction-section .form-group .other-material {
    width: 100%;
  }
  #construction-section ul.singleSub {
    width: 100%;
  }
  #construction-section ul.singleSub li {
    width: 32%;
    margin: 1px 0.5%;
  }
  #construction-section #bridgeTeeth .row {
    margin: 0 -25px;
  }
  #construction-section .bridge-suggestion,
  #construction-section .partial-suggestion {
    margin-left: 0;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    font-size: 12px;
    font-weight: normal;
  }
  #delivery-section {
    padding-top: 0;
  }
  #delivery-section .DeliveryInformation {
    margin-top: 0;
    padding: 0;
  }
  #delivery-section h1.patientTitle {
    font-size: 15px;
    line-height: 35px;
  }
  #delivery-section .DelInf {
    width: 100%;
    margin-left: 0;
  }
  #delivery-section .deliveryDate {
    margin-top: 10px;
  }
  #delivery-section .deliveryDate h3 {
    font-size: 14px;
    font-weight: normal;
  }
  #delivery-section .deliveryDate ul {
    margin-top: 0;
  }
  #delivery-section .form-group {
    margin-right: 15px;
    float: left;
    width: 95.6%;
    margin-top: 0;
  }
  #delivery-section .form-group label {
    font-size: 14px;
    font-weight: normal;
    margin-top: 10px;
  }
  #images-section {
    padding-top: 0;
  }
  #images-section .upload-images {
    margin-top: 15px;
    padding: 0 0 15px;
  }
  #images-section h1.patientTitle {
    font-size: 15px;
    line-height: 35px;
  }
  #images-section .images-upload {
    float: left;
    margin-bottom: 0;
    margin-top: 10px;
    padding: 50px 0;
  }
  #images-section .row {
    width: 100%;
    float: left;
    margin: 0;
  }
  #images-section .row span.Images-circle {
    float: left;
    width: 50px;
    margin: 10px 0 0;
    display: block;
    height: inherit;
  }
  #images-section .row .images-file-name {
    width: 90%;
    float: left;
    margin: 5px 0 0 10px;
    padding: 0;
    text-align: left;
    font-size: 12px;
    word-wrap: break-word;
  }
  #images-section .row .file-icon {
    margin: 0;
    width: 50px;
  }
  #images-section .row .file-icon i {
    margin: 10px 0 0;
    font-size: 2em;
  }
  #sign-section {
    padding-top: 0;
  }
  #sign-section h1.patientTitle {
    font-size: 15px;
    line-height: 35px;
  }
  #sign-section .signSend {
    margin-top: 15px;
    padding: 0 0 15px;
    margin-bottom: 15px;
  }
  #sign-section .signSendbox h1 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  #sign-section .signSendbox p {
    font-size: 13px;
  }
  #sign-section .signSendbox img {
    width: 50px;
    margin: 20px 0 30px;
  }
  #sign-section .signSendbox .sign-with-img-icon span.right-sign-all-select {
    width: 30px;
    height: 30px;
    padding: 15px;
    bottom: 15px;
    right: -25px;
  }
  #sign-section .signSendbox .sign-with-img-icon span.right-sign-all-select:after {
    top: 6px;
    left: 5px;
    font-size: 20px;
  }
  #denthub-header #imaginary_container {
    width: 100%;
  }
  #denthub-header #menu-profile ul.dropdown-menu {
    margin: 0 -65px !important;
    top: 49px;
    left: -105px;
  }
  #denthub-header .notificationTitle {
    font-size: 0;
  }
  #denthub-header .notificationTitle i {
    font-size: 20px;
  }
  #denthub-header #Notifications ul.dropdown-menu {
    top: 42px;
    left: -229px;
    border-top: 0;
  }
  #denthub-header #Notifications .btn-default span.position-relative span.Notifications-notes {
    top: -24px;
  }
  #denthub-header #Notifications.open button:after {
    left: -2px;
    top: 16px;
  }
  #denthub-header .dropdown {
    float: right;
    margin-top: 8px;
  }
  #denthub-header .dropdown .btn-default {
    font-size: 0;
  }
  #denthub-header .dropdown .btn-default i {
    font-size: 20px;
    margin-right: 10px;
  }
  #denthub-header .dropdown-menu li a:focus {
    text-align: left;
  }
  #navBar .navbar-collapse {
    padding: 0;
  }
  #navBar .navbar-collapse .nav li a {
    position: relative;
    display: block;
    padding: 11px 5px;
    font-size: 11px;
    width: auto;
  }
  #navBar .navbar-collapse .nav li:nth-child(3) a {
    width: 170px;
  }
  #navBar .navbar-collapse .nav li.dentist-order-manage:first-child a {
    width: 220px;
    padding-left: 5px;
  }
  #navBar .navbar-collapse .nav li.dentist-order-manage:nth-child(3) {
    width: 100px;
  }
  #navBar .navbar-collapse .nav li.dentist-order-manage:nth-child(3) a {
    width: 100px;
  }
  #navBar .navbar-collapse .nav li.dentist-order-manage.modify-order {
    width: 100px !important;
    text-align: center;
  }
  #delivery-section .deliveryDate h3 {
    font-size: 14px;
  }
  #order-status-view .no-padding-lr .Sent-by-dentist h1 {
    font-size: 16px;
  }
  #order-status-view .no-padding-lr .Sent-by-dentist h1:after,
  #order-status-view .no-padding-lr .Sent-by-dentist h1:before {
    border-top: 20px solid transparent;
    border-bottom: 21px solid transparent;
  }
  #order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-left.width-resize {
    width: 100%;
  }
  #order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-right.width-resize {
    width: 100%;
    margin-top: 0;
  }
  #order-list .order-list .no-padding-default .order-list-hading .font-manage {
    font-size: 14px;
    padding: 0 2px;
  }
  #order-list .order-list .no-padding-default .order-list-content .font-manage {
    font-size: 14px;
    padding: 0 2px;
  }
  #order-list .order-list .no-padding-default .order-list-content .font-manage .lab-flag-icon {
    left: -3px;
    top: 5px;
  }
  #order-list .order-list .no-padding-default .order-list-content:first-child .font-manage {
    padding: 0 20px;
  }
  #order-list .order-list .order-list-box.no-padding-default {
    padding: 10px 15px;
    float: left;
    width: 100%;
  }
  #my-modal-compatible .modal-dialog {
    width: 650px;
  }
  #my-modal-compatible .modal-dialog .modal-content {
    padding: 10px 15px;
  }
  #my-modal-compatible .modal-dialog .modal-content .modal-footer .btn.btn-default {
    font-size: 12px;
    padding: 15px 5px;
    margin-bottom: 5px;
  }
  #clinics,
  #users-lab,
  #users {
    margin-top: 150px;
  }
  #clinics .clinics ul li,
  #users-lab .clinics ul li,
  #users .clinics ul li,
  #clinics .users-lab ul li,
  #users-lab .users-lab ul li,
  #users .users-lab ul li,
  #clinics .users ul li,
  #users-lab .users ul li,
  #users .users ul li {
    width: 32.3%;
    margin: 0 0.5% 10px;
    min-width: 32.3%;
    max-width: 32.3%;
  }
  #clinics .clinics ul li .clinic-block h3,
  #users-lab .clinics ul li .clinic-block h3,
  #users .clinics ul li .clinic-block h3,
  #clinics .users-lab ul li .clinic-block h3,
  #users-lab .users-lab ul li .clinic-block h3,
  #users .users-lab ul li .clinic-block h3,
  #clinics .users ul li .clinic-block h3,
  #users-lab .users ul li .clinic-block h3,
  #users .users ul li .clinic-block h3 {
    margin-bottom: 10px;
  }
  #clinics .clinics ul li .clinic-block .clinic-activation-block,
  #users-lab .clinics ul li .clinic-block .clinic-activation-block,
  #users .clinics ul li .clinic-block .clinic-activation-block,
  #clinics .users-lab ul li .clinic-block .clinic-activation-block,
  #users-lab .users-lab ul li .clinic-block .clinic-activation-block,
  #users .users-lab ul li .clinic-block .clinic-activation-block,
  #clinics .users ul li .clinic-block .clinic-activation-block,
  #users-lab .users ul li .clinic-block .clinic-activation-block,
  #users .users ul li .clinic-block .clinic-activation-block {
    padding: 10px 5px;
    margin-top: 5px;
  }
  #clinics .clinics ul li .clinic-block .clinic-activation-block .clinic-active,
  #users-lab .clinics ul li .clinic-block .clinic-activation-block .clinic-active,
  #users .clinics ul li .clinic-block .clinic-activation-block .clinic-active,
  #clinics .users-lab ul li .clinic-block .clinic-activation-block .clinic-active,
  #users-lab .users-lab ul li .clinic-block .clinic-activation-block .clinic-active,
  #users .users-lab ul li .clinic-block .clinic-activation-block .clinic-active,
  #clinics .users ul li .clinic-block .clinic-activation-block .clinic-active,
  #users-lab .users ul li .clinic-block .clinic-activation-block .clinic-active,
  #users .users ul li .clinic-block .clinic-activation-block .clinic-active {
    font-size: 12px;
    line-height: 20px;
  }
  #clinics .clinics ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #users-lab .clinics ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #users .clinics ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #clinics .users-lab ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #users-lab .users-lab ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #users .users-lab ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #clinics .users ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #users-lab .users ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #users .users ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic {
    margin-top: -1px;
  }
  #clinics .clinics ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #users-lab .clinics ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #users .clinics ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #clinics .users-lab ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #users-lab .users-lab ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #users .users-lab ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #clinics .users ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #users-lab .users ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #users .users ul li .clinic-block .clinic-activation-block .clinic-active-button a {
    padding: 2px 2px;
    margin-top: -2px;
    float: left;
    font-size: 10px;
    margin-left: 1px;
  }
  #clinics .clinics ul li .clinic-block ul.multiple-check-organization li,
  #users-lab .clinics ul li .clinic-block ul.multiple-check-organization li,
  #users .clinics ul li .clinic-block ul.multiple-check-organization li,
  #clinics .users-lab ul li .clinic-block ul.multiple-check-organization li,
  #users-lab .users-lab ul li .clinic-block ul.multiple-check-organization li,
  #users .users-lab ul li .clinic-block ul.multiple-check-organization li,
  #clinics .users ul li .clinic-block ul.multiple-check-organization li,
  #users-lab .users ul li .clinic-block ul.multiple-check-organization li,
  #users .users ul li .clinic-block ul.multiple-check-organization li {
    min-width: 98%;
    font-size: 13px;
  }
  #clinics .clinics ul li,
  #clinics .users-lab ul li,
  #clinics .users ul li {
    width: 49%;
    margin: 0 0.5% 10px;
    min-width: 49%;
    max-width: 49%;
  }
  #add-clinic-modal .modal-dialog,
  #add-user-modal .modal-dialog,
  #add-user-lab-modal .modal-dialog,
  #add-lab-modal .modal-dialog {
    width: 700px;
  }
  #connect-lab {
    margin-top: 150px;
  }
  #connect-lab .connect-lab .profile-left-section .profile-form-left-row {
    padding: 0 20px;
  }
  #connect-lab .connect-lab .profile-right-section {
    width: 100%;
    margin-right: 0;
  }
  #connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .jobs-div {
    width: 100%;
  }
  #connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .select-days-div .jobs-div-row {
    width: 100%;
  }
  #account-setting {
    margin-top: 150px;
  }
  #account-setting .account-setting .profile-left-section .profile-form-left .change-profile-picture input[type='button'] {
    bottom: 5px;
    right: 5px;
    padding: 3px 10px;
    font-size: 15px;
  }
  #account-setting .account-setting .profile-right-section {
    width: 100%;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one {
    padding: 35px 5px;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .head-profile {
    padding: 0;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .head-profile h2 span {
    padding: 0 5px;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row {
    margin-bottom: 0;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col {
    width: 100%;
    max-width: 100%;
    margin: 0 0 20px;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.archive-order {
    margin-top: -40px;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.archive-order span {
    margin-bottom: 10px;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.archive-order p {
    margin-left: 0;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.view-settings .switch-button {
    display: flex;
  }
  #account-setting .account-setting .profile-right-section .save-change-btn input[type='button'] {
    padding: 6px 20px;
    font-size: 15px;
  }
  #lab-profile {
    margin-top: 150px;
  }
  #lab-profile .lab-profile .profile-left-section {
    padding-top: 20px;
  }
  #lab-profile .lab-profile .profile-left-section .head-profile {
    padding: 0;
  }
  #lab-profile .lab-profile .profile-left-section .head-profile h2 span {
    padding: 0 5px;
    font-size: 18px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row {
    padding: 0 10px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div {
    padding: 3px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-left .upload-file-name {
    padding: 5px 0 0;
    min-height: 30px;
    max-height: 30px;
    width: 80%;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-left .upload-file-name a.link-icon:before,
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-left .upload-file-name a.link-icon.link-file[href]:before {
    margin-top: 3px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-left img {
    margin-top: 6px;
    width: 11px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-right .upload-file-div {
    width: auto;
    font-size: 12px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-right .upload-file-div .filechoose-div {
    padding: 0 5px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-right span {
    font-size: 11px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row label {
    width: auto;
    margin-left: 0;
    margin-top: 0;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row label input {
    margin: -1px 5px 0 0;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row label p {
    font-size: 11px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row .delivery-section {
    width: 50%;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row .delivery-section input {
    padding: 1px 0;
    font-size: 12px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row .delivery-section span {
    width: auto;
    margin-bottom: 0;
    font-size: 11px;
    line-height: 22px;
    margin-top: 0;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row .delivery-section .tooltip.ng-scope.ng-isolate-scope.top.file-name-tooltip.fade.in {
    width: 130px;
  }
  #lab-profile .lab-profile .profile-right-section {
    width: 100%;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-one {
    padding: 20px 5px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-one .head-profile {
    padding: 0;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-one .head-profile h2 span {
    padding: 0 5px;
    font-size: 18px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-one .media-section .media-section-block {
    width: 100%;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-one .media-section .media-section-block:first-child {
    margin-bottom: 25px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two {
    padding: 20px 5px;
    margin-top: 15px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .head-profile {
    padding: 0;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .head-profile h2 span {
    padding: 0 5px;
    font-size: 18px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block {
    width: 100%;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row {
    padding-left: 5px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row label {
    width: 75px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row label input[type='checkbox'].checkedInput {
    width: 100%;
    height: 30px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row label input[type='checkbox'].notCheckedInput {
    width: 100%;
    height: 30px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row label input[type='checkbox'] + span {
    line-height: 30px;
    font-size: 14px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row .delivery-section {
    width: 70%;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row .delivery-section span {
    font-size: 12px;
    line-height: 31px;
    padding: 0 10px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row .delivery-section .ng-isolate-scope tr td input {
    line-height: 28px;
    min-height: 28px;
    height: 28px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block:nth-child(2) {
    width: 100%;
    margin-top: 25px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block:nth-child(2) .contact-form .contact-form-row i {
    width: 30px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .calendar-section {
    padding-left: 0;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .calendar-section .btn-sm {
    padding: 5px 8px;
  }
  #lab-profile .lab-profile .notifications .profile-left-section .head-profile h2 {
    margin-top: 20px;
  }
  #lab-profile .lab-profile .notifications .profile-left-section .profile-form-left {
    padding: 0 15px !important;
  }
  #lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-email .checkbox-inline p.email-noti-para {
    width: 80%;
  }
  #lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-Select .switch-button .switch {
    margin-right: 8px;
  }
  #lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-Select .switch-button .switch.checked {
    margin-right: 8px;
  }
  #lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-Select .switch-button p {
    width: 76%;
  }
  #lab-profile .container {
    padding: 0;
  }
  #profile-dentist {
    margin-top: 150px;
  }
  #profile-dentist .profile-dentist .profile-left-section {
    padding-top: 20px;
  }
  #profile-dentist .profile-dentist .profile-left-section .head-profile {
    padding: 0;
  }
  #profile-dentist .profile-dentist .profile-left-section .head-profile h2 span {
    padding: 0 5px;
  }
  #profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row {
    padding: 0 15px;
  }
  #profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row .profile-form-left-col {
    width: 100%;
    margin-bottom: 20px;
  }
  #profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row.change-pass-div .profile-form-left-col.extra-top-margin-add {
    margin-top: -50px !important;
  }
  #profile-dentist .profile-dentist .profile-left-section .notifications {
    padding-top: 15px;
  }
  #profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left:last-child {
    min-height: inherit;
  }
  #profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left {
    padding: 0 15px;
  }
  #profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .switch-button .switch {
    margin-right: 8px;
  }
  #profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .switch-button .switch.checked {
    margin-right: 8px;
  }
  #profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .switch-button p {
    width: 76%;
  }
  #profile-dentist .profile-dentist .profile-right-section {
    width: 100%;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one {
    padding: 20px 15px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .head-profile {
    padding: 0;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .head-profile h2 span {
    padding: 0 5px;
    font-size: 18px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col {
    width: 100%;
    max-width: inherit;
    margin: 0 0 15px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col {
    margin: 0;
    width: 100% !important;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin .switch-button .switch {
    margin-right: 8px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin .switch-button .switch.checked {
    margin-right: 8px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin .switch-button p {
    width: 78%;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin.auto-archive span {
    margin-bottom: 10px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin.auto-archive p {
    margin-left: 0;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-two {
    padding: 15px 10px;
    margin-top: 15px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-two .head-profile {
    padding: 0;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-two .head-profile h2 span {
    padding: 0 5px;
    font-size: 18px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-two .profile-form-right .profile-form-right-row .profile-form-right-col {
    width: 100%;
    max-width: inherit;
    margin: 0 0 15px;
  }
  #profile-dentist .container {
    padding: 0;
  }
  #navBar .navbar {
    margin-top: 2px;
  }
  #navBar .nav > li > a {
    width: 135px;
  }
  #connect-to-lab {
    margin-top: 150px;
  }
  #connect-to-lab .connect-to-lab .search-box-lab input[type='button'] {
    padding: 0 15px;
    font-size: 15px;
  }
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-head .first-sec,
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-head .second-sec,
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-head .third-sec,
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-head .fourth-sec {
    font-size: 15px;
  }
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-body .repeated-div-report .fourth-sec .one-by-third .one-by-third-one input[type='button'] {
    padding: 0 5px;
    font-size: 12px;
  }
  #connect-to-lab .connect-to-lab .pagination-center {
    margin: 0;
  }
  #connect-to-lab .connect-to-lab .pagination-center li a {
    padding: 5px 10px;
    font-size: 14px;
  }
  #dentist-order-detail {
    margin-top: 150px;
  }
  #dentist-order-detail .container {
    padding: 0;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail {
    padding: 5px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part h3 {
    font-size: 13px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part p {
    font-size: 13px;
    line-height: 15px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part ul li {
    font-size: 13px;
    line-height: 15px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part.full-construction ul li span:after {
    right: 2px;
    bottom: 15px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part h3 {
    font-size: 13px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part p {
    font-size: 13px;
    line-height: 15px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part.order-created i {
    font-size: 30px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part.order-created span p {
    font-weight: normal;
    font-size: 10px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .orders-pics {
    padding: 8px;
    margin-top: 5px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .orders-pics ul {
    width: 70%;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .orders-pics ul li {
    width: 48%;
    height: 46px;
    max-width: 100px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .orders-pics ul li img {
    width: 100%;
    height: auto;
    max-height: 45px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .orders-pics ul button {
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 13px;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .orders-pics .order-pic-upload {
    width: 30%;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .orders-pics .order-pic-upload .upload-image {
    margin-top: 0;
  }
  #dentist-order-detail .container .dentist-order-detail .client-lab-detail .orders-pics .order-pic-upload .upload-image span {
    height: 30px;
    line-height: 20px;
    font-size: 10px;
  }
  #dentist-order-detail .container .dentist-order-detail .chat-box {
    padding: 0;
  }
  #dentist-order-detail .container .dentist-order-detail .chat-box .chat-box-date {
    padding: 5px 0px;
  }
  #dentist-order-detail .container .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-login-person {
    padding: 20px 10px 0px;
  }
  #dentist-order-detail .container .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-login-person .chat-front-person-chat {
    width: 77%;
  }
  #dentist-order-detail .container .dentist-order-detail .chat-option-box {
    margin-top: 2px;
  }
  #dentist-order-detail .container .dentist-order-detail .chat-option-box input {
    padding: 15px;
  }
  #dentist-order-detail .container .dentist-order-detail .chat-send-btn input {
    padding: 5px 40px;
  }
  p.red-astrix-mark {
    font-size: 13px;
    line-height: 14px;
    padding: 0 5px;
  }
  .orders-pics-resend ul li {
    width: 100px;
    height: 50px;
  }
  .orders-pics-resend ul li img {
    width: 100%;
    height: 45px;
  }
  #resend-order-button button:focus {
    outline: none;
  }
  #order-status-view .no-padding-lr .Sent-by-dentist {
    width: 96%;
  }
  #order-status-view .no-padding-lr .Sent-by-dentist ul li {
    padding: 5px;
  }
  #order-status-view .no-padding-lr .Sent-by-dentist ul li p {
    font-size: 11px;
  }
  #order-status-view .no-padding-lr .Sent-by-dentist ul li .dentist .dentist-right.width-resize {
    font-size: 12px;
  }
  #rate-order .modal-dialog {
    width: 96%;
    margin: 10px 2%;
  }
  #rate-order .modal-dialog .modal-content {
    padding: 15px 0px 5px;
  }
  #rate-order .modal-dialog .modal-content .modal-header .close {
    margin: -15px 7px;
  }
  #rate-order .modal-dialog .modal-content .modal-body p {
    font-size: 12px;
  }
  #rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div {
    margin: 0;
  }
  #rate-order .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 {
    width: 100%;
  }
  #rate-order .modal-dialog .modal-content .modal-footer {
    display: inline-block;
    text-align: right;
    width: 100%;
    padding: 25px 10px 10px;
    margin: 0;
  }
  #rate-order .modal-dialog .modal-content .modal-footer .btn.btn-default {
    padding: 5px;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: normal;
    margin: 5px 0;
  }
  #rate-order.one-rate-option .modal-dialog,
  #rate-order.two-rate-option .modal-dialog,
  #rate-order.three-rate-option .modal-dialog,
  #rate-order.four-rate-option .modal-dialog {
    width: 96%;
    margin: 10px 2%;
  }
  #rate-order.one-rate-option .modal-dialog .modal-content,
  #rate-order.two-rate-option .modal-dialog .modal-content,
  #rate-order.three-rate-option .modal-dialog .modal-content,
  #rate-order.four-rate-option .modal-dialog .modal-content {
    padding: 15px 0px 5px;
  }
  #rate-order.one-rate-option .modal-dialog .modal-content .modal-header .close,
  #rate-order.two-rate-option .modal-dialog .modal-content .modal-header .close,
  #rate-order.three-rate-option .modal-dialog .modal-content .modal-header .close,
  #rate-order.four-rate-option .modal-dialog .modal-content .modal-header .close {
    margin: -15px 7px;
  }
  #rate-order.one-rate-option .modal-dialog .modal-content .modal-body p,
  #rate-order.two-rate-option .modal-dialog .modal-content .modal-body p,
  #rate-order.three-rate-option .modal-dialog .modal-content .modal-body p,
  #rate-order.four-rate-option .modal-dialog .modal-content .modal-body p {
    font-size: 12px;
  }
  #rate-order.one-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div,
  #rate-order.two-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div,
  #rate-order.three-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div,
  #rate-order.four-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div {
    margin: 0;
  }
  #rate-order.one-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2,
  #rate-order.two-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2,
  #rate-order.three-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2,
  #rate-order.four-rate-option .modal-dialog .modal-content .modal-body .row.auto-center-div .col-md-2 {
    width: 50%;
  }
  #rate-order.one-rate-option .modal-dialog .modal-content .modal-footer,
  #rate-order.two-rate-option .modal-dialog .modal-content .modal-footer,
  #rate-order.three-rate-option .modal-dialog .modal-content .modal-footer,
  #rate-order.four-rate-option .modal-dialog .modal-content .modal-footer {
    display: inline-block;
    text-align: right;
    width: 100%;
    padding: 25px 10px 10px;
    margin: 0;
  }
  #rate-order.one-rate-option .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #rate-order.two-rate-option .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #rate-order.three-rate-option .modal-dialog .modal-content .modal-footer .btn.btn-default,
  #rate-order.four-rate-option .modal-dialog .modal-content .modal-footer .btn.btn-default {
    padding: 5px;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: normal;
    margin: 5px 0;
  }
  #imgModal .align-img-cross {
    width: 60%;
  }
  #imgModal img {
    width: 100%;
    height: inherit;
  }
}
@media all and (orientation: landscape) and (min-width: 812px) and (max-width: 860px) {
  login .container,
  forgot .container {
    display: block;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1599px) {
  #patient-section.order-two-part .SearchPatient ul li label {
    font-size: 12px;
  }
  #patient-section.order-two-part .SearchPatient ul li p.red-astrix-mark {
    padding: 0;
  }
  #patient-section .SearchPatient .patientExistingorder .col-lg-12.order-list-box {
    float: left;
    width: 100%;
  }
  #navBar .navbar {
    margin-top: 2px;
  }
  #order-confirm .order-confirm .order-details {
    width: 100%;
  }
  order-new .row {
    margin: 15px 0 0;
  }
  #patient-section span.check-icons i {
    padding-left: 3px;
    top: 42px;
    right: -17px;
    font-size: 10px;
    padding-top: 3px;
  }
  #patient-section .patientLab {
    margin-top: 120px;
    padding: 0;
  }
  #patient-section h1.patientTitle {
    font-size: 15px;
    line-height: 35px;
  }
  #patient-section .SearchPatient {
    padding: 25px 10px;
  }
  #patient-section .SearchPatient h1 {
    font-size: 15px;
  }
  #patient-section .SearchPatient ul {
    width: 100%;
  }
  #patient-section .SearchPatient ul li {
    margin-top: 10px;
    text-align: left;
  }
  #patient-section .SearchPatient ul li label {
    margin-bottom: 2px;
  }
  #patient-section .SearchPatient ul li input.form-control {
    text-align: left;
  }
  #patient-section .SearchPatient .col-lg-2 {
    width: 100% !important;
  }
  #patient-section .SearchPatient .col-lg-2 .chooseLAb {
    width: 100%;
    margin-top: 5px;
  }
  #patient-section .SearchPatient + .SearchPatient {
    text-align: left;
    display: inline-block;
  }
  #patient-section .SearchPatient + .SearchPatient .col-lg-2 {
    width: auto !important;
    max-width: 110px;
    padding-right: 5px;
    float: none;
    display: inline-block;
  }
  #construction-section .cross-rectangle-post {
    width: 12px;
    height: 12px;
    bottom: -15px;
    right: 9.5px;
    line-height: 12px;
    font-size: 8px;
  }
  #construction-section .cross-square {
    width: 12px;
    height: 12px;
    top: -9px;
    right: -8px;
    line-height: 12px;
    font-size: 8px;
  }
  #construction-section .margin-top {
    margin-top: 0 !important;
  }
  #construction-section .row {
    margin: 0;
  }
  #construction-section h1.patientTitle {
    font-size: 15px;
    line-height: 35px;
  }
  #construction-section .Constructions {
    margin-top: 0;
    padding: 0;
  }
  #construction-section .SearchPatient {
    padding: 10px 0 0;
  }
  #construction-section .SearchPatient ul li {
    margin-bottom: 10px;
  }
  #construction-section .SearchPatient ul li a {
    font-size: 15px;
    line-height: 24px;
  }
  #construction-section .SearchPatient ul li.active a {
    border-bottom-width: 2px;
  }
  #construction-section .divider-new h1 {
    margin-top: 0;
    font-size: 18px;
  }
  #construction-section .clickonBoth .well-sm .range {
    display: none;
  }
  #construction-section .clickonBoth .well-sm .btn-group {
    margin-bottom: 10px;
  }
  #construction-section .margin-top-45 {
    margin-top: 5px !important;
  }
  #construction-section .SelectMaterial h3 {
    margin-top: 0;
    line-height: 24px;
  }
  #construction-section .SelectMaterial ul {
    margin-top: 0;
  }
  #construction-section .SelectMaterial ul li {
    width: 32%;
    margin-right: 1.33%;
    margin-bottom: 3px;
  }
  #construction-section .SelectMaterial ul li a {
    padding: 6px 3px;
    font-size: 11px;
  }
  #construction-section .SelectMaterial .designBox ul li {
    width: 100%;
  }
  #construction-section .SelectMaterial.designBox ul li {
    margin-bottom: 3px;
  }
  #construction-section .form-group .other-material {
    width: 100%;
  }
  #construction-section ul.singleSub {
    width: 100%;
  }
  #construction-section ul.singleSub li {
    width: 32%;
    margin: 1px 0.5%;
  }
  #construction-section #bridgeTeeth .row {
    margin: 0 -25px;
  }
  #construction-section .bridge-suggestion,
  #construction-section .partial-suggestion {
    margin-left: 0;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    font-size: 12px;
    font-weight: normal;
  }
  #delivery-section h1.patientTitle {
    font-size: 15px;
    line-height: 35px;
  }
  #delivery-section .DelInf {
    width: 100%;
    margin-left: 0;
  }
  #delivery-section .deliveryDate {
    margin-top: 10px;
  }
  #delivery-section .deliveryDate h3 {
    font-size: 14px;
    font-weight: normal;
  }
  #delivery-section .deliveryDate ul {
    margin-top: 0;
  }
  #delivery-section .form-group {
    margin-right: 15px;
    float: left;
    width: 95.6%;
    margin-top: 0;
  }
  #delivery-section .form-group label {
    font-size: 14px;
    font-weight: normal;
    margin-top: 10px;
  }
  #images-section .upload-images {
    margin-top: 15px;
    padding: 0 0 15px;
  }
  #images-section h1.patientTitle {
    font-size: 15px;
    line-height: 35px;
  }
  #images-section .images-upload {
    float: left;
    margin-bottom: 0;
    margin-top: 10px;
    padding: 50px 0;
  }
  #images-section .row {
    width: 100%;
    float: left;
    margin: 0;
  }
  #images-section .row span.Images-circle {
    float: left;
    width: 50px;
    margin: 10px 0 0;
    display: block;
    height: inherit;
  }
  #images-section .row .images-file-name {
    width: 90%;
    float: left;
    margin: 5px 0 0 10px;
    padding: 0;
    text-align: left;
    font-size: 12px;
    word-wrap: break-word;
  }
  #images-section .row .file-icon {
    margin: 0;
    width: 50px;
  }
  #images-section .row .file-icon i {
    margin: 10px 0 0;
    font-size: 2em;
  }
  #sign-section h1.patientTitle {
    font-size: 15px;
    line-height: 35px;
  }
  #sign-section .signSend {
    margin-top: 15px;
    padding: 0 0 15px;
    margin-bottom: 15px;
  }
  #sign-section .signSendbox h1 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  #sign-section .signSendbox p {
    font-size: 13px;
  }
  #sign-section .signSendbox img {
    width: 50px;
    margin: 20px 0 30px;
  }
  #sign-section .signSendbox .sign-with-img-icon span.right-sign-all-select {
    width: 30px;
    height: 30px;
    padding: 15px;
    bottom: 15px;
    right: -25px;
  }
  #sign-section .signSendbox .sign-with-img-icon span.right-sign-all-select:after {
    top: 6px;
    left: 5px;
    font-size: 20px;
  }
  #denthub-header #imaginary_container {
    width: 100%;
  }
  #denthub-header #menu-profile ul.dropdown-menu {
    margin: 0 -105px !important;
    top: 46px;
  }
  #denthub-header .notificationTitle {
    font-size: 15px;
  }
  #denthub-header .btn-default {
    font-size: 16px;
    margin-left: 11px;
    width: 90%;
  }
  #denthub-header #Notifications ul.dropdown-menu {
    top: 47px;
    left: 21px;
    border-top: 0;
  }
  #denthub-header #Notifications.open button:after {
    left: 21px;
    top: 21px;
  }
  #navBar .navbar-collapse {
    padding: 0;
  }
  #navBar .navbar-collapse .nav li a {
    position: relative;
    display: block;
    padding: 12px 15px 10px;
    font-size: 12px;
    width: auto;
  }
  #navBar .navbar-collapse .nav li.dentist-order-manage a {
    padding: 14px 15px 10px;
  }
  #navBar .navbar-collapse .nav li.dentist-order-manage.modify-order {
    width: auto !important;
  }
  #navBar .navbar-collapse .nav li.lab-order-manage a {
    padding: 14px 15px 10px;
  }
  #navBar .navbar-collapse .nav li.lab-order-manage:first-child a {
    width: 220px;
    padding: 14px 0 10px;
  }
  #navBar .navbar-collapse .nav li.lab-order-manage.modify-order {
    width: auto !important;
  }
  #navBar .navbar-collapse .nav li.sub-name a {
    padding: 8px 15px 6px;
  }
  #add-user-modal .check-icons i {
    text-align: center;
  }
  #remove-user-modal .check-icons i {
    text-align: center;
  }
  #order-status-view .no-padding-lr .Sent-by-dentist h1 {
    font-size: 18px;
  }
  #order-status-view .no-padding-lr .Sent-by-dentist h1:after,
  #order-status-view .no-padding-lr .Sent-by-dentist h1:before {
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
  #order-status-view .no-padding-lr .Sent-by-dentist ul li {
    padding: 7px;
  }
  #order-list .order-list .no-padding-default .order-list-hading .font-manage {
    font-size: 14px;
    padding: 0 20px;
  }
  #order-list .order-list .no-padding-default .order-list-hading .font-manage:first-child {
    padding-left: 15px;
  }
  #order-list .order-list .no-padding-default .order-list-content .font-manage {
    font-size: 14px;
    padding: 0 2px;
  }
  #order-list .order-list .no-padding-default .order-list-content .font-manage .dentist-right {
    width: 14%;
    margin-right: 8px;
  }
  #order-list .order-list .no-padding-default .order-list-content .font-manage .dentist-right span {
    padding: 0 4px;
    font-size: 11px;
    top: -7px;
    right: -8px;
  }
  #order-list .order-list .no-padding-default .order-list-content .font-manage .lab-flag-icon {
    left: -3px;
    top: 5px;
  }
  #order-list .order-list .no-padding-default .order-list-content:first-child .font-manage {
    padding: 0 20px;
  }
  #order-list .order-list .order-list-box.no-padding-default {
    padding: 10px 15px;
    float: left;
    width: 100%;
  }
  #my-modal-compatible .modal-dialog {
    width: 750px;
  }
  #my-modal-compatible .modal-dialog .modal-content {
    padding: 10px 15px;
  }
  #my-modal-compatible .modal-dialog .modal-content .modal-footer .btn.btn-default {
    font-size: 14px;
    padding: 15px 10px;
    margin-bottom: 5px;
  }
  #clinics,
  #users-lab,
  #users {
    margin-top: 175px;
  }
  #clinics .clinics ul li,
  #users-lab .clinics ul li,
  #users .clinics ul li,
  #clinics .users-lab ul li,
  #users-lab .users-lab ul li,
  #users .users-lab ul li,
  #clinics .users ul li,
  #users-lab .users ul li,
  #users .users ul li {
    width: 32.3%;
    margin: 0 0.5% 10px;
    min-width: 32.3%;
    max-width: 32.3%;
  }
  #clinics .clinics ul li .clinic-block h3,
  #users-lab .clinics ul li .clinic-block h3,
  #users .clinics ul li .clinic-block h3,
  #clinics .users-lab ul li .clinic-block h3,
  #users-lab .users-lab ul li .clinic-block h3,
  #users .users-lab ul li .clinic-block h3,
  #clinics .users ul li .clinic-block h3,
  #users-lab .users ul li .clinic-block h3,
  #users .users ul li .clinic-block h3 {
    margin-bottom: 10px;
  }
  #clinics .clinics ul li .clinic-block h4,
  #users-lab .clinics ul li .clinic-block h4,
  #users .clinics ul li .clinic-block h4,
  #clinics .users-lab ul li .clinic-block h4,
  #users-lab .users-lab ul li .clinic-block h4,
  #users .users-lab ul li .clinic-block h4,
  #clinics .users ul li .clinic-block h4,
  #users-lab .users ul li .clinic-block h4,
  #users .users ul li .clinic-block h4 {
    min-height: 20px;
  }
  #clinics .clinics ul li .clinic-block .clinic-activation-block,
  #users-lab .clinics ul li .clinic-block .clinic-activation-block,
  #users .clinics ul li .clinic-block .clinic-activation-block,
  #clinics .users-lab ul li .clinic-block .clinic-activation-block,
  #users-lab .users-lab ul li .clinic-block .clinic-activation-block,
  #users .users-lab ul li .clinic-block .clinic-activation-block,
  #clinics .users ul li .clinic-block .clinic-activation-block,
  #users-lab .users ul li .clinic-block .clinic-activation-block,
  #users .users ul li .clinic-block .clinic-activation-block {
    padding: 10px 5px;
    margin-top: 5px;
  }
  #clinics .clinics ul li .clinic-block .clinic-activation-block .clinic-active,
  #users-lab .clinics ul li .clinic-block .clinic-activation-block .clinic-active,
  #users .clinics ul li .clinic-block .clinic-activation-block .clinic-active,
  #clinics .users-lab ul li .clinic-block .clinic-activation-block .clinic-active,
  #users-lab .users-lab ul li .clinic-block .clinic-activation-block .clinic-active,
  #users .users-lab ul li .clinic-block .clinic-activation-block .clinic-active,
  #clinics .users ul li .clinic-block .clinic-activation-block .clinic-active,
  #users-lab .users ul li .clinic-block .clinic-activation-block .clinic-active,
  #users .users ul li .clinic-block .clinic-activation-block .clinic-active {
    font-size: 12px;
    line-height: 20px;
  }
  #clinics .clinics ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #users-lab .clinics ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #users .clinics ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #clinics .users-lab ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #users-lab .users-lab ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #users .users-lab ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #clinics .users ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #users-lab .users ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic,
  #users .users ul li .clinic-block .clinic-activation-block .clinic-active .round-active-clinic {
    margin-top: -1px;
  }
  #clinics .clinics ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #users-lab .clinics ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #users .clinics ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #clinics .users-lab ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #users-lab .users-lab ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #users .users-lab ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #clinics .users ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #users-lab .users ul li .clinic-block .clinic-activation-block .clinic-active-button a,
  #users .users ul li .clinic-block .clinic-activation-block .clinic-active-button a {
    padding: 0 5px;
    margin-top: -2px;
    float: left;
    font-size: 12px;
    margin-left: 1px;
  }
  #clinics .clinics ul li .clinic-block ul.multiple-check-organization li,
  #users-lab .clinics ul li .clinic-block ul.multiple-check-organization li,
  #users .clinics ul li .clinic-block ul.multiple-check-organization li,
  #clinics .users-lab ul li .clinic-block ul.multiple-check-organization li,
  #users-lab .users-lab ul li .clinic-block ul.multiple-check-organization li,
  #users .users-lab ul li .clinic-block ul.multiple-check-organization li,
  #clinics .users ul li .clinic-block ul.multiple-check-organization li,
  #users-lab .users ul li .clinic-block ul.multiple-check-organization li,
  #users .users ul li .clinic-block ul.multiple-check-organization li {
    min-width: 98%;
    font-size: 13px;
  }
  #connect-lab {
    margin-top: 20px;
  }
  #connect-lab .connect-lab .profile-left-section .profile-form-left-row {
    padding: 0 20px;
  }
  #connect-lab .connect-lab .profile-right-section {
    width: 100%;
    margin-right: 0;
  }
  #connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .jobs-div {
    width: 80%;
  }
  #connect-lab .connect-lab .profile-right-section .profile-right-block-one .profile-form-right .select-days-div .jobs-div-row {
    width: 80%;
  }
  #connect-to-lab .connect-to-lab .search-box-lab input[type='button'] {
    padding: 0 25px;
  }
  #account-setting {
    margin-top: 150px;
  }
  #account-setting .account-setting .profile-left-section .profile-form-left .change-profile-picture input[type='button'] {
    bottom: 5px;
    right: 5px;
    padding: 3px 10px;
    font-size: 15px;
  }
  #account-setting .account-setting .profile-right-section {
    width: 100%;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one {
    padding: 35px 5px;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .head-profile {
    padding: 0;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .head-profile h2 span {
    padding: 0 5px;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row {
    margin-bottom: 25px;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col {
    width: 100%;
    max-width: 31%;
    margin: 0 1% 0;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.archive-order {
    margin-top: -40px;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.archive-order span {
    margin-bottom: 10px;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.archive-order p {
    margin-left: 0;
  }
  #account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.view-settings .switch-button {
    display: flex;
  }
  #account-setting .account-setting .profile-right-section .save-change-btn input[type='button'] {
    padding: 6px 20px;
    font-size: 15px;
  }
  #lab-profile {
    margin-top: 150px;
  }
  #lab-profile .lab-profile .profile-left-section .head-profile {
    padding: 0;
  }
  #lab-profile .lab-profile .profile-left-section .head-profile h2 span {
    padding: 0 5px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row {
    padding: 0 10px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-right span {
    font-size: 14px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row label {
    width: 55%;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row .delivery-section {
    width: 40%;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row .delivery-section input {
    width: 20%;
    font-size: 13px;
  }
  #lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row .delivery-section span {
    width: 80%;
  }
  #lab-profile .lab-profile .profile-right-section {
    width: 100%;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-one {
    padding: 35px 5px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-one .head-profile {
    padding: 0;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-one .head-profile h2 span {
    padding: 0 5px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two {
    padding: 35px 5px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .head-profile {
    padding: 0;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .head-profile h2 span {
    padding: 0 5px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block {
    width: 57%;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row {
    padding-left: 5px;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block:nth-child(2) {
    width: 40%;
  }
  #lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block:nth-child(2) .contact-form .contact-form-row i {
    width: 30px;
  }
  #lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-email .checkbox-inline p.email-noti-para {
    width: 80%;
  }
  #profile-dentist {
    margin-top: 150px;
  }
  #profile-dentist .profile-dentist .profile-left-section {
    padding-top: 20px;
  }
  #profile-dentist .profile-dentist .profile-left-section .head-profile {
    padding: 0;
  }
  #profile-dentist .profile-dentist .profile-left-section .head-profile h2 span {
    padding: 0 5px;
  }
  #profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row {
    padding: 0 15px;
  }
  #profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row .profile-form-left-col {
    width: 100%;
    margin-bottom: 20px;
  }
  #profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row.change-pass-div .profile-form-left-col.extra-top-margin-add {
    margin-top: -50px !important;
  }
  #profile-dentist .profile-dentist .profile-left-section .notifications {
    padding-top: 15px;
  }
  #profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left:last-child {
    min-height: inherit;
  }
  #profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left {
    padding: 0 15px;
  }
  #profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .switch-button .switch {
    margin-right: 8px;
  }
  #profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .switch-button .switch.checked {
    margin-right: 8px;
  }
  #profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .switch-button p {
    width: 76%;
  }
  #profile-dentist .profile-dentist .profile-right-section {
    width: 100%;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one {
    padding: 20px 15px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .head-profile {
    padding: 0;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .head-profile h2 span {
    padding: 0 5px;
    font-size: 18px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col {
    width: 100%;
    max-width: inherit;
    margin: 0 0 15px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col {
    margin: 0;
    width: 100% !important;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin .switch-button .switch {
    margin-right: 8px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin .switch-button .switch.checked {
    margin-right: 8px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin .switch-button p {
    width: 78%;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin.auto-archive span {
    margin-bottom: 10px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin.auto-archive p {
    margin-left: 0;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-two {
    padding: 15px 10px;
    margin-top: 15px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-two .head-profile {
    padding: 0;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-two .head-profile h2 span {
    padding: 0 5px;
    font-size: 18px;
  }
  #profile-dentist .profile-dentist .profile-right-section .profile-right-block-two .profile-form-right .profile-form-right-row .profile-form-right-col {
    width: 100%;
    max-width: inherit;
    margin: 0 0 15px;
  }
  #profile-dentist .container {
    padding: 0;
  }
  #dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .left-detail-part {
    width: 65%;
  }
  #dentist-order-detail .dentist-order-detail .client-lab-detail .client-lab-detail-row .right-detail-part {
    width: 35%;
  }
  #dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics {
    padding: 8px;
    margin-top: 5px;
  }
  #dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics ul {
    width: 70%;
  }
  #dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics ul li {
    width: 32%;
    height: 46px;
    max-width: 100px;
  }
  #dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics ul li img {
    width: 100%;
    height: auto;
    max-height: 45px;
  }
  #dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics ul button {
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 13px;
  }
  #dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics .order-pic-upload {
    width: 30%;
  }
  #dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics .order-pic-upload .upload-image {
    margin-top: 0;
  }
  #dentist-order-detail .dentist-order-detail .client-lab-detail .orders-pics .order-pic-upload .upload-image span {
    height: 35px;
    line-height: 24px;
    font-size: 13px;
  }
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-login-person .chat-front-person-chat {
    width: 80%;
  }
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date .chat-with-box .chat-front-person .chat-front-person-chat {
    width: 80%;
  }
  #connect-to-lab {
    margin-top: 150px;
  }
  #connect-to-lab .connect-to-lab .search-box-lab input[type='button'] {
    padding: 0 15px;
    font-size: 15px;
  }
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-head .first-sec,
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-head .second-sec,
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-head .third-sec,
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-head .fourth-sec {
    font-size: 15px;
  }
  #connect-to-lab .connect-to-lab .pagination-center {
    margin: 0;
  }
  #connect-to-lab .connect-to-lab .pagination-center li a {
    padding: 5px 10px;
    font-size: 14px;
  }
  .orders-pics-resend ul li {
    width: 100px;
    height: 50px;
  }
  .orders-pics-resend ul li img {
    width: 100%;
    height: 45px;
  }
  #imgModal .align-img-cross {
    width: 60%;
  }
  #imgModal img {
    width: 100%;
    height: inherit;
  }
}
@media only screen and (min-width: 2500px) {
  #navBar .nav > li.dentist-order-manage:first-child > a {
    width: 367px;
  }
  #navBar .nav > li.dentist-order-manage > a {
    height: 53px;
  }
}
.modal-dialog {
  margin-top: 70px !important;
}
datepicker a,
[datepicker] a,
.datepicker a {
  color: inherit;
  text-decoration: none;
}
datepicker a:hover,
[datepicker] a:hover,
.datepicker a:hover {
  text-decoration: none;
}
datepicker select,
datepicker select:focus,
datepicker select:hover,
.datepicker select,
.datepicker select:focus,
.datepicker select:hover,
[datepicker] select,
[datepicker] select:focus,
[datepicker] select:hover {
  width: 100%;
  overflow: hidden;
  background: none;
  color: #fff;
  background-color: #138EFA;
  border-radius: 2px;
  border: 0;
  margin-top: 5px;
}
datepicker,
.datepicker,
[datepicker],
._720kb-datepicker-calendar-header,
._720kb-datepicker-calendar-body,
._720kb-datepicker-calendar-days-header,
._720kb-datepicker-calendar-years-pagination-pages {
  font-family: Helvetica Neue, Arial, sans-serif;
  font-size: 13.5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  float: left;
  clear: right;
  position: relative;
}
._720kb-datepicker-calendar {
  background: white;
  color: #333;
  position: absolute;
  z-index: 999;
  min-width: 220px;
  margin: 0 auto;
  width: 101%;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  visibility: hidden;
  overflow: hidden;
  margin-left: -0.5%;
  padding: 0 0 2% 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
._720kb-datepicker-calendar._720kb-datepicker-open,
._720kb-datepicker-calendar._720kb-datepicker-forced-to-open {
  visibility: visible;
}
._720kb-datepicker-calendar-header {
  text-align: center;
  font-size: 15px;
  line-height: 40px;
}
._720kb-datepicker-calendar-header:nth-child(odd) {
  background: #105c9d;
}
._720kb-datepicker-calendar-header:nth-child(even) {
  background: #105c9d;
}
._720kb-datepicker-calendar-header-left,
._720kb-datepicker-calendar-header-middle,
._720kb-datepicker-calendar-header-right {
  width: 15%;
  float: left;
}
._720kb-datepicker-calendar-header-middle {
  width: 70%;
}
._720kb-datepicker-calendar-header-closed-pagination::after {
  content: " \25BE";
}
._720kb-datepicker-calendar-header-opened-pagination::after {
  content: " \25BE";
  margin-left: 4px;
  position: relative;
  bottom: -3px;
  display: inline-block;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
._720kb-datepicker-calendar-body {
  width: 96%;
  margin: 2%;
  text-align: center;
}
._720kb-datepicker-calendar-day {
  cursor: pointer;
  font-size: 12.5px;
  width: 12.2%;
  margin: 5px 1%;
  padding: 1.5% 0;
  float: left;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
._720kb-datepicker-calendar-day:hover,
._720kb-datepicker-calendar-day._720kb-datepicker-active {
  background: rgba(0, 0, 0, 0.03);
}
._720kb-datepicker-calendar-header a,
._720kb-datepicker-calendar-header a:hover {
  text-decoration: none;
  padding: 3% 9% 4% 9%;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.55);
  font-weight: bold;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
._720kb-datepicker-calendar-header a:hover {
  color: rgba(0, 0, 0, 0.9);
  background: rgba(255, 255, 255, 0.45);
}
._720kb-datepicker-calendar-month {
  color: #fff;
}
._720kb-datepicker-calendar-month span {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.4);
}
._720kb-datepicker-calendar-month a span i {
  font-style: normal;
  font-size: 15px;
}
._720kb-datepicker-calendar-month a,
._720kb-datepicker-calendar-month a:hover {
  padding: 3px;
  margin-left: 1%;
}
._720kb-datepicker-calendar-years-pagination {
  padding: 2% 0 0 0;
  float: left;
  clear: right;
  width: 100%;
}
._720kb-datepicker-calendar-years-pagination a,
._720kb-datepicker-calendar-years-pagination a:hover {
  font-size: 12px;
  padding: 0 7px;
  font-weight: normal;
  margin: 3px 1% 0 1%;
  line-height: 20px;
  display: inline-block;
}
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active {
  color: rgba(0, 0, 0, 0.9);
  font-weight: 500;
  background: rgba(255, 255, 255, 0.45);
}
._720kb-datepicker-calendar-years-pagination-pages a,
._720kb-datepicker-calendar-years-pagination-pages a:hover {
  padding: 5px 10px;
}
._720kb-datepicker-calendar-days-header {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 2% 0 2%;
  background: rgba(19, 142, 250, 0.08);
  border-bottom: 1px solid rgba(0, 0, 0, 0.02);
}
._720kb-datepicker-calendar-days-header div {
  width: 14.18%;
  font-weight: 500;
  font-size: 11.5px;
  padding: 10px 0;
  float: left;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
}
._720kb-datepicker-calendar-days ._720kb-datepicker-default-button {
  font-size: 18.5px;
  position: relative;
  bottom: -0.5px;
}
._720kb-datepicker-default-button {
  padding: 0 4.5px;
}
._720kb-datepicker-calendar-header-middle._720kb-datepicker-mobile-item {
  width: 95%;
  float: none;
  margin: 0 auto;
}
._720kb-datepicker-item-hidden {
  visibility: hidden;
}
._720kb-datepicker-calendar-day._720kb-datepicker-disabled,
._720kb-datepicker-calendar-day._720kb-datepicker-disabled:hover,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-disabled,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-disabled:hover,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active._720kb-datepicker-disabled,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active._720kb-datepicker-disabled:hover {
  color: rgba(0, 0, 0, 0.2);
  background: rgba(25, 2, 0, 0.02);
  cursor: default;
}
a._720kb-datepicker-calendar-day._720kb-datepicker-disabled {
  background-color: #ffb5b6 !important;
}
a._720kb-datepicker-calendar-day._720kb-datepicker-active {
  background-color: #6ed803 !important;
}
.hide-date {
  opacity: 0;
}
@-webkit-keyframes three-quarters {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes three-quarters {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes three-quarters {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes three-quarters {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* Styles for old versions of IE */
.btn-spinner {
  font-family: sans-serif;
  font-weight: 100;
}
/* :not(:required) hides this rule from IE9 and below */
.btn-spinner:not(:required) {
  -webkit-animation: three-quarters 1250ms infinite linear;
  -moz-animation: three-quarters 1250ms infinite linear;
  -ms-animation: three-quarters 1250ms infinite linear;
  -o-animation: three-quarters 1250ms infinite linear;
  animation: three-quarters 1250ms infinite linear;
  border: 3px solid #bdbdbd;
  border-right-color: transparent;
  border-radius: 100%;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
  text-indent: -9999px;
  width: 18px;
  height: 18px;
}
.btn-spinner:not(:required) {
  margin-left: -22px;
  opacity: 0;
  transition: 0.4s margin ease-out, 0.2s opacity ease-out;
}
.is-loading .btn-spinner {
  transition: 0.2s margin ease-in, 0.4s opacity ease-in;
  margin-left: 5px;
  opacity: 1;
}
#manage-actions {
  width: 100%;
  float: left;
}
#manage-actions .container {
  margin-top: 180px;
  padding-bottom: 50px;
  background: #fff;
}
#manage-actions .manage-actions {
  width: 100%;
  float: left;
}
#manage-actions .manage-actions .actions-header {
  width: 100%;
  float: left;
  padding: 40px 20px 5px;
  border-bottom: solid 2px #4a4a4a;
  color: #4a4a4a;
  font-size: 20px;
  font-weight: 600;
}
#manage-actions .manage-actions .categories-section {
  width: 100%;
  float: left;
}
#manage-actions .manage-actions .categories-section .section-header {
  width: 100%;
  float: left;
  padding: 40px 20px 5px;
  color: #1f2532;
  font-size: 20px;
  font-weight: 600;
  border-bottom: solid 2px #1f2532;
}
#manage-actions .manage-actions .categories-section .category-section-block {
  width: 100%;
  float: left;
}
#manage-actions .manage-actions .categories-section .category-section-block h4 {
  width: auto;
  float: left;
  padding: 5px 25px;
  margin: 20px 20px 5px 5px;
  color: #1f2532;
  font-weight: 600;
  line-height: 39px;
}
#manage-actions .manage-actions .categories-section .category-section-block ul {
  width: auto;
  float: left;
  margin-top: 15px;
}
#manage-actions .manage-actions .categories-section .category-section-block ul li {
  width: auto;
  float: left;
  padding: 5px 50px;
  margin: 5px;
  border: solid 1px #1f2532;
  color: #1f2532;
  font-weight: 600;
  cursor: pointer;
}
#manage-actions .manage-actions .categories-section .category-section-block ul li.sub-category-text {
  color: #fff;
}
#manage-actions .manage-actions .categories-section .category-section-block button {
  width: auto;
  float: left;
  padding: 5px 50px;
  margin: 20px 5px 5px;
  border: solid 1px #1f2532;
  color: #1f2532;
  background: none;
  font-weight: 600;
}
#manage-actions .manage-actions .categories-section .category-section-block .main-category-list {
  width: 100%;
  float: left;
}
#manage-actions .manage-actions .categories-section .category-section-block .main-category-list .main-category-list-item {
  width: 100%;
  float: left;
}
#manage-actions .manage-actions .categories-section .manage-action-filter {
  width: 100%;
  float: left;
  margin: 15px 0;
}
#manage-actions .manage-actions .categories-section .manage-action-filter .category-dropdown {
  width: 15%;
  float: left;
  margin-right: 2%;
  text-align: center;
}
#manage-actions .manage-actions .categories-section .manage-action-filter .category-dropdown label {
  width: 100%;
  float: left;
  font-weight: 500;
  line-height: 31px;
  margin: 0;
  font-size: 14px;
}
#manage-actions .manage-actions .categories-section .manage-action-filter .category-dropdown select {
  width: 100%;
  float: left;
  padding: 5px 0;
  text-align: center;
  border: solid 1px #a5a3a3;
  color: #979797;
  font-size: 14px;
  background: #dcdada;
}
#manage-actions .manage-actions .categories-section .manage-action-filter .category-search-input {
  width: 65%;
  float: left;
  margin-top: 31px;
}
#manage-actions .manage-actions .categories-section .manage-action-filter .category-search-input input {
  width: 72%;
  float: left;
  padding: 3px 0;
  text-align: center;
  border: solid 1px #a5a3a3;
  color: #979797;
  font-size: 14px;
  margin-right: 2%;
  background: #dcdada;
  height: 31px;
}
#manage-actions .manage-actions .categories-section .manage-action-filter .category-search-input button {
  width: auto;
  float: right;
  padding: 1px 30px;
  margin: 0 5px 5px;
  border: solid 1px #1f2532;
  color: #1f2532;
  background: none;
  font-weight: 600;
}
#manage-actions .manage-actions .categories-section .action-data-table {
  width: 100%;
  float: left;
  background: #eee;
  margin: 15px 0;
}
#manage-actions .manage-actions .categories-section .action-data-table p {
  width: 100%;
  float: left;
  text-align: right;
  font-size: 11px;
  padding: 5px 10px;
}
#manage-actions .manage-actions .categories-section .action-data-table p span {
  color: #d0021b;
}
#manage-actions .manage-actions .categories-section .action-data-table .table-head-title {
  width: 100%;
  float: left;
  margin-top: 60px;
  border-bottom: solid 1px #dcdada;
}
#manage-actions .manage-actions .categories-section .action-data-table .table-head-title label {
  width: 10%;
  float: left;
  font-size: 12px;
  color: #1f2532;
  font-weight: 600;
  margin-bottom: 0;
}
#manage-actions .manage-actions .categories-section .action-data-table .table-head-title label:nth-child(2) {
  width: 20%;
}
#manage-actions .manage-actions .categories-section .action-data-table .table-head-title label.rotate-90 {
  width: 1%;
  transform: rotate(-90deg);
  white-space: nowrap;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 400;
}
#manage-actions .manage-actions .categories-section .action-data-table .table-head-title label.extra-width {
  width: 4%;
  margin: -17px 0;
}
#manage-actions .manage-actions .categories-section .action-data-table .table-head-title label.check-this,
#manage-actions .manage-actions .categories-section .action-data-table .table-head-title label.not-check-this {
  width: 1%;
  margin-left: 5px;
  margin-right: 5px;
  text-overflow: clip !important;
}
#manage-actions .manage-actions .categories-section .action-data-table .table-head-title label.color-red-sign {
  color: #d0021b;
}
#manage-actions .manage-actions .categories-section .action-data-table .table-head-title label:first-child {
  padding-left: 10px;
}
#manage-actions .manage-actions .categories-section .action-data-table .table-head-title label:nth-child(3),
#manage-actions .manage-actions .categories-section .action-data-table .table-head-title label:nth-child(4),
#manage-actions .manage-actions .categories-section .action-data-table .table-head-title label:nth-child(5) {
  text-align: center;
}
#manage-actions .manage-actions .categories-section .action-data-table .table-head-title.table-body {
  margin-top: 0;
  border-bottom: 0;
}
#manage-actions .manage-actions .categories-section .action-data-table .table-head-title .table-body-content {
  width: 100%;
  float: left;
  background: #fff;
  margin-top: 0;
  border-bottom: solid 1px #dcdada;
  cursor: pointer;
}
#manage-actions .manage-actions .categories-section .action-data-table .table-head-title .table-body-content label {
  font-weight: 400;
  padding: 10px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}
#manage-actions .manage-actions .categories-section .action-data-table .table-head-title .table-body-content label:first-child {
  padding-left: 10px;
}
#manage-actions .manage-actions .categories-section .action-data-table .table-head-title .table-body-content label.extra-width {
  margin: 0;
  width: 4%;
  text-align: center;
}
#manage-actions .manage-actions .categories-section .action-data-table .table-head-title .table-body-content label.extra-width span {
  width: 100%;
  float: left;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#manage-actions .manage-actions .categories-section .action-data-table .table-head-title .table-body-content label a {
  width: 60px;
  float: right;
  color: #105c9d;
  border: solid 1px #105c9d;
  text-align: center;
}
#main-category-modal .modal-dialog,
#sub-category-modal .modal-dialog,
#material-group-modal .modal-dialog,
#add-action-modal .modal-dialog,
#add-role-modal .modal-dialog,
#material-price-diff-modal .modal-dialog,
#add-material-list-modal .modal-dialog,
#add-action-list-modal .modal-dialog,
#add-category-modal .modal-dialog,
#upload-modal .modal-dialog {
  width: 420px;
}
#main-category-modal .modal-dialog .modal-content,
#sub-category-modal .modal-dialog .modal-content,
#material-group-modal .modal-dialog .modal-content,
#add-action-modal .modal-dialog .modal-content,
#add-role-modal .modal-dialog .modal-content,
#material-price-diff-modal .modal-dialog .modal-content,
#add-material-list-modal .modal-dialog .modal-content,
#add-action-list-modal .modal-dialog .modal-content,
#add-category-modal .modal-dialog .modal-content,
#upload-modal .modal-dialog .modal-content {
  padding: 20px 40px 0 40px;
  border-radius: 0;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
#main-category-modal .modal-dialog .modal-content .modal-header,
#sub-category-modal .modal-dialog .modal-content .modal-header,
#material-group-modal .modal-dialog .modal-content .modal-header,
#add-action-modal .modal-dialog .modal-content .modal-header,
#add-role-modal .modal-dialog .modal-content .modal-header,
#material-price-diff-modal .modal-dialog .modal-content .modal-header,
#add-material-list-modal .modal-dialog .modal-content .modal-header,
#add-action-list-modal .modal-dialog .modal-content .modal-header,
#add-category-modal .modal-dialog .modal-content .modal-header,
#upload-modal .modal-dialog .modal-content .modal-header {
  border: 0;
  padding: 0;
}
#main-category-modal .modal-dialog .modal-content .modal-header .close,
#sub-category-modal .modal-dialog .modal-content .modal-header .close,
#material-group-modal .modal-dialog .modal-content .modal-header .close,
#add-action-modal .modal-dialog .modal-content .modal-header .close,
#add-role-modal .modal-dialog .modal-content .modal-header .close,
#material-price-diff-modal .modal-dialog .modal-content .modal-header .close,
#add-material-list-modal .modal-dialog .modal-content .modal-header .close,
#add-action-list-modal .modal-dialog .modal-content .modal-header .close,
#add-category-modal .modal-dialog .modal-content .modal-header .close,
#upload-modal .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  font-size: 30px;
  margin: -15px -25px;
}
#main-category-modal .modal-dialog .modal-content .modal-header .close:focus,
#sub-category-modal .modal-dialog .modal-content .modal-header .close:focus,
#material-group-modal .modal-dialog .modal-content .modal-header .close:focus,
#add-action-modal .modal-dialog .modal-content .modal-header .close:focus,
#add-role-modal .modal-dialog .modal-content .modal-header .close:focus,
#material-price-diff-modal .modal-dialog .modal-content .modal-header .close:focus,
#add-material-list-modal .modal-dialog .modal-content .modal-header .close:focus,
#add-action-list-modal .modal-dialog .modal-content .modal-header .close:focus,
#add-category-modal .modal-dialog .modal-content .modal-header .close:focus,
#upload-modal .modal-dialog .modal-content .modal-header .close:focus {
  outline: none;
}
#main-category-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#sub-category-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#material-group-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#add-action-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#add-role-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#material-price-diff-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#add-material-list-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#add-action-list-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#add-category-modal .modal-dialog .modal-content .modal-header h4.modal-title,
#upload-modal .modal-dialog .modal-content .modal-header h4.modal-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #105c9d;
  margin-bottom: 0;
}
#main-category-modal .modal-dialog .modal-content .modal-body,
#sub-category-modal .modal-dialog .modal-content .modal-body,
#material-group-modal .modal-dialog .modal-content .modal-body,
#add-action-modal .modal-dialog .modal-content .modal-body,
#add-role-modal .modal-dialog .modal-content .modal-body,
#material-price-diff-modal .modal-dialog .modal-content .modal-body,
#add-material-list-modal .modal-dialog .modal-content .modal-body,
#add-action-list-modal .modal-dialog .modal-content .modal-body,
#add-category-modal .modal-dialog .modal-content .modal-body,
#upload-modal .modal-dialog .modal-content .modal-body {
  width: 100%;
  float: left;
  padding-top: 30px;
  padding-bottom: 20px;
}
#main-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#material-group-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#add-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#add-role-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#add-material-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#add-action-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#add-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form,
#upload-modal .modal-dialog .modal-content .modal-body .add-clinic-form {
  width: 100%;
  float: left;
  margin: 10px 0;
}
#main-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form #instruction-textarea,
#sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form #instruction-textarea,
#material-group-modal .modal-dialog .modal-content .modal-body .add-clinic-form #instruction-textarea,
#add-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form #instruction-textarea,
#add-role-modal .modal-dialog .modal-content .modal-body .add-clinic-form #instruction-textarea,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form #instruction-textarea,
#add-material-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form #instruction-textarea,
#add-action-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form #instruction-textarea,
#add-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form #instruction-textarea,
#upload-modal .modal-dialog .modal-content .modal-body .add-clinic-form #instruction-textarea {
  width: 100%;
  padding: 70px 5px 50px;
  text-align: center;
}
#main-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form input,
#sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form input,
#material-group-modal .modal-dialog .modal-content .modal-body .add-clinic-form input,
#add-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form input,
#add-role-modal .modal-dialog .modal-content .modal-body .add-clinic-form input,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form input,
#add-material-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form input,
#add-action-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form input,
#add-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form input,
#upload-modal .modal-dialog .modal-content .modal-body .add-clinic-form input {
  text-align: center;
  margin: 0;
  width: 100%;
  float: left;
  line-height: 30px;
  height: 36px;
}
#main-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content,
#sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content,
#material-group-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content,
#add-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content,
#add-role-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content,
#add-material-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content,
#add-action-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content,
#add-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content,
#upload-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content {
  text-align: right;
}
#main-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content label,
#sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content label,
#material-group-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content label,
#add-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content label,
#add-role-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content label,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content label,
#add-material-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content label,
#add-action-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content label,
#add-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content label,
#upload-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content label {
  font-weight: 400;
  line-height: 31px;
  margin: 0;
}
#main-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content select,
#sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content select,
#material-group-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content select,
#add-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content select,
#add-role-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content select,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content select,
#add-material-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content select,
#add-action-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content select,
#add-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content select,
#upload-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content select {
  width: 100px;
  float: right;
  padding: 5px 0;
  margin-left: 8px;
  text-align: center;
  border: solid 1px #a5a3a3;
  color: #a5a3a3;
  font-size: 14px;
}
#main-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content input,
#sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content input,
#material-group-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content input,
#add-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content input,
#add-role-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content input,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content input,
#add-material-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content input,
#add-action-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content input,
#add-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content input,
#upload-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content input {
  width: 150px;
  float: right;
  padding: 0;
  line-height: 29px;
  height: 35px;
  margin-left: 8px;
  margin-bottom: 5px;
  text-align: center;
  border: solid 1px #a5a3a3;
  color: #a5a3a3;
  font-size: 14px;
}
#main-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul,
#sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul,
#material-group-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul,
#add-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul,
#add-role-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul,
#add-material-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul,
#add-action-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul,
#add-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul,
#upload-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul {
  width: 150px;
  float: right;
  margin-right: -22px;
}
#main-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li,
#sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li,
#material-group-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li,
#add-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li,
#add-role-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li,
#add-material-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li,
#add-action-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li,
#add-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li,
#upload-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li {
  width: 100%;
  float: left;
}
#main-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li input,
#sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li input,
#material-group-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li input,
#add-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li input,
#add-role-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li input,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li input,
#add-material-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li input,
#add-action-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li input,
#add-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li input,
#upload-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li input {
  width: 120px;
  float: left;
}
#main-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li i,
#sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li i,
#material-group-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li i,
#add-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li i,
#add-role-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li i,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li i,
#add-material-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li i,
#add-action-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li i,
#add-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li i,
#upload-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li i {
  float: right;
  margin-top: 10px;
}
#main-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn,
#sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn,
#material-group-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn,
#add-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn,
#add-role-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn,
#add-material-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn,
#add-action-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn,
#add-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn,
#upload-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn {
  width: 100%;
  float: left;
}
#main-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button,
#sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button,
#material-group-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button,
#add-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button,
#add-role-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button,
#add-material-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button,
#add-action-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button,
#add-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button,
#upload-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button {
  border-radius: 0;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 0 20px;
  color: #105c9d;
  float: right;
  background: none;
}
#main-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:hover,
#sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:hover,
#material-group-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:hover,
#add-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:hover,
#add-role-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:hover,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:hover,
#add-material-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:hover,
#add-action-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:hover,
#add-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:hover,
#upload-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:hover,
#main-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:focus,
#sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:focus,
#material-group-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:focus,
#add-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:focus,
#add-role-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:focus,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:focus,
#add-material-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:focus,
#add-action-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:focus,
#add-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:focus,
#upload-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button:focus,
#main-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button.active-tab-close,
#sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button.active-tab-close,
#material-group-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button.active-tab-close,
#add-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button.active-tab-close,
#add-role-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button.active-tab-close,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button.active-tab-close,
#add-material-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button.active-tab-close,
#add-action-list-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button.active-tab-close,
#add-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button.active-tab-close,
#upload-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content .manage-add-btn button.active-tab-close {
  background: #105c9d;
  color: #fff;
}
#main-category-modal .modal-dialog .modal-content .modal-footer,
#sub-category-modal .modal-dialog .modal-content .modal-footer,
#material-group-modal .modal-dialog .modal-content .modal-footer,
#add-action-modal .modal-dialog .modal-content .modal-footer,
#add-role-modal .modal-dialog .modal-content .modal-footer,
#material-price-diff-modal .modal-dialog .modal-content .modal-footer,
#add-material-list-modal .modal-dialog .modal-content .modal-footer,
#add-action-list-modal .modal-dialog .modal-content .modal-footer,
#add-category-modal .modal-dialog .modal-content .modal-footer,
#upload-modal .modal-dialog .modal-content .modal-footer {
  width: 100%;
  float: left;
  border: 0;
  padding: 5px 30px 30px;
}
#main-category-modal .modal-dialog .modal-content .modal-footer button,
#sub-category-modal .modal-dialog .modal-content .modal-footer button,
#material-group-modal .modal-dialog .modal-content .modal-footer button,
#add-action-modal .modal-dialog .modal-content .modal-footer button,
#add-role-modal .modal-dialog .modal-content .modal-footer button,
#material-price-diff-modal .modal-dialog .modal-content .modal-footer button,
#add-material-list-modal .modal-dialog .modal-content .modal-footer button,
#add-action-list-modal .modal-dialog .modal-content .modal-footer button,
#add-category-modal .modal-dialog .modal-content .modal-footer button,
#upload-modal .modal-dialog .modal-content .modal-footer button {
  border-radius: 0;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 5px 20px;
  color: #105c9d;
  float: left;
}
#main-category-modal .modal-dialog .modal-content .modal-footer button:hover,
#sub-category-modal .modal-dialog .modal-content .modal-footer button:hover,
#material-group-modal .modal-dialog .modal-content .modal-footer button:hover,
#add-action-modal .modal-dialog .modal-content .modal-footer button:hover,
#add-role-modal .modal-dialog .modal-content .modal-footer button:hover,
#material-price-diff-modal .modal-dialog .modal-content .modal-footer button:hover,
#add-material-list-modal .modal-dialog .modal-content .modal-footer button:hover,
#add-action-list-modal .modal-dialog .modal-content .modal-footer button:hover,
#add-category-modal .modal-dialog .modal-content .modal-footer button:hover,
#upload-modal .modal-dialog .modal-content .modal-footer button:hover,
#main-category-modal .modal-dialog .modal-content .modal-footer button:focus,
#sub-category-modal .modal-dialog .modal-content .modal-footer button:focus,
#material-group-modal .modal-dialog .modal-content .modal-footer button:focus,
#add-action-modal .modal-dialog .modal-content .modal-footer button:focus,
#add-role-modal .modal-dialog .modal-content .modal-footer button:focus,
#material-price-diff-modal .modal-dialog .modal-content .modal-footer button:focus,
#add-material-list-modal .modal-dialog .modal-content .modal-footer button:focus,
#add-action-list-modal .modal-dialog .modal-content .modal-footer button:focus,
#add-category-modal .modal-dialog .modal-content .modal-footer button:focus,
#upload-modal .modal-dialog .modal-content .modal-footer button:focus,
#main-category-modal .modal-dialog .modal-content .modal-footer button.active-tab-close,
#sub-category-modal .modal-dialog .modal-content .modal-footer button.active-tab-close,
#material-group-modal .modal-dialog .modal-content .modal-footer button.active-tab-close,
#add-action-modal .modal-dialog .modal-content .modal-footer button.active-tab-close,
#add-role-modal .modal-dialog .modal-content .modal-footer button.active-tab-close,
#material-price-diff-modal .modal-dialog .modal-content .modal-footer button.active-tab-close,
#add-material-list-modal .modal-dialog .modal-content .modal-footer button.active-tab-close,
#add-action-list-modal .modal-dialog .modal-content .modal-footer button.active-tab-close,
#add-category-modal .modal-dialog .modal-content .modal-footer button.active-tab-close,
#upload-modal .modal-dialog .modal-content .modal-footer button.active-tab-close {
  background: #105c9d;
  color: #fff;
}
#main-category-modal .modal-dialog .modal-content .modal-footer button i,
#sub-category-modal .modal-dialog .modal-content .modal-footer button i,
#material-group-modal .modal-dialog .modal-content .modal-footer button i,
#add-action-modal .modal-dialog .modal-content .modal-footer button i,
#add-role-modal .modal-dialog .modal-content .modal-footer button i,
#material-price-diff-modal .modal-dialog .modal-content .modal-footer button i,
#add-material-list-modal .modal-dialog .modal-content .modal-footer button i,
#add-action-list-modal .modal-dialog .modal-content .modal-footer button i,
#add-category-modal .modal-dialog .modal-content .modal-footer button i,
#upload-modal .modal-dialog .modal-content .modal-footer button i {
  float: left;
  margin: 2px 10px 2px 0;
}
#main-category-modal .modal-dialog .modal-content .modal-footer button + button,
#sub-category-modal .modal-dialog .modal-content .modal-footer button + button,
#material-group-modal .modal-dialog .modal-content .modal-footer button + button,
#add-action-modal .modal-dialog .modal-content .modal-footer button + button,
#add-role-modal .modal-dialog .modal-content .modal-footer button + button,
#material-price-diff-modal .modal-dialog .modal-content .modal-footer button + button,
#add-material-list-modal .modal-dialog .modal-content .modal-footer button + button,
#add-action-list-modal .modal-dialog .modal-content .modal-footer button + button,
#add-category-modal .modal-dialog .modal-content .modal-footer button + button,
#upload-modal .modal-dialog .modal-content .modal-footer button + button {
  float: right;
}
#add-material-list-modal .modal-dialog,
#add-action-list-modal .modal-dialog,
#upload-modal .modal-dialog {
  width: 900px;
}
#add-material-list-modal .modal-dialog .modal-content .modal-footer button,
#add-action-list-modal .modal-dialog .modal-content .modal-footer button,
#upload-modal .modal-dialog .modal-content .modal-footer button {
  float: right;
}
#add-action-modal .modal-dialog {
  width: 700px;
}
#add-action-modal .modal-dialog .modal-content .modal-body .action-no-name {
  width: 100%;
  float: left;
}
#add-action-modal .modal-dialog .modal-content .modal-body .action-no-name input {
  width: 15%;
  float: left;
  border: solid 1px #dcdada;
  min-height: 32px;
  height: 36px;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 14px;
}
#add-action-modal .modal-dialog .modal-content .modal-body .action-no-name input + input {
  width: 83%;
  margin-left: 2%;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform {
  width: 100%;
  float: left;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section {
  width: 32%;
  float: left;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division {
  width: 100%;
  float: left;
  margin-top: 25px;
  text-align: center;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division label {
  width: 100%;
  float: left;
  font-weight: 400;
  line-height: 31px;
  margin: 0;
  font-size: 14px;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division select {
  width: 100%;
  float: left;
  padding: 5px 0;
  margin-left: 0;
  text-align: center;
  border: solid 1px #a5a3a3;
  color: #a5a3a3;
  font-size: 14px;
  background: #eee;
  border-radius: 3px;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .several-options {
  margin: 0;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent {
  width: 100%;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent button.dropdown-toggle {
  width: 100%;
  float: left;
  padding: 5px 6px;
  margin-left: 0;
  text-align: center;
  border: solid 1px #a5a3a3;
  color: #a5a3a3;
  font-size: 14px;
  background: #eee;
  border-radius: 3px;
  box-shadow: none;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent button.dropdown-toggle .caret {
  float: right;
  margin-top: 8px;
  border-top: 6px dashed;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent ul.dropdown-menu.dropdown-menu-form {
  width: 100%;
  padding: 0;
  margin: 0;
  background: #eee;
  border-radius: 0;
  max-height: 40vh;
  z-index: 10;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent ul.dropdown-menu.dropdown-menu-form::-webkit-scrollbar-track {
  background-color: #ccc;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent ul.dropdown-menu.dropdown-menu-form::-webkit-scrollbar {
  width: 6px;
  background-color: #ccc;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent ul.dropdown-menu.dropdown-menu-form::-webkit-scrollbar-thumb {
  background-color: #105c9d;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent ul.dropdown-menu.dropdown-menu-form li {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
  border-bottom: solid 1px #fff;
  color: #105c9d;
  font-weight: 600;
  cursor: pointer;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent ul.dropdown-menu.dropdown-menu-form li:nth-child(1),
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent ul.dropdown-menu.dropdown-menu-form li:nth-child(2) {
  width: 50%;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent ul.dropdown-menu.dropdown-menu-form li:nth-child(1) a,
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent ul.dropdown-menu.dropdown-menu-form li:nth-child(2) a {
  padding: 8px 0;
  font-size: 12px;
  width: 100%;
  float: left;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent ul.dropdown-menu.dropdown-menu-form li:nth-child(3) {
  display: none;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a {
  width: 100%;
  float: left;
  white-space: inherit;
  padding: 8px 20px;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox {
  margin: 0;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox label {
  display: flex;
  align-items: center;
  padding: 0;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox label input[type="checkbox"]:not(:checked) {
  margin: 0 20px 0 0;
  border: 1px solid #ccc;
  outline: none;
  -webkit-appearance: inherit;
  padding: 10px;
  position: static;
  cursor: pointer;
  display: inline;
  border-radius: 3px;
  background: #fff;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox label input[type="checkbox"]:checked {
  margin: 0 20px 0 0;
  border: 1px solid #105c9d;
  outline: none;
  cursor: pointer;
  -webkit-appearance: inherit;
  position: relative;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 10px;
  display: inline;
  background: #105c9d;
  border-radius: 3px;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a .checkbox label input[type="checkbox"]:checked:after {
  content: '\f00c';
  position: absolute;
  top: 5px;
  left: 4px;
  font-size: 12px;
  color: #fff;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a:hover {
  color: #105c9d;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent ul.dropdown-menu.dropdown-menu-form li a:focus {
  outline: none;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division .multiselect-parent ul.dropdown-menu.dropdown-menu-form li.active a {
  background: #ddd;
  color: #000;
  padding: 8px 20px;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .right-cat-section {
  width: 65%;
  float: right;
  margin-top: 20px;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .right-cat-section .head-part {
  width: 100%;
  float: left;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .right-cat-section .head-part h4 {
  width: 40%;
  float: left;
  opacity: 0;
  font-weight: normal;
  margin: 0;
  font-size: 14px;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .right-cat-section .head-part h4 + h4 {
  width: 30%;
  float: left;
  text-align: center;
  opacity: 1;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .right-cat-section .body-part {
  width: 100%;
  float: left;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .right-cat-section .body-part .repeat-box-li {
  width: 100%;
  float: left;
  margin-top: 10px;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .right-cat-section .body-part .repeat-box-li h4 {
  width: 40%;
  float: left;
  font-weight: normal;
  margin: 0;
  font-size: 14px;
  text-align: right;
  line-height: 24px;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .right-cat-section .body-part .repeat-box-li .create-area-check {
  width: 30%;
  float: left;
  text-align: center;
  display: inline-block;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .right-cat-section .body-part .repeat-box-li .create-area-check label {
  float: none;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .right-cat-section .body-part .repeat-box-li .create-area-check select {
  width: 65%;
  float: left;
  border: solid 1px #dcdada;
  min-height: 24px;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  margin-left: 45px;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .right-cat-section .body-part .repeat-box-li .create-area-check select:disabled {
  opacity: 0.5;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .right-cat-section .body-part .repeat-box-li .create-area-check input[type="text"] {
  width: 160%;
  float: left;
  border: solid 1px #dcdada;
  min-height: 24px;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  margin-bottom: 35px;
  margin-left: 45px;
}
#add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .right-cat-section .body-part .repeat-box-li.extra-margin-35 {
  margin-top: 35px;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.create-area-check label.checkbox-style {
  width: 24px;
  font-weight: normal;
  font-size: 14px;
  position: relative;
  height: 24px;
  display: inline-block;
  margin: 0;
}
.create-area-check label.checkbox-style label {
  color: #1f2532 !important;
  margin-left: 0 !important;
  font-weight: 400;
}
.create-area-check label.checkbox-style input[type="checkbox"] {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 11;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}
.create-area-check label.checkbox-style input[type="checkbox"] label {
  color: #1f2532 !important;
  margin-left: 0 !important;
  font-weight: 400;
}
.create-area-check label.checkbox-style input[type="checkbox"] input[type="checkbox"] {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 11;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}
.create-area-check label.checkbox-style input[type="checkbox"] input[type="checkbox"] + label {
  position: relative;
  padding-left: 30px;
  line-height: 23px;
  float: left;
}
.create-area-check label.checkbox-style input[type="checkbox"] input[type="checkbox"] + label:after {
  width: 23px;
  height: 23px;
  content: '';
  border: solid 2px #4a4a4a;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  border-radius: 3px;
}
.create-area-check label.checkbox-style input[type="checkbox"] input[type="checkbox"]:checked + label:after {
  border-color: #105c9d;
}
.create-area-check label.checkbox-style input[type="checkbox"] input[type="checkbox"]:checked + label:before {
  content: '\2714';
  font-size: 14px;
  text-align: center;
  color: #fff !important;
  background: #105c9d;
  width: 23px;
  height: 23px;
  position: absolute;
  left: 0;
  border-radius: 3px;
  z-index: 10;
}
@media (max-width: 767px) {
  #manage-actions .container {
    margin-top: 10px;
    padding: 0;
  }
  #manage-actions .manage-actions .actions-header {
    padding: 10px 0 5px;
    font-size: 15px;
  }
  #manage-actions .manage-actions .categories-section .section-header {
    padding: 10px 0 0;
    font-size: 14px;
  }
  #manage-actions .manage-actions .categories-section .section-header + div {
    padding: 0;
  }
  #manage-actions .manage-actions .categories-section .category-section-block ul {
    margin-top: 2px;
  }
  #manage-actions .manage-actions .categories-section .category-section-block ul li {
    padding: 0 5px;
    margin: 2px;
    font-weight: 400;
    font-size: 12px;
  }
  #manage-actions .manage-actions .categories-section .category-section-block h4 {
    padding: 0 3px;
    margin: 4px 2px 0 0;
    line-height: 22px;
    font-size: 14px;
  }
  #manage-actions .manage-actions .categories-section .category-section-block button {
    padding: 0 5px;
    margin: 4px;
    font-weight: 400;
    font-size: 12px;
  }
  #manage-actions .manage-actions .categories-section .manage-action-filter .category-dropdown {
    width: 48%;
  }
  #manage-actions .manage-actions .categories-section .manage-action-filter .category-dropdown select {
    padding: 3px 0;
  }
  #manage-actions .manage-actions .categories-section .manage-action-filter .category-search-input {
    width: 100%;
    margin-top: 4px;
  }
  #manage-actions .manage-actions .categories-section .manage-action-filter .category-search-input input {
    padding: 2px 0;
    font-size: 12px;
    height: 31px;
  }
  #manage-actions .manage-actions .categories-section .manage-action-filter .category-search-input button {
    padding: 1px 7px 0;
    margin: 0 5px 5px;
    font-size: 14px;
    float: right;
  }
  #manage-actions .manage-actions .categories-section .action-data-table {
    overflow-x: scroll;
  }
  #manage-actions .manage-actions .categories-section .action-data-table p {
    text-align: left;
  }
  #manage-actions .manage-actions .categories-section .action-data-table .table-scroll-mobile {
    width: 1169px;
  }
  #main-category-modal .modal-dialog,
  #sub-category-modal .modal-dialog,
  #material-group-modal .modal-dialog,
  #add-action-modal .modal-dialog {
    width: 300px;
    margin: 10px auto;
  }
  #main-category-modal .modal-dialog .modal-content,
  #sub-category-modal .modal-dialog .modal-content,
  #material-group-modal .modal-dialog .modal-content,
  #add-action-modal .modal-dialog .modal-content {
    padding: 15px 30px 0 30px;
  }
  #main-category-modal .modal-dialog .modal-content .modal-body,
  #sub-category-modal .modal-dialog .modal-content .modal-body,
  #material-group-modal .modal-dialog .modal-content .modal-body,
  #add-action-modal .modal-dialog .modal-content .modal-body {
    padding: 10px 0;
  }
  #main-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content,
  #sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content,
  #material-group-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content,
  #add-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content {
    text-align: center;
  }
  #main-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content label,
  #sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content label,
  #material-group-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content label,
  #add-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content label,
  #main-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content select,
  #sub-category-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content select,
  #material-group-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content select,
  #add-action-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content select {
    width: 100%;
    float: left;
    margin: 0;
  }
  #main-category-modal .modal-dialog .modal-content .modal-footer,
  #sub-category-modal .modal-dialog .modal-content .modal-footer,
  #material-group-modal .modal-dialog .modal-content .modal-footer,
  #add-action-modal .modal-dialog .modal-content .modal-footer {
    padding: 5px 5px 20px;
  }
  #add-action-modal .modal-dialog .modal-content {
    padding: 15px 10px 0 10px;
  }
  #add-action-modal .modal-dialog .modal-content .modal-header .close {
    margin: -15px -5px;
  }
  #add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section,
  #add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .right-cat-section {
    width: 100%;
  }
  #add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .cat-division select,
  #add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .right-cat-section .cat-division select {
    margin-left: 0;
  }
  #add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .body-part .repeat-box-li .create-area-check input[type="text"],
  #add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .right-cat-section .body-part .repeat-box-li .create-area-check input[type="text"] {
    margin-left: 15px;
  }
  #add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .left-cat-section .body-part .repeat-box-li .create-area-check input[type="number"],
  #add-action-modal .modal-dialog .modal-content .modal-body .cat-action-perform .right-cat-section .body-part .repeat-box-li .create-area-check input[type="number"] {
    margin-left: 15px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  #manage-actions .container {
    margin-top: 150px;
    padding: 0;
  }
  #manage-actions .manage-actions .actions-header {
    padding: 10px 0 5px;
    font-size: 15px;
  }
  #manage-actions .manage-actions .categories-section .section-header {
    padding: 10px 0 0;
    font-size: 14px;
  }
  #manage-actions .manage-actions .categories-section .section-header + div {
    padding: 0;
  }
  #manage-actions .manage-actions .categories-section .category-section-block ul {
    margin-top: 2px;
  }
  #manage-actions .manage-actions .categories-section .category-section-block ul li {
    padding: 0 5px;
    margin: 2px;
    font-weight: 400;
    font-size: 12px;
  }
  #manage-actions .manage-actions .categories-section .category-section-block h4 {
    padding: 0 3px;
    margin: 4px 2px 0 0;
    line-height: 22px;
    font-size: 14px;
  }
  #manage-actions .manage-actions .categories-section .category-section-block button {
    padding: 0 5px;
    margin: 4px;
    font-weight: 400;
    font-size: 12px;
  }
  #manage-actions .manage-actions .categories-section .manage-action-filter .category-search-input button {
    padding: 1px 30px;
  }
  #manage-actions .manage-actions .categories-section .action-data-table p {
    text-align: left;
  }
  #manage-actions .manage-actions .categories-section .action-data-table .table-head-title label a {
    width: 40px !important;
    float: right !important;
  }
  #manage-actions .manage-actions .categories-section .action-data-table .table-head-title label:nth-child(2) {
    width: 13%;
  }
  #manage-actions .manage-actions .categories-section .action-data-table .table-head-title label.check-this {
    font-size: 8px;
  }
}
@media (min-width: 1024px) and (max-width: 1365px) {
  #manage-actions .container {
    margin-top: 150px;
    padding: 0;
  }
  #manage-actions .manage-actions .actions-header {
    padding: 10px 0 5px;
    font-size: 15px;
  }
  #manage-actions .manage-actions .categories-section .section-header {
    padding: 10px 0 0;
    font-size: 14px;
  }
  #manage-actions .manage-actions .categories-section .section-header + div {
    padding: 0;
  }
  #manage-actions .manage-actions .categories-section .category-section-block ul {
    margin-top: 2px;
  }
  #manage-actions .manage-actions .categories-section .category-section-block ul li {
    padding: 0 5px;
    margin: 2px;
    font-weight: 400;
    font-size: 12px;
  }
  #manage-actions .manage-actions .categories-section .category-section-block h4 {
    padding: 0 3px;
    margin: 4px 2px 0 0;
    line-height: 22px;
    font-size: 14px;
  }
  #manage-actions .manage-actions .categories-section .category-section-block button {
    padding: 0 5px;
    margin: 4px;
    font-weight: 400;
    font-size: 12px;
  }
  #manage-actions .manage-actions .categories-section .manage-action-filter .category-search-input button {
    padding: 1px 30px;
  }
  #manage-actions .manage-actions .categories-section .action-data-table p {
    text-align: left;
  }
  #manage-actions .manage-actions .categories-section .action-data-table .table-head-title label a {
    width: 40px !important;
    float: right !important;
  }
  #manage-actions .manage-actions .categories-section .action-data-table .table-head-title label:nth-child(2) {
    width: 16%;
  }
  #manage-actions .manage-actions .categories-section .action-data-table .table-head-title label.check-this {
    font-size: 8px;
  }
}
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul {
  width: 150px;
  float: left;
  margin-right: 0;
  text-align: center;
}
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li span,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form > span,
#material-price-diff-modal .modal-dialog .modal-content .modal-body .add-clinic-form.select-content ul li input {
  width: 100%;
  float: left;
  border: solid 1px #bab9b9;
  margin-bottom: 5px;
  line-height: 33px;
  text-align: center;
  color: #bab9b9;
  font-weight: 300;
}
#material-price-diff-modal .modal-dialog .modal-content .modal-footer button {
  float: right;
}
.subcategory-input-margin {
  margin: 6px 0 30px;
}
.sub-category-sec {
  margin-left: 0;
}
.sub-category-checkbox input {
  width: 100%;
  float: left;
  background: #eee;
  text-align: center;
  border: solid 1px #a9a7a7;
}
.sub-category-checkbox i {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #aaa;
}
.sub-category-dropdown-menu {
  top: 30px;
  width: 100%;
  margin: 0;
  padding: 0;
}
.sub-category-dropdown-menu li {
  cursor: pointer;
}
.sub-category-dropdown-menu li .checkbox-style {
  padding: 10px 20px;
  width: 100%;
  background: #eee;
  margin-bottom: 0;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}
.sub-category-dropdown-menu li:hover label.checkbox-style label {
  cursor: pointer;
  color: #105c9d !important;
}
.font-14 {
  font-size: 14px;
}
#reports {
  width: 100%;
  float: left;
}
#reports .container {
  margin-top: 150px;
  padding-bottom: 50px;
  background: #fff;
}
#reports .report-separator {
  width: 100%;
  float: left;
  padding: 40px 20px 5px;
  border-bottom: solid 2px #4a4a4a;
}
#reports .report-data-placeholder {
  width: 100%;
  float: left;
}
#reports .report-data-placeholder p {
  padding: 15px 15px 15px;
  background: #eee;
}
#reports span.spinner {
  position: absolute;
  top: 250px;
  left: 50%;
}
#reports .report-container {
  width: 100%;
  float: left;
}
#reports .report-container .report-header {
  width: 100%;
  float: left;
  padding: 40px 20px 5px;
  border-bottom: solid 2px #4a4a4a;
  color: #4a4a4a;
  font-size: 20px;
  font-weight: 600;
}
#reports .report-container .report-data-header {
  width: 100%;
  float: left;
  padding: 10px 20px 10px;
  background: #eee;
}
#reports .report-container .report-data-header h1 {
  padding: 40px 20px 5px;
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 600;
}
#reports .report-container .report-data-header button {
  float: right;
  width: auto;
  padding: 1px 30px;
  margin: 35px 20px 5px;
  border: solid 1px #1f2532;
  color: #1f2532;
  background: #fff;
  font-weight: 600;
}
#reports .report-container .report-data-row {
  width: 100%;
  float: left;
  padding: 10px 20px 10px;
  border-bottom: solid 1px #dcdada;
}
#reports .report-container .report-data-row-heading {
  width: 100%;
  float: left;
  background: #eee;
  padding: 10px 20px;
}
#reports .report-container .report-data-row-heading.scroll-auto {
  padding: 0;
  overflow-x: auto;
  background: #eee;
}
#reports .report-container .report-data-row-heading.scroll-auto .display-flex span {
  min-width: 200px;
  float: left;
  padding: 10px 20px;
  max-width: 200px;
}
#reports .report-container .report-data-row-heading.scroll-auto .display-flex.bg-change-white {
  background: #fff;
}
#reports .report-container .report-data-row-heading.scroll-auto .display-flex.bg-change-white span {
  background: #fff;
}
#reports .report-container .report-data-row-heading.scroll-auto .display-flex.bg-change-white:nth-child(2n) {
  background: #eee;
}
#reports .report-container .report-data-row-heading.scroll-auto .display-flex.bg-change-white:nth-child(2n) span {
  background: #eee;
}
#reports .report-container .report-data-row-heading.scroll-auto .display-flex.report-data-row {
  padding: 0;
}
#reports .report-container .report-data-row-heading.scroll-auto .vertical-scroll-auto {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  display: inline-block;
}
#reports .report-container .report-data-row-heading.scroll-auto .full-white-space + .vertical-scroll-auto {
  width: 100%;
}
#reports .report-container .report-options-section {
  width: 100%;
  float: left;
}
#reports .report-container .report-options-section .report-option-row {
  width: 100%;
  float: left;
  margin: 15px 0;
}
#reports .report-container .report-options-section .report-option-row .report-option {
  width: 100%;
  float: left;
  margin-right: 2%;
}
#reports .report-container .report-options-section .report-option-row .report-option label {
  width: 100%;
  float: left;
  font-weight: 500;
  line-height: 31px;
  margin: 0;
  font-size: 16px;
}
#reports .report-container .report-options-section .report-option-row .report-option select {
  width: 100%;
  float: left;
  height: 2em;
  padding: 5px 0;
  text-align: center;
  border: solid 1px #a5a3a3;
  color: #979797;
  font-size: 16px;
  background: #fff;
}
#reports .report-container .report-options-section .report-option-row .report-option button {
  width: auto;
  padding: 1px 30px;
  margin: 16% 5px 5px;
  border: solid 1px #1f2532;
  color: #fff;
  background: #1f2532;
  font-weight: 600;
}
#reports .report-container .report-options-section .report-option-row .report-option input {
  width: 100%;
  float: left;
  text-align: left;
  border-radius: 4px 4px 4px;
  border: solid 1px #a5a3a3;
  color: #979797;
  font-size: 16px;
  background: #fff;
  padding-left: 5px;
}
#reports .report-container .report-options-section .action-data-table {
  width: 100%;
  float: left;
  background: #eee;
  margin: 15px 0;
}
#reports .report-container .report-options-section .action-data-table p {
  width: 100%;
  float: left;
  text-align: right;
  font-size: 11px;
  padding: 5px 10px;
}
#reports .report-container .report-options-section .action-data-table p span {
  color: #d0021b;
}
#reports .report-container .report-options-section .action-data-table .table-head-title {
  width: 100%;
  float: left;
  margin-top: 60px;
  border-bottom: solid 1px #dcdada;
}
#reports .report-container .report-options-section .action-data-table .table-head-title label {
  width: 10%;
  float: left;
  font-size: 12px;
  color: #1f2532;
  font-weight: 600;
  margin-bottom: 0;
}
#reports .report-container .report-options-section .action-data-table .table-head-title label:nth-child(2) {
  width: 22%;
}
#reports .report-container .report-options-section .action-data-table .table-head-title label.rotate-90 {
  width: 1%;
  transform: rotate(-90deg);
  white-space: nowrap;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 400;
}
#reports .report-container .report-options-section .action-data-table .table-head-title label.extra-width {
  width: 4%;
  margin: -17px 0;
}
#reports .report-container .report-options-section .action-data-table .table-head-title label.check-this,
#reports .report-container .report-options-section .action-data-table .table-head-title label.not-check-this {
  width: 1%;
  margin-left: 5px;
  margin-right: 5px;
  text-overflow: clip !important;
}
#reports .report-container .report-options-section .action-data-table .table-head-title label.color-red-sign {
  color: #d0021b;
}
#reports .report-container .report-options-section .action-data-table .table-head-title label:first-child {
  padding-left: 10px;
}
#reports .report-container .report-options-section .action-data-table .table-head-title label:nth-child(3),
#reports .report-container .report-options-section .action-data-table .table-head-title label:nth-child(4),
#reports .report-container .report-options-section .action-data-table .table-head-title label:nth-child(5) {
  text-align: center;
}
#reports .report-container .report-options-section .action-data-table .table-head-title.table-body {
  margin-top: 0;
  border-bottom: 0;
}
#reports .report-container .report-options-section .action-data-table .table-head-title .table-body-content {
  width: 100%;
  float: left;
  background: #fff;
  margin-top: 0;
  border-bottom: solid 1px #dcdada;
  cursor: pointer;
}
#reports .report-container .report-options-section .action-data-table .table-head-title .table-body-content label {
  font-weight: 400;
  padding: 10px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}
#reports .report-container .report-options-section .action-data-table .table-head-title .table-body-content label:first-child {
  padding-left: 10px;
}
#reports .report-container .report-options-section .action-data-table .table-head-title .table-body-content label.extra-width {
  margin: 0;
  width: 4%;
  text-align: center;
}
#reports .report-container .report-options-section .action-data-table .table-head-title .table-body-content label.extra-width span {
  width: 100%;
  float: left;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#reports .report-container .report-options-section .action-data-table .table-head-title .table-body-content label a {
  width: 60px;
  float: right;
  color: #105c9d;
  border: solid 1px #105c9d;
  text-align: center;
}
.display-flex {
  display: flex;
}
#reports select,
#reports input {
  background: #ffffff;
  border: solid 1px #a5a3a3;
  border-radius: 0 !important;
  height: 33px !important;
  color: #929090 !important;
  font-size: 14px !important;
  padding-left: 15px !important;
}
#reports input::placeholder {
  color: #929090 !important;
  font-size: 14px !important;
}
#reports .status-filter h3 {
  width: 100%;
  float: left;
  font-weight: 500;
  line-height: 31px;
  margin: 0;
  font-size: 16px;
}
#reports .status-filter .several-options {
  margin: 0 0 15px;
}
#reports .createBtn {
  margin-top: -25px;
}
#reports .report-description-icon {
  min-width: 18px;
  height: 18px;
  text-align: center;
  line-height: 16px;
  border: solid 2px #105c9d;
  border-radius: 50%;
  margin-right: 5px;
  color: #105c9d;
  cursor: pointer;
  margin-top: -23px;
  font-size: 11px;
}
.construction-pointer {
  cursor: pointer;
}
#construction-section.order-two-part .SearchPatient.newConstructionType {
  width: 100%;
  float: left;
  display: inline-block;
  text-align: center;
}
#construction-section.order-two-part .SearchPatient.newConstructionType ul {
  float: none;
  width: auto;
  display: inline-block;
  margin-top: 15px;
}
#construction-section.order-two-part .SearchPatient.newConstructionType span {
  float: left;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  margin: 20px 0;
}
#construction-section.order-two-part .SearchPatient.newConstructionType li {
  float: left;
  width: auto;
  text-align: center;
  border: solid 0 #105c9d;
  height: 32px;
  margin: 0 10px 5px;
}
#construction-section.order-two-part .SearchPatient.newConstructionType li a {
  color: #105c9d;
  border: solid 1px #105c9d;
  padding: 0;
  font-size: 16px;
  min-width: 150px;
}
#construction-section.order-two-part .SearchPatient.newConstructionType li.active a {
  font-weight: bold;
  background-color: #1f2532;
  width: 100%;
  float: left;
  color: #fff;
}
#construction-section.order-two-part .right-sec-head .btn-default {
  font-size: 22px;
  border: 0;
  padding-right: 0;
  color: #000;
}
#construction-section.order-two-part .right-sec-head .btn-default:hover {
  background: none;
  color: #000;
}
#construction-section.order-two-part .align-btn .required-field {
  margin-top: -40px;
  position: static !important;
}
#construction-section.order-two-part .align-btn button {
  width: 120px;
  float: right;
  border: solid 1px #105c9d;
  line-height: 32px;
  color: #105c9d;
  cursor: pointer;
  font-weight: 500;
  background: none;
  opacity: 1;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}
#construction-section.order-two-part .align-btn button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
#construction-section.order-two-part .SelectMaterial p.red-astrix-mark,
.color-sampling-radio p.red-astrix-mark {
  margin: 0;
  line-height: 16px;
}
#construction-section.order-two-part .SelectMaterial.remove-margin-top {
  margin-top: 0;
}
#construction-section ul.singleSub-odr-2 {
  float: none;
  width: auto;
  margin-top: 5px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
}
#construction-section ul.singleSub-odr-2 li {
  margin: 0 10px 5px;
  width: auto;
  min-width: 150px;
}
#construction-section ul.singleSub-odr-2 li a {
  /*border: solid 1px @sky-start-dark !important;*/
  font-weight: 400 !important;
  text-transform: uppercase;
  padding: 4px;
  font-size: 14px;
}
#construction-section ul.singleSub-odr-2 li.active a {
  box-shadow: 0 2px 2px rgba(8, 8, 8, 0.66);
  font-weight: bold !important;
}
#construction-section.order-two-part h1 {
  color: inherit;
}
#construction-section .manage-flex-boxes .SelectMaterial.designBox.img-width-change ul {
  margin-top: 1px;
}
#construction-section .manage-flex-boxes .SelectMaterial.designBox.img-width-change ul li {
  margin-bottom: 0;
  border-bottom: solid 1px #ccc;
}
#construction-section .manage-flex-boxes .SelectMaterial.designBox.img-width-change ul li a {
  padding: 6px 10px;
  height: 36px;
}
#construction-section .SelectMaterial.colorBox ul li.orthColorSelect select {
  padding: 8.5px 0;
}
#construction-section.order-two-part .clickonBoth {
  width: 80%;
  margin-left: 10%;
}
.manage-div-smooth {
  width: 100%;
  float: left;
}
/*Constructions css*/
#construction-section {
  width: 100%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
#construction-section.simple-order-section {
  margin-top: 0;
}
@media only screen and (min-width: 787px) {
  .cons-sec-modify {
    margin-top: 120px;
  }
}
#construction-section .Constructions {
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 35px;
  padding: 18px 61px 50px;
  padding-bottom: 50px;
}
@media only screen and (min-width: 787px) {
  .cons-sec-modify {
    margin-top: 120px;
  }
}
#construction-section h1.patientTitle {
  float: left;
  font-size: 20px;
  color: #1f2532;
  font-weight: 700;
  border-bottom: solid 1px #1f2532;
  width: 100%;
  line-height: 50px;
}
#construction-section .SearchPatient {
  float: left;
  width: 100%;
  padding: 5px;
}
#construction-section .SearchPatient.margin-less-top {
  padding-top: 0px;
}
#construction-section .SearchPatient ul {
  float: left;
  width: 100%;
}
#construction-section .SearchPatient li {
  float: left;
  width: 49%;
  text-align: center;
}
#construction-section .SearchPatient li a {
  font-size: 18px;
  text-transform: uppercase;
  color: #969696;
  border-bottom: solid 5px #fff;
  width: 100%;
  float: left;
  text-decoration: none;
}
#construction-section .SearchPatient li.active a {
  font-weight: bold;
  border-bottom: solid 5px #1f2532;
  width: 100%;
  float: left;
  color: #1f2532;
}
#construction-section .constructions-sub-types {
  width: 100%;
  float: left;
  text-align: center;
  display: inline-block;
}
#construction-section .SearchPatient li.active a:focus {
  outline: none;
}
#construction-section ul.singleSub {
  float: none;
  width: auto;
  margin-top: 5px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
}
#construction-section ul.singleSub li {
  margin: 0 5px;
  width: 155px;
}
#construction-section ul.singleSub li a {
  border: 0 !important;
  font-weight: 400 !important;
  color: #000 !important;
  text-transform: uppercase;
  padding: 4px 0;
  font-size: 14px;
}
#construction-section ul.singleSub li.active a {
  color: #fff !important;
  box-shadow: 0px 2px 2px rgba(8, 8, 8, 0.66);
  font-weight: bold !important;
}
#construction-section ul.implantSub {
  float: right;
  width: 100%;
  margin-top: 30px;
}
#construction-section ul.implantSub li {
  margin-right: 10px;
  width: 49%;
}
#construction-section ul.implantSub li:last-child {
  margin-right: 0;
}
#construction-section ul.implantSub li a {
  border: 0 !important;
  background: #f4f4f4;
  font-weight: normal !important;
  color: #000 !important;
  text-transform: uppercase;
  padding: 10px 15px;
  font-size: 19px;
}
#construction-section ul.implantSub li.active a {
  background: #a6d273;
  color: #fff !important;
  box-shadow: 0px 2px 2px rgba(8, 8, 8, 0.66);
  font-weight: bold !important;
}
#construction-section .clickonBoth {
  float: left;
  width: 100%;
  margin-top: 0px;
  margin-left: 0;
}
#construction-section .clickonBoth .well-sm.text-center p {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 500;
}
#construction-section .clickonBoth h1 {
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 35px;
  font-weight: 400;
  color: #1f2532;
}
#construction-section .btn span.glyphicon {
  opacity: 0;
}
#construction-section .btn.active span.glyphicon {
  opacity: 1;
}
#construction-section label.btn {
  background: #fff;
  border: solid 1px #ccc;
  border-radius: 4px;
  width: 57px;
  height: 57px;
}
#construction-section label.btn.active {
  background: #cedd3d;
  color: #fff;
  border: solid 1px #ccc;
  box-shadow: none;
  position: relative;
}
#construction-section label.btn.active:after {
  width: 0;
  height: 0;
  border-bottom: 46px solid #105c9d;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  position: absolute;
  right: 22.5px;
  bottom: -3px;
  content: '';
}
#construction-section label.btn:focus,
#construction-section label.btn.focus {
  outline: none !important;
  box-shadow: none;
}
#construction-section .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
#construction-section .btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
#construction-section p.range {
  position: absolute;
  bottom: 0px;
  left: -53px;
  text-transform: uppercase;
  font-size: 14px;
}
#construction-section .well-sm.text-center.marginBottom {
  margin-top: 35px;
}
#construction-section .well-sm {
  padding: 0;
}
#construction-section #bridgeTeeth {
  float: left;
  width: 100%;
  margin: 30px 0 15px;
  padding-bottom: 10px;
}
#construction-section .divider-new {
  font-weight: 300;
  margin-top: 45px;
  margin-bottom: 5px;
  float: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#construction-section .divider-new h1 {
  text-transform: uppercase;
  font-size: 22px;
  color: #4a4a4a;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 0;
}
#construction-section .divider-new::after {
  content: '';
  height: 1px;
  background: #868686;
  flex: 1;
  margin: 11px 0 0 0.45em;
}
#construction-section .divider-new::before {
  content: '';
  height: 1px;
  background: #868686;
  flex: 1;
  margin: 11px 0.45em 0 0;
}
#construction-section .SelectMaterial {
  float: left;
  width: 100%;
  margin-top: 10px;
}
#construction-section .SelectMaterial h3 {
  font-size: 15px;
  margin-top: 10px;
}
#construction-section .SelectMaterial ul {
  width: 100%;
  float: left;
  margin-top: 15px;
}
#construction-section .SelectMaterial ul li {
  float: left;
  width: auto;
  min-width: 19%;
  margin-right: 4px;
  text-align: center;
  margin-bottom: 12px;
  cursor: pointer;
}
#construction-section .SelectMaterial ul li a {
  color: #5a5959;
  padding: 4px 3px;
  width: 100%;
  float: left;
  min-width: 70px;
  font-size: 13px;
  height: 30px;
}
#construction-section .SelectMaterial ul li.active {
  background: #105c9d;
}
#construction-section .SelectMaterial.designBox.img-width-change ul li img {
  width: 20px;
}
#construction-section .SelectMaterial ul li.active a {
  color: #fff;
}
#construction-section .SelectMaterial.colorBox ul li {
  width: 35%;
}
#construction-section .SelectMaterial.colorBox ul li {
  width: 100%;
}
#construction-section .SelectMaterial.colorBox ul li select {
  border: 0;
  padding: 5px 0px;
  background: transparent;
  width: 100%;
  float: left;
  font-size: 13px;
}
#construction-section .ColorSampling {
  float: right;
  width: 100%;
  text-align: left;
  margin-right: 0;
  margin-top: 0px;
  font-size: 12px;
  margin-bottom: 12px;
}
#construction-section .ColorSampling .checkbox-inline {
  padding-left: 0px;
  width: 100%;
}
#construction-section .form-group.other-material {
  margin-top: 7px;
  float: left;
}
#construction-section .form-group.other-material label {
  float: left;
  width: 100%;
  font-weight: normal;
  margin-top: 20px;
}
#construction-section .form-group.other-material input {
  width: 100%;
  height: 34px;
}
#construction-section .form-group.other-material.post-input input {
  width: 100%;
  height: 34px;
  margin-top: 9px;
  float: left;
}
#construction-section .bridgeTeeth {
  float: left;
  width: 100%;
  padding: 3px 0 5px;
}
#construction-section .bridgeTeeth span {
  text-transform: uppercase;
}
#construction-section h1 {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  line-height: 28px;
}
#construction-section h3.addPost {
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  padding-top: 3px;
}
#construction-section .SelectMaterial.designBox ul li {
  width: 100%;
  float: left;
  padding-left: 5px;
  text-align: left;
  margin-bottom: 10px;
}
#construction-section .SelectMaterial.designBox ul li img {
  height: 20px;
  margin-right: 5px;
}
#construction-section .padding_Bottom-50 {
  margin-bottom: 50px;
}
#construction-section .SelectMaterial.colorBox ul {
  width: 97%;
}
#construction-section .right-sec-head .btn-default {
  color: #fff;
  background: none;
  border-color: #fff;
  font-size: 12px;
  border-radius: initial;
  font-weight: 600;
  padding: 4px 25px 2px;
  margin-right: 8px;
}
#construction-section .right-sec-head .btn-default:hover,
#construction-section .right-sec-head .btn-default.active {
  background: #fff;
  color: #a6d273;
  box-shadow: initial;
  opacity: 1;
}
#construction-section .btn.active span.glyphicon {
  opacity: 0;
}
#construction-section .SelectMaterial ul li input {
  width: 100px;
  font-size: 13px;
  padding: 2px 5px 2px;
  min-height: 30px;
}
#construction-section .ColorSampling input[type='checkbox'].ng-empty {
  float: left;
  width: 15px;
  margin: 0px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid #4a4a4a;
  outline: none;
  -webkit-appearance: inherit;
  padding: 8px;
  border-radius: 4px;
  position: static;
  cursor: pointer;
}
#construction-section .ColorSampling input[type='checkbox'].ng-not-empty {
  float: left;
  width: 15px;
  margin: 0px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid #1f2532;
  outline: none;
  cursor: pointer;
  -webkit-appearance: inherit;
  position: static;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 8px;
  border-radius: 4px;
}
#construction-section .ColorSampling input[type='checkbox'].ng-not-empty:after {
  content: '\f00c';
  position: absolute;
  top: 4px;
  left: 4px;
  font-size: 12px;
  color: #1f2532;
}
#construction-section .softInside input[type='checkbox'].ng-empty {
  float: left;
  width: 15px;
  margin: 0px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid #4a4a4a;
  outline: none;
  -webkit-appearance: inherit;
  padding: 8px;
  border-radius: 4px;
  position: static;
  cursor: pointer;
}
#construction-section .softInside input[type='checkbox'].ng-not-empty {
  float: left;
  width: 15px;
  margin: 0px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid #ffcb05;
  outline: none;
  cursor: pointer;
  -webkit-appearance: inherit;
  position: static;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 8px;
  border-radius: 4px;
}
#construction-section .softInside input[type='checkbox'].ng-not-empty:after {
  content: '\f00c';
  position: absolute;
  top: 4px;
  left: 4px;
  font-size: 12px;
  color: #ffcb05;
}
#construction-section .selectmaterial ul li.single-crown a {
  color: #fff;
}
#construction-section .selectmaterial ul li.bridge a {
  color: #fff;
}
#construction-section .selectmaterial ul li.post a {
  color: #fff;
}
#construction-section .selectmaterial ul li.implant-crown a {
  color: #fff;
}
#construction-section .selectmaterial ul li.implant-bridge a {
  color: #fff;
}
#construction-section .selectmaterial ul li.other a {
  color: #fff;
}
#construction-section .default-bg {
  background: #d8d5d5;
}
#construction-section .default-bg.inactive-construction {
  opacity: 0.5;
  cursor: not-allowed;
}
#construction-section .gray-bg-color {
  background: #dcdada;
}
#construction-section .single-crown {
  background: #cddc39;
}
#construction-section .single-crown a {
  color: #fff;
}
#construction-section .other {
  background: #461b7e;
}
#construction-section .other a {
  color: #fff;
}
#construction-section .single-crown.bitetray {
  background: linear-gradient(to bottom, #ffcb05 50%, #cddc39 0%);
}
#construction-section .other.bitetray {
  background: linear-gradient(to bottom, #ffcb05 50%, #461b7e 0%);
}
#construction-section .bridge {
  background: #e92266;
}
#construction-section .bridge a {
  color: #fff;
}
#construction-section .bridge.bitetray {
  background: linear-gradient(to bottom, #ffcb05 50%, #e92266 0%);
}
#construction-section .post {
  background: #4a4a4a;
}
#construction-section .post a {
  color: #fff;
}
#construction-section .post.bitetray {
  background: linear-gradient(to bottom, #ffcb05 50%, #4a4a4a 0%);
}
#construction-section .implant-crown {
  background: #e65405;
}
#construction-section .implant-crown a {
  color: #fff;
}
#construction-section .implant-crown.bitetray {
  background: linear-gradient(to bottom, #ffcb05 50%, #e65405 0%);
}
#construction-section .implant-bridge {
  background: #096d9f;
}
#construction-section .implant-bridge a {
  color: #fff;
}
#construction-section .implant-bridge.bitetray {
  background: linear-gradient(to bottom, #ffcb05 50%, #096d9f 0%);
}
#construction-section .complete-denture {
  background: #2c3995;
}
#construction-section .complete-denture a {
  color: #fff;
}
#construction-section .complete-denture.bitetray {
  background: linear-gradient(to bottom, #ffcb05 50%, #2c3995 0%);
}
#construction-section .partial-denture {
  background: #a6d273;
}
#construction-section .partial-denture a {
  color: #fff;
}
#construction-section .partial-denture.bitetray {
  background: linear-gradient(to bottom, #ffcb05 50%, #a6d273 0%);
}
#construction-section .temporary-denture {
  background: #0095a8;
}
#construction-section .temporary-denture a {
  color: #fff;
}
#construction-section .temporary-denture.bitetray {
  background: linear-gradient(to bottom, #ffcb05 50%, #0095a8 0%);
}
#construction-section .other-removable {
  background: #461b7e;
}
#construction-section .other-removable a {
  color: #fff;
}
#construction-section .other-removable.bitetray {
  background: linear-gradient(to bottom, #ffcb05 50%, #461b7e 0%);
}
#construction-section .bitetray {
  background: #ffcb05;
}
#construction-section .bitetray a {
  color: #fff;
}
#construction-section #bridgeTeeth.single-crown-section {
  border: solid 1px #cddc39;
  position: relative;
}
#construction-section #bridgeTeeth.implant-crown-section {
  border: solid 1px #e65405;
  position: relative;
}
#construction-section #bridgeTeeth.bridge-section {
  border: solid 1px #e92266;
  position: relative;
}
#construction-section #bridgeTeeth.implant-bridge-section {
  border: solid 1px #096d9f;
  position: relative;
}
#construction-section #bridgeTeeth.partial-denture-section {
  border: solid 1px #a6d273;
  position: relative;
}
#construction-section #bridgeTeeth.temporary-denture-section {
  border: solid 1px #0095a8;
  position: relative;
}
#construction-section #bridgeTeeth.other-removable-section {
  border: solid 1px #461b7e;
  position: relative;
}
#construction-section #bridgeTeeth.complete-denture-section {
  border: solid 1px #2c3995;
  position: relative;
}
#construction-section #bridgeTeeth.bitetray-denture-section {
  border: solid 1px #ffcb05;
  position: relative;
}
#construction-section #bridgeTeeth.bridgeTeethPost {
  border: solid 1px #4a4a4a;
  position: relative;
}
#construction-section #bridgeTeeth.bridgeTeethOther {
  border: solid 1px #461b7e;
  position: relative;
}
#construction-section #bridgeTeeth.other-section {
  border: solid 1px #461b7e;
  position: relative;
}
#construction-section .bridge-start {
  border: 3px solid #1f2532;
  border-right: none;
  border-radius: 8px 0 0 8px;
  margin-right: 0px;
  width: 38px;
  padding: 0px;
}
#construction-section .bridge-selected.bridge-start {
  border: 3px solid #1f2532;
  border-right: none;
  border-radius: 8px 0 0 8px;
  margin-right: 0px;
  width: 38px;
  padding: 0px;
}
#construction-section .bridge-start-temp {
  border: 3px solid #1f2532;
  border-right: none;
  border-left: none;
  border-radius: 0px;
  margin-right: 0px;
  width: 38px;
  padding: 0px;
}
#construction-section .bridge-end {
  border: 3px solid #1f2532;
  border-left: none;
  border-radius: 0px 8px 8px 0px;
  margin-left: 0px;
  padding: 0px;
  width: 38px;
}
#construction-section .bridge-selected.bridge-end {
  border: 3px solid #1f2532;
  border-left: none;
  border-radius: 0px 8px 8px 0px;
  margin-left: 0px;
  padding: 0px;
  width: 38px;
}
#construction-section .bridge-middle {
  border: 3px solid #1f2532;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
  width: 38px;
}
#construction-section .bridge-selected {
  border-radius: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
  width: 38px;
  border-left: none;
  border-right: none;
  position: relative;
}
#construction-section .bridge-selected-start {
  border-radius: 0px;
  margin-right: 0px;
  padding: 0px;
  width: 38px;
  border-right: none;
  border-radius: 3px 0px 0px 3px;
  position: relative;
}
#construction-section .bridge-selected-end {
  border-radius: 0px;
  margin-left: 0px;
  padding: 0px;
  width: 38px;
  border-left: none;
  border-radius: 0px 3px 3px 0px;
  position: relative;
}
#construction-section .single-crown.square-selected .cross-square,
#construction-section .implant-crown.square-selected .cross-square,
#construction-section .bridge-end.bridge .cross-square,
#construction-section .bridge-end.implant-bridge .cross-square,
#construction-section .partial-denture.square-selected .cross-square,
#construction-section .temporary-denture.square-selected .cross-square,
#construction-section .bridge-end.complete-denture .cross-square,
#construction-section .bridge-end.bitetray .cross-square,
#construction-section .bridge-selected-end.bridge-end .cross-square,
#construction-section .other-removable.square-selected .cross-square,
#construction-section .other.square-selected .cross-square {
  display: block;
}
#construction-section .single-crown.square-selected .cross-square:hover,
#construction-section .implant-crown.square-selected .cross-square:hover,
#construction-section .bridge-end.bridge .cross-square:hover,
#construction-section .bridge-end.implant-bridge .cross-square:hover,
#construction-section .partial-denture.square-selected .cross-square:hover,
#construction-section .temporary-denture.square-selected .cross-square:hover,
#construction-section .bridge-end.complete-denture .cross-square:hover,
#construction-section .bridge-end.bitetray .cross-square:hover,
#construction-section .bridge-selected-end.bridge-end .cross-square:hover,
#construction-section .other-removable.square-selected .cross-square:hover,
#construction-section .other.square-selected .cross-square:hover {
  background: #0095a8;
}
#construction-section .square-post.square-selected .cross-rectangle-post {
  display: block;
}
#construction-section .square-post.square-selected .cross-rectangle-post:hover {
  background: #0095a8;
}
#construction-section #bridgeTeeth.bridgeTeethPost {
  border: solid 1px #4a4a4a;
}
#construction-section #bridgeTeeth.bridgeTeethPost .bridgeTeeth {
  background: #4a4a4a;
}
#construction-section #bridgeTeeth.bridgeTeethPost .bridgeTeeth .row .container .col-md-6.col-lg-6.col-sm-6.col-xs-12 h1.bridgeTeeth {
  padding: 0px;
  color: #fff;
}
#construction-section #bridgeTeeth.bridgeTeethPost .bridgeTeeth .row .container .col-md-6.col-lg-6.col-sm-6.col-xs-12 h1.bridgeTeeth span {
  font-weight: 600;
}
#construction-section #bridgeTeeth.bridgeTeethPost .row .extra-padding-left-right {
  padding: 0 45px;
}
#construction-section #bridgeTeeth.bridgeTeethPost .row .extra-padding-left-right .col-md-6.col-lg-6.col-sm-6.col-xs-12 .SelectMaterial ul li.active {
  background: #4a4a4a;
}
#construction-section #bridgeTeeth.bridgeTeethPost .row .extra-padding-left-right .col-md-6.col-lg-6.col-sm-6.col-xs-12 .form-group.other-material {
  margin-top: 5px;
  float: left;
  width: 49%;
  margin-left: 11%;
}
#construction-section #bridgeTeeth.bridgeTeethPost .row .extra-padding-left-right .col-md-6.col-lg-6.col-sm-6.col-xs-12 .form-group.other-material label {
  margin-bottom: 15px;
}
#construction-section #bridgeTeeth.bridgeTeethPost .row .extra-padding-left-right .col-md-6.col-lg-6.col-sm-6.col-xs-12 .form-group.other-material input {
  border-radius: 0px;
}
#construction-section #bridgeTeeth.bridgeTeethPost .extra-padding-left-right.padding_Bottom-50 {
  padding: 45px 45px 0px;
}
@media screen and (max-width: 767px) {
  #construction-section {
    padding-top: 0;
  }
  #construction-section .cross-rectangle-post {
    width: 12px;
    height: 12px;
    bottom: -15px;
    right: 9.5px;
    line-height: 12px;
    font-size: 8px;
  }
  #construction-section .cross-square {
    width: 12px;
    height: 12px;
    top: -9px;
    right: -8px;
    line-height: 12px;
    font-size: 8px;
  }
  #construction-section .margin-top {
    margin-top: -10px !important;
  }
  #construction-section .row {
    margin: 0;
  }
  #construction-section h1.patientTitle {
    font-size: 15px;
    line-height: 35px;
  }
  #construction-section .Constructions {
    margin-top: 0;
    padding: 0;
  }
  #construction-section .SearchPatient {
    padding: 10px 0 0;
  }
  #construction-section .SearchPatient ul {
    text-align: center;
    display: inline-block;
  }
  #construction-section .SearchPatient ul li {
    margin-bottom: 10px;
    max-width: 130px;
    float: none;
    display: inline-block;
  }
  #construction-section .SearchPatient ul li a {
    font-size: 12px;
    line-height: 14px;
    border-bottom: solid 2px #fff;
  }
  #construction-section .SearchPatient ul li.active a {
    font-weight: normal;
    border-bottom-width: 2px;
  }
  #construction-section .SearchPatient.margin-less-top .constructions-sub-types ul {
    display: block;
  }
  #construction-section .SearchPatient.margin-less-top .constructions-sub-types ul li {
    max-width: inherit;
    float: left;
    display: block;
  }
  #construction-section .divider-new h1 {
    margin-top: 0;
    font-size: 18px;
  }
  #construction-section .clickonBoth {
    width: 100%;
    margin-left: 0;
  }
  #construction-section .clickonBoth .well-sm .range {
    display: none;
  }
  #construction-section .clickonBoth .well-sm .btn-group {
    margin-bottom: 10px;
  }
  #construction-section .margin-top-45 {
    margin-top: 5px !important;
  }
  #construction-section .SelectMaterial h3 {
    margin-top: 0;
    line-height: 24px;
  }
  #construction-section .SelectMaterial ul {
    margin-top: 0;
  }
  #construction-section .SelectMaterial ul li {
    width: 32%;
    margin-right: 1.33%;
    margin-bottom: 3px;
  }
  #construction-section .SelectMaterial ul li a {
    padding: 6px 3px;
    font-size: 11px;
  }
  #construction-section .SelectMaterial .designBox ul li {
    width: 100%;
  }
  #construction-section .SelectMaterial.designBox ul li {
    margin-bottom: 3px;
  }
  #construction-section .form-group .other-material {
    width: 100%;
  }
  #construction-section ul.singleSub li {
    width: 49%;
    margin: 1px 0.5%;
  }
  #construction-section #bridgeTeeth .row {
    margin: 0 -25px;
  }
  #construction-section .bridge-suggestion,
  #construction-section .partial-suggestion {
    margin-left: 0;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    font-size: 12px;
    font-weight: normal;
  }
}
.whitelabel {
  background-color: #fafafa !important;
}
.whitelabel button.mat-button {
  color: #105c9d !important;
}
.whitelabel button.mat-raised-button,
.whitelabel .mat-ink-bar {
  background-color: #105c9d !important;
}
.whitelabel a {
  color: #105c9d !important;
}
.whitelabel i.fa,
.whitelabel .shape-open-list-btn {
  color: #105c9d !important;
  border-color: #105c9d !important;
}
.whitelabel #dentist-order-detail .dentist-order-detail .chat-send-btn input {
  background-color: #105c9d !important;
}
