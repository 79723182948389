.whitelabel {
    background-color: #fafafa !important;
    button.mat-button {
        color: #105c9d !important;
    }
    button.mat-raised-button, .mat-ink-bar {
        background-color: #105c9d !important;
    }
    a {
        color: #105c9d !important;
    }
    i.fa, .shape-open-list-btn {
        color: #105c9d !important;
        border-color: #105c9d !important;
    }
    #dentist-order-detail .dentist-order-detail .chat-send-btn input {
        background-color: #105c9d !important;        
    }
}

