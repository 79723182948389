#three-shape-upload-modal, #three-shape-scan-view {
  h4 {
    text-align: center;
    color: #636363 !important;
    font-weight: 600 !important;
    border: none !important;
  }
  h5 {
    text-align: center;
    color: #aaa;
  }
  .close {
    font-size: 25px !important;
    opacity: 1.2 !important;
  }
  .shape-download-info {
    float: left;
    padding: 8px 32px;
    margin: 20px 5px 12px;
    i {
      font-size: 25px;
      color: #5dcbcc;
    }
  }

  .scan-spinner-text {
    font-size: 20px !important;
    color: #000 !important;
  }
}

.shape-open-list-btn {
  color: #fff;
  padding: 5px;
  margin-left: 0;
  font-size: 13px;
  text-transform: uppercase;
  background: #105c9d;
  margin-bottom: 3px;
  border: none;
}
.uploading-toastr {
  background: #fff !important;
  color: black !important;
  i{
    color:#5dcbcc !important;
  }
  .toast-close-button{
    color:#5dcbcc !important;
  }
}

.shape-open-list-btn.image-annotation {
  padding: 3px 15px;
  float: initial;
  margin-left: 10px;
}

.annotation-instruction {
  font-size: 16px;
  font-weight: 500;
  margin: 50px 0 0 15px;
}