#three-shape-scan-view {
  .scan-file {
    max-width: 100%;
    height: 120px;
    object-fit: cover;
  }
  .modal-footer {
    border: none;
    text-align: center;
    display: inline-block;
    button {
      float: none !important;
    }
  }
}