#three-shape-scan-view .scan-file {
  max-width: 100%;
  height: 120px;
  object-fit: cover;
}
#three-shape-scan-view .modal-footer {
  border: none;
  text-align: center;
  display: inline-block;
}
#three-shape-scan-view .modal-footer button {
  float: none !important;
}
