@font-face {
  font-family: 'Roboto';
  src: url('../../../../../fonts/roboto/Roboto-Regular.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url('../../../../../fonts/roboto/Roboto-Regular.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  src: url('../../../../../fonts/roboto/Roboto-RegularItalic.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto-RegularItalic';
  src: url('../../../../../fonts/roboto/Roboto-RegularItalic.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-RegularItalic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  src: url('../../../../../fonts/roboto/Roboto-Light.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Light';
  src: url('../../../../../fonts/roboto/Roboto-Light.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-Light.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  src: url('../../../../../fonts/roboto/Roboto-LightItalic.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto-LightItalic';
  src: url('../../../../../fonts/roboto/Roboto-LightItalic.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-LightItalic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  src: url('../../../../../fonts/roboto/Roboto-Thin.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Thin';
  src: url('../../../../../fonts/roboto/Roboto-Thin.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-Thin.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  src: url('../../../../../fonts/roboto/Roboto-ThinItalic.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url('../../../../../fonts/roboto/Roboto-ThinItalic.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-ThinItalic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  src: url('../../../../../fonts/roboto/Roboto-Medium.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('../../../../../fonts/roboto/Roboto-Medium.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  src: url('../../../../../fonts/roboto/Roboto-MediumItalic.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url('../../../../../fonts/roboto/Roboto-MediumItalic.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-MediumItalic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  src: url('../../../../../fonts/roboto/Roboto-Bold.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url('../../../../../fonts/roboto/Roboto-Bold.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  src: url('../../../../../fonts/roboto/Roboto-BoldItalic.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url('../../../../../fonts/roboto/Roboto-BoldItalic.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-BoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  src: url('../../../../../fonts/roboto/Roboto-Black.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Black';
  src: url('../../../../../fonts/roboto/Roboto-Black.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-Black.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  src: url('../../../../../fonts/roboto/Roboto-BlackItalic.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url('../../../../../fonts/roboto/Roboto-BlackItalic.woff2') format('woff2'), url('../../../../../fonts/roboto/Roboto-BlackItalic.woff') format('woff');
}
