#section-page{
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 35px;
  padding: 18px 61px 50px;
}


#section-heading{
  float: left;
  font-size: 20px;
  color: #1f2532;
  font-weight: 700;
  border-bottom: solid 1px #1f2532;
  width: 100%;
  line-height: 50px;
}

#full-width{
  width: 100%; float: left;
}

.box-shadow(@shadows) {
  -webkit-box-shadow: @shadows;
  -moz-box-shadow: @shadows;
  box-shadow: @shadows;
}
.border-radius (@radius) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}

#rate-it{
  .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
    background-image: url("./../images/rated-star.svg");
  }
  .ngrateit-reset{
    background-image: url("./../images/cancel-icon.svg");
  }
  /* Background */
  .ngrateit-bg-star{
    background-image: url("./../images/bg-star.svg");
  }
  /* Selected state */
  .ngrateit-selected {
    background-image: url("./../images/rated-star.svg") ;
  }
  /* Cancelation of hover styling */
  .ngrateit:not(.ngrateit-readonly) .ngrateit-rating span:hover ~ span {
    background-image: url("./../images/bg-star.svg");
  }
  /* Hover styling */
  .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
    background-image: url("./../images/rated-star.svg") ;
  }
}