.add-row-span-boxes {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 100px;
    grid-gap: 10px;
}
.add-row-span-boxes .col-lg-3 {
    width: 100%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .add-row-span-boxes .col-lg-3 {
        width: 25%;
    }
}

.one-span {
    grid-row: span 1;
}

.two-span {
    grid-row: span 2;
}

.three-span {
    grid-row: span 3;
}

