.active-float-align .clinic-active {
  float: left !important;
}
.street-block h4 {
  color: #105c9d;
  margin-bottom: 5px;
}
.street-block select {
  line-height: 38px;
  min-height: 38px;
  width: 100%;
  font-size: 14px;
  height: 38px;
}
.street-block .upload-section-file {
  position: relative;
  width: 115px;
  float: left;
  height: 38px;
  overflow: hidden;
  margin-top: 30px;
}
.street-block .upload-section-file input[type="file"] {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.street-block .upload-section-file span {
  color: #105c9d;
  border: solid 1px #105c9d;
  line-height: 38px;
  min-height: 38px;
  height: 38px;
  font-size: 14px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 20px;
}
.street-block .file-name-style {
  float: left;
  margin: 35px 0 0 10px;
}
.ml-10 {
  margin-left: 10px;
}
