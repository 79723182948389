#schedule-pick-up-from-truck-icon-modal .modal-dialog {
  width: 840px;
}
#schedule-pick-up-from-truck-icon-modal .modal-dialog .commet-export-production {
  display: flex;
  align-items: center;
  margin-top: 28px;
}
#schedule-pick-up-from-truck-icon-modal .modal-dialog .commet-export-production input[type="checkbox"]:not(:checked) {
  margin-top: -8px;
  border-color: #000;
}
#schedule-pick-up-from-truck-icon-modal .modal-dialog .commet-export-production input[type="checkbox"]:checked {
  margin-top: -8px;
}
#schedule-pick-up-from-truck-icon-modal .modal-header h4 {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: #105c9d;
  margin-bottom: 0;
}
#schedule-pick-up-from-truck-icon-modal .modal-header {
  border: 0;
  padding: 25px 15px 35px;
}
#schedule-pick-up-from-truck-icon-modal .modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 30px 20px;
  border: 0;
  width: 100%;
}
#schedule-pick-up-from-truck-icon-modal .modal-footer button {
  background: none;
  color: #105c9d;
  border-color: #105c9d;
  padding: 5px 35px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 0;
}
#schedule-pick-up-from-truck-icon-modal .modal-footer button i {
  margin-right: 10px;
}
#schedule-pick-up-from-truck-icon-modal .modal-body {
  padding: 15px 55px;
}
#schedule-pick-up-from-truck-icon-modal .close {
  opacity: 1;
  font-size: 32px;
  margin-right: 14px;
}
#schedule-pick-up-from-truck-icon-modal .datepicker {
  bottom: 30px;
  z-index: 9;
}
#schedule-pick-up-from-truck-icon-modal .datepicker-hidden {
  z-index: -9;
}
.red-text {
  color: #ff0005 !important;
  text-align: center;
}
