#navBar{
  .newOrder.add-clinics{
    float: left;
    border-left: solid 0px #979797;
    border-right: solid 1px #979797;
    padding-left: 0;
    padding-right: 10px;
    margin-right: 10px;
  }
}
#clinics{
  #full-width; margin-top: 200px;
  .clinics{
    #full-width;
    ul{
      #full-width;
      li{
        width:29.30%; float:left; margin: 0 2% 30px; background: @white-color; .box-shadow(0 0 5px @gray-font-color); padding-top:14px;
        .clinic-block{
          #full-width;
          .delete-clinic{
            cursor: pointer;
            width: auto; float: right; margin-right:17px; position: relative; z-index: 9;
          }
          .clinic-img{
            #full-width; text-align: center; display: inline-block;
            .round-img-box{
              width: auto; float: none; display: inline-block; border: solid 1px @gray-bg-color; .border-radius(100%); padding: 18px; position: relative;
              .round-active-clinic{
                width: 20px; height: 20px; .border-radius(100%); background: @active-tab-green; float: left; margin: 3px 10px 0 0; position: absolute; bottom: 0px; right: -8px;
              }
              .round-inactive-clinic{
                width: 20px; height: 20px; .border-radius(100%); background: @dark-red-color; float: left; margin: 3px 10px 0 0; position: absolute; bottom: 0px; right: -8px;
              }
            }
          }
          .delete-clinic + .clinic-img{ margin-top: -15px;}
          h4{
            #full-width; text-align: center; color: @sky-start-dark; margin-top: 15px; min-height: 20px;
          }
          p{
            #full-width; text-align: center; color:@gray-font-color; font-weight: 300; font-size: 13px;
          }
          .clinic-activation-block{
            #full-width; background: @gray-bg-color; padding: 20px 10px; margin-top: 20px;
            .clinic-active{
              width: auto; float: left; margin-top: 4px; text-transform: uppercase; font-size: 14px; position: relative; z-index: 8;
              .round-active-clinic{
                width: 20px; height: 20px; .border-radius(100%); background: @active-tab-green; float: left; margin-right: 4px;
              }
              .round-inactive-clinic{
                width: 20px; height: 20px; .border-radius(100%); background: @dark-red-color; float: left; margin-right: 4px;
              }
            }
            .clinic-active-button{
              width: auto; float: right;
              a{
                color: @sky-start-dark; border: solid 1px @sky-start-dark; padding: 3px 5px; margin-left: 0px; font-size: 12px; text-transform: uppercase;
                &:hover, &:focus, &.activation-tab-btn{
                  color: @white-color; background: @sky-start-dark;
                }
              }
            }
          }
        }
      }
    }
  }
}

#add-clinic-modal, #add-client-modal, #add-material-modal, #add-actionList-action-modal, #add-transport-clinic-modal{
  .modal-dialog{
    width: 1000px;
    .modal-content{
      padding: 20px 40px 20px 70px;
      border-radius: 0px;
      background: @white-color;
      box-shadow: 0 5px 15px rgba(0,0,0,.5);
      .modal-header{
        border: 0px;
        .close{
          opacity: 1; font-size: 30px; margin: -15px -25px;
          &:focus{
            outline: none;
          }
        }
        h4.modal-title{
          font-size: 25px;
          font-weight: bold;
          text-align: center;
        }
      }
      .modal-body{
        text-align:left;
        .add-clinic-form{
          #full-width; margin-bottom: 55px; margin-top: 20px;
          .add-clinic-form-row{
            #full-width; margin-bottom: 30px;
            h4{
              color: @sky-start-dark; margin-bottom: 10px;
              p.red-astrix-mark {
                margin: 0;
                line-height: 19px;
              }
            }
            input{
              width: 210px; float: left; padding: 8px 10px; font-size:12px; text-align: center; border: solid 1px @gray-dark; min-height: 38px;
              &:focus{
                outline: none;
              }
            }

            .street-block{
              width: 25%; float: left; margin-right: 2%; margin-top: -15px;
              label{
                #full-width; color: @gray-bg-color; font-weight: 300; font-style: italic;
              }
              ul{
                #full-width;
                li{
                  width: auto; float: left; margin-right: 10px; margin-bottom: 10px; padding: 8px 15px; background: @gray-bg-color;
                  cursor: pointer;
                  &.choose-clinic-tab{
                    background: @sky-start-dark; color: @white-color;
                  }
                }
              }
              &.full-street-div {
                width: 100%;
              }
              .street-block-select {
                line-height: 38px;
                min-height: 38px;
                width: 120%;
                font-size: 14px;
                height: 38px;
              }
            }

            .street-block.postcode-input{
              width: 15%;
              input{ width: 100%;}
            }
          }
        }
      }
      .modal-footer{
        border: 0;
        margin: 30px 0 20px;
        .btn.btn-default{
          border-radius: 0px; border: solid 1px @sky-start-dark; text-transform: uppercase; font-size:18px; letter-spacing: 1px; font-weight: bold; padding: 10px 45px; color:@sky-start-dark;
          &:hover, &:focus, &.active-tab-close{
            background: @sky-start-dark; color: @white-color;
          }
        }
      }
    }
  }
}
.inValidPinCode{
  color: red;
  font-size: 11px;
}

#organization-setting {
  #full-width; margin-bottom: 5px;
  h4{
    color: @sky-start-dark; margin-bottom: 20px;
  }
  .full-street-div{
    width: 100%; float: left;
    label{
      #full-width; color: @gray-bg-color; font-weight: 300; font-style: italic;
    }
    ul{
      #full-width;
      li{
        width: auto; float: left; margin-right: 10px; margin-bottom: 10px; padding: 8px 15px; background: @gray-bg-color;
        cursor: pointer;
        &.choose-clinic-tab{
          background: @sky-start-dark; color: @white-color;
        }
      }
    }
  }
}

.is-Pick-up-delivery-place input[type="checkbox"]:not(:checked),
.is-Pick-up-delivery-place input.notCheckedInput {
  float: left !important;
  width: 15px !important;
  height: 15px;
  -webkit-appearance: inherit;
  margin: 2px 10px 0px 0px;
  background: none;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: rgb(142, 221, 221) !important;
  border-image: initial !important;
  outline: none;
  padding: 7px !important;
  border-radius: 2px;
  min-height: inherit !important;
}

.is-Pick-up-delivery-place input[type="checkbox"]:checked,
.is-Pick-up-delivery-place input:checked.checkedInput {
  float: left !important;
  width: 15px !important;
  height: 15px;
  -webkit-appearance: inherit;
  position: relative;
  margin: 2px 10px 0px 0px;
  background: rgb(31, 186, 187);
  border-width: 2px !important;
  border-style: solid !important;
  border-color: rgb(31, 186, 187) !important;
  border-image: initial !important;
  outline: 0px;
  font: 14px/1 FontAwesome;
  padding: 7px !important;
  border-radius: 4px;
  min-height: inherit !important;
}

.is-Pick-up-delivery-place input[type="checkbox"]:checked:after,
.is-Pick-up-delivery-place input:checked.checkedInput:after {
  content: '\f00c';
  position: absolute;
  top: 1px;
  left: 1px;
  font-size: 13px;
  color: #fff;
}

.is-Pick-up-delivery-place {
  label {
    font-weight: 400;
  }
}