.bankid-method {
  transition: height 0.2s ease-in-out;
  height: 0;
  overflow: hidden;
}
.bankid-method.active {
  display: block;
  height: 150px;
}
.bankid-method h2 {
  color: #1f2532 !important;
  text-transform: uppercase;
  font-size: 22px !important;
  font-weight: 300 !important;
}
.federated-iframe {
  transition: height 0.2s ease-in-out;
  height: 0;
  overflow: hidden;
}
.federated-iframe.active {
  display: block;
  height: 600px;
}
.federated-button {
  color: white;
  width: 100%;
  background-color: #105c9d;
  padding: 0px;
  margin: 10px;
  font-weight: 700;
  font-size: 14px;
  height: 36px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: none;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
