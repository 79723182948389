#order-confirm {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;   

  .order-confirm {
    width: 100%;    
    float: left;
    background: #fff;
    border-radius: 4px;
    margin-top: 150px;
    padding: 20px;
    text-align: center;
    h1 {
      font-size: 38px;
      text-align: center;
      color: #1f2532;
      margin-top: 37px;
      font-weight: bold;
    }
    .order-details {
      width: 100%;
      max-width: 800px;
      background: #f5f5f5;
      margin: auto;
      padding: 20px;
      text-align: center;
      display: inline-block;
      margin-top: 36px;

      span {
        float: left;
        width: 100%;
        font-size: 25px;
        margin-top: 10px;
      }
    }
    .order-button {
      width: 100%;
      float: left;
      text-align: center;
      margin-top: 37px;
      a {
        font-size: 28px;
        color: @gray-bg-color;
        padding: 20px 40px 20px 40px;
        text-transform: uppercase;
        text-decoration: none;
      }
      a&:hover {
        text-decoration: none
      }
    }
    .right-detail-part.order-created{
      width: 300px !important;
      float: none;
      margin: 30px auto 0;
      span{
        width: 70px; margin:0 35px; float: right; text-align: center; cursor: pointer;
        svg {
          height: 65px;
        }
        p{
          font-weight: bold;
          font-size: 20px;
          color: #bbb;
          line-height: 17px;
        }
        i{
          font-size: 65px;
          color: #ccc;
        }
      }
      button{
        margin:0 35px; text-align: center; cursor: pointer;
        background: none;
        border: 0;
        svg {
          height: 65px;
        }
        p{
          font-weight: bold;
          font-size: 20px;
          color: #bbb;
          line-height: 17px;
        }
        i{
          font-size: 65px;
          color: #ccc;
        }
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
    .right-detail-part.order-created.order-action-btn {
      width: 350px !important;
      button p {
        margin-top: 10px;
      }
    }
    .order-message {
      float: left;
      text-align: center;
      width: 100%;
      margin-top: 15px;
      margin-bottom: 15px;
      p {
        width: 50%;
        margin: auto;
        text-align: center;
        font-size: 20px;
        color: #a7a7a7;
        font-weight: 300;
      }
    }
    .next-step-btn.home-page-btn {
      display: inline-block;
      text-align: center;
      .step-button {
        width: 220px;
        float: none;
        display: inline-block;
      }
    }
  }

}