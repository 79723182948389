#report-description-modal .modal-dialog {
  min-width: 800px;
}
#report-description-modal .modal-dialog .modal-content {
  padding: 20px 50px 30px;
}
#report-description-modal .modal-dialog .modal-content .modal-header {
  border-bottom: 0;
}
#report-description-modal .modal-dialog .modal-content .modal-header h4 {
  text-align: center;
  color: #105c9d;
  font-weight: 600;
}
#report-description-modal .modal-dialog .modal-content .modal-header button {
  font-size: 32px;
  opacity: 1;
  margin: -25px -45px;
}
#report-description-modal .modal-dialog .modal-content .modal-body p {
  margin-bottom: 0;
}
#report-description-modal .modal-dialog .modal-content .modal-body p:first-child {
  margin-bottom: 20px;
}
