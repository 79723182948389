body {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  display: block;
  color: #5c5c5c;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.7;
  background: #F5F3F2 !important;
  font-family: 'Roboto', sans-serif !important;
}

div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
ul,
li,
img {
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  list-style: none;
}

a {
  text-decoration: none;
  color: #105c9d;
  font-weight: 400;
}

a:hover,
a:focus {
  text-decoration: none !important;
}

p {
  line-height: 1.7;
  margin-bottom: 10px;
  font-weight: 400;
}

span {
  font-weight: 400;
}

/*header ccs*/
.margin-0 {
  margin: 0;
}
.padding-0 {
  padding: 0;
}
.header-fixed {
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
}

#denthub-header {
  background: @header-bg;
  width: 100%;
  height: 48px;
  display: flex;
}
#denthub-header .logo-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  margin-left: 62px;
}
#denthub-header .menu-items-wrapper {
  display: flex;
  flex: 1 0 auto;
  align-items: center;

  .header-icon-button {
    margin-left: 10px;
    color: #fff;
  }
}
#denthub-header a.dentHub-first-part img {
  height: 30px;
}
#denthub-header a.dentHub-second-part img {
  height: 20px;
  margin-left: 10px;
}
#denthub-header a.white-label-logo img {
  height: 36px;
  margin-left: 10px;
}
#denthub-header .stylish-input-group {
  border-radius: 4px;
}
#denthub-header .stylish-input-group .input-group-addon {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
  font-size: 16px;
  padding-left: 15px;
  padding-right: 0;
}

#denthub-header .stylish-input-group .form-control {
  border-right: 0;
  box-shadow: 0 0 0;
  border-color: #ccc;
  width: 330px;
}

#denthub-header .stylish-input-group input {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
  font-size: 15px;
}

#denthub-header .stylish-input-group input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

#denthub-header #imaginary_container .form-control {
  border: inherit;
  border-radius: 0 1px 1px 0px !important;
  height: 39px;
}

#denthub-header #imaginary_container .input-group-addon {
  border: inherit;
  border-radius: 1px 0px 0px 1px;
}
#denthub-header #imaginary_container .input-group-addon span + span {
  display: none;
}
#denthub-header .btn-default {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  font-size: 17px;
  text-transform: uppercase;
  width: 73%;
  padding: 4px 0px;
  border-radius: inherit;
}

#denthub-header .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 10px dashed;
  border-top: 4px solid \9;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

#denthub-header .btn .caret {
  margin-right: 10px;
}

#denthub-header .btn:focus,
.btn:active:focus {
  outline: inherit;
  outline-offset: inherit;
  box-shadow: inherit;
}

#denthub-header .notificationTitle {
  width: 100%;
  color: #fff;
  float: left;
  font-size: 17px;
  padding-top: 14px;
  text-transform: uppercase;
}

#denthub-header .notificationTitle i {
  margin-right: 10px;
  font-size: 21px;
}

#denthub-header .row.padding-top-10 {
  padding-top: 10px;
}

#denthub-header #imaginary_container {
  margin: 2px auto 0px;
  float: left;
  margin-top: 0px;
  height: 39px;
}

#navBar {
  background: #1f2532;
  width: 100%;
  float: left;
  min-height: 53px;
  padding-left: 100px;
}
.navbar-default .navbar-collapse {
  min-height: 53px !important;
  height: inherit !important;
}
#navBar .navbar-default {
  background-color: transparent;
  border-color: transparent;
}

#navBar .navbar {
  margin-bottom: 0;
  border: 0;
  float: left;
}

#navBar .navbar-default .navbar-nav > .active > a,
#navBar .navbar-default .navbar-nav > .active > a:hover,
#navBar .navbar-default .navbar-nav > .active > a:focus {
  color: #fff;
  background-color: transparent;
  border-bottom: solid 9px #105c9d;
}

#navBar .navbar-default .navbar-nav > li > a:hover,
#navBar .navbar-default .navbar-nav > li > a:focus {
  color: #fff;
  background-color: transparent;
  border-bottom: solid 9px #105c9d;
}

#navBar .navbar-default .navbar-nav > li > a {
  color: #fff;
}
#navBar .container {
  position: relative;
}
#navBar .nav > li > a {
  position: relative;
  display: block;
  padding: 12px 0 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  cursor: pointer;
  width: 160px;
  text-align: center;
}
#navBar .nav > li > a.order2-step {
  width: 204px;
}
#navBar .nav > li > a.order-step {
  width: 204px;
}
#navBar .nav > li:first-child > a.first-a-li {
  width: 235px;
}
#navBar .navbar,
#navBar .nav {
  width: 100%;
}
#navBar .nav li.openModal {
  float: right;
}
#navBar .nav > li.dentist-order-manage {
  &.modify-order {
    border: 0;
    width: 202px !important;
    float: left;
  }
}
#navBar .nav > li.dentist-order-manage a {
  height: 52.99px;
}
#navBar .nav > li.dentist-order-manage:first-child > a {
  width: 270px;
}
#navBar .nav > li > a img {
  width: 16px;
  margin-top: -3px;
}

#navBar .nav li.sub-name a {
  padding: 7px 0 5px;
  font-size: 11px;
  font-weight: 400;
}
#navBar .nav li a p {
  margin: 0;
}
#navBar .nav li.sub-name a p {
  font-weight: 500;
  font-size: 16px;
  line-height: 10px;
  text-transform: uppercase;
  margin: 0;
}

#denthub-header .dropdown-menu > li > a:focus,
.dropdown-menu > li > {
  color: #105c9d;
  text-decoration: none;
  text-align: center;
  font-size: 15px;
  line-height: 22px;
  width: 100%;
  float: left;
}

#denthub-header .dropdown-menu > li > a:focus,
.dropdown-menu > li:last-child {
  border: 0;
}

#denthub-header .dropdown-menu > li > a {
  padding: 16px 15px !important;
  font-size: 17px;
  width: 100%;
  float: left;
}

#denthub-header .dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: transparent;
}

#denthub-header .dropdown-menu {
  padding-bottom: 0 !important;
  position: absolute;
  margin: 0 -77px 0 !important;
}

#denthub-header .collapsing,
.dropdown,
.dropup {
  margin-left: 19px;
}
#denthub-header #Notifications.open > button {
  position: relative;
}
#denthub-header #Notifications.open > button:after {
  content: '';
  height: 25px;
  right: 60px;
  position: absolute;
  top: 21px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #fff;
}

#denthub-header .btn-default:hover {
  color: #ffe;
  background-color: transparent !important;
  border-color: transparent !important;
}

.dropdown-menu {
  min-width: 196px !important;
}

#denthub-header .no-padding-default {
  padding: 0 !important;
}
.no-padding-default {
  padding: 0 !important;
}

#denthub-header::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 15px;
  color: #dcdcdc;
}

#denthub-header::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 15px;
  color: #dcdcdc;
}

#denthub-header:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 15px;
  color: #dcdcdc;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 15px;
  color: #dcdcdc;
}

/********************************less*****************/

#denthub-header {
  .search-bar-list {
    float: left;
    width: 370px;
    background: #fff;
    margin-top: 7px;
    position: absolute;
    top: 39px;
    z-index: 9;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.39), 0 2px 10px 0 rgba(0, 0, 0, 0.39);
    border-radius: 4px;
    ul {
      max-height: 75vh;
      overflow-y: auto;
      li {
        width: 100%;
        float: left;
        padding: 15px;
        div {
          line-height: 24px;
          color: black;
        }
        .person-name {
          font-size: 16px;
          font-weight: bold;
        }

        .pin,
        .details {
          font-size: 15px;
        }

        .created-time {
          color: rgba(0, 0, 0, 0.54);
          font-size: 14px;
        }

        &:hover {
          background: #ebebeb;
        }
      }
    }
  }
  #Notifications {
    .btn-default {
      width: 100%;
      text-align: left;
      margin-right: 5px;
      span.position-relative {
        position: relative;
        span.Notifications-notes {
          position: absolute;
          color: #fff;
          top: -17px;
          background: #f44336;
          width: auto;
          height: auto;
          border-radius: 100%;
          font-size: 12px;
          padding: 1px 5px 1px;
          right: -10px;
        }
      }
    }
    ul.dropdown-menu {
      width: 100%;
      float: left;
      margin: 0 !important;
      padding: 0;
      position: static;
      display: block;
      background: #fff;
      border: 0;
      box-shadow: none;
      max-height: 60vh;
      overflow-y: auto;
      min-width: 340px !important;
    }
    li {
      a {
        border-bottom: solid 1px #d4d4d4;
        font-size: 13px;
        &:hover {
          background: #f5f5f5;
          color: #626262;
          cursor: pointer;
        }
      }
    }
    li.is-not-read {
      background: #fcf6cc;
      a {
        font-weight: bold;
      }
    }
    li.is-read {
      background: #fff;
      a {
        opacity: 0.6;
      }
    }
  }
  #menu-profile {
    ul.dropdown-menu {
      padding-bottom: 0 !important;
      position: absolute;
      margin: 0 -82px !important;
      min-width: 100px !important;
      width: 196px;
      padding-top: 0;
      max-height: 80vh;
      overflow-y: auto;
      li.User-icons {
        width: 100%;
        float: left;
        span.user-icons-img {
          float: left;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          border: solid 1px #ccc;
          overflow: hidden;
          img {
            width: auto;
            height: 100%;
          }
        }
        h1 {
          font-size: 16px;
          float: right;
          color: #105c9d;
          width: 67%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          span {
            float: left;
            width: 100%;
            font-size: 12px;
            padding-top: 5px;
          }
        }
      }
      li {
        a {
          color: #105c9d;
        }
        &:hover {
          background: #eee;
        }
      }
      &::-webkit-scrollbar-track {
        border-radius: 7px;
        background-color: @white-color;
      }
      &::-webkit-scrollbar {
        width: 7px;
        background-color: @white-color;
      }
      &::-webkit-scrollbar-thumb {
        background-color: @h1-color;
        border-radius: 7px;
      }
    }
  }
}

#menu-profile .dropdown-menu {
  top: 51px;
  left: -4px;
}

#menu-profile {
  margin-top: 10px;
}

.searchBg {
  overflow: hidden;
}
.arrow-left {
  margin-top: 15px;
  color: #fff;
  cursor: pointer;
}

#denthub-header #Notifications li a.bold-font {
  font-weight: bold;
}
#denthub-header #Notifications li {
  text-align: left;
}

#denthub-header #Notifications li a img {
  width: 20px;
  margin: 0 5px;
}
#denthub-header #Notifications li a i {
  font-size: 18px;
  width: 30px;
  text-align: center;
}
#denthub-header .search-bar-list ul li h2 {
  width: 91%;
  float: left;
  font-size: 14px;
  font-weight: 600;
  padding: 0 0 0 2px;
}
#denthub-header .search-bar-list ul li h2 span {
  font-size: 12px;
  font-weight: 400;
}
#denthub-header .search-bar-list ul li .search-related-data {
  width: 100%;
  cursor: pointer;
  margin: auto;
}
#denthub-header .search-bar-list ul li .search-related-data a span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  float: left;
  width: 100%;
}
#denthub-header .search-bar-list ul li .search-related-data a span span {
  display: inline;
  width: auto;
  float: none;
}
.manage-all-notification {
  padding-bottom: 0 !important;
  position: absolute;
  margin: 0 5px !important;
  min-width: 250px !important;
  top: 46px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  display: none;
  z-index: 1;
}
.open > .manage-all-notification {
  display: block;
}
#denthub-header .dropdown-menu.clear-all-notifications {
  background: #105c9d !important;
  border-radius: 0;
  li {
    a {
      text-align: center;
      font-size: 20px !important;
      color: #fff !important;
      padding: 10px !important;
      font-weight: normal !important;
      &:hover {
        background: #105c9d !important;
      }
    }
  }
}
#navBar .nav > li > a.add-dentist-sub-menu {
  width: 175px;
}

.transform {
  transform: scaleX(-1);
}
#imaginary_container {
  .overlay > div:not(.loading-text) {
    left: 90%;
  }
}
.reload-page-text {
  position: fixed;
  right: 0;
  z-index: 9;
  background: #f5d823;
  padding: 10px 16px;
  color: black;
  font-size: 14px;
  p {
    margin: 0;
    .message {
      margin-right: 10px;
      font-weight: 500;
    }
  }
}
