a[download$=".com"].link-icon:before,
a[download$=".net"].link-icon:before,
a[download$=".org"].link-icon:before,
a[download$=".edu"].link-icon:before,
a[download$=".gov"].link-icon:before,
a[download$=".mil"].link-icon:before,
a[download$="/"].link-icon:before,
a[download$=".html"].link-icon:before,
a[download$=".htm"].link-icon:before,
a[download$=".xhtml"].link-icon:before,
a[download$=".jhtml"].link-icon:before,
a[download$=".php"].link-icon:before,
a[download$=".php3"].link-icon:before,
a[download$=".php4"].link-icon:before,
a[download$=".php5"].link-icon:before,
a[download$=".phtml"].link-icon:before,
a[download$=".asp"].link-icon:before,
a[download$=".aspx"].link-icon:before,
a[download$=".cfm"].link-icon:before,
a[download*="?"].link-icon:before,
a[download*="#"].link-icon:before,
a.link-icon:not([href*='.']):before,
a.link-icon.link[href]:before {
  content: "\f0c1"; /*link*/
}
a.link-icon:before,
a.link-icon.link-file[href]:before {
  content: "\f016"; /* generic file icon */
  display: inline-block;
  font: normal normal normal 18px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 20px;
  text-align: right;
  margin-right: 10px;
  float: left;
  margin-top: 6px;
  color: @h1-color; 
}
a[download$=".pdf"].link-icon:before,
a.link-icon.link-pdf[href]:before {
  content: "\f1c1"; /*PDF*/
}
a[download$=".txt"].link-icon:before,
a.link-icon.link-text[href]:before {
  content: "\f0f6"; /*Text*/
}

a[download$=".doc"].link-icon:before,
a[download$=".docx"].link-icon:before,
a.link-icon.link-word[href]:before {
  content: "\f1c2"; /*Word*/
}
a[download$=".xls"].link-icon:before,
a[download$=".xlsx"].link-icon:before,
a.link-icon.link-excel[href]:before {
  content: "\f1c3"; /*Excel*/
}
a[download$=".ppt"].link-icon:before,
a[download$=".pptx"].link-icon:before,
a.link-icon.link-powerpoint[href]:before {
  content: "\f1c4"; /*Powerpoint*/
}
a[download$=".gif"].link-icon:before,
a[download$=".jpg"].link-icon:before,
a[download$=".jpeg"].link-icon:before,
a[download$=".png"].link-icon:before,
a[download$=".bmp"].link-icon:before,
a[download$=".tif"].link-icon:before,
a.link-icon.link-image[href]:before {
  content: "\f1c5"; /*Image*/
}
a[download$=".zip"].link-icon:before,
a[download$=".zipx"].link-icon:before,
a[download$=".rar"].link-icon:before,
a[download$=".tar"].link-icon:before,
a[download$=".gz"].link-icon:before,
a[download$=".dmg"].link-icon:before,
a[download$=".iso"].link-icon:before,
a.link-icon.link-archive[href]:before {
  content: "\f1c6"; /*Archive*/
}
a[download$=".wav"].link-icon:before,
a[download$=".mp3"].link-icon:before,
a[download$=".fla"].link-icon:before,
a[download$=".flac"].link-icon:before,
a[download$=".ra"].link-icon:before,
a[download$=".rma"].link-icon:before,
a[download$=".aif"].link-icon:before,
a[download$=".aiff"].link-icon:before,
a[download$=".aa"].link-icon:before,
a[download$=".aac"].link-icon:before,
a[download$=".aax"].link-icon:before,
a[download$=".ac3"].link-icon:before,
a[download$=".au"].link-icon:before,
a[download$=".ogg"].link-icon:before,
a[download$=".avr"].link-icon:before,
a[download$=".3ga"].link-icon:before,
a[download$=".flac"].link-icon:before,
a[download$=".mid"].link-icon:before,
a[download$=".midi"].link-icon:before,
a[download$=".m4a"].link-icon:before,
a[download$=".mp4a"].link-icon:before,
a[download$=".amz"].link-icon:before,
a[download$=".mka"].link-icon:before,
a[download$=".asx"].link-icon:before,
a[download$=".pcm"].link-icon:before,
a[download$=".m3u"].link-icon:before,
a[download$=".wma"].link-icon:before,
a[download$=".xwma"].link-icon:before,
a.link-icon.link-audio[href]:before {
  content: "\f1c7"; /*Audio*/
}
a[download$=".avi"].link-icon:before,
a[download$=".mpg"].link-icon:before,
a[download$=".mp4"].link-icon:before,
a[download$=".mkv"].link-icon:before,
a[download$=".mov"].link-icon:before,
a[download$=".wmv"].link-icon:before,
a[download$=".vp6"].link-icon:before,
a[download$=".264"].link-icon:before,
a[download$=".vid"].link-icon:before,
a[download$=".rv"].link-icon:before,
a[download$=".webm"].link-icon:before,
a[download$=".swf"].link-icon:before,
a[download$=".h264"].link-icon:before,
a[download$=".flv"].link-icon:before,
a[download$=".mk3d"].link-icon:before,
a[download$=".gifv"].link-icon:before,
a[download$=".oggv"].link-icon:before,
a[download$=".3gp"].link-icon:before,
a[download$=".m4v"].link-icon:before,
a[download$=".movie"].link-icon:before,
a[download$=".divx"].link-icon:before,
a.link-icon.link-video[href]:before {
  content: "\f1c8"; /*Video*/
}
a[download$=".css"].link-icon:before,
a[download$=".js"].link-icon:before,
a[download$=".py"].link-icon:before,
a[download$=".git"].link-icon:before,
a[download$=".py"].link-icon:before,
a[download$=".cpp"].link-icon:before,
a[download$=".h"].link-icon:before,
a[download$=".ini"].link-icon:before,
a[download$=".config"].link-icon:before,
a.link-icon.link-code[href]:before {
  content: "\f1c9"; /*code*/
}
a[download$=".exe"].link-icon:before,
a[download$=".jar"].link-icon:before,
a[download$=".dll"].link-icon:before,
a[download$=".bat"].link-icon:before,
a[download$=".pl"].link-icon:before,
a[download$=".scr"].link-icon:before,
a[download$=".msi"].link-icon:before,
a[download$=".app"].link-icon:before,
a[download$=".deb"].link-icon:before,
a[download$=".apk"].link-icon:before,
a[download$=".jar"].link-icon:before,
a[download$=".vb"].link-icon:before,
a[download$=".prg"].link-icon:before,
a[download$=".sh"].link-icon:before,
a.link-icon.link-exe[href]:before {
  content: "\f085"; /*Executable*/
}



