#section-page {
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 35px;
  padding: 18px 61px 50px;
}
#section-heading {
  float: left;
  font-size: 20px;
  color: #1f2532;
  font-weight: 700;
  border-bottom: solid 1px #1f2532;
  width: 100%;
  line-height: 50px;
}
#full-width {
  width: 100%;
  float: left;
}
#rate-it {
  /* Background */
  /* Selected state */
  /* Cancelation of hover styling */
  /* Hover styling */
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../../../../images/rated-star.svg");
}
#rate-it .ngrateit-reset {
  background-image: url("../../../../images/cancel-icon.svg");
}
#rate-it .ngrateit-bg-star {
  background-image: url("../../../../images/bg-star.svg");
}
#rate-it .ngrateit-selected {
  background-image: url("../../../../images/rated-star.svg");
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating span:hover ~ span {
  background-image: url("../../../../images/bg-star.svg");
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../../../../images/rated-star.svg");
}
.order-material .add-associated-material {
  width: auto;
  border: solid 1px #105c9d;
  color: #fff;
  background: #105c9d;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  height: 36px;
  margin-left: 15px;
  min-width: 60px;
  margin-top: 15px;
}
#add-order-material-modal .modal-dialog,
#add-simple-order-action-modal .modal-dialog {
  width: 90%;
}
#add-order-material-modal .modal-dialog .modal-content,
#add-simple-order-action-modal .modal-dialog .modal-content {
  padding: 20px 40px 20px 70px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
#add-order-material-modal .modal-dialog .modal-content .modal-header,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-header {
  border: 0px;
}
#add-order-material-modal .modal-dialog .modal-content .modal-header .close,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  font-size: 30px;
  margin: -15px -25px;
}
#add-order-material-modal .modal-dialog .modal-content .modal-header .close:focus,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-header .close:focus {
  outline: none;
}
#add-order-material-modal .modal-dialog .modal-content .modal-header label.modal-title,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-header label.modal-title {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  color: #105c9d;
  width: 100%;
}
#add-order-material-modal .modal-dialog .modal-content .modal-body label,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-body label {
  display: flex;
  max-width: 100%;
  margin-bottom: 5px;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  min-height: 47px;
  text-align: center;
}
#add-order-material-modal .modal-dialog .modal-content .modal-body p.red-astrix-mark,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-body p.red-astrix-mark {
  width: auto;
  padding: 0 6px;
  float: none;
  display: inline-block;
}
#add-order-material-modal .modal-dialog .modal-content .modal-body input,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-body input {
  width: 100%;
  text-align: center;
}
#add-order-material-modal .modal-dialog .modal-content .modal-body .associated-material-search-filter,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-body .associated-material-search-filter {
  margin-top: 10px;
  margin-bottom: 20px;
}
#add-order-material-modal .modal-dialog .modal-content .modal-body .associated-material-search-filter input,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-body .associated-material-search-filter input {
  border: 0;
  background: #eee;
  padding: 5px 5px 5px 35px;
  width: 100%;
}
#add-order-material-modal .modal-dialog .modal-content .modal-body .associated-material-search-filter i,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-body .associated-material-search-filter i {
  position: absolute;
  left: 25px;
  top: 8px;
  color: #aaa;
}
#add-order-material-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown {
  margin-left: 0;
}
#add-order-material-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown .caret,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown .caret {
  position: absolute;
  top: 15px;
  right: 10px;
}
#add-order-material-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown ul,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown ul {
  min-width: 100% !important;
}
#add-order-material-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown ul li,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown ul li {
  color: #000;
  padding: 0;
  margin-bottom: 0;
}
#add-order-material-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown ul li p.free-text,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown ul li p.free-text {
  border: none;
  text-align: center;
}
#add-order-material-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown ul li.dropdown-header,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown ul li.dropdown-header {
  color: #aaa;
}
#add-order-material-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown ul li span,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown ul li span {
  width: 80px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 1px solid #aaa;
  float: left;
  cursor: pointer;
}
#add-order-material-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown ul li span:nth-child(2),
#add-simple-order-action-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown ul li span:nth-child(2) {
  width: calc(100% - 80px);
  padding-left: 15px;
  text-align: left;
  float: right;
}
#add-order-material-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown ul li.material-item,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown ul li.material-item {
  max-height: 150px;
  overflow-y: auto;
}
#add-order-material-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown ul li.material-item .material-data:hover,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-body .material-custom-dropdown ul li.material-item .material-data:hover {
  background: #eee;
  width: 100%;
  float: left;
}
#add-order-material-modal .modal-dialog .modal-content .modal-footer,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-footer {
  border: none !important;
}
#add-order-material-modal .modal-dialog .modal-content .modal-footer .btn.btn-default,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-footer .btn.btn-default {
  border-radius: 0px;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 10px 45px;
  color: #105c9d;
}
#add-order-material-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:hover,
#add-order-material-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-footer .btn.btn-default:focus,
#add-order-material-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close,
#add-simple-order-action-modal .modal-dialog .modal-content .modal-footer .btn.btn-default.active-tab-close {
  background: #105c9d;
  color: #fff;
}
