#find-lab{
  #full-width; margin-top: 120px; margin-bottom: 20px;
  .find-lab{
    #full-width;
    .find-lab-form{
      width: 50%; float: none; margin:30px auto;
      .lab-logo{
        #full-width; text-align: center;
      }
      h3{
        #full-width; text-align: center; font-weight: bold; font-size: 20px; color:@sky-start-dark; margin: 15px 0 10px;
      }
      p{
        width:80%; float: left; margin-left: 10%; line-height: 20px; text-align: center; font-size: 16px; color: @h1-color; margin-bottom: 30px;
      }
      .find-form{
        #full-width; position: relative; text-align: center; margin-bottom: 40px;
        input[type="text"]{
          width: 67%; padding:0 10px 0 55px; border-radius: 3px; border: solid 1px @gray-light-white; height:55px; line-height: 55px; background: @white-color; box-shadow: 1px 2px 3px @gray-bg-color;
          &:focus{
            outline: none;
          }
        }
        i.fa.fa-search{
          font-size: 15px; left: 18px; top: 20px; position: absolute;
        }
        input[type="button"]{
          min-width: 187px; max-width: 187px; text-align: center; font-size: 18px; font-weight: bold; border-radius: 1px; padding: 0px; text-transform: uppercase; display: inline-block; height:55px; line-height: 55px; border:0px; background: @sky-start-dark; color: @white-color; box-shadow: 1px 2px 3px @gray-bg-color;
        }
        button[type="button"]{
          min-width: 55px; max-width:55px; height: 55px; border-radius: 100%; text-align: center; font-size: 15px; font-weight: bold; padding: 0px; text-transform: uppercase; display: inline-block; height:55px; line-height: 55px; border:0px; background: @sky-start-dark; color: @white-color; box-shadow: 1px 2px 3px @gray-bg-color;
        }
      }
      .find-form.arrow-form{
        margin-top: 20px;
        i.fa.fa-search{
          left: 85px;
        }
      }
    }
  }
}