#section-page {
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 35px;
  padding: 18px 61px 50px;
}
#section-heading {
  float: left;
  font-size: 20px;
  color: #1f2532;
  font-weight: 700;
  border-bottom: solid 1px #1f2532;
  width: 100%;
  line-height: 50px;
}
#full-width {
  width: 100%;
  float: left;
}
#rate-it {
  /* Background */
  /* Selected state */
  /* Cancelation of hover styling */
  /* Hover styling */
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../../../images/rated-star.svg");
}
#rate-it .ngrateit-reset {
  background-image: url("../../../images/cancel-icon.svg");
}
#rate-it .ngrateit-bg-star {
  background-image: url("../../../images/bg-star.svg");
}
#rate-it .ngrateit-selected {
  background-image: url("../../../images/rated-star.svg");
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating span:hover ~ span {
  background-image: url("../../../images/bg-star.svg");
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../../../images/rated-star.svg");
}
input:-webkit-autofill {
  background: #fff !important;
  background-color: #fff !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
#profile-dentist {
  width: 100%;
  float: left;
  margin-top: 200px;
  margin-bottom: 20px;
}
#profile-dentist .profile-dentist {
  width: 100%;
  float: left;
}
#profile-dentist .profile-dentist .profile-left-section {
  width: 100%;
  float: left;
  background: #fff;
  padding-top: 45px;
}
#profile-dentist .profile-dentist .profile-left-section .head-profile {
  width: 100%;
  float: left;
  padding: 0 35px;
}
#profile-dentist .profile-dentist .profile-left-section .head-profile h2 {
  width: 100%;
  float: left;
  text-align: center;
  background: url(images/border-head-h2.png) 0 center repeat-x;
}
#profile-dentist .profile-dentist .profile-left-section .head-profile h2 span {
  width: auto;
  padding: 0 28px;
  background: #fff;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 300;
}
#profile-dentist .profile-dentist .profile-left-section .profile-form-left {
  width: 100%;
  float: left;
  padding-top: 25px;
}
#profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row {
  width: 100%;
  float: left;
  margin-bottom: 37px;
  padding: 0 35px;
}
#profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row .profile-form-left-col {
  width: 43%;
  float: left;
}
#profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row .profile-form-left-col label {
  color: #105c9d;
  margin-top: 10px;
  margin-bottom: 0;
  float: left;
}
#profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row .profile-form-left-col input {
  width: 100%;
  float: left;
  padding: 5px 10px;
  font-size: 15px;
  text-align: center;
  border: solid 1px #979797;
  min-height: 37px;
}
#profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row .profile-form-left-col:nth-child(2n) {
  float: right;
}
#profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row.change-pass-div .profile-form-left-col {
  /*input{
              width: 50%; float: right; border: 0px;
            }*/
}
#profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row.change-pass-div .profile-form-left-col label {
  line-height: 34px;
}
#profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row.change-pass-div .profile-form-left-col {
  margin-top: -10px;
}
#profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row.change-pass-div .profile-form-left-col input[type="button"] {
  color: #fff;
  background: #105c9d;
  font-size: 20px;
  width: 100%;
  text-transform: uppercase;
  white-space: inherit;
  line-height: 22px;
  font-weight: 500;
}
#profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row.change-pass-div .profile-form-left-col input[type="button"]:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
#profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row.change-pass-div .profile-form-left-col:nth-child(2) input[type="button"] {
  background: url(../../../images/bankid_vector_rgb.svg) 94% 3px no-repeat, #105c9d;
  background-size: 30px;
}
#profile-dentist .profile-dentist .profile-left-section .profile-form-left .profile-form-left-row.change-pass-div .profile-form-left-col.extra-top-margin-add {
  margin-top: -73px;
}
#profile-dentist .profile-dentist .profile-left-section .profile-form-left .change-profile-picture {
  width: 100%;
  float: left;
  position: relative;
  padding-bottom: 80px;
}
#profile-dentist .profile-dentist .profile-left-section .profile-form-left .change-profile-picture img {
  width: 100%;
  width: 28%;
  float: left;
  margin-left: 36%;
}
#profile-dentist .profile-dentist .profile-left-section .profile-form-left .change-profile-picture input[type="button"],
#profile-dentist .profile-dentist .profile-left-section .profile-form-left .change-profile-picture upload-button#profileImage {
  color: #fff;
  background: #105c9d;
  font-size: 20px;
  text-transform: uppercase;
  white-space: nowrap;
  line-height: 35px;
  width: auto;
  padding: 8px 15px;
  border: 0;
  font-weight: 500;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, 0);
}
#profile-dentist .profile-dentist .profile-left-section .profile-form-left .change-profile-picture upload-button#profileImage {
  padding: 0;
}
#profile-dentist .profile-dentist .profile-left-section .profile-form-left .change-profile-picture upload-button#profileImage button {
  color: #fff;
  background: #105c9d;
  font-size: 20px;
  text-transform: uppercase;
  white-space: nowrap;
  line-height: 35px;
  width: auto;
  padding: 8px 15px;
  border: 0;
  font-weight: 500;
}
#profile-dentist .profile-dentist .profile-left-section .notifications {
  float: left;
  padding-top: 40px;
  background: #eee;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .head-profile {
  background: #fff;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .head-profile h2 {
  margin-top: 30px;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left {
  background: #fff;
  margin-bottom: 20px;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left:last-child {
  min-height: 180px;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left {
  width: 100%;
  float: left;
  padding: 0 35px;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col {
  width: 100%;
  float: left;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .select-email {
  font-size: 13px;
  color: #a7a2a2;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .notifications-title {
  width: 100%;
  float: left;
  font-weight: bold;
  color: #105c9d;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .select-destination {
  font-size: 13px;
  color: #a7a2a2;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .user-email {
  width: 100%;
  float: left;
  text-align: left;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .user-email .checkbox-inline {
  padding-left: 0;
  width: 100%;
  font-size: 13px;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .user-email .checkbox-inline input[type="radio"]:not(:checked) {
  float: left;
  width: 15px;
  margin: 2px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid #105c9d;
  outline: none;
  -webkit-appearance: inherit;
  padding: 8px !important;
  border-radius: 50%;
  position: static;
  cursor: pointer;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .user-email .checkbox-inline input[type="radio"]:checked {
  border: 2px solid #105c9d;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .user-email .checkbox-inline input[type="radio"]:checked {
  float: left ;
  width: 15px ;
  margin: 0px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid #105c9d;
  outline: none;
  cursor: pointer;
  -webkit-appearance: inherit;
  position: static;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 8px !important;
  border-radius: 50%;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .user-email .checkbox-inline input[type="radio"]:checked:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  font-size: 12px;
  width: 12px;
  height: 12px;
  background: #105c9d;
  border-radius: 50%;
  color: #105c9d;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .user-email .checkbox-inline input[type="radio"]:checked:after {
  background: #105c9d;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .user-enter-email .email-input {
  width: 85%;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .user-enter-email .checkbox-inline {
  padding-left: 0px;
  width: 100%;
  margin-left: 0px ;
  margin-top: 5px ;
  font-size: 13px;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .user-enter-email .checkbox-inline input[type="radio"]:not(:checked) {
  float: left;
  width: 15px ;
  margin: 0px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid #105c9d;
  outline: none;
  -webkit-appearance: inherit;
  padding: 8px ;
  border-radius: 50%;
  position: static;
  cursor: pointer;
  min-height: inherit;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .user-enter-email .checkbox-inline input[type="radio"]:checked {
  border: 2px solid #105c9d;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .user-enter-email .checkbox-inline input[type="radio"]:checked {
  float: left;
  width: 15px ;
  margin: 4px 10px 0 0;
  height: 15px;
  background: none;
  border: 2px solid #105c9d;
  outline: none;
  cursor: pointer;
  -webkit-appearance: inherit;
  position: static;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 8px ;
  border-radius: 50%;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .user-enter-email .checkbox-inline input[type="radio"]:checked:after {
  content: '';
  position: absolute;
  top: 8px;
  left: 4px;
  font-size: 12px;
  width: 12px;
  height: 12px;
  background: #105c9d;
  border-radius: 50%;
  color: #105c9d;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .user-enter-email .checkbox-inline input[type="radio"]:checked:after {
  background: #105c9d;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .user-enter-email .checkbox-inline input {
  float: left;
  width: auto ;
  text-align: left;
  padding: 0 ;
  padding-left: 5px ;
  border: solid 2px #ccc;
  color: #000;
  min-height: 25px;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .switch-button {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .switch-button .switch.checked {
  background: #105c9d;
  border-color: #105c9d;
  float: left;
  margin-right: 20px;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .switch-button .switch {
  float: left;
  margin-right: 20px;
  background: #d9dadc;
  width: 42px;
  height: 22px;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .switch-button .switch small {
  width: 22px;
  height: 22px;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .switch-button p {
  float: left;
  margin-bottom: 0;
  font-size: 13px;
  color: #a7a2a2;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .switch-button input {
  float: left;
  margin-right: 10px;
  padding: 0 5px;
  line-height: 25px;
  font-size: 14px;
  text-align: center;
}
#profile-dentist .profile-dentist .profile-left-section .notifications .profile-form-left .profile-form-left .profile-form-left-col .switch-button span {
  float: left;
}
#profile-dentist .profile-dentist .profile-right-section {
  width: 97%;
  float: right;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one {
  width: 100%;
  float: left;
  background: #fff;
  padding: 45px 35px 82px;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .head-profile {
  width: 100%;
  float: left;
  padding: 0 35px;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .head-profile h2 {
  width: 100%;
  float: left;
  text-align: center;
  background: url(images/border-head-h2.png) 0 center repeat-x;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .head-profile h2 span {
  width: auto;
  padding: 0 28px;
  background: #fff;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 300;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right {
  width: 100%;
  float: left;
  text-align: center;
  display: inline-block;
  margin-top: 25px;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row {
  width: auto;
  float: none;
  text-align: center;
  display: inline-block;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col {
  width: auto;
  float: left;
  margin: 0 20px;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col label {
  width: 100%;
  color: #105c9d;
  margin-bottom: 10px;
  float: left;
  text-align: left;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col label span.version-number {
  display: inline-block;
  color: #979797;
  margin-left: 15px;
  font-size: 14px;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col select {
  width: 100%;
  float: left;
  padding: 7px 10px;
  font-size: 15px;
  text-align: center;
  border: 0px;
  background: #f4f4f4;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col select:focus {
  outline: none;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col .ui-select {
  min-width: 300px;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col .ui-select-container.select2.select2-container {
  cursor: pointer;
  min-width: inherit !important;
  width: 100%;
  float: left;
  position: relative;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col .ui-select-container.select2.select2-container a.select2-choice.ui-select-match {
  border: solid 1px #979797;
  width: 100%;
  float: left;
  padding: 5px 0;
  color: #979797;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col .ui-select-container.select2.select2-container a.select2-choice.ui-select-match i.fa.fa-angle-down {
  float: right;
  padding-top: 5px;
  padding-right: 10px;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col .ui-select-container.select2.select2-container .ui-select-dropdown.select2-drop {
  float: left;
  width: 100%;
  border: 1px solid #979797;
  border-top: 0;
  position: absolute;
  left: 0;
  top: 39px;
  background: #fff;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col .ui-select-container.select2.select2-container .ui-select-dropdown.select2-drop .search-container.select2-search {
  display: none;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col {
  width: 92.5% !important;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col .ui-select-container.select2.select2-container {
  width: auto;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col .ui-select-container.select2.select2-container a.select2-choice.ui-select-match {
  width: auto;
  min-width: 150px;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin {
  margin-top: 20px !important;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin.auto-archive input {
  width: 15%;
  margin-right: 10px;
  float: left;
  padding: 0;
  text-align: center;
  font-weight: 700;
  min-height: 31px;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin.auto-archive span {
  font-size: 13px;
  float: left;
  margin-top: 4px;
  color: #a5a3a3;
  margin-right: 15px;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin.auto-archive p {
  width: 100%;
  margin-left: 7%;
  text-align: left;
  font-size: 13px;
  line-height: 16px;
  margin-right: 28%;
  color: #a5a3a3;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin .switch-button {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin .switch-button .switch.checked {
  background: #105c9d;
  border-color: #105c9d;
  float: left;
  margin-right: 20px;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin .switch-button .switch {
  float: left;
  margin-right: 20px;
  background: #d9dadc;
  width: 42px;
  height: 22px;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin .switch-button .switch small {
  width: 22px;
  height: 22px;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.default-clinic-right-col.top-margin .switch-button p {
  float: left;
  margin-bottom: 0;
  font-size: 13px;
  color: #a7a2a2;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-two {
  width: 100%;
  float: left;
  background: #fff;
  padding: 45px 35px 82px;
  margin-top: 35px;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-two .head-profile {
  width: 100%;
  float: left;
  padding: 0 35px;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-two .head-profile h2 {
  width: 100%;
  float: left;
  text-align: center;
  background: url(images/border-head-h2.png) 0 center repeat-x;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-two .head-profile h2 span {
  width: auto;
  padding: 0 28px;
  background: #fff;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 300;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-two .profile-form-right {
  width: 100%;
  float: left;
  text-align: center;
  display: inline-block;
  margin-top: 25px;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-two .profile-form-right .profile-form-right-row {
  width: 100%;
  float: none;
  text-align: center;
  display: inline-block;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-two .profile-form-right .profile-form-right-row .profile-form-right-col {
  width: auto;
  float: left;
  margin: 10px 3%;
  max-width: 27%;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-two .profile-form-right .profile-form-right-row .profile-form-right-col label {
  width: 100%;
  color: #105c9d;
  margin-bottom: 0;
  float: left;
  text-align: left;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-two .profile-form-right .profile-form-right-row .profile-form-right-col select {
  width: 100%;
  float: left;
  padding: 7px 10px;
  font-size: 15px;
  text-align: center;
  border: 0px;
  background: #f4f4f4;
}
#profile-dentist .profile-dentist .profile-right-section .profile-right-block-two .profile-form-right .profile-form-right-row .profile-form-right-col select:focus {
  outline: none;
}
#profile-dentist .profile-dentist .profile-right-section .save-change-button {
  width: 100%;
  float: left;
}
#profile-dentist .profile-dentist .profile-right-section .save-change-button button {
  width: auto;
  float: right;
  text-transform: uppercase;
  padding: 10px 15px;
  margin-top: 20px;
  color: #fff;
  background: #105c9d;
  border: 0px;
}
#profile-dentist .profile-dentist .profile-right-section .save-change-button button.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
#profile-dentist .profile-dentist .profile-right-section .save-change-button .input-button {
  width: auto;
  float: right;
  text-transform: uppercase;
  padding: 10px 15px;
  margin-top: 20px;
  color: #fff;
  background: #105c9d;
  border: 0px;
}
#profile-dentist .profile-dentist .profile-right-section .save-change-button .input-button.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
#profile-dentist .profile-dentist.tabbing-section-add ul.tabbing-ul {
  padding: 0;
  margin-bottom: 40px;
}
#profile-dentist .profile-dentist.tabbing-section-add ul.tabbing-ul li {
  width: 100%;
  float: left;
}
#profile-dentist .profile-dentist.tabbing-section-add ul.tabbing-ul li a {
  width: 100%;
  float: left;
  padding: 0 10px !important;
  background: #fff;
  border-bottom: solid 1px #dcdada;
  text-align: left;
  color: #1f2532;
  line-height: 44px;
  height: 44px;
}
#profile-dentist .profile-dentist.tabbing-section-add ul.tabbing-ul li:last-child {
  border-bottom: 0;
}
#profile-dentist .profile-dentist.tabbing-section-add ul.tabbing-ul li.active a {
  background: #1f2532;
  color: #fff;
}
#profile-dentist .profile-dentist.tabbing-section-add .profile-right-section {
  width: 100%;
}
#profile-dentist .profile-dentist.tabbing-section-add .profile-right-section .profile-right-block-two {
  margin-top: 0;
}
#profile-dentist .profile-dentist.tabbing-section-add .profile-right-section .profile-right-block-two .head-profile {
  padding: 0;
}
#profile-dentist .profile-dentist.tabbing-section-add .profile-left-section .notifications {
  padding-top: 0;
}
#profile-dentist .profile-dentist.tabbing-section-add .profile-left-section .notifications .head-profile h2 {
  margin-top: 0;
}
body.modal-open > .modal.in > .modal-dialog {
  width: 100%;
}
body.modal-open > .modal.in > .modal-dialog > .modal-content {
  background-color: inherit;
  box-shadow: none;
  border: 0px;
}
/*.modal-dialog{
  width: 100%;
  .modal-content{
    background-color: inherit; box-shadow: none; border:0px;
  }
}*/
body.modal-open > modal-container.modal.fade .modal-dialog {
  min-height: 100vh;
  transform: translate(0, 0);
  margin: auto;
  display: flex;
  align-items: center;
  width: 800px;
}
body.modal-open > modal-container.modal.fade .modal-dialog .modal-content {
  width: 100%;
  padding: 20px 30px;
  background-color: #fff;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
#change-password-modal .modal-header {
  border: 0px;
}
#change-password-modal .modal-header .close {
  opacity: 1;
  font-size: 30px;
  margin: -15px -25px;
}
#change-password-modal .modal-header .close:focus {
  outline: none;
}
#change-password-modal .modal-header h4.modal-title {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}
#change-password-modal .modal-body {
  width: 100%;
  float: left;
}
#change-password-modal .modal-body .add-clinic-form {
  width: 28%;
  float: none;
  margin: auto;
  text-align: center;
  margin-bottom: 55px;
  margin-top: 20px;
}
#change-password-modal .modal-body .add-clinic-form .add-clinic-form-row {
  width: 100%;
  float: left;
  margin-bottom: 45px;
  position: relative;
}
#change-password-modal .modal-body .add-clinic-form .add-clinic-form-row h4 {
  color: #105c9d;
  margin-bottom: 12px;
  font-size: 15px;
}
#change-password-modal .modal-body .add-clinic-form .add-clinic-form-row h4.sky-low-dark {
  color: #bce9e9;
}
#change-password-modal .modal-body .add-clinic-form .add-clinic-form-row input {
  width: 100%;
  float: left;
  padding: 8px 10px;
  font-size: 12px;
  text-align: center;
  border: solid 1px #979797;
}
#change-password-modal .modal-body .add-clinic-form .add-clinic-form-row span {
  float: left;
  width: 20px;
  margin: 0px 10px 0 10px;
  height: 20px;
  background: #6ed803;
  outline: 0;
  -webkit-appearance: inherit;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 10px;
  border-radius: 100%;
  content: '\f00c';
  position: absolute;
  right: -55px;
  top: 37px;
}
#change-password-modal .modal-body .add-clinic-form .add-clinic-form-row span:after {
  content: '\f00c';
  position: absolute;
  top: 5px;
  left: 4px;
  font-size: 12px;
  color: #fff;
}
#change-password-modal .modal-footer {
  border: 0;
  margin: 30px 0 20px;
}
#change-password-modal .modal-footer .btn.btn-default {
  border-radius: 0px;
  border: solid 1px #105c9d;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 10px 25px;
  color: #105c9d;
}
#change-password-modal .modal-footer .btn.btn-default:hover,
#change-password-modal .modal-footer .btn.btn-default:focus,
#change-password-modal .modal-footer .btn.btn-default.active-tab-close {
  background: #105c9d;
  color: #fff;
}
