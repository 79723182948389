.clickonBoth .teeth-label {
  font-size: 14px;
  padding: 5px;
  font-weight: 400;
}
.clickonBoth .bridge-start {
  border: 3px solid #105c9d;
  border-right: none;
  border-radius: 8px 0 0 8px;
  margin-right: 0;
  margin-left: 3px;
  width: 35px;
  padding: 0;
}
.clickonBoth .bridge-middle,
.clickonBoth .bridge-start-temp {
  border: 3px solid #105c9d;
  border-left: none;
  border-right: none;
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  width: 38px;
}
.clickonBoth .bridge-end {
  border: 3px solid #105c9d;
  border-left: none;
  border-radius: 0 8px 8px 0;
  margin-left: 0;
  margin-right: 3px;
  padding: 0;
  width: 35px;
}
