.constructions-grid .construction-cell-wrapper .standard-list ul {
  display: flex;
  flex-wrap: wrap;
}
.constructions-grid .construction-cell-wrapper .standard-list ul li {
  flex: 0 0 auto;
  background-color: #dcdada;
  margin-right: 5px;
  margin-bottom: 5px;
  min-width: 80px;
  text-align: center;
}
.constructions-grid .construction-cell-wrapper .standard-list ul li a {
  padding: 5px 5px;
  display: block;
  cursor: pointer;
  color: #5a5959;
  font-size: 13px;
}
.constructions-grid .construction-cell-wrapper .standard-list input {
  font-size: 13px;
  margin-top: 5px;
}
.constructions-grid .construction-cell-wrapper .color-selector .select-lists {
  margin-bottom: 10px;
}
.constructions-grid .construction-cell-wrapper .color-selector .select-lists select {
  cursor: pointer;
  font-size: 13px;
  background-color: #dcdada;
  border: 0;
  padding: 8px 5px;
  min-width: 150px;
}
.constructions-grid .construction-cell-wrapper .color-selector .select-lists select.color {
  min-width: 100px;
}
.constructions-grid .construction-cell-wrapper .color-selector .select-lists .shade {
  margin-right: 5px;
}
.constructions-grid .construction-cell-wrapper .icon-list ul li {
  background-color: #dcdada;
  margin-bottom: 5px;
  cursor: pointer;
}
.constructions-grid .construction-cell-wrapper .icon-list ul li a {
  padding: 4px;
  display: block;
  font-size: 13px;
  color: #5a5959;
}
.constructions-grid .construction-cell-wrapper .icon-list ul li a img {
  height: 25px;
  background: #fff;
  margin: 0 5px;
  border-radius: 20px;
  padding: 5px;
}
.constructions-grid .construction-cell-wrapper .number-of-unit .action-units-number {
  margin-top: 0;
}
.constructions-grid .construction-cell-wrapper .multiselect-settings {
  cursor: pointer;
}
.constructions-grid .construction-cell-wrapper .multiselect-settings .dropdown {
  margin: 0;
  font-size: 13px;
  background-color: #dcdada;
  max-width: 150px;
  padding: 5px 5px;
  font-weight: 400;
  color: #5a5959;
}
.constructions-grid .construction-cell-wrapper .multiselect-settings .dropdown input {
  cursor: pointer;
  border: 0;
  background: none;
}
.constructions-grid .construction-cell-wrapper .multiselect-settings .dropdown i.fa {
  font-size: 10px;
}
.constructions-grid .construction-cell-wrapper .multiselect-settings .dropdown-menu {
  padding: 0;
  height: 35vh;
  overflow: auto;
}
.constructions-grid .construction-cell-wrapper .multiselect-settings .dropdown-menu li {
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
}
.constructions-grid .construction-cell-wrapper .multiselect-settings .dropdown-menu li label {
  margin: auto;
}
warranty .wrapper-label {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
}
warranty .wrapper-label input,
warranty .wrapper-label label {
  margin: auto;
  float: none;
}
.add-index {
  z-index: 99999 !important;
}
.add-index input {
  z-index: 0 !important;
}
