.drophover {
  outline: 2px dashed black;
}

.broken-document {
  border: 2px dashed red;
}

.delivery-section .tooltip.in {
  width: 200px;
}