#dentist-order-detail {
  #full-width;
  margin-top: 150px;
  margin-bottom: 20px;
  .dentist-order-detail {
    #full-width;
    .client-lab-detail {
      #full-width;
      background: @white-color;
      padding: 25px;
      .client-lab-detail-row {
        #full-width;
        margin-bottom: 20px;
        .left-detail-part {
          width: 50%;
          float: left;
          h3 {
            #full-width;
            font-size: 16px;
            color: @sky-start-dark;
            margin: 0px;
          }
          p {
            #full-width;
            font-size: 16px;
            color: @post-block-color;
            line-height: 20px;
          }
          p.destination-address {
            #full-width;
            font-size: 16px;
            color: @post-block-color;
            line-height: 20px;
            margin-bottom: 0px;
          }
          ul {
            h3 {
              margin-top: 3px;
              color: @post-block-color;
            }
            li {
              #full-width;
              font-size: 16px;
              color: @post-block-color;
              line-height: 20px;
              font-weight: 400;
              margin-bottom: 15px;
            }
            &.partial-temporary-type {
              li {
                margin-bottom: 0px;
                &:first-child {
                  div {
                    span.construction-type {
                      display: block;
                      &:after {
                        content: '';
                      }
                    }
                  }
                }
                div {
                  span.construction-type {
                    display: none;
                  }
                  .move-to-left {
                    margin-left: 80px;
                  }
                }
              }
            }
          }
        }
        .left-detail-part.full-construction {
          ul {
            li {
              span {
                position: relative;
                margin-right: 5px;
                display: inline-flex;
                &:after {
                  content: ',';
                  color: #000;
                  font-weight: bold;
                  width: 3px;
                  height: 0px;
                  position: absolute;
                  right: 1px;
                  bottom: 19px;
                }
                strong {
                  margin-right: 6px;
                  position: relative;
                  &:before {
                    position: absolute;
                    right: -6px;
                    top: 10px;
                    width: 6px;
                    height: 1px;
                    background: @white-color;
                    content: '';
                  }
                }
                span {
                  &:after {
                    content: '';
                  }
                }
                &:last-child {
                  &:after {
                    content: '';
                  }
                }
                &.separate-arrow-right {
                  display: block;
                  strong {
                    margin-right: 0;
                  }
                  span {
                    span {
                      &:after {
                        content: ',';
                        right: -4px;
                        color: @delay-red-dark;
                      }
                    }
                    &:last-child {
                      span {
                        &:after {
                          content: '';
                        }
                      }
                    }
                  }
                }
              }
              del {
                span {
                  strong {
                    &:before {
                      background: @delay-red-dark;
                    }
                  }
                  &:after {
                    color: @delay-red-dark !important;
                  }
                  &:before {
                    color: @delay-red-dark !important;
                    position: absolute;
                    right: -6px;
                    top: 10px;
                    width: 6px;
                    height: 1px;
                    background: @delay-red-dark;
                    content: '';
                  }
                }
              }
            }
            li.modified {
              span {
                &:after {
                  color: @delay-red-dark !important;
                }
              }
            }
          }
        }
        .right-detail-part {
          width: 50%;
          float: left;
          text-align: right;
          h3 {
            #full-width;
            font-size: 16px;
            color: @sky-start-dark;
          }
          p {
            #full-width;
            font-size: 16px;
            color: @post-block-color;
            line-height: 20px;
          }
          p.destination-address {
            #full-width;
            font-size: 16px;
            color: @post-block-color;
            line-height: 20px;
            margin-bottom: 0px;
          }
          .modify-date {
            width: 100%;
            float: left;
            button {
              background: @sky-start-dark;
              box-shadow: none;
              border: 0;
              color: #fff;
              margin: -15px 0 0 80px;
              float: left;
              border-radius: 0;
              font-weight: 600;
            }
            span {
              width: 33%;
              float: right;
              margin-top: 5px;
              datepicker {
                input {
                  background: #eeecec;
                  box-shadow: none;
                  border: 0;
                  margin: 0;
                  float: right;
                  border-radius: 0;
                  width: 100%;
                  text-align: center;
                  font-weight: 600;
                  min-height: 25px;
                }
              }
            }
          }
        }
        .left-detail-part.full-construction {
          width: 100%;
        }
        .left-detail-part.order-created {
          width: 70% !important;
        }
        .right-detail-part.order-created {
          width: 30% !important;
          span {
            width: 40%;
            float: right;
            text-align: center;
            cursor: pointer;
            p {
              font-weight: bold;
              font-size: 14px;
              color: #bbb;
            }
          }
        }
      }
      .modified {
        color: @delay-red-dark !important;
        &:after {
          color: @delay-red-dark !important;
        }
      }
      .orders-pics {
        #full-width;
        background: @gray-light-white;
        padding: 13px;
        margin-top: 25px;
        ul {
          width: 80%;
          float: left;
          li {
            width: 24%;
            float: left;
            margin-right: 1%;
            margin-bottom: 10px;
            text-align: center;
            position: relative;
            img {
              width: 100%;
              height: 60px;
            }

            button {
              position: absolute;
              right: 0;
              width: 25px;
              height: 25px;
              text-align: center;
              line-height: 25px;
              border-radius: 100%;
              background: @white-color;
              opacity: 1;
              top: -7px;
              color: @sky-start-dark;
            }
            span {
              width: 70%;
              float: left;
              padding: 3px 10px 3px 0px;
              color: @h1-color;
              text-align: center;
              font-size: 15px;
              font-weight: 400;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
        .order-pic-upload {
          width: 20%;
          float: right;
          .upload-image {
            position: relative;
            width: 100%;
            margin-top: 10px;
            input {
              width: 100%;
              opacity: 0;
              position: relative;
              z-index: 99;
              height: 33px;
              cursor: pointer;
            }
            span {
              width: 100%;
              text-align: center;
              font-size: 15px;
              background: @sky-start-dark;
              height: 42px;
              line-height: 33px;
              color: @white-color;
              padding: 5px;
              float: left;
              cursor: pointer;
            }
          }
          p {
            #full-width;
            color: @gray-dark;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            cursor: pointer;
            margin-bottom: 0px;
            margin-top: 2px;
          }
        }
      }
      .orders-pics.pdf-call-order {
        margin-top: 15px;
        .order-pic-upload {
          .upload-image {
            margin-top: 0px;
          }
          p {
            margin-bottom: 0px;
          }
        }
      }

      .client-lab-detail-row.order-created-by {
        margin-top: 25px;
        margin-bottom: 0px;
        .left-detail-part {
          h3 {
            width: auto;
            margin-top: 10px;
            margin-right: 5px;
            margin-bottom: 0px;
          }
          p {
            width: auto;
            margin-top: 10px;
            margin-bottom: 0px;
          }
        }
        .right-detail-part {
          i {
            font-size: 50px;
            color: @gray-bg-color;
            cursor: pointer;
          }
        }
      }
    }
    .barcode-wrapper {
      text-align: center;
      background: #fff;
      margin: 5px;
      border: 1px #ccc solid;
    }
    .chat-box {
      width: 100%;
      float: left;
      padding: 5px; /* position: fixed;*/
      &.order-two-chat {
        .chat-box-date {
          border: 1px #ccc solid;
          .chat-with-box {
            .chat-login-person {
              .chat-front-person-img {
              }
              .chat-front-person-chat {
                width: 81%;
              }
            }
          }
        }
      }
      .chat-box-date {
        #full-width;
        min-height: 300px;
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 20px 0px;
        background: @white-color;
        .chat-date {
          #full-width;
          text-align: center;
          display: inline-block;
          margin-top: 30px;
          span {
            width: auto;
            display: inline-block;
            float: none;
            padding: 3px 15px 2px;
            border-radius: 12px;
            background: #1f2532;
            color: #fff;
            font-size: 12px;
            margin: 0 10px;
          }
        }
        .chat-with-box {
          #full-width;
          .chat-login-person {
            #full-width;
            padding: 30px 25px 0px;
            .chat-front-person-img {
              width: 45px;
              height: 45px;
              overflow: hidden;
              border-radius: 100%;
              float: left;
              margin-right: 10px;
              box-shadow: 2px 3px 8px 2px @light-grey;
              img {
                width: auto;
                height: 100%;
              }
            }
            .chat-front-person-chat {
              width: 86%;
              float: left;
              .front-person-chatting {
                width: auto;
                float: left;
                font-size: 14px;
                word-wrap: break-word;
                line-height: 18px;
                height: max-content;
                background: @sent-message;
                padding: 8px 15px;
                border-radius: 10px;
                box-shadow: 2px 3px 8px 2px @light-grey;
                color: @h1-color;
                max-width: 100%;
              }

              .front-person-chatting-date {
                #full-width;
                color: @delay-red;
                font-size: 13px;
                margin-top: 5px;
              }
              .front-person-chatting.new-massage-bg {
                background: @new-message;
                position: relative;
                span {
                  width: auto;
                  font-size: 13px;
                  font-weight: 500;
                  position: absolute;
                  right: -55px;
                  top: 30%;
                  color: @new-message;
                  display: block;
                }
              }
            }
          }
          .chat-front-person {
            #full-width;
            padding: 30px 25px 0px;
            .chat-front-person-img {
              width: 45px;
              height: 45px;
              overflow: hidden;
              border-radius: 100%;
              float: right;
              margin-left: 10px;
              box-shadow: 2px 3px 8px 2px @light-grey;
              img {
                width: auto;
                height: 100%;
              }
            }
            .chat-front-person-chat {
              width: 86%;
              float: left;
              .front-person-chatting {
                width: auto;
                float: right;
                font-size: 14px;
                word-wrap: break-word;
                line-height: 18px;
                height: max-content;
                background: @received-message;
                padding: 8px 15px;
                border-radius: 10px;
                box-shadow: 2px 3px 8px 2px @light-grey;
                color: @h1-color;
                max-width: 100%;
              }
              .front-person-chatting.new-massage-bg {
                background: @new-message;
                position: relative;
                span {
                  width: auto;
                  font-size: 13px;
                  font-weight: 500;
                  position: absolute;
                  left: -55px;
                  top: 30%;
                  color: @new-message;
                  display: block;
                }
              }
              .front-person-chatting-date {
                #full-width;
                color: @delay-red;
                font-size: 13px;
                margin-top: 5px;
                text-align: right;
              }
            }
          }
        }
      }
      #style-1::-webkit-scrollbar-track {
        border-radius: 7px;
        background-color: @white-color;
      }
      #style-1::-webkit-scrollbar {
        width: 7px;
        background-color: @white-color;
      }
      #style-1::-webkit-scrollbar-thumb {
        background-color: @h1-color;
        border-radius: 7px;
      }
    }
    .chat-option-box {
      #full-width;
      background: @white-color;
      padding: 0;
      border-radius: 4px;
      margin-top: 21px;
      box-shadow: 2px 3px 8px 2px @light-grey;
      input {
        border: 0;
        #full-width;
        padding: 35px 25px;
        line-height: 25px;
        &:focus {
          outline: inherit;
        }
      }
    }
    .chat-send-btn {
      #full-width;
      margin-top: 21.5px;
      input {
        width: auto;
        float: right;
        padding: 10px 40px;
        font-weight: 500;
        color: @white-color;
        background: @sky-start-dark;
        border: 0px;
        &:focus {
          outline: inherit;
        }
      }
      input.not-allowed-cursor {
        cursor: not-allowed;
      }
    }
  }
}

.orders-pics.pdf-call-order .tooltip.file-name-tooltip .tooltip-inner {
  word-wrap: break-word;
  color: #462a43;
  background-color: #abe6b7;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.orders-pics-resend {
  #full-width;
  background: @gray-light-white;
  padding: 13px;
  margin-top: 25px;
  ul {
    width: 100%;
    float: left;
    li {
      width: 10%;
      float: left;
      margin-right: 1%;
      text-align: center;
      position: relative;
      img {
        width: 100%;
        height: 60px;
      }
      button {
        position: absolute;
        right: 0;
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        border-radius: 100%;
        background: @white-color;
        opacity: 1;
        top: -7px;
        color: @sky-start-dark;
      }
      span {
        width: 100%;
        float: left;
        padding: 12px 0;
        background: @h1-color;
        color: @white-color;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
      }
    }
  }
}

.navbar-nav {
  li.dentist-order-manage {
    border-right: solid 1px #979797;
    min-height: 53px;
    a {
      float: left;
      i {
        font-size: 40px;
        float: left;
        margin: -10px 25px 0 0;
      }
      strong {
        width: 100%;
        float: left;
        margin-top: -5px;
      }
      span {
        width: 80%;
        float: left;
        text-align: left;
        font-size: 11px;
        margin-top: -7px;
        margin-bottom: 0px;
      }
      .rate-star-rating {
        width: 100%;
        float: left;
        padding-left: 56px;
      }
      strong.rate-head-strong {
        margin: -10px 0 -3px 0px;
      }
      strong.rate-head-strong + .ngrateit.ng-empty span {
        margin-top: 2px;
        margin-bottom: -9px;
      }
      strong.rate-head-strong + .ngrateit span {
        margin-top: 4px;
        margin-bottom: -2px;
      }
    }
    /*a.first-a-li{
      .ngrateit{
        span{
          margin-top: 2px;
        }
      }

    }*/
  }
  li.dentist-order-manage:first-child {
    a {
      padding-bottom: 0px !important;
      strong {
        text-align: left;
        width: 75%;
      }
    }
  }
  li {
    a.first-a-li {
      padding-bottom: 0px !important;
    }
    a.disabled {
      opacity: 0.2;
    }
  }
  li.lab-order-manage {
    border-right: solid 1px #979797;
    min-height: 53px;
    a {
      float: left;
      i {
        font-size: 40px;
        float: left;
        margin: -10px 15px 0 0;
      }
      strong {
        width: 100%;
        float: left;
        margin-top: -5px;
      }
      span {
        width: 85%;
        float: left;
        text-align: left;
        font-size: 11px;
        margin-top: -7px;
        margin-bottom: 0px;
      }
    }
  }
  li.lab-order-manage:first-child {
    a {
      padding-bottom: 0px !important;
      strong {
        text-align: left;
        width: 75%;
      }
    }
  }
}

@media screen and (min-height: 599px) and (max-height: 601px) {
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date {
    max-height: 250px;
    min-height: 250px;
  }
}
@media screen and (min-height: 765px) and (max-height: 770px) {
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date {
    max-height: 400px;
    min-height: 400px;
  }
}
@media screen and (min-height: 795px) and (max-height: 805px) {
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date {
    max-height: 430px;
    min-height: 430px;
  }
}
@media screen and (min-height: 890px) and (max-height: 910px) {
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date {
    max-height: 480px;
    min-height: 480px;
  }
}
@media screen and (min-height: 1040px) and (max-height: 1060px) {
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date {
    max-height: 630px;
    min-height: 630px;
  }
}
@media screen and (min-height: 1070px) and (max-height: 1090px) {
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date {
    max-height: 650px;
    min-height: 650px;
  }
}
@media screen and (min-height: 1190px) and (max-height: 1210px) {
  #dentist-order-detail .dentist-order-detail .chat-box .chat-box-date {
    max-height: 780px;
    min-height: 780px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1169px) {
  #dentist-order-detail
    .dentist-order-detail
    .chat-box
    .chat-box-date
    .chat-with-box
    .chat-front-person
    .chat-front-person-chat {
    width: 83%;
    float: left;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1599px) {
  #dentist-order-detail
    .dentist-order-detail
    .chat-box
    .chat-box-date
    .chat-with-box
    .chat-front-person
    .chat-front-person-chat {
    width: 88%;
    float: left;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1599px) {
  #dentist-order-detail
    .dentist-order-detail
    .chat-box
    .chat-box-date
    .chat-with-box
    .chat-front-person
    .chat-front-person-chat {
    width: 88%;
    float: left;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1919px) {
  #dentist-order-detail
    .dentist-order-detail
    .chat-box
    .chat-box-date
    .chat-with-box
    .chat-front-person
    .chat-front-person-chat {
    width: 89%;
    float: left;
  }
}
@media screen and (min-width: 1920px) {
  #dentist-order-detail
    .dentist-order-detail
    .chat-box
    .chat-box-date
    .chat-with-box
    .chat-front-person
    .chat-front-person-chat {
    width: 90%;
    float: left;
  }
}

#cancel-order {
  .modal-dialog {
    width: 700px;
    .modal-content {
      padding: 20px 40px 0px 40px;
      border-radius: 0px;
      background: @white-color;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      .modal-header {
        border: 0px;
        padding: 0px;
        .close {
          opacity: 1;
          font-size: 30px;
          margin: -15px -25px;
          &:focus {
            outline: none;
          }
        }
        h4.modal-title {
          font-size: 20px;
          font-weight: bold;
          text-align: left;
          color: @sky-start-dark;
          margin-bottom: 0px;
        }
      }
      .modal-body {
        padding-top: 0px;
        padding-bottom: 20px;
        .add-clinic-form {
          #full-width;
          margin-top: 0px;
          p {
            text-align: left;
            margin: 0;
            width: 100%;
            float: left;
            line-height: 18px;
          }
          input {
            #full-width;
            padding: 35px;
            text-align: center;
            margin: 7px 0 12px;
          }
        }
      }
      .modal-footer {
        border: 0;
        margin: 30px 0 20px;
        .btn.btn-default {
          border-radius: 0px;
          border: solid 1px @sky-start-dark;
          text-transform: uppercase;
          font-size: 15px;
          letter-spacing: 1px;
          font-weight: bold;
          padding: 10px 10px;
          color: @sky-start-dark;
          &:hover,
          &:focus,
          &.active-tab-close {
            background: @sky-start-dark;
            color: @white-color;
          }
        }
      }
    }
  }
}

#decline-order {
  .modal-dialog {
    width: 700px;
    .modal-content {
      padding: 20px 40px 0px 40px;
      border-radius: 0px;
      background: @white-color;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      .modal-header {
        border: 0px;
        padding: 0px;
        .close {
          opacity: 1;
          font-size: 30px;
          margin: -15px -25px;
          &:focus {
            outline: none;
          }
        }
        h4.modal-title {
          font-size: 20px;
          font-weight: bold;
          text-align: left;
          color: @sky-start-dark;
          margin-bottom: 0px;
        }
      }
      .modal-body {
        padding-top: 0px;
        padding-bottom: 20px;
        .add-clinic-form {
          #full-width;
          margin-top: 0px;
          p {
            text-align: left;
            margin: 0;
            width: 100%;
            float: left;
            line-height: 18px;
          }
          input {
            #full-width;
            padding: 35px;
            text-align: center;
            margin: 7px 0 12px;
          }
        }
      }
      .modal-footer {
        border: 0;
        margin: 30px 0 20px;
        .btn.btn-default {
          border-radius: 0px;
          border: solid 1px @sky-start-dark;
          text-transform: uppercase;
          font-size: 15px;
          letter-spacing: 1px;
          font-weight: bold;
          padding: 10px 10px;
          color: @sky-start-dark;
          &:hover,
          &:focus,
          &.active-tab-close {
            background: @sky-start-dark;
            color: @white-color;
          }
        }
      }
    }
  }
}

#send-order {
  .modal-dialog {
    width: 700px;
    .modal-content {
      padding: 20px 40px 0px 40px;
      border-radius: 0px;
      background: @white-color;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      .modal-header {
        border: 0px;
        padding: 0px;
        .close {
          opacity: 1;
          font-size: 30px;
          margin: -15px -25px;
          &:focus {
            outline: none;
          }
        }
        h4.modal-title {
          font-size: 20px;
          font-weight: bold;
          text-align: left;
          color: @sky-start-dark;
          margin-bottom: 0px;
        }
      }
      .modal-body {
        padding-top: 30px;
        padding-bottom: 60px;

        .approved-by-wrapper {
          margin-top: 15px;

          .red-astrix-mark {
            padding: 0;
            font-size: 14px;
            vertical-align: super;
            color: #ff0515 !important;
          }

          .approved-by-instructions {
            margin-top: 5px;
            font-size: 12px;
          }

          .approved-by-search-select {
            max-width: 300px;
          }
        }

        .add-clinic-form {
          float: none !important;

          #full-width;
          margin-top: 0px;
          p {
            text-align: left;
            margin: 0;
            width: 100%;
            line-height: 18px;
          }
        }
      }
      .modal-footer {
        border: 0;
        margin: 30px 0 20px;
        display: flex;
        align-items: center;

        .archive-check-wrapper {
          flex: 1 0 auto;
          margin: 0;

          input.archive-check-box {
            width: 100%;
            height: 100%;
          }
        }

        .btn.btn-default {
          flex: 0 1 auto;
          border-radius: 0px;
          border: solid 1px @sky-start-dark;
          text-transform: uppercase;
          font-size: 15px;
          letter-spacing: 1px;
          font-weight: bold;
          padding: 10px 10px;
          color: @sky-start-dark;
          &:hover,
          &:focus,
          &.active-tab-close {
            background: @sky-start-dark;
            color: @white-color;
          }
        }
      }
    }
  }
}
#archive-order,
#un-archive-order {
  .modal-dialog {
    width: 700px;
    .modal-content {
      padding: 20px 40px 0px 40px;
      border-radius: 0px;
      background: @white-color;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      .modal-header {
        border: 0px;
        padding: 0px;
        .close {
          opacity: 1;
          font-size: 30px;
          margin: -15px -25px;
          &:focus {
            outline: none;
          }
        }
        h4.modal-title {
          font-size: 20px;
          font-weight: bold;
          text-align: left;
          color: @sky-start-dark;
          margin-bottom: 0px;
        }
      }
      .modal-body {
        padding-top: 30px;
        padding-bottom: 60px;
        .add-clinic-form {
          #full-width;
          margin-top: 0px;
          p {
            text-align: left;
            margin: 0;
            width: 100%;
            float: left;
            line-height: 18px;
          }
        }
      }
      .modal-footer {
        border: 0;
        margin: 30px 0 20px;
        .btn.btn-default {
          border-radius: 0px;
          border: solid 1px @sky-start-dark;
          text-transform: uppercase;
          font-size: 15px;
          letter-spacing: 1px;
          font-weight: bold;
          padding: 10px 10px;
          color: @sky-start-dark;
          &:hover,
          &:focus,
          &.active-tab-close {
            background: @sky-start-dark;
            color: @white-color;
          }
        }
      }
    }
  }
}

#accept-order, #clinic-received-delivery, #lab-work-started {
  .modal-dialog {
    width: 700px;
    .modal-content {
      padding: 20px 40px 0px 40px;
      border-radius: 0px;
      background: @white-color;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      .modal-header {
        border: 0px;
        padding: 0px;
        .close {
          opacity: 1;
          font-size: 30px;
          margin: -15px -25px;
          &:focus {
            outline: none;
          }
        }
        h4.modal-title {
          font-size: 20px;
          font-weight: bold;
          text-align: left;
          color: @sky-start-dark;
          margin-bottom: 0px;
        }
      }
      .modal-body {
        padding-top: 30px;
        padding-bottom: 0;
        .version-comment {
          font-size: 13px;
          color: #000;
          font-weight: normal;
        }
        .add-clinic-form {
          #full-width;
          margin-top: 0px;
          p {
            text-align: left;
            margin: 25px 0 0;
            width: 100%;
            float: left;
            line-height: 18px;
          }
        }
        .commet-export-production {
          label.change-label-color {
            color: #105c9d;
            margin-top: 30px;
            font-weight: 500;
          }
        }
        .sample-content {
          ul {
            margin-top: -20px;
            padding-left: 0;
            margin-left: -4px;
          }
        }
      }
      .modal-footer {
        border: 0;
        margin: 30px 0 20px;
        .btn.btn-default {
          border-radius: 0px;
          border: solid 1px @sky-start-dark;
          text-transform: uppercase;
          font-size: 15px;
          letter-spacing: 1px;
          font-weight: bold;
          padding: 10px 10px;
          color: @sky-start-dark;
          &:hover,
          &:focus,
          &.active-tab-close {
            background: @sky-start-dark;
            color: @white-color;
          }
        }
      }
    }
  }
}

#rate-order {
  .modal-dialog {
    width: 1200px;
    .modal-content {
      padding: 30px 70px 5px 70px;
      border-radius: 0px;
      background: @white-color;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      .modal-header {
        border: 0px;
        padding: 0px;
        .close {
          opacity: 1;
          font-size: 30px;
          margin: -15px -25px;
          &:focus {
            outline: none;
          }
        }
        h4.modal-title {
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          color: @post-block-color;
          margin-bottom: 0px;
        }
      }
      .modal-body {
        padding-top: 10px;
        padding-bottom: 10px;
        p {
          text-align: left;
          margin: 0;
          width: 100%;
          float: left;
          line-height: 18px;
          font-size: 15px;
          text-align: center;
        }
        .row.auto-center-div {
          text-align: center;
          display: inline-block;
          width: 100%;
          float: left;
          margin-bottom: 50px;
          .col-md-2 {
            display: inline-block;
            float: none;
            vertical-align: top;
            width: 20%;
            .rating-head-div {
              #full-width;
              border-bottom: solid 1px @sky-start-dark;
              padding: 10px 0;
              margin-top: 25px;
              .rating-heading {
                width: 40%;
                float: left;
                text-align: center;
                line-height: 16px;
                color: @sky-start-dark;
                font-weight: 600;
                #rate-it;
              }
              .rating-heading + .rating-heading {
                width: 60%;
                float: left;
                text-align: center;
                #rate-it;
                a.ngrateit-reset {
                  display: none;
                }
              }
            }
            ul {
              li {
                color: @sky-start-dark;
                font-weight: 600;
                .rating-head-div {
                  border: 0px;
                  margin: 0px;
                  padding: 16px 0 0px;
                  .rating-heading {
                    color: @post-block-color;
                  }
                }
              }
            }
          }
        }
      }
      .modal-footer {
        border: 0;
        margin: 30px 0 20px;
        .btn.btn-default {
          border-radius: 0px;
          border: solid 1px @sky-start-dark;
          text-transform: uppercase;
          font-size: 15px;
          letter-spacing: 1px;
          font-weight: bold;
          padding: 10px 25px;
          color: @sky-start-dark;
          &:hover,
          &:focus,
          &.active-tab-close {
            background: @sky-start-dark;
            color: @white-color;
          }
        }
      }
    }
  }
}
#rate-order.one-rate-option {
  .modal-dialog {
    width: 500px;
    .modal-content {
      padding: 30px 0px 5px;
      .modal-header {
        .close {
          margin: -15px 15px;
        }
      }
      .modal-body {
        .row.auto-center-div {
          .col-md-2 {
            width: 50%;
          }
        }
      }
    }
  }
}
#rate-order.two-rate-option {
  .modal-dialog {
    width: 600px;
    .modal-content {
      padding: 30px 0px 5px;
      .modal-header {
        .close {
          margin: -15px 15px;
        }
      }
      .modal-body {
        .row.auto-center-div {
          .col-md-2 {
            width: 50%;
          }
        }
      }
    }
  }
}
#rate-order.three-rate-option {
  .modal-dialog {
    width: 750px;
    .modal-content {
      padding: 30px 10px 5px;
      .modal-header {
        .close {
          margin: -15px 15px;
        }
      }
      .modal-body {
        .row.auto-center-div {
          .col-md-2 {
            width: 33%;
          }
        }
      }
    }
  }
}
#rate-order.four-rate-option {
  .modal-dialog {
    width: 1000px;
    .modal-content {
      padding: 30px 10px 5px;
      .modal-header {
        .close {
          margin: -15px 15px;
        }
      }
      .modal-body {
        .row.auto-center-div {
          .col-md-2 {
            width: 25%;
          }
        }
      }
    }
  }
}

#resend-order-button {
  width: 100%;
  float: left;
  margin-bottom: 20px;
  button {
    width: 100%;
    float: left;
    padding: 10px 15px 8px;
    text-align: left;
    border-radius: 0px;
    background: @h1-color;
    color: @white-color;
    i {
      float: right;
      font-size: 20px;
    }
  }
}
.order-patient-resend #patient-section .patientLab {
  margin-top: 0px;
}
.compress-container-30 {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}

@media (min-width: 1169px) {
  .compress-container-30 .container {
    width: 1192px;
  }
}
.full-width {
  #full-width;
}

#imgModal {
  .ui-carousel {
    .slide {
      .carousel-item {
        .image {
          width: 100%;
          float: left;
          text-align: center;
          img {
            height: 450px;
            float: none;
            display: inline-block;
            margin: 100px 0 0px;
          }
        }
      }
    }
    .carousel-prev {
      .carousel-btn {
        left: 15px;
        i {
          line-height: 27px;
          background: @white-color;
          padding: 3px 10px;
          border-radius: 50%;
        }
      }
    }
    .carousel-next {
      .carousel-btn {
        right: 0px;
        i {
          line-height: 27px;
          background: @white-color;
          padding: 3px 10px;
          border-radius: 50%;
        }
      }
    }
  }
  .carousel-dots {
    bottom: 70px;
    li {
      button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 10px;
        height: 10px;
        padding: 0;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: 0;
        background: @white-color;
        border-radius: 50%;
        &:before {
          color: @white-color;
        }
      }
    }
  }
  span.close {
    position: absolute;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 32px;
    border-radius: 100%;
    background: #fff;
    opacity: 1;
    top: 60px;
    color: #111;
    font-size: 25px;
    z-index: 999;
    right: -25px;
  }
  .text-center {
    text-align: center;
    display: inline-block;
    width: 100%;
    float: left;
    position: relative;
  }
  .align-img-cross {
    width: auto;
    display: inline-block;
    position: relative;
  }
  img {
    max-width: 1000px;
    margin: 80px auto;
    max-height: 450px;
  }

  ul.carousel-dots li {
    width: 15px;
    height: 15px;
  }
}

.span-two-full {
  ul {
    li {
      span {
        position: relative;
        margin-right: 10px;
        display: inline-flex;
        &:after {
          content: ',';
          color: #000;
          font-weight: bold;
          width: 3px;
          height: 0px;
          position: absolute;
          right: 1px;
          bottom: 25px;
        }
        span {
          &:after {
            content: '';
          }
        }
        &:last-child {
          &:after {
            content: '';
          }
        }
      }
      span.modified {
        margin-right: 5px;
        &:after {
          color: @delay-red-dark !important;
          -webkit-print-color-adjust: exact;
        }
      }
      del {
        span {
          margin-right: 0px;
          &:after {
            color: @delay-red-dark !important;
            -webkit-print-color-adjust: exact;
          }
        }
      }
    }
    li.modified {
      span {
        margin-right: 5px;
        &:after {
          color: @delay-red-dark !important;
          -webkit-print-color-adjust: exact;
        }
      }
    }
  }
}

span.temporary-denture-tooth {
  position: relative;
  margin-right: 10px;
  display: inline-flex;
  &:after {
    content: ',' !important;
    color: #000;
    font-weight: bold;
    width: 3px;
    height: 0px;
    position: absolute;
    right: -2px !important;
    bottom: 20px !important;
  }
  span.temporary-denture-tooth {
    &:after {
      content: '' !important;
    }
  }
  &:last-child {
    &:after {
      content: '' !important;
    }
  }
}

#dentist-order-detail
  .dentist-order-detail
  .client-lab-detail
  .client-lab-detail-row
  .left-detail-part
  ul
  li
  > div {
  display: inline;
}
#dentist-order-detail
  .dentist-order-detail
  .client-lab-detail
  .client-lab-detail-row
  .left-detail-part
  ul
  li
  > del
  > div {
  display: inline;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

.chat-box .chat-box-date {
  position: relative;
}
#sppiner-loader-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 999;
}

.comment-date {
  white-space: nowrap;
  min-width: 125px;
}
