order-new > .row {
  max-width: 1200px;
  margin: auto !important;
}

/*patient Lab css*/
#patient-section {
  width: 100%;
  margin-left: auto;
}

#patient-section .patientLab {
  #section-page;
  margin-top: 155px;
}

#patient-section h1.patientTitle {
  #section-heading;
}

#patient-section .SearchPatient {
  width: 100%;
  float: left;
  padding: 40px 70px 10px;
}

#patient-section .SearchPatient .col-lg-2 {
  width: 14% !important;
}

#patient-section .SearchPatient .col-lg-2 {
  width: 19% !important;
  padding: 0;
  padding-right: 54px;
  min-height: 150px;
}

#patient-section .SearchPatient h1 {
  font-size: 18px;
  color: @header-bg;
  font-weight: 700;
}

#patient-section .SearchPatient ul {
  float: left;
  width: 85%;
  margin-top: 15px;
}

#patient-section .SearchPatient ul li {
  float: left;
  width: 28%;
  margin-right: 8%;
  text-align: center;
  position: relative;
}

#patient-section .SearchPatient ul li input {
  text-align: center;
  border-radius: initial;
  box-shadow: inherit;
}

#patient-section .SearchPatient ul li input.success-value {
  border: solid 1px green;
  color: #000;
  letter-spacing: 1.2px;
  font-weight: 400;
}

#patient-section .SearchPatient ul li input.success-error {
  border: solid 1px red;
}

#patient-section .SearchPatient ul li label {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 6px;
}

#patient-section .SearchPatient ul li input.form-control {
  height: 32px;
  padding: 5px 12px;
}

#patient-section .SearchPatient ul li:last-child {
  margin-right: 0;
  float: right;
}

#patient-section .chooseLAb {
  float: left;
  width: 100%;
  background: #ffffff;
  padding: 5px 0 0;
  text-align: center;
  margin: 25px auto auto;
  display: block;
  cursor: pointer;
  border: solid 2px #eeecec;
  overflow: hidden;
}

#patient-section .chooseLAb h1 {
  font-weight: 400 !important;
  font-size: 12px;
  padding: 0;
  color: #676666;
  margin-top: 5px !important;
  margin-bottom: 0px !important;
  background: #eeecec;
  padding: 9px 0 5px;
}

#patient-section .chooseLAb.active {
  background: @header-bg;
  cursor: pointer;
  border-color: @header-bg;
}

#patient-section .chooseLAb:hover {
  background: @header-bg;
  cursor: pointer;
}

#patient-section .chooseLAb:hover h1 {
  color: @white-color !important;
}

#patient-section .chooseLAb.active h1 {
  color: @white-color !important;
  background: @header-bg;
}

#patient-section .chooseLAb .imageWrapper {
  width: 100%;
  height: 68px;
}

#patient-section .chooseLAb img {
  width: 100%;
  height: 68px;
  object-fit: contain;
}

#patient-section span.check-icons i {
  background: #105c9d;
  color: @white-color;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  padding-top: 3px;
  position: absolute;
  top: 46px;
  right: -20px;
  font-size: 10px;
}

@-moz-document url-prefix() {
  #patient-section span.check-icons i {
    top: 40px;
  }
}

.no-padding-default {
  padding: 0 !important;
}

/*Delivery Information ccs*/
#delivery-section {
  width: 100%;
  padding-top: 55px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

#delivery-section .DeliveryInformation {
  #section-page;
  padding-bottom: 35px;
}

#delivery-section h1.patientTitle {
  #section-heading;
}

#delivery-section .DelInf {
  float: left;
  width: 80%;
  margin-left: 10%;
}

#delivery-section .deliveryDate {
  float: left;
  width: 100%;
  margin-top: 41px;
}

#delivery-section .deliveryDate h3 {
  font-size: 18px;
  font-weight: bold;
  color: @sky-start-dark;
}

#delivery-section .deliveryDate ul {
  width: 100%;
  float: left;
  margin-top: 10px;
}

#delivery-section .deliveryDate ul li {
  float: left;
  width: 100%;
  background: #eeecec;
  margin-right: 1%;
  text-align: center;
  margin-bottom: 2%;
}

#delivery-section .deliveryDate ul li a {
  color: #5a5959;
}

#delivery-section .deliveryDate ul li select {
  width: 100%;
  border: 0;
  background: transparent;
  padding: 2px 9px;
  cursor: pointer;
}

#delivery-section .deliveryDate ul li input {
  width: 100%;
  border: 0;
  background: transparent;
  margin: 0;
  float: left;
  padding: 2px 9px;
  cursor: pointer;
  min-height: 26px;
}

#delivery-section .form-group {
  margin-bottom: 15px;
  margin-top: 22px;
  margin-left: 15px;
}

#delivery-section .form-group label {
  font-size: 18px;
  font-weight: bold;
  color: @sky-start-dark;
}

#delivery-section .padding_Bottom-50 .btn-default:hover,
#delivery-section .padding_Bottom-50 .btn-default.active {
  background: @header-bg;
  color: @white-color;
  box-shadow: initial;
}

#delivery-section .padding_Bottom-50 .btn-default {
  color: @header-bg;
  background-color: @white-color;
  border-color: @header-bg;
  font-size: 15px;
  border-radius: initial;
  font-weight: 600;
  padding: 13px 40px;
}

.flex-textarea {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 400px;
  height: 250px;
  background-color: lightgrey;
  text-align: center;
}

/*upload-images css*/

#images-section {
  width: 100%;
  padding-top: 60px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

#images-section .upload-images {
  #section-page;
  padding-bottom: 30px;
}

#images-section h1.patientTitle {
  #section-heading;
}

#images-section .images-upload {
  float: left;
  width: 77%;
  background: #f2f2f2;
  text-align: center;
  margin-bottom: 20px;
  margin-left: 12%;
  margin-top: 43px;
  padding: 41px 20px 40px;
}

#images-section .images-upload img {
  width: 90px;
}

#images-section .drop-box {
  margin-top: 0px;
  padding: 41px 20px 40px;
  cursor: pointer;
}

#images-section .upload-images .drop-box.filechoose-div {
  width: 100%;
  margin-top: 28px !important;
  border: none;
  padding: 41px 20px 40px;
  cursor: pointer;
}

#images-section .progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  width: 50%;
  margin-top: 14px;
  float: left;
}

#images-section .progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  color: @white-color;
  text-align: center;
  background-color: @header-bg !important;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

#images-section span.images-file-name {
  float: left;
  margin-right: 12px;
  margin-left: 10px;
  padding-top: 14px;
  font-size: 15px;
}

#images-section span.images-file-name i {
  cursor: pointer;
}

#images-section span.Images-circle img {
  width: 100%;
}

#images-section span.Images-circle {
  float: left;
  width: 50px;
  height: 50px;
  margin: 5px;
}

#images-section span.images-refresh {
  padding-top: 17px;
  float: left;
  margin-left: 16px;
}

#images-section span.file-icon {
  float: left;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 10px;
}

#images-section span.file-icon i {
  font-size: 4em;
}

/*signSend css*/
#sign-section {
  width: 100%;
  padding-top: 60px;
}

#sign-section .signSend {
  #section-page;
  padding-bottom: 45px;
  margin-bottom: 45px;
}

#sign-section h1.patientTitle {
  #section-heading;
}

#sign-section .signSendbox {
  float: left;
  width: 100%;
  text-align: center;
}

.sign-with-img-icon {
  position: relative;
  width: auto;
  display: inline-block;
  float: none;
}

.sign-with-img-icon span.right-sign-all-select {
  float: left;
  width: 50px;
  margin: 0 10px 0 0;
  height: 50px;
  background: #105c9d;
  border: 2px solid #105c9d;
  outline: 0;
  /* cursor: pointer;*/
  -webkit-appearance: inherit;
  position: absolute;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 30px;
  border-radius: 100%;
  bottom: 10px;
  right: -45px;
}

.sign-with-img-icon span.right-sign-all-select:after {
  content: '\f00c';
  position: absolute;
  top: 17px;
  left: 15px;
  font-size: 30px;
  color: #ffffff;
}

#sign-section .signSendbox h1 {
  font-size: 25px;
  color: #000;
  font-weight: bold;
  margin-bottom: 20px;
}

#sign-section .signSendbox p {
  font-size: 15px;
  margin-bottom: 15px;
}

#sign-section label.btn {
  background: @white-color;
  border: solid 1px #ccc;
  border-radius: inherit;
  margin-right: 15px;
  width: 22px;
  height: 26px;
  margin-top: -1px;
}

#sign-section label.btn input {
  opacity: 0;
}

#sign-section label.btn.active {
  background: #105c9d;
  color: @white-color;
  border: solid 1px #105c9d;
}

#sign-section label.btn .glyphicon {
  display: none;
}

#sign-section label.btn.active .glyphicon {
  left: -7px;
  top: -17px;
  font-size: 12px;
  display: block;
}

#sign-section .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: inherit;
  border-top-right-radius: inherit;
}

#sign-section .btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: inherit;
  border-top-left-radius: inherit;
}

#sign-section .btn.active:focus {
  outline: inherit;
}

#sign-section .signSendbox .glyphicon-ok:before {
  content: '\e013';
  color: @white-color;
}

#sign-section .signSendbox img {
  width: 85px;
  margin: 40px 0px;
}

#sign-section .signSendbox button.btn.btn-default.active,
.signSendbox button.btn.btn-default.active {
  background: @header-bg;
  color: @white-color;
  box-shadow: initial;
}

#sign-section .signSendbox button.btn.btn-default {
  color: @header-bg;
  background-color: @white-color;
  border-color: @header-bg;
  font-size: 15px;
  border-radius: initial;
  font-weight: 600;
  padding: 9px 0px;
  min-width: 155px;
  text-transform: uppercase;
}

.has-error {
  border: 1px solid #de4744 !important;
}

#sign-section .signSendbox .well-sm {
  margin-bottom: 18px;
}

#sign-section span.Sample-disinfected.ng-binding {
  font-size: 15px;
}

#sign-section .glyphicon {
  left: -6px;
  top: 0;
  font-size: 12px;
}

.square {
  border: solid 1px @dark-grey;
  border-radius: 8px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  margin: 0 3px;
  margin-right: 0px;
}

.bridge-suggestion {
  margin-left: 20px;
  width: 120px;
  margin-top: 10px;
  border: 1px solid @success-green;
  border-radius: 15px;
  padding: 10px;
  color: @success-green;
}

label.bridge-suggestion {
  max-width: inherit !important;
  text-align: center;
}

.partial-suggestion {
  margin-left: 20px;
  width: 120px;
  margin-top: 24px;
  border: 1px solid #8dc44e;
  border-radius: 15px;
  padding: 10px;
  color: #8dc44e;
}

label.partial-suggestion {
  max-width: inherit !important;
  text-align: center;
}

.square-bg {
  background: #a6d273;
}

.square-selected {
  border: 3px solid @header-bg !important;
}

.softInside .checkbox-inline {
  padding-left: 0px;
  width: 100%;
}

.color-sampling-radio input[type='radio']:not(:checked) {
  float: left;
  width: 15px;
  margin: 0px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid @post-block-color;
  outline: none;
  -webkit-appearance: inherit;
  padding: 8px;
  border-radius: 50%;
  position: static;
  cursor: pointer;
}

.color-sampling-radio input[type='radio']:checked {
  float: left;
  width: 15px;
  margin: 0px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid @complete-denture;
  outline: none;
  cursor: pointer;
  -webkit-appearance: inherit;
  position: static;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 8px;
  border-radius: 50%;
}

.color-sampling-radio input[type='radio']:checked:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  font-size: 12px;
  width: 12px;
  height: 12px;
  background: @complete-denture;
  border-radius: 50%;
  color: @complete-denture;
}

.color-sampling-radio.implant-bridge-retainment input[type='radio']:checked {
  border: 2px solid @implant-bridge;
}

.color-sampling-radio.implant-bridge-retainment input[type='radio']:checked:after {
  background: @implant-bridge;
}

.color-sampling-radio.implant-crown-retainment input[type='radio']:checked {
  border: 2px solid @implant-crown;
}

.color-sampling-radio.implant-crown-retainment input[type='radio']:checked:after {
  background: @implant-crown;
}

.signSendbox .marginBottom input[type='checkbox'] {
  opacity: 1 !important;
}

.signSendbox .marginBottom #Sample-disinfected,
.signSendbox .marginBottom #sampleDisinfected1 {
  opacity: 0 !important;
}

/*.signSendbox .marginBottom input[type="checkbox"]{ opacity: 1 !important;}
  .signSendbox .marginBottom input[type="checkbox"]:not(:checked){
    float: left;
    width: 22px;
    margin: 0px 10px 0px 0px;
    height: 26px;
    background: none;
    border: 0px solid @post-block-color;
    outline: none;
    -webkit-appearance: inherit;
    padding: 0px;
    border-radius: 50%;
    position: static;
    cursor: pointer;
  }

  .signSendbox .marginBottom input[type="checkbox"]:checked {
    float: left;
    width: 22px;
    margin: 0px 10px 0px 0px;
    height: 26px;
    background: none;
    border: 0px solid @complete-denture;
    outline: none;
    cursor: pointer;
    -webkit-appearance: inherit;
    position: static;
    font: normal normal normal 14px/1 FontAwesome;
    padding: 0px;
    border-radius: 50%;
  }

  .signSendbox .marginBottom input[type="checkbox"]:checked:after {
    content: '\f00c';
    position: absolute;
    top: 4px;
    left: 4px;
    font-size: 15px;
    color: @white-color;
  }*/

.color-sampling-radio .checkbox-inline {
  padding-left: 0px;
  width: 100%;
  margin-left: 0px !important;
  margin-top: 5px !important;
  font-size: 13px;
}

.color-sampling-radio {
  margin-top: 20px;
}

.color-sampling-radio h3 {
  font-size: 15px;
  margin-bottom: 15px;
}

.color-sampling-radio img {
  max-width: 50%;
}

.image-printable {
  padding: 5% 5% 5% 5%;
  align-content: center;
}

.image-printable img {
  height: 600px;
}

#sign-section label.btn:focus {
  outline: 0;
  outline-offset: 0;
}

.square-post {
  position: relative;
}

.square-post:after {
  width: 0;
  height: 0;
  border-bottom: 28px solid #4a4a4a;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  position: absolute;
  right: 14px;
  bottom: -4px;
  content: '';
}

.square-post:hover:after {
  bottom: -1px;
  right: 12px;
}

.square-selected.square-post:after {
  bottom: -1px;
  right: 12px;
}

.square-bg:hover {
  border: solid 3px @header-bg;
  position: relative;
}

.square {
  position: relative;
}

.cross-square {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: @header-bg;
  position: absolute;
  top: -12px;
  right: -8px;
  color: #fff;
  line-height: 20px;
  font-size: 12px;
  display: none;
  z-index: 9;
}

.cross-rectangle-post {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: @header-bg;
  position: absolute;
  bottom: -19px;
  right: 3.5px;
  color: #fff;
  line-height: 20px;
  font-size: 12px;
  display: none;
}

.square:hover {
  border: solid 3px @header-bg;
}

p.red-astrix-mark {
  display: inline-block;
  padding: 0 5px;
  color: @delay-red-dark !important;
}

b.red-astrix-mark {
  display: inline;
  color: @delay-red-dark;
}

p.required-field.ng-binding {
  color: @delay-red-dark;
  font-size: 13px;
  position: absolute;
  bottom: -5px;
  right: 28px;
  margin-bottom: 0px;
}

.margin-top {
  margin-top: 35px !important;
}

.margin-top-45 {
  margin-top: -15px !important;
}

.margin-top-75 {
  margin-top: -61px !important;
}

.margin-top-130 {
  margin-top: -95px !important;
}

input {
  &::-webkit-input-placeholder {
    color: @gray-dark;
  }

  &::-moz-placeholder {
    color: @gray-dark;
  }

  &:-ms-input-placeholder {
    color: @gray-dark;
  }

  &:-moz-placeholder {
    color: @gray-dark;
  }
}

.identityError {
  color: @dark-red-color;
}

a._720kb-datepicker-calendar-day._720kb-datepicker-disabled {
  background-color: @light-red-grey-tone !important;
}

a._720kb-datepicker-calendar-day._720kb-datepicker-active {
  background-color: @active-tab-green !important;
}

._720kb-datepicker-calendar {
  z-index: 9 !important;
}

._720kb-datepicker-calendar-header:nth-child(odd),
._720kb-datepicker-calendar-header:nth-child(even) {
  background: @header-bg !important;
  border-bottom: solid 1px @white-color;
}

.commet-export-production p {
  display: inline-block;
  line-height: 22px;
  font-size: 15px;
  font-weight: 400;
  color: #5c5c5c;
  text-shadow: none;
}

.commet-export-production input[type='checkbox']:not(:checked),
.commet-export-production input.notCheckedInput {
  float: left;
  width: 15px;
  height: 15px;
  -webkit-appearance: inherit;
  margin: 2px 10px 0px 0px;
  background: none;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(142, 221, 221);
  border-image: initial;
  outline: none;
  padding: 7px;
  border-radius: 2px;
}

.commet-export-production input[type='checkbox']:checked,
.commet-export-production input:checked.checkedInput {
  float: left;
  width: 15px;
  height: 15px;
  -webkit-appearance: inherit;
  position: relative;
  margin: 2px 10px 0px 0px;
  background: rgb(31, 186, 187);
  border-width: 2px;
  border-style: solid;
  border-color: rgb(31, 186, 187);
  border-image: initial;
  outline: 0px;
  font: 14px/1 FontAwesome;
  padding: 7px;
  border-radius: 4px;
}

.commet-export-production input[type='checkbox']:checked:after,
.commet-export-production input:checked.checkedInput:after {
  content: '\f00c';
  position: absolute;
  top: 1px;
  left: 1px;
  font-size: 13px;
  color: #fff;
}

.new-order-comments {
  margin-bottom: 0px !important;
  font-size: 13px !important;
}

/*label.checkbox-style{
    font-weight: normal; font-size: 14px; margin-top: 20px;
    label {
      color: @h1-color !important;
      margin-left: 0 !important;
      font-weight: 400;
    }
    input[type=radio],
    input[type=checkbox],
    input.notCheckedInput {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: transparent;
      position: relative;
      visibility: hidden;
    }
    input[type=radio],
    input[type=checkbox],
    input[type=radio] + label::before,
    input[type=checkbox] + label::before,
    input.notCheckedInput + label::before {
      font-size: 18px;
      line-height: 20px;
      cursor: pointer;
      vertical-align: middle;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      border-radius: 5px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
      display: none;
    }

    input[type=radio] {
      border-radius: 50%;
    }

    input[type=radio] + label::before,
    input[type=checkbox] + label::before,
    input.notCheckedInput + label::before{
      content: '';
      text-align: center;
      display: inline-block;
      pointer-events: none;
      opacity: 1;
      color: black;
      transition: all .3s ease;
      border: 2px solid @sky-start-dark;
      width: 23px;
      height: 23px;
      margin-right: 15px;
    }
    input[type=radio] + label::before,
    input.checkedInput + label::before,
    input.ng-not-empty + label::before{
      background: @sky-start-dark;
    }
    input[type=radio] + label,
    input[type=checkbox] + label,
    input.notCheckedInput + label {
      line-height: 23px;
      margin: 0 15px 0 15px;
    }
    input[type=checkbox]:hover,
    input.notCheckedInput:hover {
      cursor: pointer;
    }
    input.notCheckedInput.ng-empty:hover + label::before {
      content: '';
    }
    input[type=checkbox]:checked + label::before,
    input.checkedInput + label::before {
      content: '\2714';
      font-size: 14px;
      text-align: center;
      color: #fff !important;
    }

    input[type=radio]:checked + label::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      font-size: 12px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      color: #2c3995;
      background: #096d9f;
    }

    input[type=checkbox]:checked:hover + label::before,
    input.checkedInput:hover + label::before {
      opacity: 1;
    }
  }*/

label.checkbox-style {
  width: 100%;
  float: left;
  font-weight: normal;
  font-size: 14px;
  position: relative;
  cursor: pointer;

  label {
    cursor: pointer;
    color: @h1-color !important;
    margin-left: 0 !important;
    font-weight: 400;

    .email-noti-para {
      span {
        &:last-child {
          span {
            display: none;
          }
        }
      }
    }
  }

  input[type='checkbox'],
  input[type='radio'],
  input.notCheckedInput {
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
  }

  input.chooseLAb[type='radio'] {
    width: 30px;
  }

  input[type='checkbox'] + label,
  input[type='radio'] + label,
  input.notCheckedInput + label {
    position: relative;
    padding-left: 30px;
    line-height: 23px;
    float: left;
  }

  input[type='checkbox'] + label:after,
  input[type='radio'] + label:after,
  input.notCheckedInput + label:after,
  input.chooseLAb[type='radio'] + label:after {
    width: 23px;
    height: 23px;
    content: '';
    border: solid 2px @post-block-color;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8;
    border-radius: 3px;
  }

  input[type='radio'] + label:after {
    border-radius: 50%;
  }

  input[type='checkbox']:checked + label:after,
  input.checkedInput + label:after,
  input.chooseLAb.active[type='radio'] + label:after {
    border-color: @sky-dark;
  }

  input[type='checkbox']:checked + label:before,
  input.checkedInput + label:before,
  input.chooseLAb.active[type='radio'] + label:before {
    content: '\2714';
    font-size: 14px;
    text-align: center;
    color: #fff !important;
    background: @sky-dark;
    width: 23px;
    height: 23px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 3px;
    z-index: 8;
  }

  input[type='radio']:checked + label:before,
  input[type='radio'].checkedInput:checked + label:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    color: @sky-dark;
    background: @sky-dark;
  }

  input[type='radio']:checked + label:after {
    border-color: @sky-dark;
  }
}

.implant-crown-retainment {
  label.checkbox-style {
    input[type='checkbox']:checked + label:after,
    input.checkedInput + label:after,
    input[type='radio']:checked + label:after {
      border-color: @implant-crown;
    }

    input[type='checkbox']:checked + label:before,
    input.checkedInput + label:before,
    input[type='radio']:checked + label:before {
      background: @implant-crown;
    }
  }
}

.implant-bridge-retainment {
  label.checkbox-style {
    input[type='checkbox']:checked + label:after,
    input.checkedInput + label:after,
    input[type='radio']:checked + label:after {
      border-color: @implant-bridge;
    }

    input[type='checkbox']:checked + label:before,
    input.checkedInput + label:before,
    input[type='radio']:checked + label:before {
      background: @implant-bridge;
    }
  }
}

.complete-denture-section {
  label.checkbox-style {
    input[type='checkbox']:checked + label:after,
    input.checkedInput + label:after,
    input[type='radio']:checked + label:after {
      border-color: @complete-denture;
    }

    input[type='checkbox']:checked + label:before,
    input.checkedInput + label:before,
    input[type='radio']:checked + label:before {
      background: @complete-denture;
    }
  }
}

.signSendbox {
  label.checkbox-style {
    label {
      color: #a5a3a3;
    }
  }
}

.export-prod:after {
  z-index: 8 !important;
}

.flex-div-layout {
  display: flex;
  flex-wrap: wrap;
  .col-lg-2 {
    min-width: 19% !important;
  }
}
@media only screen and (min-width: 768px) {
  .simple-order-section {
    padding-top: 0 !important;
    margin-top: -15px;
  }
}
@media only screen and (min-width: 768px) {
  .simple-order-image-sign-section {
    padding-top: 0 !important;
    margin-top: -7px;
  }
}
