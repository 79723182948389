#section-page {
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 35px;
  padding: 18px 61px 50px;
}
#section-heading {
  float: left;
  font-size: 20px;
  color: #1f2532;
  font-weight: 700;
  border-bottom: solid 1px #1f2532;
  width: 100%;
  line-height: 50px;
}
#full-width {
  width: 100%;
  float: left;
}
#rate-it {
  /* Background */
  /* Selected state */
  /* Cancelation of hover styling */
  /* Hover styling */
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../../../images/rated-star.svg");
}
#rate-it .ngrateit-reset {
  background-image: url("../../../images/cancel-icon.svg");
}
#rate-it .ngrateit-bg-star {
  background-image: url("../../../images/bg-star.svg");
}
#rate-it .ngrateit-selected {
  background-image: url("../../../images/rated-star.svg");
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating span:hover ~ span {
  background-image: url("../../../images/bg-star.svg");
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../../../images/rated-star.svg");
}
.tabs-ui {
  width: 100%;
  float: left;
  padding: 20px 0;
}
.tabs-ui ul.nav-tabs {
  padding: 0 35px;
}
.tabs-ui ul.nav-tabs li {
  display: block;
  width: auto;
  float: left;
}
.tabs-ui ul.nav-tabs li.active a {
  border: 0;
  border-bottom: 5px solid #105c9d;
}
.tabs-ui ul.nav-tabs li.active a:focus,
.tabs-ui ul.nav-tabs li.active a:hover {
  border: 0;
  border-bottom: 5px solid #105c9d;
}
.tabs-ui ul.nav-tabs li a {
  border: 0;
  border-bottom: 5px solid #fff;
  font-weight: 600;
  color: #1f2532;
  padding: 7px 35px;
}
.tabs-ui .profile-form-left {
  margin-bottom: 20px;
  margin-top: 20px;
}
.tabs-ui .profile-form-left .info-tab-content-row {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}
.tabs-ui .profile-form-left .info-tab-content-row h4 {
  color: #105c9d;
  margin-bottom: 4px;
  font-size: 14px;
}
.tabs-ui .profile-form-left .info-tab-content-row h4 p.red-astrix-mark {
  margin: 0;
  line-height: 19px;
}
.tabs-ui .profile-form-left .info-tab-content-row input {
  width: 100%;
  float: left;
  padding: 8px 10px;
  font-size: 12px;
  text-align: center;
  border: solid 1px #979797;
  min-height: 38px;
}
.tabs-ui .profile-form-left .info-tab-content-row input:focus {
  outline: none;
}
.tabs-ui .profile-form-left .info-tab-content-row .street-block {
  width: 32%;
  float: left;
  margin-right: 2%;
  margin-bottom: 7px;
}
.tabs-ui .profile-form-left .info-tab-content-row .street-block label {
  width: 100%;
  float: left;
  color: #dcdada;
  font-weight: 300;
  font-style: italic;
  line-height: 15px;
  font-size: 13px;
}
.tabs-ui .profile-form-left .info-tab-content-row .street-block ul {
  width: 100%;
  float: left;
}
.tabs-ui .profile-form-left .info-tab-content-row .street-block ul li {
  width: auto;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px 15px;
  background: #dcdada;
  cursor: pointer;
}
.tabs-ui .profile-form-left .info-tab-content-row .street-block ul li.choose-clinic-tab {
  background: #105c9d;
  color: #fff;
}
.tabs-ui .profile-form-left .info-tab-content-row .street-block.full-street-div {
  width: 100%;
}
.tabs-ui .profile-form-left .info-tab-content-row .street-block .street-block-select {
  line-height: 38px;
  min-height: 38px;
  width: 120%;
  font-size: 14px;
  height: 38px;
}
.tabs-ui .profile-form-left .info-tab-content-row .street-block.remove-mrgn {
  margin-right: 0;
}
.tabs-ui .profile-form-left .info-tab-content-row .street-block.postcode-input {
  width: 15%;
}
.tabs-ui .profile-form-left .info-tab-content-row .street-block.postcode-input input {
  width: 100%;
}
.tabs-ui .profile-form-left .profile-form-left-col .user-email label.checkbox-style {
  margin-top: 5px;
  margin-bottom: -10px;
}
.tabs-ui .profile-form-left .profile-form-left-col .user-email label.checkbox-style input[type=radio] {
  width: 25px;
  height: 25px;
  margin: 0;
}
.tabs-ui .profile-form-left .profile-form-left-col .input-number {
  width: 50px;
  text-align: center;
  margin-left: 15px;
}
.tabs-ui .profile-form-left .profile-form-left-col .starting-number-input {
  width: 120px;
}
.tabs-ui .profile-form-left .invoice-list {
  width: 100%;
  float: left;
}
.tabs-ui .profile-form-left .invoice-list .invoice-list-header {
  width: 100%;
  float: left;
  background: #dcdada;
}
.tabs-ui .profile-form-left .invoice-list .invoice-list-content .invoice-list-settings:nth-child(2n) {
  width: 100%;
  float: left;
  background: #eee;
}
.tabs-ui .profile-form-left .invoice-list .font-manage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 35px;
}
.tabs-ui .profile-form-left .invoice-list .font-manage input,
.tabs-ui .profile-form-left .invoice-list .font-manage select {
  height: 26px;
  width: 100%;
}
.tabs-ui .profile-form-left .invoice-list .font-manage .profile-form-left-Select {
  margin-top: 0 !important;
}
.tabs-ui .profile-form-left .invoice-list .font-manage .profile-form-left-Select .switch-button {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0!important;
}
#order-list .order-list .order-list-hading.invoice-table-width .col-md-2 {
  width: 16.66666667%;
}
.invoice-pdf-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}
.padding-zero {
  padding: 0 !important;
}
