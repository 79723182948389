@import '~stylesheets/mixins.less';
@import '~stylesheets/variables.less';

input:-webkit-autofill {
  background: @white-color !important;
  background-color: @white-color !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#lab-profile {
  #full-width;
  margin-top: 150px;
  margin-bottom: 20px;
  .lab-profile {
    #full-width;
    .profile-left-section {
      #full-width;
      background: @white-color;
      padding-top: 45px;
      .head-profile {
        #full-width;
        padding: 0 35px;
        h2 {
          #full-width;
          text-align: center;
          background: url(images/border-head-h2.png) 0 center repeat-x;
          span {
            width: auto;
            padding: 0 28px;
            background: @white-color;
            text-transform: uppercase;
            font-size: 22px;
            font-weight: 300;
          }
        }
      }
      .profile-form-left {
        #full-width;
        padding: 25px 0px;
        .profile-form-left-row {
          #full-width;
          margin-bottom: 0px;
          padding: 0 35px;
          label {
            color: @sky-start-dark;
            margin-bottom: 5px;
            float: left;
            width: 100%;
          }
          input {
            width: 100%;
            float: left;
            padding: 10px 20px;
            font-size: 15px;
            text-align: center;
            text-align: left;
            border: solid 1px @gray-dark;
            line-height: 17px;
            font-weight: 400;
            color: @gray-font-color;
          }
          textarea {
            width: 100%;
            float: left;
            padding: 15px 20px;
            font-size: 15px;
            text-align: center;
            text-align: left;
            border: solid 1px @gray-dark;
            line-height: 17px;
            font-weight: 400;
            color: @gray-font-color;
          }
          span {
            #full-width;
            color: @gray-bg-color;
            font-size: 12px;
            margin: -5px 0 7px;
          }
          p {
            #full-width;
            font-size: 12px;
            text-align: right;
            display: inline;
            span {
              display: inline;
              width: auto;
              float: none;
              color: @sky-start-dark;
            }
          }
        }
        .profile-form-left-row.current-jobs-section {
          .jobs-div {
            #full-width;
            .jobs-div-row {
              #full-width;
              margin: 1px 0px;
              label {
                width: 55%;
                margin-left: 2.5%;
                float: left;
                font-weight: normal;
                font-size: 14px;
                margin-top: 5px;
                color: @gray-font-color;
                display: flex;
                justify-content: left;
                align-items: center;
                input[type='checkbox'].notCheckedInput {
                  float: left;
                  width: 15px;
                  margin: 2px 10px 0px 0px;
                  height: 15px;
                  background: none;
                  border: 2px solid @sky-low-bright;
                  outline: none;
                  -webkit-appearance: inherit;
                  padding: 7px;
                  border-radius: 2px;
                }
                input[type='checkbox'].checkedInput {
                  float: left;
                  width: 15px;
                  margin: 2px 10px 0 0;
                  height: 15px;
                  background: #105c9d;
                  border: 2px solid #105c9d;
                  outline: 0;
                  -webkit-appearance: inherit;
                  position: relative;
                  font: normal normal normal 14px/1 FontAwesome;
                  padding: 7px;
                  border-radius: 4px;
                }
                input[type='checkbox'].checkedInput:after {
                  content: '\f00c';
                  position: absolute;
                  top: 1px;
                  left: 1px;
                  font-size: 12px;
                  color: @white-color;
                }
                p {
                  width: auto;
                  margin-left: 2.5%;
                  float: left;
                  font-weight: 400;
                  font-size: 14px;
                  color: #a5a3a3;
                  margin: 0;
                  text-align: left;
                  line-height: 17px;
                }
              }
              .delivery-section {
                width: 40%;
                float: right;
                input {
                  width: 30%;
                  float: left;
                  text-align: center;
                  padding: 6px 0px;
                }
                span {
                  width: 70%;
                  margin-bottom: -1px;
                  float: right;
                  color: @gray-bg-color;
                  font-size: 14px;
                  text-align: right;
                  line-height: 39px;
                }
                &.align-span-content {
                  span {
                    width: 65%;
                    text-align: left;
                    line-height: 15px;
                    margin-top: 1px;
                  }
                }
              }
            }
          }
          &.document-section {
            margin: 20px 0 10px;
          }
        }
        .profile-form-left-row.document-section {
          margin-top: 10px;
          .document-div {
            #full-width;
            background: @gray-light-white;
            padding: 7px;
            .document-div-left {
              float: left;
              width: 60%;
              .upload-file-name {
                margin-top: 0px;
                color: @h1-color;
                padding: 15px 5px 0px;
                min-height: 50px;
                max-height: 50px;
                margin-bottom: 3px;
                margin-right: 3px;
                float: left;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: block;
                width: 90%;
                .progress {
                  width: 100%;
                  float: left;
                  height: 3px;
                  border-radius: 0px;
                  margin: 12px 0 0px;
                  background-color: @header-bg;
                  .progress-bar {
                    height: 3px;
                    float: left;
                    border-radius: 0px;
                  }
                }
              }
              img {
                float: left;
                margin-top: 10px;
                margin-left: 5px;
                cursor: pointer;
              }
            }
            .document-div-right {
              width: auto;
              float: right;
              max-width: 40%;
              .upload-file-div {
                position: relative;
                width: 130px;
                float: right;
                input[type='file'] {
                  width: 100%;
                  cursor: pointer;
                  float: right;
                  padding-top: 4px;
                  padding-bottom: 0px;
                  background: @sky-start-dark;
                  color: @white-color;
                  position: relative;
                  z-index: 99;
                  opacity: 0;
                }
                .filechoose-div {
                  width: 100%;
                  cursor: pointer;
                  float: right;
                  background: @sky-start-dark;
                  color: @white-color;
                  text-align: center;
                  text-transform: uppercase;
                  font-weight: 500;
                }
              }
              span {
                width: 100%;
                margin-bottom: 0;
                float: right;
                color: @gray-dark;
                font-size: 16px;
                text-align: right;
                line-height: 21px;
                margin-top: 0;
              }
            }
          }
        }
      }
    }
    .profile-right-section {
      width: 98%;
      float: right;
      .profile-right-block-one {
        #full-width;
        background: @white-color;
        padding: 45px 35px 50px;
        .head-profile {
          #full-width;
          h2 {
            #full-width;
            text-align: center;
            background: url(images/border-head-h2.png) 0 center repeat-x;
            span {
              width: auto;
              padding: 0 28px;
              background: @white-color;
              text-transform: uppercase;
              font-size: 22px;
              font-weight: 300;
            }
          }
        }
        .media-section {
          #full-width;
          margin-top: 30px;
          .media-section-block {
            width: 47%;
            float: left;
            margin-right: 3%;
            h3 {
              #full-width;
              color: @sky-start-dark;
              font-size: 18px;
              margin-bottom: 18px;
            }
            img {
              width: 75%;
              float: left;
            }
            button {
              width: auto;
              text-transform: uppercase;
              float: left;
              padding: 10px 15px;
              margin-top: 20px;
              color: @white-color;
              background: @sky-start-dark;
              border: 0px;
            }
          }
        }
      }
      .profile-right-block-two {
        #full-width;
        background: @white-color;
        padding: 45px 35px 30px;
        margin-top: 50px;
        .head-profile {
          #full-width;
          padding: 0 35px;
          h2 {
            #full-width;
            text-align: center;
            background: url(images/border-head-h2.png) 0 center repeat-x;
            span {
              width: auto;
              padding: 0 28px;
              background: @white-color;
              text-transform: uppercase;
              font-size: 22px;
              font-weight: 300;
            }
          }
        }
        .media-section {
          #full-width;
          margin-top: 30px;
          .media-section-block {
            width: 51%;
            float: left;
            margin-right: 1%;
            h3 {
              #full-width;
              color: @sky-start-dark;
              font-size: 18px;
              margin-bottom: 18px;
            }
            .select-days-div {
              #full-width;
              .jobs-div-row {
                #full-width;
                padding-left: 15px;
                label {
                  width: 95px;
                  position: relative;
                  cursor: pointer;
                  float: left;
                  font-weight: normal;
                  font-size: 14px;
                  margin-top: 0px;
                  color: @gray-font-color;
                  input[type='checkbox'].notCheckedInput {
                    float: left;
                    width: 95px;
                    margin: 0px 0px 0px 0px;
                    height: 40px;
                    background: @gray-bg-color;
                    outline: none;
                    -webkit-appearance: inherit;
                  }
                  input[type='checkbox'].checkedInput {
                    float: left;
                    width: 95px;
                    margin: 0px 10px 0 0;
                    height: 40px;
                    background: @sky-start-dark;
                    outline: 0;
                    position: relative;
                    opacity: 0;
                  }
                  input[type='checkbox'].notCheckedInput + span {
                    background: @gray-bg-color;
                    color: @h1-color;
                  }
                  input[type='checkbox'].checkedInput + span {
                    background: @sky-start-dark;
                    color: @white-color;
                  }
                  input[type='checkbox'] + span {
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    line-height: 40px;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    font-size: 16px;
                  }
                }
                .delivery-section {
                  width: 63%;
                  float: right;
                  display: flex;
                  table {
                    border: solid 1px #000;
                    border-radius: 4px;
                    td {
                      input {
                        border: 0;
                        &:focus {
                          box-shadow: none;
                        }
                      }
                    }
                  }
                  timepicker input {
                    width: 100%;
                    float: left;
                    text-align: center;
                    padding: 7px 0 6px;
                    font-size: 14px;
                    color: @h1-color;
                    border: solid 1px @h1-color;
                  }
                  input {
                    width: 27%;
                    float: left;
                    text-align: center;
                    padding: 7px 0 6px;
                    font-size: 14px;
                    color: @h1-color;
                    border: solid 1px @h1-color;
                  }
                  span {
                    width: auto;
                    padding: 0 3px;
                    font-style: italic;
                    text-align: center;
                    margin-bottom: -1px;
                    float: left;
                    color: @gray-font-color;
                    font-size: 13px;
                    line-height: 39px;
                  }
                }
              }
              .jobs-div-row:first-child {
                padding-left: 0;
                margin-bottom: 5px;
                label {
                  width: 100%;
                  float: left;
                  font-weight: normal;
                  font-size: 14px;
                  margin-top: 0;
                  color: @gray-font-color;
                  /*input[type="checkbox"].notCheckedInput {
                    float: left;
                    width: 15px;
                    margin: 2px 10px 0 0;
                    height: 15px;
                    background: none;
                    border: 2px solid @sky-low-bright;
                    outline: none;
                    -webkit-appearance: inherit;
                    padding: 7px;
                    border-radius: 2px;
                  }
                  input[type="checkbox"].checkedInput {
                    float: left;
                    width: 15px;
                    margin: 2px 10px 0 0;
                    height: 15px;
                    background: none;
                    border: 2px solid @sky-start-dark;
                    outline: 0;
                    -webkit-appearance: inherit;
                    position: relative;
                    font: normal normal normal 14px/1 FontAwesome;
                    padding: 7px;
                    border-radius: 4px;
                    opacity: 1;
                  }
                  input[type="checkbox"].checkedInput:after {
                    content: '\f00c';
                    position: absolute;
                    top: 1px;
                    left: 1px;
                    font-size: 12px;
                    color: @sky-start-dark;
                  }*/
                }
              }
            }
          }
          .media-section-block:nth-child(2) {
            width: 45%;
            float: right;
            .contact-form {
              #full-width;
              margin-top: 7px;
              .contact-form-row {
                #full-width;
                margin-bottom: 20px;
                i {
                  width: 35px;
                  text-align: center;
                  float: left;
                  color: @sky-dark;
                  font-size: 20px;
                  line-height: 25px;
                }
                input {
                  width: 85%;
                  background: url(images/cross-icon.jpg) 95% center no-repeat;
                  float: right;
                  border: solid 1px @h1-color;
                  color: @h1-color;
                  padding: 0px 30px 0px 10px;
                  font-size: 14px;
                  position: relative;
                  background-image: none;
                  min-height: 25px;
                }
                input.pincode-input {
                  width: 30%;
                  float: left;
                  margin-left: 15%;
                  margin-top: 7px;
                  background-position: 90% center;
                  padding-right: 20px;
                }
                input.city-name-input {
                  width: 50%;
                  float: right;
                  margin-top: 7px;
                  background-position: 92% center;
                }
                span {
                  width: auto;
                  position: absolute;
                  right: 10px;
                  top: 5px;
                  color: @h1-color;
                }
              }
            }
          }
        }
        .calendar-section {
          #full-width;
          margin-top: 20px;
          padding-left: 15px;
          h4 {
            #full-width;
            font-size: 16px;
            color: @header-bg;
            margin-bottom: 15px;
          }
          p {
            #full-width;
            font-size: 14px;
            color: @post-block-color;
          }
          .lab-contact-calendar {
            #full-width;
            margin-top: 20px;
            .multiple-date-picker {
              width: 252px;
              box-shadow: 0 0 2px #eee;
              .picker-top-row {
                display: flex;
                align-items: center;
                border: solid 1px #ccc;
                padding: 0;
                p {
                  margin-bottom: 0;
                }
                .text-center.picker-month {
                  border-right: solid 1px #ccc;
                  border-left: solid 1px #ccc;
                  font-weight: bold;
                  font-size: 12px;
                  padding: 5px 0;
                }
              }
              .picker-days-week-row {
                .text-center {
                  font-weight: bold;
                  width: 36px;
                  font-size: 13px;
                }
              }
              .picker-days-row {
                .picker-day {
                  width: 36px;
                  height: 30px;
                  padding: 0;
                  font-size: 12px;
                  line-height: 30px;
                  border-radius: 4px;
                  cursor: pointer;
                  color: #333;
                  background-color: #fff;
                  border-color: #ccc;
                  &:hover {
                    color: #333;
                    background-color: #d4d4d4;
                    border-color: #8c8c8c;
                  }
                  &.future {
                    cursor: pointer;
                  }
                  &.past {
                    cursor: not-allowed;
                    opacity: 0.65;
                  }
                  &.picker-off:not(.past),
                  &.picker-selected {
                    color: #fff;
                    background-color: #ffb5b6;
                    border-color: #ffb5b6;
                  }
                }
              }
            }
          }
        }
      }
      .save-change-button {
        #full-width;
        button {
          width: auto;
          float: right;
          text-transform: uppercase;
          padding: 10px 15px;
          margin-top: 20px;
          color: @white-color;
          background: @sky-start-dark;
          border: 0px;
        }
        button.disableBtn {
          cursor: not-allowed;
          opacity: 0.8;
        }
      }
    }
    .notifications {
      float: left;
      padding-top: 40px;
      background: #eee;
      .profile-left-section {
        padding-top: 0;
        .head-profile {
          background: #fff;
          h2 {
            margin-top: 30px;
          }
        }
        .profile-form-left {
          #full-width;
          padding: 0 35px !important;
          .profile-form-left-col {
            #full-width;
            margin-top: 13px;
            .notifications-title {
              width: 100%;
              float: left;
              font-weight: bold;
              color: #105c9d;
            }
            .select-destination {
              font-size: 13px;
              color: #a7a2a2;
            }
            .user-email {
              #full-width;
              text-align: left;
              .checkbox-inline {
                padding-left: 0;
                width: 100%;
                font-size: 13px;
                p {
                  float: left;
                  margin-right: 8px;
                  position: relative;
                  &:after {
                    content: ',';
                    color: #000;
                    font-weight: bold;
                    width: 3px;
                    height: 0px;
                    position: absolute;
                    right: -4px;
                    bottom: 22px;
                  }
                  &:last-child {
                    &:after {
                      content: '';
                    }
                  }
                  &.email-noti-para {
                    width: 90%;
                    span {
                      float: left;
                      margin-right: 8px;
                      position: relative;
                      line-height: 16px;
                      &:after {
                        content: ',';
                        color: #000;
                        font-weight: bold;
                        width: 3px;
                        height: 0px;
                        position: absolute;
                        right: -4px;
                        bottom: 16px;
                      }
                      &:last-child {
                        &:after {
                          content: '';
                        }
                      }
                    }
                  }
                }
                input[type='radio']:not(:checked) {
                  float: left;
                  width: 15px;
                  margin: 2px 10px 0px 0px;
                  height: 15px;
                  background: none;
                  border: 2px solid #105c9d;
                  outline: none;
                  -webkit-appearance: inherit;
                  padding: 8px !important;
                  border-radius: 50%;
                  position: static;
                  cursor: pointer;
                }

                input[type='radio']:checked {
                  border: 2px solid #105c9d;
                }

                input[type='radio']:checked {
                  float: left;
                  width: 15px;
                  margin: 0px 10px 0px 0px;
                  height: 15px;
                  background: none;
                  border: 2px solid #105c9d;
                  outline: none;
                  cursor: pointer;
                  -webkit-appearance: inherit;
                  position: static;
                  font: normal normal normal 14px/1 FontAwesome;
                  padding: 8px !important;
                  border-radius: 50%;
                }

                input[type='radio']:checked:after {
                  content: '';
                  position: absolute;
                  top: 4px;
                  left: 4px;
                  font-size: 12px;
                  width: 12px;
                  height: 12px;
                  background: #105c9d;
                  border-radius: 50%;
                  color: #105c9d;
                }

                input[type='radio']:checked:after {
                  background: #105c9d;
                }
              }
            }
            .user-enter-email {
              .email-input {
                width: 85%;
              }
              .checkbox-inline {
                padding-left: 0;
                width: 100%;
                margin-left: 0;
                margin-top: 5px;
                font-size: 13px;
                input[type='radio']:not(:checked) {
                  float: left;
                  width: 15px;
                  margin: 0 10px 0 0;
                  height: 15px;
                  background: none;
                  border: 2px solid #105c9d;
                  outline: none;
                  -webkit-appearance: inherit;
                  padding: 8px;
                  border-radius: 50%;
                  position: static;
                  cursor: pointer;
                  min-height: inherit;
                }
                input[type='radio']:checked {
                  border: 2px solid #105c9d;
                }
                input[type='radio']:checked {
                  float: left;
                  width: 15px;
                  margin: 4px 10px 0 0;
                  height: 15px;
                  background: none;
                  border: 2px solid #105c9d;
                  outline: none;
                  cursor: pointer;
                  -webkit-appearance: inherit;
                  position: static;
                  font: normal normal normal 14px/1 FontAwesome;
                  padding: 8px;
                  border-radius: 50%;
                }
                input[type='radio']:checked:after {
                  content: '';
                  position: absolute;
                  top: 8px;
                  left: 4px;
                  font-size: 12px;
                  width: 12px;
                  height: 12px;
                  background: #105c9d;
                  border-radius: 50%;
                  color: #105c9d;
                }
                input[type='radio']:checked:after {
                  background: #105c9d;
                }
                input {
                  float: left;
                  width: auto;
                  text-align: left;
                  padding: 0 0 0 5px;
                  border: solid 2px #ccc;
                  color: #000;
                  min-height: 25px;
                }
              }
            }
          }
          .profile-form-left-Select {
            padding: 0;
            #full-width;
            margin-top: 24px;
            margin-bottom: 50px;
            .select-email {
              font-size: 13px;
              color: #a7a2a2;
            }
            .switch-button {
              #full-width;
              margin-bottom: 10px;
              .switch.checked {
                background: #105c9d;
                border-color: #105c9d;
                float: left;
                margin-right: 20px;
              }
              .switch {
                float: left;
                margin-right: 20px;
                background: #d9dadc;
                width: 42px;
                height: 22px;
                small {
                  width: 22px;
                  height: 22px;
                }
              }
              p {
                float: left;
                margin-bottom: 0;
                font-size: 13px;
                color: #a7a2a2;
              }
            }
          }
        }
      }
    }
    &.tabbing-section-add {
      ul.tabbing-ul {
        padding: 0;
        margin-bottom: 40px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        li {
          #full-width;
          a {
            #full-width;
            padding: 0 10px !important;
            background: #fff;
            text-align: center;
            color: #105c9d;
            line-height: 44px;
            height: 44px;
          }
          &:last-child {
            border-bottom: 0;
          }
          &.active {
            a {
              background: #000;
              color: @white-color;
            }
          }
          &.hover {
            opacity: 0.8;
          }
        }
      }
      .profile-right-section {
        width: 100%;
        .profile-right-block-two {
          margin-top: 0;
          padding: 45px 10px 30px;
          .head-profile {
            padding: 0;
          }
        }
      }
      .notifications {
        padding-top: 0;
      }
      .profile-left-section {
        .notifications {
          padding-top: 0;
          .head-profile {
            h2 {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

.delivery-section .ng-isolate-scope {
  width: 60px;
  float: left;
}

.delivery-section .ng-isolate-scope tr:first-child {
  display: none;
}

.delivery-section .ng-isolate-scope tr {
  border: 1px solid @h1-color;
}

.delivery-section .ng-isolate-scope tr:last-child {
  display: none;
}

.delivery-section .ng-isolate-scope tr td input {
  width: 28px !important;
  border: 0px !important;
  padding: 0px !important;
  line-height: 35px;
  min-height: 35px;
  height: 35px;
  border-radius: 0px;
  box-shadow: none;
}

.delivery-section .tooltip.ng-scope.ng-isolate-scope.top.file-name-tooltip.fade.in {
  width: 220px;
  margin-top: 3px;
  max-width: initial;
}

.delivery-section .tooltip-inner {
  background: #105c9d;
  max-width: initial;
}

.delivery-section .tooltip.top .tooltip-arrow,
.delivery-section .tooltip.top-left .tooltip-arrow,
.delivery-section .tooltip.top-right .tooltip-arrow {
  border-top-color: #105c9d;
}

.uib-day .btn.btn-info {
  background-color: inherit;
  border-color: inherit;
  color: inherit;
}

.uib-day.selected .btn {
  color: @white-color;
  background-color: @light-red-grey-tone;
  border-color: @light-red-grey-tone;
}

.uib-day.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
  color: @white-color !important;
  background-color: @header-bg !important;
  border-color: @header-bg !important;
}

.profile-form-left-Select.general-notifications {
  margin-bottom: 0px !important;
  .notifications-title {
    margin-bottom: 10px !important;
  }
}

.red-astrix-mark.invalid-email {
  margin-left: 40px;
  font-size: 11px;
  margin-bottom: 0;
}

.lh-0 {
  line-height: 0;
}

.m-0 {
  margin-bottom: 0;
}
.transport-service {
  margin-bottom: 15px;
  h2 {
    background: none !important;
  }
  .toggle-transport {
    display: flex;
    align-items: center;
    padding: 0;
    button {
      width: 52px;
      height: 22px;
      small {
        width: 22px;
        height: 22px;
      }
    }
  }
}
.action-list-category-section-block {
  width: 100%;
  float: left;
}
.action-list-category-section-block ul li {
  width: auto;
  float: left;
  padding: 5px 30px;
  margin: 5px;
  border: solid 1px #105c9d;
  color: #105c9d;
  font-weight: 600;
  cursor: pointer;
}

.lab-hex-code {
  width: 30% !important;
}

.lab-text-code {
  width: 40% !important;
}
