#connect-to-lab{
  #full-width; margin-top: 150px; margin-bottom: 20px;
  .connect-to-lab{
    #full-width;
    .search-box-lab{
      #full-width; padding: 0 3px; margin-bottom: 30px;
      .search-input{
        width: 82%; float: left; position: relative; background: #fff;
        input[type="search"]{
          width: 100%; float: right; padding:0 65px; border-radius: 3px; border: solid 1px @gray-light-white; height:55px; line-height: 55px; background: @white-color; box-shadow: 1px 2px 3px @gray-bg-color;
          &:focus{
            outline: none;
          }
        }
        i.fa.fa-search{
          font-size: 20px; left: 18px; top: 17px; position: absolute;
        }
        i.fa.fa-times{
          font-size: 20px; right: 18px; top: 17px; position: absolute;
        }

        tags-input {
          display: block;
          position: absolute;
          left: 50px;
          border: 0;
          box-shadow: inherit;
          width: 89%;
          top: 6px;

          .tags{
            border: 0;
            box-shadow: inherit;
            height: 100%;
          }
        }


      }
      input[type="button"]{
        width: auto; font-size: 18px; font-weight: bold; border-radius: 1px; padding: 0 38px; text-transform: uppercase; float: right; height:55px; line-height: 55px; border:0px; background: @sky-start-dark; color: @white-color; box-shadow: 1px 2px 3px @gray-bg-color;
      }
    }
    .lab-report-section{
      #full-width;
      .lab-report-head{
        #full-width; background: @sky-start-dark; padding: 7px 0px;
        .first-sec{
          width: 25%; font-weight: bold; float: left; border-right: solid 1px @white-color; text-align: center; font-size: 18px; color: @white-color;
          span{
            width: auto; display: inline-block;
            i{
              float: left; top: -3px; font-size: 15px; position: relative;
            }
            i+i{
              top: 5px; right:-1px;
            }
          }
        }
        .second-sec{
          width: 20%; font-weight: bold; float: left; border-right: solid 1px @white-color; text-align: center; font-size: 18px; color: @white-color;
        }
        .third-sec{
          width: 15%; font-weight: bold; float: left; border-right: solid 1px @white-color; text-align: center; font-size: 18px; color: @white-color;
        }
        .fourth-sec{
          width: 40%; font-weight: bold; float: left; text-align: center; font-size: 18px; color: @white-color;
          span{
            width: auto; display: inline-block;
            i{
              float: left; top: -3px; font-size: 15px; position: relative;
            }
            i+i{
              top: 5px; right:-3px;
            }
          }
        }
      }
      .lab-report-body{
        #full-width;
        .repeated-div-report{
          #full-width; background: @white-color; padding: 12px 0px; border-bottom: solid 1px @gray-d-line;
          width: 100%; float: left; cursor: pointer;
          .first-sec{
            width: 25%; float: left; text-align: center; color: @h1-color; font-weight: normal;
            .one-by-two{
              width: 50%; float: left; font-size: 13px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden; font-weight: normal;
              img{
                height: 40px; width:auto;
              }
              &.public-lab {
                width: 100% !important;
              }
            }
            .one-by-two+.one-by-two{
              margin-top:13px;
            }
          }
          .second-sec{
            width: 20%; float: left; text-align: center; font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-height: 16px;
            max-height: 48px;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            color: @h1-color; font-weight: normal;
          }
          .third-sec{
            width: 15%; float: left; text-align: center; font-size: 13px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden; margin-top:13px; padding:0 5px;
            color: @h1-color; font-weight: normal;
            p.third-sec-day-name{
              width: auto; float: none; margin-right: 5px; position: relative; display:inline;
              &:after{
                width: 2px; height: 2px; content: ','; position: absolute; right: -3px; bottom: 17px;
              }
            }
            p.third-sec-day-name:last-child{
              &:after{
                content: '';
              }
            }
          }
          .fourth-sec{
            width: 40%; float: left;
            .one-by-third{
              width: 33.33%; float: left; font-size: 13px;
              .one-by-third-one{
                #full-width; padding-right: 5px; margin-top: -2px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: @h1-color; font-weight: normal;
                input[type="button"]{
                  width: auto; float: right; text-transform: uppercase; margin-right: 7px; padding:0 8px; border:0px; height: 36px; line-height: 36px; font-size: 16px; font-weight: 400; margin-top:5px; color: @white-color; background: @sky-start-dark;
                }
                input[type="button"].redColor{
                  background: @delay-red-dark;
                }
                i{
                  color: @sky-start-dark; width: 15px;
                }
              }
              .one-by-third-one+.one-by-third-one{
                margin-top: 5px;
              }
            }
            &.public-lab-fourth{
              width: 75%;
              .one-by-third{
                text-align: right;
                padding-top: 1px;
                width: 26.5%;
                input[type="button"] {
                  padding: 0 25px !important;
                }
                &:nth-child(2){
                  text-align: left;
                }
                &:nth-child(1){
                  width: 47%;
                }
              }
            }
          }
        }
      }
    }
    .no-data-found{
      text-align: center;
    }
    #style-2::-webkit-scrollbar-track {
      border-radius: 7px;
      background-color: @white-color;
    }
    #style-2::-webkit-scrollbar {
      width: 7px;
      background-color: @white-color;
    }
    #style-2::-webkit-scrollbar-thumb {
      background-color: @h1-color;
      border-radius: 7px;
    }
  }
}


@media screen and ( min-height: 765px ) and ( max-height: 770px ){
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-body{ max-height: 460px;}
}
@media screen and ( min-height: 890px ) and ( max-height: 910px ){
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-body{ max-height: 600px;}
}
@media screen and ( min-height: 1040px ) and ( max-height: 1060px ){
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-body{ max-height: 740px;}
}
@media screen and ( min-height: 1070px ) and ( max-height: 1090px ){
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-body{ max-height: 760px;}
}
@media screen and ( min-height: 1190px ) and ( max-height: 1210px ){
  #connect-to-lab .connect-to-lab .lab-report-section .lab-report-body{ max-height: 880px;}
}