#section-page {
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 35px;
  padding: 18px 61px 50px;
}
#section-heading {
  float: left;
  font-size: 20px;
  color: #1f2532;
  font-weight: 700;
  border-bottom: solid 1px #1f2532;
  width: 100%;
  line-height: 50px;
}
#full-width {
  width: 100%;
  float: left;
}
#rate-it {
  /* Background */
  /* Selected state */
  /* Cancelation of hover styling */
  /* Hover styling */
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../../../images/rated-star.svg");
}
#rate-it .ngrateit-reset {
  background-image: url("../../../images/cancel-icon.svg");
}
#rate-it .ngrateit-bg-star {
  background-image: url("../../../images/bg-star.svg");
}
#rate-it .ngrateit-selected {
  background-image: url("../../../images/rated-star.svg");
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating span:hover ~ span {
  background-image: url("../../../images/bg-star.svg");
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../../../images/rated-star.svg");
}
input:-webkit-autofill {
  background: #fff !important;
  background-color: #fff !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
#account-setting {
  width: 100%;
  float: left;
  margin-top: 200px;
  margin-bottom: 20px;
}
#account-setting .account-setting {
  width: 100%;
  float: left;
}
#account-setting .account-setting .profile-left-section {
  width: 100%;
  float: left;
  background: #fff;
  padding-top: 0px;
}
#account-setting .account-setting .profile-left-section .profile-form-left {
  width: 100%;
  float: left;
  padding-top: 0px;
}
#account-setting .account-setting .profile-left-section .profile-form-left .profile-form-left-row {
  width: 100%;
  float: left;
  margin-bottom: 37px;
  padding: 0 35px;
}
#account-setting .account-setting .profile-left-section .profile-form-left .profile-form-left-row .profile-form-left-col {
  width: 43%;
  float: left;
}
#account-setting .account-setting .profile-left-section .profile-form-left .profile-form-left-row .profile-form-left-col label {
  color: #105c9d;
  margin-bottom: 10px;
  float: left;
}
#account-setting .account-setting .profile-left-section .profile-form-left .profile-form-left-row .profile-form-left-col input {
  width: 100%;
  float: left;
  padding: 5px 10px;
  font-size: 15px;
  text-align: center;
  border: solid 1px #979797;
}
#account-setting .account-setting .profile-left-section .profile-form-left .profile-form-left-row .profile-form-left-col:nth-child(2n) {
  float: right;
}
#account-setting .account-setting .profile-left-section .profile-form-left .profile-form-left-row.change-pass-div .profile-form-left-col {
  /*input{
              width: 58%; border: 0px;
            }*/
}
#account-setting .account-setting .profile-left-section .profile-form-left .profile-form-left-row.change-pass-div .profile-form-left-col label {
  line-height: 34px;
}
#account-setting .account-setting .profile-left-section .profile-form-left .profile-form-left-row.change-pass-div .profile-form-left-col {
  margin-top: -10px;
}
#account-setting .account-setting .profile-left-section .profile-form-left .profile-form-left-row.change-pass-div .profile-form-left-col input[type="button"] {
  color: #fff;
  background: #105c9d;
  font-size: 20px;
  width: 100%;
  text-transform: uppercase;
  white-space: inherit;
  line-height: 22px;
  font-weight: 500;
}
#account-setting .account-setting .profile-left-section .profile-form-left .profile-form-left-row.change-pass-div .profile-form-left-col input[type="button"]:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
#account-setting .account-setting .profile-left-section .profile-form-left .profile-form-left-row.change-pass-div .profile-form-left-col:nth-child(2) input[type="button"] {
  background: url(../../../images/bankid_vector_rgb.svg) 94% 3px no-repeat, #105c9d;
  background-size: 30px;
}
#account-setting .account-setting .profile-left-section .profile-form-left .change-profile-picture {
  width: 100%;
  float: left;
  position: relative;
  padding-bottom: 80px;
}
#account-setting .account-setting .profile-left-section .profile-form-left .change-profile-picture img {
  width: 100%;
  width: 28%;
  float: left;
  margin-left: 36%;
}
#account-setting .account-setting .profile-left-section .profile-form-left .change-profile-picture input[type="button"] {
  color: #fff;
  background: #105c9d;
  font-size: 20px;
  text-transform: uppercase;
  white-space: inherit;
  line-height: 35px;
  width: auto;
  padding: 8px 15px;
  border: 0;
  font-weight: 500;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, 0);
}
#account-setting .account-setting .profile-right-section {
  width: 97%;
  float: right;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one {
  width: 100%;
  float: left;
  background: #fff;
  padding: 45px 35px 65px;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .head-profile {
  width: 100%;
  float: left;
  padding: 0 35px;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .head-profile h2 {
  width: 100%;
  float: left;
  text-align: center;
  background: url(images/border-head-h2.png) 0 center repeat-x;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .head-profile h2 span {
  width: auto;
  padding: 0 28px;
  background: #fff;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 300;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right {
  width: 100%;
  float: left;
  text-align: center;
  display: inline-block;
  margin-top: 25px;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row {
  width: 100%;
  float: none;
  text-align: center;
  display: inline-block;
  margin-bottom: 63px;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col {
  width: auto;
  float: left;
  margin: 0 3%;
  max-width: 27%;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col label {
  width: 100%;
  color: #105c9d;
  margin-bottom: 10px;
  float: left;
  text-align: left;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col input {
  width: 100%;
  float: left;
  padding: 5px 10px;
  font-size: 15px;
  text-align: center;
  border: solid 1px #979797;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col select {
  width: 100%;
  float: left;
  padding: 7px 10px;
  font-size: 15px;
  text-align: center;
  border: 0px;
  background: #f4f4f4;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col select:focus {
  outline: none;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col .ui-select {
  min-width: 300px;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col .ui-select-container.select2.select2-container {
  cursor: pointer;
  min-width: inherit !important;
  width: 100%;
  float: left;
  position: relative;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col .ui-select-container.select2.select2-container a.select2-choice.ui-select-match {
  border: solid 1px #979797;
  width: 100%;
  float: left;
  padding: 4px 0;
  color: #979797;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col .ui-select-container.select2.select2-container a.select2-choice.ui-select-match i.fa.fa-angle-down {
  float: right;
  padding-top: 5px;
  padding-right: 10px;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col .ui-select-container.select2.select2-container .ui-select-dropdown.select2-drop {
  float: left;
  width: 100%;
  border: 1px solid #979797;
  border-top: 0;
  position: absolute;
  left: 0;
  top: 36px;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col .ui-select-container.select2.select2-container .ui-select-dropdown.select2-drop ul {
  background: #fff;
  border-top: solid 0.1px #979797;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col .ui-select-container.select2.select2-container .ui-select-dropdown.select2-drop .search-container.select2-search {
  display: none;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.archive-order {
  max-width: 100%;
  margin-top: -70px;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.archive-order input {
  width: 15%;
  margin-right: 10px;
  float: left;
  padding: 0;
  text-align: center;
  font-weight: 700;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.archive-order span {
  font-size: 13px;
  float: left;
  margin-top: 4px;
  color: #a5a3a3;
  margin-right: 15px;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.archive-order p {
  width: 100%;
  margin-left: 7%;
  text-align: left;
  font-size: 13px;
  line-height: 16px;
  margin-right: 28%;
  color: #a5a3a3;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.view-settings {
  width: 100% !important;
  max-width: none;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.view-settings .switch-button {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.view-settings .switch-button .switch.checked {
  background: #105c9d;
  border-color: #105c9d;
  float: left;
  margin-right: 20px;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.view-settings .switch-button .switch {
  float: left;
  margin-right: 20px;
  background: #d9dadc;
  width: 42px;
  height: 22px;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.view-settings .switch-button .switch small {
  width: 22px;
  height: 22px;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.view-settings .switch-button p {
  float: left;
  margin-bottom: 0;
  font-size: 13px;
  color: #a7a2a2;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.view-settings .switch-button input {
  float: left;
  margin-right: 10px;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row .profile-form-right-col.view-settings .switch-button span {
  float: left;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row.change-pass-div {
  margin-bottom: 10px;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row.change-pass-div .profile-form-right-col label {
  line-height: 29px;
  width: auto;
  float: left;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row.change-pass-div .profile-form-right-col input {
  width: 50%;
  border: 0px;
  float: right;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row.change-pass-div .profile-form-right-col:nth-child(2) {
  margin-top: -10px;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right .profile-form-right-row.change-pass-div .profile-form-right-col:nth-child(2) input[type="button"] {
  color: #fff;
  background: #105c9d;
  font-size: 20px;
  width: 100%;
  text-transform: uppercase;
  white-space: inherit;
  line-height: 22px;
  font-weight: 500;
}
#account-setting .account-setting .profile-right-section .profile-right-block-one .profile-form-right span.version-number {
  display: inline-block;
  color: #979797;
  margin-left: 15px;
  font-size: 14px;
}
#account-setting .account-setting .profile-right-section .save-change-btn {
  width: 100%;
  float: left;
  margin-top: 20px;
}
#account-setting .account-setting .profile-right-section .save-change-btn input[type="button"] {
  color: #fff;
  background: #105c9d;
  font-size: 20px;
  width: 100%;
  text-transform: uppercase;
  white-space: inherit;
  line-height: 35px;
  font-weight: 500;
  width: auto;
  float: right;
  border: 0px;
  padding: 8px 25px;
}
.display-inline {
  display: inline;
}
.profile-form-left-col.general-notifications .notifications-title {
  margin-bottom: 10px !important;
}
.profile-form-left-col.general-notifications form .switch-button input {
  width: 8% !important;
  margin-left: 5px;
  height: 25px;
  padding: 5px;
}
.profile-form-left-col.general-notifications form span {
  font-size: 13px;
  line-height: 1.7;
  color: #a5a3a3;
}
.bankId-status {
  text-align: center;
}
