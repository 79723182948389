:root {
  --radio-color: #2cba92;
}

.mr-18 {
  margin-right: 18px !important;
}

order-new-2 > .row {
  max-width: 1300px;
  margin: auto;
}

#patient-section.order-two-part .SearchPatient ul {
  width: 100%;
}
#patient-section.order-two-part .SearchPatient ul li {
  width: 30%;
  margin-right: 3%;
  text-align: left;
}
#patient-section.order-two-part .SearchPatient ul.four-column li {
  width: 22%;
}
#patient-section.order-two-part .SearchPatient ul.five-column li {
  width: 17%;
}
#patient-section.order-two-part .SearchPatient ul li .dropdown {
  margin-left: 0;
}
#patient-section.order-two-part .SearchPatient ul li .dropdown .btn.form-control {
  height: 32px;
  box-shadow: none;
  border-radius: 0;
}
#patient-section.order-two-part .SearchPatient ul li label {
  margin-bottom: -6px;
}
#patient-section.order-two-part .SearchPatient ul li .dropdown ul.dropdown-menu {
  min-width: 183px !important;
  margin: 0;
}
#patient-section.order-two-part
  .SearchPatient
  ul
  li
  .dropdown
  ul
  li.ui-select-choices-group {
  width: 100%;
  margin: 0;
}

.next-step-btn {
  width: 100%;
  float: left;
  padding: 40px 106px 10px;
}
.next-step-btn .step-button {
  width: 220px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: none;
  line-height: 43px;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  margin-right: 0;
  padding: 0 20px;
  background: @sky-start-dark;
}
.next-step-btn .step-button i {
  font-size: 24px;
  font-weight: normal;
}
.previous-step-btn.step-button {
  float: left;
}

.next-step-btn .step-button.disabled {
  border-color: @dark-grey;
  color: @dark-grey;
  opacity: 0.7;
  cursor: not-allowed;
}

#images-section.order-two-part .upload-images h3.patientTitle {
  padding-left: 12%;
}

#order-list.order-two-part .order-list {
  margin-top: 15px;
}
#order-list.order-two-part .order-list .order-list-box .order-list-content {
  cursor: default;
}
#order-list.order-two-part .order-list .order-list-hading .col-lg-2,
#order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content
  .col-lg-2 {
  width: 16.66666667%;
}
#order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content
  .mobi-manage-content
  .font-manage {
  padding-top: 13px;
}
#order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content
  .mobi-manage-content:first-child
  .font-manage {
  padding-top: 0;
}
#order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content
  .mobi-manage-content
  .font-manage
  label.checkbox-style {
  width: auto;
  margin-top: 10px;
}
.permissions-div label.checkbox-style {
  margin-top: 0 !important;
}
#order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content
  .mobi-manage-content
  .font-manage
  img {
  width: auto;
  float: right;
  max-height: 70px;
  max-width: 70%;
  margin-top: 3px;
}
.permissions-edit-role button {
  margin: 3px !important;
}

#sign-section .sign-send {
  float: left;
  width: 100%;
  background: @white-color;
  border-radius: 4px;
  margin-top: 35px;
  padding: 18px 61px 50px;
}
#sign-section .sign-send .confirm-info-sign-send h3 {
  float: left;
  font-size: 18px;
  color: @h1-color;
  font-weight: 700;
  width: 100%;
  line-height: 70px;
}
#sign-section .sign-send .confirm-info-sign-send h2 {
  float: left;
  font-size: 20px;
  color: #1f2532;
  font-weight: 800;
  border-bottom: solid 2px #1f2532;
  width: 100%;
  line-height: 30px;
  padding: 0 15px;
}
#sign-section .sign-send .confirm-info-sign-send p {
  font-weight: 400;
  margin: 6px 0 0;
  float: left;
  width: 100%;
  line-height: 20px;
  color: @h1-color;
  padding: 0 15px;
}
#sign-section .sign-send .confirm-info-sign-send p label,
#sign-section
  .sign-send
  .confirm-info-sign-send
  .teeth-construction-action
  .action-on-teeth
  .action-text
  label,
#sign-section.order-details-2 .sign-send .confirm-info-sign-send p label {
  font-weight: 500;
  color: @gray-font-color;
  width: 40%;
  margin-bottom: 2px;
  float: left;
  min-width: 40%;
}
#sign-section .sign-send .confirm-info-sign-send p label span,
#sign-section.order-details-2
  .sign-send
  .confirm-info-sign-send
  p
  label
  span
  .red-astrix-mark {
  color: @delay-red-dark !important;
}
#sign-section
  .sign-send
  .confirm-info-sign-send
  p
  label.remove-parent-astrick-color
  span {
  color: #000 !important;
}
#sign-section
  .sign-send
  .confirm-info-sign-send
  p
  label.remove-parent-astrick-color
  span.red-color {
  color: @delay-red-dark !important;
}
#sign-section.order-details-2
  .sign-send
  .confirm-info-sign-send
  p
  label.handle-lab-request {
  width: 39%;
  min-width: 39%;
  margin-right: 5px;
}
#sign-section
  .sign-send
  .confirm-info-sign-send
  .teeth-construction-action
  .action-on-teeth
  p.comment-p
  label {
  font-weight: normal;
}
.aside-area {
  width: 60%;
  float: left;
  label.checkbox-style {
    width: 100% !important;
    display: flex;
    label.check-radio-color {
      width: auto !important;
      margin-right: 10px;
      min-width: 52% !important;
    }
    span {
      line-height: 25px;
      color: #000;
      &.date-span {
        margin-left: auto;
        &.sign-send-date-span {
          margin-left: 0;
        }
      }
      &:after {
        content: '';
      }
    }
  }
  span {
    position: relative;
    padding-right: 10px;
    float: left;
    &:after {
      position: absolute;
      right: 5px;
      bottom: 0;
      content: ',';
    }
    &:last-child {
      &:after {
        content: '';
      }
    }
    span {
      padding-right: 0;
    }
  }
}

p.comment-p .aside-area {
  width: 90%;
  float: left;
  text-align: justify;
}
p.comment-p.steps-manage-area {
  width: 50% !important;
  display: block !important;
  min-width: 50% !important;
}
p.comment-p.steps-manage-area label {
  width: 100% !important;
  display: flex !important;
}
p.comment-p.steps-manage-area .aside-area {
  width: 100% !important;
  display: block;
}
.mt-20 {
  margin-top: 20px;
}
.mt-35 {
  margin-top: 35px;
}
#sign-section .sign-send .confirm-info-sign-send h4 {
  float: left;
  font-size: 20px;
  color: @h1-color;
  font-weight: 800;
  border-bottom: solid 2px @sky-start-dark;
  width: 100%;
  line-height: 30px;
  padding: 0 15px;
  cursor: pointer;
}
#sign-section .sign-send .confirm-info-sign-send h4.single-crown-h4 {
  border-bottom: solid 2px @single-crown;
}
#sign-section .sign-send .confirm-info-sign-send h4.bridge-h4 {
  border-bottom: solid 2px @bridge;
}
#sign-section .sign-send .confirm-info-sign-send h4.post-h4 {
  border-bottom: solid 2px @post;
}
#sign-section .sign-send .confirm-info-sign-send h4.implant-crown-h4 {
  border-bottom: solid 2px @implant-crown;
}
#sign-section .sign-send .confirm-info-sign-send h4.implant-bridge-h4 {
  border-bottom: solid 2px @implant-bridge;
}
#sign-section .sign-send .confirm-info-sign-send h4.partial-denture-h4 {
  border-bottom: solid 2px @partial-denture;
}
#sign-section .sign-send .confirm-info-sign-send h4.temporary-denture-h4 {
  border-bottom: solid 2px @temporary-denture;
}
#sign-section .sign-send .confirm-info-sign-send h4.complete-denture-h4 {
  border-bottom: solid 2px @complete-denture;
}
#sign-section .sign-send .confirm-info-sign-send h4.bitetray-h4 {
  border-bottom: solid 2px @bitetray;
}
#sign-section .sign-send .confirm-info-sign-send h4.other-h4 {
  border-bottom: solid 2px @other;
}
#sign-section
  .sign-send
  .confirm-info-sign-send
  .teeth-construction-action
  .action-on-teeth
  p {
  width: 50%;
  float: left;
}
#sign-section
  .sign-send
  .confirm-info-sign-send
  .teeth-construction-action
  .action-on-teeth
  p
  i {
  min-width: 22px;
  height: 22px;
  text-align: center;
  line-height: 20px;
  border: solid 2px #105c9d;
  border-radius: 50%;
  margin-left: -29px;
  color: #105c9d;
  cursor: pointer;
  margin-top: -3px;
}
#sign-section
  .sign-send
  .confirm-info-sign-send
  .teeth-construction-action
  .action-on-teeth
  p.comment-p {
  width: 100%;
  margin-top: 25px;
}

div.comment-p {
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
}
#sign-section
  .sign-send
  .confirm-info-sign-send
  .teeth-construction-action
  .action-on-teeth
  .action-text {
  display: flex;
  width: 100%;
  float: left;
  padding: 15px 15px 0;
}
#sign-section
  .sign-send
  .confirm-info-sign-send
  .teeth-construction-action
  .action-on-teeth
  .action-text
  .action-perform-text
  span
  strong,
#sign-section
  .sign-send
  .confirm-info-sign-send
  .teeth-construction-action
  .action-on-teeth
  .action-text
  label {
  color: @dark-blue;
  font-weight: 800;
  width: 19%;
  min-width: 19%;
}
#sign-section
  .sign-send
  .confirm-info-sign-send
  .teeth-construction-action
  .action-on-teeth
  .action-text
  .action-perform-text {
  width: 81%;
  float: left;
}
#sign-section
  .sign-send
  .confirm-info-sign-send
  .teeth-construction-action
  .action-on-teeth
  p.comment-p
  label {
  width: 80px;
  min-width: inherit;
}
#sign-section
  .sign-send
  .confirm-info-sign-send
  .teeth-construction-action
  .action-on-teeth
  .action-text
  .action-perform-text
  span {
  width: 100%;
  float: left;
  font-weight: normal;
}
#sign-section .sign-send .files-box {
  width: 100%;
  float: left;
  text-align: center;
}
#sign-section .sign-send .files-box img {
  width: auto;
  height: 100px;
  float: none;
  display: inline-block;
  margin: 0;
}
#sign-section .sign-send .files-box .file-icon {
  margin: 0;
  display: inline-block;
  text-align: center;
  float: none;
}
#sign-section .sign-send .files-box .file-icon i {
  font-size: 100px;
}
#sign-section .sign-send .files-box h3 {
  width: 100%;
  float: left;
  margin: 12px 0 0;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
}
#sign-section .sign-send .confirm-info-sign-send .sending-box,
#sign-section .signSend .confirm-info-sign-send .sending-box {
  width: 100%;
  float: left;
  display: inline-block;
  text-align: center;
  margin: 50px 0 20px;
}
#sign-section .sign-send .confirm-info-sign-send .sending-box label,
#sign-section .signSend .confirm-info-sign-send .sending-box label {
  width: 100%;
  font-size: 20px;
  display: inline-block;
  color: @sky-start-dark;
}
#sign-section .sign-send .confirm-info-sign-send .sending-box p {
  width: 100%;
  display: inline-block;
  color: @sky-start-dark;
  line-height: 40px;
  font-size: 20px;
  font-weight: bold;
}

#sign-section
  .sign-send
  .confirm-info-sign-send
  .sending-box
  p
  input[type='checkbox']:not(:checked) {
  margin-left: 10px;
  border: 1px solid @sky-start-dark;
  outline: none;
  -webkit-appearance: inherit;
  padding: 3px 15px;
  position: static;
  cursor: pointer;
  display: inline;
}
#order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content
  .font-manage
  input[type='radio']:not(:checked) {
  margin-right: 10px;
  border: 1px solid @sky-start-dark;
  outline: none;
  -webkit-appearance: inherit;
  padding: 6px 15px 5px;
  position: static;
  cursor: pointer;
  display: inline;
  float: left;
  height: 32px;
}

#sign-section
  .sign-send
  .confirm-info-sign-send
  .sending-box
  p
  input[type='checkbox']:checked {
  margin-left: 10px;
  border: 1px solid @sky-start-dark;
  outline: none;
  cursor: pointer;
  -webkit-appearance: inherit;
  position: relative;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 8px 15px;
  display: inline;
  background: @sky-start-dark;
}
#order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content
  .font-manage
  input[type='radio']:checked {
  margin-right: 10px;
  border: 1px solid @sky-start-dark;
  outline: none;
  cursor: pointer;
  -webkit-appearance: inherit;
  position: relative;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 8px 15px;
  display: inline;
  background: @sky-start-dark;
  float: left;
  height: 32px;
}
#sign-section
  .sign-send
  .confirm-info-sign-send
  .sending-box
  p
  input[type='checkbox']:checked:after {
  content: '\f00c';
  position: absolute;
  top: 7px;
  left: 7px;
  font-size: 16px;
  color: @white-color;
}
#order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content
  .font-manage
  input[type='radio']:checked:after {
  content: '\f00c';
  position: absolute;
  top: 7px;
  left: 7px;
  font-size: 16px;
  color: @white-color;
}

#patient-section.order-two-part .SearchPatient ul li:first-child input.form-control {
  text-align: left;
}
#patient-section.order-two-part span.check-icons i {
  top: 36px;
  right: 8px;
  padding: 3px 0 0 3px;
}

.several-options {
  width: 100%;
  float: left;
  margin: 20px 0 30px;
  .search-material-actions.dropdown {
    margin-left: 0;
    input.full-width {
      background: #e6e6e6;
      color: #333;
      border: 0;
      width: 100%;
      cursor: pointer;
      float: left;
      line-height: 32px;
      padding: 6px;
      border-radius: 4px;
      &:focus {
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      }
      &:hover {
        color: #333;
        background-color: #e6e6e6;
        border-color: #adadad;
      }
      + i {
        position: absolute;
        right: 20px;
        top: 15px;
        color: #fff;
      }
    }
    .option-materials-actions.dropdown-menu.full-width {
      padding: 0;
      margin-top: 45px;
      ul {
        width: 100%;
        padding: 0;
        margin: 0;
        background: @light-grey;
        border-radius: 0;
        max-height: 40vh;
        z-index: 10;
        float: left;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar-track {
          background-color: @dark-grey;
        }
        &::-webkit-scrollbar {
          width: 6px;
          background-color: @dark-grey;
        }
        &::-webkit-scrollbar-thumb {
          background-color: @sky-start-dark;
        }
        li {
          text-align: left;
          cursor: pointer;
          border-bottom: solid 1px @dark-grey;
          padding: 3px 20px;
          float: left;
          width: 100%;
          label {
            cursor: pointer;
            margin-bottom: 0 !important;
            p {
              margin-bottom: 0;
            }
          }
          .checkbox {
            cursor: pointer;
            pointer-events: none;
            margin: 0;
            label {
              display: flex;
              align-items: center;
              padding: 0;
              input[type='checkbox']:not(:checked) {
                margin: 0 20px 0 0;
                border: 1px solid @dark-grey;
                outline: none;
                -webkit-appearance: inherit;
                padding: 10px;
                position: static;
                cursor: pointer;
                display: inline;
                border-radius: 3px;
                background: @white-color;
              }
              input[type='checkbox']:checked {
                margin: 0 20px 0 0;
                border: 1px solid @sky-start-dark;
                outline: none;
                cursor: pointer;
                -webkit-appearance: inherit;
                position: relative;
                font: normal normal normal 14px/1 FontAwesome;
                padding: 10px;
                display: inline;
                background: @sky-start-dark;
                border-radius: 3px;
              }
              input[type='checkbox']:checked:after {
                content: '\f00c';
                position: absolute;
                top: 5px;
                left: 5px;
                font-size: 12px;
                color: @white-color;
              }
            }
          }
          &:hover {
            color: @sky-start-dark;
          }
          &:focus {
            outline: none;
          }
          &.active {
            a {
              background: @dark-grey;
              color: @dark-blue;
            }
          }
        }
      }
    }
  }

  .multiselect-parent {
    width: 100%;
    input {
      width: 100%;
      background: @dark-grey;
      border-radius: 0;
      box-shadow: none;
      white-space: inherit;
      .caret {
        float: right;
        margin-top: 10px;
      }
    }
    ul.dropdown-menu.dropdown-menu-form {
      width: 100%;
      padding: 0;
      margin: 0;
      background: @light-grey;
      border-radius: 0;
      max-height: 40vh;
      z-index: 10;
      &::-webkit-scrollbar-track {
        background-color: @dark-grey;
      }
      &::-webkit-scrollbar {
        width: 6px;
        background-color: @dark-grey;
      }
      &::-webkit-scrollbar-thumb {
        background-color: @sky-start-dark;
      }
      li {
        text-align: left;
        cursor: pointer;
        border-bottom: solid 1px @dark-grey;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          display: none;
        }
        a {
          white-space: inherit;
          .checkbox {
            margin: 0;
            label {
              display: flex;
              align-items: center;
              padding: 0;
              input[type='checkbox']:not(:checked) {
                margin: 0 20px 0 0;
                border: 1px solid @dark-grey;
                outline: none;
                -webkit-appearance: inherit;
                padding: 10px;
                position: static;
                cursor: pointer;
                display: inline;
                border-radius: 3px;
                background: @white-color;
              }
              input[type='checkbox']:checked {
                margin: 0 20px 0 0;
                border: 1px solid @sky-start-dark;
                outline: none;
                cursor: pointer;
                -webkit-appearance: inherit;
                position: relative;
                font: normal normal normal 14px/1 FontAwesome;
                padding: 10px;
                display: inline;
                background: @sky-start-dark;
                border-radius: 3px;
              }
              input[type='checkbox']:checked:after {
                content: '\f00c';
                position: absolute;
                top: 5px;
                left: 5px;
                font-size: 12px;
                color: @white-color;
              }
            }
          }
          &:hover {
            color: @sky-start-dark;
          }
          &:focus {
            outline: none;
          }
        }
        &.active {
          a {
            background: @dark-grey;
            color: @dark-blue;
          }
        }
      }
    }
  }
  button {
    background: @sky-start-dark;
    color: @white-color;
    border: 0;
    width: 100%;
    float: left;
    line-height: 32px;
  }
}
.reason-exception {
  width: 100%;
  float: left;
  padding: 50px 150px 10px;
  p.red-astrix-mark {
    &.textarea-bottom {
      display: inline-block;
      padding: 0;
      color: @delay-red-dark;
      text-align: right;
      width: 100%;
      margin: -40px 0;
    }
  }
  h4 {
    color: @dark-blue;
    font-weight: 700;
    margin-bottom: 10px;
  }
  ul {
    width: 100%;
    float: left;
    margin-top: 10px;
    padding-left: 10px;
    li {
      width: auto;
      float: left;
      padding: 2px 25px;
      border: solid 2px @sky-start-dark;
      color: @sky-start-dark;
      font-weight: 600;
      margin-right: 40px;
      margin-bottom: 20px;
      cursor: pointer;
      &.active {
        background: #105c9d;
        color: #fff;
      }
    }
  }
}
.actions-content {
  li {
    font-weight: 500;
  }
}
.build-construction {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  color: @dark-blue;
  background: @light-grey;
  padding: 5px 10px;
  margin-top: 30px;
  margin-bottom: 20px;
  cursor: pointer;
  span.count-construction {
    width: 40px;
    font-size: 30px;
    font-weight: 400;
    text-align: center;
  }
  .construction-content {
    width: 100%;
    float: left;
    text-align: center;
    h3 {
      font-size: 16px;
      font-weight: 700;
    }
    h4 {
      font-size: 14px;
      font-weight: 400;
      margin-top: 3px;
      span {
        position: relative;
        padding-right: 7px;
        &:after {
          position: absolute;
          right: 3px;
          top: 0;
          content: ',';
        }
        &:last-child {
          &:after {
            content: '';
          }
        }
      }
    }
  }
  &.single-crown {
    background: @single-crown;
  }
  &.bridge {
    background: @bridge;
  }
  &.implant-crown {
    background: @implant-crown;
  }
  &.other {
    background: @other;
  }
  &.post {
    background: @post;
  }
  &.implant-bridge {
    background: @implant-bridge;
  }
  &.complete-denture {
    background: @complete-denture;
  }
  &.partial-denture {
    background: @partial-denture;
  }
  &.temporary-denture {
    background: @temporary-denture;
  }
  &.other-removable {
    background: @other;
  }
  &.bitetray {
    background: @bitetray;
  }
}

.color-sampling-radio {
  label.checkbox-style {
    input[type='radio'] {
      &:checked + label {
        &:before {
          background: var(--radio-color) !important;
        }
        &:after {
          border: 2px solid var(--radio-color) !important;
        }
      }
    }
  }
}

.multiselect-parent {
  ul.dropdown-menu.dropdown-menu-form {
    li {
      a {
        width: 100%;
        float: left;
        .checkbox {
          label {
            width: 100%;
            float: left;
            font-weight: normal;
            font-size: 14px;
            position: relative;

            input[type='checkbox'] {
              position: absolute !important;
              top: 0;
              left: 0;
              z-index: 10;
              opacity: 0;
            }

            input[type='checkbox'] + span {
              position: relative;
              padding-left: 30px;
              line-height: 23px;
              float: left;
            }

            input[type='checkbox'] + span:after {
              width: 23px;
              height: 23px;
              content: '';
              border: solid 2px @post-block-color;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 9;
              border-radius: 3px;
            }

            input[type='checkbox']:checked + span:after {
              border-color: @sky-dark;
            }

            input[type='checkbox']:checked + span:before {
              content: '\2714';
              font-size: 14px;
              text-align: center;
              color: #fff !important;
              background: @sky-dark;
              width: 23px;
              height: 23px;
              position: absolute;
              left: 0;
              top: 0;
              border-radius: 3px;
              z-index: 10;
            }
          }
        }
      }
    }
  }
}

#order-list.order-two-part .order-list .order-list-hading i {
  margin-left: 0;
}

.ui-select-bootstrap .ui-select-choices-row.active > span {
  background: @sky-start-dark;
}

.ui-select-container.ui-select-bootstrap.dropdown .ui-select-match {
  display: block !important;
}
.ui-select-container.ui-select-bootstrap.dropdown input.ui-select-search {
  display: none;
}

.extra-mrn-btn button {
  margin-top: 60px;
}

@media (max-width: 767px) {
  .next-step-btn {
    padding: 15px 0;
  }
  #sign-section {
    .container {
      padding: 0;
      .sign-send {
        border-radius: 0;
        margin-top: 0;
        padding: 10px 0 10px;
      }
    }
  }
}

#order-list.order-two-part {
  min-height: inherit !important;
}
.full-div {
  width: 100% !important;
}

#order-list.order-two-part .order-list .order-list-box.first-child-bg {
  background: @sky-low-dark;
}
#order-list.order-two-part
  .order-list
  .order-list-box.first-child-bg
  .order-list-content {
  background: none;
}
#order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content.active-lis {
  background: @light-grey;
}

#images-section.order-two-part .upload-images h3.patientTitle {
  padding-left: 0;
}

#images-section .images-upload {
  width: 100%;
  margin-left: 0;
}

.manage-flex-boxes {
  /*display: flex;
  flex: 1;
  flex-wrap: wrap;*/
}

.manage-flex-boxes .color-sampling-radio h3 {
  margin-bottom: 15px;
}

.action-units-number {
  width: 100%;
  float: left;
  margin: 15px 0;
  cursor: pointer;
}
.action-units-number i {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  float: left;
  border: solid 1px #eee;
  font-size: 14px;
  background: #eee;
  color: #000;
}
.action-units-number input {
  width: 60px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  float: left;
  border: solid 1px #eee;
  border-left: 0;
  border-right: 0;
  font-size: 12px;
  background: none;
}
.action-units-number input:focus {
  outline: none;
}
.text-right.right-sec-head i {
  color: #fff;
  line-height: 28px;
  cursor: pointer;
  width: 28px;
  text-align: center;
}

#sub-category-modal
  .modal-dialog
  .modal-content
  .modal-body
  .add-clinic-form.select-content
  input#hex {
  width: 100px;
}
#add-action-modal
  .modal-dialog
  .modal-content
  .modal-body
  .cat-action-perform
  .left-cat-section
  .cat-division.inline-content {
  display: flex;
}
#add-action-modal
  .modal-dialog
  .modal-content
  .modal-body
  .cat-action-perform
  .left-cat-section
  .cat-division.inline-content
  label {
  line-height: 23px;
}
#add-action-modal
  .modal-dialog
  .modal-content
  .modal-body
  .cat-action-perform
  .left-cat-section
  .cat-division
  .checkbox-style {
  width: 25px;
}
#add-action-modal
  .modal-dialog
  .modal-content
  .modal-body
  .cat-action-perform
  .left-cat-section
  .cat-division
  .checkbox-style
  label:before {
  z-index: 8;
  line-height: 25px;
}
#add-action-modal
  .modal-dialog
  .modal-content
  .modal-body
  .cat-action-perform
  .left-cat-section
  .cat-division.inline-content
  input {
  width: 100%;
  height: 100%;
}

#bridgeTeeth.order-2-construction-section {
  position: relative;
}

.half-section-fill {
  height: 16px;
  float: left;
  position: absolute !important;
  left: 0;
  bottom: 0;
  cursor: pointer;
}
.marginBottom .half-section-fill {
  bottom: 23px;
}

#manage-labs
  .manage-labs
  #order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content.tag-header {
  background-color: #ccc;
  padding: 0;
  min-height: 36px;
  font-weight: 500;
  text-align: left;
}
#manage-labs
  .manage-labs
  #order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content {
  min-height: 36px;
  border-bottom: solid 1px #ddd;
  padding: 5px 0;
}
#manage-labs .manage-labs #order-list.order-two-part .order-list .order-list-hading {
  padding: 6px 0;
  text-align: left;
}

#customer-settings #delivery-section {
  padding-top: 122px;
}
#customer-settings
  #delivery-section
  #order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content
  .mobi-manage-content
  .font-manage,
#order-list
  .order-list.clinic-list-view
  .order-list-box
  .order-list-content
  .mobi-manage-content
  .font-manage {
  text-align: left;
  padding-top: 0;
}
#order-list .order-list.clinic-list-view .order-list-box .order-list-content {
  cursor: pointer;
  min-height: 36px;
  padding-bottom: 6px;
}
#order-list
  .order-list.clinic-list-view
  .order-list-box
  .order-list-content
  .mobi-manage-content.add-edit-css
  a,
#order-list .order-list .order-list-content .mobi-manage-content.add-edit-css a {
  border: solid 1px @header-bg;
  color: @header-bg;
  padding: 0 10px;
  float: right;
  margin-right: 5px;
  font-size: 13px;
}
#customer-settings
  #delivery-section
  #order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content
  .mobi-manage-content
  .font-manage
  select,
#customer-settings
  #delivery-section
  #order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content
  .mobi-manage-content
  .font-manage
  textarea,
#customer-settings
  #delivery-section
  #order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content
  .mobi-manage-content
  .font-manage
  input[type='text'] {
  width: 100%;
  border: solid 1px #ddd;
  min-height: 30px;
  line-height: 30px;
}
#customer-settings
  #delivery-section
  #order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content:nth-child(2n + 1) {
  background: @gray-light-white;
}
#customer-settings
  #delivery-section
  #order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content
  .mobi-manage-content
  .mobi-manage-content:first-child
  .font-manage
  p,
#customer-settings
  #delivery-section
  #order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content
  .mobi-manage-content
  .mobi-manage-content:nth-child(3)
  .font-manage
  p {
  font-size: 16px;
  font-weight: normal;
  word-break: break-word;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #customer-settings
    #delivery-section
    #order-list.order-two-part
    .order-list
    .order-list-box
    .order-list-content
    .mobi-manage-content
    .mobi-manage-content:first-child
    .font-manage
    p,
  #customer-settings
    #delivery-section
    #order-list.order-two-part
    .order-list
    .order-list-box
    .order-list-content
    .mobi-manage-content
    .mobi-manage-content:nth-child(3)
    .font-manage
    p {
    word-break: break-all;
  }
}

#customer-settings
  #delivery-section
  #order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content
  .mobi-manage-content
  .mobi-manage-content:nth-child(2)
  .font-manage
  p {
  font-size: 13px;
  font-weight: normal;
  word-break: break-word;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #customer-settings
    #delivery-section
    #order-list.order-two-part
    .order-list
    .order-list-box
    .order-list-content
    .mobi-manage-content
    .mobi-manage-content:nth-child(2)
    .font-manage
    p {
    word-break: break-all;
  }
}

#customer-settings
  #delivery-section
  #order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content {
  min-height: 36px;
  border-bottom: solid 1px #ddd;
  padding: 5px 0;
}
#customer-settings
  #delivery-section
  #order-list.order-two-part
  .order-list
  .order-list-hading
  .font-manage,
#order-list .order-list.clinic-list-view .order-list-hading .font-manage {
  text-align: left;
}
#customer-settings
  #delivery-section
  #order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content.tag-header {
  background-color: #ccc;
  padding: 0;
  min-height: 36px;
  font-weight: 500;
}
#customer-settings
  #delivery-section
  #order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content.tag-header
  .font-manage {
  padding-top: 5px;
}

#customer-settings #delivery-section #order-list.order-two-part .text-right button {
  width: auto;
  float: right;
  padding: 3px 50px;
  margin: 50px 5px 5px;
  border: solid 1px @header-bg;
  color: @header-bg;
  background: none;
  font-weight: 600;
}
#customer-settings
  #delivery-section
  #order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content
  .mobi-manage-content
  .font-manage
  .checkbox-style {
  width: 24px;
  height: 24px;
}
#customer-settings
  #delivery-section
  #order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content
  .mobi-manage-content
  .font-manage
  .checkbox-style
  input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin: 0;
}

#customer-settings
  #delivery-section
  #order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content
  .mobi-manage-content
  .font-manage
  label.checkbox-style
  input[type='checkbox']
  + label:after,
#customer-settings
  #delivery-section
  #order-list.order-two-part
  .order-list
  .order-list-box
  .order-list-content
  .mobi-manage-content
  .font-manage
  label.checkbox-style
  input[type='checkbox']:checked
  + label:before {
  z-index: 9;
}

.display-block {
  display: block;
}

#navBar .navbar-default .navbar-nav > li > a.order2-step:hover,
#navBar .navbar-default .navbar-nav > li > a.order2-step:focus {
  border-bottom: solid 9px #1f2532;
  cursor: default;
}

.mt-53 {
  margin-top: 53px;
}

.order-two-part .bridge-suggestion {
  margin-top: 10px;
  margin-left: 0;
}
.font-weight-normal {
  font-weight: normal;
}
.manage-flex-boxes {
  margin-bottom: 40px;
}

.manage-div-smooth .construction-msg {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#sign-section.order-details-2
  .sign-send
  .confirm-info-sign-send
  .files-box
  .file-icon
  svg {
  fill: #dcdada;
  width: 40px;
  margin-top: 10px;
  cursor: pointer;
  height: 40px;
}
#sign-section.order-details-2
  .sign-send
  .confirm-info-sign-send
  .files-box
  .file-icon
  i.fa-file {
  font-size: 40px;
  margin-top: 8px;
}
.manage-steps h3 {
  margin-bottom: 20px;
}
.manage-steps .checkbox-style {
  display: flex;
}
.manage-steps .checkbox-style .datepicker {
  width: 120px;
  margin-left: auto;
  margin-right: 0;
}
.manage-steps .checkbox-style table tr {
  background: #eee;
}
.manage-steps .checkbox-style table tr:first-child,
.manage-steps .checkbox-style table tr:last-child {
  display: none;
}
.manage-steps .checkbox-style table tr .form-control {
  height: 27px;
  padding: 0;
  width: 40px;
  background: none;
  border: 0;
}
.manage-steps .checkbox-style datepicker input {
  background: #eee;
  border: solid 1px #ddd;
  width: 95%;
  height: 26px;
}

#delivery-section .deliveryDate .deliveryDateTime li {
  width: 48%;
}
#delivery-section .deliveryDate .deliveryDateTime li span.custom-time-picker {
  height: 26px;
  background: none;
  border: none;
}
#delivery-section .deliveryDate .deliveryDateTime li span.custom-time-picker input {
  outline: none;
  color: #5c5c5c;
  opacity: 1;
  font-size: 13.5px;
}
.action-on-teeth span.deliveryTime {
  margin-left: 4px;
}

#delivery-section
  .deliveryDate
  .deliveryDateTime
  li
  span.custom-time-picker
  input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #5c5c5c;
  opacity: 1;
  font-size: 13.5px;
}
#delivery-section
  .deliveryDate
  .deliveryDateTime
  li
  span.custom-time-picker
  input::-moz-placeholder {
  /* Firefox 19+ */
  color: #5c5c5c;
  opacity: 1;
  font-size: 13.5px;
}
#delivery-section
  .deliveryDate
  .deliveryDateTime
  li
  span.custom-time-picker
  input:-ms-input-placeholder {
  /* IE 10+ */
  color: #5c5c5c;
  opacity: 1;
  font-size: 13.5px;
}
#delivery-section
  .deliveryDate
  .deliveryDateTime
  li
  span.custom-time-picker
  input:-moz-placeholder {
  /* Firefox 18- */
  color: #5c5c5c;
  opacity: 1;
  font-size: 13.5px;
}

.additional-dentist {
  width: 100%;
  float: left;
  margin: 35px 0;
}
.additional-dentist .divider-new {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}
.additional-dentist .divider-new h1 {
}
.additional-dentist .additional-dentist-details {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}
.additional-dentist .additional-dentist-details span {
  color: #000;
  font-size: 14px;
  margin-bottom: 0;
  display: block;
}
.additional-dentist .additional-dentist-details span > span {
  display: inline-block;
}
.additional-dentist .additional-dentist-details span span > span {
  color: @sky-start-dark;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  text-decoration: underline;
}
.additional-dentist .additional-dentist-btn {
  width: 100%;
  float: left;
}
.additional-dentist .additional-dentist-btn button {
  border: solid 1px @sky-start-dark;
  background: #fff;
  color: @sky-start-dark;
  padding: 2px 12px;
  font-size: 16px;
}
.additional-dentist .additional-dentist-btn button:disabled {
  background: #e7e5e5;
  color: #8888886e;
  cursor: no-drop;
}
#change-dentist-modal .modal-dialog {
  background: #fff;
  overflow: hidden;
  width: 100%;
  max-width: 850px;
}
#change-dentist-modal .modal-dialog .lab-search-input {
  width: 100%;
  float: left;
  margin: 0;
  position: relative;
}
#change-dentist-modal .modal-dialog .lab-search-input i {
  position: absolute;
  left: 18px;
  top: 11px;
  color: @sky-start-dark;
}
#change-dentist-modal .modal-dialog .lab-list {
  margin: 20px 0;
  width: 100%;
  float: left;
}
#change-dentist-modal .modal-dialog .modal-body {
}
#change-dentist-modal .modal-dialog .lab-search-input input {
  width: 250px;
  float: left;
  border: solid 1px #eee;
  line-height: 40px;
  padding: 0 20px 0 45px;
  height: 40px;
}
#change-dentist-modal .modal-dialog .lab-list-heading {
  background: #105c9d;
  width: 100%;
  float: left;
  color: #fff;
  font-size: 16px;
  margin: 0;
  padding: 6px 0;
  font-weight: bold;
}

#change-dentist-modal .modal-header {
  border-bottom: none;
}

#change-dentist-modal .modal-footer {
  text-align: center;
  border-top: none;
}
#change-dentist-modal .modal-footer button {
  color: #105c9d;
  background-color: #fff;
  border-color: #105c9d;
  border-radius: 0;
  padding: 6px 30px;
}
#change-dentist-modal .modal-footer button:hover {
  color: #105c9d;
  background-color: #fff;
  border-color: #105c9d;
}
#change-dentist-modal .lab-list-box {
  height: 350px;
  overflow: auto;
  padding: 6px 0;
}
#change-dentist-modal .mobi-manage-content {
  min-height: 27px;
  border-bottom: solid 1px #eee;
  cursor: pointer;
}

#change-dentist-modal .selected-additional-dentist {
  width: 100%;
  float: left;
  background: #eee;
}
#change-dentist-modal .mobi-manage-content > div {
  min-height: 33px;
  color: #000;
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 500;
  white-space: nowrap;
  padding: 5px 0;
}
#change-dentist-modal .lab-list-box::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

/* Track */
#change-dentist-modal .lab-list-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
#change-dentist-modal .lab-list-box::-webkit-scrollbar-thumb {
  background: #105c9d;
}

#sign-section
  .sign-send
  .confirm-info-sign-send
  .teeth-construction-action
  .action-on-teeth
  p.comment-p.additional-information
  label {
  width: auto;
  float: left;
  min-width: 17.5%;
}
#sign-section
  .sign-send
  .confirm-info-sign-send
  .teeth-construction-action
  .action-on-teeth
  p.comment-p.additional-information
  span {
  float: left;
  width: 80%;
  margin-left: 2%;
}

#sign-section.order-details-2
  .sign-send
  .sign-send
  .sample-span-ui
  p.comment-p.additional-information
  span.remove-m-w {
  width: auto;
  margin-left: 1%;
  white-space: nowrap;
  font-size: 13px;
  padding-right: 0;
}
#sign-section.order-details-2
  .sign-send
  .sign-send
  .sample-span-ui
  p.comment-p.additional-information.sample-content-comma
  span:after {
  right: -4px;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.status-view-row {
  height: 20px;
}
#change-dentist-modal .pagination,
#change-dentist-modal .pagination ul {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
  display: inline-block;
  text-align: center;
}
#change-dentist-modal .pagination ul {
  width: auto;
  float: none;
}
#change-dentist-modal .pagination ul li {
  width: auto;
  height: 30px;
  float: left;
  text-align: center;
  line-height: 30px;
  margin: 1px;
  border-radius: 3px;
  cursor: pointer;
}

#change-dentist-modal .pagination ul li i {
  line-height: 29px;
}

#change-dentist-modal .pagination ul li.active-tab {
  color: #6dd2d3;
  border: solid 1px #6dd2d3;
  font-weight: bold;
}

#order-list.invoice-table .order-list .order-list-hading {
  font-size: 13px;
  line-height: 16px;
}
#order-list.invoice-table .order-list {
  font-size: 12px;
}
#order-list.invoice-table .order-list .order-list-box p {
  font-size: 12px;
  font-weight: normal;
  padding: 10px 0;
}
.status-filter.invoice-filter-part .several-options input {
  padding-left: 15px;
}

.user-message {
  padding: 4px 0;
  font-weight: bold;
}
#manage-labs .manage-scroll-mobile .repeat-lis {
  float: left;
  width: 100%;
  border-bottom: solid 2px #ccc;
  cursor: pointer;
  color: #000;
  padding-top: 5px;
}
#manage-labs .manage-scroll-mobile .repeat-lis .text-left label {
  font-weight: normal;
}
#manage-labs .manage-scroll-mobile .last-price-section {
  text-align: center;
  width: 100%;
  min-height: 27px;
}
#manage-labs .manage-scroll-mobile .change-head-bg .head-table {
  background: rgb(238, 238, 238);
  width: 100%;
  float: left;
  padding: 5px 0 0;
  color: #000;
}
h3.material-heading {
  color: #1bd3c8;
  font-weight: normal;
  margin: 25px 0 15px;
}

.sample-taken-options label.checkbox-style label {
  cursor: pointer;
  z-index: 12;
}

label.checkbox-style.sample-taken-radio input[type='radio'] + label:after {
  border-radius: 50%;
}

.sample-taken-options {
  float: left;
  width: 100%;
  display: inline-flex;
}

.sample-taken-options .sample-taken-option {
  margin-right: 36px;
  z-index: 8;
}

.sample-content {
  width: 100%;
  float: left;
  padding: 20px;
  ul {
    width: 100%;
    float: left;
    margin-top: 10px;
    padding-left: 10px;
    li {
      width: auto;
      float: left;
      padding: 0 14px;
      border: solid 2px @sky-start-dark;
      color: @sky-start-dark;
      font-weight: 600;
      margin-right: 16px;
      margin-bottom: 20px;
      cursor: pointer;
      &.active {
        background: #105c9d;
        color: #fff;
      }
    }
  }
}
.sample-content-comma span {
  position: relative;
  padding-right: 10px;
}

.sample-content-comma span:after {
  position: absolute;
  right: 5px;
  bottom: 0;
  content: ',';
}

.sample-content-comma span:last-child:after {
  content: '';
}

.sample-all-comments {
  .additional-information label {
    width: 20% !important;
    min-width: 20% !important;
  }
  .additional-information .rightPanel {
    float: left;
    width: 79%;
  }
  .additional-information .rightPanel > span {
    margin-bottom: 15px;
    width: 100% !important;
    display: inline-block;
  }
}

.patientExistingorder {
  display: inline-block;
  width: 100%;
  & > p {
    font-size: 14px;
    color: #105c9d;
    padding: 40px 0 0px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .checkBox {
    label.checkbox-style {
      font-weight: normal;
      font-size: 14px;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: auto;
      float: none;
      padding-left: 0;
    }
    span {
      padding-left: 10px;
      padding-top: 3px;
      display: inline-block;
      vertical-align: middle;
      font-weight: 600;
      font-size: 14px;
    }
  }
}

#patient-section .SearchPatient .patientExistingorder .col-lg-2 {
  width: 16% !important;
  min-height: auto;
  padding-right: 0;
  text-align: center;
}
#patient-section
  .SearchPatient
  .patientExistingorder
  .order-list-hading
  .col-lg-2:last-child {
  width: 18% !important;
}
#patient-section .SearchPatient .patientExistingorder .order-list-hading {
  border-bottom: 3px solid #105c9d;
  overflow: hidden;
  margin-bottom: 0;
  &:not(:first-child) .mobi-manage-content {
    text-align: center;
  }
  .col-lg-2 {
    padding: 10px 0 0;
    margin-bottom: 0;
    border-bottom: 0;
    font-weight: bold;
  }
}
#patient-section
  .SearchPatient
  .patientExistingorder
  .mobi-manage-content:last-child
  .col-lg-2 {
  width: 18% !important;
}
#patient-section .SearchPatient .patientExistingorder {
  .col-lg-12 {
    p {
      line-height: normal;
      margin-bottom: 0;
      font-size: 12px;
      color: #000;
    }
    &.order-list-box {
      border-bottom: 2px solid #bdbdbd;
      padding-bottom: 8px !important;
      margin-bottom: 0 !important;
      cursor: pointer;
      padding-top: 8px !important;
      &:nth-last-child(2) {
        border-bottom: 0;
      }
      &:hover {
        background: @gray-bg-color;
      }
    }
  }
  .order-list-hading .col-lg-2:first-child {
    text-align: left;
  }
  .mobi-manage-content:first-child .col-lg-2 {
    text-align: left !important;
  }
}
.DeliveryInformation .contentWrapper {
  margin: 10px 0 0 15px;
}

.constallCommenwrapper {
  display: table;
  width: 100%;
  .constallComment {
    display: table-cell;
    float: none !important;
    width: 50%;
    .checkbox-style.full-div {
      margin-top: 30px;
    }
  }
}
#sign-section
  .sign-send
  .confirm-info-sign-send
  .teeth-construction-action
  .action-on-teeth
  p.comment-p.exception-reason
  span {
  width: auto;
  margin-left: 0;
  &:nth-child(2) {
    margin-left: 2%;
  }
}

.mr-12 {
  margin-right: 12px !important;
}

.h-32 {
  height: 32px !important;
}

#patient-section.order-two-part .SearchPatient .pagination ul {
  display: flex;
  justify-content: center;
}

#patient-section.order-two-part .SearchPatient .pagination ul li {
  width: auto;
  margin-right: 4px;
}

#patient-section.order-two-part .SearchPatient .pagination ul li.active a {
  background-color: #105c9d;
  border-color: #105c9d;
}
