#section-page {
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 35px;
  padding: 18px 61px 50px;
}
#section-heading {
  float: left;
  font-size: 20px;
  color: #1f2532;
  font-weight: 700;
  border-bottom: solid 1px #1f2532;
  width: 100%;
  line-height: 50px;
}
#full-width {
  width: 100%;
  float: left;
}
#rate-it {
  /* Background */
  /* Selected state */
  /* Cancelation of hover styling */
  /* Hover styling */
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../../../images/rated-star.svg");
}
#rate-it .ngrateit-reset {
  background-image: url("../../../images/cancel-icon.svg");
}
#rate-it .ngrateit-bg-star {
  background-image: url("../../../images/bg-star.svg");
}
#rate-it .ngrateit-selected {
  background-image: url("../../../images/rated-star.svg");
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating span:hover ~ span {
  background-image: url("../../../images/bg-star.svg");
}
#rate-it .ngrateit:not(.ngrateit-readonly) .ngrateit-rating:hover span {
  background-image: url("../../../images/rated-star.svg");
}
input:-webkit-autofill {
  background: #fff !important;
  background-color: #fff !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
#lab-profile {
  width: 100%;
  float: left;
  margin-top: 150px;
  margin-bottom: 20px;
}
#lab-profile .lab-profile {
  width: 100%;
  float: left;
}
#lab-profile .lab-profile .profile-left-section {
  width: 100%;
  float: left;
  background: #fff;
  padding-top: 45px;
}
#lab-profile .lab-profile .profile-left-section .head-profile {
  width: 100%;
  float: left;
  padding: 0 35px;
}
#lab-profile .lab-profile .profile-left-section .head-profile h2 {
  width: 100%;
  float: left;
  text-align: center;
  background: url(images/border-head-h2.png) 0 center repeat-x;
}
#lab-profile .lab-profile .profile-left-section .head-profile h2 span {
  width: auto;
  padding: 0 28px;
  background: #fff;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 300;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left {
  width: 100%;
  float: left;
  padding: 25px 0px;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row {
  width: 100%;
  float: left;
  margin-bottom: 0px;
  padding: 0 35px;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row label {
  color: #105c9d;
  margin-bottom: 5px;
  float: left;
  width: 100%;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row input {
  width: 100%;
  float: left;
  padding: 10px 20px;
  font-size: 15px;
  text-align: center;
  text-align: left;
  border: solid 1px #979797;
  line-height: 17px;
  font-weight: 400;
  color: #a5a3a3;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row textarea {
  width: 100%;
  float: left;
  padding: 15px 20px;
  font-size: 15px;
  text-align: center;
  text-align: left;
  border: solid 1px #979797;
  line-height: 17px;
  font-weight: 400;
  color: #a5a3a3;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row span {
  width: 100%;
  float: left;
  color: #dcdada;
  font-size: 12px;
  margin: -5px 0 7px;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row p {
  width: 100%;
  float: left;
  font-size: 12px;
  text-align: right;
  display: inline;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row p span {
  display: inline;
  width: auto;
  float: none;
  color: #105c9d;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div {
  width: 100%;
  float: left;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row {
  width: 100%;
  float: left;
  margin: 1px 0px;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row label {
  width: 55%;
  margin-left: 2.5%;
  float: left;
  font-weight: normal;
  font-size: 14px;
  margin-top: 5px;
  color: #a5a3a3;
  display: flex;
  justify-content: left;
  align-items: center;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row label input[type='checkbox'].notCheckedInput {
  float: left;
  width: 15px;
  margin: 2px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid #8edddd;
  outline: none;
  -webkit-appearance: inherit;
  padding: 7px;
  border-radius: 2px;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row label input[type='checkbox'].checkedInput {
  float: left;
  width: 15px;
  margin: 2px 10px 0 0;
  height: 15px;
  background: #105c9d;
  border: 2px solid #105c9d;
  outline: 0;
  -webkit-appearance: inherit;
  position: relative;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 7px;
  border-radius: 4px;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row label input[type='checkbox'].checkedInput:after {
  content: '\f00c';
  position: absolute;
  top: 1px;
  left: 1px;
  font-size: 12px;
  color: #fff;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row label p {
  width: auto;
  margin-left: 2.5%;
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #a5a3a3;
  margin: 0;
  text-align: left;
  line-height: 17px;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row .delivery-section {
  width: 40%;
  float: right;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row .delivery-section input {
  width: 30%;
  float: left;
  text-align: center;
  padding: 6px 0px;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row .delivery-section span {
  width: 70%;
  margin-bottom: -1px;
  float: right;
  color: #dcdada;
  font-size: 14px;
  text-align: right;
  line-height: 39px;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section .jobs-div .jobs-div-row .delivery-section.align-span-content span {
  width: 65%;
  text-align: left;
  line-height: 15px;
  margin-top: 1px;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.current-jobs-section.document-section {
  margin: 20px 0 10px;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section {
  margin-top: 10px;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div {
  width: 100%;
  float: left;
  background: #f4f4f4;
  padding: 7px;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-left {
  float: left;
  width: 60%;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-left .upload-file-name {
  margin-top: 0px;
  color: #1f2532;
  padding: 15px 5px 0px;
  min-height: 50px;
  max-height: 50px;
  margin-bottom: 3px;
  margin-right: 3px;
  float: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  width: 90%;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-left .upload-file-name .progress {
  width: 100%;
  float: left;
  height: 3px;
  border-radius: 0px;
  margin: 12px 0 0px;
  background-color: #1f2532;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-left .upload-file-name .progress .progress-bar {
  height: 3px;
  float: left;
  border-radius: 0px;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-left img {
  float: left;
  margin-top: 10px;
  margin-left: 5px;
  cursor: pointer;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-right {
  width: auto;
  float: right;
  max-width: 40%;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-right .upload-file-div {
  position: relative;
  width: 130px;
  float: right;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-right .upload-file-div input[type='file'] {
  width: 100%;
  cursor: pointer;
  float: right;
  padding-top: 4px;
  padding-bottom: 0px;
  background: #105c9d;
  color: #fff;
  position: relative;
  z-index: 99;
  opacity: 0;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-right .upload-file-div .filechoose-div {
  width: 100%;
  cursor: pointer;
  float: right;
  background: #105c9d;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
}
#lab-profile .lab-profile .profile-left-section .profile-form-left .profile-form-left-row.document-section .document-div .document-div-right span {
  width: 100%;
  margin-bottom: 0;
  float: right;
  color: #979797;
  font-size: 16px;
  text-align: right;
  line-height: 21px;
  margin-top: 0;
}
#lab-profile .lab-profile .profile-right-section {
  width: 98%;
  float: right;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-one {
  width: 100%;
  float: left;
  background: #fff;
  padding: 45px 35px 50px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-one .head-profile {
  width: 100%;
  float: left;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-one .head-profile h2 {
  width: 100%;
  float: left;
  text-align: center;
  background: url(images/border-head-h2.png) 0 center repeat-x;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-one .head-profile h2 span {
  width: auto;
  padding: 0 28px;
  background: #fff;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 300;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-one .media-section {
  width: 100%;
  float: left;
  margin-top: 30px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-one .media-section .media-section-block {
  width: 47%;
  float: left;
  margin-right: 3%;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-one .media-section .media-section-block h3 {
  width: 100%;
  float: left;
  color: #105c9d;
  font-size: 18px;
  margin-bottom: 18px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-one .media-section .media-section-block img {
  width: 75%;
  float: left;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-one .media-section .media-section-block button {
  width: auto;
  text-transform: uppercase;
  float: left;
  padding: 10px 15px;
  margin-top: 20px;
  color: #fff;
  background: #105c9d;
  border: 0px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two {
  width: 100%;
  float: left;
  background: #fff;
  padding: 45px 35px 30px;
  margin-top: 50px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .head-profile {
  width: 100%;
  float: left;
  padding: 0 35px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .head-profile h2 {
  width: 100%;
  float: left;
  text-align: center;
  background: url(images/border-head-h2.png) 0 center repeat-x;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .head-profile h2 span {
  width: auto;
  padding: 0 28px;
  background: #fff;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 300;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section {
  width: 100%;
  float: left;
  margin-top: 30px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block {
  width: 51%;
  float: left;
  margin-right: 1%;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block h3 {
  width: 100%;
  float: left;
  color: #105c9d;
  font-size: 18px;
  margin-bottom: 18px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div {
  width: 100%;
  float: left;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row {
  width: 100%;
  float: left;
  padding-left: 15px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row label {
  width: 95px;
  position: relative;
  cursor: pointer;
  float: left;
  font-weight: normal;
  font-size: 14px;
  margin-top: 0px;
  color: #a5a3a3;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row label input[type='checkbox'].notCheckedInput {
  float: left;
  width: 95px;
  margin: 0px 0px 0px 0px;
  height: 40px;
  background: #dcdada;
  outline: none;
  -webkit-appearance: inherit;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row label input[type='checkbox'].checkedInput {
  float: left;
  width: 95px;
  margin: 0px 10px 0 0;
  height: 40px;
  background: #105c9d;
  outline: 0;
  position: relative;
  opacity: 0;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row label input[type='checkbox'].notCheckedInput + span {
  background: #dcdada;
  color: #1f2532;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row label input[type='checkbox'].checkedInput + span {
  background: #105c9d;
  color: #fff;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row label input[type='checkbox'] + span {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 40px;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 16px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row .delivery-section {
  width: 63%;
  float: right;
  display: flex;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row .delivery-section table {
  border: solid 1px #000;
  border-radius: 4px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row .delivery-section table td input {
  border: 0;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row .delivery-section table td input:focus {
  box-shadow: none;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row .delivery-section timepicker input {
  width: 100%;
  float: left;
  text-align: center;
  padding: 7px 0 6px;
  font-size: 14px;
  color: #1f2532;
  border: solid 1px #1f2532;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row .delivery-section input {
  width: 27%;
  float: left;
  text-align: center;
  padding: 7px 0 6px;
  font-size: 14px;
  color: #1f2532;
  border: solid 1px #1f2532;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row .delivery-section span {
  width: auto;
  padding: 0 3px;
  font-style: italic;
  text-align: center;
  margin-bottom: -1px;
  float: left;
  color: #a5a3a3;
  font-size: 13px;
  line-height: 39px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row:first-child {
  padding-left: 0;
  margin-bottom: 5px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block .select-days-div .jobs-div-row:first-child label {
  width: 100%;
  float: left;
  font-weight: normal;
  font-size: 14px;
  margin-top: 0;
  color: #a5a3a3;
  /*input[type="checkbox"].notCheckedInput {
                    float: left;
                    width: 15px;
                    margin: 2px 10px 0 0;
                    height: 15px;
                    background: none;
                    border: 2px solid @sky-low-bright;
                    outline: none;
                    -webkit-appearance: inherit;
                    padding: 7px;
                    border-radius: 2px;
                  }
                  input[type="checkbox"].checkedInput {
                    float: left;
                    width: 15px;
                    margin: 2px 10px 0 0;
                    height: 15px;
                    background: none;
                    border: 2px solid @sky-start-dark;
                    outline: 0;
                    -webkit-appearance: inherit;
                    position: relative;
                    font: normal normal normal 14px/1 FontAwesome;
                    padding: 7px;
                    border-radius: 4px;
                    opacity: 1;
                  }
                  input[type="checkbox"].checkedInput:after {
                    content: '\f00c';
                    position: absolute;
                    top: 1px;
                    left: 1px;
                    font-size: 12px;
                    color: @sky-start-dark;
                  }*/
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block:nth-child(2) {
  width: 45%;
  float: right;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block:nth-child(2) .contact-form {
  width: 100%;
  float: left;
  margin-top: 7px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block:nth-child(2) .contact-form .contact-form-row {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block:nth-child(2) .contact-form .contact-form-row i {
  width: 35px;
  text-align: center;
  float: left;
  color: #105c9d;
  font-size: 20px;
  line-height: 25px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block:nth-child(2) .contact-form .contact-form-row input {
  width: 85%;
  background: url(images/cross-icon.jpg) 95% center no-repeat;
  float: right;
  border: solid 1px #1f2532;
  color: #1f2532;
  padding: 0px 30px 0px 10px;
  font-size: 14px;
  position: relative;
  background-image: none;
  min-height: 25px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block:nth-child(2) .contact-form .contact-form-row input.pincode-input {
  width: 30%;
  float: left;
  margin-left: 15%;
  margin-top: 7px;
  background-position: 90% center;
  padding-right: 20px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block:nth-child(2) .contact-form .contact-form-row input.city-name-input {
  width: 50%;
  float: right;
  margin-top: 7px;
  background-position: 92% center;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .media-section .media-section-block:nth-child(2) .contact-form .contact-form-row span {
  width: auto;
  position: absolute;
  right: 10px;
  top: 5px;
  color: #1f2532;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .calendar-section {
  width: 100%;
  float: left;
  margin-top: 20px;
  padding-left: 15px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .calendar-section h4 {
  width: 100%;
  float: left;
  font-size: 16px;
  color: #1f2532;
  margin-bottom: 15px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .calendar-section p {
  width: 100%;
  float: left;
  font-size: 14px;
  color: #4a4a4a;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .calendar-section .lab-contact-calendar {
  width: 100%;
  float: left;
  margin-top: 20px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .calendar-section .lab-contact-calendar .multiple-date-picker {
  width: 252px;
  box-shadow: 0 0 2px #eee;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .calendar-section .lab-contact-calendar .multiple-date-picker .picker-top-row {
  display: flex;
  align-items: center;
  border: solid 1px #ccc;
  padding: 0;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .calendar-section .lab-contact-calendar .multiple-date-picker .picker-top-row p {
  margin-bottom: 0;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .calendar-section .lab-contact-calendar .multiple-date-picker .picker-top-row .text-center.picker-month {
  border-right: solid 1px #ccc;
  border-left: solid 1px #ccc;
  font-weight: bold;
  font-size: 12px;
  padding: 5px 0;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .calendar-section .lab-contact-calendar .multiple-date-picker .picker-days-week-row .text-center {
  font-weight: bold;
  width: 36px;
  font-size: 13px;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .calendar-section .lab-contact-calendar .multiple-date-picker .picker-days-row .picker-day {
  width: 36px;
  height: 30px;
  padding: 0;
  font-size: 12px;
  line-height: 30px;
  border-radius: 4px;
  cursor: pointer;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .calendar-section .lab-contact-calendar .multiple-date-picker .picker-days-row .picker-day:hover {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .calendar-section .lab-contact-calendar .multiple-date-picker .picker-days-row .picker-day.future {
  cursor: pointer;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .calendar-section .lab-contact-calendar .multiple-date-picker .picker-days-row .picker-day.past {
  cursor: not-allowed;
  opacity: 0.65;
}
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .calendar-section .lab-contact-calendar .multiple-date-picker .picker-days-row .picker-day.picker-off:not(.past),
#lab-profile .lab-profile .profile-right-section .profile-right-block-two .calendar-section .lab-contact-calendar .multiple-date-picker .picker-days-row .picker-day.picker-selected {
  color: #fff;
  background-color: #ffb5b6;
  border-color: #ffb5b6;
}
#lab-profile .lab-profile .profile-right-section .save-change-button {
  width: 100%;
  float: left;
}
#lab-profile .lab-profile .profile-right-section .save-change-button button {
  width: auto;
  float: right;
  text-transform: uppercase;
  padding: 10px 15px;
  margin-top: 20px;
  color: #fff;
  background: #105c9d;
  border: 0px;
}
#lab-profile .lab-profile .profile-right-section .save-change-button button.disableBtn {
  cursor: not-allowed;
  opacity: 0.8;
}
#lab-profile .lab-profile .notifications {
  float: left;
  padding-top: 40px;
  background: #eee;
}
#lab-profile .lab-profile .notifications .profile-left-section {
  padding-top: 0;
}
#lab-profile .lab-profile .notifications .profile-left-section .head-profile {
  background: #fff;
}
#lab-profile .lab-profile .notifications .profile-left-section .head-profile h2 {
  margin-top: 30px;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left {
  width: 100%;
  float: left;
  padding: 0 35px !important;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col {
  width: 100%;
  float: left;
  margin-top: 13px;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .notifications-title {
  width: 100%;
  float: left;
  font-weight: bold;
  color: #105c9d;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .select-destination {
  font-size: 13px;
  color: #a7a2a2;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-email {
  width: 100%;
  float: left;
  text-align: left;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-email .checkbox-inline {
  padding-left: 0;
  width: 100%;
  font-size: 13px;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-email .checkbox-inline p {
  float: left;
  margin-right: 8px;
  position: relative;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-email .checkbox-inline p:after {
  content: ',';
  color: #000;
  font-weight: bold;
  width: 3px;
  height: 0px;
  position: absolute;
  right: -4px;
  bottom: 22px;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-email .checkbox-inline p:last-child:after {
  content: '';
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-email .checkbox-inline p.email-noti-para {
  width: 90%;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-email .checkbox-inline p.email-noti-para span {
  float: left;
  margin-right: 8px;
  position: relative;
  line-height: 16px;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-email .checkbox-inline p.email-noti-para span:after {
  content: ',';
  color: #000;
  font-weight: bold;
  width: 3px;
  height: 0px;
  position: absolute;
  right: -4px;
  bottom: 16px;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-email .checkbox-inline p.email-noti-para span:last-child:after {
  content: '';
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-email .checkbox-inline input[type='radio']:not(:checked) {
  float: left;
  width: 15px;
  margin: 2px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid #105c9d;
  outline: none;
  -webkit-appearance: inherit;
  padding: 8px !important;
  border-radius: 50%;
  position: static;
  cursor: pointer;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-email .checkbox-inline input[type='radio']:checked {
  border: 2px solid #105c9d;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-email .checkbox-inline input[type='radio']:checked {
  float: left;
  width: 15px;
  margin: 0px 10px 0px 0px;
  height: 15px;
  background: none;
  border: 2px solid #105c9d;
  outline: none;
  cursor: pointer;
  -webkit-appearance: inherit;
  position: static;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 8px !important;
  border-radius: 50%;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-email .checkbox-inline input[type='radio']:checked:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  font-size: 12px;
  width: 12px;
  height: 12px;
  background: #105c9d;
  border-radius: 50%;
  color: #105c9d;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-email .checkbox-inline input[type='radio']:checked:after {
  background: #105c9d;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-enter-email .email-input {
  width: 85%;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-enter-email .checkbox-inline {
  padding-left: 0;
  width: 100%;
  margin-left: 0;
  margin-top: 5px;
  font-size: 13px;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-enter-email .checkbox-inline input[type='radio']:not(:checked) {
  float: left;
  width: 15px;
  margin: 0 10px 0 0;
  height: 15px;
  background: none;
  border: 2px solid #105c9d;
  outline: none;
  -webkit-appearance: inherit;
  padding: 8px;
  border-radius: 50%;
  position: static;
  cursor: pointer;
  min-height: inherit;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-enter-email .checkbox-inline input[type='radio']:checked {
  border: 2px solid #105c9d;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-enter-email .checkbox-inline input[type='radio']:checked {
  float: left;
  width: 15px;
  margin: 4px 10px 0 0;
  height: 15px;
  background: none;
  border: 2px solid #105c9d;
  outline: none;
  cursor: pointer;
  -webkit-appearance: inherit;
  position: static;
  font: normal normal normal 14px/1 FontAwesome;
  padding: 8px;
  border-radius: 50%;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-enter-email .checkbox-inline input[type='radio']:checked:after {
  content: '';
  position: absolute;
  top: 8px;
  left: 4px;
  font-size: 12px;
  width: 12px;
  height: 12px;
  background: #105c9d;
  border-radius: 50%;
  color: #105c9d;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-enter-email .checkbox-inline input[type='radio']:checked:after {
  background: #105c9d;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-col .user-enter-email .checkbox-inline input {
  float: left;
  width: auto;
  text-align: left;
  padding: 0 0 0 5px;
  border: solid 2px #ccc;
  color: #000;
  min-height: 25px;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-Select {
  padding: 0;
  width: 100%;
  float: left;
  margin-top: 24px;
  margin-bottom: 50px;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-Select .select-email {
  font-size: 13px;
  color: #a7a2a2;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-Select .switch-button {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-Select .switch-button .switch.checked {
  background: #105c9d;
  border-color: #105c9d;
  float: left;
  margin-right: 20px;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-Select .switch-button .switch {
  float: left;
  margin-right: 20px;
  background: #d9dadc;
  width: 42px;
  height: 22px;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-Select .switch-button .switch small {
  width: 22px;
  height: 22px;
}
#lab-profile .lab-profile .notifications .profile-left-section .profile-form-left .profile-form-left-Select .switch-button p {
  float: left;
  margin-bottom: 0;
  font-size: 13px;
  color: #a7a2a2;
}
#lab-profile .lab-profile.tabbing-section-add ul.tabbing-ul {
  padding: 0;
  margin-bottom: 40px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
#lab-profile .lab-profile.tabbing-section-add ul.tabbing-ul li {
  width: 100%;
  float: left;
}
#lab-profile .lab-profile.tabbing-section-add ul.tabbing-ul li a {
  width: 100%;
  float: left;
  padding: 0 10px !important;
  background: #fff;
  text-align: center;
  color: #105c9d;
  line-height: 44px;
  height: 44px;
}
#lab-profile .lab-profile.tabbing-section-add ul.tabbing-ul li:last-child {
  border-bottom: 0;
}
#lab-profile .lab-profile.tabbing-section-add ul.tabbing-ul li.active a {
  background: #000;
  color: #fff;
}
#lab-profile .lab-profile.tabbing-section-add ul.tabbing-ul li.hover {
  opacity: 0.8;
}
#lab-profile .lab-profile.tabbing-section-add .profile-right-section {
  width: 100%;
}
#lab-profile .lab-profile.tabbing-section-add .profile-right-section .profile-right-block-two {
  margin-top: 0;
  padding: 45px 10px 30px;
}
#lab-profile .lab-profile.tabbing-section-add .profile-right-section .profile-right-block-two .head-profile {
  padding: 0;
}
#lab-profile .lab-profile.tabbing-section-add .notifications {
  padding-top: 0;
}
#lab-profile .lab-profile.tabbing-section-add .profile-left-section .notifications {
  padding-top: 0;
}
#lab-profile .lab-profile.tabbing-section-add .profile-left-section .notifications .head-profile h2 {
  margin-top: 0;
}
.delivery-section .ng-isolate-scope {
  width: 60px;
  float: left;
}
.delivery-section .ng-isolate-scope tr:first-child {
  display: none;
}
.delivery-section .ng-isolate-scope tr {
  border: 1px solid #1f2532;
}
.delivery-section .ng-isolate-scope tr:last-child {
  display: none;
}
.delivery-section .ng-isolate-scope tr td input {
  width: 28px !important;
  border: 0px !important;
  padding: 0px !important;
  line-height: 35px;
  min-height: 35px;
  height: 35px;
  border-radius: 0px;
  box-shadow: none;
}
.delivery-section .tooltip.ng-scope.ng-isolate-scope.top.file-name-tooltip.fade.in {
  width: 220px;
  margin-top: 3px;
  max-width: initial;
}
.delivery-section .tooltip-inner {
  background: #105c9d;
  max-width: initial;
}
.delivery-section .tooltip.top .tooltip-arrow,
.delivery-section .tooltip.top-left .tooltip-arrow,
.delivery-section .tooltip.top-right .tooltip-arrow {
  border-top-color: #105c9d;
}
.uib-day .btn.btn-info {
  background-color: inherit;
  border-color: inherit;
  color: inherit;
}
.uib-day.selected .btn {
  color: #fff;
  background-color: #ffb5b6;
  border-color: #ffb5b6;
}
.uib-day.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
  color: #fff !important;
  background-color: #1f2532 !important;
  border-color: #1f2532 !important;
}
.profile-form-left-Select.general-notifications {
  margin-bottom: 0px !important;
}
.profile-form-left-Select.general-notifications .notifications-title {
  margin-bottom: 10px !important;
}
.red-astrix-mark.invalid-email {
  margin-left: 40px;
  font-size: 11px;
  margin-bottom: 0;
}
.lh-0 {
  line-height: 0;
}
.m-0 {
  margin-bottom: 0;
}
.transport-service {
  margin-bottom: 15px;
}
.transport-service h2 {
  background: none !important;
}
.transport-service .toggle-transport {
  display: flex;
  align-items: center;
  padding: 0;
}
.transport-service .toggle-transport button {
  width: 52px;
  height: 22px;
}
.transport-service .toggle-transport button small {
  width: 22px;
  height: 22px;
}
.action-list-category-section-block {
  width: 100%;
  float: left;
}
.action-list-category-section-block ul li {
  width: auto;
  float: left;
  padding: 5px 30px;
  margin: 5px;
  border: solid 1px #105c9d;
  color: #105c9d;
  font-weight: 600;
  cursor: pointer;
}
.lab-hex-code {
  width: 30% !important;
}
.lab-text-code {
  width: 40% !important;
}
